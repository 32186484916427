import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, empty, of, throwError, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';

import { LabelsRequests } from './labels.requests';

import * as fromStore from './labels.state';
import * as fromActions from './labels.actions';
// import {
//     ProjectsActions,
//     ProjectsActionType,

// 	LoadProject,
//     LoadProjectSuccess,
//     LoadProjectError,

//     LoadProjectFiles,
//     LoadProjectFilesSuccess,
//     LoadProjectFilesError,

//     LoadProjectOriginVersion,
//     LoadProjectOriginVersionSuccess,
//     LoadProjectOriginVersionError,

//     SetActiveProjectFile,
//     SetActiveProjectFileSuccess,
//     SetActiveProjectFileError,

//     UploadProjectFiles,
//     UploadProjectFilesSuccess,
//     UploadProjectFilesError,

//     LoadEditorAssigners,
//     LoadEditorAssignersSuccess,
//     LoadEditorAssignersError,

//     LoadEditorAccess,
//     LoadEditorAccessSuccess,
//     LoadEditorAccessError,

//     SetProjectAssigned,
//     SetProjectAssignedSuccess,
//     SetProjectAssignedError,

//     SetProjectDue,
//     SetProjectDueSuccess,
//     SetProjectDueError,

//     SetProjectStatus,
//     SetProjectStatusSuccess,
//     SetProjectStatusError

// } from './labels.actions';

@Injectable()
export class LabelsEffects {

    constructor(private actions$: Actions, private store$: Store<fromStore.Labels>, private requests: LabelsRequests) {}

    @Effect()
    public searchLabels$: Observable<fromActions.LabelsActions> = this.actions$.pipe(
        ofType<fromActions.SearchLabels>(fromActions.LabelsActionType.SearchLabels),
        switchMap((action) => {
            return this.requests.search$(action.input).pipe(
                map((labels: Objects.Label[]) => {
                    return new fromActions.SearchLabelsSuccess(labels);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.SearchLabelsError(err));
                })
            );
        })
    );

    @Effect()
    public loadCategories$: Observable<fromActions.LabelsActions> = this.actions$.pipe(
        ofType<fromActions.LoadCategories>(fromActions.LabelsActionType.LoadCategories),
        switchMap((action) => {
            return this.requests.loadCategories$().pipe(
                map((categories: Objects.LabelsGroup[]) => {
                    return new fromActions.LoadCategoriesSuccess(categories);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadCategoriesError(err));
                })
            );
        })
    );

    @Effect()
    public loadAccess$: Observable<fromActions.LabelsActions> = this.actions$.pipe(
        ofType<fromActions.LoadAccess>(fromActions.LabelsActionType.LoadAccess),
        switchMap((action) => {
            return this.requests.loadAccess$().pipe(
                map((access: Access.AccessLabels) => {
                    return new fromActions.LoadAccessSuccess(access);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadAccessError(err));
                })
            );
        })
    );

    @Effect()
    public addLabel$: Observable<fromActions.LabelsActions> = this.actions$.pipe(
        ofType<fromActions.AddLabel>(fromActions.LabelsActionType.AddLabel),
        switchMap((action) => {
            return this.requests.add$(action.name, action.category).pipe(
                map((label: Objects.Label) => {
                    return new fromActions.AddLabelSuccess(label);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.AddLabelError(err));
                })
            );
        })
    );

    // @Effect()
    // public addLabelSuccess$ = this.actions$.pipe(
    //     ofType<fromActions.AddLabelSuccess>(fromActions.LabelsActionType.AddLabelSuccess),
    //      //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
    //     switchMap((action) => [
    //         new fromActions.SearchLabels(action.label.name)
    //     ])
    // );
}
