import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpersModule } from '@core/components/helpers/helpers.module';
import { VersionsModule } from '@core/components/versions/versions.module';

import { VersionHelpersModule } from '../../helpers/helpers.module';
import { VersionsDetailsContentModule } from '../content/content.module';

import { VersionsDetailsVersionComponent } from './version/version.component';
import { VersionsDetailsFilePreviewComponent } from './version-file-preview/version-file-preview.component';

@NgModule({
    imports: [
        CommonModule,
        HelpersModule,
        VersionsModule,
        VersionsDetailsContentModule,
        VersionHelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionsDetailsVersionComponent,
        VersionsDetailsFilePreviewComponent
    ],
    exports: [
        VersionsDetailsVersionComponent,
        VersionsDetailsFilePreviewComponent
    ],

})
export class VersionsSectionsModule{ }
