
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';

import { UrlService } from '@core/services/url/url.service';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';
import { VersionListItemMenuClick } from '@core/components/versions/list/version-list-item-menu-click';

import { LogbookService } from 'app/services/app/logbook.service';
import { AppRouterService } from 'app/services/app/router.service';
import { VersionsService } from 'app/services/app/versions.service';
import { AppService } from 'app/services/app/app.service';


@Component({
    selector: 'project-details-logbook-properties-requests-event',
    templateUrl: './requests-event.component.html',
    styleUrls: ['./requests-event.component.scss']
})
export class ProjectDetailsLogbookRequestsEventPropertiesComponent implements OnInit, OnDestroy{
    protected readonly ACCESS_REQUEST_FEEDBACK = 'share_feedback';


    public readonly PROJECT_ADDED = 'requests.added';
    public readonly LABEL_ADDED = 'requests.labels.added';
    public readonly LABEL_REMOVED = 'requests.labels.removed';
    public readonly FILE_ADDED = 'requests.files.added';
    public readonly FILE_REMOVED = 'requests.files.removed';
    public readonly STAGE_UPDATED = 'requests.status.updated';
    public readonly MESSAGE_ADDED = 'requests.messages.added';
    public readonly MESSAGE_REMOVED = 'requests.messages.removed';
    public readonly ASSIGNED = 'requests.assigned.updated';
    public readonly DUE_DATE = 'requests.due_date.updated';
    public readonly ARTWORK_ADDED = 'requests.versions.added';

	@Input() event: Objects.Event;

    @Input() properties: any = null;

    public messagePrefixUrl: string = '';
    public notes: number;
    public menu: Array<ThreeDotsMenuItem> = [];
    // version details
    public feedbackPluginAccess: boolean = false;
    public feedbacks: Array<VersionFeedback> = [];
    public menuVersion: Array<ThreeDotsMenuItem> = [{
        title: "See Artwork Details",
        key: "details",
        icon: "see-details"
    }];

    protected subscriptor: Subscription;
    protected notesSubscriptor: Subscription;
    protected feedbacksSubscriptor: Subscription;

    constructor(private appRouter: AppRouterService, 
        private logbookSrv: LogbookService,
        private urlSrv: UrlService,
        private app: AppService,
        private versionsSrv: VersionsService){}
    
    ngOnInit(): void {
        if(this.event != null){
            this.init();
        }
    }

    ngOnDestroy(): void {
        if(this.notesSubscriptor)
            this.notesSubscriptor.unsubscribe();
        if(this.feedbacksSubscriptor)
            this.feedbacksSubscriptor.unsubscribe();
        if(this.subscriptor)
            this.subscriptor.unsubscribe();
    }

    private init() : void {
        // set prefix url for messages...
        if(this.event.type == this.MESSAGE_ADDED || this.event.type == this.MESSAGE_REMOVED )    
            this.messagePrefixUrl = '/requests/'+ this.properties.request.id;
        // get notes in case of files
        if(this.event.type == this.FILE_ADDED || this.event.type == this.FILE_REMOVED) { 
            // we need order here for multiple items on menu
            this.menu = [
                {
                    title: "Download",
                    key: "download",
                    icon: "download",
                    order: 1
                }];
            // only if event action is not removed user can see notes or open file
             // file must comes with active property
            if(this.event.type != this.FILE_REMOVED && this.properties.file.active){
                this.menu.push({
                    title: "See Notes",
                    key: "notes",
                    icon: "notes",
                    order: 2
                });
            }
            
            this.notesSubscriptor = this.logbookSrv.getProjectFilesNotes$(this.properties.request.id).subscribe((notes: Objects.Note[]) => {
                this.notes = notes.filter((n:  Objects.Note) => n.file.id == this.properties.file.id).length;
            })

        }

        // in case of version added
        if(this.event.type == this.ARTWORK_ADDED){
            // get plugins access
            this.subscriptor = this.app.getSession().subscribe( (session: any) => {
                this.feedbackPluginAccess = session.options.plugins.some((pa) => pa == this.ACCESS_REQUEST_FEEDBACK);
                this.loadFeedbacks([this.properties.version]);
            });
        }
    }

    onClickPreview(file: Objects.File) : void {
        // prevent action for removed or future removed files 
        // file comes with active property: || !this.properties.file.active
        if(this.event.type == this.FILE_REMOVED || !this.properties.file.active)
            return;
        
        if (file != null && this.properties.request != null){
            this.appRouter.navigate('/projects/'+this.properties.request.id + "/files/"+file.id);
        }        
    }

    onClickVersionPreview(file: Objects.File){
        if (file != null && this.properties.version != null){
            this.appRouter.navigate('/projects/'+this.properties.request.id + '/versions/'+this.properties.version.id + "/files/"+file.id);
        }
    }

    onMenuClick(menu: FilesListItemMenuClick) : void {
        switch(menu.key) {
            case "notes":
                this.onClickPreview(menu.file);
                break;
            case "download":
                window.location.href = this.urlSrv.build('/files/'+menu.file.id);
                break;
        }
    }

    onVersionMenuClick(menu: VersionListItemMenuClick) {
        // console.debug(" menu original click",menu);
        switch(menu.key) {
            case "details":
                // url to go to Artwork Details
                this.appRouter.navigate('/projects/'+this.properties.request.id + "/versions/"+menu.version.id);
                break;
        }
    }

    protected loadFeedbacks(versions: Array<Objects.Version>) {
        if(!this.feedbackPluginAccess) {
            // this.status = this.STATUS_DONE;
            return;
        }

        this.feedbacksSubscriptor = this.versionsSrv.getFeedbacks$(versions).subscribe((feedbacks: Array<VersionFeedback>) => {
            console.log("GET FEEDBACKS ARE ", feedbacks)
            this.feedbacks = feedbacks.filter((f: VersionFeedback) => f.version.id == this.properties.version.id);
                // this.status = this.STATUS_DONE;
        });
    }
}