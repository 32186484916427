//import { routerReducer } from '@ngrx/router-store';
// import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { ActionReducer, combineReducers } from '@ngrx/store';

import * as fromSettingsUsers from './users/users.reducer';
import * as fromSettingsGroups from './groups/groups.reducer';
import * as fromSettingsLicenses from './licenses/licenses.reducer';
import * as fromSettingsRules from './rules/rules.reducer';

import { Settings } from './settings.state';

const reducers = {
    users: fromSettingsUsers.settingsUsersReducer,
    groups: fromSettingsGroups.settingsGroupsReducer,
    licenses: fromSettingsLicenses.settingsLicensesReducer,
    rules: fromSettingsRules.settingsRulesReducer
};

const reducer: ActionReducer<Settings> = combineReducers(reducers);

function settingsReducer(state: any, action: any) {
    return reducer(state, action);
}

export { settingsReducer } 

// export const metaReducers: MetaReducer<Root>[] = [];
