import { Component, Input, Output, EventEmitter } from '@angular/core';
// import { UrlService } from '@core/services/url/url.service';
// import { Subscription } from 'rxjs';

// import { VersionsService as CoreVersionsService } from '@core/services/versions/versions.service';


import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';
// import { Router } from '@angular/router';

// import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
// import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';


@Component({
    selector: 'core-version-feedbacks-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class VersionFeedbacksItemComponent {
    
    @Input() feedback: VersionFeedback;
    @Input() user: Objects.User = null; //current user.
    @Output() action = new EventEmitter<VersionFeedback>();

    public readonly FEEDBACK_COLORS = ['#F6B273','#47B934','#F44848'];
    public readonly FEEDBACK_ONLY_COMMENT_COLOR = 'silver';
    //1-> Approved: 47B934 (old'#92D77D',)
    //2-> Rejected: F44848   (old'#C8494E', )
    //3-> only comments: '#C8494E', ????

    // maybe
    // public menu: Array<ThreeDotsMenuItem> = [
    //     {
    //         title: "Open feedback",
    //         key: "open",
    //         icon: "open"
    //     }
    // ];

    openFeedback(feedback: VersionFeedback){
        console.log("click on feedback ",feedback);
        this.action.emit(feedback);
        // this.router.navigate(['/p/feedback/versions/'+feedback.version.id + "/users/"+feedback.user.id]);
    }

    public getApprovalColor(approval: Objects.Approval){
        if (approval != null)
            return this.FEEDBACK_COLORS[approval.status_code];
        return this.FEEDBACK_ONLY_COMMENT_COLOR;
    }

    // onMenuClick(key: string) {
    //     switch(key) {
    //         case "open":
    //             console.debug(" open click on option ", key)
                
    //             break;
    //         // bla bla bla
    //     }
    // }


}
