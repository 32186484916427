import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRouterModule } from '@app/router.module';

import { SettingsAccessTokensMainComponent } from './main/main.component';
import { SettingsAccessTokenAddFormComponent } from './add-form/add-form.component';
import { SettingsAccessTokensNewComponent } from './new-access-token/new-access-token.component';



@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SettingsAccessTokensMainComponent,
        SettingsAccessTokenAddFormComponent,
        SettingsAccessTokensNewComponent
    ],
    exports: [
        SettingsAccessTokensMainComponent,
        SettingsAccessTokenAddFormComponent,
        SettingsAccessTokensNewComponent
    ],

})
export class SettingsAccessTokensModule { }
