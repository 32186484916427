import { Component, Input } from '@angular/core';

@Component({
    selector: 'core-helpers-box-http-error',
    templateUrl: './http-error.component.html',
    styleUrls: ['./http-error.component.scss']
})
export class HelperBoxHttpErrorComponent {

    @Input() code:number = 0;
    @Input() message:string;
}
