import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { AppService } from '@app/services/app/app.service';
import { ProjectsService } from '@app/services/app/projects.service';

import { TitleService } from '@core/services/title.service';

import * as Objects from '@core/objects';

@Component({
    selector: 'project-details-logbook-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectDetailsLogbookMainComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    public status: number = 0;
    public showAdd: boolean = false;
    public project: Objects.Request = null;
    public user: Objects.User;
    public events: Array<Objects.Event> = null;


    public breadcrumbs: any = [
        {
            title: "",
            link: ['']
        }
    ];

    protected subscriptor: Subscription;
    protected eventsSubscriptor: Subscription;
    protected versionsSubscriber: Subscription;

	constructor(
        public appSrv: AppService, 
        private projectsSrv: ProjectsService,
        private title: TitleService
    ){}

	ngOnInit(){
        this.title.set("Logbook - Project Details - Project#...");

        this.appSrv.getSession().subscribe(session => {
            this.user = session.user;
        });
        // get request
        this.subscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.Request ) => {
            if(project == null) return;
            
            this.setProject(project);
        });

        // get versions uploaded - needed when we go to logbook directly, versions are not loaded yet.
        this.versionsSubscriber = this.projectsSrv.selectUploadedVersions().subscribe((versions: Array<Objects.Version>) => {
            if (versions != null){
                // console.log(" We have uploaded versions so, we load events ")
                this.projectsSrv.loadProjectEvents();
            } else {
                // console.log(" We have NOT uploaded VERSIONS yet, we load them first ")
                this.projectsSrv.loadUploadedVersions();
            }
        });

        this.eventsSubscriptor = this.projectsSrv.selectProjectEvents().subscribe((events: Objects.Event[] ) => {
            this.events = events;
            this.init();
        });
    }

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
        this.eventsSubscriptor.unsubscribe();
        this.versionsSubscriber.unsubscribe();
    }
    

    protected setProject(project: Objects.Request) {
        this.updateBreadcrumbs(project);
        this.project = project;
        this.title.set("Logbook - Project "+this.project.id);
        this.init();
    }

    protected init(){
        if (this.project == null || this.events == null)
            return;
        this.status = this.STATUS_DONE;
    }

    protected updateBreadcrumbs(project: Objects.Request){
        this.breadcrumbs[0].link = '/projects/'+project.id;
        this.breadcrumbs[0].title = 'Project <small>(#'+project.id+ ')</small>';
    }

}
