import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { map } from 'rxjs/operators';
import { UrlService } from '@core/services/url/url.service';
import { User } from '@core/objects/user';

import { Session } from '@app/store/models/session.model';

@Injectable({
  providedIn: 'root'
})
export class AppRequests {
    private readonly session = "/session";
    private readonly access = "/access";
    private readonly users = "/users";

    constructor(private http: HttpClient, private urlSrv : UrlService) {}

    public getSession$ = (): Observable<Session> => this.http.get<Session>(this.urlSrv.build(this.session));
    // public changeOrganization$ = (organization: any): Observable<string> => {
    //   let org = {
    //     organization: +organization
    //   }
    //   return this.http.put(this.urlSrv.build(this.session), org, {responseType: 'text'})};

    public changeOrganization$ = (organization: any): Observable<string> => {
        let org = +organization;
        return this.http.get(this.urlSrv.build("/login/into/"+org), {responseType: 'text'})
    };
    // public getAccess$ = (event: string): Observable<Session> => this.http.get<Session>(this.urlSrv.build(this.access + "/" + event));

    public updateUser$ = (user: User, name: string, telephone: string, position: string): Observable<User> => {
        let data = {
            name: name,
            telephone: telephone != null ? telephone: '',
            position: position != null ? position: ''
        };

        return this.http.put<User>(this.urlSrv.build(this.users + "/"+ user.id), data);
    }
}
