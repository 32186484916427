import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { UrlService } from '@core/services/url/url.service';
//import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as fromApolloState from '../../store/state/apollo/apollo.state';
//import { Session } from '../../store/models/session.model';
import * as fromApolloActions from '../../store/state/apollo/apollo.actions';
import { ApolloItem } from '../../model/apollo-item.model';
import { ApolloFilter } from '../../model/apollo-filter.model';
import { ApolloRequest } from '../../model/apollo-request.model';
import { ApolloGraphicComparison } from '../../model/apollo-graphic-comparison.model';
import { ApolloContentComparison } from '../../model/apollo-content-comparison.model';

import { WebsocketService } from '@core/services/websocket/websocket.service';

@Injectable({
    providedIn: 'root'
})
export class ApolloService {
    readonly STATUS = {
        none: 0,
        done: 1,
        ready: 2
    };

    protected disabled = new EventEmitter<boolean>();
    protected websocketSubscriptor;

    protected wsSub;

    protected hashRequest:string;

    private graphicStatus: number = this.STATUS.none;


    private urlPathPlugin = '/app/p/apollo/';

    constructor(private store: Store<fromApolloState.Apollo>, private urlSrv : UrlService, public ws: WebsocketService) {
        // check if is a new request
        /*this.getRequest().subscribe((request: ApolloRequest) => {
            console.debug("apollo service request listener--->", request);
            // TODO check if:
            // new files

            // si no tiene hash y tiene dos ficheros, hacer

            if(request.target.file != null && request.reference.file != null && request.hash == null)
            {
                this.store.dispatch(new fromApolloActions.LoadRequest(request));
            }
        })*/

        this.ws.subscribe("apollo.graphic", data => {
            this.checkAndGetComparison(data.hash, "graphic");
        });

        this.ws.subscribe("apollo.content", data => {
            this.checkAndGetComparison(data.hash, "content");
        });

        /*this.websocketSubscriptor = this.ws.subscribe("apollo.graphic_ready", data => {

            this.readyGraphic(data);
        });*/
    }

    public getUrlPathPlugin() {
        return this.urlPathPlugin;
    }

    // public cleanAnchors(routeComponent: string) {
    //     // let newUrl = document.URL.replace(/#.*$/, "")
    //     // window.location.href = newUrl
    //     parent.location.hash = "";
    // }

    public getApollo() {
        return this.store.select(fromApolloState.apolloSelector);
    }

    public getMenu() {
        return this.store.select(fromApolloState.apolloTabsSelector);
    }

    public activeMenuItem(title: string ){
        this.store.dispatch(new fromApolloActions.ActiveTabsItem(title));
    }

    public deactiveMenuItem(title: string ){
        this.store.dispatch(new fromApolloActions.DeactiveTabsItem(title));
    }

    public setDisable(disabled: boolean){
        this.disabled.emit(disabled);
    }

    public getDisable(){
        return this.disabled;
    }

    public setMenu(tabs){
        this.store.dispatch( new fromApolloActions.ChangeTabsItemRequest(tabs));
    }

    // @deprecated
    public getRequest() {
        return this.store.select(fromApolloState.apolloRequestSelector);
    }

    public selectRequest() {
        return this.store.select(fromApolloState.apolloRequestSelector);
    }

    public setRequest(request: ApolloRequest) {
        console.debug("DISPATCHIN LOAD REQUEST--->", request);
        this.hashRequest = request.hash;
        this.load();
    }

    public loadSavedRequest(hash: string) {
        this.store.dispatch( new fromApolloActions.LoadSavedRequest(hash));
    }

    public loadGraphicComparison(graphic: ApolloGraphicComparison) {
        if(!graphic) {
            console.debug("request load graphic comparison");
            return this.load('graphic');
            //return this.store.dispatch( new fromApolloActions.GetGraphicComparison);
        }
    }

    public load(type?: string) {
        this.store.dispatch( new fromApolloActions.AddRequest(type));
    }

    public reset() {
        this.store.dispatch( new fromApolloActions.ResetRequest());
    }

    public setStoreRequest(request: ApolloRequest){
        this.store.dispatch ( new fromApolloActions.AddRequestSuccess(request));
    }

    // GRAPHIC COMPARISON
    public setGraphicComparison(apolloGraphic: ApolloGraphicComparison, hash: string) {
        console.debug("DISPATCHIN LOAD GRAPHIC COMPARISON--->", apolloGraphic);
        this.store.dispatch( new fromApolloActions.GetGraphicComparison());
    }

    public getGraphicComparison(){
        this.store.dispatch( new fromApolloActions.GetGraphicComparison);
    }

    public setResolutionGraphicComparison(resolution: number) {
        this.store.dispatch( new fromApolloActions.UpdateResolutionGraphicComparison(resolution));
    }
    // END GRAPHIC COMPARISON


    protected saveComparison() {

    }


    public selectorGraphicCompare(){
        return this.store.select(fromApolloState.apolloGraphicCompareSelector);
    }
    public setImageCompareGraphic(idImage) :string{
        return this.urlSrv.build("/files/"+idImage+"?f=inline");
    }




    //protected session : Session;
    /*public sendFile(file:any){
        let options = { headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};
        this.http.post(this.urlSrv.build("/app/files"), file, options).subscribe(data => {
            console.log(data);
        });
    }*/
    // Reference
    public setItemReferenceRequest(reference: ApolloItem ){
        this.store.dispatch(new fromApolloActions.SetItemReferenceRequest(reference));
    }

    public getItemReference(){
        return this.store.select(fromApolloState.apolloReferenceSelector);
    }


    // Target
    public setItemTargetRequest(target: ApolloItem){
        this.store.dispatch(new fromApolloActions.SetItemTargetRequest(target));
    }

    public getItemTarget(){
        return this.store.select(fromApolloState.apolloTargetSelector);
    }


    // Files store
    public getFileReference(){
        return this.store.select(fromApolloState.apolloFileReferenceSelector);
    }

    public getFileTarget(){
        return this.store.select(fromApolloState.apolloFileTargetSelector);
    }

    /*public setFileReference(reference: any){
        return this.store.dispatch(new fromApolloActions.SetFileReference(reference));
    }

    public setFileTarget(target: any){
        return this.store.dispatch(new fromApolloActions.SetFileTarget(target));
    }*/

    public setFile(name: string, file: any, item:any){
        if(file == null) {
            // this.setItemRequest(name, null);
            return this.removeItem(name);
        }

        this.setItemRequest(name, item);

        switch(name) {
            case "reference":
                return this.store.dispatch(new fromApolloActions.SetFileReference(file));

            case "target":
                return this.store.dispatch(new fromApolloActions.SetFileTarget(file));
            default:
                console.error("not property avalaible", name, file);
        }
    }


    public setItemRequest(name: string, value: ApolloItem) {
        return this.store.dispatch(new fromApolloActions.SetItemRequest(name, value));
    }

    //Register
    public setRegisterLayer(registerType: string, registerLayer: string){
        this.store.dispatch(new fromApolloActions.RegisterGraphicComparison(registerType, registerLayer));
    }


    // Compare
    public getGraphicCompare(){
        this.store.dispatch(new fromApolloActions.CompareGraphicComparison());
    }

    public removeItem(name: string) {
        this.store.dispatch( new fromApolloActions.RemoveItemRequest(name));
    }

    public addItemFilter(name: string, filter: ApolloFilter) {
        this.store.dispatch( new fromApolloActions.AddItemFilterRequest(name, filter));
    }

    public selectItemPage(name: string, page: number) {
        this.store.dispatch( new fromApolloActions.SelectItemPageRequest(name, page));
    }

    public getStoreProperty(name: string){
        switch(name) {
            case "reference":
                return this.getItemReference();
            case "target":
                return this.getItemTarget();
        }
    }

    public getFileStoreProperty(name: string) {
        switch(name) {
            case "reference":
                return this.getFileReference();
            case "target":
                return this.getFileTarget();
        };
    }

    protected checkAndGetComparison(hash: string, typeComparison: string){
        this.store.dispatch(new fromApolloActions.CheckAndGetComparison(hash, typeComparison));
    }

    public loadFile(type: string, id:string) {
        this.store.dispatch( new fromApolloActions.LoadFile(type, id));
    }

    // Content
    public selectorContentCompare(){
        return this.store.select(fromApolloState.apolloContentCompareSelector);
    }

    /*public selectorContentStatus(){
        return this.store.select(fromApolloState.apolloContentStatusSelector);
    }*/

    public loadContentComparison(content?: ApolloContentComparison) {
        if(!content) {
            console.debug("request load content comparison");
            return this.load('content');
            //return this.store.dispatch( new fromApolloActions.GetGraphicComparison);
        }
    }

    public updateExtractComparison(data, type){
        this.store.dispatch(new fromApolloActions.UpdateContentComparisonExtract(data, type));
    }

    public updateContentComparison(method){
        this.store.dispatch(new fromApolloActions.UpdateContentComparison(method));
    }

    public getContentCompare(){
        this.store.dispatch(new fromApolloActions.GetContentCompare());
    }

    public selectHistory() {
        return this.store.select(fromApolloState.apolloHistorySelector);
    }

    public loadHistory(page: number) {
        this.store.dispatch(new fromApolloActions.GetHistory(page));
    }

    public setNameCompareHistory(hash: string, name: string) {
        this.store.dispatch(new fromApolloActions.SetNameToCompareHistory(hash, name));
    }
}
