import { Component } from '@angular/core';
import { environment } from '@environment/environment';

@Component({
    selector: 'app-disconnected',
    templateUrl: './disconnected.component.html',
    styleUrls: ['./disconnected.component.scss']
})
export class AppDisconnectedComponent {

    goToLogin(go) {
        // TODO
        window.location.href = environment.loginBasePath + '?href='+window.location.href;
    }

}
