import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'core-helpers-forms-select-color',
  templateUrl: './select-color.component.html',
  styleUrls: ['./select-color.component.scss'],
})
export class SelectColorComponent implements OnInit, OnChanges {

    // @Input() disabled: boolean;

    @Input() colors: Array<string> = [];
    @Input() disabled: boolean = false;


    @Input() colorSelected:string = null;
    @Output() colorSelectedChange = new EventEmitter<string>();

    ngOnInit() {
    }

    select(color: string) {
        // console.log("color", color);
        if(this.disabled)
            return;

        this.colorSelected = color;
        this.colorSelectedChange.emit(this.colorSelected);
    }

    ngOnChanges(changes: SimpleChanges){
        // if(changes.options) {
        //     this.selected = this.getValueSelected();
        // }
    }

    onChange(value: any){
        // let option = this.options.filter((o: SelectItem)=> {
        //     return o.value == value;
        // });

        // this.select.emit(option[0]);
    }
}
