import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

//import { UrlService } from '@core/services/url/url.service';

import { ApolloGraphicLayer } from '../../../model/apollo-graphic-layer.model';
import { ApolloGraphicComparison } from '../../../model/apollo-graphic-comparison.model';

import { opacityOptions } from '../../../data/opacity';

import { Subscription } from 'rxjs';
import { ApolloService } from '../../../services/apollo/apollo.service';

@Component({
    selector: 'plugin-apollo-helpers-graphic-layers',
    templateUrl: './graphic-layers.component.html',
    styleUrls: ['./graphic-layers.component.scss']
})
export class PluginApolloHelpersGraphicLayersComponent implements OnInit, OnChanges {
    readonly REFERENCE_LAYER_KEY = "reference";
    readonly TARGET_LAYER_KEY = "target";
    readonly COMPARED_LAYER_KEY = "compared";

    @Input() layers: ApolloGraphicLayer[];
    @Output() layersChange = new EventEmitter<ApolloGraphicLayer[]>();
    @Output() registerLayer = new EventEmitter<Object>();

    public reference: ApolloGraphicLayer;
    public target: ApolloGraphicLayer;
    public compared: ApolloGraphicLayer;

    public selected: string = null;

    public opacityOptions = {
        reference: [...opacityOptions],
        target: [...opacityOptions],
        compared: [...opacityOptions]
    };

    public subscriptionGraphic : Subscription;

    // public controlBtn:any = {
    //     reference: {
    //         active: true,
    //         text: "Register"
    //     },
    //     target : {
    //         active: true,
    //         text: "Register"
    //     }
    // };
    public apolloGraphic: ApolloGraphicComparison;

    constructor(public apolloSrv: ApolloService){}

    ngOnInit() {
        // this.subscriptionGraphic = this.apolloSrv.selectorGraphicCompare().subscribe( (apolloGraphic : ApolloGraphicComparison) => {
        //     this.apolloGraphic = apolloGraphic;
        //     if (apolloGraphic) {
        //         this.controlButtonsRegister();
        //     }
        // });
        console.log('test layers ---- ', this.layers);
        this.setLayers();

        // init opacities
        if(this.reference != null)
            this.setSelectedOpacity('reference', this.reference.opacity);

        if(this.target != null)
            this.setSelectedOpacity('target', this.target.opacity);

        if(this.compared != null)
            this.setSelectedOpacity('compared', this.compared.opacity);

    }

    ngOnChanges(changes: SimpleChanges){
        this.setLayers();
    }

    onLayersChange() {
        this.layersChange.emit(this.layers);
    }

    protected setLayers() {
        let layers = [];

        layers = this.layers.filter(l => { return l.key == this.REFERENCE_LAYER_KEY; });
        this.reference = layers.length > 0? layers[0]: null;

        layers = this.layers.filter(l => { return l.key == this.TARGET_LAYER_KEY; });
        this.target = layers.length > 0? layers[0]: null;

        layers = this.layers.filter(l => { return l.key == this.COMPARED_LAYER_KEY; });
        this.compared = layers.length > 0? layers[0]: null;

    }

    select(key: string) {
        if(this.selected == key) {
            this.toogleVisibility(key);
        }

        this.selected = key
    }

    // onRegisterLayer(){
    //     let controlLayerRegister = this.apolloGraphic.register_layer;
    //     let emitData = {
    //         layer: this.selected,
    //         type: controlLayerRegister
    //     }
    //     this.registerLayer.emit(emitData);
    // }

    protected toogleVisibility(key: string) {
        // if only have 1 visible and want to hidden can't
        let visibles = this.layers.filter( l => { return l.visible; });
        if(visibles.length == 1 && visibles[0].key == key)
            return false;
        // end of check

        this.layers = this.layers.map(l => {
            if(l.key == key)
                l.visible = !l.visible;

            return l;
        });

        this.onLayersChange();
    }

    opacitySelect(key: string, option: any) {
        this.layers = this.layers.map(l => {
            if(l.key == key)
                l.opacity = option.value;

            return l;
        });

        this.setSelectedOpacity(key, option.value);
    }

    protected setSelectedOpacity(key: string, value: number) {
        let opacities = [...this.opacityOptions[key]];
        opacities = opacities.map(o => {
            return {...o, selected: o.value == value};
        });

        this.opacityOptions[key] = opacities;
    };

    // controlButtonsRegister(){
    //     switch(this.apolloGraphic.register_layer) {
    //         case "reference":
    //             return this.controlBtn = {
    //                 reference: {
    //                     active: true,
    //                     text: "Unregister"
    //                 },
    //                 target: {
    //                     active: false,
    //                     text: ""
    //                 }
    //             }
    //         case "target":
    //             return this.controlBtn = {
    //                 reference: {
    //                     active: false,
    //                     text: ""
    //                 },
    //                 target: {
    //                     active: true,
    //                     text: "Unregister"
    //                 }
    //             }

    //         default:
    //             return this.controlBtn = {
    //                 reference: {
    //                     active: true,
    //                     text: "Register"
    //                 },
    //                 target : {
    //                     active: true,
    //                     text: "Register"
    //                 }
    //             }
    //     }
    // }
    //previewUrl(layer: ApolloGraphicLayer) {
    //    return this.urlSrv.build("/files/" + layer.id_file + "/preview/small-height/1");
    //}
}
