import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HelpersSearchBarMainComponent } from './main/main.compontent';
import { AppHelpersSearchBarFieldsModule } from './fields/fields.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AppHelpersSearchBarFieldsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        HelpersSearchBarMainComponent
    ],
    exports: [
        HelpersSearchBarMainComponent
    ],

})
export class AppHelpersSearchBarModule { }