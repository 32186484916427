import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { CoreModule } from '@core/core.module';

import { AvatarModule } from '@core/components/avatar/avatar.module';
import { HelperFormModule } from '@core/components/helpers/form/form.module';

import { HelperIconsModule } from '../icons/icons.module';

import { HelperBoxHeaderUserDateComponent } from './header-user-date/header-user-date.component';
import { HelperBoxAlertButtonComponent } from './alert-button/alert-button.component';
import { HelperBoxAlertTryAgainComponent } from './alert-try-again/alert-try-again.component';
import { HelperBoxSuccessComponent } from './success/success.component';
import { HelperBoxLoadingComponent } from './loading/loading.component';
import { HelperBoxHttpErrorComponent } from './http-error/http-error.component';
import { HelperBoxCardComponent } from './card/card.component';
import { HelperBoxListItemComponent } from './list-item/list-item.component';
import { HelperBoxStatusMessageComponent } from './status-message/status-message.component';
import { HelperBoxStatusMainInsctructionsComponent } from './main-instructions/main-instructions.component';
import { HelperBoxUpdatedRibbonComponent } from './updated-ribbon/updated-ribbon.component';
import { HelperBoxDoneCloseTabComponent } from './done-close-tab/done-close-tab.component';
import { HelperBoxUserEmailComponent } from './user-email/user-email.component';
import { HelperBoxNotificationMessageComponent } from './notification-message/notification-message.component';
import { HelperBoxHeaderUserAsigneeComponent } from './header-user-assignee/header-user-assignee.component';
import { HelperBoxAlertMessageIconComponent } from './alert-message-icon/alert-message-icon.component';
import { HelperBoxDisconnectedRibbonComponent } from './disconnected-ribbon/disconnected-ribbon.component';


@NgModule({
    imports: [
        CommonModule,
        AvatarModule,
        HelperFormModule,
        HelperIconsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        HelperBoxHeaderUserDateComponent,
        HelperBoxAlertButtonComponent,
        HelperBoxAlertTryAgainComponent,
        HelperBoxLoadingComponent,
        HelperBoxSuccessComponent,
        HelperBoxHttpErrorComponent,
        HelperBoxCardComponent,
        HelperBoxListItemComponent,
        HelperBoxStatusMessageComponent,
        HelperBoxStatusMainInsctructionsComponent,
        HelperBoxUpdatedRibbonComponent,
        HelperBoxDoneCloseTabComponent,
        HelperBoxUserEmailComponent,
        HelperBoxNotificationMessageComponent,
        HelperBoxHeaderUserAsigneeComponent,
        HelperBoxAlertMessageIconComponent,
        HelperBoxDisconnectedRibbonComponent
    ],
    exports: [
        HelperBoxHeaderUserDateComponent,
        HelperBoxAlertButtonComponent,
        HelperBoxAlertTryAgainComponent,
        HelperBoxLoadingComponent,
        HelperBoxSuccessComponent,
        HelperBoxHttpErrorComponent,
        HelperBoxCardComponent,
        HelperBoxListItemComponent,
        HelperBoxStatusMessageComponent,
        HelperBoxStatusMainInsctructionsComponent,
        HelperBoxUpdatedRibbonComponent,
        HelperBoxDoneCloseTabComponent,
        HelperBoxUserEmailComponent,
        HelperBoxNotificationMessageComponent,
        HelperBoxHeaderUserAsigneeComponent,
        HelperBoxAlertMessageIconComponent,
        HelperBoxDisconnectedRibbonComponent
    ],

})
export class HelperBoxModule { }
