import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SelectItem } from '@core/models/select-item.model';

import { SettingsRulesService, SettingsRulesEditorService } from '@app/services/settings/srules.service';
import { RuleValidator } from '../rule-validator';

import { AvailableRuleItem, RuleEvent, RuleAction, AvailableRuleAction } from '@app/models/rules';
import { AvailableRule } from '@app/store/state/settings/rules/rules.state';

// import { TitleService } from '@core/services/title.service';

@Component({
    selector: 'settings-rules-rule-form',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SettingsRulesRuleFormMainComponent implements OnInit, OnDestroy  {

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

    // @Input() selectedRule: any = null;
    // @Input() availableEvents: Array<AvailableRuleItem> = [];

	@Output() cancel = new EventEmitter<boolean>();
	@Output() submit = new EventEmitter<RuleEvent>();

	public status: Number = 0;
    public rule: RuleEvent = {
        id: 0,
        name: "",
        key: "",
        conditions_control: "OR",
        conditions: [],
        actions: []
    };


    public availableEvents: Array<AvailableRuleItem> = [];
    public buttonDisabled: boolean = false;

	// public selectedEvent: any = '';
	public saveBtntext: string = 'Save';

    public ruleReady: boolean = false;
    protected validator: RuleValidator = new RuleValidator();

    public form: FormGroup;
    protected description: string = "";
    // public creator: RuleCreator = new RuleCreator();

	private editingSubscriptor: Subscription;
    private availablesSubscriptor: Subscription;

	constructor(public srulesSrv: SettingsRulesService, public editorSrv: SettingsRulesEditorService, private formBuilder: FormBuilder){}

	ngOnInit(){
        console.log("reload main");
        this.form = this.formBuilder.group({
            name: ['', [Validators.required]]
		});

        this.editingSubscriptor = this.srulesSrv.selectEditing().subscribe((edit: RuleEvent) => {
            this.rule = edit;
            this.form.controls['name'].setValue(this.rule.name);

            console.log("rule edit updated--->", this.rule);
            this.ruleReady = this.validator.isValid(this.rule);
            this.buttonDisabled = false;
            this.saveBtntext = "Save";
        });

        this.availablesSubscriptor = this.srulesSrv.selectAvailableEvents().subscribe((availables: AvailableRule[] ) => {
            this.availableEvents = availables.map((a: AvailableRule) => a.event);
            this.status = availables.length > 0? this.STATUS_DONE : this.STATUS_LOADING;
        });


        // detect change in name in form
        this.form.get("name").valueChanges.subscribe( (value: string) => {
            if(this.description != value) {
                // console.log('changeeeeee',value);
                this.editorSrv.setDescription(value);
                this.description = value;
            }
        });
    }

    ngOnDestroy() {
        this.editorSrv.clear();
        this.editingSubscriptor.unsubscribe();
        this.availablesSubscriptor.unsubscribe();
    }

	onCancel() {
        this.cancel.emit(true);
        // this.creator.reset();
        this.editorSrv.clear();
        this.cleanForm();
	}

    onSubmit(){
        this.saveBtntext = "Saving...";
        this.buttonDisabled = true;
        this.editorSrv.save();
    }


    cleanForm(){
        this.form.reset({name: ''});
    }


	private selectEvent(event) {
        // console.log("keyyyyy--->", event);
        this.editorSrv.setEventKey(event.key);
	}
}
