import { SelectItem } from '@core/models/select-item.model';

export const resolutionOptions : SelectItem[] = [
    {
        title: '150ppp',
        value: '150',
        selected: true
    },
    {
        title: '300ppp',
        value: '300',
        selected: false
    },
    {
        title: '450ppp',
        value: '450',
        selected: false
    },
    {
        title: '600ppp',
        value: '600',
        selected: false
    }
];
