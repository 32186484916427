import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, empty, of, throwError, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { ApolloRequest } from '../../../model/apollo-request.model';
import { ApolloHistory } from '../../../model/apollo-history.model';
import { ApolloCompare } from '../../../model/apollo-compare.model';
import { ApolloRequests } from '../../../services/apollo/apollo-requests.service';
//import { Session } from '@app/store/models/session.model';
//import { AccessItem } from '@app/store/models/access-item.model';
import { ApolloGraphicComparison } from '../../../model/apollo-graphic-comparison.model';
import { ApolloContentComparison, ApolloContentComparisonExtract } from '../../../model/apollo-content-comparison.model';

import * as fromStore from './apollo.state';
import { ApolloItem } from '../../../model/apollo-item.model';


import {
    ApolloActionTypes,
    ApolloActions,
    AddRequest,
    AddRequestSuccess,
    LoadSavedRequest,
    LoadSavedRequestSuccess,
    //LoadGraphicComparison,
    CheckAndGetComparison,
    GetGraphicComparison,
    SetGraphicComparison,
    UpdateResolutionGraphicComparison,
    RegisterGraphicComparison,
    CompareGraphicComparison,
    LoadFile,
    LoadFileSuccess,
    GetContentComparison,
    GetContentComparisonSuccess,
    SetContentComparison,
    UpdateContentComparisonExtract,
    OnUpdateContentComparisonExtract,
    UpdateContentComparisonExtractError,
    UpdateContentComparison,
    GetContentCompare,
    RemoveContentCompare,
    GetHistory,
    UpdateHistory,
    SetNameToCompareHistory,
    UpdateNameToCompareHistory
} from './apollo.actions';

@Injectable()
export class ApolloEffects {

    constructor(private actions$: Actions, private store$: Store<fromStore.Apollo>, private apolloReq: ApolloRequests) {}


    @Effect()
    public addRequestEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<AddRequest>(ApolloActionTypes.AddRequest),
        withLatestFrom(this.store$.select(fromStore.apolloRequestSelector)),
        mergeMap(([action, request]) => {
            console.debug("enter in distinctUntilChanged");
            //console.log('in course effects', action.request);
            if(request.target == null || request.reference == null) {
                /// return to upload or whathever
                console.warn("haven't target or reference!");
                //return false;
                //return throwError("error: ");
                return empty();
            }

            if (request.hash) { // with hash
                //console.log('PUT Event');
                return this.apolloReq.updateCompare$(request).pipe(
                    map((request: ApolloRequest) => new AddRequestSuccess(request, action.event)),
                    catchError((err: any) => {
                        // TODO fix
                        console.log(err);
                        window.location.href = '/app/p/apollo/upload';
                        return throwError(err);
                    })
                );

            } else {

                console.log('POST Event');
                return this.apolloReq.setCompare$(request).pipe(
                    map((request: ApolloRequest) => new AddRequestSuccess(request, action.event)),
                    catchError((err: any) => {
                        // TODO fix
                        console.log(err);
                        window.location.href = '/app/p/apollo/upload';
                        return throwError(err);
                    })
                );
            }
        })
    );


    @Effect()
    public addRequestSuccessEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<AddRequestSuccess>(ApolloActionTypes.AddRequestSuccess),
        mergeMap((action): Observable<any> => {
            switch(action.event) {
                case 'graphic':
                    return of(new GetGraphicComparison());
                case 'content':
                    return of(new GetContentComparison());
            };

            console.warn("event not found", action);
            return empty();
        })
    );

    @Effect()
    public loadSavedRequestEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<LoadSavedRequest>(ApolloActionTypes.LoadSavedRequest),
        mergeMap((action): Observable<any> => {
            return this.apolloReq.getCompareByHash$(action.hash).pipe(
                map((request: ApolloRequest) => {
                    return new LoadSavedRequestSuccess(request);
                }),
                catchError((err: any) => {
                    console.log(err);
                    return throwError(err);
                })
            );
        })
    );


    @Effect()
    public checkAndGetComparisonEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<CheckAndGetComparison>(ApolloActionTypes.CheckAndGetComparison),
        withLatestFrom(this.store$.select(fromStore.apolloRequestSelector)),
        mergeMap(([action, request]): Observable<any> => {
            if(request.hash == action.hash) {
                switch(action.event) {
                    case 'graphic':
                        return of(new GetGraphicComparison());
                    case 'content':
                        return of(new GetContentComparison());
                };
            }

            return empty();
        })
    );

    // Graphic



    @Effect()
    public updateResolutionGraphicComparisonEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<UpdateResolutionGraphicComparison>(ApolloActionTypes.UpdateResolutionGraphicComparison),
        withLatestFrom(this.store$.select(fromStore.apolloRequestSelector)),
        mergeMap(([action, request]) => {
            console.log('sending new ppp ---->', action.resolution);
            return this.apolloReq.updateGraphic$(action, request.hash).pipe(
                map((graphicComparison: ApolloGraphicComparison) =>{ return new SetGraphicComparison(graphicComparison)}),
                catchError((err: any) => {
                    console.log(err);
                    return throwError(err);
                })
            );
        })
    );


    // GET graphic
    @Effect()
    public getGraphicComparisonEffect$: Observable <ApolloActions> = this.actions$.pipe(
        ofType<GetGraphicComparison>(ApolloActionTypes.GetGraphicComparison),
        withLatestFrom(this.store$.select(fromStore.apolloRequestSelector)),
        switchMap(([action, request]) => {
            console.log('in course effects', request.hash);
            return this.apolloReq.getGraphic$(request.hash).pipe(
                map((graphicComparison: ApolloGraphicComparison) => {return new SetGraphicComparison(graphicComparison)}),
                catchError((err: any) => {
                    console.log(err);
                    return throwError(err);
                })
            );
        })
    );

    // Graphic Register
    @Effect()
    public registerGraphicComparison$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<RegisterGraphicComparison>(ApolloActionTypes.RegisterGraphicComparison),
        withLatestFrom(this.store$.select(fromStore.apolloRequestSelector)),
        mergeMap(([action, request]) => {
            return this.apolloReq.updateRegisterGraphic$(action.registerType, action.registerLayer, request.hash).pipe(
                map((graphicComparison: ApolloGraphicComparison) => {return new SetGraphicComparison(graphicComparison)}),
                catchError((err: any) => {
                    console.warn('Register error');
                    console.log(err);
                    return throwError(err);
                })
            );
        })
    );


     // compare Graphic Compare
     @Effect()
     public compareGraphicComparison$: Observable<ApolloActions> = this.actions$.pipe(
         ofType<CompareGraphicComparison>(ApolloActionTypes.CompareGraphicComparison),
         withLatestFrom(this.store$.select(fromStore.apolloRequestSelector)),
         mergeMap(([action, request]) => {
             return this.apolloReq.getCompareGraphic$(request.hash).pipe(
                //  TODO CHANGE EVENT TO SetGraphicComparisonSuccess
                 map((graphicComparison: ApolloGraphicComparison) => {return new SetGraphicComparison(graphicComparison)}),
                 catchError((err: any) => {
                     console.log(err);
                     return throwError(err);
                 })
             );
         })

     );

    // LoadFile
    @Effect()
    public loadFile$: Observable <ApolloActions> = this.actions$.pipe(
        ofType<LoadFile>(ApolloActionTypes.LoadFile),
        mergeMap((action) => {
            return this.apolloReq.loadFile$(action.id).pipe(
                map((file: any) => {

                    let newItem = new ApolloItem;
                    newItem.id_file = file.id;
                    return new LoadFileSuccess(action.propertyName, file, newItem);
                }),
                catchError((err: any) => {
                    console.log(err);
                    return throwError(err);
                })
            );
        })
    );

    // content comparison
    @Effect()
    public getContentComparisonEffect$: Observable <ApolloActions> = this.actions$.pipe(
        ofType<GetContentComparison>(ApolloActionTypes.GetContentComparison),
        withLatestFrom(this.store$.select(fromStore.apolloRequestSelector)),
        switchMap(([action, request]) => {
            console.log('in course effects', request.hash);
            return this.apolloReq.getContent$(request.hash).pipe(
                map((contentComparison: ApolloContentComparison) => {
                    return new GetContentComparisonSuccess(contentComparison)
                }),
                catchError((err: any) => {
                    console.log(err);
                    return throwError(err);
                })
            );
        })
    );

    @Effect()
    public getContentComparisonSuccessEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<GetContentComparisonSuccess>(ApolloActionTypes.GetContentComparisonSuccess),
        mergeMap((action) => {
            return of(new SetContentComparison(action.apolloContent));
        })
    );

    @Effect()
    public updateContentComparisonExtractEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<UpdateContentComparisonExtract>(ApolloActionTypes.UpdateContentComparisonExtract),
        withLatestFrom(this.store$.select(fromStore.apolloRequestSelector)),
        mergeMap(([action, request]) => {
            //console.debug(action, request);
            return this.apolloReq.updateContentComparisonExtract$(action.comparisonExtract, action.typeFile, request.hash).pipe(
                map((contentComparison: ApolloContentComparison) =>{
                    //console.debug("content--->", contentComparison);
                    //return new OnUpdateContentComparisonExtract(contentComparison[action.typeFile+"_extract"], action.typeFile);
                    return new GetContentComparisonSuccess(contentComparison);
                }),
                catchError((err: any) => {
                    console.log(err);
                    // return of(new UpdateContentComparisonExtractError());
                    return throwError(err);
                })
            );
        })
    );

    @Effect()
    public updateContentComparisonEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<UpdateContentComparison>(ApolloActionTypes.UpdateContentComparison),
        withLatestFrom(this.store$.select(fromStore.apolloRequestSelector)),
        mergeMap(([action, request]) => {
            return this.apolloReq.updateContentComparison$(action.method, request.hash).pipe(
                map((contentComparison: ApolloContentComparison) =>{
                    of(new RemoveContentCompare());
                    return new GetContentComparisonSuccess(contentComparison);
                }),
                catchError((err: any) => {
                    console.log(err);
                    return throwError(err);
                })
            );
        })
    );

    @Effect()
    public getContentCompareEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<GetContentCompare>(ApolloActionTypes.GetContentCompare),
        withLatestFrom(this.store$.select(fromStore.apolloRequestSelector)),
        mergeMap(([action, request]) => {
            return this.apolloReq.getContentCompare$(request.hash).pipe(
                map((contentComparison: ApolloContentComparison) =>{
                    return new GetContentComparisonSuccess(contentComparison);
                }),
                catchError((err: any) => {
                    console.log(err);
                    return throwError(err);
                })
            );
        })
    );
    @Effect()
    public getHistoryEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<GetHistory>(ApolloActionTypes.GetHistory),
        mergeMap((action) => {
            return this.apolloReq.getHistory$(action.page).pipe(
                map((history: ApolloHistory) => {
                    return new UpdateHistory(action.page, history);
                }),
                catchError((err: any) => {
                    console.log(err);
                    return throwError(err);
                })
            );
        })
    );

    @Effect()
    public setNameToCompareHistoryEffect$: Observable<ApolloActions> = this.actions$.pipe(
        ofType<SetNameToCompareHistory>(ApolloActionTypes.SetNameToCompareHistory),
        mergeMap((action): Observable<any> => {
            return this.apolloReq.updateNameCompareHistory$(action.hash, action.name).pipe(
                map((compare: ApolloCompare) => {
                    return new UpdateNameToCompareHistory(compare);
                }),
                catchError((err: any) => {
                    console.log(err);
                    return throwError(err);
                })
            );
        })
    );
}
