import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { File } from '@core/objects/file';

@Injectable({
    providedIn: 'root'
})
export class AvatarService {

    constructor(protected urlSrv: UrlService, protected http: HttpClient) {}

    public deleteAvatar$(){
        return this.http.delete(this.urlSrv.build("/avatar"));
    }

    public saveAvatar$(fileId: any, fileOptions: any) {
        let options = {headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};
        let avatarData: any = {
            options: fileOptions,
            file_id: fileId
        };
        return this.http.post(
            this.urlSrv.build("/avatar"),
            avatarData,
            options
        );
    }

}
