import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { UrlService } from '@core/services/url/url.service';
import { ImageFilter } from '../../../models/image-filter.model';

class CropFilter implements ImageFilter {
    type: string = "crop";
    options: any = {
        top: 0,
        left: 0,
        width: 1,
        height: 1
    };
};

class ExcludeFilter implements ImageFilter {
    type: string = "exclude";
    options: Array<ExcludeFilterItem> = [];
};

interface ExcludeFilterItem {
    top: number;
    left: number;
    width: number;
    height: number;
}

class RotateFilter implements ImageFilter {
    type: string = "rotate";
    options: any = 0;
}

class CropArea {
    style: any = {
        'top.px': 0,
        'left.px': 0,
        'width.px': 0,
        'heigth.px': 0,
    };
};


class CropMatrix {
    t = {
        l: {},
        r: {}
    };

    b = {
        l: {},
        r: {}
    };
    width = null;
    height = null;
};

@Component({
    selector: 'core-file-page-filters',
    templateUrl: './file-page-filters.component.html',
    styleUrls: ['./file-page-filters.component.scss']
})
export class FilePageFiltersComponent implements OnInit, OnChanges{
    // tribute of the fallen in this development
    // https://www.youtube.com/watch?v=0Dahs5A4wrQ

    @Input() page: number;
    @Input() file: any;
    @Input() filters:ImageFilter[];

    @Output() onChange = new EventEmitter<any>();

    // sizes and css controls
    protected styles: any;
    protected sizes: any;

    // image control
    public urlImage:string; //URL image
    protected img: any; // object for load image and get original sizes;
    public imgLoaded: boolean = false;
    public imgFullLoaded: boolean = false;
    protected imgRendered = false;

    // crop control
    public crop: any;
    protected cropArea: CropArea = new CropArea;
    protected selectCorner: any;

    // exclude control
    public exclude: any;
    public excludes: any[];

    public filtered: boolean = false; // exist any filter

    constructor(public urlSrv: UrlService) { }

    /**
     * Initialize data
     **/
    protected init() {
        this.cropArea = new CropArea;
        this.crop = {
            cropped: false,
            resizing: false,
            cropping: false,
            move: false,
            selector: {
                tl: { style: {}},
                tr: { style: {}},
                bl: { style: {}},
                br: { style: {}}
            },
            area: {
                style: {}
            },
            matrix: new CropMatrix
        };

        this.exclude = {
            options: {
                ['width.px']: 0,
                ['height.px']: 0,
                ['top.px']: 0,
                ['left.px']: 0,
            },
            excluded: false
        };

        this.styles = {
            area: {
                'transform-origin': "0% 0%",
                'background-size': "100%",
                'background-position-x.px': 0,
                'background-position-y.px': 0,
                'transform': null,
                'width': 'inherit'
            },
            container: {
                //'width.px': 0, // nee, this size is always 100%
                'height.px': 0
            }
        };

        this.sizes = {
            original: {width: 0, height: 0},
            current: {width: 0, height: 0},
            image: {width: 0, height: 0, top: 0, left: 0},
            container: {width: 0, height: 0},
            area: {width: 0, height: 0}

        };

        //this.filtered = false;
    }

    ngOnInit(){
        this.img = new Image();
        /* LISTENERS */

        // listener for get original image size
        this.img.onload = () => {
            this.urlImage = this.img.src;

            // get sizes
            if(!this.imgLoaded) {
                this.init();
                this.sizes.original = {
                    width: this.img.width,
                    height: this.img.height
                };

                this.setImageUrl('big-width');
            }

            this.onImageLoaded();

            // TODO quit this timeout
            //setTimeout(()=>{
            //    this.applyFilters();
            //}, 100);
        };

        /* CODE */


        this.loadImage();
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.page){
            if(changes.page.currentValue != changes.page.previousValue && !changes.page.firstChange){
                this.loadImage();
            }
        }

        if(changes.filters) {
            this.initFilters()
            this.applyFilters();
        }
    }

    /**
     * dispatched when area is resized
     **/
    onResizeArea(event) {
        if(event.width == this.sizes.current.width)
            return false;

        this.sizes.current = {
            width: event.width,
            height: event.height
        };

        this.onImageRendered();
    }

    /**
     * When image is rendered
     * for control when image is viewed and get real area size
     * the first time
     **/
    protected onImageRendered() {
        //if(!this.imgRendered) { // COMENTED FOR RESIZE but fail sometime
            this.resetSizes();
            this.applyFilters();
            //this.resetShowAreaSize();
            //this.resetCropAreaSize();
            this.imgRendered = true;
        //}
    }
    /**
     * Dispatched when image is loaded
     **/
    protected onImageLoaded() {
        this.initFilters();
        this.emitFilterChanges();

        if(this.imgLoaded) {
            this.imgFullLoaded = true;
        }

        if(!this.imgLoaded) {
            this.imgLoaded = true;
        }
    }

    /**
     * Request load image
     **/
    protected loadImage() {
        this.imgRendered = false;
        this.imgLoaded = false;
        this.imgFullLoaded = false;
        this.setImageUrl('min');
    }

    /**
     * Set img src
     **/
    protected setImageUrl(type: string){
        this.img.src = this.urlSrv.build('/files/'+this.file.id+'/preview/' + type + '/'+this.page);
    }

    /**
     * initialize filters
     **/
    protected initFilters() {
        // check if exists

        let crop = this.getFilter("crop");
        if(crop == null)
            this.setFilter(new CropFilter);

        let rotate = this.getFilter("rotate");
        if(rotate == null)
            this.setFilter(new RotateFilter);

        let exclude = this.getFilter("exclude");
        if(exclude == null)
             this.setFilter(new ExcludeFilter);

    }

    protected setFilter(filter: ImageFilter) {
         //if(filter.type !== "exclude")
        this.filters = this.filters.filter((f) => { return f.type != filter.type});

        this.filters.push(filter);
    }


    /**
     * Add filter or update filter and emit changes
     **/
    protected addFilter(filter: ImageFilter){

        // switch(filter.type) {
        //     case "exclude":
        //         this.setFilter(filter);
        //         this.emitFilterChanges();
        //         break;
        //     default:
        //         let change = this.filters.filter((f: ImageFilter) => { return f.type == filter.type});
        //
        //         this.setFilter(filter);
        //
        //         if(change.length == 0 || (change.length > 0 && this.hasChanged(filter, change[0])) )
        //             this.emitFilterChanges();
        // }

        let change = this.filters.filter((f) => { return f.type == filter.type});

        this.setFilter(filter);

        if(change.length == 0 || (change.length > 0 && this.hasChanged(filter, change[0])) )
            this.emitFilterChanges();
    }

    protected hasChanged(item: ImageFilter, old: ImageFilter) {
        let s1: string = JSON.stringify(item);
        let s2: string = JSON.stringify(old);

        return s1 != s2;
    }

    protected emitFilterChanges() {
        this.onChange.emit(this.filters);
    }

    /**
     * Remove filter by type
     **/
    protected removeFilter(type: string) {
        switch(type) {
            case 'rotate':
                this.addFilter(new RotateFilter);
                break;
            case 'crop':
                this.addFilter(new CropFilter);
                break;
            case 'exclude':
                this.addFilter(new ExcludeFilter);
                break;
        }
        //this.filters = this.filters.filter((f) => { return f.type != type});
        //this.onChange.emit(this.filters);
    }

    /**
     * Get filter by type
     **/
    protected getFilter(type: string) {
        let filters = this.filters.filter((f: ImageFilter) => f.type == type );

        if(filters.length == 0)
            return null;

        return filters[0];
    }

    /**
     * Only for reset areas
     **/
    protected resetSizes() {
        let height = (this.sizes.current.width * this.sizes.original.height)/this.sizes.original.width;

        let sizes = {
            width: this.sizes.current.width,
            height: height
        };

        this.sizes.area = sizes;

        this.sizes.container.width = sizes.width;
        this.sizes.container.height = sizes.height;

        this.sizes.image.height = sizes.height;
        this.sizes.image.width = sizes.width;

        this.draw();
    }

    /**
     * Only apply style css
     **/
    protected draw() {
        // this function get sizes from `this.sizes`
        // container

        this.styles.container = {
        //    'width.px': this.sizes.container.width,
            'height.px': this.sizes.container.height
        };

        // area and background
        this.styles.area['background-size'] = this.sizes.image.width + "px " + this.sizes.image.height + "px";
        this.styles.area['background-position-x.px'] = this.sizes.image.left;
        this.styles.area['background-position-y.px'] = this.sizes.image.top;

        this.styles.area['width.px'] = this.sizes.area.width;
        this.styles.area['height.px'] = this.sizes.area.height;

        // set rotate
        let rotate = this.getFilter('rotate');

        switch(rotate.options) {
            case 90:
                this.styles.area["transform"] = "rotate(90deg) translate(0%, -100%)";
                break;
            case 180:
                this.styles.area["transform"] = "rotate(180deg) translate(-100%, -100%)";
                break;
            case 270:
                this.styles.area["transform"] = "rotate(270deg) translate(-100%, 0%)";
                break;
            default:
                this.styles.area["transform"] = null;
        }

        this.drawExcludes();

        // set sizes in crop area
        this.resetCropAreaSize();
    }

    protected drawExcludes() {
        let rotate = this.getFilter('rotate');
        let exclude = this.getFilter('exclude');
        //let excludes = this.filters.filter((f: ImageFilter) => f.type == "exclude" );

        if(exclude == null)
            return false;

        this.excludes = [];

        //console.debug("excludes --->", exclude);
        exclude.options.forEach((e:ExcludeFilterItem)  => {
            // let height: number = 0;
            // let width: number = 0;

            let sizes: any;

            switch(rotate.options) {
                case 90:
                    sizes = {
                        width: e.height * this.sizes.image.height,
                        height: e.width * this.sizes.image.width,
                        top: e.left * this.sizes.image.width, // this.sizes.image.width - (e.width * this.sizes.image.width) - (e.left * this.sizes.image.height),
                        left:  this.sizes.image.height - (e.height * this.sizes.image.height) - (e.top * this.sizes.image.height)
                    };

                    // crop
                    sizes.top = sizes.top + this.sizes.image.left;
                    sizes.left = sizes.left - ((this.sizes.image.height + this.sizes.image.top) - this.sizes.area.height);

                    break;
                case 180:
                    sizes = {
                        width: e.width * this.sizes.image.width,
                        height: e.height * this.sizes.image.height,
                        top: this.sizes.image.height - (e.height * this.sizes.image.height) - (e.top * this.sizes.image.height), //e.left *this.sizes.image.height, // this.sizes.image.width - (e.width * this.sizes.image.width) - (e.left * this.sizes.image.height),
                        left:  this.sizes.image.width - (e.width * this.sizes.image.width) - (e.left * this.sizes.image.width)
                    };
                    // resize for crop
                    sizes.top = sizes.top - (this.sizes.image.height + this.sizes.image.top - this.sizes.area.height);
                    sizes.left = sizes.left - (this.sizes.image.width + this.sizes.image.left - this.sizes.area.width);
                    break;
                case 270:
                    sizes = {
                        width: e.height * this.sizes.image.height,
                        height: e.width * this.sizes.image.width,
                        top: this.sizes.image.width - (e.width * this.sizes.image.width) - (e.left * this.sizes.image.width),
                        left:  e.top * this.sizes.image.height,
                    };
                    // resize for crop
                    sizes.top = sizes.top - (this.sizes.image.width + this.sizes.image.left - this.sizes.area.width);
                    sizes.left = sizes.left + this.sizes.image.top;
                    break;
                default:
                    sizes = {
                        width: e.width * this.sizes.image.width,
                        height: e.height * this.sizes.image.height,
                        top: e.top * this.sizes.image.height,
                        left: e.left * this.sizes.image.width,
                    };

                    // resize for crop
                    sizes.top = sizes.top + this.sizes.image.top;
                    sizes.left = sizes.left + this.sizes.image.left;
                }

                // check crop excludes
                // console.log("this.sizes--->", this.sizes);
                // console.log("sizes--->", sizes);
                // let final = sizes;

                let final = {
                    width: sizes.left > 0? sizes.width : sizes.width + sizes.left, // + bcause left is negative
                    height: sizes.top > 0? sizes.height : sizes.height + sizes.top, // + bcause top is negative
                    top: sizes.top > 0? sizes.top : 0,
                    left: sizes.left > 0? sizes.left : 0,
                }

                // if width and left is bigger than limits of crop
                final.width = (final.left + final.width) < this.sizes.container.width? final.width : final.width - ((final.left + final.width)-this.sizes.container.width);
                final.height = (final.top + final.height) < this.sizes.container.height? final.height : final.height - ((final.top + final.height)-this.sizes.container.height);

                if(final.top < this.sizes.container.height && final.left < this.sizes.container.width &&  final.width > 0 && final.height > 0){
                    this.excludes.push({
                        'width.px': final.width,
                        'height.px': final.height,
                        'top.px': final.top,
                        'left.px': final.left,
                    });
                };

        });
    }


    protected applyFilters(){
        if(!this.imgLoaded)
            return false;
        this.filtered = false;

        // check if cropped
        let crop = this.getFilter("crop");
        if(crop.options.width != 1 || crop.options.height != 1){
            this.crop.cropped = true;
            this.crop.move = true;
            this.filtered = true;
        }

        let exclude = this.getFilter("exclude");
        if( exclude.options.length > 0) {
            this.filtered = true;
        }
        //     this.crop.move =  true;

        this.applySizes();
    }

    protected translate() {
        let crop = this.getFilter('crop');
        let rotate = this.getFilter('rotate');

        let height = crop.options.height;
        let width = crop.options.width;

        //let scaleValue = this.imageArea.width;
        let cropSizeWidth = crop.options.width;
        let cropSizeHeight = crop.options.height;
        let scaleValue = this.sizes.image.width;

        if(rotate.options == 90 || rotate.options == 270) {
            cropSizeHeight = width;
            cropSizeWidth = height;

            scaleValue = this.sizes.image.height;
        }

        // calc scales
        let scaleX = 1/cropSizeWidth;
        let scaleXoffset = (this.sizes.current.width/scaleValue);

        // image size / background image size
        let image: any = {
            height: this.sizes.image.height * scaleXoffset * scaleX,
            width: this.sizes.image.width * scaleXoffset *scaleX
        };

        // visual area
        let area: any = {
            width: this.sizes.container.width,
            //height: crop.options.height * image.height
            height: cropSizeHeight * image.height
        };

        let container = area;

        if(rotate.options == 90 || rotate.options == 270){
            area = {
                width: cropSizeHeight * image.width,
                height: this.sizes.container.width
            };

            container.width = area.height;
            container.height = area.width;
        }

        // position of image / background position
        let position: any = {
            left: crop.options.left * image.width * (-1),
            top: crop.options.top * image.height * (-1)
        };

        return {
            image: image,
            area: area,
            position: position,
            container: container
        };
    }

    protected applySizes() {
        let trans = this.translate();

        // image
        this.sizes.image.width  = trans.image.width;
        this.sizes.image.height = trans.image.height;
        this.sizes.image.left   = trans.position.left;
        this.sizes.image.top    = trans.position.top;

        this.sizes.container.height = trans.container.height;
        this.sizes.area.width  = trans.area.width;
        this.sizes.area.height = trans.area.height;

        this.draw();
        //this.drawExclude();
    }

    /** *****
     * CROP BELOW
     * ******/

    /**
     * Submit crop button
     **/

    // ONLY CROP EVENT
    // submitCrop() {
    //     // set crop variables
    //     this.crop.cropped = true;
    //     this.crop.cropping = false;

    //     let rotate = this.getFilter('rotate');
    //     let crop = this.getFilter('crop');

    //     let width = this.crop.matrix.width / this.sizes.image.width;
    //     let height = this.crop.matrix.height / this.sizes.image.height;

    //     if(rotate.options == 90 || rotate.options == 270) {
    //         width = this.crop.matrix.height / this.sizes.image.width;
    //         height = this.crop.matrix.width / this.sizes.image.height;
    //     }

    //     let left = ((-1)*this.sizes.image.left + this.crop.matrix.t.l.left) / this.sizes.image.width;
    //     let top  = ((-1)*this.sizes.image.top + this.crop.matrix.t.l.top) / this.sizes.image.height;

    //     switch(rotate.options) {
    //         case 90:
    //             left  = ((-1)*this.sizes.image.left + this.crop.matrix.t.l.top) / this.sizes.image.width;
    //             top = ((-1)*this.sizes.image.top + (this.sizes.container.width-this.crop.matrix.t.r.left)) / this.sizes.image.height;
    //             break;
    //         case 180:
    //             left  = ((-1)*this.sizes.image.left + (this.sizes.container.width-this.crop.matrix.t.r.left)) / this.sizes.image.width;
    //             top = ((-1)*this.sizes.image.top + (this.sizes.container.height-this.crop.matrix.b.l.top)) / this.sizes.image.height;
    //             break;
    //         case 270:
    //             left  = ((-1)*this.sizes.image.left + (this.sizes.container.height-this.crop.matrix.b.l.top)) / this.sizes.image.width;
    //             top = ((-1)*this.sizes.image.top + this.crop.matrix.t.l.left) / this.sizes.image.height;
    //             break;
    //     }

    //     let filter = new CropFilter;
    //     filter.options = {
    //         width: width,
    //         height: height,
    //         top: top,
    //         left: left,
    //     };

    //     this.addFilter(filter);
    //     this.applyFilters();
    // }

    submitEvent(type) {
        // set crop variables
        this.crop.cropped = true;
        this.crop.cropping = false;

        let rotate = this.getFilter('rotate');
        let crop = this.getFilter('crop');

        let width = this.crop.matrix.width / this.sizes.image.width;
        let height = this.crop.matrix.height / this.sizes.image.height;

        if(rotate.options == 90 || rotate.options == 270) {
            width = this.crop.matrix.height / this.sizes.image.width;
            height = this.crop.matrix.width / this.sizes.image.height;
        }

        let left = ((-1)*this.sizes.image.left + this.crop.matrix.t.l.left) / this.sizes.image.width;
        let top  = ((-1)*this.sizes.image.top + this.crop.matrix.t.l.top) / this.sizes.image.height;

        switch(rotate.options) {
            case 90:
                left  = ((-1)*this.sizes.image.left + this.crop.matrix.t.l.top) / this.sizes.image.width;
                top = ((-1)*this.sizes.image.top + (this.sizes.container.width-this.crop.matrix.t.r.left)) / this.sizes.image.height;
                break;
            case 180:
                left  = ((-1)*this.sizes.image.left + (this.sizes.container.width-this.crop.matrix.t.r.left)) / this.sizes.image.width;
                top = ((-1)*this.sizes.image.top + (this.sizes.container.height-this.crop.matrix.b.l.top)) / this.sizes.image.height;
                break;
            case 270:
                left  = ((-1)*this.sizes.image.left + (this.sizes.container.height-this.crop.matrix.b.l.top)) / this.sizes.image.width;
                top = ((-1)*this.sizes.image.top + this.crop.matrix.t.l.left) / this.sizes.image.height;
                break;
        }

        let filter: any;
        switch(type) {
            case "crop":
                filter = new CropFilter;
                filter.options = {
                    width: width,
                    height: height,
                    top: top,
                    left: left,
                };
                break;
            case "exclude":
                filter = new ExcludeFilter;

                let filterTmp = this.getFilter('exclude');
                if(filterTmp !== null)
                    filter.options = filter.options.concat(filterTmp.options);

                filter.options.push({
                    width: width,
                    height: height,
                    top: top,
                    left: left,
                });
                break;
        }

        this.addFilter(filter);

        this.applyFilters();
    }

    /**
     * Submit reset
     **/
     public resetFilters() {
         // delete exclude filters
        // this.filters = this.filters.filter((f: ImageFilter) => f.type !== "exclude");
        this.excludes = [];
        this.addFilter(new ExcludeFilter);

         this.resetCrop();
     }

    /**
     * reset crop
     **/
    public resetCrop() {
        this.crop.cropping = false;
        this.crop.cropped = false;
        this.crop.move = false;
        //this.exclude.excluded = false;

        this.addFilter(new CropFilter);
        //this.addFilter(new ExcludeFilter);
        this.applyFilters();
    }

    /**
     * set crop area size
     **/
    protected resetCropAreaSize() {
        // set sizes from container
        let height = this.sizes.container.height;
        let width = this.sizes.container.width;

        this.crop.matrix.t.l = { top: 0, left: 0 };
        this.crop.matrix.t.r = { top: 0, left: width};
        this.crop.matrix.b.l = { top: height, left: 0};
        this.crop.matrix.b.r = { top: height, left: width};

        this.crop.matrix.width = width;
        this.crop.matrix.height = height;

        this.setCropAreaStyle();
    }

    /**
     * set crop area size style
     **/
    protected setCropAreaStyle() {
        // Styles crop area
        this.crop.selector.tl.style['top.px'] = this.crop.matrix.t.l.top;
        this.crop.selector.tl.style['left.px'] = this.crop.matrix.t.l.left;

        this.crop.selector.tr.style['top.px'] = this.crop.matrix.t.r.top;
        this.crop.selector.tr.style['left.px'] = this.crop.matrix.t.r.left;

        this.crop.selector.bl.style['top.px'] = this.crop.matrix.b.l.top;
        this.crop.selector.bl.style['left.px'] = this.crop.matrix.b.l.left;

        this.crop.selector.br.style['top.px'] = this.crop.matrix.b.r.top;
        this.crop.selector.br.style['left.px'] = this.crop.matrix.b.r.left;

        // set crop area
        this.crop.area.style['top.px'] = this.crop.matrix.t.l.top;
        this.crop.area.style['left.px'] = this.crop.matrix.t.l.left;
        this.crop.area.style['width.px'] = this.crop.matrix.width;
        this.crop.area.style['height.px'] = this.crop.matrix.height;
    }

    /**
     * When move mouse whit button down and selector selected
     **/
    onSelectCrop(event) {
        if(!this.crop.resizing)
            return false;

        // Event mousemove
        if(event.type === "mousemove") {
            // if resizing but not button press
            if(this.crop.resizing && event.buttons != 1)
                return this.endSelectCrop(event);

            // if not button press
            if(event.buttons != 1)
                return false;

            var rect = event.currentTarget.getBoundingClientRect(); // Get offset with getBoundingClient.
            var left = event.clientX - rect.left;
            var top = event.clientY - rect.top;
        }

        // Event touchmove
        if(event.type === "touchmove") {
            var rect = event.currentTarget.getBoundingClientRect(); // Get offset with getBoundingClient.
            var left = event.touches[0].clientX - rect.left;
            var top = event.touches[0].clientY - rect.top;
        }

        if(left > this.sizes.container.width
            || left < 0
            || top > this.sizes.container.height
            || top < 0)
            return false;

        switch(this.selectCorner) {
            case 'tl':
                this.crop.matrix.t.l.top = top >= this.crop.matrix.b.l.top ? this.crop.matrix.b.l.top : top;
                this.crop.matrix.t.l.left = left >= this.crop.matrix.t.r.left ? this.crop.matrix.t.r.left : left;

                this.crop.matrix.t.r.top = this.crop.matrix.t.l.top;
                this.crop.matrix.b.l.left = this.crop.matrix.t.l.left;
                break;
            case 'tr':
                this.crop.matrix.t.r.top = top >= this.crop.matrix.b.r.top ? this.crop.matrix.b.r.top : top;
                this.crop.matrix.t.r.left = left <= this.crop.matrix.t.l.left ? this.crop.matrix.t.l.left : left;

                this.crop.matrix.t.l.top = this.crop.matrix.t.r.top;
                this.crop.matrix.b.r.left = this.crop.matrix.t.r.left;
                break;
            case 'bl':
                this.crop.matrix.b.l.top = top <= this.crop.matrix.t.l.top ? this.crop.matrix.t.l.top : top;
                this.crop.matrix.b.l.left = left >= this.crop.matrix.b.r.left ? this.crop.matrix.b.r.left : left;

                this.crop.matrix.b.r.top = this.crop.matrix.b.l.top;
                this.crop.matrix.t.l.left = this.crop.matrix.b.l.left;
                break
            case 'br':
                this.crop.matrix.b.r.top = top <= this.crop.matrix.t.r.top ? this.crop.matrix.t.r.top : top;
                this.crop.matrix.b.r.left = left <= this.crop.matrix.b.l.left ? this.crop.matrix.b.l.left : left;

                this.crop.matrix.b.l.top = this.crop.matrix.b.r.top;
                this.crop.matrix.t.r.left = this.crop.matrix.b.r.left;
                break
        }

        this.crop.matrix.width = this.crop.matrix.t.r.left - this.crop.matrix.t.l.left;
        this.crop.matrix.height = this.crop.matrix.b.l.top - this.crop.matrix.t.l.top;

        this.setCropAreaStyle();
    }

    /**
     * When up mouse button
     **/
    endSelectCrop(event){
        this.crop.resizing = false;
        this.crop.cropping = true;
        this.crop.move = true;
    }

    /**
     * When down mouse button over a selector
     **/
    selectorSelectCrop(num: any) {
        this.selectCorner = num;
        this.crop.resizing = true;
        this.crop.cropping = true;
    }

    // drawExclude(){
    //     let exclude = this.getFilter("exclude");
    //     if(exclude.options.width != 0 || exclude.options.height != 0){
    //         this.exclude = {
    //             options: {
    //                 ['width.px']: exclude.options.width * this.sizes.container.width,
    //                 ['height.px']: exclude.options.height * this.sizes.container.height,
    //                 ['top.px']: exclude.options.top * this.sizes.container.height,
    //                 ['left.px']: exclude.options.left * this.sizes.container.width,
    //             },
    //             excluded: true
    //         }
    //     } else {
    //         this.resetExclude();
    //     }
    // }

    resetExclude(){
        this.exclude = {
            options: {
                ['width.px']: 0,
                ['height.px']: 0,
                ['top.px']: 0,
                ['left.px']: 0,
            },
            excluded: false
        }
    }
}
