import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'core-helpers-updated-ribbon-button',
    templateUrl: './updated-ribbon.component.html',
    styleUrls: ['./updated-ribbon.component.scss']
})
export class HelperBoxUpdatedRibbonComponent {

    reload() {
        window.location.reload();
    }
}
