import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';
 
import { AppService } from '@app/services/app/app.service';
import { ProjectsService, ProjectsEditorService } from '@app/services/app/projects.service';

import { TitleService } from '@core/services/title.service';
import { MenuItem } from '@core/models/menu-item.model';
// import { WebsocketService } from '@core/services/websocket/websocket.service';

import { Request,BasicRequest } from '@core/objects/request';

import { projectDetailsMenu } from '../projects.menu';

@Component({
    selector: 'project-details-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectDetailsMainComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;
    public readonly STATUS_NOT_FOUND = 3;
    public readonly STATUS_ERROR = 4;

    public status: number = 0;
    public menuProjects: Array<MenuItem> = projectDetailsMenu;

    public accessReadMessages: boolean = false;
    public accessWriteMessages: boolean = false;

    public project: Request;
    public projectHeader: BasicRequest = null;
    protected projectId: number;

    protected urlSubscriptor: Subscription;
    protected subscriptor: Subscription;
    protected errorSubscriptor: Subscription;
    protected headerSubscriptor: Subscription;
    protected wsSubscription: any = null;
    protected appSubscriptor: Subscription;

    protected isTabKey: boolean = false;
    protected isPKey: boolean = false;

	constructor(
        public app: AppService, 
        private projectsSrv: ProjectsService, 
        private editorSrv: ProjectsEditorService,
        // public websocket: WebsocketService,
        private title: TitleService, 
        private activeRoute: ActivatedRoute
        ){}

	ngOnInit(){
        this.title.set("Project #");

        this.urlSubscriptor = this.activeRoute.params.subscribe((params: any) => {
            let pid: number = +params.id;
            // console.debug(" project load from route => "+pid);

            if(pid != this.projectId)
                this.projectsSrv.load(pid);

            this.projectId = pid;
        });


        this.subscriptor = this.projectsSrv.selectProject().subscribe((project: Request) => {
            if(project == null) return;

            this.setProject(project);
        });

        this.headerSubscriptor = this.projectsSrv.selectProjectHeader().subscribe((header: Request) => {
            if(header == null) return;

            this.projectHeader = header;
            this.updateMenu(this.projectHeader);
        });

        // this.appSubscriptor = this.app.getSession().subscribe( (session: any) => {
        //     if(session == null)
        //         return;

            // connect to websocket to update project
            // this.wsSubscription = this.websocket.addChannel('private-org_'+session.organization.id);
            // this.wsSubscription.bind('request.updated', data => {
            //         if( data != null && +data.request == +this.projectId){
            //             console.debug("[project screen] websocket --->", data);
            //             this.projectsSrv.load(+this.projectId);
            //         }
            // });
        // });

        this.errorSubscriptor = this.projectsSrv.selectError('load_project').subscribe((error: HttpErrorResponse) => this.setError(error));

	}

    ngOnDestroy() {
        this.urlSubscriptor.unsubscribe();
        this.subscriptor.unsubscribe();
        this.errorSubscriptor.unsubscribe();
        this.headerSubscriptor.unsubscribe();
        // this.appSubscriptor.unsubscribe();
        if(this.wsSubscription)
            this.wsSubscription.unsubscribe();
        this.projectsSrv.clear();
        this.editorSrv.clearTransitions();
    }

    protected setError(error: HttpErrorResponse) {

        // console.log("errrroooooorrrr--->", error);
        if(error == null)
            return;

        switch(error.status) {
            case 500:
                this.status = this.STATUS_ERROR;
                break;
            case 404:
                this.status = this.STATUS_NOT_FOUND;
                break;
            case 403:
                this.status = this.STATUS_NOT_ALLOWED;
                break;
        }
    }

    protected setProject(project: Request) {
        this.project = project;
        // console.log(" ******* PROJECT GOT ",project);
        this.projectHeader = null; // when available project come we clear header
        this.updateMenu(project);
        this.title.set("Project "+this.project.id);
        this.status = this.STATUS_DONE;
    }

    init() {

    }

    // with this works the selected items in nav according activeRoute.
    protected updateMenu(project: Request | BasicRequest){
        if( project == null)
            return;

        this.menuProjects = projectDetailsMenu.map((item: MenuItem) => {
            return {...item, link: item.link.replace(':id', project.id.toString()) }
        });
    }

}
