import { Component, EventEmitter, Input, Output} from '@angular/core';

import * as Objects from '@core/objects';

@Component({
    selector: 'core-labels-list-excluded',
    templateUrl: './labels-list-excluded.component.html',
    styleUrls: ['./labels-list-excluded.component.scss'],
    providers: []
})
export class LabelsListExcludedComponent {
    @Input() labels: Array<Objects.Label>;
    @Input() excluded: Array<Objects.Label> = [];
    @Output() choose  = new EventEmitter<Objects.Label>();

    action(label: Objects.Label) {
        this.choose.emit(label);
    }
}
