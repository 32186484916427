import { EventEmitter } from '@angular/core';

export class Viewer {
    private config: any = {
        zoomFactor: 0.1,
        rotateAngle: 90
    };

    public onChange = new EventEmitter<Viewer>();

    public scale = 1;
    public rotation = 0;
    public translateX = 0;
    public translateY = 0;

    public style = { transform: '', msTransform: '', oTransform: '', webkitTransform: '' };

    public size: any = { 
        width: 0,
        height: 0
    };

    zoomIn() {
        this.scale *= (1 + this.config.zoomFactor);
        this.updateStyle();
    }

    zoomOut() {
        if (this.scale > this.config.zoomFactor) {
            this.scale /= (1 + this.config.zoomFactor);
        }
        this.updateStyle();
    }

    rotate() {
        this.rotateClockwise();
    }

    rotateClockwise() {
        this.rotation += this.config.rotateAngle;

        if(this.rotation >359)
            this.rotation = 0;

        this.updateStyle();
    }
    
    rotateCounterClockwise() {
        this.rotation -= this.config.rotateAngle;
        if(this.rotation < 0)
            this.rotation = 270;

        this.updateStyle();
    }

    reset() {
        this.scale = 1;
        this.rotation = 0;
        this.translateX = 0;
        this.translateY = 0;
    }

    private updateStyle() {        
        // let nsize = this.getNormalizeSize();
        // let tx = nsize.width/2;
        // let ty = nsize.height/2;

        // if(this.rotation == 90 || this.rotation== 270) {
        //     tx = this.size.width + tx;
        //     ty = this.size.height + ty;
        // }

        // this.style.transform = `translate(-${tx}px, -${ty}px) rotate(${this.rotation}deg) scale(${this.scale})`;
        
        let translate = {
            0: "0, 0",
            90: this.size.height*this.scale + "px, 0%",
            180: this.size.width*this.scale +"px, "+this.size.height*this.scale+"px",
            270: "0px, " + this.size.width*this.scale+"px"
        }

        this.style.transform = `translate(${translate[this.rotation]}) rotate(${this.rotation}deg) scale(${this.scale})`;
        // this.style.transform = `translate(${this.translateX}px, ${this.translateY}px) rotate(${this.rotation}deg) scale(${this.scale})`;
        this.style.msTransform = this.style.transform;
        this.style.webkitTransform = this.style.transform;
        this.style.oTransform = this.style.transform;
    
        this.changed();
    }

    changed() {
        this.onChange.emit(this);
        // console.debug("normalized sizes...", this.getNormalizeSize());
    }

    public setSize(width: number, height: number) {
        this.size = {
            width: width,
            height: height
        };

        this.changed();
    }

    public getNormalizeSize() : any {
        let size = {
            width: this.size.width * this.scale,
            height: this.size.height * this.scale
        };

        if(this.rotation == 90 || this.rotation == 270)
            return {
                width: size.height,
                height: size.width 
            }
        
        return size;
    }


}