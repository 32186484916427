import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import { CoreFreshdeskButtonDirective } from './freshdesk-button/freshdesk-button.directive';


@NgModule({
    declarations: [
        CoreFreshdeskButtonDirective,
    ],
    exports: [
        CoreFreshdeskButtonDirective,
    ],

})
export class CoreDirectives { }
