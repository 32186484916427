import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Session } from '@app/store/models/session.model';
import { AccessItem } from '@app/store/models/access-item.model';
import { ApplicationInitStatus } from '@angular/core';

// import { SettingsState } from '../settings/settings.state';


export interface App {
    session: Session;
    version: AppVersion;
    status: AppStatus;
    settings: any;
}

export interface AppVersion {
    hash: string,
    checked: Date,
    changed: boolean
}

export interface AppStatus {
    value: string,
    checked: Date
}

export const AppStatusValues = {
    OK: "ok",
    VERSION_CHANGED: "version_changed",
    DISCONNECTED: "disconnected"
}

export const initialState: App = {
    session: null,
    version: {
        hash: null,
        checked: new Date(),
        changed: false
    },
    status: {
        value: AppStatusValues.OK,
        checked: new Date()
    },
    settings: null,
};

const appFeatureSelector = createFeatureSelector('app');

export const appSelector = createSelector(
    appFeatureSelector,
    (state: App) => state
);

export const appSessionSelector = createSelector(
    appFeatureSelector,
    (state: App) => state.session
);

export const appSessionOrganizationSelector = createSelector(
    appFeatureSelector,
    (state: App) => state.session.organization
);

export const appVersionSelector = createSelector(
    appFeatureSelector,
    (state: App) => state.version
);

export const appStatusSelector = createSelector(
    appFeatureSelector,
    (state: App) => state.status
);
