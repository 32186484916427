import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { AppService } from '@app/services/app/app.service';
import { ProjectsService, ProjectsEditorService } from '@app/services/app/projects.service';
import { TitleService } from '@core/services/title.service';

// import { WebsocketService } from '@core/services/websocket/websocket.service';

import * as Objects from '@core/objects';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { AppRouterService } from '@app/services/app/router.service';
import { HttpErrorResponse } from '@angular/common/http';
import { VersionsService, VersionsViewService } from 'app/services/app/versions.service';

@Component({
    selector: 'project-details-versions-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectDetailsVersionsMainComponent implements OnInit, OnDestroy{

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;
    public readonly STATUS_ERROR = 3;
    public readonly STATUS_NOT_FOUND = 4;

    protected readonly PLUGIN_APOLLO = 'apollo';
    protected readonly PLUGIN_SEND_FILES = 'share_transfer';
    protected readonly PLUGIN_REQUEST_FEEDBACK = 'share_feedback';
    protected readonly ACCESS_UPLOAD_VERSION = "access_request_upload_version";
    // protected readonly ACCESS_PREVIOUS_VERSIONS = "access_request_previous_versions";

	public pluginsAccess: Array<string> = [];
    public forbidden: boolean = true;
    public status: number = this.STATUS_LOADING;

    public accessUploadVersion: boolean = false;

    public project: Objects.Request;
    public relateds: Array<Objects.Related> = null;
    private pendingLoads: number = 0;

    public breadcrumbs: any = [
        {
            title: "",
            link: ['']
        }
    ];

    // TODO review menu icons
    public menu: Array<ThreeDotsMenuItem> = [];

    // main versions: optional  menu items
    protected addGroupMenuItem: ThreeDotsMenuItem =  {
        title: "Upload Group Artwork",
        key: "add-group",
        icon: "add-group",
        order: 1
    };
    protected sendMenuItem: ThreeDotsMenuItem = {
        title: "Send",
        key: "send-files",
        icon: "send-files",
        order: 2
    }
    protected feedbackMenuItem: ThreeDotsMenuItem = {
        title: "Request Feedback",
        key: "feedback",
        icon: "feedback",
        order: 3
    }

    protected menuCompare: ThreeDotsMenuItem = {
        title: "Compare",
        key: "compare",
        icon: "compare",
        order: 4
    };


    protected subscriptor: Subscription;
    protected accessUploadSubscriptor: Subscription;
    protected relatedSubscriptor: Subscription;
    protected errorSubscriptor: Subscription;
    protected wsSubscription: any = null;
    protected versionSubscriptor: Subscription;
    protected isRelatedSubscriptor: Subscription;

    constructor(
        public app: AppService,
        private appRouter: AppRouterService,
        private projectsSrv: ProjectsService,
        private editorSrv: ProjectsEditorService,
        private title: TitleService,
        private viewVersionsSrv: VersionsViewService,
        // private websocket: WebsocketService,
        private versionsSrv: VersionsService
    ){}

	ngOnInit(){
        this.projectsSrv.loadRelatedVersions();

        this.viewVersionsSrv.clear();

        this.relatedSubscriptor = this.projectsSrv.selectProjectRelatedVersions().subscribe((relatedsGroup: Array<Objects.Related>) => {
            if(relatedsGroup != null){
                this.relateds = relatedsGroup;
                this.relateds.length == 0 ?  this.updatePluginsMenu(false) :  this.updatePluginsMenu(true);
                this.init();
                
            } 
        });
        // get plugins access
        this.title.set("Artworks - Project...");
        this.app.getSession().subscribe( (session: any) => {
            this.pluginsAccess = session.options.plugins;
            this.updateMenu(this.sendMenuItem,this.checkPluginAccess(this.PLUGIN_SEND_FILES));
            this.updateMenu(this.feedbackMenuItem,this.checkPluginAccess(this.PLUGIN_REQUEST_FEEDBACK));
            this.updateMenu(this.menuCompare,this.checkPluginAccess(this.PLUGIN_APOLLO));
            
            // this.wsSubscription = this.websocket.addChannel('private-org_'+session.organization.id);
            // this.wsSubscription.bind('version.updated', data => {
            //     if( data != null ){
            //         this.checkToUpdate(+data.version);
            //     }
            // });
        });
        // subscribe to access of versions
        this.accessUploadSubscriptor = this.editorSrv.selectAccess(this.ACCESS_UPLOAD_VERSION)
        .subscribe((access: boolean) => {
            this.accessUploadVersion = access;
            this.updateMenu(this.addGroupMenuItem,this.accessUploadVersion);
        });

        this.subscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.Request ) => {
            if(project == null) return;

            this.setProject(project);
        });
        this.errorSubscriptor = this.projectsSrv.selectError('load_project_version_groups').subscribe((error: HttpErrorResponse) => this.setError(error));


	}

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
        this.accessUploadSubscriptor.unsubscribe();
        this.relatedSubscriptor.unsubscribe();
        this.errorSubscriptor.unsubscribe();
        this.errorSubscriptor.unsubscribe();
        if(this.wsSubscription)
            this.wsSubscription.unsubscribe();
        if(this.versionSubscriptor)
            this.versionSubscriptor.unsubscribe();
        if(this.isRelatedSubscriptor)
            this.isRelatedSubscriptor.unsubscribe();
    }

    init() {
        // TODO SET PLUGINS MENU AFTER get versions and get access together to show prroper menus 
        if(this.relateds == null || this.project == null)
            return;
        this.status = this.STATUS_DONE;
    }

    protected setProject(project: Objects.Request) {
        this.project = project;
        this.updateBreadcrumbs(project);
        this.title.set("Artworks - Project "+this.project.id+"");
        this.init();
    }

    protected checkPluginAccess(plugin: string){
        return this.pluginsAccess.some((pa) => pa == plugin);
    }

    protected updatePluginsMenu(include: boolean){
        this.updateMenu(this.sendMenuItem,include && this.checkPluginAccess(this.PLUGIN_SEND_FILES));
        this.updateMenu(this.feedbackMenuItem,include && this.checkPluginAccess(this.PLUGIN_REQUEST_FEEDBACK));
        this.updateMenu(this.menuCompare,include && this.checkPluginAccess(this.PLUGIN_APOLLO));
    }

    onMenuClick(key: string) {
        switch(key) {
            case "add-group":
                // console.debug(" add new version group ");
                this.appRouter.navigate('/projects/'+this.project.id+'/versions/add');
                break;
            case "feedback":
                // console.debug("feedback click ");
                this.appRouter.navigate('/p/feedback/'+this.project.id)
                break;
            case "send-files":
                // console.debug("send files click ");
                this.appRouter.navigate('/p/transfer/'+this.project.id);
                break;
            case "compare":
                this.appRouter.navigate('/p/apollo/request/'+this.project.id);
                break;
        }
    }

    protected updateBreadcrumbs(project: Objects.Request){
        this.breadcrumbs[0].link = '/projects/'+project.id;
        this.breadcrumbs[0].title = 'Project <small>(#'+project.id+ ')</small>';
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

    // the common tags between project and version should be all of the project.
    protected isRelated(version: Objects.Version){
        let common = this.project.labels.filter((pl: Objects.Label) => version.labels.some((vl: Objects.Label) => vl.id == pl.id));
        return (common.length == this.project.labels.length);
    }

    protected setError(error: HttpErrorResponse) {
        if(error == null)
            return;

        switch(error.status) {
            case 500:
                this.status = this.STATUS_ERROR;
                break;
            case 404:
                this.status = this.STATUS_NOT_FOUND;
                break;
            case 403:
                this.relateds = null; // clean saved versions, 
                this.status = this.STATUS_NOT_ALLOWED;
                break;
        }
    }

    protected checkToUpdate(versionId: number){
        this.isRelatedSubscriptor = this.versionsSrv.getHeadersById$(versionId).subscribe(
            (version: Objects.Version) => {
                if(version != null && this.isRelated(version)){
                    setTimeout(()=>{ // maybe we don't need the timeout but cache system delays tag addition
                        this.projectsSrv.loadRelatedVersions();
                    }, 1000);
                    
                }
            },
            error => {  // version not allowed
                this.projectsSrv.loadRelatedVersions();
            })
    }

}
