import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { User } from '@core/objects/user';

import { AppRequests } from './app.requests';
import { Session } from '@app/store/models/session.model';
import { AccessItem } from '@app/store/models/access-item.model';

import * as fromStore from './app.state';

import {
    AppActionTypes,
    AppActions,
    LoadSessionRequest,
    LoadSessionSuccess,
    ChangeOrganization,
    UpdateUser,
    UpdateUserSuccess,
    UpdateUserError
    //SetError
} from './app.actions';

@Injectable()
export class AppEffects {
    constructor(private actions$: Actions, private store$: Store<fromStore.App>, private appReq: AppRequests) {}

    @Effect()
    public loadSessionEffect$: Observable<AppActions> = this.actions$.pipe(
        ofType<LoadSessionRequest>(AppActionTypes.LoadSessionRequest),
        mergeMap(() =>
            this.appReq.getSession$().pipe(
                map((session: Session) => new LoadSessionSuccess(session)),
                catchError((err: any) => {
                    console.log(err);
                    //window.location.href = '/login?href='+encodeURIComponent(window.location.href);
                    return throwError(err);
                })
            )
        )
    );


    @Effect()
    public changeOrganizationEffect$: Observable<AppActions> = this.actions$.pipe(
        ofType<ChangeOrganization>(AppActionTypes.ChangeOrganization),
        mergeMap((action) => {
            return this.appReq.changeOrganization$(action.organization).pipe(
                map(() => new LoadSessionRequest()),
                catchError((err: any) => {
                    console.log(err);
                    //window.location.href = '/login?href='+window.location.href;
                    return throwError(err);
                })
            )}
        )
    );

    @Effect()
    public updateUserEffect$: Observable<AppActions> = this.actions$.pipe(
        ofType<UpdateUser>(AppActionTypes.UpdateUser),
        withLatestFrom(this.store$.select(fromStore.appSessionSelector)),
        mergeMap(([action, session]) =>
            this.appReq.updateUser$(session.user, action.name, action.telephone, action.position).pipe(
                map((user: User) => new UpdateUserSuccess(user)),
                catchError((err: any) => {
                    console.log(err);
                    return of(new UpdateUserError());
                    // return throwError(err);
                })
            )
        )
    );
}
