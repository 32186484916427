import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { File } from '@core/objects/file';
import { User } from '@core/objects/user';
import { Avatar } from '@core/objects/avatar';
import { AvatarsService } from '@core/services/avatars/avatars.service';

import { AppService } from '@app/services/app/app.service';
import { AvatarService } from '@app/services/app/avatar.service';


@Component({
    selector: 'profile-avatar-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProfileAvatarMainComponent implements OnInit{

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_FOUND = 2;

    public readonly VIEW_MAIN   = "main";
    public readonly VIEW_UPLOAD = "upload";
    public readonly VIEW_EDIT   = "edit";

    @Input() user: User;
    @Output() clickCancel = new EventEmitter();

    public view: string = this.VIEW_MAIN;
    public file: File = null;
    public options: any = [];
    // public defaultAvatar: boolean = true;

	constructor(private appSrv: AppService, private avatarGetSrv: AvatarsService, private avatar: AvatarService){}

	ngOnInit(){
        // TODO get file from user avatar
        if(this.user.avatar != null){
            this.file = this.user.avatar.file;
            this.options = this.user.avatar.options;
            // this.defaultAvatar = false;
        }
    }

    show(area: string) {
        this.view = area;
    }

    cancel() {
        this.clickCancel.emit();
    }

    delete() {
        // TODO: check what to do after this call.
        this.avatar.deleteAvatar$().subscribe(() => {
            // console.debug(" data from call",data);
            this.file = null;
            this.options = [];

            this.avatarGetSrv.reload(this.user);
            this.appSrv.init();
        });
    }

    saveOption(options: any) {
        this.avatar.saveAvatar$(this.file.id, options).subscribe((avatar: Avatar) => {
            console.debug(" avatar from savecall",avatar);
            this.avatarGetSrv.reload(this.user);
            this.appSrv.init();
            // this.file == null;
            this.file = avatar.file;
            this.options = avatar.options;

            this.view = this.VIEW_MAIN;
        });
    }

    cancelOption() {
        this.file = this.user.avatar != null? this.user.avatar.file : null;
        this.view = this.VIEW_MAIN;
    }

    uploadFile(file: File) {
        this.file = file;
        this.view = this.VIEW_EDIT;
    }



}
