import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

import { Message } from '@core/objects/message'
import { MessagesService } from '@core/services/messages/messages.service';
import { VersionFeedback } from '@core/models/version-feedback';

@Component({
    selector: 'core-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageComponent {


    @Input() message: Message;
    @Input() delete: boolean = false;
    @Input() canCreateNotes: boolean = true;
    @Input() notesActionText: string;
    @Input() notesActionStyle: string;
    @Input() prefixUrl: string = '';
    @Input() feedback:  VersionFeedback = null;


    @Output() onDelete = new EventEmitter<Message>();

    public working: boolean = false;
    public error: boolean = false;
    public success: boolean = false;
    public removed: boolean = false;

    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "Remove Message",
            key: "remove-message",
            icon: "remove-message"
        }
    ];


    constructor(private messagesSrv: MessagesService) {}

    onMenuClick(key: string,message: Message) {
        switch(key) {
            case "remove-message":
                console.debug(" delete message ", key,message)
                this.remove(message)
                break;
        }
    }

    remove(msg: Message) {
        this.working = true;
        this.removed = true;
        this.messagesSrv.remove(msg,this.prefixUrl).subscribe(
            (data: Message) => {
                console.log('Message removed: '+data);
                this.onDelete.emit(msg);
                this.working = false;
                this.setSuccess(true);
            },
            (error:any) => {
                console.log('Error removing message: '+error);
                this.working = false;
                this.error = true;
                this.removed = false;
            }
        );
    }

    private setSuccess(success: boolean) {
        this.success = success;
        setTimeout(()=>{
            this.success = false;
        }, 1000);
    }

    try(){
        this.error = false;
    }

}
