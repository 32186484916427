import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { PreviewsService } from '@core/services/previews/previews.service';
import { File } from '@core/objects/file';

import { ApolloService } from '../../../services/apollo/apollo.service';
import { ApolloItem } from '../../../model/apollo-item.model';
import { ApolloFilter } from '../../../model/apollo-filter.model';
import { ImageFilter } from '@core/models/image-filter.model';

class IgnoreFilter implements ImageFilter {
    type: string = "ignore";
    options: boolean = false;
}

@Component({
    selector: 'plugin-apollo-helpers-item-upload',
    templateUrl: './item-upload.component.html',
    styleUrls: ['./item-upload.component.scss']
})
export class PluginApolloHelpersItemUploadComponent implements OnInit, OnDestroy{
    @ViewChild("container", {read: ViewContainerRef}) containerRef: ViewContainerRef;
    @ViewChild("content", {read: TemplateRef}) contentRef: TemplateRef<any>;
    //@ViewChild('childComponent') child:any;

    @Input() propertyName: string;
    //@Output() onRemoveItem = new EventEmitter<any>();

    public filtersActions: any = {
        rotate: true,
        ignore: true,
    };

    public item : ApolloItem = new ApolloItem;

    public file:any;
    public buttonDisabled:boolean = false;
    public preview: any;
    public preview$ : Subscription;

    public filtersPageSelected: ApolloFilter[] = [];

    // Subscriptions
    public subscriptionFile: Subscription;
    public subscriptionStoreProperty: Subscription;

    // constructor(private prwSrv: PreviewsService, private apolloSrv: ApolloService, protected http: HttpClient, protected url: UrlService, public ws: WebsocketService){
    constructor(private prwSrv: PreviewsService, private apolloSrv: ApolloService){}

    ngOnInit(){
        this.subscriptionFile = this.apolloSrv.getFileStoreProperty(this.propertyName).subscribe((file: any)=> {
            console.debug("PropertyName -->", this.propertyName, file);
            if(file !== null){
                this.file = file;
                // this.item.id_file = item.id;
                //this.setPage(1);
                // this.selectPage({page: 1});
            }

            if(file == null) {
                this.renderUploader();
                this.preview = null;
                if(typeof this.preview$ != 'undefined')
                    this.preview$.unsubscribe();
            }
            else {
                this.removeUploader();
                this.preview$ = this.prwSrv.getSubscriptor(file).subscribe(preview => {
                    console.debug("preview subscriber---->", preview);
                    if (this.preview === null){
                        this.setPage(1);
                    } else {
                        this.setPage(this.item.page_selected);
                    }
                    this.preview = preview;
                    this.filtersActions.ignore = preview.total_pages > 1;
                });

                this.prwSrv.load(file);
            }

        });

        this.subscriptionStoreProperty = this.apolloSrv.getStoreProperty(this.propertyName).subscribe((item: any)=> {
            if(item != null){
                console.log(item.filters);
                this.item = item;
            }

            this.updateFiltersPageSelected();
        });
    }

    ngOnDestroy(){
        this.subscriptionFile.unsubscribe();
        this.subscriptionStoreProperty.unsubscribe();
        if(typeof this.preview$ != 'undefined')
            this.preview$.unsubscribe();
    }

    onUploading(uploading: boolean) {
        console.debug("UPLOADINGGGG!!", uploading);
        this.buttonDisabled = uploading;
    }

    upload(event) {
        if (event.length === 0)
            return;

        let item = new ApolloItem;
        item.id_file = event[0].id;

        // this.apolloSrv.setItemRequest(this.propertyName, item);

        this.apolloSrv.setFile(this.propertyName, event[0], item);

        // if(this.propertyName === 'reference'){
        //     this.apolloSrv.setFileReference(event[0]);
        // }else{
        //     this.apolloSrv.setFileTarget(event[0]);
        // }
    }

    protected setPage(page: number) {
        this.apolloSrv.selectItemPage(this.propertyName, page);
        //this.item.page_selected = page;
        //this.updateFiltersPageSelected();
        //this.storeItem();
    }

    selectPage(event){
        console.log("event select page", event);
        if(this.item.page_selected == event.page){
            this.rotate();
        }
        //else {
        //    this.item.pageSelected = event.page;
        //    //this.apolloSrv.setStoreProperty(this.propertyName, this.item);
        //}

        this.setPage(event.page);

    }

    storeItem() {
        this.apolloSrv.setItemRequest(this.propertyName, this.item);
    }

    updateFiltersPageSelected() {
        if(!this.item)
            return false;

        this.filtersPageSelected = this.item.filters.filter(f => {
            return f.page == this.item.page_selected;
        });
    }

    protected renderUploader() {
        this.containerRef.clear();
        this.containerRef.createEmbeddedView(this.contentRef);
    }

    protected removeUploader() {
        this.containerRef.clear();
    }

    removeFile() {
        this.file = null;
        this.item.id_file = null;

        this.apolloSrv.setFile(this.propertyName, this.file, this.item);
    }

    protected rotate() {
        console.log("setting rotated page", event);

        let allFilters = this.item.filters;

        this.filtersPageSelected = allFilters.filter(filter => filter.page == this.item.page_selected);
        let filtersRotate = this.filtersPageSelected.filter(filter => filter.type == "rotate");

        console.log(filtersRotate);
        // initialize filter
        let filter = new ApolloFilter;
        filter.type = "rotate";
        filter.page = this.item.page_selected;
        filter.options = 0;

        if(filtersRotate.length > 0 ) // hay al menos uno
            filter = filtersRotate[0]; // entonces lo seteo encima del default

        // rotate
        filter.options = filter.options >= 270? 0 : filter.options + 90;

        this.setItemFilter(filter);
    }

    protected getFilterPageSelected(type: string) {
        let filters = this.filtersPageSelected.filter(f => { return f.type == type; });

        return filters.length > 0? filters[0]: null;
    }

    /*protected getApolloFilterFromImageFilter(filter: ImageFilter) : ApolloFilter {
        return {...filter, page: this.item.page_selected}
    }*/

    protected saveNewFilter(event){
        let rotateEvent = event.filter(f => { return f.type == 'rotate'; });
        let rotate = null;
        if(rotateEvent.length > 0)  {
            rotate =  {...rotateEvent[0], ...{ page: this.item.page_selected} };
        };


        let cropEvent = event.filter(f => { return f.type == 'crop'; });
        let crop = null;
        if(cropEvent.length > 0) {
            crop =  {...cropEvent[0], ...{ page: this.item.page_selected} };
        };

        let excludeEvent = event.filter(f => f.type == 'exclude');
        let exclude = null;
        if (excludeEvent.length > 0)
            exclude = {...excludeEvent[0], ...{page: this.item.page_selected}}


        let rotateSelected = this.getFilterPageSelected('rotate');
        if(rotate && (!rotateSelected || rotateSelected.options != rotate.options)) {
            this.setItemFilter(rotate);
        }

        let cropSelected = this.getFilterPageSelected('crop');
        if(crop && (!cropSelected || cropSelected.options != crop.options))
            this.setItemFilter(crop);


        let excludeSelected = this.getFilterPageSelected('exclude');
        if (exclude && (!excludeSelected || excludeSelected.options != exclude.options))
            this.setItemFilter(exclude);
    }

    setItemFilter(filter: any) {
        this.apolloSrv.addItemFilter(this.propertyName, filter);
    }

    activePage(event) {
        console.debug("active-->", event);

        // initialize filter
        let filter = new ApolloFilter;
        filter.type = "ignore";
        filter.page = event.page;
        filter.options = !event.active;

        // they cant deactivate all pages
        if(filter.options && !this.getDeactivePages().includes(filter.page) && this.getDeactivePages().length === this.preview.total_pages-1)
            return false;

        this.setItemFilter(filter);
    }

    getDeactivePages(): number[]  {
        return this.item.filters.filter(f=>f.type=="ignore" && f.options==true).map(f => f.page);
    }
}
