import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';
import { VersionsService } from '@core/services/versions/versions.service';


import { VersionFeedback } from '@core/models/version-feedback';

import { User } from '@core/objects/user';
import { Message } from '@core/objects/message';
import { Version } from '@core/objects/version';

// import { Request } from '@core/objects/request';
// import { Label } from '@core/objects/label';
// import { Related } from '@core/objects/related';

@Component({
    selector: 'plugin-share-feedback-core-version-messages',
    templateUrl: './version-messages.component.html',
    styleUrls: ['./version-messages.component.scss']
})
export class PluginShareFeedbackCoreVersionMessagesComponent implements OnInit, OnChanges {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_FOUND = 3;

    @Input() version: Version = null;
    @Input() user: User = null;
    @Input() addMessage: boolean = false;
    @Input() notesActionText: string;
    @Input() notesActionStyle: string;
    @Input() canCreateNotes: boolean = true;
    @Input() prefixUrl: string = '';
    @Input() prefixNotesUrl: string = '';

    public messages: Array<Message> = [];
    public status: number = this.STATUS_LOADING;

    // legacy variables
    public messageAddUrl: string;

    constructor(private versionsSrv: VersionsService, private urlSrv: UrlService ) { }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if(changes.version && this.version != null)
            this.init();
    }

    protected init() {
        this.messageAddUrl = this.urlSrv.build(this.prefixUrl+"/versions/"+this.version.id+"/messages");
        this.status = this.STATUS_LOADING;
        // this.selectedFile = null;
        this.loadMessages();

    }

    protected loadMessages(){
        // here we only load messages
        this.versionsSrv.getMessages$(this.version, this.prefixUrl).subscribe((messages: Array<Message>) => {
            this.messages = messages;
            this.status = this.STATUS_DONE;
        });
    }

    public saveMessage(message: Message){
        this.loadMessages();
    }

    public messageDeleted(message: Message){
        // TODO with messagesSrv
        console.debug("message delete clicked ", message);
        this.loadMessages();
    }

    // public openNotes(e: any){
    //     if(this.selectedFile != null && this.selectedFile.id == e.id)
    //         this.selectedFile = null;
    //     else
    //         this.selectedFile = e;
    // }

    // notesSaved(e:any) {
    //     // this update files and reload notes counter
    //     this.files = [...this.files];
    // }


}
