import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpResponse,
  HttpHandler, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { AppService } from '@app/services/app/app.service';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {

    constructor(private app: AppService) {
    }

    intercept( req: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(
            (data: any) => {
                if(data.type != 0){
                    this.app.setVersion(data.headers.get('Twona-Version'));
                }
            },
            (err: any) => {
                console.log(err);
                // if (err instanceof HttpErrorResponse) {
                //     console.log("llega el error status ", err.status);
                //     console.log('req url :: ' + JSON.stringify(err));
                //     if (err.status === 401) {
                //         this.app.setVersion(err);
                //         // window.location.href = '/login?href='+window.location.pathname;
                //     }
                // }
            }
        ));
    }
}
