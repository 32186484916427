import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';

import * as Objects from '@core/objects';

import { ProjectsService, ProjectsEditorService } from '@app/services/app/projects.service';
// import { WorkflowsService } from '@app/services/settings/workflows.service';

@Component({
    selector: 'project-details-info-features-stages',
    templateUrl: './stages.component.html',
    styleUrls: ['./stages.component.scss']
})
export class ProjectDetailsInfoFeaturesStagesComponent implements OnInit, OnDestroy { 
    public readonly ACCESS_UPDATE_STAGE = "access_request_change_status";

    @Input() project: Objects.Request = null;
    
    public showNextStages: boolean = false;
    public loadingNextStages: boolean = true;
    public nextStages: Array<Objects.Status> = null;
    
    public accessUpdateStage: boolean = false;

    protected accessSubscriptor: Subscription;
    protected stagesSubscriptor: Subscription;
    private wasInside: boolean = false;
	
    constructor(
        private projectsSrv: ProjectsService,
        // private workflowSrv: WorkflowsService, 
        private editorSrv: ProjectsEditorService,
    ){}

    @HostListener('click')
    clickInside() {
        // console.log("click inside")
        this.wasInside = true;
    }
  
    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.showNextStages = false;
        }
        // console.log("click outside")
        this.wasInside = false;
    }

	ngOnInit(){
        // get update stage access
        this.accessSubscriptor = this.editorSrv.selectAccess(this.ACCESS_UPDATE_STAGE)
            .subscribe((access: boolean) => this.accessUpdateStage = access);

        this.stagesSubscriptor = this.editorSrv.selectWorkflowTransitionsByOrigin()
            .subscribe((transitions: Objects.WorkflowTransition[]) => {
                // console.debug("TRANSITIONS ARRIVED!!!! ", transitions);
                if(transitions != null){
                    this.nextStages = transitions.map((t: Objects.WorkflowTransition) => {return t.target});
                    this.loadingNextStages = false;
                }
            });
	}

    ngOnDestroy() {
        this.accessSubscriptor.unsubscribe();
        this.stagesSubscriptor.unsubscribe();
        // this.editorSrv.clearTransitions();
    }

    public openNextStages(){
        if(!this.accessUpdateStage)
            return;
        /// onlly when we have stages
        if(this.nextStages != null) {
            this.showNextStages = !this.showNextStages;
        }
        
    }

    public updateStage(stage: Objects.RequestStatus){
        if(!this.accessUpdateStage)
            return;

        this.nextStages = null;
        this.projectsSrv.setStatus(stage);
        this.showNextStages = false;
        this.loadingNextStages = true;
    }

    public hasNextStages(){
        return this.nextStages != null && this.nextStages.length > 0;
    }

}
