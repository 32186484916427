import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LabelsModule } from '@core/components/labels/labels.module';
import { PipesModule } from '@core/pipes/pipes.module';

import { HelpersSearchFiltersWorkflowStatusMainComponent } from './main/main.component';
import { HelpersSearchFiltersWorkflowStatusStatusComponent } from './status/status.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        LabelsModule

    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        HelpersSearchFiltersWorkflowStatusMainComponent,
        HelpersSearchFiltersWorkflowStatusStatusComponent
    ],
    exports: [
        HelpersSearchFiltersWorkflowStatusMainComponent
    ],

})
export class AppHelpersSearchFiltersWorkflowStatusModule { }