import { Component, Input, OnChanges, OnInit, Output, SimpleChanges,EventEmitter } from '@angular/core';
import { Router,  NavigationEnd, ResolveEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { WorkflowMenuItem } from '@core/models/menu-item.model';

@Component({
    selector: 'core-helpers-navigation-vertical-tabs-workflow',
    templateUrl: './vertical-tabs-workflow.component.html',
    styleUrls: ['./vertical-tabs-workflow.component.scss']
})
export class HelperNavigationVerticalTabsNavigatorComponent implements OnInit, OnChanges {
    public currentRoute: string;
    public ShowNavOptions: boolean;
    
    @Input() menu: WorkflowMenuItem[];
    @Input() fullMatch: boolean = true;
    @Output() menuClick = new EventEmitter<WorkflowMenuItem>();

    public showNavOptions: boolean;

    public menuItems: WorkflowMenuItem[];

    constructor(private router: Router){
        this.router.events.pipe(
            filter(event => (event instanceof NavigationEnd || event instanceof ResolveEnd) )
        ).subscribe((event: any) => {
            // console.log("URL dashboard ---->", event);
            this.currentRoute = event.url;

        });
    }

    ngOnInit(){
        this.menuItems = this.menu;
        this.currentRoute = this.router.url;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.menu){
            // console.log(" MENU UPDATED!!!! ",changes.menu);
            this.menuItems = this.menu;
        }
    }

    onClick(item: WorkflowMenuItem) {
        // console.log(" GOING TO "+item)
        this.menuClick.emit(item);
    }

}
