
import { SettingsLicenses, initialState } from './licenses.state';
import { SettingsLicensesActions, SettingsLicensesActionType } from './licenses.actions';
import { HttpStatus } from '@core/classes/http-status.class';

export function settingsLicensesReducer(state = initialState, action: SettingsLicensesActions): SettingsLicenses {
    switch (action.type) {
		// EVENTS STATUS

		// Users
		// case SettingsLicensesActionType.LoadAllLicenses:
		// 	return {...state, status: { ...state.status, list: new HttpStatus(HttpStatus.PENDING, "")}};
        //
		// case SettingsLicensesActionType.LoadAllLicensesSuccess:
		// 	return {...state, status: { ...state.status, list: new HttpStatus(HttpStatus.DONE, "")}};
        //
		// case SettingsLicensesActionType.LoadAllLicensesError:
		// 	return {...state, status: { ...state.status, list: new HttpStatus(HttpStatus.ERROR, "")}};

        case SettingsLicensesActionType.SetAllLicenses:
			return {
				...state,
				licenses: action.licenses
			};

		// // Invitations
		// case SettingsUsersActionType.LoadAllInvitations:
		// 	return {...state, status: { ...state.status, invite: new HttpStatus(HttpStatus.PENDING, "")}};
        //
		// case SettingsUsersActionType.LoadAllInvitationsSuccess:
		// 	return {...state, status: { ...state.status, invite: new HttpStatus(HttpStatus.DONE, "")}};
        //
		// case SettingsUsersActionType.LoadAllInvitationsError:
		// 	return {
		// 		...state,
		// 		status: {
		// 			...state.status,
		// 			invite: new HttpStatus(HttpStatus.ERROR, action.error.status.toString())
		// 		}};
        //
		// // AddInvitation
		// case SettingsUsersActionType.AddNewInvitation:
		// 	return {...state, status: { ...state.status, addInvite: new HttpStatus(HttpStatus.PENDING, "")}};
        //
		// case SettingsUsersActionType.AddNewInvitationSuccess:
		// 	return {...state, status: { ...state.status, addInvite: new HttpStatus(HttpStatus.DONE, "")}};
        //
		// case SettingsUsersActionType.AddNewInvitationError:
		// 	return {
		// 		...state,
		// 		status: {
		// 			...state.status,
		// 			addInvite: new HttpStatus(HttpStatus.ERROR, action.error.status.toString())
		// 		}};
        //
        //
		// // DeleteUser
		// case SettingsUsersActionType.DeleteOneUser:
		// 	return {...state, status: { ...state.status, delUser: new HttpStatus(HttpStatus.PENDING, "")}};
        //
		// case SettingsUsersActionType.DeleteOneUserSuccess:
		// 	return {...state, status: { ...state.status, delUser: new HttpStatus(HttpStatus.DONE, "")}};
        //
		// case SettingsUsersActionType.DeleteOneUserError:
		// 	return {...state, status: { ...state.status, delUser: new HttpStatus(HttpStatus.ERROR, "")}};
        //
		// // DeleteInvitation
		// case SettingsUsersActionType.DeleteInvitation:
		// 	return {...state, status: { ...state.status, delInvite: new HttpStatus(HttpStatus.PENDING, "")}};
        //
		// case SettingsUsersActionType.DeleteInvitationSuccess:
		// 	return {...state, status: { ...state.status, delInvite: new HttpStatus(HttpStatus.DONE, "")}};
        //
		// case SettingsUsersActionType.DeleteInvitationError:
		// 	return {...state, status: { ...state.status, delInvite: new HttpStatus(HttpStatus.ERROR, "")}};
        //
		// case SettingsUsersActionType.SetAllUsers:
		// 	return {
		// 		...state,
		// 		users: action.users
		// 	};
        //
		// case SettingsUsersActionType.SetAllInvitations:
        //
        //     // let deleted = state.invitations.filter(i => action.invitations.indexOf(i) < 0);
        //     // let added   = action.invitations.filter(i => state.invitations.indexOf(i) < 0);
        //     //
        //     // console.log(deleted, added);
        //     //
        //     // for(let e of deleted) {
        //     //     var i = state.invitations.indexOf(e);
        //     //     state.invitations.splice(i, 1);
        //     // }
        //     //
        //     // //state.invitations.concat(added);
        //     // for(let e of added) {
        //     //     state.invitations.push(e);
        //     // }
        //     //
        //     // return state;
        //
		// 	return {
		// 		...state,
		// 		invitations: action.invitations
		// 	};
        //

	    default:
	        return state;
    }
}
