import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelsModule } from '@core/components/labels/labels.module';
import { LayoutTitlesModule } from '@core/components/layout/titles/titles.module';
import { VersionsModule } from '@core/components/versions/versions.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { AppHelperLabelsModule } from '@app/components/helpers/labels/labels.module';

import { ProjectDetailsDefaultHeaderComponent } from './default-header/default-header.component';
import { ProjectDetailsBreadcrumbHeaderComponent } from './breadcrumb-header/breadcrumb-header.component';
import { ProjectDetailsBreadcrumbVersionHeaderComponent } from './breadcrumb-version-header/breadcrumb-version-header.component';
import { ProjectDetailsHelpersStagesTransitionComponent } from './stages-transition/stages-transition.component';

@NgModule({
    imports: [
        CommonModule,
        LayoutTitlesModule,
        LabelsModule,
        HelpersModule,
        VersionsModule,
        AppHelperLabelsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsDefaultHeaderComponent,
        ProjectDetailsBreadcrumbHeaderComponent,
        ProjectDetailsBreadcrumbVersionHeaderComponent,
        ProjectDetailsHelpersStagesTransitionComponent
    ],
    exports: [
        ProjectDetailsDefaultHeaderComponent,
        ProjectDetailsBreadcrumbHeaderComponent,
        ProjectDetailsBreadcrumbVersionHeaderComponent,
        ProjectDetailsHelpersStagesTransitionComponent
    ],

})
export class ProjectsDetailsHelpersModule { }
