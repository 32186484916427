import { Component, OnInit, OnDestroy } from '@angular/core';
import {formatDate} from '@angular/common';
import { Subscription } from 'rxjs';

import { ProjectsService, ProjectsEditorService } from '@app/services/app/projects.service';

import * as Objects from '@core/objects';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';


@Component({
    selector: 'project-details-info-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class ProjectDetailsInfoDetailsComponent implements OnInit, OnDestroy { 
    public readonly ACCESS_UPLOAD_FILE = "access_request_upload_file";

    public accessUpload: boolean = false;
    public showUpload: boolean = false;
    public project: Objects.Request = null;
    public menu: Array<ThreeDotsMenuItem> = [];
    protected menuUpload: ThreeDotsMenuItem = {
            title: "Attach Files",
            key: "upload",
            icon: "upload-file"
        };
    protected subscriptor: Subscription;
    protected accessSubscriptor: Subscription;
	constructor(
        private projectsSrv: ProjectsService,
        private editorSrv: ProjectsEditorService,
    ){}

    ngOnInit(){ 
        this.subscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.Request ) => {
            if(project != null)
                this.project = project;
        });

        this.accessSubscriptor = this.editorSrv.selectAccess(this.ACCESS_UPLOAD_FILE)
        .subscribe((access: boolean) => {
            this.accessUpload = access;
            this.updateMenu(this.menuUpload,this.accessUpload);
        });
	}

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
    }

    onUploadDone(event: any){
        if(!this.accessUpload)
            return;

        this.showUpload = !this.showUpload;
    }

    onMenuClick(key: string) {
        switch(key) {
            case "upload":
                console.debug(" open upload click on files ", key)
                if(!this.accessUpload)
                    break;

                this.showUpload = !this.showUpload;
                break;
        }
    }

    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }
}