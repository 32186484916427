import { Component, Input } from '@angular/core';

@Component({
    selector: 'core-helpers-box-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class HelperBoxCardComponent {
    @Input() text:string;
    @Input() linkText: string = null;
    @Input() linkHtml: string;
    @Input() title: string;
}
