import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Group } from '@core/objects/group';
import { HttpStatus } from '@core/classes/http-status.class';

import { Settings } from '../settings.state';


export class SettingsGroupsStatus {
    groups: HttpStatus = new HttpStatus();
    save: HttpStatus = new HttpStatus();
};


export interface SettingsGroups {
    status: SettingsGroupsStatus;
    groups: Array<Group>;
}

export const initialState: SettingsGroups = {
    status: new SettingsGroupsStatus,
    groups: null
};


// const settingsGroupsFeatureSelector = createFeatureSelector('groups');

const settingsFeatureSelector = createFeatureSelector('settings');

const settingsGroupsFeatureSelector = createSelector(
    settingsFeatureSelector,
    (state: Settings) => state.groups
);

// Selectors
export const groupsGetAllGroupsSelector = createSelector(
    settingsGroupsFeatureSelector,
    (state: SettingsGroups) => state.groups
);

// Status
export const groupsStatusGetGroups = createSelector(
    settingsGroupsFeatureSelector,
    (state: SettingsGroups) => state.status.groups
);

export const groupsStatusSaveGroup = createSelector(
    settingsGroupsFeatureSelector,
    (state: SettingsGroups) => state.status.save
);
