import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// dependencies
import { HelperBoxModule } from '@core/components/helpers/box/box.module';

//components
import { LayoutErrorNotFoundComponent } from './not-found/not-found.component';
import { ErrorsFullScreenComponent } from './errors-full-screen/errors-full-screen.component';
import { DeprecatedBrowserScreenComponent } from './deprecated-browser-screen/deprecated-browser-screen.component';

@NgModule({
    imports: [
        CommonModule,
        HelperBoxModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LayoutErrorNotFoundComponent,
        ErrorsFullScreenComponent,
        DeprecatedBrowserScreenComponent
    ],
    exports: [
        LayoutErrorNotFoundComponent,
        ErrorsFullScreenComponent,
        DeprecatedBrowserScreenComponent
    ],

})
export class LayoutErrorsModule { }
