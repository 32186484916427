import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'core-helpers-lists-ordered',
  host: {'class': 'list'},
  templateUrl: './ordered-list.component.html',
  styleUrls: ['./ordered-list.component.scss'],
})
export class OrderedListComponent {

    @Input() elements: Array<string>;
    @Input() title: string = "";
    @Output() select = new EventEmitter<number>();

    public hidden: boolean = false;

    onSelect(event){
        this.select.emit(event);
    }
}
