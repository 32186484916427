import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UrlService } from '../url/url.service';
import { Version } from '../../objects/version';
import { Message } from '../../objects/message';

@Injectable({
    providedIn: 'root'
})

export class MessagesService {

    constructor(public urlSrv: UrlService, public http: HttpClient){}

    // find(version: Version, urlPrefix: string = "") {
    //     return this.http.get(this.urlSrv.build(urlPrefix + "/versions/" + version.id+"/messages"));
    // }

    remove(message: Message, prefixUrl: string = "" ) {
        return this.http.delete(this.urlSrv.build(prefixUrl+"/messages/"+message.id));
    }

}
