import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { UrlService } from '@core/services/url/url.service';

import { Version } from '@core/objects/version';
import { VersionFeedback } from '@core/models/version-feedback';

// import { File } from '@core/objects/file';
// import { Request } from '@core/objects/request';
// import { Label } from '@core/objects/label';
// import { Related } from '@core/objects/related';

@Component({
    selector: 'plugin-share-feedback-core-versions-thumbnail-vertical-list',
    templateUrl: './version-thumbnail-vertical-list.component.html',
    styleUrls: ['./version-thumbnail-vertical-list.component.scss']
})
export class PluginShareFeedbackCoreVersionsThumbnailVerticalListComponent implements OnInit {

    public readonly APPROVAL_PENDING = 0;
    public readonly APPROVAL_APPROVED = 1;
    public readonly APPROVAL_REJECTED = 2;

    @Input() feedbacks: Array<VersionFeedback>;
    @Input() versionSelected: Version;
    @Input() seeApprovalStatus: boolean = true;
    @Output() clickVersion: EventEmitter<Version> = new EventEmitter<Version>();


    // constructor(private httpClient: HttpClient, private urlSrv: UrlService ) { }

    ngOnInit() {
        console.debug("versionSelected ",this.versionSelected,this.feedbacks)
    }

    onClickItem(version: Version){
        console.debug(" click on version list ",version.id);
        this.clickVersion.emit(version);
    }

}
