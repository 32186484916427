import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, empty, of, throwError, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import * as Objects from '@core/objects';

import * as Access from '@app/models/access';
import * as Search from '@app/models/search';

import { LibraryRequests } from './library.requests';

import * as fromStore from './library.state';
import * as fromActions from './library.actions';

@Injectable()
export class LibraryEffects {

    constructor(private actions$: Actions, private store$: Store<fromStore.Library>, private requests: LibraryRequests) {}

    @Effect()
    public init$ = this.actions$.pipe(
        ofType<fromActions.Init>(fromActions.LibraryActionType.Init),
         //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            new fromActions.LoadFilters(),
            new fromActions.LoadResults()
        ])
    );
    
    @Effect()
    public loadFilters$ = this.actions$.pipe(
        ofType<fromActions.LoadFilters>(fromActions.LibraryActionType.LoadFilters),
         //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            new fromActions.LoadFilterLabels(),
            new fromActions.LoadFilterStatus()
        ])
    );

    @Effect()
    public loadFilterLabels$: Observable<fromActions.LibraryActions> = this.actions$.pipe(
        ofType<fromActions.LoadFilterLabels>(fromActions.LibraryActionType.LoadFilterLabels),
        switchMap((action) => {
            return this.requests.filterLabels$().pipe(
                map((labels: Objects.Label[]) => {
                    return new fromActions.LoadFilterLabelsSuccess(labels);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadFilterLabelsError(err));
                })
            );
        })
    );

    @Effect()
    public loadFiltersLabelsSuccess$ = this.actions$.pipe(
        ofType<fromActions.LoadFilterLabelsSuccess>(fromActions.LibraryActionType.LoadFilterLabelsSuccess),
        withLatestFrom(this.store$.select(fromStore.librarySelector)),
        switchMap(([action, library]) => [
            new fromActions.PushSearchFilters(Search.SearchConvert.filtersFromParams(library.search_query_params, library.available_filters.labels)),
        ])
    );

    @Effect()
    public loadFilterStatus$: Observable<fromActions.LibraryActions> = this.actions$.pipe(
        ofType<fromActions.LoadFilterStatus>(fromActions.LibraryActionType.LoadFilterStatus),
        switchMap((action) => {
            return this.requests.filterStatus$().pipe(
                map((status: Objects.Status[]) => {
                    return new fromActions.LoadFilterStatusSuccess(status);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadFilterStatusError(err));
                })
            );
        })
    );

    @Effect()
    public loadFiltersStatusSuccess$ = this.actions$.pipe(
        ofType<fromActions.LoadFilterStatusSuccess>(fromActions.LibraryActionType.LoadFilterStatusSuccess),
        withLatestFrom(this.store$.select(fromStore.librarySelector)),
        switchMap(([action, library]) => [
            new fromActions.PushSearchFilters(Search.SearchConvert.filtersFromParams(library.search_query_params, library.available_filters.status)),
        ])
    );

    @Effect()
    public setSearchTerm$ = this.actions$.pipe(
        ofType<fromActions.SetSearchTerm>(fromActions.LibraryActionType.SetSearchTerm),
        // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
        switchMap((action) => [
            // new fromActions.UpdateSearchQuery()
            new fromActions.UpdateQueryParams()
        ])
    );

    @Effect()
    public applySearchFilter$ = this.actions$.pipe(
        ofType<fromActions.ApplySearchFilter>(fromActions.LibraryActionType.ApplySearchFilter),
        // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
        switchMap((action) => [
            // new fromActions.UpdateSearchQuery()
            new fromActions.UpdateQueryParams()
        ])
    );

    @Effect()
    public removeSearchFilter$ = this.actions$.pipe(
        ofType<fromActions.RemoveSearchFilter>(fromActions.LibraryActionType.RemoveSearchFilter),
        // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
        switchMap((action) => [
            // new fromActions.UpdateSearchQuery(),
            new fromActions.UpdateQueryParams()
        ])
    );

    // @Effect()
    // public updateSearchQuery$ = this.actions$.pipe(
    //     ofType<fromActions.UpdateSearchQuery>(fromActions.LibraryActionType.UpdateSearchQuery),
    //     withLatestFrom(this.store$.select(fromStore.searchSelector)),
    //     switchMap(([action, search]) => {
    //         let convert = new Search.SearchConvert();
    //         return [
    //             new fromActions.SetSearchQuery(convert.queryFromFilters(search.filters, search.term, search.sort)),
    //         ]
    //     })
    // );

    @Effect()
    public updateQueryParams$ = this.actions$.pipe(
        ofType<fromActions.UpdateQueryParams>(fromActions.LibraryActionType.UpdateQueryParams),
        withLatestFrom(this.store$.select(fromStore.searchSelector)),
        switchMap(([action, search]) => {
            // let convert = new Search.SearchConvert();
            return [
                new fromActions.SetQueryParams(Search.SearchConvert.paramsFromFilters(search.filters, search.term, search.sort)),
            ]
        })
    );

    // @Effect()
    // public setSearchQuery$ = this.actions$.pipe(
    //     ofType<fromActions.SetSearchQuery>(fromActions.LibraryActionType.SetSearchQuery),
    //     // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
    //     switchMap((action) => [
    //         new fromActions.LoadResults()
    //     ])
    // );

    @Effect()
    public setQueryParams$ = this.actions$.pipe(
        ofType<fromActions.SetQueryParams>(fromActions.LibraryActionType.SetQueryParams),
        // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
        switchMap((action) => [
            new fromActions.LoadResults()
        ])
    );

    @Effect()
    public loadResultItems$: Observable<fromActions.LibraryActions> = this.actions$.pipe(
        ofType<fromActions.LoadResults>(fromActions.LibraryActionType.LoadResults),
        withLatestFrom(this.store$.select(fromStore.librarySelector)),
        switchMap(([action, library]) => {
            return this.requests.results$(Search.SearchConvert.removeVoidParams(library.search_query_params), library.results.next_page, library.search.limit, null).pipe(
                map((versions: Objects.Version[]) => {
                    return new fromActions.LoadResultsSuccess(versions);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadResultsError(err));
                })
            );
        })
    );

    @Effect()
    public reloadResults$: Observable<fromActions.LibraryActions> = this.actions$.pipe(
        ofType<fromActions.ReloadResults>(fromActions.LibraryActionType.ReloadResults),
        // withLatestFrom(this.store$.select(fromStore.resultItemsSelector)),
        switchMap((action) => [
            new fromActions.LoadResults()
        ])
    );

    @Effect()
    public updateVersion$: Observable<fromActions.LibraryActions> = this.actions$.pipe(
        ofType<fromActions.UpdateVersion>(fromActions.LibraryActionType.UpdateVersion),
        withLatestFrom(this.store$.select(fromStore.resultItemsSelector)),
        mergeMap(([action, versions]) => {
            let events = [];
            if (versions.filter((version: Objects.Version) => version.id == action.versionId).length > 0)
                events.push(new fromActions.ReloadResults());
            
            return events;
        })
    );

    // @Effect()
    // public loadVersionSuccess$ = this.actions$.pipe(
    //     ofType<fromActions.LoadVersionSuccess>(fromActions.VersionsActionType.LoadVersionSuccess),
    //      //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
    //     switchMap((action) => [
    //         new fromActions.LoadVersionFiles(),
    //         new fromActions.LoadVersionMessages(),
    //         new fromActions.LoadEditorAccess(),
    //         new fromActions.LoadVersionProject(),
    //     ])
    // );
}
