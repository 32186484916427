import { Component, OnInit, OnDestroy, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';
import { TitleService } from '@core/services/title.service';

import { ApolloService } from '../../../../services/apollo/apollo.service';
import { ApolloRequest } from '../../../../model/apollo-request.model';
import { ApolloContentComparison } from '../../../../model/apollo-content-comparison.model';

@Component({
    selector: 'plugin-apollo-main-section-compare-content',
    templateUrl: './compare-content.component.html',
    styleUrls: ['./compare-content.component.scss']
})
export class PluginApolloMainSectionCompareContentComponent implements OnInit, OnDestroy {


    public show:boolean = false; // Show instructions

    public apolloReady: boolean = true; // check if request is valid

    // Subscriptions
    public subscriptionContent: Subscription;
    public subscriptionRequest: Subscription;

    public apolloContent: ApolloContentComparison = null;

    // public errorExtract:boolean = false;
    public errorCompare :boolean = false;

    public loadingExtract:boolean = true;

    public httpStatus = {
        code: null,
        text: null
    };

    public showCompareComponent: boolean = false;

    public textError :string = '';

    constructor(public apolloSrv: ApolloService, public urlSrv: UrlService, private router: Router, private title: TitleService){}

    ngOnInit() {
        this.title.set("Content comparison - XRay");
        this.subscriptionRequest = this.apolloSrv.getRequest().subscribe((request: ApolloRequest) => {
            if(!request.reference || !request.target) {
                this.apolloReady = false;
                return false;
            }
            this.apolloReady = true;
        });

        this.subscriptionContent = this.apolloSrv.selectorContentCompare().subscribe((apolloContent : ApolloContentComparison) => {
            this.apolloSrv.loadContentComparison(apolloContent);
            this.apolloContent = apolloContent;

            if (apolloContent != null) {
                this.httpStatus = {
                    code: apolloContent.status_code,
                    text: apolloContent.status_text
                }
                if (this.httpStatus.code === 5 || this.httpStatus.code === 6) {
                    this.showCompareComponent = false;
                } else {
                    this.showCompareComponent = true;
                }
            }
        });
    }

    ngOnDestroy(){
		this.subscriptionContent.unsubscribe();
		this.subscriptionRequest.unsubscribe();
    }

    returnToUpload() {
        this.router.navigate(["/p/apollo/upload"]);
    }

    tryAgain(type){
        if (type == 'extract'){
            return this.apolloSrv.loadContentComparison();
        }
        this.compare();
    }

    compare(){
        this.apolloSrv.getContentCompare();
    }

    newExtractProcess(loading){
        if (loading) {
            this.showCompareComponent = false;
        }
    }
}
