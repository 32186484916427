import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";

import { Subscription } from 'rxjs';

import { ProjectsService } from '@app/services/app/projects.service';
import { AppService } from 'app/services/app/app.service';
import { VersionsService } from '@app/services/app/versions.service';


import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { VersionListItemMenuClick } from '@core/components/versions/list/version-list-item-menu-click';

@Component({
    selector: 'project-details-info-origin-version',
    templateUrl: './origin-version.component.html',
    styleUrls: ['./origin-version.component.scss']
})
export class ProjectDetailsInfoOriginVersionComponent implements OnInit, OnDestroy {
    protected readonly ACCESS_REQUEST_FEEDBACK = 'share_feedback';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "See Artwork Details",
            key: "details",
            icon: "see-details"
        }
    ];

    public version: Objects.Version = null;
    public project: Objects.Request = null;
    public feedbackPluginAccess: boolean = false;
    public feedbacks: Array<VersionFeedback> = [];
    public status: number = this.STATUS_LOADING;

    protected subscriptor: Subscription;
    protected appSubscriptor: Subscription;
    protected feedbacksSubscriptor: Subscription;

	constructor(private router: Router, private projectsSrv: ProjectsService,private app: AppService,private versionsSrv: VersionsService){}

	ngOnInit(){

        // get plugins access
        this.appSubscriptor = this.app.getSession().subscribe( (session: any) => {
            this.feedbackPluginAccess = session.options.plugins.some((pa) => pa == this.ACCESS_REQUEST_FEEDBACK);
            this.initView();
        });

        this.subscriptor = this.projectsSrv.selectOriginVersion().subscribe((version: Objects.Version ) => {
            if (version != null){
                this.version = version;
                this.initView();
                // this.status = this.STATUS_DONE;
            }
        });


	}

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
        this.appSubscriptor.unsubscribe();
        if(this.feedbacksSubscriptor)
            this.feedbacksSubscriptor.unsubscribe();
    }

    initView(){
        if(this.version != null)
            this.loadFeedbacks([this.version]);
    }

    public getFeedbacks(version: Objects.Version){
        return this.feedbacks.filter((f: VersionFeedback) => f.version.id == version.id);
    }

    onMenuClick(menu: VersionListItemMenuClick) {
        console.debug(" menu original click",menu.key);
        switch(menu.key) {
            case "details":
                // navigate to Artwork Details
                this.router.navigate([ "/versions/"+this.version.id]);
                break;
        }
    }

    onFileClick(file: Objects.File){
        console.debug(" NEW click file preview ",file);
        if (file != null ){
            // set this url to preview artwork FILE
            this.router.navigate(["/versions/"+this.version.id + "/files/"+file.id]);
        }
    }

    // feedback still come one by one for each version!!
    protected loadFeedbacks(versions: Array<Objects.Version>) {
        if(!this.feedbackPluginAccess) {
            this.status = this.STATUS_DONE;
            return;
        }

        this.feedbacksSubscriptor = this.versionsSrv.getFeedbacks$(versions).subscribe((feedbacks: Array<VersionFeedback>) => {
                this.feedbacks = feedbacks;
                this.status = this.STATUS_DONE;
        });
    }

}
