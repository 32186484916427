import { Component, ElementRef, ViewChild, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Subscription } from 'rxjs';

import { File } from '@core/objects/file';
import { Note } from '@core/objects/note';
import { PreviewRequest} from '@core/objects/preview-request';
import { PreviewsService } from '@core/services/previews/previews.service';

@Component({
    selector: 'core-notes-pagination',
    templateUrl: './notes-pagination.component.html',
    styleUrls: ['./notes-pagination.component.scss']
})
export class NotesPaginationComponent implements OnInit, OnChanges {

    @Input() file: File;
    @Input() notes: Array<Note> = [];
    @Input() canCreate: boolean = true;
    @Output() onClick = new EventEmitter<Array<Note>>();

    protected preview$ : Subscription;
    public preview: PreviewRequest = {
        date: "",
        id_file: "",
        total_pages: 0,
        status_code: 0,
        status_text: "PENDING",
        page_properties: []
    };

    public page = 1;
    public noteSelected = null;
    public list: Array<string> = [];

    public pages: Array<number>;

    constructor(private prwSrv: PreviewsService){}

    ngOnInit(){
        this.preview$ = this.prwSrv.getSubscriptor(this.file).subscribe(preview => {
            this.preview = preview;
            this.pages = Array(preview.total_pages).fill(1).map((x,i) => i+1);
        });

        this.prwSrv.load(this.file);
    }

    ngOnChanges(changes: SimpleChanges){

        if(changes.notes) {
            this.list = this.notes.map( n => n.description);
        }
    }

    selectPage(event) {
        this.page = event.page;
    }

    onListSelect(index: number) {
        // for the first page, the index comeing it's zero.
        this.noteSelected = { ...this.notes[index]};

        this.selectPage({page: this.noteSelected.page}); // whatch out the page number. it was +1 before march'22
    }
}
