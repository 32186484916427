import { Component, Input, Output, EventEmitter,OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'core-layout-modal-message-one-action',
    templateUrl: './modal-message-one-action.component.html',
    styleUrls: ['./modal-message-one-action.component.scss']
})
export class LayoutModalMessageOneActionComponent implements OnInit {
    public textControl = new FormControl('');
    public buttonEnabled: boolean = false;
    public message: string;
    @Input() title: string;
    @Input() text: string;
    @Input() messagePlaceholder: string = 'Write your message...';
    @Input() buttonText: string;
    @Input() buttonType: string = '';
    @Input() disabled: boolean = false;

    @Output() buttonClick = new EventEmitter<string>();
    @Output() buttonCloseClick = new EventEmitter<string>();

    ngOnInit() {
        this.textControl.valueChanges.subscribe(value => {
            this.buttonEnabled = true;
            if(value.trim() == '')
                this.buttonEnabled = false;
            else {
                this.message = value;
            }
        });
    }

    onButtonClick(e) {
        this.buttonClick.emit(this.message);
    }

    onButtonCloseClick(e){
        this.buttonCloseClick.emit(this.message);
    }
}
