import { Note } from '@core/objects/note';

export class VNote {
    // public index: number = 0;
    // public note: LegacyNote = null;
    constructor(public index: number, public note: Note) {}

    getPosition() : any {
        return { x: this.note.options.x, y: this.note.options.y }
        // switch(this.note.type) {
        //     case "point":
        //         return { x: this.note.options.x, y: this.note.options.y }
        //     default:
        //         return {x:0, y: 0 }
        // }
    }

    setPosition(x: number, y: number) {
        this.note.options = {...this.note.options, x: x, y: y}
    }


}

export class VNoteController {
    public vnotes: Array<VNote> = [];

    constructor(notes: Array<Note>) {
        notes.forEach(n => this.add(n))
        //this.vnotes = notes.map(n => this.makeVNote(n));
    }

    add(note: Note) {
        this.vnotes.push(this.makeVNote(note));
    }

    protected makeVNote(note: Note) : VNote {
        let index = this.vnotes.length+1;
        if(this.vnotes.length>0)
            index = this.vnotes[this.vnotes.length-1].index + 1;

        return new VNote(index, note);
    }

    getNotes() : Array<Note> {
        return this.vnotes.map(vn => vn.note);
    }

    getUnsavedNotes(): Array<Note> {
        return this.getNotes().filter(n => n.id == 0);
    }

    hasUnsavedNotes() : boolean {
        return this.getUnsavedNotes().length > 0;
    }

    // getByPage
    findByPage(page: number, type: string) : Array<VNote> {
        return this.vnotes.filter(vn => vn.note.page == page && vn.note.type == type);
    }


    getByNote(note: Note) : VNote | null {
        let index = this.vnotes.map(vn => vn.note.id).indexOf(note.id);

        if(index <0 ) return null;

        return this.vnotes[index];
        // let notes = this.vnotes.filter(vn => vn.note.id != note.id);

        // if(notes.length == 0)
        //     return null;
        
        // return notes[0];
    }

    remove(vnote: VNote) {
        this.vnotes = this.vnotes.filter(vn => vn.index != vnote.index);
    }

    setPosition(vnote: VNote, x: number, y: number) {
        let index = this.vnotes.map(vn => vn.index).indexOf(vnote.index);
        this.vnotes[index].setPosition(x, y);
        // this.vnotes[index].note = {...this.vnotes[index].note, x_pos: x, y_pos: y};
    }

}