import { Component, Input, OnDestroy, OnInit, Output,EventEmitter } from '@angular/core';

import * as Search from '@app/models/search';

@Component({
    selector: 'app-helpers-search-bar-fields-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class HelpersSearchBarFieldsUserComponent{

    @Input() filter: Search.SearchAppliedFilter;
    @Input() style: string = '';
    @Output() remove = new EventEmitter<Search.SearchAppliedFilter>();
    
    onRemove(filter: Search.SearchAppliedFilter){
        this.remove.emit(filter);
    }

}