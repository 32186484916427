import { Component, Input, Output, EventEmitter,OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'core-layout-modal-message-two-action',
    templateUrl: './modal-message-two-action.component.html',
    styleUrls: ['./modal-message-two-action.component.scss']
})
export class LayoutModalMessageTwoActionComponent implements OnInit {
    public textControl = new FormControl('');
    public buttonEnabled: boolean = false;
    public message: string;
    @Input() title: string;
    @Input() text: string;
    @Input() messagePlaceholder: string = 'Write your message...';
    @Input() buttonOneText: string;
    @Input() buttonTwoText: string;
    @Input() buttonOneType: string = '';
    @Input() buttonTwoType: string = '';

    @Output() buttonOneClick = new EventEmitter<string>();
    @Output() buttonTwoClick = new EventEmitter<string>();
    @Output() buttonCloseClick = new EventEmitter<string>();

    ngOnInit() {
        this.textControl.valueChanges.subscribe(value => {
            this.buttonEnabled = true;
            if(value.trim() == '')
                this.buttonEnabled = false;
            else {
                this.message = value;
            }
        });
    }

    onButtonOneClick(e) {
        this.buttonOneClick.emit(this.message);
    }
    onButtonTwoClick(e) {
        this.buttonTwoClick.emit(this.message);
    }
    onButtonCloseClick(e){
        this.buttonCloseClick.emit(this.message);
    }
}
