import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HelperBoxModule } from '../box/box.module';
import { HelperFormModule } from '@core/components/helpers/form/form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from '@core/components/avatar/avatar.module';
import { HelperThreeDotsMenuModule } from './three-dots-menu/three-dots-menu.module';

import { HelperNavigationHeaderMenuComponent } from './header-menu/header-menu.component';
import { HelperNavigationVerticalTabsComponent } from './vertical-tabs/vertical-tabs.component';
import { HelperNavigationUserMenuHeaderComponent } from './user-menu-header/user-menu-header.component';
import { HelperNavigationVerticalTabsNavigatorComponent } from './vertical-tabs-workflow/vertical-tabs-workflow.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HelperBoxModule,
        FormsModule,
        AvatarModule,
        ReactiveFormsModule,
        HelperFormModule,
        HelperThreeDotsMenuModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        HelperNavigationHeaderMenuComponent,
        HelperNavigationVerticalTabsComponent,
        HelperNavigationUserMenuHeaderComponent,
        HelperNavigationVerticalTabsNavigatorComponent
    ],
    exports: [
        HelperNavigationHeaderMenuComponent,
        HelperNavigationVerticalTabsComponent,
        HelperNavigationUserMenuHeaderComponent,
        HelperNavigationVerticalTabsNavigatorComponent,
        HelperThreeDotsMenuModule
    ],

})
export class HelperNavigationModule { }
