import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as Search from '@app/models/search';


@Component({
    selector: 'app-helpers-search-bar-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class HelpersSearchBarMainComponent {

    @Input() placeholder: string = '';
    @Input() term: string = '';
    @Input() filters: Array<Search.SearchAppliedFilter> = [];

    @Output() removeFilter = new EventEmitter<Search.SearchAppliedFilter>();
    @Output() termChanges = new EventEmitter<string>();

    onRemove(filter: Search.SearchAppliedFilter){
        this.removeFilter.emit(filter);
    }

    onTermChanges(term: string) {
        this.termChanges.emit(term);
    }


}