import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'core-helpers-lists-one-action-list-item',
  templateUrl: './one-action-list-item.component.html',
  styleUrls: ['./one-action-list-item.component.scss'],
})
export class OneActionListItemComponent {

    @Input() title: string;
    @Input() subtitle: string = "";
    @Input() actionText: string = "";
    @Input() type: string = "";
    @Output() titleClick = new EventEmitter<any>();
    @Output() actionClick = new EventEmitter<any>();

    onSelect(event){
        this.titleClick.emit(event);
    }

    onAction(event){
        this.actionClick.emit(event);
    }
}
