import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'core-message-add-form',
  templateUrl: './message-add-form.component.html',
  styleUrls: ['./message-add-form.component.scss'],
})
export class MessageAddFormComponent implements OnInit {

    public textControl = new FormControl('');
    public files:any [] = [];
    public buttonDisabled:boolean = true;
    public uploading: boolean = false;
    public dataSend:any;
    @Input() placeholder: any;

    @Output() onSubmit = new EventEmitter<any>();
    @Output() onCancel = new EventEmitter<any>();

    ngOnInit() {
        this.textControl.valueChanges.subscribe(value => {
            this.buttonDisabled = false;
            if(value.trim() == '')
                this.buttonDisabled = true;
        });
    }


    sendMessage(data){
        // Method send message with the textarea and files
        let filesIds = this.files.map((e) => { return e.id; } );
        console.log(this.textControl.value);
        this.dataSend ={
            message: this.textControl.value,
            files: filesIds
        };
        if(this.dataSend.message != '' && this.dataSend.message != undefined && this.dataSend.message != null){
            this.onSubmit.emit(this.dataSend);
        }
    }

    clean() {
        this.textControl.setValue('');
    }


    cancel(){
        this.clean();
        this.onCancel.emit();
    }

}
