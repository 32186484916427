import { Component, OnInit, OnDestroy, Input, Output,EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, throwError, Subscription } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';
import { TitleService } from '@core/services/title.service';

import { File } from '@core/objects/file';
import { Request } from '@core/objects/request';

@Component({
    selector: 'share-transfer-select-files-section',
    templateUrl: './select-files.component.html',
    styleUrls: ['./select-files.component.scss']
})
export class PluginShareTransferSelectFilesSectionComponent implements OnInit, OnDestroy {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly NOT_FOUND = 3;

    public status:number = this.LOADING;
    // public files: Array<File> = [];
    public filesUrl: string = "";

    @Input() request: Request;
    @Input() files: Array<File> = [];

    @Output() selectedChange = new EventEmitter<Array<File>>()



    constructor(private http: HttpClient, public urlSrv: UrlService){}


    ngOnInit() {
        if(this.request != null){
            this.filesUrl = "/requests/" + this.request.id +"/files"
        }

    }

    ngOnDestroy(){

    }
    onChange(e) {
        // console.log(" cambia  ",e);
        this.setSelected(e);
        this.selectedChange.emit(this.files);
    }

    onRemove(e) {
        let fileId = e.id;
        let files = this.files.filter(f => f.id != fileId);
        this.setSelected(files);
        this.selectedChange.emit(this.files);

    }

    setSelected(files: Array<File>){
        this.files = files;
    }

}
