import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'settings-users-info-user',
    templateUrl: './info-user.component.html',
    styleUrls: ['./info-user.component.scss']
})
export class SettingsUsersInfoUserComponent implements OnInit, OnChanges{

	@Input() data:any;
	@Input() typeAction:string;
	@Input() owner:boolean = null;
	@Output() onSubmit = new EventEmitter<string>();

	@Input() dataDisabled: string = null;

	public getData:string = "id";
	public textButton:string = 'Remove';


	public disabledAction: boolean = false;


	constructor(){}

	ngOnInit(){
		console.log("data ---- ",this.data);
		if (this.typeAction != 'user'){
			this.getData = "email";
			this.textButton = 'Cancel';
		}
	}

	ngOnChanges(changes: SimpleChanges){
		if(changes.dataDisabled) {
            if (changes.dataDisabled.currentValue === this.data[this.getData]) {
				this.disabledAction = true;
			} else if (changes.dataDisabled.currentValue == null) {
				this.disabledAction = false;
			}
        }
	}


	submit(){
		let remove = this.data[this.getData];
		this.onSubmit.emit(remove);
	}
}
