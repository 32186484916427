import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { Subscription } from 'rxjs';

import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';

@Component({
    selector: 'core-version-feedbacks',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class VersionFeedbacksMainComponent {

    @Input() feedbacks: Array<VersionFeedback> = [];
    @Input() user: Objects.User = null; //current user.
    @Output() action = new EventEmitter<VersionFeedback>();
    
    sendAction(feedback: VersionFeedback) {
        this.action.emit(feedback);
    }

}
