import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { ProjectsService } from '@app/services/app/projects.service';
import { AppService } from 'app/services/app/app.service';
import { VersionsService as AppVersionsService, VersionsViewService } from '@app/services/app/versions.service';

import { UrlService } from '@core/services/url/url.service';
import { AppRouterService } from 'app/services/app/router.service';

import * as Objects from '@core/objects';
import { TitleService } from '@core/services/title.service';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';


@Component({
    selector: 'project-version-file-preview-main',
    templateUrl: './version-file-preview.component.html',
    styleUrls: ['./version-file-preview.component.scss']
})
export class ProjectDetailsVersionDetailsFilePreviewComponent implements OnInit, OnDestroy {
    protected readonly PLUGIN_SEND_FILES = 'share_transfer';
    protected readonly PLUGIN_APOLLO = 'apollo';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_ERROR = 2;
    public readonly STATUS_NOT_LINKED = 3;
    public readonly STATUS_FILE_NOT_FOUND = 4;
    public readonly STATUS_NOT_ALLOWED = 5;
    public readonly STATUS_NOT_FOUND= 6;

    public version: Objects.Version = null;
    public project: Objects.Request = null;
    public originProject: Objects.Request = null;
    public file: Objects.File = null;
    public excludeLabels: Array<Objects.Label> = [];
    public versionStatusList: Array<Objects.Status> = null;
    public canCreateNotes: boolean = true;

    public context: string = '';

    public breadcrumbs: any = [
    ];
    public pluginsAccess: Array<string> = [];
    public menu: Array<ThreeDotsMenuItem> = [{
        title: "Download File",
        key: "download",
        icon: "download",
        order: 1
    }];

    // optional menu
    protected menuSendFiles: ThreeDotsMenuItem = {
        title: "Send",
        key: "send-files",
        icon: "send-files",
        order: 2
    };
    protected menuCompare: ThreeDotsMenuItem = {
        title: "Compare",
        key: "compare",
        icon: "compare",
        order: 3
    };

    public status:number = this.STATUS_LOADING;

    protected urlSubscriptor: Subscription;
    protected urlParamsSubscriptor: Subscription;
    protected filesSubscriptor: Subscription;
    protected projectSubscriptor: Subscription;
    protected versionSubscriptor: Subscription;
    protected originProjectSubscriptor: Subscription;
    protected appSubscriptor: Subscription;
    protected errorSubscriptor: Subscription;

    constructor(private title: TitleService,
        private app: AppService,
        private urlSrv: UrlService,
        private appRouter: AppRouterService,
        private activeRoute: ActivatedRoute,
        private projectsSrv: ProjectsService,
        private viewVersionsSrv: VersionsViewService) { }

    ngOnInit() {
        this.setDefaultBredcrumb();
        this.title.set("Preview File... - Artwork... - Project...");
        this.urlSubscriptor = this.activeRoute.queryParams.subscribe((params: any) => {
            console.log("query params ===>", params);
            if (params.context) {
                this.context = params.context;
            }
        });

        this.projectSubscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.Request) => {
            this.project = project;
            this.initView();
        });

        this.versionSubscriptor = this.viewVersionsSrv.selectVersion().subscribe((version: Objects.Version) =>
        {
            this.version = version;
            this.initView();
        });

        this.originProjectSubscriptor = this.viewVersionsSrv.selectProject().subscribe((projectOrigin: Objects.Request) => {
            this.originProject = projectOrigin;
            this.initView();
        });

        this.urlParamsSubscriptor = this.activeRoute.params.subscribe((params: any) => {
            let fid: any = +params.fid;
            this.viewVersionsSrv.load(+params.vid);
            this.filesSubscriptor = this.viewVersionsSrv.selectFiles().subscribe((files: Objects.File[] ) => {
                if(files.length > 0){
                    let match = files.filter((f: Objects.File) => {return f.id == fid;});
                    if (match.length > 0 ){
                        this.file = match[0];
                    } else {
                        this.status = this.STATUS_FILE_NOT_FOUND;
                    }
                    this.initView();
                }

            });

        });

        this.appSubscriptor = this.app.getSession().subscribe( (session: any) => {
            this.pluginsAccess = session.options.plugins;
            this.updateMenu(this.menuSendFiles,this.checkPluginAccess(this.PLUGIN_SEND_FILES))
            this.updateMenu(this.menuCompare,this.checkPluginAccess(this.PLUGIN_APOLLO));
        });

        this.errorSubscriptor = this.viewVersionsSrv.selectError('load_version').subscribe((error: HttpErrorResponse) => {
            this.setError(error);
        });

    }

    ngOnDestroy() {
        this.urlSubscriptor.unsubscribe();
        this.urlParamsSubscriptor.unsubscribe();
        this.projectSubscriptor.unsubscribe();
        this.versionSubscriptor.unsubscribe();
        this.originProjectSubscriptor.unsubscribe();
        this.appSubscriptor.unsubscribe();
        if(this.filesSubscriptor)
            this.filesSubscriptor.unsubscribe();
        this.errorSubscriptor.unsubscribe();
        // option instead clean version from store on project versions is to 
        // clear here version FILES after leave preview page to avoid files from one version being loaded or checked after
        // but this not show precisely the version number on the breadcrums
        // this.viewVersionsSrv.clearFiles();
    }

    onMenuClick(menu: FilesListItemMenuClick) {
        switch(menu.key) {
            case "download":
                window.location.href = this.urlSrv.build('/files/'+menu.file.id);
                break;
            case "send-files":
                if (this.checkPluginAccess(this.PLUGIN_SEND_FILES)){
                    if(this.originProject != null)
                        this.appRouter.navigate('/p/transfer/'+this.originProject.id, { queryParams: { file: +menu.file.id} });
                }
                break;
            case "compare":
                if (this.checkPluginAccess(this.PLUGIN_APOLLO))
                    this.appRouter.navigate('/p/apollo/request/'+this.originProject.id);//, { queryParams: { reference: +menu.file.id} });
                break;
        }
    }

    private initView() {
        if(this.project != null && this.version != null)
            this.updateBreadcrumbs();

        if(this.project == null || this.version == null || this.file == null || this.originProject == null)
            return;

        // this.updateContext();
        this.setHeaderTitle();
        this.status = this.STATUS_DONE;
    }

    protected checkPluginAccess(plugin: string){
        return this.pluginsAccess.some((pa) => pa == plugin);
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

    protected updateBreadcrumbs(){ // update intermediate breadcrumb depending on context
        switch (this.context) {
            case "origin": // by now, origin will be on its original project url and version
                this.excludeLabels = this.originProject.labels;
                this.breadcrumbs[0] = {link: '/projects/'+this.originProject.id,
                    title: 'Project <small>(#'+this.originProject.id+ ')</small>'};
                this.breadcrumbs[1] = {link: "/versions/"+this.version.id,
                    title: 'Artwork v.'+this.version.version+' <small>(#'+this.version.id+')</small>'};
                break;
            default:
                this.excludeLabels = this.project.labels;
                this.breadcrumbs[0] = {link: '/projects/'+this.project.id,
                                        title: 'Project <small>(#'+this.project.id+ ')</small>'};
                this.breadcrumbs[1] = {link: '/projects/'+this.project.id+"/versions/"+this.version.id,
                    title: 'Artwork v.'+this.version.version+' <small>(#'+this.version.id+')</small>'};
                break;
        }
    }

    private setHeaderTitle() {
        this.title.set("Preview File "+this.file.name+ " - Artwork #"+ this.version.id + "- Project #" + this.project.id);
    }

    protected setDefaultBredcrumb(){
        this.breadcrumbs[0] = {link: '',
                    title: 'Project <small>(# .  .  . )</small>'};
        this.breadcrumbs[1] = {link: "",
                    title: 'ARTWORK v. . . . <small>(# .  .  . )</small>'};

    }
    protected setError(error: HttpErrorResponse) {
        // console.log("we have file or version errors ",error)
        if(error == null)
            return;
        switch(error.status) {
            case 500:
                this.status = this.STATUS_ERROR;
                break;
            case 404:
                this.status = this.STATUS_NOT_FOUND;
                break;
            case 403:
                this.viewVersionsSrv.clearFiles();
                this.file = null;
                this.status = this.STATUS_NOT_ALLOWED;
                break;
        }
    }

}
