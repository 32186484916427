import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as Objects from '@core/objects';
import { UrlService } from '@core/services/url/url.service';
import { AddRequest } from '@app/models/add-project';

import * as fromAddProjectsState from '@app/store/state/add-project/add-project.state';
import * as fromAddProjectsActions from '@app/store/state/add-project/add-project.actions';

@Injectable({
    providedIn: 'root'
})
export class AddProjectService {

    constructor(private url : UrlService,  protected http: HttpClient, private store: Store<fromAddProjectsState.AddProject>) {}

    addProject$(data: AddRequest, prefixUrl: string = "") {
        let postData = {
            info: data.info,
            due_date: data.due_date,
            files: data.files.map((f:Objects.File) => f.id),
            labels:data.labels.map((l:Objects.Label) => l.id),
            version: data.version,
            workflow: data.workflow
        }
        return this.http
        .post(this.url.build(prefixUrl+"/requests"),postData);
    }

    clear = () => this.store.dispatch( new fromAddProjectsActions.Clear());

    public init(workflowId: number, versionId: number | null) {
        this.store.dispatch(new fromAddProjectsActions.InitAddProject(workflowId, versionId));
    }

    select = (): Observable<AddRequest> => this.store.select(fromAddProjectsState.addProjectSelector);

    update = (property: Partial<AddRequest>) => this.store.dispatch( new fromAddProjectsActions.UpdateAddProject(property));
}