import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { FormGroup, FormControl,Validators } from '@angular/forms';

@Component({
  selector: 'core-helpers-forms-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
})
export class InputRadioComponent implements OnInit, OnChanges {

    @Input() radioItems: Array<any>;
    @Input() name: string;
    @Input() form: FormGroup;
    @Input() selectedValue: string = '';
    @Output() selectedChange = new EventEmitter<string>();
    // public checked: boolean = false;
    public radioControl: FormControl;
    // public selectedValue: string = '';
    public selected: string = ''

    constructor() { }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.radioItems && this.radioControl) {
            this.selected = this.selectedValue;
            this.radioControl.setValue(this.selected);
        }
    }


    select(value: string) {
        this.radioControl.setValue(value);
        this.selectedChange.emit(value);
    }

    init() {
        this.selected = this.selectedValue;
        this.radioControl = new FormControl(this.selected,[Validators.required]);
        this.form.addControl(this.name,this.radioControl);
    }
}
