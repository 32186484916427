import { Injectable } from '@angular/core';
//import { Observable } from "rxjs"
import { Observable, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';
import { PreviewRequest } from '@core/objects/preview-request';

import { Version } from '@core/objects/version';

export interface PreviewAttempt {
    interval: number,
    tried: number
}

export interface PreviewSubject {
    id: string;
    subject: Subject<any>;
    attempts: PreviewAttempt;
}

@Injectable({
    providedIn: 'root'
})
export class PreviewsService {
    readonly MAX_ATTEMPTS = 10;

    private previews: Array<PreviewSubject> = [];

    private firstAttempt: PreviewAttempt = {
        interval: 4000,
        tried: 0
    };

    constructor(protected url: UrlService, protected http: HttpClient) {}

    public getSubscriptor(file: File) {
        let preview = this._getById(file.id);

        return preview.subject.asObservable();
    }

    public getSubscriptorByFileId$(id) {
        let preview = this._getById(id);

        return preview.subject.asObservable();
    }

    protected _loadById(id: string) {
        let preview = this._getById(id);

        this.http
        .get(this.url.build("/files/" + id + "/preview"))
        .subscribe(
            (data:PreviewRequest) => {
                this._process(data);
            },
            (error:any) => {
                console.error('Error preview: '+ error);
            }
        );
    }

    protected _process(request: PreviewRequest) {
        let preview = this._getById(request.id_file);

        // if status is pending
        if(request.status_code === 0) {
            // if MAX_ATTEMPTS not try
            if(preview.attempts.tried < this.MAX_ATTEMPTS) {
                setTimeout(() => {
                    this._loadById(request.id_file);
                }, preview.attempts.interval);
            }

            switch(preview.attempts.tried) {
                case 0:
                    preview.attempts = {
                        ...preview.attempts,
                        tried: 1,
                        interval: 1500 // next interval
                    }
                    break;
                case this.MAX_ATTEMPTS:
                    // TODO set other state
                default:
                    preview.attempts = {
                        ...preview.attempts,
                        tried: preview.attempts.tried + 1,
                        interval: preview.attempts.interval * 1.4
                    }
            }

            this._replaceAttempt(preview);
        }

        preview.subject.next(request); // dispatch
    }

    public load(file: File) {
        this._loadById(file.id);
    }

    private _replaceAttempt(preview: PreviewSubject) {
        let index = this.previews.map( (ps: PreviewSubject)  => ps.id).indexOf(preview.id)

        this.previews[index].attempts = preview.attempts;
    }

    private _getById(id: string): PreviewSubject {

        let previews = this.previews.filter( (ps: PreviewSubject) => {
            return ps.id == id;
        });

        if (previews.length > 0)
            return previews[0];

        let preview = {
            id: id,
            subject: new Subject<any>(),
            attempts: {...this.firstAttempt}
        };

        this.previews.push(preview);

        return this._getById(id);
    }

    /**
     * First preview methods
     **/
    public getFirstSubscriptor(files: Array<File>) {
        let ids = this.makeIdFirst(files);

        let preview = this._getById(ids);

        return preview.subject.asObservable();
    }

    public loadFirst(files: Array<File>) {
        let ids = this.makeIdFirst(files);
        this._loadFirstById(ids);
    }

    private makeIdFirst(files: Array<File>): string {
        return files.map(f => f.id).sort().join(",");
    }

    protected _loadFirstById(id: string) {
        let preview = this._getById(id);

        this.http
        .get(this.url.build("/preview/first/" + id ))
        .subscribe(
            (data:PreviewRequest) => {
                this._processFirst(data, id);
            },
            (error:any) => {
                console.error('Error preview: '+ error);
            }
        );
    }

    protected _processFirst(request: PreviewRequest, id: string) {
        let preview = this._getById(id);

        // if status is pending
        if(request.status_code === 0) {
            // if MAX_ATTEMPTS not try
            if(preview.attempts.tried < this.MAX_ATTEMPTS) {
                setTimeout(() => {
                    this._loadFirstById(preview.id);
                }, preview.attempts.interval);
            }

            switch(preview.attempts.tried) {
                case 0:
                    preview.attempts = {
                        ...preview.attempts,
                        tried: 1,
                        interval: 1500 // next interval
                    }
                    break;
                case this.MAX_ATTEMPTS:
                    // TODO set other state
                default:
                    preview.attempts = {
                        ...preview.attempts,
                        tried: preview.attempts.tried + 1,
                        interval: preview.attempts.interval * 1.4
                    }
            }

            this._replaceAttempt(preview);
        }

        preview.subject.next(request);
    }


}
