import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import * as Objects from '@core/objects';

@Component({
    selector: 'project-details-versions-relateds-list',
    templateUrl: './relateds-list.component.html',
    styleUrls: ['./relateds-list.component.scss']
})
export class ProjectDetailsVersionsRelatedsListComponent implements OnInit, OnChanges {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_ERROR = 2;

    @Input() project: Objects.Request;
    @Input() relateds: Array<Objects.Related> = null;

    public status:number = this.STATUS_LOADING;

    ngOnInit() {
        if(this.relateds != null){
            this.status = this.STATUS_DONE;
        } else {
            this.status = this.STATUS_LOADING;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.relateds){
            if (this.relateds != null){
                this.status = this.STATUS_DONE;
            }
        }
    }

}
