import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'core-preview-not-found',
    templateUrl: './preview-not-found.component.html',
    styleUrls: ['./preview-not-found.component.scss'],
    providers: []
})
export class PreviewNotFoundComponent implements OnInit {


    @Input() file: any = null;

    public alt:string;

    ngOnInit(){
        // this.alt = "file-" + this.file;
        this.alt = "preview-not-found";
    }

}
