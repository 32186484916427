import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Observable, of, throwError, Subscription } from 'rxjs';

import { File } from '@core/objects/file';

import { ApolloGraphicLayer } from '../../../model/apollo-graphic-layer.model';
import { ApolloInspector } from '../../../model/apollo-inspector.model';

@Component({
    selector: 'plugin-apollo-helpers-graphic-inspector',
    templateUrl: './graphic-inspector.component.html',
    styleUrls: ['./graphic-inspector.component.scss']
})
export class PluginApolloHelpersGraphicInspectorComponent implements OnInit, OnChanges, AfterViewInit {
    readonly REFERENCE_LAYER_KEY = "reference";
    readonly TARGET_LAYER_KEY = "target";
    readonly XRAY_LAYER_KEY = "xray";

    @ViewChild('figure1', { read: ElementRef }) public figure1: ElementRef<any>;
    @ViewChild('figure2', { read: ElementRef }) public figure2: ElementRef<any>;
    @ViewChild('figure3', { read: ElementRef }) public figure3: ElementRef<any>;

    @Input() layers: ApolloGraphicLayer[];

    @Input() inspector: ApolloInspector;
    @Output() inspectorChange = new EventEmitter<ApolloInspector>();


    protected inspectorObservable: Observable<ApolloInspector>;

    public reference: ApolloGraphicLayer;
    public target: ApolloGraphicLayer;
    public xray: ApolloGraphicLayer;


    public size: any = {};

    public tab: string = "reference";

    ngOnInit() {
        this.setLayers();
        this.drawScroll();

        this.inspectorObservable = of(this.inspector);
        this.inspector.onChange.subscribe( t => {
            this.drawScroll();
        });
    }


    ngAfterViewInit() {
    }

    ngOnChanges(changes: SimpleChanges){
        this.setLayers();
        this.drawScroll();
    }

    setMobileTab(name: string) {
        this.tab = name;

        // ugly hack by tovar
        // a gift https://www.youtube.com/watch?v=52UC6kC-p64
        setTimeout( () => { this.drawScroll(); }, 200 );
    }

    onInspectorChange() {
        this.inspectorChange.emit(this.inspector);
    }

    protected setLayers() {
        let layers = [];

        layers = this.layers.filter(l => { return l.key == this.REFERENCE_LAYER_KEY; });
        this.reference = layers.length > 0? layers[0]: null;

        layers = this.layers.filter(l => { return l.key == this.TARGET_LAYER_KEY; });
        this.target = layers.length > 0? layers[0]: null;

        layers = this.layers.filter(l => { return l.key == this.XRAY_LAYER_KEY; });
        this.xray = layers.length > 0? layers[0]: null;
    }

    logResize(event) {
        console.debug("resize---->", event);
        if(event.width == 0 && event.height == 0)
            return false;

        this.inspector.setInspectorSize(event.width, event.height);
        this.onInspectorChange();
    }

    onScroll(event) {
        this.inspector.setInspectorPosition(event.target.scrollLeft, event.target.scrollTop);
        this.drawScroll();
    }

    protected drawScroll() {
        this.figure1.nativeElement.scrollTop = this.inspector.inspectorPosition.top;
        this.figure1.nativeElement.scrollLeft = this.inspector.inspectorPosition.left;

        this.figure2.nativeElement.scrollTop = this.inspector.inspectorPosition.top;
        this.figure2.nativeElement.scrollLeft = this.inspector.inspectorPosition.left;

        this.figure3.nativeElement.scrollTop = this.inspector.inspectorPosition.top;
        this.figure3.nativeElement.scrollLeft = this.inspector.inspectorPosition.left;
    }


}
