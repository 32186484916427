import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import { AvailableRuleItem, AvailableRuleActionVariable, AvailableRuleAction, RuleEvent } from '@app/models/rules';

// import { Rule } from '@core/objects/rule';


/**
 * @deprecated
 */
@Injectable({
    providedIn: 'root'
})
export class RulesService {

    constructor(protected url: UrlService, protected http: HttpClient) {}
    // add object rule
    findRules$() {
        return this.http
        .get(this.url.build("/rules/events"));
    }

    findSettingsEvents$() {
        return this.http
        .get(this.url.build("/settings/rules/events"));
    }

    findSettingsEventConditions$(key: string) {
        return this.http
        .get(this.url.build("/settings/rules/events/"+key+"/conditions"));
    }

    findSettingsEventActions$(eventKey: string) {
        return this.http
            .get<AvailableRuleAction[]>(this.url.build("/settings/rules/events/"+eventKey+"/actions"));
    }

    // findSettingsEventActionVariables$(actionKey: string) {
    //     return this.http
    //         .get<AvailableRuleActionVariable[]>(this.url.build("/settings/rules/actions/"+actionKey+"/variables"));
    // }

    getRuleEvent$(id: Number) {
        return this.http.get<RuleEvent>(this.url.build("/rules/events/" + id ));
    }

    // getRule$(rule: any, prefixUrl: string = "") {
    //     return this.http
    //     .get(this.url.build(prefixUrl+"/settings/rules/" + rule.id));
    // }

    deactivateRuleEvent$(id: Number) {
        return this.http
        .delete(this.url.build("/rules/events/" + id ));
    }

    activateRuleEvent$(id: Number) {
        return this.http
        .put(this.url.build("/rules/events/" + id ),
            {active: true}
        );
    }

    updateRuleEvent$(event: RuleEvent) {
        let options = {headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};

        return this.http
        .put<RuleEvent>(this.url.build("/rules/events/" + event.id ),
            event,
            options
        );
    }


    addRuleEvent$(event: RuleEvent) {
        let options = {headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};
        
        if(event.id > 0)
            return this.updateRuleEvent$(event);
            
        return this.http.post<RuleEvent>(
            this.url.build("/rules/events"),
            event,
            options
        );
    }
}
