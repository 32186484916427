import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import {LabelsModule } from '../labels/labels.module';
import { ProjectsSummaryItemComponent } from './summary-item/summary-item.component';

@NgModule({
    imports: [
        CommonModule,
        LabelsModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectsSummaryItemComponent
    ],
    exports: [
        ProjectsSummaryItemComponent
    ],

})
export class ProjectsModule { }
