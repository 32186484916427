import { Component, Input, Output, EventEmitter } from '@angular/core';
//import { ApolloService } from '../../../services/apollo/apollo.service';
import { Router } from "@angular/router";


@Component({
    selector: 'plugin-apollo-helpers-compare-buttons',
    templateUrl: './compare-buttons.component.html',
    styleUrls: ['./compare-buttons.component.scss']
})
export class PluginApolloHelpersCompareButtonsComponent {
    @Input() graphic: boolean = false; // disabled button
    @Input() content: boolean = false; // disabled button

    constructor(private router: Router){}

    goToGraphic() {
        this.router.navigate(['/p/apollo/compare-graphics']);
    }

    goToContent() {
        this.router.navigate(['/p/apollo/compare-content']);
    }
}
