import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatus } from '@core/classes/http-status.class';

import * as Objects from '@core/objects';

import * as Access from '@app/models/access';
import { AddVersion } from '@app/models/add-version';

export enum ProjectsActionType {

	// EVENT CALLS
	LoadProject = "[Projects] GET - Obtain a project by id",
	LoadProjectSuccess = "[Projects] Obtain a project by id SUCCESS",
	LoadProjectError = "[Projects] Obtain a project by id ERROR",

    LoadProjectFiles = "[Projects] GET - Obtain a project files",
    LoadProjectFilesSuccess = "[Projects] GET - Obtain a project files SUCCESS",
    LoadProjectFilesError = "[Projects] GET - Obtain a project files ERROR",

    LoadProjectOriginVersion = "[Projects] GET - Obtain a project origin version",
    LoadProjectOriginVersionSuccess = "[Projects] GET - Obtain a project origin version SUCCESS",
    LoadProjectOriginVersionError = "[Projects] GET - Obtain a project origin version ERROR",

    SetActiveProjectFile = "[Projects] PUT - Set active a file in project",
    SetActiveProjectFileSuccess = "[Projects] PUT - Set active a file in project SUCCESS",
    SetActiveProjectFileError = "[Projects] PUT - Set active a file in project ERROR",

    UploadProjectFiles = "[Projects] PUT - Upload files in project",
    UploadProjectFilesSuccess = "[Projects] PUT - Upload files in project SUCCESS",
    UploadProjectFilesError = "[Projects] PUT - Upload files in project ERROR",

    LoadEditorAssigners = "[Projects - Editor] GET - Obtain assigners",
    LoadEditorAssignersSuccess = "[Projects - Editor] GET - Obtain assigners SUCCESS",
    LoadEditorAssignersError = "[Projects - Editor] GET - Obtain assigners ERROR",

    LoadEditorWorkflowTransitions = "[Projects - Editor] GET - Obtain workflow transitions by origin",
    LoadEditorWorkflowTransitionsSuccess = "[Projects - Editor] GET - Obtain workflow transitions by origin SUCCESS",
    LoadEditorWorkflowTransitionsError = "[Projects - Editor] GET - Obtain workflow transitions by origin ERROR",

    LoadEditorAccess = "[Projects - Editor] GET - Obtain access",
    LoadEditorAccessSuccess = "[Projects - Editor] GET - Obtain access SUCCESS",
    LoadEditorAccessError = "[Projects - Editor] GET - Obtain access ERROR",

    SetProjectAssigned = "[Projects] PUT - Set assigned in project",
    SetProjectAssignedSuccess = "[Projects] PUT - Set assigned in project SUCCESS",
    SetProjectAssignedError = "[Projects] PUT - Set assigned in project ERROR",

    SetProjectDue = "[Projects] PUT - Set due date in project",
    SetProjectDueSuccess = "[Projects] PUT - Set due date in project SUCCESS",
    SetProjectDueError = "[Projects] PUT - Set due date in project ERROR",

    SetProjectStatus = "[Projects] PUT - Set status in project",
    SetProjectStatusSuccess = "[Projects] PUT - Set status in project SUCCESS",
    SetProjectStatusError = "[Projects] PUT - Set status in project ERROR",

    SetProjectLabels = "[Projects] PUT - Set labels in project",
    SetProjectLabelsSuccess = "[Projects] PUT - Set labels in project SUCCESS",
    SetProjectLabelsError = "[Projects] PUT - Set labels in project ERROR",

    LoadProjectMessages = "[Projects] GET - Obtain a project messages",
    LoadProjectMessagesSuccess = "[Projects] GET - Obtain a project messages SUCCESS",
    LoadProjectMessagesError = "[Projects] GET - Obtain a project messages ERROR",

    LoadProjectVersionGroups = "[Projects] GET - Obtain a project version groups",
    LoadProjectVersionGroupsSuccess = "[Projects] GET - Obtain a project version groups SUCCESS",
    LoadProjectVersionGroupsError = "[Projects] GET - Obtain a project version groups ERROR",

    LoadProjectUploadedVersions = "[Projects] GET - Obtain a project uploaded versions",
    LoadProjectUploadedVersionsSuccess = "[Projects] GET - Obtain a project uploaded versions SUCCESS",
    LoadProjectUploadedVersionsError = "[Projects] GET - Obtain a project uploaded versions ERROR",

    LoadProjectFilesNotes = "[Projects] GET - Obtain a project files notes",
    LoadProjectFilesNotesSuccess = "[Projects] GET - Obtain a project files notes SUCCESS",
    LoadProjectFilesNotesError = "[Projects] GET - Obtain a project files notes ERROR",

    LoadProjectHeader = "[Projects] GET - Obtain a project header by id",
	LoadProjectHeaderSuccess = "[Projects] Obtain a project header by id SUCCESS",
	LoadProjectHeaderError = "[Projects] Obtain a project header by id ERROR",

    LoadEvents = "[Projects] GET - Obtain specific events",
    LoadEventsSuccess = "[Projects] GET - Obtain specific events SUCCESS",
    LoadEventsError = "[Projects] GET - Obtain specific events ERROR",

    LoadProjectEvents = "[Projects] GET - Obtain all project events",
    LoadProjectEventsSuccess = "[Projects] GET - Obtain all project events SUCCESS",
    LoadProjectEventsError = "[Projects] GET - Obtain all project events ERROR",

    UpdateProject = "[Projects] - Update project by id",
    
    Clear = "[Projects] clear data",
    ClearEditorTransitions = "[Projects - Editor] clear project transitions",
    ClearVersionsGroups = "[Projects] clear project version groups"
}

// Load Available Events
export class LoadProject implements Action {
    readonly type = ProjectsActionType.LoadProject;
    constructor(public projectId: number) {}
}
export class LoadProjectSuccess implements Action {
    readonly type = ProjectsActionType.LoadProjectSuccess;
    constructor(public project: Objects.Request) {}
}

export class LoadProjectError implements Action {
    readonly type = ProjectsActionType.LoadProjectError;
    constructor(public error: HttpErrorResponse,public projectId: number) {}
}

export class LoadProjectFiles implements Action {
    readonly type = ProjectsActionType.LoadProjectFiles;
    constructor() {}
}

export class LoadProjectFilesSuccess implements Action {
    readonly type = ProjectsActionType.LoadProjectFilesSuccess;
    constructor(public files: Array<Objects.File>) {}
}

export class LoadProjectFilesError implements Action {
    readonly type = ProjectsActionType.LoadProjectFilesError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadProjectOriginVersion implements Action {
    readonly type = ProjectsActionType.LoadProjectOriginVersion;
    constructor() {}
}

export class LoadProjectOriginVersionSuccess implements Action {
    readonly type = ProjectsActionType.LoadProjectOriginVersionSuccess;
    constructor(public versions: Array<Objects.Version>) {}
}

export class LoadProjectOriginVersionError implements Action {
    readonly type = ProjectsActionType.LoadProjectOriginVersionError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadProjectUploadedVersions implements Action {
    readonly type = ProjectsActionType.LoadProjectUploadedVersions;
    constructor() {}
}

export class LoadProjectUploadedVersionsSuccess implements Action {
    readonly type = ProjectsActionType.LoadProjectUploadedVersionsSuccess;
    constructor(public versions: Array<Objects.Version>) {}
}

export class LoadProjectUploadedVersionsError implements Action {
    readonly type = ProjectsActionType.LoadProjectUploadedVersionsError;
    constructor(public error: HttpErrorResponse) {}
}

export class Clear implements Action {
    readonly type = ProjectsActionType.Clear;
    constructor() {}
}


export class SetActiveProjectFile implements Action {
    readonly type = ProjectsActionType.SetActiveProjectFile;
    constructor(public file: Objects.File, public active: boolean) {}
}

export class SetActiveProjectFileSuccess implements Action {
    readonly type = ProjectsActionType.SetActiveProjectFileSuccess;
    constructor(public file: Objects.File) {}
}

export class SetActiveProjectFileError implements Action {
    readonly type = ProjectsActionType.SetActiveProjectFileError;
    constructor(public error: HttpErrorResponse) {}
}

export class UploadProjectFiles implements Action {
    readonly type = ProjectsActionType.UploadProjectFiles;
    constructor(public files: Array<Objects.File>) {}
}

export class UploadProjectFilesSuccess implements Action {
    readonly type = ProjectsActionType.UploadProjectFilesSuccess;
    constructor(public files: Array<Objects.File>) {}
}

export class UploadProjectFilesError implements Action {
    readonly type = ProjectsActionType.UploadProjectFilesError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadEditorAssigners implements Action {
    readonly type = ProjectsActionType.LoadEditorAssigners;
    constructor() {}
}

export class LoadEditorAssignersSuccess implements Action {
    readonly type = ProjectsActionType.LoadEditorAssignersSuccess;
    constructor(public users: Objects.User[]) {}
}

export class LoadEditorAssignersError implements Action {
    readonly type = ProjectsActionType.LoadEditorAssignersError;
    constructor(public error: HttpErrorResponse) {}
} 

export class LoadEditorWorkflowTransitions implements Action {
    readonly type = ProjectsActionType.LoadEditorWorkflowTransitions;
    constructor() {}
}

export class LoadEditorWorkflowTransitionsSuccess implements Action {
    readonly type = ProjectsActionType.LoadEditorWorkflowTransitionsSuccess;
    constructor(public transitions: Objects.WorkflowTransition[]) {}
}

export class LoadEditorWorkflowTransitionsError implements Action {
    readonly type = ProjectsActionType.LoadEditorWorkflowTransitionsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadEditorAccess implements Action {
    readonly type = ProjectsActionType.LoadEditorAccess;
    constructor() {}
}

export class LoadEditorAccessSuccess implements Action {
    readonly type = ProjectsActionType.LoadEditorAccessSuccess;
    constructor(public access: Access.AccessProject) {}
}

export class LoadEditorAccessError implements Action {
    readonly type = ProjectsActionType.LoadEditorAccessError;
    constructor(public error: HttpErrorResponse) {}
}

export class SetProjectAssigned implements Action {
    readonly type = ProjectsActionType.SetProjectAssigned;
    constructor(public user: Objects.User) {}
}

export class SetProjectAssignedSuccess implements Action {
    readonly type = ProjectsActionType.SetProjectAssignedSuccess;
    constructor(public project: Objects.Request) {}
}

export class SetProjectAssignedError implements Action {
    readonly type = ProjectsActionType.SetProjectAssignedError;
    constructor(public error: HttpErrorResponse) {}
}

export class SetProjectDue implements Action {
    readonly type = ProjectsActionType.SetProjectDue;
    constructor(public date: string) {}
}

export class SetProjectDueSuccess implements Action {
    readonly type = ProjectsActionType.SetProjectDueSuccess;
    constructor(public project: Objects.Request) {}
}

export class SetProjectDueError implements Action {
    readonly type = ProjectsActionType.SetProjectDueError;
    constructor(public error: HttpErrorResponse) {}
}

export class SetProjectStatus implements Action {
    readonly type = ProjectsActionType.SetProjectStatus;
    constructor(public status: Objects.RequestStatus) {}
}

export class SetProjectStatusSuccess implements Action {
    readonly type = ProjectsActionType.SetProjectStatusSuccess;
    constructor(public project: Objects.Request) {}
}

export class SetProjectStatusError implements Action {
    readonly type = ProjectsActionType.SetProjectStatusError;
    constructor(public error: HttpErrorResponse) {}
}

export class SetProjectLabels implements Action {
    readonly type = ProjectsActionType.SetProjectLabels;
    constructor(public labels: Array<Objects.Label>) {}
}

export class SetProjectLabelsSuccess implements Action {
    readonly type = ProjectsActionType.SetProjectLabelsSuccess;
    constructor(public project: Objects.Request) {}
}

export class SetProjectLabelsError implements Action {
    readonly type = ProjectsActionType.SetProjectLabelsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadProjectMessages implements Action {
    readonly type = ProjectsActionType.LoadProjectMessages;
    constructor() {}
}

export class LoadProjectMessagesSuccess implements Action {
    readonly type = ProjectsActionType.LoadProjectMessagesSuccess;
    constructor(public messages: Array<Objects.Message>) {}
}

export class LoadProjectMessagesError implements Action {
    readonly type = ProjectsActionType.LoadProjectMessagesError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadProjectVersionGroups implements Action {
    readonly type = ProjectsActionType.LoadProjectVersionGroups;
    constructor() {}
}

export class LoadProjectVersionGroupsSuccess implements Action {
    readonly type = ProjectsActionType.LoadProjectVersionGroupsSuccess;
    constructor(public relateds: Array<Objects.Related>) {}
}

export class LoadProjectVersionGroupsError implements Action {
    readonly type = ProjectsActionType.LoadProjectVersionGroupsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadProjectFilesNotes implements Action {
    readonly type = ProjectsActionType.LoadProjectFilesNotes;
    constructor() {}
}
export class LoadProjectFilesNotesSuccess implements Action {
    readonly type = ProjectsActionType.LoadProjectFilesNotesSuccess;
    constructor(public notes: Array<Objects.Note>) {}
}

export class LoadProjectFilesNotesError implements Action {
    readonly type = ProjectsActionType.LoadProjectFilesNotesError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadProjectHeader implements Action {
    readonly type = ProjectsActionType.LoadProjectHeader;
    constructor(public projectId: number) {}
}
export class LoadProjectHeaderSuccess implements Action {
    readonly type = ProjectsActionType.LoadProjectHeaderSuccess;
    constructor(public header: Objects.BasicRequest) {}
}

export class LoadProjectHeaderError implements Action {
    readonly type = ProjectsActionType.LoadProjectHeaderError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadEvents implements Action {
    readonly type = ProjectsActionType.LoadEvents;
    constructor(public object: string, public id: number) {}
}

export class LoadEventsSuccess implements Action {
    readonly type = ProjectsActionType.LoadEventsSuccess;
    constructor(public events: Array<Objects.Event>) {}
}

export class LoadEventsError implements Action {
    readonly type = ProjectsActionType.LoadEventsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadProjectEvents implements Action {
    readonly type = ProjectsActionType.LoadProjectEvents;
    constructor() {}
}

export class LoadProjectEventsSuccess implements Action {
    readonly type = ProjectsActionType.LoadProjectEventsSuccess;
    constructor(public events: Array<Objects.Event>) {}
}

export class LoadProjectEventsError implements Action {
    readonly type = ProjectsActionType.LoadProjectEventsError;
    constructor(public error: HttpErrorResponse) {}
}

export class UpdateProject implements Action {
    readonly type = ProjectsActionType.UpdateProject;
    constructor(public projectId: number, public event: string) {}
}

export class ClearEditorTransitions implements Action {
    readonly type = ProjectsActionType.ClearEditorTransitions;
    constructor() {}
}


export class ClearVersionsGroups implements Action {
    readonly type = ProjectsActionType.ClearVersionsGroups;
    constructor() {}
}

export type ProjectsActions = 	LoadProject |
                                LoadProjectSuccess |
								LoadProjectError |
                                LoadProjectFiles |
                                LoadProjectFilesSuccess | 
                                LoadProjectFilesError | 
                                LoadProjectOriginVersion |
                                LoadProjectOriginVersionSuccess |
                                LoadProjectOriginVersionError | 
                                SetActiveProjectFile |
                                SetActiveProjectFileSuccess |
                                SetActiveProjectFileError |
                                UploadProjectFiles |
                                UploadProjectFilesSuccess |
                                UploadProjectFilesError | 
                                LoadEditorAssigners |
                                LoadEditorAssignersSuccess |
                                LoadEditorAssignersError | 
                                LoadEditorWorkflowTransitions |
                                LoadEditorWorkflowTransitionsSuccess |
                                LoadEditorWorkflowTransitionsError |
                                LoadEditorAccess |
                                LoadEditorAccessSuccess |
                                LoadEditorAccessError | 
                                SetProjectAssigned |
                                SetProjectAssignedSuccess |
                                SetProjectAssignedError | 
                                SetProjectDue |
                                SetProjectDueSuccess | 
                                SetProjectDueError |
                                SetProjectStatus |
                                SetProjectStatusSuccess |
                                SetProjectStatusError |
                                SetProjectLabels |
                                SetProjectLabelsSuccess |
                                SetProjectLabelsError | 
                                LoadProjectMessages |
                                LoadProjectMessagesSuccess |
                                LoadProjectMessagesError | 
                                LoadProjectVersionGroups |
                                LoadProjectVersionGroupsSuccess |
                                LoadProjectVersionGroupsError |
                                LoadProjectUploadedVersions |
                                LoadProjectUploadedVersionsSuccess |
                                LoadProjectUploadedVersionsError | 
                                LoadProjectFilesNotes |
                                LoadProjectFilesNotesSuccess |
								LoadProjectFilesNotesError |
                                LoadProjectHeader |
                                LoadProjectHeaderSuccess |
                                LoadProjectHeaderError |
                                LoadProjectEvents |
                                LoadProjectEventsSuccess |
                                LoadProjectEventsError |
                                LoadEvents |
                                LoadEventsError |
                                LoadEventsSuccess | 
                                UpdateProject |
                                Clear |
                                ClearEditorTransitions |
                                ClearVersionsGroups
                                ;
