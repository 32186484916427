import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {VersionsService } from '@core/services/versions/versions.service';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { VersionListItemMenuClick } from '../list/version-list-item-menu-click';

import * as  Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';
import { Approval } from '@core/objects/approval';

@Component({
    selector: 'core-versions-summary-box',
    templateUrl: './summary-box.component.html',
    styleUrls: ['./summary-box.component.scss']
})
export class VersionsSummaryBoxComponent implements OnInit {
    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

    public readonly FEEDBACK_COLORS = ['#F6B273','#47B934','#F44848'];
    public readonly FEEDBACK_ONLY_COMMENT_COLOR = 'silver';
        //0-> Pending: F0BC36 (old'#F6B273',)
        //1-> Approved: 47B934 (old'#92D77D',)
        //2-> Rejected: F44848   (old'#C8494E', )
        //3-> only comments: '#C8494E', ????

    @Input() version: Objects.Version;
    @Input() uploaded: boolean = false;
    @Input() feedbacks: Array<VersionFeedback> = [];
    @Input() exclude: Array<Objects.Label> = [];
    @Input() menu: Array<ThreeDotsMenuItem> = [];
    @Input() filesPrefixUrl: string = "";

    @Output() menuClick = new EventEmitter<VersionListItemMenuClick>();
    @Output() actionFileClick = new EventEmitter<Objects.File>();

    public status: number = this.STATUS_LOADING;
    public opened: boolean = false;
    public files: Array<Objects.File> = [];
    public diffLabels: Array<Objects.Label> = [];
    public feedbacksList: Array<VersionFeedback> = [];
    
    constructor(private versionSrv: VersionsService ) { }

    ngOnInit() {
        if(this.version != null)
            this.updateDiffLabels();
        
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.feedbacks) {
            this.feedbacksList = this.feedbacks;
        }

        if(changes.exclude)
            this.updateDiffLabels();
    }

    toogleFiles() {
        this.opened = !this.opened;

        if(this.status == this.STATUS_DONE)
            return;

        this.versionSrv.getFiles$(this.version, this.filesPrefixUrl).subscribe((files: Array<Objects.File>) => {
            // console.debug(" LOAD FILES VERSION files",files)
            this.files = files;
            this.status = this.STATUS_DONE;
        })

    }


    onMenuSelect(key: string) {
        this.menuClick.emit({key: key, version: this.version});
    }

    public getApprovalColor(approval: Approval){
        if (approval != null)
            return this.FEEDBACK_COLORS[approval.status_code];
        return this.FEEDBACK_ONLY_COMMENT_COLOR;
    }

    private updateDiffLabels() {
        this.diffLabels = this.version.labels.filter((label: Objects.Label) => !this.exclude.some((l) => l.id == label.id));
    }

}
