import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilesListModule } from '@core/components/files/lists/lists.module';

import {LabelsModule } from '../labels/labels.module';
import {HelpersModule } from '../helpers/helpers.module';
import { StatusModule } from '../status/status.module';

import { VersionApprovalModule } from './version-approval/version-approval.module';
import { VersionsRelatedModule } from './versions-related/versions-related.module';
import { VersionPreviewModule } from './version-preview/version-preview.module';
import { VersionStatusUpdateMenuModule } from './status-update-menu/status-update-menu.module'
import { VersionFeedbacksModule } from './feedbacks/feedbacks.module';

import { VersionsSummaryBoxComponent } from './summary-box/summary-box.component';

//import { VersionsThumbnailModule } from './versions-thumbnail/versions-thumbnail.module';

import { PreviewModule } from '@core/components/preview/preview.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VersionsDetailBoxComponent } from './detail-box/detail-box.component';
import { VersionsDetailPreviewFileComponent } from './detail-preview-file/detail-preview-file.component';
import { VersionsApprovalBoxComponent } from './approval-box/approval-box.component';

@NgModule({
    imports: [
        CommonModule,
        VersionApprovalModule,
        VersionsRelatedModule,
        VersionPreviewModule,
        VersionFeedbacksModule,
        //VersionsThumbnailModule,
        FormsModule,
        LabelsModule,
        HelpersModule,
        ReactiveFormsModule,
        StatusModule,
        FilesListModule,
        VersionStatusUpdateMenuModule,
        PreviewModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        //VersionPreviewComponent,
        VersionsSummaryBoxComponent,
        VersionsDetailBoxComponent,
        VersionsDetailPreviewFileComponent,
        VersionsApprovalBoxComponent


    ],
    exports: [
        VersionApprovalModule,
        VersionsRelatedModule,
        // VersionsThumbnailModule,
        VersionPreviewModule,
        VersionsSummaryBoxComponent,
        VersionsDetailBoxComponent,
        VersionStatusUpdateMenuModule,
        VersionFeedbacksModule,
        VersionsDetailPreviewFileComponent,
        VersionsApprovalBoxComponent

    ],

})
export class VersionsModule { }
