import { Action } from '@ngrx/store';
// import { User } from '@core/objects/user';
// import { UserRequest } from '@core/objects/user-request';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatus } from '@core/classes/http-status.class';

import { AvailableRuleItem, AvailableRuleActionVariable, AvailableRuleEventCondition, AvailableRuleAction, RuleEvent, RuleListItem, RuleAction, RuleCondition } from '@app/models/rules';

export enum SettingsRulesActionType {

	// EVENT CALLS
	LoadAvailableEvents = "[SettingsRules] GET - Obtain all available events",
	LoadAvailableEventsSuccess = "[SettingsRules] - Obtain all available events SUCCESS",
	LoadAvailableEventsError = "[SettingsRules] - Obtain all available events ERROR",

	LoadAvailableEvent = "[SettingsRules] - Obtain all available event properties (actions and conditions)",

	LoadAvailableConditions = "[SettingsRules] GET - Obtain all available event conditions",
	LoadAvailableConditionsSuccess = "[SettingsRules] - Obtain all available event conditions SUCCESS",
	LoadAvailableConditionsError = "[SettingsRules] - Obtain all available event conditions ERROR",

	LoadAvailableActions = "[SettingsRules] GET - Obtain all available event actions",
	LoadAvailableActionsSuccess = "[SettingsRules] - Obtain all available event actions SUCCESS",
	LoadAvailableActionsError = "[SettingsRules] - Obtain all available event actions ERROR",

	LoadRules = "[SettingsRules] GET - Obtain all persisted rules events",
	LoadRulesSuccess = "[SettingsRules] - Obtain all persisted rules events SUCCESS",
	LoadRulesError = "[SettingsRules] - Obtain all persisted rules events ERROR",

	SetActiveRule = "[SettingsRules] - PUT set active property of rule",
	SetActiveRuleSuccess = "[SettingsRules] - set active property of rule SUCCESS",
	SetActiveRuleError = "[SettingsRules] - set active property of rule ERROR",

	LoadRule = "[SettingsRules] - GET load persisted rule event",
	LoadRuleSuccess = "[SettingsRules] - load persisted rule event SUCCESS",
	LoadRuleError = "[SettingsRules] - load persisted rule event ERROR",

	ClearRule = "[SettingsRules] - clear editing rule",

    SetRuleEventKey = "[SettingsRules] - set event key in editing rule",
    SetRuleDescription = "[SettingsRules] - set rule description in editing rule",
    SetRuleConditionsControl = "[SettingsRules] - set event condition control in editing rule",

    AddRuleAction = "[SettingsRules] - add new rule action in editing rule",
    ChangeRuleAction = "[SettingsRules] - change rule action in editing rule",
    ChangeRuleActionKey = "[SettingsRules] - change rule action key in editing rule",
    RemoveRuleAction = "[SettingsRules] - remove rule action in editing rule",

    AddRuleCondition = "[SettingsRules] - add new rule condition in editing rule",
    ChangeRuleCondition = "[SettingsRules] - change existing rule condition in editing rule",
    ChangeRuleConditionVariable = "[SettingsRules] - change existing rule condition variable in editing rule",
    RemoveRuleCondition = "[SettingsRules] - remove rule condition in editing rule",

    SaveRule = "[SettingsRules] - save editing rule",
    UpdateRule = "[SettingsRules] PUT - save editing rule",
    CreateRule = "[SettingsRules] POST - save editing rule",
    SaveRuleSuccess = "[SettingsRules] - save editing rule SUCCESS",
    SaveRuleError = "[SettingsRules] - save editing rule ERROR",

    SetEditSelectScroll = "[SettingsRules] - save editing multiselect scroll"
}

// Load Available Events
export class LoadAvailableEvents implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableEvents;
    constructor() {}
}
export class LoadAvailableEventsSuccess implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableEventsSuccess;
    constructor(public events: AvailableRuleItem[]) {}
}
export class LoadAvailableEventsError implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableEventsError;
    constructor() {}
}

export class LoadAvailableEvent implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableEvent;
    constructor(public eventKey: string) {}
}

// Load Available Event Conditions
export class LoadAvailableConditions implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableConditions;
    constructor(public eventKey: string) {}
}
export class LoadAvailableConditionsSuccess implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableConditionsSuccess;
    constructor(public eventKey: string, public conditions: AvailableRuleEventCondition[]) {}
}
export class LoadAvailableConditionsError implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableConditionsError;
    constructor() {}
}

// Load available Event Actions
export class LoadAvailableActions implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableActions;
    constructor(public eventKey: string) {}
}

export class LoadAvailableActionsSuccess implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableActionsSuccess;
    constructor(public eventKey: string, public actions: AvailableRuleAction[]) {}
}

export class LoadAvailableActionsError implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableActionsError;
    constructor() {}
}

// Load persisted rules list
export class LoadRules implements Action {
    readonly type = SettingsRulesActionType.LoadRules;
    constructor() {}
}

export class LoadRulesSuccess implements Action {
    readonly type = SettingsRulesActionType.LoadRulesSuccess;
    constructor(public rules: RuleListItem[]) {}
}

export class LoadRulesError implements Action {
    readonly type = SettingsRulesActionType.LoadAvailableActionsError;
    constructor() {}
}

// active / deactive actions
export class SetActiveRule implements Action {
    readonly type = SettingsRulesActionType.SetActiveRule;
    constructor(public ruleId: number, public active: boolean) {}
}

export class SetActiveRuleSuccess implements Action {
    readonly type = SettingsRulesActionType.SetActiveRuleSuccess;
    constructor() {}
}

export class SetActiveRuleError implements Action {
    readonly type = SettingsRulesActionType.SetActiveRuleError;
    constructor(public ruleId: number, public active: boolean) {}
}

export class LoadRule implements Action {
    readonly type = SettingsRulesActionType.LoadRule;
    constructor(public ruleId: number) {}
}

export class LoadRuleSuccess implements Action {
    readonly type = SettingsRulesActionType.LoadRuleSuccess;
    constructor(public rule: RuleEvent) {}
}

export class LoadRuleError implements Action {
    readonly type = SettingsRulesActionType.LoadRuleError;
    constructor() {}
}

export class ClearRule implements Action {
    readonly type = SettingsRulesActionType.ClearRule;
    constructor() {}
}

export class SetRuleEventKey implements Action {
    readonly type = SettingsRulesActionType.SetRuleEventKey;
    constructor(public key: string) {}
}

export class SetRuleDescription implements Action {
    readonly type = SettingsRulesActionType.SetRuleDescription;
    constructor(public description: string) {}
}

export class SetRuleConditionsControl implements Action {
    readonly type = SettingsRulesActionType.SetRuleConditionsControl;
    constructor(public control: string) {}
}

export class AddRuleAction implements Action {
    readonly type = SettingsRulesActionType.AddRuleAction;
    constructor(public position: number) {}
}

export class ChangeRuleAction implements Action {
    readonly type = SettingsRulesActionType.ChangeRuleAction;
    constructor(public position: number, public action: RuleAction) {}
}

export class ChangeRuleActionKey implements Action {
    readonly type = SettingsRulesActionType.ChangeRuleActionKey;
    constructor(public position: number, public key: string) {}
}

export class RemoveRuleAction implements Action {
    readonly type = SettingsRulesActionType.RemoveRuleAction;
    constructor(public position: number) {}
}

export class AddRuleCondition implements Action {
    readonly type = SettingsRulesActionType.AddRuleCondition;
    constructor(public position: number) {}
}

export class ChangeRuleCondition implements Action {
    readonly type = SettingsRulesActionType.ChangeRuleCondition;
    constructor(public position: number, public condition: RuleCondition) {}
}

export class ChangeRuleConditionVariable implements Action {
    readonly type = SettingsRulesActionType.ChangeRuleConditionVariable;
    constructor(public position: number, public variable: string) {}
}

export class RemoveRuleCondition implements Action {
    readonly type = SettingsRulesActionType.RemoveRuleCondition;
    constructor(public position: number) {}
}

export class SaveRule implements Action {
    readonly type = SettingsRulesActionType.SaveRule;
    constructor() {}
}

export class CreateRule implements Action {
    readonly type = SettingsRulesActionType.CreateRule;
    constructor(public rule: RuleEvent) {}
}

export class UpdateRule implements Action {
    readonly type = SettingsRulesActionType.UpdateRule;
    constructor(public id: number, public rule: RuleEvent) {}
}

export class SaveRuleSuccess implements Action {
    readonly type = SettingsRulesActionType.SaveRuleSuccess;
    constructor(public rule: RuleEvent) {}
}

export class SaveRuleError implements Action {
    readonly type = SettingsRulesActionType.SaveRuleError;
    constructor() {}
}

export class SetEditSelectScroll implements Action {
    readonly type = SettingsRulesActionType.SetEditSelectScroll;
    constructor(public key: string, public scroll: number) {}
}

export type SettingsRulesActions = 	LoadAvailableEvents |
									LoadAvailableEventsSuccess |
									LoadAvailableEventsError |

									LoadAvailableConditions |
									LoadAvailableConditionsSuccess |
									LoadAvailableConditionsError |

									LoadAvailableActions |
									LoadAvailableActionsSuccess |
									LoadAvailableActionsError |

									LoadRules |
									LoadRulesSuccess |
									LoadRulesError |

									SetActiveRule |
									SetActiveRuleSuccess |
									SetActiveRuleError |

									LoadRule |
									LoadRuleSuccess |
									LoadRuleError |

									ClearRule |

                                    SetRuleEventKey |
                                    SetRuleDescription |
                                    SetRuleConditionsControl |

                                    AddRuleAction |
                                    ChangeRuleAction |
                                    ChangeRuleActionKey |
                                    RemoveRuleAction |

                                    AddRuleCondition |
                                    ChangeRuleCondition |
                                    ChangeRuleConditionVariable |
                                    RemoveRuleCondition |

                                    SaveRule |
                                    UpdateRule |
                                    CreateRule |
                                    SaveRuleSuccess |
                                    SaveRuleError |

                                    SetEditSelectScroll
									;
