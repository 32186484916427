import { EventEmitter } from '@angular/core';

import { ApolloContentComparisonResultMark } from './apollo-content-compared-result-mark.model';
import { ApolloContentInspectorProcessWord } from './apollo-content-inspector-process-words.model';


export interface ApolloContentApprovalItem {
    ref: number | null,
    tar: number | null,
    status: number;
};

export class ApolloContentApprovals {
    public approvals:  Array<ApolloContentApprovalItem> = [];

    protected avalaibleFilters: Array<string> = ["accepted", "rejected"];
    public activeFilters: Array<string> = [];

    public setApproval(ref: number | null, tar: number | null, status: number) {
        let rid = ref === -1? null : ref;
        let tid = tar === -1? null : tar;

        this.approvals = this.approvals.filter(a => !(a.ref === rid && a.tar === tid));

        if(status==0) return;

        this.approvals.push({
            ref: rid,
            tar: tid,
            status: status
        })

    }

    public getApprovalByIds(ref: number, tar: number) : ApolloContentApprovalItem {
        let approvals = this.approvals.filter(a => a.ref == ref && a.tar == tar);

        return approvals.length > 0? approvals[0] : { ref: ref, tar: tar, status: 0};
    }

    public getApprovalStatusByIds(ref: number, tar: number) : number {
        let appr = this.getApprovalByIds(ref, tar);
        return appr == null? 0 : appr.status;
    }

    public getApprovalByTargetId(tar: number) : ApolloContentApprovalItem {
        let approvals = this.approvals.filter(a => a.tar == tar);

        return approvals.length > 0? approvals[0] : { ref: null, tar: tar, status: 0};
    }

    public getApprovalByReferenceId(ref: number) : ApolloContentApprovalItem {
        let approvals = this.approvals.filter(a => a.ref == ref);

        return approvals.length > 0? approvals[0] : { ref: ref, tar: null, status: 0};
    }


    public getApprovalsByStatus(status: number) : ApolloContentApprovalItem[] {
        return this.approvals.filter((a:ApolloContentApprovalItem) => a.status == status);
    }
    public getTarIdsByStatus(status: number) {
        return this.getApprovalsByStatus(status).map(a => a.tar);
    }

    public setFilter(filter: string) {
        let actives = this.activeFilters.filter(f => f==filter);
        let avalaible = this.avalaibleFilters.filter(f => f==filter);

        if(avalaible.length > 0 && actives.length == 0)
            this.activeFilters.push(filter);
    }

    public unsetFilter(filter: string) {
        this.activeFilters = this.activeFilters.filter(f => f!=filter);
    }

    public isStatusFilterActive(status: number) : boolean {
        //let appr = this.getApprovalStatusByTargetIds(target.id);

        let filter : string = "";

        switch(status) {
            case 1:
                filter = "accepted";
                break;
            case 2:
                filter = "rejected";
                break;
        }

        //return (this.activeFilters.length == 0 && appr.status == 0) ? true : this.activeFilters.indexOf(filter) > -1;
        return this.activeFilters.indexOf(filter) > -1;
    }

    public getApproval(word:  ApolloContentInspectorProcessWord | ApolloContentComparisonResultMark) : ApolloContentApprovalItem {
        let appr = {ref: null, tar: null, status: 0};

        switch(word.type) {
            case "ref":
            case "reference":
                appr = this.getApprovalByReferenceId(word.id);
                break;
            case "tar":
            case "target":
                appr = this.getApprovalByTargetId(word.id);
                break;
        }

        return appr;
    }
    public isFilterActive(word:  ApolloContentInspectorProcessWord | ApolloContentComparisonResultMark) : boolean {
        let appr = this.getApproval(word);

        if(this.activeFilters.length == 0 && appr.status == 0)
            return true;

        let filter : string = "";

        switch(appr.status) {
            case 1:
                filter = "accepted";
                break;
            case 2:
                filter = "rejected";
                break;
        }

        //return (this.activeFilters.length == 0 && appr.status == 0) ? true : this.activeFilters.indexOf(filter) > -1;
        return this.activeFilters.indexOf(filter) > -1;
    }

}
