import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { File } from '@core/objects/file';
import { Related } from '@core/objects/related';
import { Version } from '@core/objects/version';

@Component({
    selector: 'core-versions-related-files-list-checkbox',
    templateUrl: './versions-related-files-list-checkbox.component.html',
    styleUrls: ['./versions-related-files-list-checkbox.component.scss']
})
export class VersionsRelatedFilesListCheckboxComponent implements OnInit {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly ERROR = 2;

    @Input() related: Related;
    @Input() disabled: boolean;
    @Input()  selected: Array<File>;
    @Output() selectedChange = new EventEmitter<Array<File>>();

    public status:number = this.LOADING;
    public versions: Array<Version> = [];

    constructor(private httpClient: HttpClient, private urlSrv: UrlService ) { }

    ngOnInit() {
        this.loadRelatedVersions();
    }

    private loadRelatedVersions() {
        this.httpClient.get(this.urlSrv.build("/versions?link="+this.related.id)).subscribe(
            (data:Array<Version>) => {
                this.versions = data;
                this.status = this.DONE;
            },
            (error:any) => {
                this.status = this.ERROR;
            }
        );
    }

    onChange(files: Array<File>) {
        this.selected = files;
        this.selectedChange.emit(this.selected);
    }

}
