import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as fromSettingsUsersState from '@app/store/state/settings/users/users.state';
import * as fromSettingsUsersActions from '@app/store/state/settings/users/users.actions';

import * as fromSettingsLicensesState from '@app/store/state/settings/licenses/licenses.state';
import * as fromSettingsLicensesActions from '@app/store/state/settings/licenses/licenses.actions';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    constructor(private store: Store<fromSettingsUsersState.SettingsUsers>, public http: HttpClient) {}

	// Selectors store Info
	selectorGetAllUsers(){
		return this.store.select(fromSettingsUsersState.usersGetAllUsersSelector);
	}

	selectorGetAllInvitations(){
		return this.store.select(fromSettingsUsersState.usersGetAllInvitationsSelector);
	}


	// Selectos store STATUS
	selectorStatusList(){
		return this.store.select(fromSettingsUsersState.usersStatusListSelector);
	}

	selectorStatusInvite(){
		return this.store.select(fromSettingsUsersState.usersStatusInviteSelector);
	}

	selectorStatusAddInvite(){
		return this.store.select(fromSettingsUsersState.usersStatusAddInviteSelector);
	}

	selectorStatusDelInvite(){
		return this.store.select(fromSettingsUsersState.usersStatusDelInviteSelector);
	}

	selectorStatusDelUser(){
		return this.store.select(fromSettingsUsersState.usersStatusDelUserSelector);
	}

	// Actions
	loadAllUsers(){
		// GET
		//console.log('loadAllUsers --- service');
		this.store.dispatch( new fromSettingsUsersActions.LoadAllUsers());
	}

	loadAllInvitations(){
		// GET
		this.store.dispatch( new fromSettingsUsersActions.LoadAllInvitations());
	}

	addNewInvitation(name:string, email:string, message:string, groups:Array<number>, extra: any){
		// POST
		this.store.dispatch( new fromSettingsUsersActions.AddNewInvitation(name, email, message, groups, extra));
	}

	deleteOneUser(id:string){
		// DELETE
		this.store.dispatch( new fromSettingsUsersActions.DeleteOneUser(id));
	}

	deleteInvitation(email:string){
		this.store.dispatch( new fromSettingsUsersActions.DeleteInvitation(email));
	}

	setStatusInvitationStore(){
		this.store.dispatch(new fromSettingsUsersActions.AddNewInvitationSuccess());
	}

    // licenses
    loadAllLicenses(){
		// GET
		this.store.dispatch( new fromSettingsLicensesActions.LoadAllLicenses());
	}

    selectorGetAllLicenses(){
		return this.store.select(fromSettingsLicensesState.licensesGetAllLicensesSelector);
	}
}
