import { Injectable } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root'
})
export class BrowserService {

    constructor(private device: DeviceDetectorService) {}

    public  isValidBrowser(): boolean {
        return this.device.getDeviceInfo().browser != "IE";
    }
}
