// angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// ngrx
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

// import { appReducers } from '@stores/app.reducers';

// Environments
import { environment } from '@environment/environment';

// Components
// import { IndexComponent } from '@home/index/index.component';
// import { TwonaModule } from './modules/twona.module';
// import { ImportsModule } from '@imports';

import { PluginAppModule } from '@plugins/plugin-app.module';
import { LayoutModule } from '@app/components/layout/layout.module';
import { ShareModule } from '@app/components/share/share.module';
import { SectionsModule } from '@app/components/sections/sections.module';
import { AppHelpersModule } from '@app/components/helpers/helpers.module';
import { AppMainComponent } from '@app/components/layout/main/main.component';
import { AppInterceptorsProvider } from './app.interceptors';

// stores
import {rootReducers, metaReducers } from './store/state/root/root.reducer';
import { rootEffects } from './store/state/root/root.effects';
// import {AppEffects } from '@app/store/state/app/app.effects';
// import { SettingsUsersEffects } from './store/state/settings/users/users.effects';
// import { SettingsGroupsEffects } from './store/state/settings/groups/groups.effects';
// import { SettingsLicensesEffects } from './store/state/settings/licenses/licenses.effects';
// import { SettingsRulesEffects } from './store/state/settings/rules/rules.effects';

@NgModule({
    imports: [
        // TwonaModule,
        BrowserModule,
        HttpClientModule,
        // MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        PluginAppModule,
        ShareModule,
        LayoutModule,
        SectionsModule,
        AppHelpersModule,
        // stores
        StoreModule.forRoot(rootReducers, { metaReducers }),
        !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
        EffectsModule.forRoot([...rootEffects])
    ],
    providers: [
        AppInterceptorsProvider
        //{provide: Window, useValue: window}
    ],
    bootstrap: [AppMainComponent]
})
export class AppModule { }
