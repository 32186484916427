import { Component, ElementRef, ViewChild, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HostListener } from '@angular/core';

import { Viewer } from '@core/components/preview/preview-page-viewer/viewer';

import { Note } from '@core/objects/note';
import { File } from '@core/objects/file';

import { NotesViewerStatus, NotesViewer } from './notes-viewer';
import { NoteBuilder } from './note-builder';

import {VNote, VNoteController } from './virtual-note';

@Component({
    selector: 'core-notes-viewer',
    templateUrl: './notes-viewer.component.html',
    styleUrls: ['./notes-viewer.component.scss']
})
export class NotesViewerComponent implements OnInit, OnChanges {
    @ViewChild('scroll') scroll: ElementRef;
    //@ViewChild('figure1', { read: ElementRef }) public figure1: ElementRef<any>;
    @ViewChild('notesContainer') notesContainer: ElementRef;
    @ViewChild('bounds') notesBounds: ElementRef;

    @Input() file: File;
    @Input() page: number = 1;
    @Input() notes: Array<Note> = [];
    @Input() canCreate: boolean = true;
    @Input() selected: Note = null;

    @Output() onClick = new EventEmitter<any>();

    // public page: number = 1;
    public previewLoaded = false;

    public typeSelected: string = "point";

    protected indexNotes: Array<string> = []; // this is an array for search the index of the note

    //public vnotes: Array<VNote> = [];

    public vnotesCtrl: VNoteController;

    // public viewers:any = {};
    // public viewerStatus = new NotesViewerStatus();
    public viewerCtrl = new NotesViewer();
    protected viewerSubscriptor: any;

    ngOnInit() {
        this.vnotesCtrl = new VNoteController(this.notes);
        this.viewerSubscriptor = this.viewerCtrl.onChange.subscribe(data => this.onViewerUpdate());

    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.page) {
            //this.page = changes.page.currentValue - 1;
            this.previewLoaded = false;
            if(changes.page.currentValue > 0) {
                this.viewerCtrl.setPage(changes.page.currentValue);
                // this.onViewerUpdate()
            }
                // this.createViewer(changes.page.currentValue);
        }

        if(changes.notes) {
            //this.indexNotes = this.notes.map( n=> JSON.stringify(n));
            //this.makeIndexes();
            this.vnotesCtrl = new VNoteController(this.notes);
        }

        if(changes.selected) {
            console.debug("Note selected!");
            if(this.selected !== null && this.selected.page+1 == this.page)
                this.onScrollToNote();
        }

        if(changes.file) {
            this.viewerCtrl = new NotesViewer();
            this.viewerCtrl.setPage(this.page);
            this.viewerSubscriptor = this.viewerCtrl.onChange.subscribe(data => this.onViewerUpdate());
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    onWindowClose(event: any): void {

        if(this.vnotesCtrl.hasUnsavedNotes()) {
            event.preventDefault();
            event.returnValue = false;
        }
    }

    
    // protected createViewer(page: number) {
    //     if(!this.viewers.hasOwnProperty(page.toString()))
    //         this.viewers[page] = new Viewer();
    
    // }

    protected onScrollToNote() {
        if(this.selected === null)
            return;

        let position = this.vnotesCtrl.getByNote(this.selected).getPosition();
        this.scroll.nativeElement.scrollTop = position.y * this.notesContainer.nativeElement.clientHeight - (this.scroll.nativeElement.clientHeight/2);
    }

    protected resetScroll() {
        this.scroll.nativeElement.scrollTop = 0;
        //this.scroll.nativeElement.scrollLeft = 0;
    }


    makeNote(event) {
        if(!this.canCreate)
            return;
        // let builder = new NoteBuilder(this.notesContainer.nativeElement.offsetWidth, this.notesContainer.nativeElement.offsetHeight);
        let builder = new NoteBuilder();

        let note = builder.create(event, this.viewerCtrl.status[this.page], this.page, this.typeSelected);
        
        this.vnotesCtrl.add(note);
        //console.debug(this.vnotesCtrl.vnotes);
    }

    protected makeIndexes() {
        this.indexNotes = this.notes.map( n=> JSON.stringify(n));
    }

    sendNotes(){
        //console.debug(this.notes);
        //this.onClick.emit(this.notes);
        //this.onClick.emit(this.vnotesCtrl.getNotes());
        this.onClick.emit(this.vnotesCtrl.getUnsavedNotes());
    }

    deleteNote(note: Note, vnote:VNote) {
        this.vnotesCtrl.remove(vnote);
        // this.notes = this.notes.filter(n => {
        //     return !('temporal_id' in n) || n.temporal_id != note.temporal_id;
        // });
    }

    private getNewNotes() : Array<any> {
        return this.vnotesCtrl.getNotes().filter(n => n.id == 0);
    }

    previewLoad(load) {
        this.previewLoaded = load;
        if(load) {
            this.resetScroll();
            this.onScrollToNote();
        }
    }

    moveNote(position: any, vnote: VNote){
        //console.debug("old percent", position.note.x_pos, position.note.y_pos);
        //console.debug("new move", position.x_move, position.y_move);
        //console.debug(" old position in px", position.note.x_pos * this.getLayerWidth(), position.note.y_pos * this.getLayerHeight());
        //console.debug(" new position in px", (position.note.x_pos * this.getLayerWidth()) + position.x_move, (position.note.y_pos * this.getLayerHeight()) + position.y_move);

        let x = ((position.note.x_pos * this.getLayerWidth() ) + position.x_move) / this.getLayerWidth();
        let y = ((position.note.y_pos * this.getLayerHeight()) + position.y_move) / this.getLayerHeight();

        this.vnotesCtrl.setPosition(vnote, x, y);
        // let notes = this.notes;
        // this.notes = [];
        //
        // notes.map(n => {
        //     //if( n.x_pos == position.note.x_pos &&
        //     //    n.y_pos == position.note.y_pos &&
        //     //    n.page == position.note.page) {
        //     if(('temporal_id' in n) && n.temporal_id == position.note.temporal_id) {
        //         n.x_pos = x;
        //         n.y_pos = y;
        //     }
        //
        //     this.notes.push(n);
        // });
        //
        // console.log("notas movidas", this.notes);

    }

    protected getContainerLayer() {
        return this.notesContainer.nativeElement;
    }

    protected getLayerWidth(): number {
        // return this.getContainerLayer().offsetWidth;
        return this.viewerCtrl.viewers[this.page].getNormalizeSize().width;
    }

    protected getLayerHeight(): number {
        // return this.notesContainer.nativeElement.offsetHeight;
        return this.viewerCtrl.viewers[this.page].getNormalizeSize().height;
    }

    // public selectNotesPerPage(notes) {
    //     return notes.filter(n => { return n.page == this.legacyPage; });
    // }

    public index(note): number {
        //let indexNotes = this.notes.map( n=> JSON.stringify(n));
        let index = this.indexNotes.indexOf(JSON.stringify(note));
        if(index === -1)
            index = this.indexNotes.length;
        return index + 1;
    }

    isSelected(note: Note) : boolean {
        if(this.selected === null)
            return false;

        if('id' in note) {
            return this.selected.id === note.id;
        } else
            return true;
    }

    viewerAction(event: any, action: string) {
        event.preventDefault();

        switch(action) {
            case "zoom-in":
                this.viewerCtrl.viewers[this.page].zoomIn();
                break;
            case "zoom-out":
                this.viewerCtrl.viewers[this.page].zoomOut();
                break;
            case "rotate":
                this.viewerCtrl.viewers[this.page].rotate();
                break;
        }

        this.onViewerAction(this.viewerCtrl.viewers[this.page]);
    }

    onViewerAction(event: Viewer) {
        console.log("neeewwww sizes", event.getNormalizeSize());
        // let nsize = event.getNormalizeSize();

        // this.notesBounds.nativeElement.style.height = nsize.height + "px";
        // this.notesBounds.nativeElement.style.width = nsize.width + "px";
        // this.notesContainer.nativeElement.style.height = nsize.height + "px";
        // this.notesContainer.nativeElement.style.width = nsize.width + "px";

        this.onViewerUpdate();
    }

    onViewerUpdate() {
        // TODO not useful
        let viewer = this.viewerCtrl.viewers[this.page];
        let nsize = viewer.getNormalizeSize();

        this.notesBounds.nativeElement.style.height = nsize.height + "px";
        this.notesBounds.nativeElement.style.width = nsize.width + "px";
        this.notesContainer.nativeElement.style.height = nsize.height + "px";
        this.notesContainer.nativeElement.style.width = nsize.width + "px";

        // this.viewerStatus.setContainerSize(nsize.width, nsize.height);
        // this.viewerStatus.setRotation(viewer.rotation);
    }

}
