import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';

@Component({
    selector: 'core-files-selected-box',
    templateUrl: './files-selected-box.component.html',
    styleUrls: ['./files-selected-box.component.scss']
})
export class FilesSelectedBoxComponent implements OnInit {

    @Input() title: string;
    @Input() boxDefaultText: string = 'select a file below'; // default text inside the box 
    @Input() files: Array<File> = [];

    @Input() buttonAction: boolean = false; // if extra button is necessary
    @Input() buttonActionText: string = "";
    @Output() onButtonActionClick = new EventEmitter<any>();

    @Input() fileActionText: string = "x";
    @Output() onFileActionClick = new EventEmitter<File>();
    constructor() { }

    ngOnInit() {

    }
}
