import { SelectItem } from '@core/models/select-item.model';

export const opacityOptions : SelectItem[] = [
    {
        title: '100%',
        value: '100',
        selected: false
    },
    {
        title: '90%',
        value: '90',
        selected: false
    },
    {
        title: '80%',
        value: '80',
        selected: false
    },
    {
        title: '70%',
        value: '70',
        selected: false
    },
    {
        title: '60%',
        value: '60',
        selected: false
    },
    {
        title: '50%',
        value: '50',
        selected: false
    },
    {
        title: '40%',
        value: '40',
        selected: false
    },
    {
        title: '30%',
        value: '30',
        selected: false
    }
];
