import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';

import { CommonModule } from '@angular/common';

import { AppHelpersModule } from '@app/components/helpers/helpers.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { FilesModule } from '@core/components/files/files.module';

import { ShareSendBoxComponent } from './send-box/send-box.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AppHelpersModule,
        ReactiveFormsModule,
        HelpersModule,
        FilesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ShareSendBoxComponent,
        
    ],
    exports: [
        ShareSendBoxComponent,
    ],

})
export class ShareModule { }
