import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as Objects from '@core/objects'

@Component({
    selector: 'app-helpers-search-filters-labels-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})
export class HelpersSearchFiltersLabelsGroupComponent {


    @Input()  labels: Array<Objects.Label> = [];
    @Output() choose = new EventEmitter<Objects.Label>();

    public placeholder: string = "Search tags in category";
    public input: string = "";
    public labelsFiltered: Array<Objects.Label> = [];

    public open: boolean = false;

    selectLabel(label: Objects.Label) {
        console.log(" chossseeeee label",label);
        this.choose.emit(label);
    }

    onEnter(event) {
        event.preventDefault();
    }

}