import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LayoutTitlesModule } from '@core/components/layout/titles/titles.module';

import { DashboardSearchMainComponent } from './main/main.component';
import { AppHelpersSearchFiltersModule } from 'app/components/helpers/search/filters/filters.module';
import { AppHelpersSearchBarModule } from 'app/components/helpers/search/bar/bar.module';
import { DashboardSearchFiltersComponent } from './filters/filters.component';

@NgModule({
    imports: [
        CommonModule,
        HelpersModule,
        LayoutTitlesModule,
        AppHelpersSearchFiltersModule,
        AppHelpersSearchBarModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        DashboardSearchMainComponent,
        DashboardSearchFiltersComponent
    ],
    exports: [
        DashboardSearchMainComponent
    ],

})
export class DashboardSearchModule{ }
