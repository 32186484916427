import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter  } from '@angular/core';
import { HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Subscription,Observable } from 'rxjs';
import { Router } from "@angular/router";
import { WorkflowsService } from '../../../../../services/settings/workflows.service';

import { Workflow, WorkflowTransition } from '@core/objects/workflow';
// import { Status } from '@core/objects/status';
import { RequestStatus, RequestStatusWithInitial } from '@core/objects/request-status';

// import { WorkflowEditor, WorkflowEditorData } from '../workflow-editor';
import { WorkflowEditor, WorkflowEditorData } from './editor';

@Component({
    selector: 'settings-workflows-workflow-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss']
})
export class SettingsWorkflowsWorkflowEditorComponent implements OnInit, OnChanges {

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

    @Input() workflow: Workflow;
    // @Input() stages: Array<Status>;
    // @Input() saved: Observable<void>;
    // @Output() submit:EventEmitter<any> = new EventEmitter<any>();

    public status: Number = this.STATUS_LOADING;
    // public editor: any;

    // public showInstructions: boolean =  false;
    // protected projectStages: Array<any> = [];
    // private savedSubscription: Subscription;

    // --
    private editorConfig: any = {
        width: 600,
        height: 400,
        target: "figure#workflow-editor-area"
    };

    // private e: WorkflowEditor
    // private data: WorkflowEditorData;
    private e: any;
    public data: WorkflowEditorData = null;

    private output:EventEmitter<any> = new EventEmitter<any>();
    public valid: boolean = false;
    protected editing: boolean = false;


	constructor(private workflowsSrv: WorkflowsService, private router: Router){}

	ngOnInit(){
        // this.savedSubscription = this.saved.subscribe(() => this.hideSaveButton());

		//this.init();

        this.output.subscribe((data) => this.receiveData(data));

	}

    ngOnChanges(changes: SimpleChanges) {
        if(changes.workflow && changes.workflow.currentValue != null)
            this.init();
    }

    @HostListener('window:beforeunload', ['$event'])
    onWindowClose(event: any): void {

        if(this.editing) {
            event.preventDefault();
            event.returnValue = false;
        }
    }

    init(){
        this.loadTransitions();
        this.editing = false;
        // this.loadEditor();

    }

    protected loadTransitions(){
        this.status = this.STATUS_LOADING;
        this.workflowsSrv.getAllWorkflowTransitions$(this.workflow.id).subscribe((transitions: Array<WorkflowTransition>) => {
            console.debug(" transitions ",transitions);
            // this.transitions = data;
            // this.parseTransitions();
            // console.debug(" procesed data projects for editor ",this.projectStages);
            this.data = new WorkflowEditorData(this.workflow, transitions);
            this.initEditor(this.data);
            this.status = this.STATUS_DONE;
        });
    }

    protected initEditor(data: WorkflowEditorData) {
        // TODO set width of page area in editor config

        // this.e = new WorkflowEditor(this.editorConfig, data);

        this.e = new WorkflowEditor({
            target: "figure#workflow-editor-area",
            width: 1130,
            height: 1000,
            update: this.output
        }, data.status, data.transitions);
    }

    receiveData(event: any) {
        this.editing = true;
        this.data.setOutput(event);
        this.valid = this.data.isOutputValid();
        console.log("update data---->", event);
    }

    save() {
        this.workflowsSrv.updateWorkflowTransitions$(this.data.output,this.workflow.id).subscribe((data: any) => {
            //this.loadTransitions();

            //don't reload editor after save.
            this.data.resetOutput();
            this.editing = false;
            this.valid = this.data.isOutputValid();
        });
    }

    cancel() {
        this.editing = false;
        this.data.resetOutput();
        this.valid = this.data.isOutputValid();
        // this.loadTransitions();
        this.router.navigate(['/settings/workflows']);
    }
    // protected loadEditor(data: WorkflowEditorData){
    //     this.editor = null;
    //     this.editor = new workflowEditor({
    //         target: "#settings-workflow-editor-area",
    //         width: 1130,
    //         height: 1000,
    //         save: this.saveWorkflow
    //     }, data);
    // }


    // the old way seems not work with the jquery of the twona-workflow-editor.js.
//     saveWorkflow = (event: any) => {
//         if (event.hasOwnProperty('initial_status')){
//             console.log("save here: ", event);
//             // this.submit.emit(event);
//         } else {
//             console.error("NO INITIAL STATUS SET ");

// // TODO notify to set initial status with some message!
//         }
//     }

    // listener of action save transitions done and saved
    protected hideSaveButton() {
        console.debug("saved done, hide button in editor");
        // this.editor.hiddeSaveButton();
    }

    // cleanForm(){
	// 	this.form.reset({name: ''});
	// }

}
