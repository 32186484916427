import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, empty, of, throwError, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SettingsLicensesRequests } from './licenses.requests';

import { User } from '@core/objects/user';
import { License } from '@core/objects/license';

import * as fromStore from './licenses.state';

import {
	SettingsLicensesActions,
    SettingsLicensesActionType,

    LoadAllLicenses,
    LoadAllLicensesSuccess,
    LoadAllLicensesError,

	SetAllLicenses

} from './licenses.actions';

@Injectable()
export class SettingsLicensesEffects {

    constructor(private actions$: Actions, private store$: Store<fromStore.SettingsLicenses>, private licensesReq: SettingsLicensesRequests) {}

    @Effect()
    public loadAllLicenses$: Observable<SettingsLicensesActions> = this.actions$.pipe(
        ofType<LoadAllLicenses>(SettingsLicensesActionType.LoadAllLicenses),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap(() => {
            return this.licensesReq.loadAllLicenses$().pipe(
                map((licenses: License[]) => {
                    return new LoadAllLicensesSuccess(licenses);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new LoadAllLicensesError());
                })
            );
        })
    );

    @Effect()
    public loadAllLicensesSuccess$: Observable<SettingsLicensesActions> = this.actions$.pipe(
        ofType<LoadAllLicensesSuccess>(SettingsLicensesActionType.LoadAllLicensesSuccess),
        mergeMap((action) => {
            return of(new SetAllLicenses(action.licenses));
        })
    );

    //
	// @Effect()
    // public loadAllInvitations$: Observable<SettingsUsersActions> = this.actions$.pipe(
    //     ofType<LoadAllInvitations>(SettingsUsersActionType.LoadAllInvitations),
    //     //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
    //     switchMap(() => {
    //         return this.usersReq.loadAllInvitations$().pipe(
    //             map((invitations: UserRequest[]) => {
    //                 return new LoadAllInvitationsSuccess(invitations);
    //             }),
    //             catchError((err: any) => {
    //                 console.log(err);
    //                 throwError(err);
    //                 return of(new LoadAllInvitationsError(err));
    //             })
    //         );
    //     })
    // );
    //
    // @Effect()
    // public loadAllInvitationsSuccess$: Observable<SettingsUsersActions> = this.actions$.pipe(
    //     ofType<LoadAllInvitationsSuccess>(SettingsUsersActionType.LoadAllInvitationsSuccess),
    //     mergeMap((action) => {
    //         return of(new SetAllInvitations(action.invitations));
    //     })
    // );
    //
    //
    // @Effect()
    // public addNewInvitation$: Observable<SettingsUsersActions> = this.actions$.pipe(
    //     ofType<AddNewInvitation>(SettingsUsersActionType.AddNewInvitation),
    //     //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
    //     mergeMap((action) => {
    //         return this.usersReq.addNewInvitation$(action.name, action.email, action.message, action.groups).pipe(
    //             map(() => {
    //                 return new AddNewInvitationSuccess();
    //             }),
    //             catchError((err: any) => {
    //                 console.log(err);
    //                 throwError(err);
    //                 return of(new AddNewInvitationError(err));
    //             })
    //         );
    //     })
    // );
    //
    // @Effect()
    // public addNewInvitationSuccess$: Observable<SettingsUsersActions> = this.actions$.pipe(
    //     ofType<AddNewInvitationSuccess>(SettingsUsersActionType.AddNewInvitationSuccess),
    //     mergeMap(() => {
    //         return of(new LoadAllInvitations());
    //     })
    // );
    //
    // @Effect()
    // public deleteOneUser$ : Observable<SettingsUsersActions> = this.actions$.pipe(
    //     ofType<DeleteOneUser>(SettingsUsersActionType.DeleteOneUser),
    //     //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
    //     mergeMap((action) => {
    //         return this.usersReq.deleteOneUser$(action.id).pipe(
    //             map(() => {
    //                 return new DeleteOneUserSuccess()
    //             }),
    //             catchError((err: any) => {
    //                 console.log(err);
    //                 throwError(err);
    //                 return of(new DeleteOneUserError());
    //             })
    //         );
    //     })
    // );
    //
    //
    // @Effect()
    // public deleteOneUserSuccess$: Observable<SettingsUsersActions> = this.actions$.pipe(
    //     ofType<DeleteOneUserSuccess>(SettingsUsersActionType.DeleteOneUserSuccess),
    //     mergeMap(() => {
    //         return of(new LoadAllUsers());
    //     })
    // );
    //
    // @Effect()
    // public deleteInvitation$ : Observable<SettingsUsersActions> = this.actions$.pipe(
    //     ofType<DeleteInvitation>(SettingsUsersActionType.DeleteInvitation),
    //     //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
    //     mergeMap((action) => {
    //         return this.usersReq.deleteInvitation$(action.email).pipe(
    //             map(() => {
    //                 return new DeleteInvitationSuccess();
    //             }),
    //             catchError((err: any) => {
    //                 console.log(err);
    //                 throwError(err);
    //                 return of(new DeleteInvitationError());
    //             })
    //         );
    //     })
    // );
    //
    // @Effect()
    // public deleteInvitationSuccess$: Observable<SettingsUsersActions> = this.actions$.pipe(
    //     ofType<DeleteInvitationSuccess>(SettingsUsersActionType.DeleteInvitationSuccess),
    //     mergeMap(() => {
    //         return of(new LoadAllInvitations());
    //     })
    // );
}
