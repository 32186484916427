import {Component, OnInit, SimpleChanges, OnChanges, OnDestroy, Input, Optional, Inject, Output, EventEmitter, HostListener} from '@angular/core';
import { Subscription } from 'rxjs';

// import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';
import { PreviewRequest} from '@core/objects/preview-request';
import { PreviewsService } from '@core/services/previews/previews.service';

// import { Viewer } from './viewer';

@Component({
  selector: 'core-preview-viewer',
  templateUrl: './preview-viewer.component.html',
  styleUrls: ['./preview-viewer.component.scss']
})
export class PreviewViewerComponent implements OnInit, OnChanges, OnDestroy {

    @Input() file: File;

    protected preview$ : Subscription = null;
    public preview: PreviewRequest = null;
    public page:number = 1;
    public imageLoaded: boolean = true;

    constructor(private prwSrv: PreviewsService){}

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.file && changes.file.currentValue != null) {
            this.init();
        }
    }

    ngOnDestroy() {
        if (this.preview$ != null)
            this.preview$.unsubscribe();
    }

    protected init() {
        this.page = 1;
        this.preview = null;
        if(this.preview$ != null)
            this.preview$.unsubscribe();

        this.preview$ = this.prwSrv.getSubscriptor(this.file).subscribe(preview => {
            this.preview = preview;
        });

        this.prwSrv.load(this.file);
    }

    selectPage(event) {
        // console.log("select page --->", page);
        this.page = event.page;
        this.imageLoaded = false;
    }

    previewLoad(event: any) {
        console.log("preview load-->", event);
        this.imageLoaded = event;
    }

}
