import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'core-helpers-box-alert-try-again',
    templateUrl: './alert-try-again.component.html',
    styleUrls: ['./alert-try-again.component.scss']
})
export class HelperBoxAlertTryAgainComponent {

    @Input() text: string;
    @Input() textButton: string = "Try again";

    @Output() tryAgain = new EventEmitter<boolean>();

    try() {
        this.tryAgain.emit(true);
    }

}
