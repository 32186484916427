import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

// import { UrlService } from '@core/services/url/url.service';
import { NotesService } from '@core/services/notes/notes.service';
import { File } from '@core/objects/file';
import { Note } from '@core/objects/note';

@Component({
    selector: 'core-files-notes-action-list',
    templateUrl: './files-notes-action-list.component.html',
    styleUrls: ['./files-notes-action-list.component.scss']
})
export class FilesNotesActionListComponent implements OnInit, OnChanges {

    @Input() files: Array<File>;
    @Input() urlPrefix: string = "";
    @Input() notesActionText: string;
    @Input() notesActionStyle: string;
    // @Input() button: boolean = true;
    @Output() buttonClick = new EventEmitter<File>();
    // @Output() bubbleClick = new EventEmitter<any>();

    // public actionText: string = "Add notes";
    public notes: Array<Note> = [];
    public bubbleTexts: Array<string> = [];

    // public loaded: boolean = false;

    constructor(private notesSrv: NotesService) { }

    ngOnInit() {
        // if(!this.button)
        //     this.actionText = "";

        // this.initBubbleTexts();
        // this.loadNotes();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.files) {
            // console.error("files updates");
            if(this.files.length > 0)
                this.loadNotes();
        }
    }

    onClick(file: File) {
        // if(!this.button)
        //     return false;

        this.buttonClick.emit(file);
    }

    protected loadNotes() {
        this.notesSrv.findByMultipleFiles(this.files, this.urlPrefix).subscribe((data: Array<Note>) => {
            this.notes = data;
            this.initBubbleTexts();
            // this.loaded = true;
        });
    }

    protected getNotes(file: File) : Array<Note> {
        return this.notes.filter(n => n.file.id == file.id);
    }

    initBubbleTexts() {
        this.bubbleTexts = this.files.map(f => this.getNotes(f).length.toString()).map(s => s == "0"? "" : s);
    }




}
