import { AppActions, AppActionTypes } from "./app.actions";
import { App, initialState, AppStatusValues} from "./app.state";

export function appReducer(state = initialState, action: AppActions): App {
    switch (action.type) {
    //case AppActionTypes.LoadSessionRequest:
    //  return { ...state, session: null };

    case AppActionTypes.LoadSessionSuccess:
        return { ...state, session: action.payload };

    case AppActionTypes.SetVersion:
        // let version = {
        //     hash: action.version,
        //     checked: new Date(),
        //     changed: false
        // };
        //
        // if((state.version.hash !== null && state.version.hash !== version.hash) || state.version.changed)
        //     version.changed = true;

        return {
                ...state,
                version: {
                    ...state.version,
                    hash: action.version,
                    checked: new Date(),
                    changed: state.version.changed || (state.version.hash !== null && state.version.hash !== action.version)
                },
                status: {
                    value: (state.version.hash !== null && state.version.hash !== action.version)? AppStatusValues.VERSION_CHANGED : state.status.value,
                    checked: new Date() 
                }
            };
    case AppActionTypes.Disconnected:
        return {
            ...state,
            status: {
                value: AppStatusValues.DISCONNECTED,
                checked: new Date()
            }
        };
    case AppActionTypes.UpdateUserSuccess:
        return {
            ...state,
            session: {
                ...state.session,
                user: action.user
            }

        };

    default:
        return state;
    }
}
