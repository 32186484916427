import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { HelperNavigationThreeDotsMenuComponent } from './menu/menu.component';
import { MenuIconComponent } from './icon/icon.component';
import { PipesModule } from '../../../../pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        PipesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        HelperNavigationThreeDotsMenuComponent,
        MenuIconComponent
    ],
    exports: [
        HelperNavigationThreeDotsMenuComponent,
        MenuIconComponent
    ],

})
export class HelperThreeDotsMenuModule { }
