import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HelperBoxModule } from '@core/components/helpers/box/box.module';
//import { CoreModule } from '@core/core.module';

// components
import { HelpersFormInputEmailAutocompleteComponent } from './input-emails-autocomplete/input-emails-autocomplete.component';
import { HelpersFormSelectUsersAutocompleteComponent } from './select-users-autocomplete/select-users-autocomplete.component';
import { HelpersFormAddObjectFilesUploadComponent } from './add-object-files-upload/add-object-files-upload.component';
import { FilesModule } from '@core/components/files/files.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HelperBoxModule,
        FilesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        HelpersFormInputEmailAutocompleteComponent,
        HelpersFormSelectUsersAutocompleteComponent,
        HelpersFormAddObjectFilesUploadComponent
    ],
    exports: [
        HelpersFormInputEmailAutocompleteComponent,
        HelpersFormSelectUsersAutocompleteComponent,
        HelpersFormAddObjectFilesUploadComponent
    ],

})
export class AppHelperFormModule { }
