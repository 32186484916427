import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelperBoxModule } from './box/box.module';
import { HelperFormModule } from './form/form.module';
import { HelperIconsModule } from './icons/icons.module';
import { HelperListModule } from './list/list.module';
import { HelperNavigationModule } from './navigation/navigation.module';

@NgModule({
    imports: [
        CommonModule,
        HelperBoxModule,
        HelperFormModule,
        HelperIconsModule,
        HelperListModule,
        HelperNavigationModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [

        //AppApprovalComponents,
    ],
    exports: [
        //AppApprovalComponents,
        HelperBoxModule,
        HelperFormModule,
        HelperIconsModule,
        HelperListModule,
        HelperNavigationModule
    ],

})
export class HelpersModule { }
