import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterModule } from "@angular/router";

import { Subscription, interval } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';
import { TitleService } from '@core/services/title.service';

import { ApolloService } from '../../../../services/apollo/apollo.service';
import { ApolloHistory } from '../../../../model/apollo-history.model';

@Component({
    selector: 'plugin-apollo-main-section-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class PluginApolloMainSectionHistoryComponent implements OnInit, OnDestroy {
    readonly uploadUrl: string = "/p/apollo/upload/";

    public howTo: boolean;

    public loading: boolean = true;
    public list: Array<any> =[];
    public page: number = 0;

    public history: ApolloHistory = {
        items: [],
        last: false
    };


    protected subscriptionHistory: Subscription;


    constructor(public apolloSrv: ApolloService, public urlSrv: UrlService, private router: Router, private title: TitleService){}

    ngOnInit() {
        this.title.set("History - XRay");
        this.subscriptionHistory = this.apolloSrv.selectHistory().subscribe((history: ApolloHistory) => {
            this.history = history;
            console.debug(history);
            this.loading = false;
        });

        this.loadMore();

    }

    ngOnDestroy(){
        this.subscriptionHistory.unsubscribe();
    }

    loadMore() {
        this.apolloSrv.loadHistory(++this.page);
        this.loading = true;
    }

    goToUpload(hash) {
        // clean upload
        this.apolloSrv.reset();
        // router to upload
        this.router.navigate([this.uploadUrl+hash]);

    }

    url(compare) : string {
        return "/p/apollo/upload/" + compare.hash;
    }

    setName(hash, name) {
        this.apolloSrv.setNameCompareHistory(hash, name);
    }
}
