
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { VersionsService } from '@app/services/app/versions.service';


import * as Objects from '@core/objects';
import { AppService } from '@app/services/app/app.service';
import { VersionFeedback } from '@core/models/version-feedback';

export interface ExtendedVersionFeedback extends VersionFeedback {
    share: any;
}

@Component({
    selector: 'versions-details-content-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class VersionsDetailsContentInfoComponent implements OnInit, OnChanges{

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    public readonly VIEW_APPROVAL_NONE = 0;
    public readonly VIEW_APPROVAL_APPROVE_MODAL = 1;
    public readonly VIEW_APPROVAL_REJECT_MODAL = 2;
    public readonly VIEW_APPROVAL_DONE = 3;
    public readonly VIEW_APPROVAL_ERROR = 4; 
    public readonly VIEW_APPROVAL_APPROVE_SIGNATURE = 5;
    public readonly VIEW_APPROVAL_SIGNATURE_ERROR = 6;
    

    public readonly PLUGIN_FEEDBACK = 'share_feedback';
    
    @Input() version: Objects.Version;
    @Input() feedbacks: Array<VersionFeedback>;
    @Output() feedbackDone  = new EventEmitter<any>();

    public status: number = 0;
    public view: number = this.VIEW_APPROVAL_NONE;
    public accessFeedbacks: boolean = false;
    public feedback: VersionFeedback = null;
    public userVersionFeedback: ExtendedVersionFeedback = null;

    private user: Objects.User = null;
    private approveMessage: string = '';
    public approveDisabled: boolean = false;
    public rejectDisabled: boolean = false;
    public signDisabled: boolean = false;

    protected appSubscriptor: Subscription;

    constructor (
        private versionsSrv: VersionsService,
        public appSrv: AppService,
        private router: Router
    ){}

    ngOnInit(): void {

        this.appSubscriptor = this.appSrv.getSession().subscribe( (session: any) => {
            this.accessFeedbacks = session.options.plugins.some((pa) => pa == this.PLUGIN_FEEDBACK);
            this.user = session.user;
            
            this.loadMyFeedback(this.version);
            this.loadUserVersionFeedback();
        });


        
    }

    ngOnDestroy() {
        this.appSubscriptor.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.feedbacks)
            this.loadMyFeedback(this.version);
    }

    onApprove(event: any) : void {
        // view approve modal popup
        this.view = this.VIEW_APPROVAL_APPROVE_MODAL;
    } 

    onReject(event: any) : void {
        // view reject modal popup
        this.view = this.VIEW_APPROVAL_REJECT_MODAL;
    } 

    signApprove(pass: string):void {
        this.signDisabled = true;
        if(this.userVersionFeedback != null){
            this.versionsSrv.giveFeedback$(this.version,this.userVersionFeedback.share.entry.hash, this.approveMessage, true, pass).subscribe(
                (data:any) => {
                    this.feedbackDone.emit();
                    console.debug("Approved sent "+this.version.id,data);
                    this.loadMyFeedback(this.version);
                    this.view = this.VIEW_APPROVAL_DONE;
                    this.approveMessage = '';
                    this.signDisabled = false;
                },
                (error:any) => {
                    if(error.status == 403){
                        this.approveMessage = '';
                        this.view = this.VIEW_APPROVAL_SIGNATURE_ERROR;
                    }else{
                        this.view = this.VIEW_APPROVAL_ERROR;
                    }
                    this.signDisabled = false;
                    
                }
            );
        }
    }

    public approve(message: string){
        this.approveDisabled = true;
        this.approveMessage = message;
        if(this.userVersionFeedback != null){
            this.versionsSrv.giveFeedback$(this.version,this.userVersionFeedback.share.entry.hash, this.approveMessage, true).subscribe(
                (data:any) => {
                    this.feedbackDone.emit();
                    console.debug("Approved sent "+this.version.id,data);
                    this.loadMyFeedback(this.version);
                    this.view = this.VIEW_APPROVAL_DONE;
                    this.approveMessage = '';
                    this.approveDisabled = false;
                },
                (error:any) => {
                    if(error.status == 422){
                        // this.approveMessage = '';
                        this.view = this.VIEW_APPROVAL_APPROVE_SIGNATURE;
                    }else{
                        this.view = this.VIEW_APPROVAL_ERROR;
                    }
                    this.approveDisabled = false;
                }
            );
        }
    }

    public reject(message: any){
        this.rejectDisabled = true;
        if(this.userVersionFeedback != null){
            this.versionsSrv.giveFeedback$(this.version,this.userVersionFeedback.share.entry.hash , message, false, null).subscribe(
                (data:any) => {
                    this.feedbackDone.emit();
                    console.debug("Rejected sent "+this.version.id,data);
                    this.loadMyFeedback(this.version);
                    this.view = this.VIEW_APPROVAL_DONE;
                    this.rejectDisabled = false;
                },
                (error:any) => {
                    this.view = this.VIEW_APPROVAL_ERROR;
                    this.rejectDisabled = false;
                }
            );
        }
    }

    public dismiss(e:any){
        this.view = this.VIEW_APPROVAL_NONE;
    }

    public retry(e:any){
        switch(this.view) {
            case this.VIEW_APPROVAL_SIGNATURE_ERROR:
                this.view = this.VIEW_APPROVAL_APPROVE_MODAL;
                break;
            case this.VIEW_APPROVAL_ERROR:
                this.status = this.VIEW_APPROVAL_NONE;
                break;
            default:
                this.status = this.VIEW_APPROVAL_NONE;
                break;
        }
    }

    public onFeedbackSent(e){
        this.view = this.VIEW_APPROVAL_NONE;
    }

    protected loadMyFeedback(version: Objects.Version){
        if (!this.accessFeedbacks){
            this.status = this.STATUS_DONE;
            return;
        }

        // TODO call /web/app/p/feedback/versions/{vid}/users/{userId}
        if (this.accessFeedbacks && this.version != null && this.feedbacks !=null){
            this.feedback = null;
            let myFBs = this.feedbacks.filter((f: VersionFeedback) => f.user.id == this.user.id );
            this.feedback = myFBs.length > 0 ? myFBs[0] : null;
            // console.log(" My feedback is ",this.feedback);
            this.status = this.STATUS_DONE;
        }
    }

    protected loadUserVersionFeedback(){
        this.versionsSrv.getVersionShareFeedback$(this.version, this.user).subscribe((versionFeedback: ExtendedVersionFeedback[]) => {
            let myVF = versionFeedback.filter((f: ExtendedVersionFeedback) => f.version.id == this.version.id );
            this.userVersionFeedback = (myVF.length > 0) ? myVF[0] : null;
        });
    }

    

}
