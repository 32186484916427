import { EventEmitter  } from '@angular/core';
import * as d3 from 'd3';

import { RequestStatus, RequestStatusWithInitial } from "@core/objects/request-status";
import { Workflow, WorkflowTransition } from '@core/objects/workflow';

export interface WorkflowEditorTransition {
	origin: number | string;
	target: number | string;
}

export interface WorkflowEditorOutput {
	status: Array<RequestStatusWithInitial>;
	transitions: Array<WorkflowEditorTransition>;
}
// export interface WorkflowEditorConfig {
//     width: number;
//     height: number;
//     target: string;
// }

export class WorkflowEditorData {
	public status: Array<RequestStatusWithInitial> = [];
	// public transitions: Array<WorkflowEditorTransition> = [];
	public transitions: Array<any> = [];

	// output data processed from editor
	public output: WorkflowEditorOutput = {
		status: [],
		transitions: []
	};

    constructor(private workflow: Workflow, private trans: Array<WorkflowTransition> ) {
		this.process(workflow, trans);
	}

	private process(workflow: Workflow, transitions: Array<WorkflowTransition>) {
		if(transitions.length == 0)
			return;

		// get all status
		let status: Array<RequestStatus> = [];

		transitions.forEach((t: WorkflowTransition) => {
			if(!status.some((s) => s.id == t.origin.id))
				status.push(t.origin);

			if(!status.some((s) => s.id == t.target.id))
				status.push(t.target);
		});

		// transitions.forEach((t: WorkflowTransition) => {
		// 	// if(!this.status.some((s) => s.id == t.origin.id))
		// 	// 	status.push(t.origin);

		// 	if(!this.status.some((s) => s.id == t.target.id))
		// 		status.push(t.target);
		// });

		// set property `initial` in status
		this.status = status.map((s: RequestStatus) => {
			// s['initial'] = s.id == workflow.initial_status.id;

			return {...s, initial: s.id == workflow.initial_status.id};
			// return s;
		});

		// set transitions with position of status
		// this.transitions = transitions.map((t) => {
		// 	return {
		// 		origin: this.status.map((s) => s.id).indexOf(t.origin.id),
		// 		target: this.status.map((s) => s.id).indexOf(t.target.id)
		// 	};
		// });

		this.transitions = transitions;
	}

	public setOutput(data: WorkflowEditorOutput) {
		this.output = this.cleanOutput(data);
	}

	private cleanOutput(data: WorkflowEditorOutput) {
		let transitions = data.transitions.filter((t) => t.origin != "-1" && t.target != "-1");

		return {
			status: data.status,
			transitions: transitions
		}
	}

	public isOutputValid() : boolean {
		// console.log("output---->", this.output);

		let hasInitial = this.output.status.filter((s) => s.initial).length == 1;

		// check if has any status without transition
		let indexes = [];

		this.output.transitions.forEach((t:WorkflowEditorTransition) => {
			if(!indexes.some((s) => s == t.origin))
				indexes.push(t.origin);

			if(!indexes.some((s) => s == t.target))
				indexes.push(t.target);
		});

		let anyStatusWithoutTransition = this.output.status.filter((s) => s != null).length == indexes.length;

		// console.log("indexs", indexes, anyStatusWithoutTransition);
		//this.output.transitions
		return this.output.status.length > 0 && hasInitial && anyStatusWithoutTransition;
	}

	public resetOutput() {
		this.output = {
			status: [],
			transitions: []
		};
	}

}

d3.selection.prototype.moveToFront = function() {
  return this.each(function(){
    this.parentNode.appendChild(this);
  });
};

d3.selection.prototype.moveToBack = function() {
    return this.each(function() {
        var firstChild = this.parentNode.firstChild;
        if (firstChild) {
            this.parentNode.insertBefore(this, firstChild);
        }
    });
};

export var WorkflowEditor = function(options, stages, transitions) {
	// data
	var colors = ['#E87DB0', '#E88C7D', '#E8A97D', '#E8D57D','#8CE87D','#7DE8C2','#7DD5E8','#7D9BE8','#B97DE8'];
	var svg = null;
	var status = {};
	var tempId = -1;
	var addStageEnabled = true;

	// config
	var config = {
        width: 600,
        height: 600,
		helpMessage: "Double click in this area for new stage.",
		prefixNewStageName: "Stage #",
        // save: function(editor){},
		update: new EventEmitter<any>(),
		target: ""
    }

	var graphic = {
		buttonRadio: 20,
		buttonMargin: 10,
        buttonHeight: 28,
        buttonWidth: 28,
		inputHeight: 30,
		inputWidth: 180,
		statusColorsRadio: 60,
		statusColorsInterval: 150/colors.length,
        statusNameLimitSplit: 10,
        statusNameMaxLength: 19,
		statusExternalRadio: 60,
		statusExternalRadioActive: 72,
		initialRadio: 8,
		statusMainRadio: 50,
		transitionDeleteRadio: 10,
        trashFileSvg: "assets/workflow-editor-trash.svg",
        trashWidth: 50,
		textFont: "15px Montserrat"
	}

    var dragPosition = {
        x: null,
        y: null
    }

    var eventDispatcher = d3.dispatch("statusDragging", "statusStop", "statusUpdate");
	var root = this; // important!

	this.construct = function(options, stages, transitions){
        // $.extend(config , options);
		config = {...config, ...options};

        //listeners
        eventDispatcher.on("statusUpdate", function() {
            root.statusUpdate();
            // root.showSaveButton();
			root.emit();
        });

		// reset space
		var oldSvg = d3.select('svg');
			if(oldSvg) oldSvg.remove();

        var s = d3.select(config.target)
          .append('svg')
          .attr('oncontextmenu', 'return false;')
          .attr('width', config.width)
          .attr('height', config.height)
		  .on("dblclick", (d, i) => {
			d3.event.preventDefault();
			// console.error("new stage!!", d, i, d3.event);
			// d.fx = d3.event.x;
			// d.fy = d3.event.y;
			if(addStageEnabled)
				this.addStage(d3.event.offsetX, d3.event.offsetY);
		});
		  //.on('click', root.addNewState);

		// container
		svg = s.append('svg:g')
        .classed("dragging", false);

		this.help = svg.append('svg:text')
            .attr('id', 'help')
			.attr('class', 'active')
            .attr('y', (config.height/2)-10)
		    .attr('x', (config.width/2)-150)
		    // .attr("text-anchor", "middle")
            .text(config.helpMessage);

		//svg.attr("transform", "translate(" + d3.event.translate + ") scale(" + d3.event.scale + ")");
		// root.drawButtonsArea(); // remove buttons 2021/08/19 punta umbría (huelva)
		root.addMarkerDefs();
		  //root.drawState();
		  //circle = svg.append('svg:g').selectAll('g');

        // load default states and transitions
        root.setData(stages, transitions);

        // hidden save button
        // root.hiddeSaveButton(); // remove buttons
        //d3.select('g.button.save').classed('active', false);

        //root.getData();
	}

	this.emit = function() {
		// root.statusUpdate();
		root.toogleHelp();
		config.update.emit(root.getData());
	}

	this.statusCount = function() {
		var length = 0;
		d3.selectAll('g.status').each((d, i) => length++);
		return length;
	}

	this.toogleHelp = function() {
		root.help.attr('class', 'active')
		if(root.statusCount() > 0)
			root.help.attr('class', 'hidden');
	}

	this.addStage = function(x, y) {
		// console.log("event---->", d3.event);
		var count = root.statusCount() + 1;
		// console.log(x, y, count);

		root.deactiveAllStatus();
        // TODO fix position
        // var mouse = d3.mouse(this);

        // get new status name
		var newName = config.prefixNewStageName + count;
        // if (newName !=''){
			var init = root.statusCount() == 0;
            root.drawStatus(++tempId, newName,x, y, colors[0], 0, init, true);
            // root.showSaveButton();
			root.emit();
        // }

	}
    // listener statusUpdate event
    this.statusUpdate = function(element) {
        if( typeof element === 'undefined') {
            // update all active status
            //d3.selectAll('g.status.active').each(function(d, i){
            d3.selectAll('g.status').each(function(d, i){
                var e = d3.select(this);
                root.setStatusData(e);
            });
        } else
            root.setStatusData(element);
    }

    this.setData = function(stages, transitions) {
        // draw status
        // $.each(stages, function(i, item) {
        //     root.drawStatus(i, item.name, item.x, item.y, item.color, item.id, item.initial, false);
        //     tempId = i; // increase status count

        // });
		console.log("stages--->", stages);
		stages.forEach((item, i) => {
			root.drawStatus(i, item.name, item.x, item.y, item.color, item.id, item.initial, false);
            tempId = i; // increase status count
		});

        // draw transitions departing from each status must be AFTER DRAW THE STATUS
        // $.each(stages, function(i, item) {
        //     var currentTrans = transitions.filter((t) => {return t.origin.id == item.id;});
        //     $.each(currentTrans, function(x, trans) {
        //         //get get index of target status
        //         var targetIndex = stages.findIndex(st => st.id == trans.target.id);
        //         root.addNewTransition(i,targetIndex , false)
        //     });
        // });

		//console.log("transssss--->", transitions);
		stages.forEach((item, i) => {
            var currentTrans = transitions.filter((t) => t.origin.id == item.id);
            currentTrans.forEach((trans, x) => {
                //get get index of target status
                var targetIndex = stages.findIndex(st => st.id == trans.target.id);
                // root.addNewTransition(i, targetIndex , false)
				this.setDrawTransition(i, targetIndex, false);
            });
        });

		root.toogleHelp();
    }

    this.getData = function() {
        var projectStatus = [];
        var data = {transitions:[], initial_status: null, status: []};

        // get all status
        d3.selectAll('g.status').each(function(d, i){
            var e = d3.select(this);
            var status = e.data()[0];
            if(status.initial){
                data.initial_status = status;
            }
            // console.log(" located "+i,status, "d",d,e.attr('id'))
            projectStatus[e.attr('id')] = status;
        });

        // get all transition
        d3.selectAll('g.transition').each(function(d, i){
            var t = d3.select(this);
            //set on the transition the  id and the origin and target status
            var transition = { origin: t.attr("origin"),
                              target: t.attr("target")};

            data.transitions[i] = transition;
        });
        // add status list on the editor
        data.status = projectStatus;
        return data;
    }

	this.addMarkerDefs = function(){
		// Add final marker
		svg.append('svg:defs').append('svg:marker')
            .attr('id', 'arrow-marker')
            .attr('viewBox', '-50 -5 10 10')
            .attr('refX', 2)
            .attr('markerWidth', 7)
            .attr('markerHeight', 7)
            .attr('orient', 'auto')
          .append('svg:path')
            .attr('d', 'M-50,-5L-40,0L-50,5');
            //.attr('fill', '#7EA2D5');

		// add final marker on active states
		svg.append('svg:defs').append('svg:marker')
            .attr('id', 'arrow-marker-on-active')
            .attr('viewBox', '-50 -5 10 10')
            .attr('refX', 10)
            .attr('markerWidth', 7)
            .attr('markerHeight', 7)
            .attr('orient', 'auto')
          .append('svg:path')
            .attr('d', 'M-50,-5L-40,0L-50,5');
            //.attr('fill', '#7EA2D5');

		// Add dragging marker
		svg.append('svg:defs').append('svg:marker')
            .attr('id', 'arrow-marker-drag')
            .attr('viewBox', '0 -5 10 10')
            .attr('refX', 6)
            .attr('markerWidth', 5)
            .attr('markerHeight', 5)
            .attr('orient', 'auto')
          .append('svg:path')
            .attr('d', 'M0,-5L10,0L0,5');
            //.attr('fill', 'red');

	}

	this.drawButtonsArea = function() {
		var position = [graphic.buttonRadio+graphic.buttonMargin, graphic.buttonRadio+graphic.buttonMargin];

		// buttons for add status
		var buttonsArea = svg.append('svg:g')
			.attr('id', 'buttons');

		// button add status WITH TEXT
		var addButton = buttonsArea.append('svg:g')
			.attr("transform", "translate("+(graphic.buttonMargin*3+graphic.inputWidth)+","+(graphic.buttonMargin+1)+")")
	    		.attr('class', 'button add');
    		addButton.append('svg:rect')
			.attr("width", graphic.buttonWidth*4+60)
			.attr("height", graphic.buttonHeight);
		addButton.append('svg:text')
		    .attr('x',(graphic.buttonWidth*4+60)/2)
		    .attr('y',(graphic.buttonHeight/2+6))
		    .text('Add Stage');
		root.actionsButtons();

		// STATE NAME input
        // WARNING: foreign Object element has to have enough width and height
        //          either as attr or with css
        //          to show its contain from chrome version 72 (2019/02/25)
		var form = buttonsArea.append("foreignObject")
			.attr("width", graphic.inputWidth + 22)
			.attr("height", graphic.inputHeight + 13) // increase size to cout padding of the input element

		var stateName = form.append("xhtml:form")
			.append("input")
	    	.attr('class', 'new-state-text')
			.attr("placeholder", "New stage")
            .on("keypress", function() {
				this.focus();
                // IE fix
                if (!d3.event)
                    d3.event = window.event;
				// detect if intro key pressed
                var e = d3.event;
                if (e.keyCode == 13){
                    if (typeof(e.cancelBubble) !== 'undefined') // IE
                      e.cancelBubble = true;
                    if (e.stopPropagation)
                      e.stopPropagation();
                    e.preventDefault();
					// DRAW NEW STATE ON INTRO KEY
                    root.addNewStatus();
                }
			});

        // button save
        var saveButton = buttonsArea.append('svg:g')
            .attr("transform", "translate("+(graphic.buttonMargin*4+graphic.inputWidth+graphic.buttonWidth*4+60)+","+(graphic.buttonMargin+1)+")")
            .attr('class', 'button save')
            .classed("active", false)
            // .on('click',function(){ config.save(root.getData()); }); // removed 2021/08/19

        saveButton.append('svg:rect')
            .attr("width", graphic.buttonWidth*4+60)
            .attr("height", graphic.buttonHeight);

        saveButton.append('svg:text')
            //.attr('class', 'label')
            .attr('x',(graphic.buttonWidth*4+60)/2)
            .attr('y',(graphic.buttonHeight/2+6))
            //.attr('class', function(d){ return "id order"+d.id; })
            //.attr("text-anchor", "middle")
            .text('Save workflow');

        root.drawRemoveArea();
	}

    this.hiddeSaveButton = function() {
        d3.select('g.button.save').classed('active', false);
    }

    this.showSaveButton = function(){
        d3.select('g.button.save').classed('active',true);
    }

    this.drawRemoveArea = function() {
        // trash area
        var trashPositionX = config.width - graphic.trashWidth;
        var trashPositionY = 0;
        var trashWidth = graphic.trashWidth;
        var trashHeight = config.height;
        var iconWidth = graphic.buttonWidth*1.5;
        var iconHeight = graphic.buttonHeight*1.5;

		var trash = svg.append('svg:g')
			.attr('class', 'remove')
			.attr("transform", "translate("+trashPositionX+","+trashPositionY+")");


        trash.append("svg:rect")
            .attr("width", trashWidth)
            .attr("height", trashHeight)
            .classed('active', false)
            .on("mouseup", function(){})
            .on("mouseup.remove", root.removeStatusActive);


        trash.append("svg:image")
            .attr("xlink:href", graphic.trashFileSvg)
            .attr("width", iconWidth)
            .attr("height", iconHeight)
            .attr("x", trashWidth/2 - iconWidth/2)
            .attr("y", trashHeight/2 - iconHeight/2);

        eventDispatcher.on("statusDragging.trash", function(element) {
            d3.selectAll("g.remove").classed('active',true);
        });

        eventDispatcher.on("statusStop", function(element) {
            eventDispatcher.statusUpdate();

            var trashLimitX = config.width-graphic.trashWidth;
            var e = d3.select(element.parentNode).data()[0];
            // remove active status when mouse overtakes trash limit area
            if( e.x >= trashLimitX ){
                root.removeStatusActive();
            }

            d3.selectAll("g.remove").classed('active', false);

        });
        //d3.selectAll("g.transition[origin='"+origin+"'][target='"+target+"']").moveToBack();
    }

	// @deprecated
    this.removeStatusActive = function(d, i) {
		var s = d3.selectAll('g.status.active');

        // select transitions
        var transitionsTarget = d3.selectAll("g.transition[target='"+s.attr('id')+"']");
        // transition with this state as origin
        var transitionsOrigin = d3.selectAll("g.transition[origin='"+s.attr('id')+"']");
        // remove transitions and state
        transitionsTarget.remove();
        transitionsOrigin.remove();
		s.remove();

	}

	this.removeStatus = function(d, i) {
		var s = d3.select(this.parentNode);

        // select transitions
        var transitionsTarget = d3.selectAll("g.transition[target='"+s.attr('id')+"']");
        // transition with this state as origin
        var transitionsOrigin = d3.selectAll("g.transition[origin='"+s.attr('id')+"']");
        // remove transitions and state
        transitionsTarget.remove();
        transitionsOrigin.remove();
		s.remove();
		eventDispatcher.statusUpdate();
	}

	this.actionsButtons = function() {
		var addButton = d3.selectAll('g.button.add')
			.on("click", root.addNewStatus);

		//var delButton = d3.selectAll('g.button.del')
		//	.on("click", root.removeStatusActive);
	}

	this.addNewTransition = function(origin, target, active){
		//console.log("CREATING TRANS");
        if( typeof active === 'undefined'){
            active = true;
        }
		// var tr = root.drawTransition(origin, target, active);
        // root.reDrawTransition(origin, target);

		var tr = this.setDrawTransition(origin, target, active);

		eventDispatcher.statusUpdate();
		return tr;

	}

	this.setDrawTransition = function(origin, target, active) {
		var tr = root.drawTransition(origin, target, active);
        root.reDrawTransition(origin, target);

		return tr;
	}

	this.drawTransition = function(origin, target, active) {
        if( typeof active === 'undefined'){
            active = true;
        }

        var transition = svg.append('svg:g')
			.attr('class','transition')
			.classed('active', active)
			.attr('origin',origin)
			.attr('target',target);

		// Add the line
		transition.append('svg:path')
			.attr('class','line')
			.attr('d','M0,0L0,0')

		// Add the remove button
		var rmBtn = transition.append('svg:g')
			.on('mouseup', root.removeTransition);
		rmBtn.append('svg:circle')
			.attr('class', 'delete hidden')
			.attr('r',graphic.transitionDeleteRadio);

		// add remove cross
		rmBtn.append('svg:line')
			.attr('class','bw hidden')
			.attr("x1", -5).attr("y1", -5)
			.attr("x2", 5).attr("y2", 5);
		rmBtn.append('svg:line')
			.attr('class','fw hidden')
			.attr("x1", 5).attr("y1", -5)
			.attr("x2", -5).attr("y2", 5);

        // eventDispatcher.statusUpdate();

		return transition;
	}

	this.reDrawMarkers = function(active){
		d3.selectAll("g.transition.onactive").classed('onactive',false);
		d3.selectAll("g.transition[target='"+active+"']").classed('onactive',true);
	}

	// This function redraws the transitions of the given origin and target
	this.reDrawTransition = function(origin, target){
		// Move to the back
		d3.selectAll("g.transition[origin='"+origin+"'][target='"+target+"']").moveToBack();

		// Select the transitions that need to be redrawn
		var stateOrigin = d3.selectAll("g.status[id='"+origin+"']");
		// var stateTarget = d3.selectAll("g.status[id='"+target+"']");

		var originX = stateOrigin.attr('x');
		var originY = stateOrigin.attr('y');

		// var targetX = stateTarget.attr('x');
		// var targetY = stateTarget.attr('y');

		// put end of markers and lines in the edge of the node for active status
		if(stateOrigin.classed('active')){
			root.reDrawMarkers(origin);
		}

		// if(stateTarget.classed('active')){
		// 	root.reDrawMarkers(target);
		// }

		if(target != "-1") {
			var stateTarget = d3.selectAll("g.status[id='"+target+"']");
			var targetX = stateTarget.attr('x');
			var targetY = stateTarget.attr('y');

			if(stateTarget.classed('active')){
				root.reDrawMarkers(target);
			}
		} else {
			// var targetX = d3.event.sourceEvent.x;
			// var targetY = d3.event.sourceEvent.y;
			var targetX = d3.event.sourceEvent.offsetX;
			var targetY = d3.event.sourceEvent.offsetY;
		}


		// if(target == "-1")
		// {
		// 	var targetX = d3.event.sourceEvent.x;
		// 	var targetY = d3.event.sourceEvent.y;
		// }

		// DRAW THE LINE
		var newPosition = "M"+originX+","+originY+"L"+targetX+","+targetY;
		d3.selectAll("g.transition[origin='"+origin+"'][target='"+target+"'] path.line").attr('d',newPosition);

		// POSITION THE CLOSING BUTTON
		d3.selectAll("g.transition[origin='"+origin+"'][target='"+target+"'] circle")
			.classed('hidden', false)
			.attr('cx',(parseInt(targetX)-parseInt(originX))/2+parseInt(originX));
		d3.selectAll("g.transition[origin='"+origin+"'][target='"+target+"'] circle").attr('cy',(parseInt(targetY)-parseInt(originY))/2+parseInt(originY));

		// Positioning the X in closing button
		var xRef = (parseInt(targetX)-parseInt(originX))/2+parseInt(originX);
		var yRef = (parseInt(targetY)-parseInt(originY))/2+parseInt(originY);
		d3.selectAll("g.transition[origin='"+origin+"'][target='"+target+"'] line.bw")
			.classed('hidden', false)
			.attr("x1", xRef-5).attr("y1", yRef-5)
			.attr("x2", xRef+5).attr("y2", yRef+5);
		d3.selectAll("g.transition[origin='"+origin+"'][target='"+target+"'] line.fw")
			.classed('hidden', false)
			.attr("x1", xRef+5).attr("y1", yRef-5)
			.attr("x2", xRef-5).attr("y2", yRef+5);
	}

	// @deprecated
    this.addNewStatus = function() {
        root.deactiveAllStatus();
        // TODO fix position
        // var mouse = d3.mouse(this);

        // get new status name field and check empty
        var statusNameInput = d3.select('.new-state-text');
        var newName = statusNameInput.node().value.trim();
        statusNameInput.node().value = '';
        if (newName !=''){
            root.drawStatus(++tempId, newName,200, 200, colors[0], 0, false, true);
            root.showSaveButton();
        }

    }

	this.drawStatus = function( id, name, x, y, color, requestStatusId, init, active) {
        if( typeof requestStatusId === 'undefined' || requestStatusId === null ){
            requestStatusId = 0;
        }

        if( typeof active === 'undefined'){
            active = true;
        }

		// status object
		var status = svg.append('svg:g')
			.attr('id', id)
            .attr('requestStatusId', requestStatusId)
			.attr('class', 'status')
			.attr('x',x)
			.attr('y',y)
			.attr('ox',0) //This stores the offset with the mouse
			.attr('oy',0) //This stores the offset with the mouse
			.classed("active", active)
			.attr("transform", "translate("+ x + "," + y + ")");

		// circle external
		status.append('svg:circle')
			.attr('class', 'external')
			.attr('r', graphic.statusExternalRadio)
			.call(root.createTransition)
			.on('mouseup', root.closeTransition);

		// circle main
		status.append('svg:circle')
			.attr('class', 'main')
			.attr('r', graphic.statusMainRadio)
			.attr('fill', color)
			.on('mousedown', root.activeStatus)
            //.on('mouseup', function(){ eventDispatcher.statusStop(this)}) // maybe load to dragend
			.call(root.dragStatus);

		// state label
		status.append('svg:text')
			//.data([{label: name}])
            .property("label", name)
			.attr('class', 'label')
		    //.attr('x', 0)
		    .attr('y', 4)
		    //.attr('class', function(d){ return "id order"+d.id; })
		    .attr("text-anchor", "middle")
		    //.text(function(d, i) {return d.label})
            .text(function(d, i) {return d3.select(this).property("label")})
            .on('mousedown', root.activeStatus)
			.on('mouseup', root.statusLabelEdit)
			.call(root.textWrap);

		// colors
		status.append("svg:g")
		   .attr('class', "colors")
		   .classed("hidden", true)
		   .selectAll('g')
		   .data(colors)
		   .enter()
		   .append('svg:circle')
		   .attr('r', 7)
		   .on("mouseover", function(){d3.select(this)
			.attr("r", 8);})
			.on("mouseout", function(){d3.select(this)
			.attr("r", 7);})
		   .attr('fill', function(d, i) { return colors[i]; })
		   .attr('transform', function (d, i) {
				  //return "translate(" + ((rccc) * Math.cos((interval*i) * Math.PI/180)) + "," + ((rccc) * Math.sin((interval*i) * Math.PI/180)) + ")";
			   return "translate(" +((graphic.statusColorsRadio) * Math.sin((graphic.statusColorsInterval*i) * Math.PI/180))  + "," + ((graphic.statusColorsRadio) * Math.cos((graphic.statusColorsInterval*i) * Math.PI/180)*(-1)) + ")";
			})
			.on('click', function(d, i) { // click colors
				d3.select(this.parentNode.parentNode).select('circle.main').attr('fill', d);
                eventDispatcher.statusUpdate(); // update info
			});

		// initial state signal
		status.append('svg:circle')
			.attr('class', 'initial')
			.attr('r', graphic.initialRadio)
            .classed('active', init)
			.attr('transform', 'translate(0,-'+graphic.statusColorsRadio/2+')')
			.on('click', function(d, i) {
				root.setInitialStatus(this);
			})

		// remove button
		var rmBtn = status.append('svg:g')
			.attr('class', 'remove')
			.classed("hidden", true)
			.attr('transform', function (d, i) {
				return "translate(" +((graphic.statusColorsRadio) * Math.sin((315) * Math.PI/180))  + "," + ((graphic.statusColorsRadio) * Math.cos((315) * Math.PI/180)*(-1)) + ")";
			})
			.on('mouseup', root.removeStatus);

		rmBtn.append('svg:circle')
			.classed("hidden", true)
			.attr('r', 7)
 		    .on("mouseover", function(){d3.select(this)
 				.attr("r", 8);})
 			.on("mouseout", function(){d3.select(this)
 				.attr("r", 7);})
			// .attr('class', 'remove')
            // .classed('active', init)
			// .attr('transform', 'translate(0,-'+graphic.statusColorsRadio/2+')')
			// .attr('transform', function (d, i) {
			// 	//return "translate(" + ((rccc) * Math.cos((interval*i) * Math.PI/180)) + "," + ((rccc) * Math.sin((interval*i) * Math.PI/180)) + ")";
			//  return "translate(" +((graphic.statusColorsRadio) * Math.sin((315) * Math.PI/180))  + "," + ((graphic.statusColorsRadio) * Math.cos((315) * Math.PI/180)*(-1)) + ")";
		  	// })
			// .on('mouseup', root.removeTransition);

		// add remove cross
		rmBtn.append('svg:line')
			.attr('class','bw')
			.attr("x1", -3).attr("y1", -3)
			.attr("x2", 3).attr("y2", 3);
		rmBtn.append('svg:line')
			.attr('class','fw')
			.attr("x1", 3).attr("y1", -3)
			.attr("x2", -3).attr("y2", 3);
			// .append('svg:text')
			// .text("&#10060;")
			// .on('click', function(d, i) {
			// 	console.log("remove", this);
			// 	// root.setInitialStatus(this);
			// })
		// show button remove
		if( requestStatusId == 0 ){
			d3.selectAll('g.active g.remove')
				.classed("hidden", false)
			d3.selectAll('g.active g.remove circle')
				.classed("hidden", false)
			d3.selectAll('g.active g.colors')
				.classed("hidden", false)
			d3.selectAll('g.active circle.external')
				.attr('r',graphic.statusExternalRadioActive);
		}
        // set data
        root.setStatusData(status);
		//root.actionsStatus();
	}

    this.setStatusData = function(e) {
        //var name = e.select("text.label").text();
        //var name = e.select("text.label").data()[0].label;
        var name = e.select("text.label").property("label");
        var initial = e.select("circle.initial").classed("active");
        var color = d3.rgb(e.select("circle.main").style("fill"));

        var data = [{id: parseInt(e.attr('requestStatusId')),
                    color: color.toString().toUpperCase(),
                    initial: initial,
                    x: parseInt(e.attr('x')),
                    y: parseInt(e.attr('y')),
                    ox: parseInt(e.attr('ox')),
                    oy:  parseInt(e.attr('oy')),
                    name: name}]

        e.data(data);
    }

	this.statusLabelEdit = function(d, i) {
		addStageEnabled = false;
		var labelToEdit = d3.select(this);
        var value = labelToEdit.property("label");

		var putValue = function(txt, label) {
            // avoid blank new name in edition
            if(txt.trim() == ''){
                txt = labelToEdit.property("label");
            }
			label.text(txt)
                .property("label", txt)
		    label.call(root.textWrap);
            eventDispatcher.statusUpdate();
		}

        var foreign = d3.select(this.parentNode)
			.append("foreignObject") //this ahs size from css
            .attr("class","editable-container")
			.attr("transform", "translate("+ -44 + "," +  -20 + ")");

        var inp = foreign.append("xhtml:div")
            .attr('class',"editable")
            .attr('contenteditable','true')
            .text(function() {
                // nasty spot to place this call, but here we are sure that the <div> tag is available
                // and is handily pointed at by 'this':
                this.focus();
                return value;
            })
            // make the foreign object go away when you jump out (div looses focus) or hit ENTER:
            .on("blur", function() {
				var txt = this.innerText;
				putValue(txt, labelToEdit);
				foreign.remove();
				addStageEnabled = true;
            })
            .on("keypress", function() {
                //console.log("keypress", this, arguments);

                // IE fix
                if (!d3.event)
                    d3.event = window.event;

                var e = d3.event;
                // check length to avoid writing
                if(this.innerText.length>graphic.statusNameMaxLength && e.keyCode != 13){
                    e.preventDefault();
                    return false;
                }
                if (e.keyCode == 13){
                    if (typeof(e.cancelBubble) !== 'undefined') // IE
                      e.cancelBubble = true;
                    if (e.stopPropagation)
                      e.stopPropagation();
                    e.preventDefault();
                    var txt = this.innerText;
					putValue(txt, labelToEdit);
                    // odd. Should work in Safari, but the debugger crashes on this instead.
                    // Anyway, it SHOULD be here and it doesn't hurt otherwise.
					inp.on('blur', function(){});
                    foreign.remove();
					addStageEnabled = true;
                }
            });
	}

	this.deactiveAllStatus = function() {
		d3.selectAll('g.status')
			.classed("active", false);
		d3.selectAll('g.remove')
			.classed("hidden", true)
		d3.selectAll('g.active g.remove circle')
			.classed("hidden", true)
		d3.selectAll('g.colors')
			.classed("hidden", true)
		d3.selectAll('circle.external')
			.attr('r',graphic.statusExternalRadio);
	}

	this.activeStatus = function(d, i) {
        //eventDispatcher.statusDragging(this);
		root.deactiveAllStatus();
		root.reDrawMarkers(d3.select(this.parentNode).attr('id'));
		d3.select(this.parentNode)
		.classed("active", true)
		.moveToFront();

		d3.selectAll('g.active g.remove')
			.classed("hidden", false)
		d3.selectAll('g.active g.remove circle')
			.classed("hidden", false)
		d3.selectAll('g.active g.colors')
			.classed("hidden", false)
		d3.selectAll('g.active circle.external')
			.attr('r',graphic.statusExternalRadioActive);
	}

	// set one status (st) as the initial status for this workflow
	this.setInitialStatus = function(initElement){
		// uncheck other signals
		d3.selectAll('circle.initial').classed("active", false);
        // select new init status
        d3.select(initElement).classed("active", true);

		/*// unset other initial status
		d3.selectAll('g.isfirst').classed('isfirst', false);
		// set this status as the initial and activate his signal
		d3.select(st.parentNode.parentNode).classed('isfirst',true);
		d3.select(st).classed('initial-state-off', false);
		d3.select(st).classed('initial-state-on', true);
        */
        eventDispatcher.statusUpdate();
	}

	/* actions */

	// action create connection
	this.createTransition = d3.behavior.drag()
		.on("dragstart",function(){
			// Create a new transition for the current origin
			var t = d3.select(this.parentNode);
			var tr = root.addNewTransition(t.attr('id'),-1);
			tr.moveToBack(); //bug?
            svg.classed("dragging", true);

		})
		.on("dragend",function(){
			//console.log("ENDING");
            svg.classed("dragging", false);
			var origin = d3.select('g.transition.active').attr('origin');
			var target = d3.select('g.transition.active').attr('target');
			//remove temporal transitions when origin and target are the same state
			if (origin == target)
				d3.selectAll("g.transition[origin='"+origin+"'][target='"+target+"']").remove()
			// deactivate current transition and remove unlinked
			d3.selectAll('g.transition').classed('active',false);
			var tr = d3.selectAll("g.transition[target='-1']");
			tr.remove();
            // reset position of transition marker
            dragPosition.x = null;
            dragPosition.y = null;

			eventDispatcher.statusUpdate();
		})
		.on("drag",function(d){
            svg.classed("dragging", true);
			var t = d3.select(this.parentNode);
			var tr = d3.selectAll('g.transition.active path.line');
			var originX = t.attr('x');
			var originY = t.attr('y');
			var targetX = d3.event.sourceEvent.offsetX;
			var targetY = d3.event.sourceEvent.offsetY;
            // calculate stating position from source and offset for transition marker
            if (dragPosition.x==null && dragPosition.y==null){

                // fix on chrome Version 53.0.2785.113 (64-bit)
                dragPosition.x = parseInt(targetX);
                dragPosition.y = parseInt(targetY);
                    // TODO this 2 lines was working on chrome Version 52.0.2743.116 (64-bit) beggining 2016
                    //dragPosition.x = parseInt(originX) + targetX;
                    //dragPosition.y = parseInt(originY) + targetY;
            }
            // add incremental position in each drag loop for each translation
            dragPosition.x += d3.event.dx;
            dragPosition.y += d3.event.dy;

			var newPosition = "M"+originX+","+originY+"L"+dragPosition.x+","+dragPosition.y;
			tr.attr('d',newPosition);
		})

	this.closeTransition = function(d,i){
        var currentOrigin = d3.select('g.transition.active').attr('origin');
        var closingStateId = d3.select(this.parentNode).attr('id');
        // select all transitions with the same origin & target
        var sameTransitions = d3.selectAll("g.transition[origin='"+currentOrigin+"'][target='"+closingStateId+"']");

        // avoid duplicated transitions when already exists
        if(sameTransitions.size() == 0){
    		d3.selectAll("g.transition[target='-1']").attr('target',closingStateId).each(function(d,i){
    			// avoid generate transitions to the same state
    			if(d3.select(this).attr('origin') != d3.select(this).attr('target'))
    				root.reDrawTransition(d3.select(this).attr('origin'),d3.select(this).attr('target'));
    		});
        }
	}

	this.removeTransition = function(d,i){
		d3.select(this.parentNode).remove();
        // root.showSaveButton();
		root.emit();
	}

	// action drag
	this.dragStatus = d3.behavior.drag()
		.on("dragstart", function(d, i){
            //d3.event.sourceEvent.stopPropagation();
            eventDispatcher.statusDragging(this);
			// On dragstart, we calculate the offset position with respect to the position of the mouse and we store this during the dragging
			var t = d3.select(this.parentNode);
			var offsetX = d3.event.sourceEvent.x-t.attr('x');
			var offsetY = d3.event.sourceEvent.y-t.attr('y');

			t.attr('ox',offsetX);
			t.attr('oy',offsetY);

			d3.select(this.parentNode).classed("dragging", true);

		})
		.on("dragend", function(d, i){
            eventDispatcher.statusStop(this)
			d3.select(this.parentNode).classed("dragging", false);
			//var st = d3.select(this.parentNode);
			eventDispatcher.statusUpdate();
		})
	    .on("drag", function(d,i) {

			// Current Status
			var t = d3.select(this.parentNode);

			// DRAW LINES STARTING AT STATE
			d3.selectAll("g.transition[origin='"+t.attr('id')+"']").each(function(d,i){
				root.reDrawTransition(d3.select(this).attr('origin'),d3.select(this).attr('target'));
			});

			// DRAW LINES ENDING AT STATE
			d3.selectAll("g.transition[target='"+t.attr('id')+"']").each(function(d,i){
				root.reDrawTransition(d3.select(this).attr('origin'),d3.select(this).attr('target'));
			});


			//console.log(d3.event);
	        d3.select(this.parentNode)
			//.data([{ox:  parseInt(t.attr('ox')), oy:  parseInt(t.attr('oy'))}])
            //.data(d3.select(this.parentNode).data().push([{ox:  parseInt(t.attr('ox')), oy:  parseInt(t.attr('oy'))}]))
			.attr("transform", function(d,i){
				//var t = d3.select(this);
				//var offsetX = d.ox;
				//var offsetY = d.oy;
                var othis = d3.select(this);
                var offsetX = parseInt(othis.attr("ox"));
				var offsetY = parseInt(othis.attr("oy"));
				return "translate(" + [ d3.event.sourceEvent.x - (offsetX),d3.event.sourceEvent.y - (offsetY)] + ")";
	        })
			.attr('x',function(d){
                var othis = d3.select(this);
				//var offsetX = d.ox;
                var offsetX = parseInt(othis.attr("ox"));
				return d3.event.sourceEvent.x - (offsetX);
			})
			.attr('y',function(d){
				//var offsetY = d.oy;
                var othis = d3.select(this);
                var offsetY = parseInt(othis.attr("oy"));
				return d3.event.sourceEvent.y - (offsetY);
			})

		});

	this.textWrap = function(text) {
		text.each(function() {
            // removing whitespaces over each side of the string
            var currentText = d3.select(this).text();
            if (currentText.trim() != ''){
                var element = d3.select(this),
    				width = graphic.statusColorsRadio,
    				words = element.text().trim().split(/\s+/).reverse(),
    				word,
                    newWords,
                    newWord,
    				line = [],
					currentLine = '',
    				lineNumber = 0,
    				lineHeight = 1.1, // ems
    				y = element.attr("y"),
    				dy = 0.7, //parseFloat(text.attr("dy")), hack beacause we not have "dy" attribute?
    				tspan = element.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");

    			while (word = words.pop()) {
                    // check each word length to split it under limit into a new array
                    if(word.length > graphic.statusNameLimitSplit){
                        newWords = word.match(/.{1,8}/g);
                    }
                    else {
                        newWords = [word];
                    }
                    while (newWord = newWords.shift()){
        				line.push(newWord);
        				tspan.text(line.join(" "))
						currentLine = line.join(" ");
        				// check if length of current words olverlaps internal circle: graphic.statusColorsRadio
        				// hack for first word use custom function to get width
        				if (root.getTextWidth(currentLine, graphic.textFont) > width && newWord != element.text().split(/\s+/)[0]) {
        					line.pop();
        					tspan.text(line.join(" "));

							text.attr("class","label double");
        					line = [newWord];
        					// if bigger, append new line
        					tspan = element.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(newWord);
							if(lineNumber>2){
								text.attr("class","label multi");
							}
        				}
                    }
    			}
    			// positioning text element according its size
    			text.attr("transform", "translate(0," + -(Math.ceil(this.clientHeight/2)) + ")");
            }
		});
	}

	/**
	 * Measure the width of a text were it to be rendered using a given font.
	 *
	 * @param {string} text the text to be measured
	 * @param {string} font a valid css font value
	 *
	 * @returns {number} the width of the rendered text in pixels.
	 */
	 this.getTextWidth = function(text, font) {
		 const element = document.createElement("canvas");
		 const context = element.getContext("2d");
		 context.font = font;

		 return context.measureText(text).width;
	 };


	this.construct(options, stages, transitions);

}
