import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'core-layout-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class LayoutFooterComponent implements OnInit {

    public listLink:Array<any> = [
        {
            link: 'LinkedIn',
            href: 'https://www.linkedin.com/company/twona/'
        },
        // {
        //     link: '© 2019 Twonas',
        //     href: '',
        //     info: true
        // }
    ];

    protected year: number = 2019;

    constructor() {
        this.year = new Date().getFullYear();
    }

    ngOnInit() {
        let info = {
            link: "© " + this.year + " Twonas",
            href: "",
            info: true
        }

        this.listLink.push(info);
    }
}
