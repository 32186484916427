import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRouterModule } from '@app/router.module';
import { CoreModule } from '@core/core.module';

import { LabelsModule } from '@core/components/labels/labels.module';
import { StatusModule } from '@core/components/status/status.module';

import { MessagesModule } from '@core/components/messages/messages.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { CommonsModule } from '@core/components/commons/commons.module';
import { FilesModule } from '@core/components/files/files.module';
import { PreviewModule } from '@core/components/preview/preview.module';

// import { ProjectsDetailsHelpersModule } from '../projects/details/helpers/helpers.module';
import { VersionsModule } from '@core/components/versions/versions.module';

import { VersionsDetailsModule } from './details/details.module';
import { VersionsDetailsMainComponent } from './main/main.component';
import { VersionsRelatedModule } from './related/related.module';


@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        AppRouterModule,
        LabelsModule,
        StatusModule,
        MessagesModule,
        HelpersModule,
        CommonsModule,
        FilesModule,
        PreviewModule,
        VersionsDetailsModule,
        VersionsModule,
        VersionsRelatedModule
        
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionsDetailsMainComponent
    ],
    exports: [
        VersionsDetailsModule,
        VersionsDetailsMainComponent,
        VersionsRelatedModule
    ],

})
export class AppVersionsModule { }