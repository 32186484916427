/**
 * Main manage users component,
 * between Users and Licenses
 **/
import { Component, OnInit } from '@angular/core';
import { TitleService } from '@core/services/title.service';

import { AppService } from '@app/services/app/app.service';

@Component({
    selector: 'settings-manage-users',
    templateUrl: './manage-users.component.html',
    styleUrls: ['./manage-users.component.scss']
})
export class SettingsManageUsersComponent implements OnInit{

    private readonly ACCESS = 'access_setting_users_access';

	public loaded: boolean = false;
    public access: boolean = false;

    public licensed: boolean = false;

    public session: any = null;

	constructor(private app: AppService, private title: TitleService){}

	ngOnInit(){
        this.title.set("Users - System settings");

        this.app.getSession().subscribe( (session: any) => {
            this.session = session;
            this.access = this.session.options.access.hasOwnProperty(this.ACCESS)? this.session.options.access[this.ACCESS] : false;
            this.licensed = this.session.options.license !== null;
            this.loaded = true;
        });
	}

}
