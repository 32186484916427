import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabelsModule } from '@core/components/labels/labels.module';
import { StatusModule } from '@core/components/status/status.module';
import { PipesModule } from '@core/pipes/pipes.module'

import { MessagesModule } from '@core/components/messages/messages.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { CommonsModule } from '@core/components/commons/commons.module';
import { FilesModule } from '@core/components/files/files.module';
// import { PreviewModule } from '@core/components/preview/preview.module';

import { VersionsDetailsContentMainComponent } from './main/main.component';
import { VersionsDetailsContentMessagesComponent } from './messages/messages.component';
import { VersionsDetailsContentFilesComponent } from './files/files.component';


// import { VersionsPreviewModule } from './preview/preview.module';
import { VersionsModule } from '@core/components/versions/versions.module';
import { FilesListModule } from '@core/components/files/lists/lists.module';

// import { VersionsDetailsContentFeedbackModule } from './feedback/feedback.module';
import { VersionsDetailsContentFeedbacksComponent } from './feedbacks/feedbacks.component';
import { VersionsDetailsContentInfoComponent } from './info/info.component';
import { LayoutModule } from '@core/components/layout/layout.module';


@NgModule({
    imports: [
        CommonModule,
        LabelsModule,
        StatusModule,
        MessagesModule,
        HelpersModule,
        CommonsModule,
        FilesModule,
        PipesModule,
        LayoutModule,
        VersionsModule,
        FilesListModule,
        // VersionsDetailsContentFeedbackModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionsDetailsContentMainComponent,
        VersionsDetailsContentMessagesComponent,
        VersionsDetailsContentFilesComponent,
        VersionsDetailsContentFeedbacksComponent,
        VersionsDetailsContentInfoComponent

    ],
    exports: [
        VersionsDetailsContentMainComponent,
        // VersionsPreviewModule
    ],

})
export class VersionsDetailsContentModule { }