import { Action } from '@ngrx/store';

import { User } from '@core/objects/user';
import { Session } from '@app/store/models/session.model';
import { AccessItem } from '@app/store/models/access-item.model';

export enum AppActionTypes {
    LoadSessionRequest = '[App] Load Session request',
    LoadSessionSuccess = '[App] Load Session success',
    SetVersion = '[App] Set Version',
    Disconnected = '[App] Disconnected',
    ChangeOrganization = '[App] Set Organization',
    UpdateUser = '[App] PUT - Save User',
    UpdateUserSuccess = '[App] Save User success',
    UpdateUserError = '[App] Save User error',
}

export class LoadSessionRequest implements Action {
    readonly type = AppActionTypes.LoadSessionRequest;
    constructor() {}
}

export class LoadSessionSuccess implements Action {
    readonly type = AppActionTypes.LoadSessionSuccess;
    constructor(public payload: Session) {}
}

export class SetVersion implements Action {
    readonly type = AppActionTypes.SetVersion;
    constructor(public version: string) {}
}

export class Disconnected implements Action {
    readonly type = AppActionTypes.Disconnected;
    constructor() {}
}

export class ChangeOrganization implements Action {
    readonly type = AppActionTypes.ChangeOrganization;
    constructor(public organization: any) {}
}

export class UpdateUser implements Action {
    readonly type = AppActionTypes.UpdateUser;
    constructor(public name: string, public telephone: string, public position: string) {}
}

export class UpdateUserSuccess implements Action {
    readonly type = AppActionTypes.UpdateUserSuccess;
    constructor(public user: User) {}
}

export class UpdateUserError implements Action {
    readonly type = AppActionTypes.UpdateUserError;
    constructor() {}
}

//export type AppActions = GetSession | ClearUserMessage | IsLoginNeeded | StoreToken;
export type AppActions = LoadSessionRequest | 
                         LoadSessionSuccess | 
                         SetVersion | 
                         Disconnected | 
                         ChangeOrganization | 
                         UpdateUser | 
                         UpdateUserSuccess | 
                         UpdateUserError;
