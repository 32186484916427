import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'core-helpers-navigation-three-dots-menu-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
/**
    ADD here all icon possibilities as a class 
    status
    add
    edit
    etc..
*/
export class MenuIconComponent {

    @Input() icon: string = '';

}


