import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import { Request, BasicRequest } from '@core/objects/request';
import { File } from '@core/objects/file';
import { User } from '@core/objects/user';
import { RequestStatus } from '@core/objects/request-status';
import { Version } from '@core/objects/version';
import { Label } from '@core/objects/label';
import { Message } from '@core/objects/message';
import { Related } from '@core/objects/related';
import { Note } from '@core/objects/note';
import { Event } from '@core/objects/event';

import * as Access from '@app/models/access';
import { WorkflowTransition } from '@core/objects';

@Injectable({
  providedIn: 'root'
})
export class ProjectsRequests {
	private readonly prefix = "/requests";

    constructor(private http: HttpClient, private urlSrv : UrlService) {}

    public create$ = (info: string, workflowId: number, labels: number[], files: Array<string|number>, dueDate: string = null) : Observable<Request> => {
        let request = {
            info: info ,
            workflow: workflowId ,
            labels: labels ,
            files: files
        };

        return this.http.post<Request>(this.urlSrv.build(this.prefix), request, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });
    }


	public load$ = (id: number) : Observable<Request> =>
		this.http.get<Request>(this.urlSrv.build(this.prefix+"/"+id));

    public loadHeader$ = (id: number) : Observable<BasicRequest> =>
		this.http.get<BasicRequest>(this.urlSrv.build(this.prefix+"/"+id+"/headers"));

    public loadFiles$ = (id: number) : Observable<File[]> =>
        this.http.get<File[]>(this.urlSrv.build(this.prefix + "/" + id + "/files"), { params: new HttpParams().set('all', "true")});

    public loadOrigin$ = (id: number) : Observable<Version[]> =>
        this.http.get<Version[]>(this.urlSrv.build(this.prefix + "/" + id + "/versions"), {params: {origin: 'true', uploads: 'false'}});


    public activeFile$ = (id: number, file: File, active: boolean) =>
        this.http.put<File>(this.urlSrv.build(this.prefix+"/"+id+"/files/" + file.id ), {active: active}, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });


    public addFiles$ = (id: number, files: Array<File>) =>
        this.http.post<File[]>(this.urlSrv.build(this.prefix+"/"+id+"/files"), {files: files.map((file: File) => file.id)}, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });

    public loadAssigners$ = (id: number) =>
        this.http.get<User[]>(this.urlSrv.build(this.prefix + "/" + id + "/allowed-users"));

    public loadWorkflowTransitions$ = (id: number) =>
        this.http.get<WorkflowTransition[]>(this.urlSrv.build("/workflows/transitions?origin=" + id));

    public loadAccess$ = (id: number) =>
        this.http.get<Access.AccessProject>(this.urlSrv.build(this.prefix + "/" + id + "/permissions"));

    public loadMessages$ = (id: number) =>
        this.http.get<Message[]>(this.urlSrv.build(this.prefix + "/" + id + "/messages"));

    public loadVersionGroups$ = (id: number) =>
        this.http.get<Related[]>(this.urlSrv.build(this.prefix + "/" + id + "/versions/links"));

    // load type request and version
    public loadEvents$ = (object: string, id: number) => 
        this.http.get<Event[]>(this.urlSrv.build("/events"), {params: {q: object+':'+id }});

    public loadUploadedVersions$ = (id: number) : Observable<Version[]> =>
        this.http.get<Version[]>(this.urlSrv.build(this.prefix + "/" + id + "/versions"), {params: {origin: 'false', uploads: 'true'}});

    public setAssign$ = (id: number, user: User) =>
        this.http.put<Request>(this.urlSrv.build(this.prefix+"/"+id ), {assigned: user!=null? user.id : null}, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });

    public setDue$ = (id: number, date: string) =>
        this.http.put<Request>(this.urlSrv.build(this.prefix+"/"+id ), {due_date: date}, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });

    public setStatus$ = (id: number, status: RequestStatus) =>
        this.http.put<Request>(this.urlSrv.build(this.prefix+"/"+id ), {status: status.id}, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });

    public setLabels$ = (id: number, labels: Array<Label>) =>
        this.http.put<Request>(this.urlSrv.build(this.prefix+"/"+id ), {labels: labels.map((l: Label) => l.id)}, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });

    public loadFilesNotes$ = (id: number) =>
        this.http.get<Note[]>(this.urlSrv.build(this.prefix + "/" + id + "/notes"));


        // this.http.get<File[]>(this.urlSrv.build(this.prefix + "/" + id + "/files"), { params: new HttpParams().set('all', "true")});
	// public updateRule$ = (ruleId: number, rule: RuleEvent) : Observable<RuleEvent> =>
	// 	this.http.put<RuleEvent>(this.urlSrv.build(this.prefix+"/events/" + ruleId ), rule, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });

	// public createRule$ = (rule: RuleEvent) : Observable<RuleEvent> =>
	// 	this.http.post<RuleEvent>(this.urlSrv.build(this.prefix+"/events"), rule, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });
}
