import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as Objects from '@core/objects';
// import { File } from '@core/objects/file';
// import { Note } from '@core/objects/note';
import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { UrlService } from '@core/services/url/url.service';
import { NotesService } from '@core/services/notes/notes.service';

@Component({
    selector: 'core-message-files',
    templateUrl: './message-files.component.html',
    styleUrls: ['./message-files.component.scss']
})
export class MessageFilesComponent {

    @Input() message: Objects.Message;
    @Input() canCreateNotes: boolean = true;
    @Input() notesActionText: string;
    @Input() notesActionStyle: string;
    @Input() prefixUrl: string = '';

    public selectedFile: Objects.File = null;
    public show: boolean = false;
    public notes: Array<Objects.Note> = null;

    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "Download File",
            key: "download",
            icon: "download"
        },
        {
            title: "See Notes",
            key: "notes",
            icon: "notes"
        },
        // {
        //     title: "Remove",
        //     key: "remove",
        //     icon: "remove"
        // },
    ];
    constructor(private urlSrv: UrlService,
        private notesSrv: NotesService ){}

    ngOnInit(){
        this.loadNotes();
    }

    notesSaved(notes: Array<Objects.Note>){
        this.loadNotes();
    }

    openNotes(file: Objects.File) {
        if(this.selectedFile != null && this.selectedFile.id == file.id)
            this.selectedFile = null;
        else
            this.selectedFile = file;
    }

    toggleAttachments(){
        this.show = !this.show;
    }

    onMenuClick(menu: FilesListItemMenuClick) {
        switch(menu.key) {
            // case "remove":
            //     this.onClickDeactivate(menu.file);
            //     break;
            case "notes":
                this.openNotes(menu.file);
                break;
            case "download":
                window.location.href = this.urlSrv.build('/files/'+menu.file.id);
                break;
        }
    }

    onClickPreview(file: Objects.File){
        console.debug(" NEW click file preview ",file);
        // if (file != null && this.project != null){
        //     this.router.navigate(['/projects/'+this.project.id + "/files/"+file.id]);
        // }
    }

    protected loadNotes() {
        if(this.message != null && this.message.files.length > 0){
            this.notesSrv.findByMultipleFiles(this.message.files, this.prefixUrl+'/messages/'+this.message.id).subscribe(
                (notes: Array<Objects.Note>) => {
                console.log(" notes loaded in new way ", notes);
                this.notes = notes;
                // this.initBubbleTexts();
                // this.loaded = true;
                },
                (error: any) => {
                    this.notes = [];
                });
        } else {
            this.notes = [];
        }
    }

}
