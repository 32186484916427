import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'core-legacy-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss'],
    providers: []
})
export class NoteComponent implements OnInit, OnChanges {

    @Input() note: any;
    @Input() index: number = 0;
    @Input() moveBounds: HTMLElement;
    @Input() opened: boolean = null;
    @Output() onRemove = new EventEmitter<any>();
    @Output() movingNote = new EventEmitter<any>();
    @ViewChild('txtArea') textArea:ElementRef;

    public formControl = new FormControl('');

    //public i: number;
    public x: number;
    public y: number;
    public x_moved: number = 0;
    public y_moved: number = 0;

    public currentPosition: any;
    public draggable;
    //public opened: boolean = true;
    private movedContent: boolean = false;

    constructor() {}

    ngOnInit(){
        this.init();
    }

    ngOnChanges(changes: SimpleChanges){
        //this.init();

        if(this.note.id == 0)
            setTimeout(() => this.textArea.nativeElement.focus(), 0);

        this.formControl.valueChanges.subscribe(value => {
            this.note.text = value;
        });

        // if(changes.opened) {
        //     this.opened = changes.opened.previousValue? true : this.opened;
        // }


    }

    remove(event) {
        this.onRemove.emit(this.note);

        event.stopPropagation();
    }

    onMoveContentEnd(event) {
        this.movedContent = true;
    }

    protected init() {
        //this.i = this.index + 1;
        this.x = 100 * this.note.x_pos;
        this.y = 100 * this.note.y_pos;

        if(this.opened === null)
            this.opened = this.note.id == 0;

        if(this.note.id == 0)
            this.opened = true;

        this.draggable = this.note.id == 0;
    }

    onMoving(event) {
        if(!this.movedContent)
            this.currentPosition = event;

    }

    select(event) {
        event.stopPropagation();
    }

    open(event) {
        if(this.note.id != 0) {
            this.opened = !this.opened;
        }
        event.stopPropagation();
    }

    onMoveEnd(event){
        let moved = {
            x: event.x - this.x_moved,
            y: event.y - this.y_moved
        }

        console.debug("movido!", event);
        if(0 == this.note.id) {
            this.movingNote.emit({
                note: this.note,
                x_move: moved.x,
                y_move: moved.y,
            });
        }

        this.x_moved = event.x;
        this.y_moved = event.y;
    }


}
