import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';

import { IDatePickerConfig } from 'ng2-date-picker';

import * as moment from 'moment';

@Component({
  selector: 'core-helpers-forms-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {

    @Input() disabled: boolean;
    @Input() unSelect: boolean = false;
    @Input() label: string = "";
    @Input() selectedDate: string = null; //date that is currently selected and used as model
    @Input() theme: string;
    @Input() dateFormat: string = "YYYY-MM-DD";

    @Output() selectDate = new EventEmitter<string>();
    @Output() close = new EventEmitter();

    public config: IDatePickerConfig = {}; 
    
    // this store the moment to set the parameter to display calendar on selectedDate at load
    public displayDate: moment.Moment = null; 

    ngOnInit() {
        let today = moment().format("YYYY-MM-DD");
        if (this.selectedDate != null)
            this.displayDate = moment(this.selectedDate,"YYYY-MM-DD");
        else {
            this.displayDate = null; // display date must be null to open in current day
        }
        this.config = {
            format: this.dateFormat,
            dayBtnFormat: "D",
            monthFormat: "MMMM YYYY",
            firstDayOfWeek: 'mo',
            allowMultiSelect: false,
            enableMonthSelector: false,
            unSelectOnClick: this.unSelect, // allow unset the current date. default false
            min: today
        }
        // console.log(" calendar config",this.config);
    }

    onSelect(event){
        // console.log(" select onSeleeeect:",event.date.format(this.dateFormat));
        this.selectDate.emit(event.date.format(this.dateFormat));
    }
}
