import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProjectsEditorService, ProjectsService } from '@app/services/app/projects.service';

import * as Objects from '@core/objects';

@Component({
    selector: 'project-details-messages-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ProjectDetailsMessagesListComponent implements OnInit, OnDestroy{
    public readonly ACCESS_WRITE = "access_request_write_comments";

	@Input() project: Objects.Request = null;
    @Input() user: Objects.User = null;

    protected subscriptor: Subscription;
    protected accessSubscriptor: Subscription;

    public messages: Array<Objects.Message> = null;
    public prefixUrl: string = "";
    public accessWrite: boolean = false;
     
	constructor(
        private projectsSrv: ProjectsService,
        private editorSrv: ProjectsEditorService
    ){}

    ngOnInit() {
        this.accessSubscriptor = this.editorSrv.selectAccess(this.ACCESS_WRITE).subscribe((access: boolean) => this.accessWrite = access);
        this.subscriptor = this.projectsSrv.selectProjectMessages().subscribe((messages: Objects.Message[]) => {
            this.messages = messages != null? messages.filter((message: Objects.Message) => message.text.indexOf("(TWONA") != 0) : messages;
        });

        this.projectsSrv.loadMessages();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.project) 
            this.prefixUrl = "/requests/" + this.project.id;
    }

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
        this.accessSubscriptor.unsubscribe();
    }

    public onDeleted(message: Objects.Message){
        this.projectsSrv.loadMessages();
    }


}
