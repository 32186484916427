import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { PipesModule } from '@core/pipes/pipes.module';

import { ProjectsModule } from '@core/components/projects/projects.module';
import { DashboardViewsTableMainComponent } from './main/main.component';
import { ProjectsDetailsInfoModule } from 'app/components/sections/projects/details/info/info.module';
import { DashboardViewsTableHeaderComponent } from './header/header.component';
import { ProjectsDetailsVersionsModule } from 'app/components/sections/projects/details/versions/versions.module';
import { DashboardViewsTableDetailsModule } from './details/details.module';


@NgModule({
    imports: [
        CommonModule,   
        CoreModule,
        ProjectsModule,
        PipesModule,
        InfiniteScrollModule,
        ProjectsDetailsInfoModule,
        ProjectsDetailsVersionsModule,
        DashboardViewsTableDetailsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        DashboardViewsTableMainComponent,
        DashboardViewsTableHeaderComponent,
    ],
    exports: [
        DashboardViewsTableMainComponent
    ],

})
export class DashboardViewsTableModule { }
