import { createFeatureSelector, createSelector, State } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import * as Objects from '@core/objects';
import { AddRequest } from '@app/models/add-project';

// in snake style for help to find
// interface AddProjectsErrors {
//     load_new_project: HttpErrorResponse,
//     update_new_project: HttpErrorResponse,
// }

export interface AddProject {
    data: AddRequest;
}

export const initialState: AddProject = {
    data: null
};

export const addProjectsFeatureSelector = createFeatureSelector('new_project');

// // Selectors
export const addProjectSelector = createSelector(
    addProjectsFeatureSelector,
    (state: AddProject) => state.data
);
