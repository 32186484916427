import { createFeatureSelector, createSelector } from '@ngrx/store';
// import { HttpStatus } from '@core/classes/http-status.class';
import { HttpErrorResponse } from '@angular/common/http';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';
import * as Search from '@app/models/search';
import { SearchAvailableFilters } from '@app/models/search';

// export interface SearchAvailableFilters {
//     key: string;
//     items: Array<Entity>;
// }

export interface DashboardStatusFilter extends Objects.Entity {
    // id: number | string // (status.id)
    status: Objects.Status;
    workflow: Objects.Workflow;

}

interface DashboardStatusFilters extends Search.SearchAvailableFilters {
    items: Array<DashboardStatusFilter>;
}

interface DashboardAvailableFilters {
    status: DashboardStatusFilters;
    labels: Search.SearchAvailableFilters;
    asignees: Search.SearchAvailableFilters;
    owners: Search.SearchAvailableFilters;
}

interface DashboardSearch {
    term: string;
    sort: string;
    limit: number;
    filters: Array<Search.SearchAppliedFilter>;
    // query: string;
}

interface DashboardResults {
    next_page: number;
    // final: boolean;
    status: number;
    items: Array<Objects.ExtendedRequest>;
}

export interface DashboardWorkflows {
    items: Array<Objects.Workflow>;
    current_id: number | string;
    current: Objects.Workflow;
    empty: boolean;
}

interface DashboardStart {
    contexts: {
        session: {
            value: any;
            loaded: boolean;
        };
        url: {
            value: any;
            loaded: boolean;
        };
    },
    initialized: boolean;
}

export interface Dashboard {
    starting: DashboardStart;
    available_filters:  DashboardAvailableFilters;
    search: DashboardSearch;
    workflows: DashboardWorkflows;
    search_query_params: Search.SearchQueryParams;
    results: DashboardResults;
    view: string;
    events: Array<{name: string, id: number | string}>;
    errors: any;
}

export const initialState: Dashboard = {
    starting: {
        initialized: false,
        contexts: {
            session: {
                value: null,
                loaded: false
            },
            url: {
                value: null,
                loaded: false
            }
        }
    },
    available_filters : {
        status: {
            key: "status",
            items: []
        },
        labels: {
            key: "label",
            items: []
        },
        asignees: {
            key: "user_assigned",
            items: []
        },
        owners: {
            key: "user_owner",
            items: []
        }
    },
    search: {
        term: "",
        sort: "-id",
        limit: 50,
        filters: [],
        // query: null
    },
    workflows: {
        items: [],
        current_id: null,
        current: null,
        empty: false
    },
    search_query_params: null,
    results: {
        next_page: 1,
        // final: false,
        status: Search.SearchHttpStatus.INITIAL,
        items: []
    },
    view: 'table',
    events: [],
    errors: {
        // load_project: null,
        // load_project_files: null,
        // load_project_origin_version: null,
        // load_project_messages: null, 
        // load_project_version_groups: null,
        // load_project_uploaded_versions: null, 
    }
};

const dashboardFeatureSelector = createFeatureSelector('dashboard');

// Selectors
export const dashboardSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state
);

export const startingSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.starting
);

export const workflowsSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.workflows
);

export const currentWorkflowSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.workflows.current
);

export const availableFiltersSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.available_filters
);

export const availableFilterLabelsSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.available_filters.labels
);

export const availableFilterStatusSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.available_filters.status
);

export const availableFilterAsigneesSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.available_filters.asignees
);

export const availableFilterOwnersSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.available_filters.owners
);

export const searchSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.search
);

export const searchFiltersSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.search.filters
);

export const searchTermSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.search.term
);

export const searchSortSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.search.sort
);

export const queryParamsSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.search_query_params
);

export const resultSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.results
);


export const resultItemsSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.results.items
);

export const resultStatusSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.results.status
);

export const viewSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.view
);

export const eventsSelector = createSelector(
    dashboardFeatureSelector,
    (state: Dashboard) => state.events
);