import { Component, Input, OnInit, Output, EventEmitter, } from '@angular/core';

import { Status } from '@core/objects/status';

@Component({
    selector: 'core-version-update-status-menu-status-list',
    templateUrl: './status-list.component.html',
    styleUrls: ['./status-list.component.scss']
})
export class VersionStatusUpdateMenuStatusListComponent implements OnInit{
    // @Input() session: any;
    @Input()  status: Array<Status> = [];

    @Output() action = new EventEmitter<Status>();

    public open: boolean = false;

    ngOnInit(){

    }

    onClick(status: Status) {
        this.action.emit(status);
        this.open = false;
    }

}
