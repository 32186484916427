import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


import { AccessTokensService } from '../../../../../services/settings/access-tokens.service';


import { AccessToken } from '@core/objects/access-token';

@Component({
    selector: 'settings-access-token-add-form',
    templateUrl: './add-form.component.html',
    styleUrls: ['./add-form.component.scss']
})
export class SettingsAccessTokenAddFormComponent implements OnInit{

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

    @Output() cancel = new EventEmitter<any>();
    @Output() submit = new EventEmitter<any>();

    public status: Number = 0;
	public newAccessToken: AccessToken = null;

    public form: FormGroup;

	constructor(public accessTokensSrv: AccessTokensService, private formBuilder: FormBuilder){}

	ngOnInit(){
		this.init();

	}

    init(){
        this.form = this.formBuilder.group({
            name: ['', [Validators.required]],
            readonly: [false]
		});
    }

    onReadOnlyChange(readonly: boolean){
        console.debug("change readonly");
        this.form.value.readonly = readonly;
    }

	onCancel(event) {
        console.log(" cancel triggered")
        this.cancel.emit(event);
        this.init();
	}

    addAccessToken(){
        if(this.form.invalid)
			return;

        this.accessTokensSrv.addAccessToken$(this.form.value.name,this.form.value.readonly).subscribe((newToken: AccessToken) => {
            console.debug(" post: new token added",newToken);
            this.newAccessToken = newToken;
            this.submit.emit()
        });

    }

    done(event){
        this.newAccessToken = null;
        this.cancel.emit(event);
        // reset form
        this.init();
    }
}
