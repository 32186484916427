import { Component, Input, OnInit} from '@angular/core';
import { VersionFeedback } from '@core/models/version-feedback';
import { Approval } from '@core/objects/approval';

@Component({
  selector: 'core-helper-box-header-user-date',
  templateUrl: './header-user-date.component.html',
  styleUrls: ['./header-user-date.component.scss']
})
export class HelperBoxHeaderUserDateComponent {
    
    public readonly FEEDBACK_COLORS = ['#F6B273','#47B934','#F44848'];
    public readonly FEEDBACK_ONLY_COMMENT_COLOR = 'silver';

    @Input() user: any;
    @Input() date: any = null;
    @Input() posText: string;
    @Input() preText: string;
    @Input() feedback:  VersionFeedback = null;


    public getApprovalColor(approval: Approval){
      if (approval != null)
          return this.FEEDBACK_COLORS[approval.status_code];
      return this.FEEDBACK_ONLY_COMMENT_COLOR;
  }

}
