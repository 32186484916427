import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// import { HttpStatus } from '@core/classes/http-status.class';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Status } from '@core/objects/status';

import { VersionStatusService } from '../../../../../services/settings/version-status.service';
import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

@Component({
    selector: 'settings-version-status-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SettingsVersionStatusMainComponent implements OnInit{
    private readonly ACCESS = 'access_setting_versions_access';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

    public versionStatus: Array<Status>;
	public showForm:boolean = false;
	public access:boolean = true;
    public status: number = 0;
    public breadcrumbs: any = [
        {
            title: "System Settings",
            link: ['/settings']
        }
    ];

	// TODO review menu icons
	public menu: Array<ThreeDotsMenuItem> = [{
		title: "Add a new artwork status",
		key: "add-status",
		icon: "add",
		order: 1
	}];

    public colorOptions: Array<string> = ['#E87DB0', '#E88C7D', '#E8A97D', '#E8D57D','#8CE87D','#7DE8C2','#7DD5E8','#7D9BE8','#B97DE8' ];





	constructor(public versionStatusSrv: VersionStatusService, public app: AppService, private title: TitleService){}

	ngOnInit(){
        this.title.set("Version statuses - System settings");
        this.app.getSession().subscribe( (session: any) => {
            this.access = session.options.access.hasOwnProperty(this.ACCESS)? session.options.access[this.ACCESS] : false;
        });
        if (this.access){
            this.load();
        }
	}

    onSaved(event){
        this.showForm = false;
        this.load();
    }

    onColorChange(color: string ,versionStatus: Status){
        this.updateColor(versionStatus,color);
    }

	protected load(){
        this.versionStatusSrv.getAllVersionStatus$().subscribe((status: Array<Status>) => {
            this.versionStatus = status;
            this.status = this.STATUS_DONE;
        });

	}

    deactivate(versionStatus: Status) {
        this.versionStatusSrv.deactivateVersionStatus$(versionStatus).subscribe(data => {
            this.load()
        });
    }

    activate(versionStatus: Status) {
        this.versionStatusSrv.activateVersionStatus$(versionStatus).subscribe(data => {
            this.load()
        });
    }

    updateColor(versionStatus: Status, color: string) {
        this.versionStatusSrv.updateVersionStatusColor$(versionStatus,color).subscribe(data => {
            this.load()
        });
    }

	onMenuClick(key: string) {
        switch(key) {
            case "add-status":
                this.showForm = !this.showForm;
                break;
        }
    }

}
