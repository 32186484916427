import { Component, Input, OnInit, OnDestroy, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { ProjectsEditorService, ProjectsService } from '@app/services/app/projects.service';

import * as Objects from '@core/objects';
import { AppRouterService } from '@app/services/app/router.service';


@Component({
    selector: 'project-details-info-attachments',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectDetailsInfoAttachmentsComponent implements OnInit, OnDestroy {

    public readonly ACCESS_UPLOAD_FILE = "access_request_upload_file";
    
    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_FOUND = 4;
    public readonly STATUS_ERROR = 3;
    public readonly STATUS_NOT_ALLOWED = 2;

    @Input() showUpload: boolean = false;
    @Output() uploadDone = new EventEmitter<any>();

    public status: number = 0;
    public show: boolean = false;
    public showInactives: boolean = false;
    public accessUpload: boolean = false;
    public loadingAttachments: boolean = true;

    public project: Objects.Request = null;
    public uploadedFiles: Array<Objects.File> = [];
    
    public selectedAttachment: Objects.File = null;

    public attachments: Array<Objects.File> = null;
    public notes: Array<Objects.Note> = null;
    protected subscriptor: Subscription;
    protected projectSubscriptor: Subscription;
    protected projectNotesSubscriptor: Subscription;
    protected errorSubscriptor: Subscription;
    protected errorNotesSubscriptor: Subscription;

    protected accessSubscriptor: Subscription;

	constructor(
        private appRouter: AppRouterService,
        private projectsSrv: ProjectsService,
        private editorSrv: ProjectsEditorService
    ){}

	ngOnInit(){
        // TODO: control access
        
        this.projectSubscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.Request ) => {
            if(project == null) return;
            
            this.project = project;
        });

        this.subscriptor = this.projectsSrv.selectProjectFiles().subscribe((files: Objects.File[] ) => {
            this.attachments = files;
            this.init();
        });

        this.projectNotesSubscriptor = this.projectsSrv.selectProjectFilesNotes().subscribe((notes: Objects.Note[] ) => {
            this.notes = notes;
            this.init();
        });

        this.errorSubscriptor = this.projectsSrv.selectError('load_project_files')
            .subscribe((error: HttpErrorResponse) => this.setError(error));

        this.errorNotesSubscriptor = this.projectsSrv.selectError('load_project_files_notes')
        .subscribe((error: HttpErrorResponse) => this.setError(error));
        
        this.accessSubscriptor = this.editorSrv.selectAccess(this.ACCESS_UPLOAD_FILE)
            .subscribe((access: boolean) => this.accessUpload = access);
    }

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
        this.errorSubscriptor.unsubscribe();
        this.projectSubscriptor.unsubscribe();
        this.accessSubscriptor.unsubscribe();
        this.projectNotesSubscriptor.unsubscribe();
        this.errorNotesSubscriptor.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.showUpload){
            // console.log(" upoad toggle changed...")
        } 
           
    }

    protected init(){
        if(this.attachments == null || this.notes == null) 
            return;
        this.status = this.STATUS_DONE;
    }

    protected setError(error: HttpErrorResponse) {
        if(error == null)
            return;

        switch(error.status) {
            case 500:
                this.status = this.STATUS_ERROR;
                break;
            case 404:
                this.status = this.STATUS_NOT_FOUND;
                break;
            case 403:
                this.status = this.STATUS_NOT_ALLOWED;
                break;
        }
    }

    public toggleAttachments(){
        this.show = !this.show;
    }

    public toggleInactives(){
        this.showInactives = !this.showInactives;
    }

    public toggleUpload(){
        if(!this.accessUpload)
            return;

        this.showUpload = !this.showUpload;
    }

    onClickPreview(file: Objects.File){
        console.debug(" NEW click file preview ",file);
        if (file != null && this.project != null){
            this.appRouter.navigate('/projects/'+this.project.id + "/files/"+file.id);
        }        
    }

    onUploadDone(event: boolean){
        if(event)
            this.show = event;
        this.uploadDone.emit(event)
    }

    public onCancel(){
        this.toggleUpload()
        this.uploadedFiles = [];
    }

}
