import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Approval } from '@core/objects/approval';
import { VersionFeedbackMessage } from '@core/models/version-feedback-message';;

@Component({
    selector: 'core-message-feedback',
    templateUrl: './message-feedback.component.html',
    styleUrls: ['./message-feedback.component.scss']
})
export class MessageFeedbackComponent {

    public readonly FEEDBACK_COLORS = ['#F6B273','#47B934','#F44848'];
    public readonly FEEDBACK_ONLY_COMMENT_COLOR = 'silver';
    
    @Input() message: VersionFeedbackMessage;
    
    public getApprovalColor(approval: Approval){
        if (approval != null)
            return this.FEEDBACK_COLORS[approval.status_code];
        return this.FEEDBACK_ONLY_COMMENT_COLOR;
    }

}
