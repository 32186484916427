import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApolloService } from '../../../services/apollo/apollo.service';

import { ApolloContentComparisonResultMark } from '../../../model/apollo-content-compared-result-mark.model';

@Component({
    selector: 'plugin-apollo-helpers-content-result-mark',
    templateUrl: './content-result-mark.component.html',
    styleUrls: ['./content-result-mark.component.scss']
})
export class PluginApolloHelpersContentResultMarkComponent implements OnInit {


    @Input() mark: ApolloContentComparisonResultMark;
    @Input() selected: boolean = false;

    public zindex: number = 0;

    constructor(public apolloSrv: ApolloService){}

    ngOnInit() {
        this.zindex = 10000-Math.ceil(this.mark.height);
    }


}
