import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProjectsEditorService, ProjectsService } from '@app/services/app/projects.service';

import * as Objects from '@core/objects';
import { OneActionListItemComponent } from '@core/components/helpers/list/one-action-list-item/one-action-list-item.component';
import { UrlService } from '@core/services/url/url.service';

@Component({
    selector: 'project-details-messages-add',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.scss']
})
export class ProjectDetailsMessagesAddComponent implements OnInit , OnDestroy, OnChanges{
    public readonly ACCESS = "access_request_write_comments";
	@Input() project: Objects.Request = null;

    public addUrl: string = "";

    protected accessSubscriptor: Subscription;
    public access: boolean = false;

    constructor(
        private projectsSrv: ProjectsService,
        private editorSrv: ProjectsEditorService,
        private urlSrv: UrlService
    ){}

    ngOnInit() {
        this.accessSubscriptor = this.editorSrv.selectAccess(this.ACCESS).subscribe((access: boolean) => this.access = access);
    }

    ngOnDestroy() {
        this.accessSubscriptor.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.project) 
            this.addUrl = this.urlSrv.build("/requests/" + this.project.id + "/messages");
    }

    public onSaved(comment: Objects.Message){
        this.projectsSrv.loadMessages();

    }
}
