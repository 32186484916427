import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
// import { LabelsModule } from '@core/components/labels/labels.module';

import { AppRouterModule } from '@app/router.module';
import { AppHelperLabelsModule } from '@app/components/helpers/labels/labels.module';
import { AppHelperFormModule } from '@app/components/helpers/form/form.module';
import { ProjectAddMainComponent } from './main/main.component';
import { ProjectAddSentComponent } from './sent/sent.component';
import { ProjectAddDuedateComponent } from './duedate/duedate.component';
import { ProjectAddOriginVersionComponent } from './origin-version/origin-version.component';
import { ProjectsDetailsHelpersModule } from '../details/helpers/helpers.module';

@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        HelpersModule,
        AppHelperLabelsModule,
        AppHelperFormModule,
        ProjectsDetailsHelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectAddMainComponent,
        ProjectAddSentComponent,
        ProjectAddDuedateComponent,
        ProjectAddOriginVersionComponent
    ],
    exports: [
        ProjectAddMainComponent,
    ],

})
export class ProjectsAddModule { }
