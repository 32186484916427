import { createFeatureSelector, createSelector } from '@ngrx/store';
// import { HttpStatus } from '@core/classes/http-status.class';
import { HttpErrorResponse } from '@angular/common/http';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';
// export interface ProjectLoaded {
//     id: number;
//     project: Request;
//     status: HttpStatus;
// }

// in snake style for help to find
interface VersionsErrors {
    load_version: HttpErrorResponse;
    load_version_files: HttpErrorResponse;
    load_version_messages: HttpErrorResponse;
    load_version_comments: HttpErrorResponse;
    load_editor_access: HttpErrorResponse;
    load_version_feedbacks: HttpErrorResponse;
    load_version_project: HttpErrorResponse;
    set_version_status: HttpErrorResponse;
    set_version_labels: HttpErrorResponse,
    load_related_versions: HttpErrorResponse,
    load_header: HttpErrorResponse
}

interface VersionsViewer {
    id: number;
    version: Objects.Version;
    files: Array<Objects.File>;
    messages: Array<Objects.Message>;
    comments: Array<Objects.VersionMessage> // for feedback and 
    feedbacks: Array<any>; // TODO
    project: Objects.Request;
    relateds: Objects.Version[];
}

interface VersionsEditor {
    access: Access.AccessVersion;
}

export interface Versions {
    editor:  VersionsEditor;
    view: VersionsViewer;
    errors: VersionsErrors;
}

export const initialState: Versions = {
    editor: {
        access: null,
    },
    view: {
        id: null,
        version: null,
        files: [],
        messages: null,
        comments: null,
        feedbacks: null,
        project: null,
        relateds: [],
    },
    errors: {
        load_version: null,
        load_version_files: null,
        load_version_messages: null,
        load_version_comments: null,
        load_editor_access: null,
        load_version_feedbacks: null,
        load_version_project: null,
        set_version_status: null,
        set_version_labels: null,
        load_related_versions: null,
        load_header: null
    }
};

const versionsFeatureSelector = createFeatureSelector('versions');

// Selectors
export const viewSelector = createSelector(
    versionsFeatureSelector,
    (state: Versions) => state.view
);

export const versionSelector = createSelector(
    versionsFeatureSelector,
    (state: Versions) => state.view.version
);

export const versionRelatedsSelector = createSelector(
    versionsFeatureSelector,
    (state: Versions) => state.view.relateds
);

export const versionFilesSelector = createSelector(
    versionsFeatureSelector,
    (state: Versions) => state.view.files
);

export const versionMessagesSelector = createSelector(
    versionsFeatureSelector,
    (state: Versions) => state.view.messages
);

export const versionCommentsSelector = createSelector(
    versionsFeatureSelector,
    (state: Versions) => state.view.comments
);

export const versionProjectSelector = createSelector(
    versionsFeatureSelector,
    (state: Versions) => state.view.project
);

export const editorAccessAllSelector = createSelector(
    versionsFeatureSelector,
    (state: Versions) => state.editor.access
);

export const editorAccessSelector = (key: string) => 
    createSelector(
        versionsFeatureSelector,
        (state: Versions) => state.editor.access!=null && state.editor.access.hasOwnProperty(key) ? state.editor.access[key] : false
);

export const errorSelector = (key: string) => 
    createSelector(
        versionsFeatureSelector,
        (state: Versions) => state.errors.hasOwnProperty(key) ? state.errors[key] : null
    );