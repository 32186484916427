import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderFishermanComponent } from './fisherman/fisherman.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LoaderFishermanComponent,
    ],
    exports: [
        LoaderFishermanComponent,
    ],

})
export class LoaderModule { }
