import { Component, Input} from '@angular/core';

@Component({
  selector: 'core-helper-navigation-user-menu-header',
  templateUrl: './user-menu-header.component.html',
  styleUrls: ['./user-menu-header.component.scss']
})
export class HelperNavigationUserMenuHeaderComponent {
  
    @Input() user: any;
    @Input() open: boolean;
}
