import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from "@angular/common";
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// External
// import { FileUploadModule } from 'ng2-file-uploading-with-chunk';
//import { UploadxModule } from 'ngx-uploadx';
// import {NgxWigModule} from 'ngx-wig';
import { BrowserModule } from '@angular/platform-browser';

// Core/Components
import { CoreComponents } from './components/core.components';
import { CoreInterceptorsProvider } from './core.interceptors';
import { CoreServicesProvider } from './services/core.services';
import { CoreDirectives } from './directives/core.directives';
import { PipesModule } from './pipes/pipes.module';
// import { UsersSortByNamePipe } from './pipes/users-sort-by-name.pipes';
// import { SortByActivePipe } from './pipes/sort-by-active.pipes';

@NgModule({
    imports: [
        PipesModule,
        CommonModule,
        HttpClientModule,
        // FileUploadModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        // NgxWigModule,
        //UploadxModule,
        CoreComponents,
        CoreDirectives,

    ],
    declarations: [
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    exports: [
        CoreComponents,
        CoreDirectives,
        PipesModule
    ],
    providers: [
        //WebsocketService,
        CoreInterceptorsProvider,
        CoreServicesProvider

    ]
})

export class CoreModule { }
