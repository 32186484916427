import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { FileSizePipe} from 'ngx-filesize';

import { FileUploader, FileItem } from 'ng2-file-uploading-with-chunk';
import { UrlService } from '@core/services/url/url.service';
import { CookieService } from '@core/services/cookie/cookie.service';

import * as tus from 'tus-js-client';

import * as FileObject from '@core/objects/file';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MD5 } from "crypto-js";

interface FileUploadInterface {
    file: File;
    status: number;
    progress: number;
    hash: string;
    object: FileObject.File;
}

class FileUploadQueue {
    static readonly SUCCESS = 2;
    static readonly ERROR = 1;
    static readonly PENDING = 0;

    public queue: Array<FileUploadInterface> = [];

    // add
    public add(file: FileUploadInterface) : boolean {
        if(this.exists(file))
            return false;

        return this.queue.push(file) > 0;
    }

    public remove(file: FileUploadInterface) {
        this.queue = this.queue.filter( f => file.hash != f.hash);
    }

    public exists(file: FileUploadInterface) : boolean {
        let files = this.queue.filter( f => file.hash === f.hash);

        return files.length > 0;
    }

    public setStatus(file: FileUploadInterface, status: number) {
        this.queue = this.queue.map(f => file.hash === f.hash? {...f, status: status} : f);
    }

    public setProgress(file: FileUploadInterface, uploaded: number, total: number) {
        let p: number = +(uploaded / total * 100).toFixed(2)

        // this.queue = this.queue.map(f => file.hash === f.hash? {...f, progress: p} : f);
        this.queue = this.queue.map( f => {
            if(f.hash === file.hash){
                f.progress = p;
            }
            return f;
        })
    }

    public setFileObject(file: FileUploadInterface, object: FileObject.File) {
        this.queue = this.queue.map(f => file.hash === f.hash? {...f, object: object} : f);
    }

    public getOnPending() : Array<FileUploadInterface>  {
        return this.getOnStatus(FileUploadQueue.PENDING);
    }

    public getOnSuccess() : Array<FileUploadInterface>  {
        return this.getOnStatus(FileUploadQueue.SUCCESS);
    }

    protected getOnStatus(status: number) : Array<FileUploadInterface> {
        return this.queue.filter(f => f.status === status);
    }

    public create(file: File) : FileUploadInterface | null {
        let i = MD5(file.name + file.size);
        let f: FileUploadInterface = {
            file: file,
            status: FileUploadQueue.PENDING,
            progress: 0,
            hash: i.toString(),
            object: null
        };

        if(!this.add(f))
            return null;

        return f;
    }
}

@Component({
    selector: 'core-files-upload',
    templateUrl: './files-upload.component.html',
    styleUrls: ['./files-upload.component.scss'],
    providers: []
})
export class FilesUploadComponent implements OnInit, OnChanges {

    @Output() response  = new EventEmitter<any>();
    @Output() cancelItem  = new EventEmitter<any>();
    @Output() onUploading  = new EventEmitter<any>();
    @Input() multiple: boolean = true;
    @Input() hiddenUploaded: boolean = false;
    @Input() removed: FileObject.File; // eachtime this changes we remove it

    @ViewChild('iptFile') iptFile:ElementRef;

    public fileInputName: string; // its necessary for click on label

    public urlFileUpload: string;
    public uploader: FileUploader;
    public dragFile: FileItem;

    public hasBaseDropZoneOver:boolean = false;

    //public file:any;
    public disable:boolean = false;

    // TUS
    public uploaderTus: Array<tus.Upload> = [];
    public files: FileUploadQueue;


    constructor(public url: UrlService, public http: HttpClient, public cookies: CookieService) {
        this.files = new FileUploadQueue();

        this.fileInputName = Math.random().toString(36).replace('0.', '');
    }

    ngOnInit(){
        this.constructUrl();
        this.createUploaderDrag();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.removed && this.removed != null){
            this.removeFile(this.files.queue.filter( f => this.removed.id != f.hash)[0]);
        }
    }

    private constructUrl(){
        this.urlFileUpload = this.url.build("/files");
    }

    private onUploadSuccess(uploader, f){
        this.http.get<any>(uploader.url+"?f=json").subscribe(
            (data: FileObject.File) => {
                this.files.setFileObject(f, data);
                this.files.setStatus(f, FileUploadQueue.SUCCESS);

                this.emitUpdatedFiles();
                this.onUploadingEmit();
            },
            (error:any) => {
                console.log(error);
                this.files.setStatus(f, FileUploadQueue.ERROR);

            }
        );
    }

    // Method Drag
    protected createUploaderDrag(){
        this.uploader = new FileUploader({
            url: this.urlFileUpload,
            disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            chunkSize: 10000000, // 10MB
            autoUpload: true,
            chunkMethod: 'POST'
        });
        this.uploader.onBeforeUploadItem = (file) => {
            this.dragFile = file;
            this.onDragUploadFile();
            setTimeout(()=>{
                this.uploader.cancelItem(file);
                this.uploader.removeFromQueue(file);
            }, 10);
        };
    }

    protected onDragUploadFile(){
        if (this.dragFile != null) {
            this.addToFiles(this.dragFile._file);
        }
    }

    // dispatch when add a file on list for upload (not drag)
    public onUploadFiles(event) {
        for (let file of event.target.files) {
            this.addToFiles(file);
        }
    }

    protected addToFiles(file: File) {
        let f = this.files.create(file);
        if(f !== null)
            this.createTusUploader(f);
    }

    protected createTusUploader(f){
        let session = this.cookies.getCookie("TWONASSID");
        let uploader: tus.Upload = new tus.Upload(f.file, {
            endpoint: this.url.build("/files"),
            chunkSize: 10000000,
            retryDelays: [0, 3000],
            metadata: {
                filename: f.file.name,
                filetype: f.file.type
            },
            // weird fix for sessions
            onBeforeRequest: (req) => {
                var xhr = req.getUnderlyingObject();
                xhr.withCredentials = true;
            }
        });

        uploader.options.onError = (error) => {
            console.log("Failed because: " + error, this.uploaderTus);
            this.files.setStatus(f, FileUploadQueue.ERROR);
            // this.onUploadingEmit();
        };

        uploader.options.onProgress = (bytesUploaded, bytesTotal) => {
            this.files.setProgress(f, bytesUploaded, bytesTotal)
        };

        uploader.options.onSuccess = () => {
            // Change file to success in listFiles
            this.onUploadSuccess(uploader, f);
        }

        uploader.start();
        this.onUploadingEmit();

        this.uploaderTus[f.hash] = uploader;
    }

    protected emitUpdatedFiles() {
        let files: Array<FileObject.File> = this.files.getOnSuccess().map( f => f.object);

        this.response.emit(files);
    }

    protected onUploadingEmit() {
        let files = this.files.getOnPending();
        this.onUploading.emit(files.length > 0);
    }


    protected humanFileSize(size) {
        var i = size == 0 ? 0 : Math.floor( Math.log(size) / Math.log(1024) );
        return +( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    public fileOverBase(e:any):void {
        this.hasBaseDropZoneOver = e;
    }

    public removeFile(file: FileUploadInterface) {
        if(file.status !== FileUploadQueue.SUCCESS) {
            this.uploaderTus[file.hash].abort();
            delete this.uploaderTus[file.hash];
        }

        this.files.remove(file);
        this.iptFile.nativeElement.value = "";

        this.emitUpdatedFiles();
        this.onUploadingEmit();
    }

    public getViewName(item: any) {
        let filesize = new FileSizePipe();
        return item.file.name +" (" + filesize.transform(item.file.size) + ")";
    }

}
