import { Component } from '@angular/core';

@Component({
    selector: 'core-helpers-box-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class HelperBoxLoadingComponent {


}
