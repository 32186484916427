import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'core-message-legacy',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageLegacyComponent {

    @Input() message: any;

    @Output() onFileClick = new EventEmitter<any>();
    @Output() onFileShow = new EventEmitter<any>();

    public showFiles:boolean = false;
    //public files;
    public notes: boolean = true;
    public showFilesList: boolean = false;


    toggleShow() {
        this.showFilesList = !this.showFilesList;
        this.onFileShow.emit(this.showFilesList);

    }

    fileClick(file){
        console.debug('file ->', file);
        this.onFileClick.emit(file);
    }

}
