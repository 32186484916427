import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperFormModule } from '@core/components/helpers/form/form.module';

import { LayoutErrorsModule } from './errors/errors.module';
import { LayoutTitlesModule } from './titles/titles.module';
import { LayoutModalsModule } from './modals/modals.module';
import { LayoutHeaderComponent } from './header/header.component';
import { LayoutFooterComponent } from './footer/footer.component';
import { HelperNavigationModule } from '../helpers/navigation/navigation.module';

@NgModule({
    imports: [
        CommonModule,
        LayoutErrorsModule,
        LayoutTitlesModule,
        HelperNavigationModule,
        HelperFormModule,
        LayoutModalsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LayoutHeaderComponent,
        LayoutFooterComponent
    ],
    exports: [
        LayoutErrorsModule,
        LayoutHeaderComponent,
        LayoutFooterComponent,
        LayoutTitlesModule,
        LayoutModalsModule
    ],

})
export class LayoutModule { }
