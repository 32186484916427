
            
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'project-add-sent',
    templateUrl: './sent.component.html',
    styleUrls: ['./sent.component.scss']
})
export class ProjectAddSentComponent{

    @Input() type: string; // error,done
    @Input() message: string;
    @Input() firstButtonText: string = "";
    @Input() secondButtonText: string = "";
    @Output() firstButtonAction = new EventEmitter<boolean>();
    @Output() secondButtonAction = new EventEmitter<boolean>();

    constructor(){}


    onSecondAction(){
        this.secondButtonAction.emit(true);
    }

    onFirstAction(){
        this.firstButtonAction.emit(true);
    }

}
