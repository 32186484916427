import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SortByPipe } from './sort-by.pipes';
import { UsersSortByNamePipe } from './users-sort-by-name.pipes';
import { SortByActivePipe } from './sort-by-active.pipes';
import { SortByPipeMenuOrder } from './sort-by-menu-order.pipes';
import { FilterByFieldPipe } from './filter-by-field.pipes';
import { ProjectsSortByFieldPipe } from './projects-sort-by-field.pipes';

@NgModule({
    imports: [
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SortByPipe,
        UsersSortByNamePipe,
        SortByActivePipe,
        SortByPipeMenuOrder,
        FilterByFieldPipe,
        ProjectsSortByFieldPipe
    ],
    exports: [
        SortByPipe,
        UsersSortByNamePipe,
        SortByActivePipe,
        SortByPipeMenuOrder,
        FilterByFieldPipe,
        ProjectsSortByFieldPipe
    ],

})
export class PipesModule { }
