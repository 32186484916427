import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, RouterModule, NavigationEnd } from "@angular/router";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
// import { ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { AppService } from '@app/services/app/app.service';
import { UrlService } from '@core/services/url/url.service';
import { TitleService } from '@core/services/title.service';
import { VersionsService } from '@core/services/versions/versions.service';
import { MessagesService } from '@core/services/messages/messages.service';

import { File } from '@core/objects/file';
import { User } from '@core/objects/user';
import { Version } from '@core/objects/version';


import { VersionFeedback } from '@core/models/version-feedback';

// import { FeedbackService } from '../../services/feedback.service';
// import { VersionFeedback } from '../../../Core/models/version-feedback';


@Component({
    selector: 'plugin-share-feedback-feedback-review',
    templateUrl: './feedback-review.component.html',
    styleUrls: ['./feedback-review.component.scss']
})
export class PluginShareFeedbackFeedbackReviewComponent implements OnInit, OnChanges {
    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_FOUND = 3;
    // public readonly STATUS_MESSAGES_LOADING = 0;
    // public readonly STATUS_MESSAGES_DONE = 1;
    // public readonly STATUS_MESSAGES_NOT_FOUND = 3;

    // public readonly VIEW_APPROVAL_NONE = 0;
    // public readonly VIEW_APPROVAL_MODAL = 1;
    // public readonly VIEW_APPROVAL_DONE = 2;
    // public readonly VIEW_APPROVAL_ERROR = 3;

    @Input() feedback: VersionFeedback;
    // @Input() entry: any;

    // @Input() version: Version;
    public version: Version;
    // public entry: any;
    public files: Array<File> = [];
    public messages: Array<any> = [];
    public prefixUrl: string = '';
    public prefixNotesUrl: string = '';

    public selectedFile: File = null;
    // public approvalsUrl: string;
//     public readonly LOADING = 0;
//     public readonly DONE = 1;
//     public readonly NOT_FOUND = 3;

    // public messagesStatus: number = this.STATUS_MESSAGES_LOADING;
    public status: number = this.STATUS_LOADING;
    // public view: number = this.VIEW_APPROVAL_NONE;
// - Review versions and files using the thumbnail
// - You can leave a message and attach files to the requestor
// - When you are ready, leave your approval or rejection with a good reason"


//     public feedback:  any = null;
    public showMessageAdd: boolean = false; // message form
//     public feedback: any;
//     public date: Date;
//     public versionSelected: any = null;
//     // for files list
//     public bubbles: boolean = false;



    constructor( //private feedbackSrv: FeedbackService,
        private activeRoute: ActivatedRoute,
        private title: TitleService,
        private versionsSrv:VersionsService,
        private messagesSrv: MessagesService,
        private urlSrv: UrlService) {}

    ngOnInit() {
        this.init();
        // this.feedbackSrv.getEntry$().subscribe((entry: any) => {
        //     console.debug(" ==== FEEDBACKS entry -->", entry);
        //      this.entry = entry;
        // });

    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.feedback){
            console.debug(" feedback SimpleChanges");
            this.init();
        }
    }

    // ngOnDestroy() {
    //     // if (this.navigationSubscription) {
    //     //     this.navigationSubscription.unsubscribe();
    //     // }
    // }

    protected init() {
        // this.status = this.STATUS_LOADING;
        console.debug(" feedback is ",this.feedback);
        this.status = this.STATUS_DONE;
        // this.messagesStatus = this.STATUS_MESSAGES_LOADING;
        this.version = null;
        this.version = this.feedback.version;
        this.prefixUrl = '/p/feedback';
        this.prefixNotesUrl = '/p/feedback/versions/'+this.version.id;
        // console.debug(" CHANGEEEEEE old="+this.version.id+" new is "+this.feedback.version.id);

        this.title.set("Feedback on version " + this.feedback.version.version);
        // this.messagesUrl = this.urlSrv.build("/versions/"+this.feedback.version.id+"/messages");
        // this.approvalsUrl = this.urlSrv.build("/versions/"+this.feedback.version.id+"/approvals");
        // this.loadFiles();
        // this.loadMessages();
        this.selectedFile = null;
        // console.log(" url approvals ",this.urlSrv.build("/p/feedback/versions/"+this.version.id+"/approvals"))
        // this.urlSrv.setPrefix("/p/feedback");
        // init variables
        // this.status = this.STATUS_LOADING;
        // this.version = null;

        // let versionId = this.activeRoute.snapshot.paramMap.get('version');

        // this.feedbackSrv.getFeedbacks$().subscribe((feedbacks:Array<VersionFeedback>) => {
        //     if(feedbacks.length > 0)
        //     {
        //         // let current = feedbacks.map( f => f.version).filter(v => v.id.toString() == versionId);
        //         let currentFeedback = feedbacks.filter( f => f.version.id.toString() == versionId);

        //         if(currentFeedback.length > 0) {
        //             this.status = this.STATUS_DONE;
        //             this.version = currentFeedback[0].version;
        //             //console.log("FEEDBACKS current showign version in section  -->", this.version, currentFeedback);
        //         }
        //         else
        //             this.status = this.STATUS_NOT_FOUND;
        //     }
        //     // this.status = this.feedbacks.length > 0? this.DONE : this.LOADING;
        // });
    }
    // display de the modal to give feedback
    // public onApprovalModalShow(){
    //     this.view = this.VIEW_APPROVAL_MODAL;
    // }

    // public onFeedbackSent(e){
    //     // todo, by now we clos modal
    //     this.view = this.VIEW_APPROVAL_NONE;
    // }

    // public approve(message: any){
    //     this.feedbackSrv.setApproveVersion(this.feedback.version, message, true).subscribe(
    //         (data:any) => {
    //             console.debug("Approved sent "+this.feedback.version.id,data);
    //             this.view = this.VIEW_APPROVAL_DONE;
    //             this.feedbackSrv.load(this.hash);
    //         },
    //         (error:any) => {
    //             this.view = this.VIEW_APPROVAL_ERROR;
    //         }
    //     );
    // }

    // public reject(message: any){
    //     this.feedbackSrv.setApproveVersion(this.feedback.version, message, false).subscribe(
    //         (data:any) => {
    //             console.debug("Rejected sent "+this.feedback.version.id,data);
    //             this.view = this.VIEW_APPROVAL_DONE;
    //             this.feedbackSrv.load(this.hash);
    //         },
    //         (error:any) => {
    //             this.view = this.VIEW_APPROVAL_ERROR;
    //         }
    //     );
    // }
    //
    // public dismiss(e:any){
    //     this.view = this.VIEW_APPROVAL_NONE;
    // }

    // public openNotes(e: any){
    //     if(this.selectedFile != null && this.selectedFile.id == e.id)
    //         this.selectedFile = null;
    //     else
    //         this.selectedFile = e;
    // }

    // public saveMessage(e){
    //     this.loadMessages();
    // }

    // public deleteMessage(message: any){
    //     // TODO with messagesSrv
    //     console.debug("message delete clicked ", message);
    //     this.loadMessages();
    // }

    // protected loadFiles(){
    //     this.versionsSrv.getFiles$(this.feedback.version).subscribe((files: Array<File>) => {
    //         this.files = files;
    //         this.status = this.STATUS_DONE;
    //     });
    // }

    // protected loadMessages(){
    //     this.messagesStatus = this.STATUS_MESSAGES_LOADING;
    //     this.messages = [];
    //     this.messagesSrv.find(this.feedback.version).subscribe(
    //         (data:any) => {
    //             this.messages = data;
    //             console.debug("Messagessss get ", this.messages);
    //             this.messagesStatus = this.STATUS_MESSAGES_DONE;
    //         },
    //         (error:any) => {
    //             this.messagesStatus = this.STATUS_MESSAGES_NOT_FOUND;
    //         }
    //     );
    // }

    // notesSaved(e:any) {
    //     // this update files and reload notes counter
    //     this.files = [...this.files];
    // }








}
