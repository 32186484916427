import { Component, Input } from '@angular/core';

@Component({
    selector: 'core-helpers-box-status-message',
    templateUrl: './status-message.component.html',
    styleUrls: ['./status-message.component.scss']
})
export class HelperBoxStatusMessageComponent {

    @Input() title: string;
    @Input() description: string;

    @Input() typeMessage:string;
}
