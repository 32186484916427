import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// import { HttpStatus } from '@core/classes/http-status.class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


import { Workflow } from '@core/objects/workflow';

import { WorkflowsService } from '../../../../../services/settings/workflows.service';
import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';


@Component({
    selector: 'settings-workflows-main-edit',
    templateUrl: './main-edit.component.html',
    styleUrls: ['./main-edit.component.scss']
})
export class SettingsWorkflowsMainEditComponent implements OnInit{
    private readonly ACCESS = 'access_setting_workflows_access';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_FOUND = 2;

    public workflow: Workflow = null;
    // public selectedWorkflow: Workflow = null;
	public workflowName:string = '';
	public access:boolean = true;
    public status: number = 0;
    public breadcrumbs: any = [
        {
            title: "System Settings",
            link: ['/settings']
        },
        {
            title: "Workflows",
            link: ['/settings/workflows']
        }
    ];
    public showForm: boolean = false;
    public showInstructions: boolean =  false;


	// TODO review menu icons
	public menu: Array<ThreeDotsMenuItem> = [{
		title: "Edit workflow name",
		key: "edit-name",
		icon: "default", // its same as edit tags icon
		order: 1
	}];

	constructor(public workflowsSrv: WorkflowsService, public app: AppService, private title: TitleService, private activeRoute: ActivatedRoute){}

	ngOnInit(){
        this.title.set("Workflows setting");
        let workflowId = this.activeRoute.snapshot.paramMap.get('id');

        this.app.getSession().subscribe( (session: any) => {
            this.access = session.options.access.hasOwnProperty(this.ACCESS)? session.options.access[this.ACCESS] : false;
        });

        if (this.access){
            this.load(workflowId);
        }
	}

    // onSaved(event){
    //     this.showForm = false;
    //     this.load();
    // }

    // onColorChange(color: string ,versionStatus: Status){
    //     this.updateColor(versionStatus,color);
    // }

	protected load(workflowId: string){
        this.workflowsSrv.getAllWorkflows$().subscribe((workflows: Array<Workflow>) => {
            let ws = workflows.filter(w => w.id.toString() == workflowId);

            if(ws.length === 0){
                this.status = this.STATUS_NOT_FOUND;
                return;
            }

            this.workflow = ws[0];
            console.debug(" MY workflow",this.workflow);

            if(this.workflow.active == false){
                this.status = this.STATUS_NOT_FOUND;
                return
            }
            this.workflowName = this.workflow.name;
            this.title.set("Editing: "+this.workflow.name+" - Workflows - System settings");

            this.status = this.STATUS_DONE;
        });

	}

    onUpdated(workflow){
        this.showForm = false;
        this.workflow = workflow;
    }

    // protected cleanForm(){
    //     this.form.reset({name: ''});
    // }

    // onCancel(event) {
    //     this.showForm = false;
    //     this.cleanForm();
    // }

	onMenuClick(key: string) {
        switch(key) {
            case "edit-name":
                this.showForm = !this.showForm;
                break;
        }
    }
}
