import { Component, Input, OnInit } from '@angular/core';

import * as  Objects from '@core/objects';

@Component({
    selector: 'core-versions-detail-box',
    templateUrl: './detail-box.component.html',
    styleUrls: ['./detail-box.component.scss']
})
export class VersionsDetailBoxComponent implements OnInit {
    
    @Input() version: Objects.Version;
    
    constructor( ) { }

    ngOnInit() {
        // this.setFutureStatus();
    }

}
