import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelsModule } from '@core/components/labels/labels.module';
//import { CoreModule } from '@core/core.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { VersionFilesModule } from '@core/components/versions/version-files/version-files.module';
import { VersionsRelatedGroupItemFilesListCheckboxComponent } from './related-group-item-files-list-checkbox/related-group-item-files-list-checkbox.component';
import { VersionsRelatedFilesListCheckboxComponent } from './versions-related-files-list-checkbox/versions-related-files-list-checkbox.component';
import { VersionsRelatedGroupsFilesListCheckboxComponent } from './related-groups-files-list-checkbox/related-groups-files-list-checkbox.component'

import { VersionsRelatedGroupItemListComponent } from './related-group-item-list/related-group-item-list.component';
import { VersionsRelatedGroupItemVersionsListComponent } from './related-group-item-versions-list/related-group-item-versions-list.component';

@NgModule({
    imports: [
        CommonModule,
        LabelsModule,
        HelpersModule,
        VersionFilesModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionsRelatedGroupItemFilesListCheckboxComponent,
        VersionsRelatedFilesListCheckboxComponent,
        VersionsRelatedGroupsFilesListCheckboxComponent,
        VersionsRelatedGroupItemListComponent,
        VersionsRelatedGroupItemVersionsListComponent
    ],
    exports: [
        VersionsRelatedGroupItemFilesListCheckboxComponent,
        VersionsRelatedFilesListCheckboxComponent,
        VersionsRelatedGroupsFilesListCheckboxComponent,
        VersionsRelatedGroupItemListComponent,
        VersionsRelatedGroupItemVersionsListComponent
    ],

})
export class VersionsRelatedModule { }
