import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';
 
import { AppService } from '@app/services/app/app.service';

import { TitleService } from '@core/services/title.service';
import { MenuItem } from '@core/models/menu-item.model';
import { LibraryService } from '@app/services/app/library.service';
import * as Search from '@app/models/search';

@Component({
    selector: 'library-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class LibraryMainComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;
    public readonly STATUS_NOT_FOUND = 3;
    public readonly STATUS_ERROR = 4;

    public status: number = 0;
   
    protected querySubscriptor: Subscription;
    protected sessionSubscriptor: Subscription;
    private firstRoute: boolean = false;
    private firstLoad: boolean = false;

    params: Search.SearchQueryParams = null;

    // private paramsCheck = {
    //     store: {
    //         checked: false,
    //         params: null
    //     },
    //     session: {
    //         checked: true,
    //         params: null
    //     }
    // }


	constructor(
        public app: AppService, 
        private title: TitleService,
        private librarySrv: LibraryService,
        private route: ActivatedRoute,
        private router: Router
        ){}

	ngOnInit(){
        this.title.set("Library");
        this.status = this.STATUS_DONE;

        this.querySubscriptor = this.librarySrv.selectQueryParams().subscribe((params: Search.SearchQueryParams) => {
            // this.setQueryInURL(params);
            this.firstLoad = true;
        });
       
        this.route.queryParams.subscribe(params => {
            // console.log("params from url--->", params, this.firstRoute, this.params);
            if (!this.firstRoute && this.params == null)
                this.params = Search.SearchConvert.paramsFromRouteParams(params);
            
            this.firstRoute = true;
        });

	}

    // private checkParams(key: string, params: Search.SearchQueryParams) {
    //     if(!this.paramsCheck[key].checked) {
    //         this.paramsCheck[key].checked = true;
    //         this.paramsCheck[key].params = params;
    //     }

    //     if(this.paramsCheck.session.checked && this.paramsCheck.store.checked) {
    //         let params = this.paramsCheck.store.params;
    //         if(params == null) {
    //             params = this.paramsCheck.session.params;
    //         }

    //         this.setQueryInURL(params)
    //     }
    // }

    private setQueryInURL(params: Search.SearchQueryParams) {
        // console.log("queryyyy", query);
        // this.params = params;
        if(params == null)
             return;

        if(!this.firstLoad)
            this.params = params;
            
        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: Search.SearchConvert.removeVoidParams(params),
                queryParamsHandling: '',
                // skipLocationChange: true // doesnt write url

            }
        )
    }

    ngOnDestroy() {
        // TODO
    }

    protected setError(error: HttpErrorResponse) {

        if(error == null)
            return;

        switch(error.status) {
            case 500:
                this.status = this.STATUS_ERROR;
                break;
            case 404:
                this.status = this.STATUS_NOT_FOUND;
                break;
            case 403:
                this.status = this.STATUS_NOT_ALLOWED;
                break;
        }
    }

    init() {

    }

}
