import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { VersionsViewService } from '@app/services/app/versions.service';

@Component({
    selector: 'version-details-files',
    templateUrl: './files.component.html',
    styleUrls: ['./files.component.scss']
})
export class VersionsDetailsContentFilesComponent implements OnInit, OnDestroy {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly ERROR = 2;

    @Input() menu: Array<ThreeDotsMenuItem> = [];
    @Output() action = new EventEmitter<FilesListItemMenuClick>();
    @Output() actionClick = new EventEmitter<Objects.File>();

    public files: Array<Objects.File> = [];
    public status:number = this.LOADING;

    protected filesSubscriptor: Subscription;
    protected appSubscriptor: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private urlSrv: UrlService,
        private viewVersionsSrv: VersionsViewService) { }

    ngOnInit() {
        this.filesSubscriptor = this.viewVersionsSrv.selectFiles().subscribe((files: Objects.File[] ) => {
            this.files = files
            this.status = this.DONE;
        });
    }

    ngOnDestroy() {
        this.filesSubscriptor.unsubscribe();
    }

    onMenuClick(menu: FilesListItemMenuClick) {
        console.debug(" clic on item ",menu.key);
        this.action.emit(menu);
        
    }

    actionFileClick(file: Objects.File){
        // This navigates to new route from the current route.
        this.router.navigate(["files/"+file.id], { relativeTo: this.activatedRoute });
    }

}
