import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { AppRouterModule } from '@app/router.module';
import { AppHelperLabelsModule } from '@app/components/helpers/labels/labels.module';
import { AppHelperFormModule } from '@app/components/helpers/form/form.module';
import { VersionsAddMainComponent } from './main/main.component';
import { LayoutModule } from '@core/components/layout/layout.module';
import { LabelsModule } from '@core/components/labels/labels.module';
import { LayoutTitlesModule } from '@core/components/layout/titles/titles.module';
import { VersionAddDetailsModule } from './details/details.module';


@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        LabelsModule,
        HelpersModule,
        LayoutModule,
        AppHelperLabelsModule,
        AppHelperFormModule,
        LayoutTitlesModule,
        VersionAddDetailsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionsAddMainComponent   ],
    exports: [
        VersionsAddMainComponent,
    ],

})
export class VersionAddModule { }
