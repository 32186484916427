import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';


@Component({
    selector: 'core-layout-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {
    @Input() session: any;
    @Output() newOrganization = new EventEmitter<number>();

    constructor(private router: Router) { }

    ngOnInit() { }

    changeOrg(value) {
        this.newOrganization.emit(value);
    }

    pageMatch(){
        if (this.router.url.includes('/dashboard'))
            return 'dashboard';

        if (this.router.url.includes('/library'))
            return 'library'
        
        return '';
    }

    navigateTo(page: string){
        this.router.navigate(["/"+page]);
    }

    navigateToSkipLocation(page: string){
        this.router.navigate(["/"+page], { skipLocationChange: true });
    }

}
