import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpersModule } from '@core/components/helpers/helpers.module';
import { VersionsModule } from '@core/components/versions/versions.module';

import { VersionHelpersModule } from '../helpers/helpers.module';

import { VersionsRelatedMainComponent } from './main/main.component';
import { VersionsRelatedFilePreviewComponent } from './related-file-preview/related-file-preview.component';

@NgModule({
    imports: [
        CommonModule,
        HelpersModule,
        VersionsModule,
        VersionHelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionsRelatedFilePreviewComponent,
        VersionsRelatedMainComponent
    ],
    exports: [
        VersionsRelatedFilePreviewComponent,
        VersionsRelatedMainComponent
    ],

})
export class VersionsRelatedModule{ }
