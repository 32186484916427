import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    protected prefix: string = "";

    constructor(protected title: Title) {}

    set(title: string | null) {
        title = title == null? "" : title + " - ";

        this.title.setTitle(title + this.getPrefix());
    }

    setPrefix(prefix: string) {
        this.prefix = prefix;
    }

    getPrefix() : string {
        return this.prefix;
    }
}
