import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppHelpersSearchFiltersModule } from './filters/filters.module';
import { AppHelpersSearchBarModule } from './bar/bar.module';

@NgModule({
    imports: [
        CommonModule,
        AppHelpersSearchFiltersModule,
        AppHelpersSearchBarModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
    ],
    exports: [
        AppHelpersSearchFiltersModule,
        AppHelpersSearchBarModule
    ],

})
export class AppHelpersSearchModule { }