// core-helpers-navigation-vertica-tabs
// HelperNavigationVerticalTabsComponent
import { Component, Input, OnInit } from '@angular/core';
import { Router,  NavigationEnd, ResolveEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { MenuItem } from '@core/models/menu-item.model';

@Component({
    selector: 'core-helpers-navigation-vertical-tabs',
    templateUrl: './vertical-tabs.component.html',
    styleUrls: ['./vertical-tabs.component.scss']
})
export class HelperNavigationVerticalTabsComponent implements OnInit {
    //public selectedIndex: number;
    public currentRoute: string;
    public ShowNavOptions: boolean;
    
    @Input() menu: MenuItem[];
    @Input() fullMatch: boolean = true;

    public showNavOptions: boolean;

    constructor(private router: Router){
        this.router.events.pipe(
            filter(event => (event instanceof NavigationEnd || event instanceof ResolveEnd) )
        ).subscribe((event: any) => {
            console.log("URL 2 ---->", event.url);
            this.currentRoute = event.url;
        });
    }

    ngOnInit(){
        this.currentRoute = this.router.url;
    }
}
