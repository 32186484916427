import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { UrlService } from '@core/services/url/url.service';

import { Version } from '@core/objects/version';
// import { File } from '@core/objects/file';
// import { Request } from '@core/objects/request';
// import { Label } from '@core/objects/label';
// import { Related } from '@core/objects/related';

@Component({
    selector: 'core-version-preview-title',
    templateUrl: './preview-title.component.html',
    styleUrls: ['./preview-title.component.scss']
})
export class VersionPreviewTitleComponent implements OnInit {

    @Input() title: string = "";
    @Input() version: Version;
    // @Input() mode: string;
    @Output() clickSelect: EventEmitter<Version> = new EventEmitter<Version>();


    // constructor(private httpClient: HttpClient, private urlSrv: UrlService ) { }

    ngOnInit() {
    }

}
