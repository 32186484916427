import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LabelsModule } from '@core/components/labels/labels.module';
import { FilesListModule } from '@core/components/files/lists/lists.module';

import { ProjectsDetailsHelpersModule } from '../../../helpers/helpers.module';
import { ProjectsDetailsInfoFeaturesModule } from '../../../info/features/features.module';

import { ProjectDetailsLogbookRequestsEventPropertiesComponent } from './requests-event/requests-event.component';
import { ProjectDetailsLogbookVersionsEventPropertiesComponent } from './versions-event/versions-event.component';
import { ProjectDetailsLogbookEventPropertiesMainComponent } from './main/main.component';
import { ProjectDetailsLogbookEventUserOnComponent } from './user-on/event-user-on.component';
import { ProjectDetailsLogbookSharesEventPropertiesComponent } from './shares/shares.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreModule,
        HelpersModule,
        LabelsModule,
        FilesListModule,
        ProjectsDetailsHelpersModule,
        ProjectsDetailsInfoFeaturesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsLogbookEventPropertiesMainComponent,
        ProjectDetailsLogbookRequestsEventPropertiesComponent,
        ProjectDetailsLogbookVersionsEventPropertiesComponent,
        ProjectDetailsLogbookSharesEventPropertiesComponent,
        ProjectDetailsLogbookEventUserOnComponent
        
    ],
    exports: [
        ProjectDetailsLogbookEventPropertiesMainComponent
    ],

})
export class ProjectDetailsLogbookEventPropertiesModule { }