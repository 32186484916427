import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Subscription } from 'rxjs';

// import { HttpStatus } from '@core/classes/http-status.class';

// import { License } from '@core/objects/license';
import { User } from '@core/objects/user';
import { Group } from '@core/objects/group';

import { SelectItem } from '@core/models/select-item.model';

// import { UserRequest } from '@core/objects/user-request';

import { UsersService } from '../../../../../services/settings/users.service';
import { GroupsService } from '../../../../../services/settings/groups.service';

@Component({
    selector: 'settings-groups-group-users-add-form',
    templateUrl: './group-users-add-form.component.html',
    styleUrls: ['./group-users-add-form.component.scss']
})
export class SettingsGroupsGroupUsersAddFormComponent implements OnInit, OnChanges {

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

	@Input() group: Group;
	@Input() existingUsers: Array<User> = [];

	@Output() cancel = new EventEmitter<boolean>();
	@Output() submit = new EventEmitter<User>();

	public status: Number = 0;

	public users: Array<SelectItem> = [];
	private allUsers: Array<User> = [];
	public selectedUser: User = null;

	private subscriptionUsers: Subscription;

	constructor(public grpSrv: GroupsService, private usrSrv: UsersService){}

	ngOnInit(){
		// this.loadUsers();
        // this.grpSrv.loadAllGroups();
		this.usrSrv.loadAllUsers();

        this.subscriptionUsers = this.usrSrv.selectorGetAllUsers().subscribe((users: User[]) => {
			this.allUsers = users;
			if(users !== null) {
				this.setCandidateUsers();
				this.status = this.STATUS_DONE;
			}
			// this.userSrv.loadAllLicenses();
        });
	}

	ngOnChanges(changes: SimpleChanges){
		if(changes.existingUsers) {
			this.setCandidateUsers();
        }
	}

	private setCandidateUsers() {
		let usrs = this.allUsers.filter(({ id: id1 }) => !this.existingUsers.some(({ id: id2 }) => id2 === id1));

		this.users = usrs.map((u: User) => {
			return {
				title: u.name,
				value: u.id,
				selected: false
			};
		});
	}

	// loadUsers() {
	// 	this.grpSrv.findGroupUsers(this.group).subscribe((users: Array<User>) => {
	// 		this.users = users;
	// 		this.status = this.STATUS_DONE;
	// 	});
	// }

	// remove(user: User) {
	// 	this.grpSrv.deleteUser(this.group, user).subscribe(() => {
	// 		this.loadUsers();
	// 	});
	// }

	onSubmit() {
		// TODO
		this.grpSrv.addUser(this.group, this.selectedUser).subscribe(() => {
			this.selectedUser = null;
			this.setCandidateUsers();
			this.submit.emit(this.selectedUser);
		});
	}

	onCancel() {
		this.setCandidateUsers();
		this.selectedUser = null;
		this.cancel.emit(true);
	}

	private getUserFromSelection(select: SelectItem) {
		let usrs = this.allUsers.filter((u) => select.value == u.id);

		return usrs[0];
	}

	select(select: SelectItem) {
		this.selectedUser = this.getUserFromSelection(select);
		console.log(this.selectedUser);
	}
}
