
import { Component, Input } from '@angular/core';

import * as Objects from '@core/objects';


@Component({
    selector: 'project-details-logbook-event-user-on',
    templateUrl: './event-user-on.component.html',
    styleUrls: ['./event-user-on.component.scss']
})
export class ProjectDetailsLogbookEventUserOnComponent {
	@Input() event: Objects.Event;
    @Input() properties: any = null;

    getObjectType(){
        return this.event.type.substring(0,this.event.type.indexOf('.'));
    }
}