import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// import { HttpStatus } from '@core/classes/http-status.class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, RouterModule } from "@angular/router";

import { Workflow } from '@core/objects/workflow';

import { WorkflowsService } from '../../../../../services/settings/workflows.service';
import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';


@Component({
    selector: 'settings-workflows-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SettingsWorkflowsMainComponent implements OnInit{
    private readonly ACCESS = 'access_setting_workflows_access';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

    public workflows: Array<Workflow>
    public selectedWorkflow: Workflow = null;
	public showForm:boolean = false;
	public access:boolean = true;
    public status: number = 0;
    public breadcrumbs: any = [
        {
            title: "System Settings",
            link: ['/settings']
        }
    ];


	// TODO review menu icons
	public menu: Array<ThreeDotsMenuItem> = [{
		title: "Add a new workflow",
		key: "add-workflow",
		icon: "add",
		order: 1
	}];

	constructor(public workflowsSrv: WorkflowsService, public app: AppService, private title: TitleService, private router: Router){}

	ngOnInit(){
        this.title.set("Workflows - System settings");
        this.app.getSession().subscribe( (session: any) => {
            this.access = session.options.access.hasOwnProperty(this.ACCESS)? session.options.access[this.ACCESS] : false;
        });
        if (this.access){
            this.load();
        }
	}

    onSaved(event){
        this.showForm = false;
        this.load();
    }

    // onColorChange(color: string ,versionStatus: Status){
    //     this.updateColor(versionStatus,color);
    // }

	protected load(){
        this.workflowsSrv.getAllWorkflows$().subscribe((workflows: Array<Workflow>) => {
            console.debug(" ALL workflows",workflows)
            this.workflows = workflows;
            this.status = this.STATUS_DONE;
        });

	}

    onSelect(workflow: Workflow) {
        if(!workflow.active)
            return;

        this.router.navigate(['/settings/workflows/', workflow.id]);
        // if(this.selectedWorkflow != null && this.selectedWorkflow.id == workflow.id)
        //     this.selectedWorkflow = null;
        // else
        //     this.selectedWorkflow = workflow;
    }

    deactivate(workflow: Workflow) {
        this.workflowsSrv.deactivateWorkflow$(workflow.id).subscribe(data => {
            this.load()
        });
    }

    activate(workflow: Workflow) {
        this.workflowsSrv.activateWorkflow$(workflow.id).subscribe(data => {
            this.load()
        });
    }

    // updateColor(versionStatus: Status, color: string) {
    //     this.versionStatusSrv.updateVersionStatusColor$(versionStatus,color).subscribe(data => {
    //         this.load()
    //     });
    // }

	onMenuClick(key: string) {
        switch(key) {
            case "add-workflow":
                this.showForm = !this.showForm;
                break;
        }
    }
}
