import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpResponse,
  HttpHandler, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { AppService } from '@app/services/app/app.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {

    constructor(private app: AppService) {
    }

    intercept( req: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {

      return next.handle(req).pipe(tap(
          (data: any) => {
              //console.log(data);
              //this.app.setLogged(true);
          },
          (err: any) => {
              if (err instanceof HttpErrorResponse) {
                  //console.log("llega el error status ", err.status);
                  console.log('req url :: ' + JSON.stringify(err));
                  this.app.setHttpError(err.status);
                  // switch (err.status) {
                  //   case 401: // not logged
                  //     this.app.setLogged(false);
                  //     break;
                  //   case 509: // too many sessions
                  //     this.app.sendTooManySessions();
                  //     break;
                  // }
              }
          }
      ));

    }

}
