import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';

@Component({
    selector: 'core-file-download-one-action-list-item',
    templateUrl: './file-download-one-action-list-item.component.html',
    styleUrls: ['./file-download-one-action-list-item.component.scss']
})
export class FileDownloadOneActionListItemComponent implements OnInit {

    @Input() file: File;
    @Input() actionText: string = "";
    @Input() actionStyle: string = "";
    @Input() bubble: boolean = false;
    @Input() bubbleNumber: Number = 0;

    @Output() actionClick = new EventEmitter<File>(); //textActionClick
    @Output() bubbleClick = new EventEmitter<any>(); //bubbleClick

    constructor() { }

    ngOnInit() {

    }

    onActionClick(file: File) {
        console.debug(" core-file-download-one-action-list-ITEM onActionClick ",file);
        this.actionClick.emit(file)
    }
    onBubbleClick(file: File) {
        console.debug(" core-file-download-one-action-list-ITEM onBubbleClick ",file);
        this.bubbleClick.emit(file)
    }


}
