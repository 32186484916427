import { Injectable } from '@angular/core';
//import { Observable } from "rxjs"
import { Observable, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
// import { UrlService } from '@core/services/url/url.service';
import { User } from '@core/objects/user';

export interface AvatarSubject {
    id: string;
    subject: Subject<any>;
    size: number;
}

@Injectable({
    providedIn: 'root'
})
export class AvatarsService {
    private avatars: Array<AvatarSubject> = [];

    constructor(protected http: HttpClient) {}

    public getSubscriptor(user: User, size: number) {
        let avatar = this._getByIdAndSize(user.id, size);

        return avatar.subject.asObservable();
    }

    public getSubscriptorByUserIdAndSize$(id: string, size: number) {
        let avatar = this._getByIdAndSize(id, size);

        return avatar.subject.asObservable();
    }

    protected _loadByIdAndSize(id: string, size: number) {
        let avatar = this._getByIdAndSize(id, size);

        let url = "/web/avatar/"+ id + "/" + size + "/base64";
        this.http
        .get(url, { responseType: 'text' })
        .subscribe(
            (data:string) => {
                this._process(id, size, data);
            },
            (error:any) => {
                console.error('Error avatar: '+ error);
            }
        );
    }

    protected _process(id: string, size: number, data: string) {
        let avatar = this._getByIdAndSize(id, size);

        avatar.subject.next(data); // dispatch

    }

    public load(user: User, size: number) {
        this._loadByIdAndSize(user.id, size);
    }

    private _getByIdAndSize(id: string, size: number): AvatarSubject {

        let avatars = this.avatars.filter( (as: AvatarSubject) => {
            return as.id == id && as.size == size;
        });

        if (avatars.length > 0)
            return avatars[0];

        let avatar = {
            id: id,
            subject: new Subject<any>(),
            size: size
        };

        this.avatars.push(avatar);

        return this._getByIdAndSize(id, size);
    }

    /**
     * reload methods
     */
    reload(user: User) {
        let avatars = this.avatars.filter((as: AvatarSubject) => as.id == user.id);

        avatars.forEach((as: AvatarSubject) => this._loadByIdAndSize(as.id, as.size));
    }


}
