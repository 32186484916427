import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LabelsModule } from '@core/components/labels/labels.module';

import { AppRouterModule } from '@app/router.module';

import { LibraryMainComponent } from './main/main.component';
import { LibrarySearchModule } from './search/search.module';
import { LibraryResultsComponent } from './results/results.component';


@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        CoreModule,
        HelpersModule,
        LabelsModule,
        LibrarySearchModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LibraryMainComponent,
        LibraryResultsComponent
    ],
    exports: [
    ],

})
export class LibraryModule { }
