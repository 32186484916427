import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
// import * as Access from '@app/models/access';
import * as Search from '@app/models/search';

@Injectable({
    providedIn: 'root'
})
export class LibraryRequests {
    constructor(private http: HttpClient, private urlSrv : UrlService) {}

    filterLabels$ = () : Observable<Objects.Label[]> =>
        this.http.get<Objects.Label[]>(this.urlSrv.build("/labels"));
    
    // users$ = () : Observable<Objects.User[]> =>
    //     this.http.get<Objects.User[]>(this.urlSrv.build("/users"));
    
    filterStatus$ = () : Observable<Objects.Status[]> =>
        this.http.get<Objects.Status[]>(this.urlSrv.build("/versions/status"));

    // results$ = (query: string, page: number) : Observable<Objects.Version[]> =>
    //     this.http.get<Objects.Version[]>(this.urlSrv.build("/library?") + query, { params: new HttpParams().set('page', page.toString())});

    results$ = (params: Search.SearchQueryParams, page: number, limit: number, vid: number | string | null) : Observable<Objects.Version[]> => {
        let query = {...params, page: page.toString(), limit: limit.toString()};
        
        if(vid != null) {
            query['q'] = query.hasOwnProperty('q')? query['q'] + "+id:" + vid.toString() : "id:" + vid.toString();
            query['page'] = "1";
        }

        let p = new HttpParams();
        Object.keys(query).forEach(key => p = p.set(key, query[key]) );

        return this.http.get<Objects.Version[]>(this.urlSrv.build("/library"), { params: p});
    }
}