import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';


import { AppService } from '@app/services/app/app.service';
import { UrlService } from '@core/services/url/url.service';
import { TitleService } from '@core/services/title.service';

import { Request } from '@core/objects/request';
import { File } from '@core/objects/file';

import { User } from '@core/objects/user';
import { Organization } from '@core/objects/organization';

// class SelectedFilesToTransfer {
//     public selected: Array<File> = [];
// }
@Component({
    selector: 'plugin-share-transfer-main-app',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class PluginShareTransferMainAppComponent implements OnInit {

    readonly PLUGIN = "share_transfer";

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly NOT_FOUND = 3;

    public readonly VIEW_SELECTOR = 0;
    public readonly VIEW_SENDER = 1;
    public readonly VIEW_DONE = 2;
    public readonly VIEW_FEEDBACK_ERROR = 3;
    public readonly VIEW_FEEDBACK_DONE = 4;

    // public screenMode:number = this.MODE_SELECTION;
    public status: number = this.LOADING;
    public permission: boolean = true;
    public view: number = this.VIEW_SELECTOR;

    public request: Request = null;
    public filesUrl: string = "";
    public filesPluralPart: string = "";

    // public files: SelectedFilesToTransfer = new SelectedFilesToTransfer();

    public filesSelected: Array<File> = [];
    //public form: FormGroup = new FormGroup({});
    public form: FormGroup;

    protected user: User;
    protected organization: Organization;
    public subject: string;

    public subscriptionRoutes: Subscription;

    constructor(private appSrv: AppService, private http: HttpClient, public urlSrv: UrlService, private activeRoute: ActivatedRoute, private title: TitleService) {
        // TODO: check options to select screen mode

        this.appSrv.getSession().subscribe(session => {
            this.user = session.user;
            this.organization = session.organization;

            if(session.options.plugins.indexOf(this.PLUGIN) == -1)
                this.status = this.NOT_FOUND;
        });
    }

    ngOnInit() {
        // load query params
        this.subscriptionRoutes = this.activeRoute.queryParams.subscribe(params => {
            if (params.file) {
                this.loadFile(params.file);
            }
        });

        this.form =  new FormGroup({});

        let requestId = this.activeRoute.snapshot.paramMap.get('request');

        this.title.set("Select files to send - Project #"+requestId);
        this.filesUrl = "/requests/" + requestId +"/files";

        if(this.status != this.NOT_FOUND)
            this.loadRequest(Number(requestId));

    }

    onChange(e) {
        if(e.length >=0)
            this.filesSelected = (e);
    }

    ngOnDestroy(){
        this.subscriptionRoutes.unsubscribe();
    }

    protected loadFile(id: Number) {
        this.http.get(this.urlSrv.build('/files/'+id)+"?f=json").subscribe(
            (data:File) => {
                this.filesSelected.push(data);
            },
            (error:any) => {
            }
        );
    }

    protected loadRequest(requestId: number){
        this.http.get(this.urlSrv.build('/requests/'+requestId)).subscribe(
            (data:Request) => {
                //this.request = data;
                this.setRequest(data);
                this.status = this.DONE;
            },
            (error:any) => {
                this.status = this.NOT_FOUND;
            }
        );
    }

    public setView(view: number)
    {
        this.view = view;
    }

    public setRequest(request: Request) {
        this.request = request;

        let labels = this.request.labels.map(l => l.name).join(", ");
        this.subject = this.user.name + " from " + this.organization.name +
            " is sending you file(s) related to #"+this.request.id + " - " + labels;
    }

    public onSubmit() {
        let data = {
            type: "transfer",
            files: this.filesSelected.map((f: File) => f.id),
            subject: this.form.get('subject').value,
            message: this.form.get('message').value,
            emails: this.form.get('emails').value
        }
        if(data.files.length >1 ) {
            this.filesPluralPart = 's';
        }
        this.http.post(this.urlSrv.build('/share'), data).subscribe(
            (data: any) => {
                this.view = this.VIEW_FEEDBACK_DONE;
            },
            (error:any) => {
                this.status = this.NOT_FOUND;
                this.view = this.VIEW_FEEDBACK_ERROR;
            }
        );

        console.debug("submit", data);

    }

    public cancel () {
        this.filesSelected = [];
    }
    // checks to show the button SEND
    isTransferReady () {
        return (
            this.filesSelected.length > 0 &&
            this.form.get('subject').value != '' &&
            this.form.get('emails').value.length > 0 &&
            this.form.get('message').value.length > 0
           )
    }
    // checks to show the button NEXT
    isSelectionReady() {
        return (this.filesSelected.length > 0);
    }
    // action when push modal buton when error
    onError() {
        console.debug(" Error modal clicked");
        this.view = this.VIEW_SENDER;
        this.status = this.DONE
    }

    onSent() {
        console.debug(" Sent modal clicked ");
        this.view = this.VIEW_DONE;
        this.cancel();
    }



}
