import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Session } from '@app/store/models/session.model';
// import { Store } from '@ngrx/store';

import { User } from '@core/objects/user';

import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';

@Component({
    selector: 'profile-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProfileMainComponent implements OnInit{
    // private readonly ACCESS = 'access_setting_workflows_access';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_FOUND = 2;

	public access:boolean = true;
    public status: number = 0;

    public form: FormGroup;
    public user: User = null;
    private subscriptor: Subscription;
    public passwordRequested: boolean = false;

    public showAvatarEditor: boolean = false;
    public showSaved: boolean = false;
    public saving: boolean = false;


	constructor(public app: AppService, private title: TitleService, private formBuilder: FormBuilder){}

	ngOnInit(){
        this.title.set("Profile");
        this.init();

        if(this.user != null ){
            this.status = this.STATUS_DONE;
        }
    }

    init(){
        this.app.getSession().subscribe( (session: Session) => {
            if (this.saving){
                this.showSaved = true;
            }
            this.user = session.user;
        });

        this.form = this.formBuilder.group({
            name: [this.user.name, [Validators.required]],
            position: [this.user.position],
            telephone: [this.user.telephone],
            email: [{ value: this.user.email, disabled: true }]
        });
    }

    save(){
        if(this.form.invalid)
			return;
        this.saving = true;
        this.app.updateUser(this.form.value.name,this.form.value.telephone,this.form.value.position);
    }

    onSavedDone(event){
        this.saving = false;
        this.showSaved = false;
    }

    changePassword(){
        this.app.resetPassword().subscribe( (data: string) => {
            // console.debug(" data from call",data);
            this.passwordRequested = true;
        });
	}

    public openAvatarEditor(){
        this.showAvatarEditor = true;
    }


    public closeAvatarEditor(){
        this.showAvatarEditor = false;
    }

    public formChanged(){
        return (
            this.user.name != this.form.value.name ||
            this.user.telephone != this.form.value.telephone ||
            this.user.position != this.form.value.position
        );
    }

}
