import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Plugins
import { ApolloAppModule } from './apollo/src/App/apollo.module';
import { ShareTransferAppModule } from './share_transfer/src/App/share-transfer.module';
import { ShareFeedbackAppModule } from './share_feedback/src/App/share-feedback.module';

@NgModule({
    imports: [
        BrowserModule,
        ApolloAppModule,
        ShareTransferAppModule,
        ShareFeedbackAppModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
    ],
    exports:[
        ApolloAppModule,
        ShareTransferAppModule,
        ShareFeedbackAppModule
    ]
})

export class PluginAppModule {}
