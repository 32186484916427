import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';

import * as Objects from '@core/objects'
import * as Search from '@app/models/search';

interface SearchAvailableStatusFilters extends Search.SearchAvailableFilters {
    items: Array<Objects.Status>;
}
@Component({
    selector: 'app-helpers-search-filters-status-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class HelpersSearchFiltersStatusMainComponent implements OnInit{

    @Input()  available: SearchAvailableStatusFilters = null;
    @Input()  applied: Array<Search.SearchAppliedFilter> = [];

    @Output() apply = new EventEmitter<Search.SearchAppliedFilter>();
    
    public status: Objects.Status[] = [];

    ngOnInit(): void {
        this.makeStatus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.applied)
            this.makeStatus();
    }

    private isApplied(status: Objects.Status) : boolean {
        return this.applied.some((f: Search.SearchAppliedFilter) => f.key == this.available.key && f.item.id == status.id);
    }

    private makeStatus() {
        this.status = this.available.items.filter((status: Objects.Status) => !this.isApplied(status));
    }

    onSelect(status: Objects.Status) {
        this.apply.emit({key: this.available.key,item: status});
    }

}
