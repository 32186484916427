import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';



import * as Objects from '@core/objects';
import * as Search from '@app/models/search';
import { DashboardSearchService } from 'app/services/app/dashboard.service';


@Component({
    selector: 'dashboard-search-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class DashboardSearchMainComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    public status: number = 0;
    public appliedFilters: Search.SearchAppliedFilter[] = [];
    public term: string = "";

    private appliedFiltersSubscription: Subscription;
    private termSubscription: Subscription;

	constructor(
        private searchSrv: DashboardSearchService 
    ){}

	ngOnInit(){
        this.appliedFiltersSubscription = this.searchSrv.selectFilters().subscribe((filters: Search.SearchAppliedFilter[]) => this.appliedFilters = filters);
        this.termSubscription = this.searchSrv.selectTerm().subscribe((term: string) => this.term = term);
    }

    ngOnDestroy() {
        this.appliedFiltersSubscription.unsubscribe();
        this.termSubscription.unsubscribe();
    }

    onRemove(filter: Search.SearchAppliedFilter){
        this.searchSrv.removeFilter(filter);
    }

    onSetTerm(term: string) {
        // console.log(" set term to filter... ",term);
        this.searchSrv.setTerm(term);
    }



}
