import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AvatarImgComponent } from './avatar-img/avatar-img.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        AvatarImgComponent,
    ],
    exports: [
        AvatarImgComponent,
    ],

})
export class AvatarModule { }
