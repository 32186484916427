import { Labels, initialState, inputSelector } from './labels.state';
import { LabelsActions, LabelsActionType } from './labels.actions';

import * as Objects from '@core/objects';

export function labelsReducer(state = initialState, action: LabelsActions): Labels {
    switch (action.type) {

        case LabelsActionType.SearchLabels:
            return {...state, input: action.input, labels: action.input.length == 0? null : state.labels}
        
        case LabelsActionType.SearchLabelsSuccess:
            return {...state, labels: action.labels }
        
        case LabelsActionType.SearchLabelsError:
            return {...state, labels: [], error: action.error }
        
        case LabelsActionType.LoadCategoriesSuccess:
            return {...state, categories: action.categories }
        
        case LabelsActionType.LoadCategoriesError:
            return {...state, categories: [], error: action.error }
        
        case LabelsActionType.AddLabelSuccess:
            return {...state, labels: state.labels == null? [action.label] : [...state.labels, ...[action.label]] }

        case LabelsActionType.LoadAccessSuccess:
            return {...state, access: action.access }
            
        case LabelsActionType.Clear:
            return {...state, ...initialState}

        default:
	        return state;
    }
}
