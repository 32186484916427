import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/sections/home/home.component';
// import { SettingsUsersMainComponent } from './components/sections/settings/users/main/main.component';
// import { SettingsLicensesMainComponent } from './components/sections/settings/licenses/main/main.component';
import { SettingsManageUsersComponent } from './components/sections/settings/manage-users/manage-users.component';
import { SettingsGroupsMainComponent } from './components/sections/settings/groups/main/main.component';
import { SettingsAccessTokensMainComponent } from './components/sections/settings/access-tokens/main/main.component';
import { SettingsVersionStatusMainComponent } from './components/sections/settings/version-status/main/main.component';
import { SettingsLabelsGroupsMainComponent } from './components/sections/settings/labels-groups/main/main.component';
import { SettingsRulesMainComponent } from './components/sections/settings/rules/main/main.component';
import { SettingsRulesMainEditComponent } from './components/sections/settings/rules/main-edit/main-edit.component';

import { SettingsWorkflowsMainComponent } from './components/sections/settings/workflows/main/main.component';
import { SettingsWorkflowsMainEditComponent } from './components/sections/settings/workflows/main-edit/main-edit.component';
import { SettingsMainComponent } from './components/sections/settings/settings-main/main/main.component';
import { ProfileMainComponent } from './components/sections/profile/main/main.component';

import { LegacyNotesRequestComponent } from './components/sections/legacy-notes/request/request.component';

import { LayoutErrorNotFoundComponent } from '@core/components/layout/errors/not-found/not-found.component';
import { ErrorsFullScreenComponent } from '@core/components/layout/errors/errors-full-screen/errors-full-screen.component';

import { AppProjectsRouterModule } from './routes/projects-router.module';
import { AppLibraryRouterModule } from './routes/library-router.module';
import { AppVersionsRouterModule } from './routes/versions-router.module';
import { AppDashboardRouterModule } from './routes/dashboard-router.module';

export const routes: Routes = [
    // {
    //     path: '',
    //     redirectTo: '/p/apollo/upload',
    //     pathMatch: 'full'
    //     //component: LayoutErrorNotFoundComponent
    // },
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'requests/:request/files/:file/notes',
        component: LegacyNotesRequestComponent
    },
    {
        path: 'profile',
        component: ProfileMainComponent
    },
    {
        path: 'settings',
        component: SettingsMainComponent
    },
    {
        path: 'settings/users',
        component: SettingsManageUsersComponent
    },
    {
        path: 'settings/groups',
        component: SettingsGroupsMainComponent
    },
    {
        path: 'settings/access-tokens',
        component: SettingsAccessTokensMainComponent
    },
    {
        path: 'settings/version-status',
        component: SettingsVersionStatusMainComponent
    },
    {
        path: 'settings/categories',
        component: SettingsLabelsGroupsMainComponent
    },
    {
        path: 'settings/rules',
        component: SettingsRulesMainComponent
    },
    {
        path: 'settings/rules/add',
        component: SettingsRulesMainEditComponent
    },
    {
        path: 'settings/rules/:id',
        component: SettingsRulesMainEditComponent
    },
    {
        path: 'settings/workflows',
        component: SettingsWorkflowsMainComponent
    },
    {
        path: 'settings/workflows/:id',
        component: SettingsWorkflowsMainEditComponent
    },

    // {
    //     path: 'settings/licenses',
    //     component: SettingsLicensesMainComponent
    // },
    {
        path: 'error/:key',
        component: ErrorsFullScreenComponent
    },
    {
        path: 'p',
        //component: PluginComponent,
        //children: pluginRoutes,
        // canActivate: [ AccessPluginsService ]
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: LayoutErrorNotFoundComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        AppProjectsRouterModule,
        AppLibraryRouterModule,
        AppVersionsRouterModule,
        AppDashboardRouterModule

    ],
    exports: [RouterModule]
})
export class AppRouterModule { }
