import { Component } from '@angular/core';

@Component({
    selector: 'core-helpers-box-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class HelperBoxSuccessComponent {


}
