import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


import { VersionStatusService } from '../../../../../services/settings/version-status.service';
import { Subscription } from 'rxjs';


import { Status } from '@core/objects/status';

@Component({
    selector: 'settings-version-status-add-form',
    templateUrl: './add-form.component.html',
    styleUrls: ['./add-form.component.scss']
})
export class SettingsVersionStatusAddFormComponent implements OnInit{

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

    @Output() cancel = new EventEmitter<any>();
    @Output() submit = new EventEmitter<any>();

    public status: Number = 0;

    public form: FormGroup;

	constructor(public versionStatusSrv: VersionStatusService, private formBuilder: FormBuilder){}

	ngOnInit(){
		this.init();

	}

    init(){
        this.form = this.formBuilder.group({
            name: ['', [Validators.required]]
		});
    }

	onCancel(event) {
        console.log(" cancel triggered")
        this.cancel.emit(event);
        this.cleanForm();
	}

    add(){
        if(this.form.invalid)
			return;

        this.versionStatusSrv.addVersionStatus$(this.form.value.name).subscribe((data: Status) => {
            console.debug(" post: new status added",data);
            this.cleanForm();
            this.submit.emit()
        });

    }

    cleanForm(){
		this.form.reset({name: ''});
	}

}
