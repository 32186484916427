
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { VersionListItemMenuClick } from '@core/components/versions/list/version-list-item-menu-click';

import { AppRouterService } from 'app/services/app/router.service';
import { AppService } from 'app/services/app/app.service';
import { VersionsService } from 'app/services/app/versions.service';

//TODO a interface to 
@Component({
    selector: 'project-details-logbook-properties-shares-event',
    templateUrl: './shares.component.html',
    styleUrls: ['./shares.component.scss']
})
export class ProjectDetailsLogbookSharesEventPropertiesComponent implements OnInit{
    protected readonly ACCESS_REQUEST_FEEDBACK = 'share_feedback';

    public readonly FILES_SENT = 'versions.messages.removed';
    public readonly FEEDBACK_REQUEST = 'share.feedbacks.entries.added';



	@Input() event: Objects.Event;

    @Input() properties: any = null;
    
    public messagePrefixUrl: string = '';

    // feedbacks details
    public show: boolean = false;
    public feedbackPluginAccess: boolean = false;
    public preText: string = '';
    // TODO create object/model for event properties depending on the kind of the event should be perfect
    public shareApproval: any;
    /** structure example for options
     *
     * {
               "id":2104,
               "key":"options",
               "value":{
                  "allow_see":true,
                  "get_approval":true,
                  "get_comments":true,
                  "get_signature":true
               },
               "share":null
            }
     */
    /** structure example for email
     * {
               "id":2102,
               "key":"email",
               "value":{
                  "subject":"lorem Ipsum....",
                  "message":"Please see files for you to review."
               },
               "share":null
            },
     */

    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "See Artwork Details",
            key: "details",
            icon: "see-details"
        }
    ];

    protected subscriptor: Subscription;

    constructor(private appRouter: AppRouterService,private app: AppService,private versionsSrv: VersionsService){}
    
    ngOnInit(): void {
        if(this.event != null){
            this.init();
        }
    }
    private init() : void {
        // check Approval/Signature/Comments/Allow see
        if(this.event.type == this.FEEDBACK_REQUEST){
            this.shareApproval = this.properties.share_entry.share;
            let options = [];
            options.push(this.getOption("get_approval") ? 'Approval' : ''); 
            options.push(this.getOption("get_comments") ? 'Comments' : ''); 
            options.push(this.getOption("get_signature") ? 'Signature' : ''); 
            options.push(this.getOption("allow_see") ? 'Allow see' : '');
            // set preText with all options requested
            this.preText = options.filter((o: string) => o!= '').join('/') + " requested from ";
            // get plugins access
            this.subscriptor = this.app.getSession().subscribe( (session: any) => {
                this.feedbackPluginAccess = session.options.plugins.some((pa) => pa == this.ACCESS_REQUEST_FEEDBACK);
            });
        }

        
    }

    onClickPreview(file: Objects.File){
        if (file != null && this.properties.version != null){
            this.appRouter.navigate('/versions/'+this.properties.version.id + "/files/"+file.id);
        }        
    }

    onMenuClick(menu: VersionListItemMenuClick) {
        switch(menu.key) {
            case "details":
                // url to go to Artwork Details
                this.appRouter.navigate("/versions/"+this.properties.version.id);
                break;
        }
    }

    toggleArtworks(){
        this.show = !this.show;
    }

    private getOption(key: string ) : boolean {
        let options =  this.shareApproval.properties.filter((op) => op.key == 'options')[0];
        return options.value[key];
    }

    private getEmail(key: string ) : boolean {
        let email =  this.shareApproval.properties.filter((op) => op.key == 'email')[0];
        return email.value[key];
    }


}