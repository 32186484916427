import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';
import * as Search from '@app/models/search';

// import { Request } from '@core/objects/request';
// import { Status } from '@core/objects/status';
// import { User } from '@core/objects/user';
// import { File } from '@core/objects/file';

import * as fromState from '@app/store/state/library/library.state';
import * as fromActions from '@app/store/state/library/library.actions';

// export interface ProjectItem extends fromProjectsState.ProjectLoaded {};

@Injectable({
    providedIn: 'root'
})
export class LibraryFiltersService {

    constructor(private url : UrlService, private store: Store<fromState.Library>, private actions$: Actions,  protected http: HttpClient) {}

    load = (query: string) => this.store.dispatch( new fromActions.LoadFilters());

    // selectError = (key: string): Observable<HttpErrorResponse> => this.store.select(fromProjectsState.errorSelector(key));

    selectLabels = ():  Observable<Search.SearchAvailableFilters> => this.store.select(fromState.availableFilterLabelsSelector);

    selectStatus = ():  Observable<Search.SearchAvailableFilters> => this.store.select(fromState.availableFilterStatusSelector);

    // selectOwners = ():  Observable<Search.SearchAvailableFilters> => this.store.select(fromState.availableFilterOwnersSelector);
}

@Injectable({
    providedIn: 'root'
})
export class LibrarySearchService {

    constructor(private url : UrlService, private store: Store<fromState.Library>, private actions$: Actions,  protected http: HttpClient) {}

    applyFilter = (filter: Search.SearchAppliedFilter) => this.store.dispatch( new fromActions.ApplySearchFilter(filter));

    removeFilter = (filter: Search.SearchAppliedFilter) => this.store.dispatch( new fromActions.RemoveSearchFilter(filter));

    setTerm = (term: string) => this.store.dispatch( new fromActions.SetSearchTerm(term));

    selectFilters = () :  Observable<Search.SearchAppliedFilter[]> => this.store.select(fromState.searchFiltersSelector);

    selectTerm = () :  Observable<string> => this.store.select(fromState.searchTermSelector);

    // selectQuery = () :  Observable<string> => this.store.select(fromState.searchQuerySelector);
}

@Injectable({
    providedIn: 'root'
})
export class LibraryService {

    constructor(private store: Store<fromState.Library>, private actions$: Actions,  protected http: HttpClient) {}

    init = (params: Search.SearchQueryParams) => this.store.dispatch( new fromActions.Init(params));

    loadResults = () => this.store.dispatch( new fromActions.LoadResults());

    selectResults = () : Observable<Objects.Version[]> => this.store.select(fromState.resultItemsSelector);

    // selectFinal = () : Observable<boolean> => this.store.select(fromState.resultFinalSelector);
    selectResultStatus = () : Observable<number> => this.store.select(fromState.resultStatusSelector);

    selectQueryParams = () :  Observable<Search.SearchQueryParams> => this.store.select(fromState.queryParamsSelector);

    updateVersion = (vid: number | string) => this.store.dispatch( new fromActions.UpdateVersion(+vid));

    reset = () => this.store.dispatch(new fromActions.Clear());
}

// @Injectable({
//     providedIn: 'root'
// })
// export class ProjectsEditorService {

//     constructor(private store: Store<fromProjectsState.Projects>, private actions$: Actions) {}

//     // loadAssigners = () => this.store.dispatch( new fromProjectsActions.LoadEditorAssigners());

//     selectAssigners = (): Observable<Objects.User[]> => this.store.select(fromProjectsState.editorAssignersSelector);

//     selectAccess = (key: string): Observable<boolean> => this.store.select(fromProjectsState.editorAccessSelector(key));

//     selectAccessAll = () : Observable<Access.AccessProject> => this.store.select(fromProjectsState.editorAccessAllSelector);

//     selectWorkflowTransitionsByOrigin = () : Observable<Objects.WorkflowTransition[]> => this.store.select(fromProjectsState.editorWorkflowTransitionsSelector);

// }
