import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
/** this pipe order by prperty 'active', order by default is 'desc':
    the active items first (active property = true)
    and after the inactive (active property = false)
usages:  | sortByActive         -->active first
        | sortByActive:'asc'   --> inactive first
        | sortByActive:'desc'  -->active first
 */
@Pipe({ name: "sortByActive" })
export class SortByActivePipe implements PipeTransform {
    transform(elements: any[], order = 'desc'): any[] {
    	if (!elements || order === '') { return elements; } // no array
		if (elements.length <= 1) { return elements; } // array with only one item
        return _.orderBy(elements, [element => element.active], [order]);
  	}
}
