import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { VersionFeedbacksMainComponent } from './main/main.component';
import { VersionFeedbacksItemComponent} from './item/item.component';

@NgModule({
    imports: [
        CommonModule,
        HelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionFeedbacksMainComponent,
        VersionFeedbacksItemComponent
    ],
    exports: [
        VersionFeedbacksMainComponent,
    ],

})
export class VersionFeedbacksModule { }


