//import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
//import { Routes, RouterModule } from '@angular/router';

import { MenuItem } from '@core/models/menu-item.model';

export const apolloMenu : MenuItem[] = [
    {
        title: 'Upload',
        link: '/p/apollo/upload',
        disabled: false
    },
    {
         title: 'Compare texts',
         link: '/p/apollo/compare-content',
         disabled: true
    },
    {
        title: 'Compare graphics',
        link: '/p/apollo/compare-graphics',
        disabled: true
    },
    // {
    //     title: 'Preflight check',
    //     link: '/p/apollo/check',
    //     disabled: true
    // },
    {
        title: 'History',
        link: '/p/apollo/history',
        disabled: false
    }
];
