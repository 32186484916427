export class ApolloGraphicLayer {
    key: string;
    id_file: any;
    opacity: number = 80;
    visible: boolean = true;
    registered: boolean = false;
    url: string = null;

    constructor(key: string) {
        this.key = key;
    }
};
