import { Component, Input } from '@angular/core';

@Component({
    selector: 'core-helpers-box-main-instructions',
    templateUrl: './main-instructions.component.html',
    styleUrls: ['./main-instructions.component.scss']
})
export class HelperBoxStatusMainInsctructionsComponent {

    @Input() title: string;
    @Input() description: string;

    @Input() steps:Object;

    public show:boolean = false;
}
