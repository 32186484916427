import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { Version } from '@core/objects/version';
import { VersionFeedback } from '@core/models/version-feedback';

import { User } from '@core/objects/user';
// import { Request } from '@core/objects/request';
// import { Label } from '@core/objects/label';
// import { Related } from '@core/objects/related';

@Component({
    selector: 'plugin-share-feedback-core-version-approval-response-list',
    templateUrl: './version-approval-response-list.component.html',
    styleUrls: ['./version-approval-response-list.component.scss']
})
export class PluginShareFeedbackCoreVersionsApprovalResponseListComponent implements OnInit, OnChanges {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_FOUND = 3;

    public readonly APPROVAL_PENDING = 0;
    public readonly APPROVAL_APPROVED = 1;
    public readonly APPROVAL_REJECTED = 2;

    @Input() version: Version = null;
    @Input() prefixUrl: string = '';

    public approvals: Array<any> = [];
    public status: number = this.STATUS_LOADING;


    constructor(private httpClient: HttpClient, private urlSrv: UrlService ) { }

    ngOnInit() {
        this.loadApprovalsList();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.version)
            this.loadApprovalsList();
    }


    protected loadApprovalsList() {
        this.httpClient.get(this.urlSrv.build(this.prefixUrl+"/versions/"+this.version.id+"/approvals")).subscribe(
            (data:any) => {
                this.approvals = data;
                console.debug(" additional approvals list ",this.approvals);
                this.status = this.STATUS_DONE;
            },
            (error:any) => {
                this.status = this.STATUS_NOT_FOUND;
            }
        );
    }


}
