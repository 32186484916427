import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';

@Component({
    selector: 'core-file-download-three-action-list',
    templateUrl: './file-download-three-action-list.component.html',
    styleUrls: ['./file-download-three-action-list.component.scss']
})
export class FileDownloadThreeActionListComponent implements OnInit, OnChanges {

    @Input() files: Array<File>;
    @Input() markedFile: File = null;
    @Input() actionOneText: string = "";
    @Input() actionOneStyle: string = "";
    @Input() actionTwoText: string = "";
    @Input() actionTwoStyle: string = "";
    @Input() actionThreeText: string = "";
    @Input() actionThreeStyle: string = "";
    @Input() bubbles: boolean = false;
    @Input() bubbleTexts: Array<string> = [];

    @Output() actionOneClick = new EventEmitter<File>();
    @Output() actionTwoClick = new EventEmitter<File>();
    @Output() actionThreeClick = new EventEmitter<File>();
    @Output() bubbleClick = new EventEmitter<any>();

    public markedItem: File = null;

    constructor() { }

    ngOnInit() {
        this.markedItem = this.markedFile;
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.markedFile){
            console.debug("%%% marked file triggered changes in 3 list",this.markedFile);
            this.markedItem = this.markedFile;
        }
    }

    onActionOneClick(file: File) {
        // console.debug(" core-file-download-three-action-list onActiononeClick ",file);
        this.actionOneClick.emit(file)
    }

    onActionTwoClick(file: File) {
        // console.debug(" core-file-download-three-action-list onActiontwoClick ",file);
        this.actionTwoClick.emit(file)
    }
    onActionThreeClick(file: File) {
        // console.debug(" core-file-download-three-action-list onActionthreeClick ",file);
        this.actionThreeClick.emit(file)
    }
    onBubbleClick(file: File) {
        // console.debug(" core-file-download-three-action-list onBubbleClick ",file);
        this.bubbleClick.emit(file)
    }

    getBubbleText(index: number) : string {
        if(this.bubbleTexts.length > index)
            return this.bubbleTexts[index];
        
        return "";
    }

    isMarked(file: File){
        if (this.markedItem == null)
            return false;
        return this.markedItem.id == file.id;
    }
}
