import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelsModule } from '@core/components/labels/labels.module';
import { LayoutTitlesModule } from '@core/components/layout/titles/titles.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { AppHelperLabelsModule } from '@app/components/helpers/labels/labels.module';
import { ProjectsDetailsInfoFeaturesModule } from '../info/features/features.module';
import { ProjectsDetailsHelpersModule } from '../helpers/helpers.module';

import { ProjectDetailsForbiddenHeaderMainComponent } from './main/main.component';
import { ProjectDetailsForbiddenHeaderFeaturesComponent } from './features/features.component';

@NgModule({
    imports: [
        CommonModule,
        // LayoutTitlesModule,
        // LabelsModule,
        HelpersModule,
        AppHelperLabelsModule,
        ProjectsDetailsInfoFeaturesModule,
        ProjectsDetailsHelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsForbiddenHeaderMainComponent,
        ProjectDetailsForbiddenHeaderFeaturesComponent
    ],
    exports: [
        ProjectDetailsForbiddenHeaderMainComponent
    ],

})
export class ProjectsDetailsForbiddenHeaderModule { }