import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';

import { TitleService } from '@core/services/title.service';

import { ApolloService } from '../../../../services/apollo/apollo.service';
import { ApolloItem } from '../../../../model/apollo-item.model';

import { ApolloRequest } from '../../../../model/apollo-request.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'plugin-apollo-main-section-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class PluginApolloMainSectionUploadComponent implements OnInit, OnDestroy {

    readonly GRAPHIC_COMPARE_MENU = "Compare graphics";
    readonly CONTENT_COMPARE_MENU = "Compare texts";

    public show: boolean;
    public showComparison:boolean = false;
    protected requestStore: ApolloRequest;

    public referenceItem: ApolloItem = null;
    public targetItem: ApolloItem = null;

    public buttons: any = { enabled: false,
                            graphic: false,
                            content: false};

    // Subscriptions
    public subscriptionMenu: Subscription;
    protected subscriptionRequest: Subscription;
    public subscriptionTarget: Subscription;
    public subscriptionReference: Subscription;
    public subscriptionRoutes: Subscription;

    // load by hash
    public hashLoad: any = {
        hash: null,
        loading: true,
        referenceLoaded: false,
        targetLoaded: false
    };

    constructor(public apolloSrv: ApolloService, private activatedRoute: ActivatedRoute, private title: TitleService){
        // loading by hash
        // let hash = this.activatedRoute.snapshot.paramMap.get('hash');
        // if(hash) {
        //     this.hashLoad = {
        //         ...this.hashLoad,
        //         hash: hash,
        //         loading: true,
        //         referenceLoaded: false,
        //         targetLoaded: false
        //     };
        //     console.debug("hash load--->", this.hashLoad);
        //
        //     this.apolloSrv.loadSavedRequest(hash);
        // }
    }

    ngOnInit() {
        this.title.set("Select files - XRay");
        this.subscriptionRequest = this.apolloSrv.selectRequest().subscribe((request: ApolloRequest) => {
            //load files when load by hash
            if(this.hashLoad.hash === request.hash && this.hashLoad.loading == true) {
                //this.referenceItem = request.reference;
                //this.targetItem = request.target;
                this.apolloSrv.loadFile('reference', request.reference.id_file);
                this.apolloSrv.loadFile('target', request.target.id_file);
                //this.hashLoad.loading == false;
            }

        });

        this.subscriptionReference = this.apolloSrv.getItemReference().subscribe( reference => {
            this.referenceItem = reference;
            setTimeout(() => this.controlItems(), 10);
            this.hashLoad.referenceLoaded = true;
            //this.controlItems();
            this.hashLoadFinished();
        });

        this.subscriptionTarget = this.apolloSrv.getItemTarget().subscribe( target => {
            this.targetItem = target;
            setTimeout(() => this.controlItems(), 10);
            this.hashLoad.targetLoaded = true;
            this.hashLoadFinished();
            //this.controlItems();
        });

        this.subscriptionMenu = this.apolloSrv.getMenu().subscribe( menu => {
            let compareGraphicLinks = menu.filter( i => { return i.title == this.GRAPHIC_COMPARE_MENU; })
            let compareContentLinks = menu.filter( i => { return i.title == this.CONTENT_COMPARE_MENU; })

            this.buttons.graphic = !compareGraphicLinks[0].disabled;
            this.buttons.content = !compareContentLinks[0].disabled;
        });

        // load query params
        this.subscriptionRoutes = this.activatedRoute.queryParams.subscribe(params => {
            if (params.reference) {
                //console.log('referenceeeee -----> ', params.reference);
                this.apolloSrv.loadFile('reference', params.reference);
            }
            if (params.target) {
                //console.log('targeeeeeet -----> ', params.target);
                this.apolloSrv.loadFile('target', params.target);
            }
        });

        // loading by hash
        let hash = this.activatedRoute.snapshot.paramMap.get('hash');
        if(hash) {
            this.hashLoad = {
                ...this.hashLoad,
                hash: hash,
                loading: true,
                referenceLoaded: false,
                targetLoaded: false
            };
            console.debug("hash load--->", this.hashLoad);

            this.apolloSrv.loadSavedRequest(hash);
        } else
        {
            this.hashLoad = {
                ...this.hashLoad,
                loading: false
            }
        }
    }

    ngOnDestroy(){
        this.subscriptionMenu.unsubscribe();
        //this.subscriptionRequest.unsubscribe();
        this.subscriptionReference.unsubscribe();
        this.subscriptionTarget.unsubscribe();
        //this.intervalSubs.unsubscribe();
    }

    private hashLoadFinished() {
        this.hashLoad.loading = !(this.hashLoad.referenceLoaded && this.hashLoad.targetLoaded)
    }

    controlItems(){
        if(this.targetItem != null
            && this.targetItem.filters.length > 0
            && this.referenceItem != null
            && this.referenceItem.filters.length > 0){
            this.buttons.enabled = true;

            // this.apolloSrv.activeMenuItem(this.GRAPHIC_COMPARE_MENU);
            if(!this.selectedPagesAreNotIgnored())
                this.apolloSrv.deactiveMenuItem(this.GRAPHIC_COMPARE_MENU);
            else
                this.apolloSrv.activeMenuItem(this.GRAPHIC_COMPARE_MENU);

            this.apolloSrv.activeMenuItem(this.CONTENT_COMPARE_MENU);

        } else {
            this.buttons.enabled = false;
            //this.apolloSrv.deactiveMenuItem(this.GRAPHIC_COMPARE_MENU);
            //this.apolloSrv.deactiveMenuItem(this.CONTENT_COMPARE_MENU);
        }
    }

    // check if selected pages are not ignored
    protected selectedPagesAreNotIgnored() {
        return !this.targetItem.filters.filter(f => f.type == "ignore" && f.options==true).map(f=>f.page).includes(Number(this.targetItem.page_selected)) &&
            !this.referenceItem.filters.filter(f => f.type == "ignore" && f.options==true).map(f=>f.page).includes(Number(this.referenceItem.page_selected));
    }

    // itemRemove(event){
    //     let newRequest = new ApolloRequest;
    //     newRequest.reference = this.requestStore.reference;
    //     newRequest.target = this.requestStore.target;
    //     if(this.requestStore.hash && event === true){
    //         this.apolloSrv.setStoreRequest(newRequest)
    //     }
    // }

    // changedPage(event) {
    //     if (this.requestStore.apollo_graphic != null) {
    //         let newRequest = this.requestStore;
    //         newRequest.apollo_graphic = null
    //         this.apolloSrv.setStoreRequest(newRequest);
    //     }
    // }

}
