import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PluginShareFeedbackMainAppComponent } from './components/main/main.component';
import { PluginShareFeedbackReviewMainAppComponent } from './components/main-review/main-review.component';

export const shareFeedbackAppRoutes : Routes = [
    {
        path: 'p/feedback/:request',
        // component: PluginApolloUnoComponent
        component: PluginShareFeedbackMainAppComponent,

    },
    {
      //
      path: 'p/feedback/versions/:version/users/:user',
      component: PluginShareFeedbackReviewMainAppComponent,
    }
];

@NgModule({
  imports: [RouterModule.forChild(shareFeedbackAppRoutes)],
  exports: [RouterModule]
})
export class ShareFeedbackAppRouterModule { }
