import { Versions, initialState, viewSelector} from './versions.state';
import { VersionsActions, VersionsActionType } from './versions.actions';

import * as Objects from '@core/objects';
import { version } from 'process';

export function versionsReducer(state = initialState, action: VersionsActions): Versions {
    switch (action.type) {
		// EVENTS STATUS
        case VersionsActionType.Clear:
            return {...state, view: initialState.view, errors: initialState.errors};
    
        case VersionsActionType.ClearFiles:
            return {...state, view: {...state.view, files: initialState.view.files}};

        /**
         * Load views
         */
        case VersionsActionType.LoadVersionSuccess:
            return {...state, view: {...state.view, version: action.version, id: action.version.id }, errors: {...state.errors, load_version: null }};

        case VersionsActionType.LoadVersionError:
            return {...state, view: {...state.view, version: null}, errors: {...state.errors, load_version: action.error }};
        case VersionsActionType.LoadVersionHeaderSuccess:
            return {...state, view: {...state.view, 
                                        id: action.version.id, 
                                        version : action.version }, errors: {...state.errors, load_header: null }};
        case VersionsActionType.LoadVersionHeaderError:
            return {...state, view: {...state.view, version: null}, errors: {...state.errors, load_version: action.error, load_header: action.error }};
                                           
        case VersionsActionType.LoadVersionFilesSuccess:
            return {...state, view: {...state.view, files: action.files}, errors: {...state.errors, load_version_files: null }};
        
        case VersionsActionType.LoadVersionFilesError:
            return {...state, view: {...state.view, files: []}, errors: {...state.errors, load_version_files: action.error }};
        
        case VersionsActionType.LoadRelatedVersionsSuccess:
            return {...state, view: {...state.view, relateds: action.versions }, errors: {...state.errors, load_version: null }};

        case VersionsActionType.LoadRelatedVersionsError:
            return {...state, view: {...state.view, relateds: []}, errors: {...state.errors, load_version: action.error }};

        case VersionsActionType.LoadVersionProjectSuccess:
            return {...state, view: {...state.view, project: action.project}, errors: {...state.errors, load_version_project: null }};
        
        case VersionsActionType.LoadVersionProjectError:
            return {...state, view: {...state.view, project: null}, errors: {...state.errors, load_version_project: action.error }};

        case VersionsActionType.LoadVersionMessagesSuccess:
            return {...state, view: {...state.view, messages: action.messages}, errors: {...state.errors, load_version_messages: null }};

        case VersionsActionType.LoadVersionMessagesError:
            return {...state, errors: {...state.errors, load_version_messages: action.error }};
        
        case VersionsActionType.LoadVersionCommentsSuccess:
            return {...state, view: {...state.view, comments: action.messages}, errors: {...state.errors, load_version_comments: null }};

        case VersionsActionType.LoadVersionCommentsError:
            return {...state, errors: {...state.errors, load_version_comments: action.error }};
        
        /**
         * Setters
         */
        case VersionsActionType.SetVersionStatus:
            return {...state, view: {...state.view, version: {...state.view.version, status: action.status}}, errors: {...state.errors, set_version_status: null }};

        case VersionsActionType.SetVersionStatusSuccess:
            return {...state, errors: {...state.errors, set_version_status: null }};
    
        case VersionsActionType.SetVersionStatusError:
            return {...state, errors: {...state.errors, set_version_status: action.error }};

        case VersionsActionType.SetVersionLabels:
            return {...state, view: {...state.view, version: {...state.view.version, labels: action.labels}}, errors: {...state.errors, set_version_status: null }}
        
        case VersionsActionType.SetVersionLabelsSuccess:
            return {...state, errors: {...state.errors, set_version_labels: null}}
        
        case VersionsActionType.SetVersionLabelsError:
            return {...state, errors: {...state.errors, set_version_labels: action.error}}
        /**
         * Load editors
         */
        case VersionsActionType.LoadEditorAccessSuccess:
            return {...state, editor: {...state.editor, access: action.access}, errors: {...state.errors, load_editor_access: null}};
        
        case VersionsActionType.LoadEditorAccessError:
            return {...state, errors: {...state.errors, load_editor_access: action.error}};

	    default:
	        return state;
    }
}
