import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
/** this pipe order by string 'orderedField', 
 * 	the string represents is the property of the object to order by 
 *  and by default the order is 'asc'.
 *  before the property name we could optionally arrives with a '-' char at the beginning
 *  this means we have to order 'desc'
 *  usages:  | projectsSortByField: 'status'		-->order by property called status.name 'asc'
 *           | projectsSortByField: '-id'		-->order by property called id 'desc'
 *         	 | projectsSortByField: 'date'		-->order by property called date 'asc'
 *         	 | projectsSortByField: '-origin_version'	-->order by property called origin_version.version 'desc'
 * 
 *  to get subproperty field in project: 
 * 		we have to use getColumn to know the field we are ordering each object by
 **/

// TODO: manage when column is an OBJECT, maybe set a new property subfield. 

@Pipe({ name: 'projectsSortByField' })
export class ProjectsSortByFieldPipe implements PipeTransform {
  	transform(values: any[], orderedField: string,): any[] {
		let order = 'asc';
		let column = this.getColumn(orderedField);
		if( orderedField[0] == '-'){
			order = 'desc';
			column =  this.getColumn(orderedField.substring(1));
		}

		
    	if (!values || !column || column === '' || order === '') { return values; } // no array
    	if (values.length <= 1) { return values; } // array with only one item
		// separate nulls to GO TO END of the list
		let partition = _.partition(values, (x) => !!_.get(x, column, null));
		// console.log("======> ORDERING property: "+orderedField+"  "+column,partition)
		// console.log("======> ORDERING property: "+orderedField,value[0][column])
		return _.concat(_.orderBy(partition[0], [column], ([order])), partition[1]);
  	}
	
	// each object has its ordering property inside a property object or it is the property value as it is
	getColumn(property: string){
		switch (property) {
			case 'origin_version':
				return property+'.version';
				break;
			case 'user_handler':
			case 'user_assigned': // maybe finally is user_handler
				return 'user_assigned.name';
				break;
			case 'status':
				return property+'.name';
				break;
			case 'label':
				return property+'s.name'; // property is plural
				break;
			// id, due_date as itself
			default:
				return property;
				break;
		}
	}
}