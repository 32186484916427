import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
// import * as Access from '@app/models/access';
import * as Search from '@app/models/search';

@Injectable({
    providedIn: 'root'
})
export class DashboardRequests {
    constructor(private http: HttpClient, private urlSrv : UrlService) {}

    workflows$ = () : Observable<Objects.Workflow[]> =>
        this.http.get<Objects.Workflow[]>(this.urlSrv.build("/workflows"));

    filterStatus$ = (workflow: Objects.Workflow) : Observable<Objects.Status[]> =>
        this.http.get<Objects.Status[]>(this.urlSrv.build("/requests/status"), {params: {workflow: workflow.id.toString()}});

    filterLabels$ = () : Observable<Objects.Label[]> =>
        this.http.get<Objects.Label[]>(this.urlSrv.build("/labels"));
    
    filterUsers$ = () : Observable<Objects.User[]> =>
        this.http.get<Objects.User[]>(this.urlSrv.build("/users"));

    results$ = (params: Search.SearchQueryParams, page: number, limit: number, workflow: number | string, rid: string | number | null) : Observable<Objects.ExtendedRequest[]> => {
        let query = {...params, page: page.toString(), limit: limit.toString(), workflow: workflow.toString()};
        if(rid != null) {
            query['q'] = query.hasOwnProperty('q')? query['q'] + "+id:" + rid.toString() : "id:" + rid.toString();
            query['page'] = "1";
        }
        
        let p = new HttpParams();
        Object.keys(query).forEach(key => p = p.set(key, query[key]) );

        return this.http.get<Objects.ExtendedRequest[]>(this.urlSrv.build("/requests"), { params: p});
    }
}