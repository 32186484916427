import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UrlService } from '@core/services/url/url.service';
import { Subscription } from 'rxjs';

// import { TitleService } from '@core/services/title.service';

import * as Objects from '@core/objects';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';


@Component({
    selector: 'core-versions-detail-preview-file',
    templateUrl: './detail-preview-file.component.html',
    styleUrls: ['./detail-preview-file.component.scss']
})
export class VersionsDetailPreviewFileComponent implements OnInit, OnDestroy{


    @Input() version: Objects.Version = null;
    @Input() file: Objects.File = null;
    @Input() menu: Array<ThreeDotsMenuItem> = [];
    @Output() menuClick = new EventEmitter<FilesListItemMenuClick>();

    public notesUrlPrefix: string = '';
    public canCreateNotes: boolean = true;
    public context: string = '';

    protected urlSubscriptor: Subscription;

    public breadcrumbs: any = [
        {
            title: "",
            link: ['']
        }
    ];


    constructor (
        private activeRoute: ActivatedRoute,
        private urlSrv: UrlService){}

    ngOnInit(): void {
        this.urlSubscriptor = this.activeRoute.queryParams.subscribe((params: any) => {
            if (params.context) {
                this.context = params.context;
            }

        });


    }

    ngOnDestroy() {
        this.urlSubscriptor.unsubscribe();
    }

    onMenuClick(menu: FilesListItemMenuClick) {
        this.menuClick.emit(menu);
    }


}
