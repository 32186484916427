import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppHelpersSearchFiltersLabelsModule } from './labels/labels.module';

import { HelpersSearchFiltersMainComponent } from './menu/menu.component';
import { AppHelpersSearchFiltersStatusModule } from './status/status.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { AppHelpersSearchFiltersWorkflowStatusModule } from './workflow-status/workflow-status.module';
import { HelpersSearchFiltersUsersMainComponent } from './users/main.component';

@NgModule({
    imports: [
        CommonModule,
        AppHelpersSearchFiltersLabelsModule,
        AppHelpersSearchFiltersStatusModule,
        AppHelpersSearchFiltersWorkflowStatusModule,
        PipesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        HelpersSearchFiltersMainComponent,
        HelpersSearchFiltersUsersMainComponent
    ],
    exports: [
        HelpersSearchFiltersMainComponent,
        HelpersSearchFiltersUsersMainComponent,
        AppHelpersSearchFiltersLabelsModule,
        AppHelpersSearchFiltersStatusModule,
        AppHelpersSearchFiltersWorkflowStatusModule
    ],

})
export class AppHelpersSearchFiltersModule { }