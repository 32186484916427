import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core'
import {HttpParams} from "@angular/common/http";
import { Router } from "@angular/router";

import { Subscription } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';
import { PreviewsService } from '@core/services/previews/previews.service';

import { ApolloContentComparison } from '../../../model/apollo-content-comparison.model';
import { ApolloService } from '../../../services/apollo/apollo.service';
import { ApolloRequests } from '../../../services/apollo/apollo-requests.service';
import { ApolloRequest } from '../../../model/apollo-request.model';
import { ApolloFilter } from '../../../model/apollo-filter.model';
import { ApolloContentLupa } from '../../../model/apollo-content-lupa.model';

//import { ApolloContentComparisonResult } from '../../../model/apollo-content-compared-result.model';
import { ApolloContentInspector, ApolloContentInspectorChange  } from '../../../model/apollo-content-inspector.model';
import { ApolloContentComparisonResultMark } from '../../../model/apollo-content-compared-result-mark.model';
import { methodOptions } from '../../../data/method';

import { ApolloContentApprovals  } from '../../../model/apollo-content-approvals.model';

@Component({
    selector: 'plugin-apollo-helpers-content-compared',
    templateUrl: './content-compared.component.html',
    styleUrls: ['./content-compared.component.scss']
})
export class PluginApolloHelpersContentComparedComponent implements OnInit, OnDestroy {

    @ViewChild('inspector', { read: ElementRef }) public inspector: ElementRef<any>;

    public loading: boolean = true; //Loading component
    public loadingCompare: boolean = true;

    public showInspector:boolean = false;
    //public showDetails:boolean = false;     not see details
    public showChanges:boolean = false;
    public openListChanges:boolean = false;

    public showFilterList:boolean = false;

    public subscriptionContent: Subscription = null;
    public subscriptionFile: Subscription;
    public subscriptionRequest: Subscription;

    public apolloContent: ApolloContentComparison;
    public request: ApolloRequest;

    public result : ApolloContentInspector = null;
    public inspectorCtrl : ApolloContentInspector = null;

    public approvalsCtrl : ApolloContentApprovals = null;

    public file:any = null;
    public preview: any;
    public preview$ : Subscription;

    ///public storeProperty:string = 'reference';
    public storeProperty:string = 'target';

    public page:number;
    protected refPage: number = 1;

    public imgLoaded: boolean = false;

    public size: any = {};

    // marks
    public marks: Array< ApolloContentComparisonResultMark> = [];
    public pageMarks: Array< ApolloContentComparisonResultMark> = [];
    public selectedMark: ApolloContentComparisonResultMark;
    public changes: Array<any> = [];

    // filters
    //public refFilters: Array<ApolloFilter> = [];
    //public tarFilters: Array<ApolloFilter> = [];

    public targetUrl: string = null;
    public referenceUrl: string = null;

    public lupa: ApolloContentLupa = null;

    public markHover: boolean = false;
    public lupaMark: ApolloContentComparisonResultMark = null;

    public reportUrl:string = null;

    public showModal:boolean = true;
    public options = {method: 'comparison'};
    public optionSelected: string = '';

    public errorCompare: boolean = false;
    // public textError = {
    //     text: 'It looks like something went wrong and we could not compare the contents of the files. Please try again.',
    //     button: "Try again"
    // };

    // public protect = {
    //     text: 'It looks like something went wrong and we could not compare the contents of the files. Please try again.',
    //     button: "Try again"
    // };

    public error: any = {
        code: -1,
        message: ""
    };

    constructor(public urlSrv: UrlService, public apolloSrv: ApolloService, protected apolloReq: ApolloRequests, private prwSrv: PreviewsService,  private router: Router){}

    ngOnInit() {
        this.approvalsCtrl = new ApolloContentApprovals();

        this.subscriptionRequest = this.apolloSrv.selectRequest().subscribe((request : ApolloRequest) => {
            this.request = request
        });

        this.subscriptionFile = this.apolloSrv.getFileStoreProperty(this.storeProperty).subscribe((file: any)=> {
            if(file !== null){
                this.file = file;
                this.prwSrv.load(this.file);
                this.preview$ = this.prwSrv.getSubscriptor(file).subscribe(preview => {
                     this.preview = preview;
                     this.setContentSubscription();
                });
            } else {
                this.preview = null;
                if(typeof this.preview$ != 'undefined'){
                    this.preview$.unsubscribe();
                }
            }
        });

        // this.subscriptionContent = this.apolloSrv.selectorContentCompare().subscribe((apolloContent: ApolloContentComparison) => {
        //     this.apolloContent = apolloContent;
        //     if(this.apolloContent != null) {
        //         this.optionSelected = this.apolloContent.comparison_type;
        //         switch (this.apolloContent.status_code){
        //             case 0:
        //                 this.loading = true;
        //                 break;
        //             case 1:
        //                 this.showModal ? this.loading = false : this.loading = true;
        //                 break;
        //             case 2:
        //                 this.showModal = false;
        //                 this.errorCompare = false;
        //                 this.apolloContent.images.status_code == 2 ? this.loadComparisonResult() : this.loading = true;
        //                 break;
        //             case 3 || 4:
        //                 this.controlError();
        //             default:
        //                 this.loading = true;
        //                 break;
        //         }
        //     }
        // });

    }

    protected setContentSubscription() {
        if(this.subscriptionContent != null)
            return;

        this.subscriptionContent = this.apolloSrv.selectorContentCompare().subscribe((apolloContent: ApolloContentComparison) => {
            this.apolloContent = apolloContent;
            if(this.apolloContent != null) {
                this.optionSelected = this.apolloContent.comparison_type;
                switch (this.apolloContent.status_code){
                    case 0:
                        this.loading = true;
                        break;
                    case 1:
                        this.showModal ? this.loading = false : this.loading = true;
                        break;
                    case 2:
                        this.showModal = false;
                        this.errorCompare = false;
                        this.apolloContent.images.status_code == 2 ? this.loadComparisonResult() : this.loading = true;
                        break;
                    case 3 || 4:
                        this.controlError();
                    default:
                        this.loading = true;
                        break;
                }
            }
        });

    }

    newCompare(option, optionModal?){
        this.loading = true;
        if (optionModal) {
            this.optionSelected = option;
            this.apolloSrv.updateContentComparison(option);
            return;
        }

        this.optionSelected = option.method;
        this.apolloSrv.updateContentComparison(option.method);
    }

    actionModal(option){
        this.showModal = false;
        this.newCompare(option, true);
    }

    tryOrdered() {
        this.setError(-1, "");
        this.actionModal('comparison');
    }

    protected setError(code: number, message: string){
        this.errorCompare = code>-1;

        this.error = {
            code: code,
            message: message
        };
    }

    initInspector(data: any) {
        if(this.inspectorCtrl != null) {
            this.inspectorCtrl.onChange.unsubscribe();
        }
        this.inspectorCtrl = new ApolloContentInspector(this.urlSrv, this.apolloContent.images, data);
        this.inspectorCtrl.onChange.subscribe( (change: ApolloContentInspectorChange )  => this.onInspectorChange(change));
    }

    onInspectorChange(change: ApolloContentInspectorChange) {
        if(change.context == "marks")
            this.onMarksChange(change.type);

            if(change.context=="page")
                this.onPageChange(change.type, change.value);
    }

    onPageChange(type: string, page: number) {
        if(type ==  this.inspectorCtrl.TAR) {
                this.page = page;
                this.imgLoaded = false;
            }
    }

    onMarksChange(type: string) {
        if(type == this.inspectorCtrl.TAR)
            this.initLupa();
    }

    ngOnDestroy(){
        this.subscriptionRequest.unsubscribe();
        this.subscriptionContent.unsubscribe();
        this.subscriptionFile.unsubscribe();
        this.preview$.unsubscribe();
    }

    imageLoad(load) {
        this.initLupa();
        this.imgLoaded = load;
    }

    protected setPage(page: any) {
        if (page.page === this.inspectorCtrl.tarPage)
            return;

        this.imgLoaded = false;
        this.inspectorCtrl.setTarPage(page.page);
        this.inspectorCtrl.clearSelectedWords();
    }

    loadComparisonResult() {
        this.apolloReq.getContentComparisonResult$(this.request.hash).subscribe(
            (data:any) => {
                let first = this.getFirstTargetPage();
                console.debug("Compared result:",data);
                this.initInspector(data);
                this.setPage({page: first});
                this.loading = false;
                this.setUrlReport();
            },
            (error:any) => {
                console.log(error);
            }
        );
    }

    viewerResize(event) {
        if(event.width == 0 && event.height == 0)
            return false;

        this.size = { width: event.width,
                      height: event.height};

        this.lupa.setContainerSize(this.size);
        this.inspectorCtrl.setViewerSize(this.size);
    }

    protected initLupa() {
        if(this.lupa != null && this.lupa.img.src == this.inspectorCtrl.tarImageSrc)
            return;

        this.lupa = new ApolloContentLupa(this.inspectorCtrl.tarImageSrc);
        this.lupa.setContainerSize(this.size);
    }

    selectMark(mark: any) {
        this.inspectorCtrl.selectMark(mark);
        this.showInspector = true;
        this.scrollToInspector();
    }

    selectWord(word: any) {
        this.inspectorCtrl.selectWord(word);
        this.showInspector = true;
        this.scrollToInspector();
    }

    scrollToInspector(){
        this.inspector.nativeElement.scrollIntoView();
    }

    lupaIn(mark: any) {
        this.lupaMark = mark;
        this.markHover = true;
    }

    lupaOut() {
        this.markHover = false;
    }

    isLupaMark(mark: ApolloContentComparisonResultMark) {
        return this.lupaMark != null && mark.id == this.lupaMark.id && mark.type == this.lupaMark.type;
    }

    getChangesFiltersActive(){
        let changes = this.inspectorCtrl.changeList.filter( c => this.inspectorCtrl.isFilterActive(c));
        return changes.length;
    }

    getChangesOrphanRefFilters(){
        // Return total number of orphanRefList filter
        let changes = this.inspectorCtrl.orphanRefList.filter( r => this.inspectorCtrl.isFilterActive(r));
        return changes.length;
    }

    // 14 - 06 -2022 deactivated button for download report action.
    setUrlReport(){
        // Only target ids.
        // TODO new array with reference ids
        let params = new HttpParams();

        params = params.set('filters', JSON.stringify(this.inspectorCtrl.activeFilters));

        if(this.approvalsCtrl.activeFilters.includes('accepted')) {
            params = params.set('accepted', JSON.stringify(this.approvalsCtrl.getApprovalsByStatus(1).map(a => [a.ref, a.tar])));
        }

        if(this.approvalsCtrl.activeFilters.includes('rejected')) {
            params = params.set('rejected', JSON.stringify(this.approvalsCtrl.getApprovalsByStatus(2).map(a => [a.ref, a.tar])));
        }

        // let approvalReport = '';
        this.reportUrl = this.urlSrv.build("/p/apollo/compare/" + this.request.hash + "/content/report");
        this.reportUrl += "?"+ params.toString();

        console.debug("report url --->", this.reportUrl);

        // let properties: any = {
        //     filters: this.inspectorCtrl.activeFilters,
        // };

        // if(this.approvalsCtrl.activeFilters.includes('accepted')) {
        //     properties['accepted'] = this.approvalsCtrl.getApprovalsByStatus(1).map(a => [a.ref, a.tar]);
        // }
        //
        // if(this.approvalsCtrl.activeFilters.includes('rejected')) {
        //     properties['rejected'] = this.approvalsCtrl.getApprovalsByStatus(2).map(a => [a.ref, a.tar]);
        // }



        // if (this.approvalsCtrl.activeFilters.length > 0) {
        //     this.approvalsCtrl.activeFilters.forEach(f => {
        //         if (f === 'accepted')
        //             approvalReport === '' ?
        //                 approvalReport = approvalReport + 'accepted=['+this.approvalsCtrl.getTarIdsByStatus(1).join(',')+"]" :
        //                 approvalReport = approvalReport + '-accepted=['+this.approvalsCtrl.getTarIdsByStatus(1).join(',')+"]"
        //
        //         if (f === 'rejected')
        //             approvalReport === '' ?
        //                 approvalReport = approvalReport + 'rejected=['+this.approvalsCtrl.getTarIdsByStatus(2).join(',')+"]" :
        //                 approvalReport = approvalReport + '-rejected=['+this.approvalsCtrl.getTarIdsByStatus(2).join(',')+"]";
        //     });
        //
        //     this.reportUrl = this.reportUrl + "?filters=" + approvalReport;
        // }
        //
        // if (this.inspectorCtrl.activeFilters.length > 0) {
        //     approvalReport != "" ?
        //         this.reportUrl = this.reportUrl + "-" + this.inspectorCtrl.activeFilters.join("-") :
        //         this.reportUrl = this.reportUrl + "?filters=" +this.inspectorCtrl.activeFilters.join("-")
        //
        // }
    }

    controlError(){
        this.errorCompare = true;
        if (this.apolloContent.error_message != '') {
            this.setError(1, this.apolloContent.error_message);
            // this.textError.text = this.apolloContent.error_message;
            // this.textError.button = "Return to upload";
        } else {
            this.setError(0, "");
            // this.textError.text = "It looks like something went wrong and we could not compare the contents of the files. Please try again.";
            // this.textError.button = "Try again";
        }
    }

    tryAgain(){
        // if (this.apolloContent.error_message != '') {
        //     return this.router.navigate(['/p/apollo/upload']);
        // }
        this.loading = true;
        this.errorCompare = false;
        this.apolloSrv.getContentCompare();
    }

    goToUpload() {
        return this.router.navigate(['/p/apollo/upload']);
    }

    approvalMatchButtons(status: number, match){
        // If ref is null
        if (match.matches.length == 0){
            // TODO Control match.type -- target or reference can be null
            this.approvalsCtrl.setApproval(null, match.id, status);
            this.setUrlReport();
            return;
        }

        match.matches.forEach(r => this.approvalsCtrl.setApproval(r, match.id, status));
            this.setUrlReport();
    }

    // isApprovedStatus(status: number, word: any) {
    //     if(word.matches.length > 0) {
    //         let appr = this.approvalsCtrl.getApprovalByIds(word.matches[0], word.id);
    //         return appr.status == status;
    //     }

    //     return 0 == status;
    // }

    isApprovedStatus(status: number, word: any) {
        let appr = this.approvalsCtrl.getApprovalByTargetId(word.id);
        if (appr.status > 0)
            return appr.status == status;

        return 0 == status;
    }

    selectFilter(event){
        event.target.checked ? this.inspectorCtrl.setFilter(event.target.id) : this.inspectorCtrl.unsetFilter(event.target.id);
        this.setUrlReport();
    }

    selectApprovalFilter(event){
        event.target.checked ? this.approvalsCtrl.setFilter(event.target.id) : this.approvalsCtrl.unsetFilter(event.target.id);
        this.setUrlReport();
    }

    // public isFilterActive(target: ApolloContentComparisonResultMark) : boolean {
    //     let appr = this.approvalsCtrl.getApprovalStatusByTargetId(target.id);
    //     if(appr.status > 0)
    //         return this.approvalsCtrl.isStatusFilterActive(appr.status);
    //     else
    //         return this.inspectorCtrl.isFilterActive(target);
    // }

    public isFilterActive(target: ApolloContentComparisonResultMark) : boolean {
        // let appr = this.approvalsCtrl.getApproval(target);
        // if(appr.status > 0 && this.approvalsCtrl.activeFilters.length > 0) {
        // //if(this.approvalsCtrl.activeFilters.length > 0 ) {
        //     return this.approvalsCtrl.isFilterActive(target);
        // } else
        //     return this.inspectorCtrl.isFilterActive(target);

        // if(this.approvalsCtrl.activeFilters.length > 0) {
        // //if(this.approvalsCtrl.activeFilters.length > 0 ) {
        //     return this.approvalsCtrl.isFilterActive(target);
        // } else {
        //     let appr = this.approvalsCtrl.getApproval(target);
        //     return appr.status > 0? false : this.inspectorCtrl.isFilterActive(target);
        //     //return this.inspectorCtrl.isFilterActive(target);
        // }

        return this.approvalsCtrl.isFilterActive(target) && this.inspectorCtrl.isFilterActive(target);

        // if(this.approvalsCtrl.activeFilters.length==0 && this.approvalsCtrl.isFilterActive(target)) {
        //     return this.inspectorCtrl.isFilterActive(target) && this.approvalsCtrl.isFilterActive(target);
        // } else {
        //     return this.approvalsCtrl.isFilterActive(target);
        // }

    }

    totalMatches(){
        let total = this.inspectorCtrl.changeList.filter( t => this.approvalsCtrl.isFilterActive(t) && this.inspectorCtrl.isFilterActive(t));
        let totalRef = this.inspectorCtrl.orphanRefList.filter( r => this.inspectorCtrl.isFilterActive(r));
        return total.length + totalRef.length;
    }

    ignorePages() : Array<number> {
        return this.request.target.filters.filter(f => f.type=="ignore" && f.options == true).map(f => f.page);
    }

    protected getFirstTargetPage(): number {
        let ignores = this.ignorePages();
        let total = this.preview.total_pages;
        let start = 1;
        let pages = Array(total).fill(0).map(i => start++).filter( i => !ignores.includes(i));

        // let pages = [...Array(total+1).fill(0).keys()];
        // pages = pages.filter(i => i > 0 &&  !ignores.includes(i));
        return pages[0];
    }
}
