import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

import { AppService } from '@app/services/app/app.service';
import { WorkflowsService } from '@app/services/settings/workflows.service';
import { VersionsService } from '@app/services/app/versions.service';
import { TitleService } from '@core/services/title.service';

import * as Objects from '@core/objects';

import { SelectItem } from '@core/models/select-item.model';
import { AddProjectService, } from '@app/services/app/add-project.service';
import { AddRequest } from '@app/models/add-project';

@Component({
    selector: 'project-add-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectAddMainComponent implements OnInit, OnDestroy{

    public readonly ACCESS = "access_global_new_request";

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

	public access:boolean = true;
    public status: number = 0;
    public creatingNewProject: boolean = false;
    // flags for leave the page conditions
    public allowLeave: boolean = false;
    public saved: boolean = false;
    public leavingModal: boolean = false;
    private urlTo: string = '';
    private uploading: boolean = false;

    public workflowsList: Array<SelectItem>; // items for wf selector 
    public addProjectData: AddRequest = null;
    public activeWorkflows: Array<Objects.Workflow> = [];
    // form controls
    public form: FormGroup;
    public infoControl: FormControl;
    // for origin version case
    public version: Objects.Version = null;
    public versionId: number = null;
    public workflowId: number = null;
    
    // response success
    public savedProject: Objects.Request = null;
    // header data
    public breadcrumbs: any = [
        {
            title: "",
            link: ['']
        }
    ];

    protected newProjectSubscriptor: Subscription;
    protected navigationSubscriber: Subscription;

	constructor(
        private router: Router,
        private location: Location,
        public appSrv: AppService,
        private workflowsSrv: WorkflowsService,
        private versionsSrv: VersionsService,
        private titleSrv: TitleService,
        private activeRoute: ActivatedRoute,
        private AddProjectSrv: AddProjectService,
        private fb: FormBuilder){}

    @HostListener('window:beforeunload', ['$event'])
    onProjectAddLeave(event: any): void {
        event.preventDefault(); 
        event.returnValue = false;
    }

	ngOnInit(){
        this.titleSrv.set("Add - Projects");
        this.newProjectSubscriptor = this.AddProjectSrv.select().subscribe((newProject: AddRequest) => {
            if(newProject != null)
                this.addProjectData = newProject;
        });

        // control access new request
        this.appSrv.getSession().subscribe( (session: any) => {
            this.access = session.options.access.hasOwnProperty(this.ACCESS)? session.options.access[this.ACCESS] : false;
            if (this.access){
                this.loadWorkflows(); // first thing to do
            } else {
                this.status = this.STATUS_NOT_ALLOWED;
            }
        });
        // get params
        if(this.activeRoute.snapshot.queryParamMap.has('workflow')) {
            this.workflowId = +this.activeRoute.snapshot.queryParamMap.get('workflow');
        }
        // in case of origin version get labels
        if(this.activeRoute.snapshot.queryParamMap.has('version')) {
            this.versionsSrv.getById$(+this.activeRoute.snapshot.queryParamMap.get('version')).subscribe((version: Objects.Version) => {
                if (version != null){
                    this.versionId = version.id;
                    this.version = version;
                    //  ensure to set labels and version origin in store
                    console.log("version origin--->", version);
                    if(this.addProjectData != null && this.addProjectData.labels.length == 0)
                        this.addProjectDataChange({labels: version.labels.map((l:Objects.Label) => { return {...l}; })});
                    if(this.addProjectData != null && this.addProjectData.version == null){
                        this.addProjectDataChange({version: this.version.id});
                    }
                }
            });
        }

        // router control for leaving
        this.navigationSubscriber = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                if(this.router.url != event.url){
                    console.debug(" new route is out --->",this.router.url, event.url);
                    this.leavingModal = true;
                    this.urlTo = event.url;
                }
            }
        });        
        
	}

    ngOnDestroy() {
        this.leavingModal = false;
        this.navigationSubscriber.unsubscribe();
        if(this.newProjectSubscriptor)
            this.newProjectSubscriptor.unsubscribe();
    }

    init() {   
                
        if (this.addProjectData == null){
            if (this.workflowId == null)
                this.workflowId = this.activeWorkflows[0].id; // must be string for the selector component
            this.AddProjectSrv.init(+this.workflowId,this.versionId );
            // ensure the version tags is set in the editor.
            if(this.version != null && this.addProjectData.labels.length == 0)
                this.addProjectDataChange({labels: this.version.labels.map((l:Objects.Label) => { return {...l}; })});

        }

        this.infoControl = new FormControl(this.addProjectData != null ? this.addProjectData.info : '',[Validators.required]);
        this.form = this.fb.group({});
        this.form.addControl("info", this.infoControl);
        this.status = this.STATUS_DONE;
    }

    protected loadWorkflows(){
        // get workflows
        this.workflowsSrv.getWorkflows$().subscribe((workflows: Array<Objects.Workflow>) => {
            if( workflows.length == 0){
                this.status = this.STATUS_NOT_ALLOWED;
                return false;
            }
            this.activeWorkflows = workflows.filter((wf: Objects.Workflow) => {return wf.active;});
            if( this.activeWorkflows.length == 0){
                this.status = this.STATUS_NOT_ALLOWED;
                return false;
            }
            
            this.workflowsList = this.activeWorkflows.map((wf: Objects.Workflow) => {
                    return {
                        title: wf.name,
                        value: wf.id.toString(),
                        selected: this.workflowId == wf.id ? true : false
                    };
            });

            // we init component after load workflow.
            this.init();
            
        });
    }

    public onSubmit(){
        this.creatingNewProject = true;
        console.debug("submit project form:",this.addProjectData);
        this.AddProjectSrv.addProject$(this.addProjectData).subscribe((project: Objects.Request) => {
            console.debug("The Created project ", project);
            this.savedProject = project;
            this.creatingNewProject = false;
            this.saved = true;
        });
    }

    public onChangeLabels(labels: Array<Objects.Label>){
        this.addProjectDataChange({labels: labels}); 
    }

    public onSetFiles(files: Array<Objects.File>){
        this.addProjectDataChange({files: files}); 
        this.uploading = false;
    }

    public onUploading(uploading: boolean){
        this.uploading = uploading;
    }

    public onInfoChange(text: string){
        this.addProjectDataChange({info: text });
    }

    public onSelectDate(date: string){
        this.addProjectDataChange({due_date: this.addProjectData.due_date == date ? null : date});
    }

    public onSelectWorkflow(workflow: SelectItem){
        this.addProjectDataChange({workflow: +(workflow.value) });
    }

    // checks to show the button SAVE
    isProjectReady () {
        return (
            !this.uploading &&
            this.addProjectData !== null && 
            this.addProjectData.labels.length > 0 &&
            this.addProjectData.workflow != null &&
            this.form.get('info').value.trim() != ''
           );
    }

    onOpenProject(){
        if (this.savedProject != null){
            this.router.navigate(['/projects/'+this.savedProject.id]);
        }
    }

    onStartNew(){
        this.saved = false;
    }

    onCancel(){
        this.AddProjectSrv.clear();
        this.allowLeave = true;
        this.location.back();
    }

    onDiscard(){
        this.AddProjectSrv.clear();
        this.leavingModal = false;
        this.allowLeave = true;
        this.router.navigateByUrl(this.urlTo);
    }

    onStay(){
        this.leavingModal =false;
        this.allowLeave = false;

    }

    addProjectDataChange(changes: Partial<AddRequest>){
        // console.log(" updating data to store",changes);
        this.AddProjectSrv.update( changes );
    }

}
