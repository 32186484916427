import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';
import { KeyPressService } from './keypress.service';

import { NavigationExtras, Router } from '@angular/router';
import { query } from '@angular/core/src/render3';


@Injectable({
    providedIn: 'root'
})
export class AppRouterService implements OnDestroy{
    private kbSubscriptor: Subscription;
    private keyBoard: KeyboardEvent = null;

    constructor(
        private urlSrv : UrlService,
        private router: Router,
        private keyPressSrv: KeyPressService
    ) {
        this.kbSubscriptor = this.keyPressSrv.keyEventObs.subscribe((keyEvent: KeyboardEvent) => {
            this.keyBoard = keyEvent;
        });
    }
    
    navigate( url: string = "", params?: NavigationExtras) {
        // Mac Cmd key
        // event.key == "Meta" || event.metaKey
        // Ctrl key
        // event.key == "Control" || event.ctrlKey
        if(this.isTargetBlank()){
            let newRelativeUrl = this.router.createUrlTree([url], params);
            let baseUrl = window.location.href.replace(this.router.url, '');
            console.debug(" >navigate< to new tab ",this.keyBoard, baseUrl + newRelativeUrl );
            window.open(baseUrl + newRelativeUrl,"_blank");
        } else {
            this.router.navigate([url],params);
        }
    }

    open( url: string = '') {
        if(this.isTargetBlank()){
            console.debug(" >open< new tab ",this.keyBoard, window.location.origin + url );
            window.open(window.location.origin + url, '_blank');
        }
        else 
            window.open(window.location.origin + url)
    }

    ngOnDestroy(){
        this.kbSubscriptor.unsubscribe();
        this.keyBoard = null;
    }

    private isTargetBlank(){
        if (this.keyBoard && this.keyBoard.type == "keydown")
            return this.keyBoard.key == "Meta" || this.keyBoard.metaKey || this.keyBoard.key == "Control" || this.keyBoard.ctrlKey;
        return false;
    }

}