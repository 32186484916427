import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { File } from '@core/objects/file';
import { Related } from '@core/objects/related';

@Component({
    selector: 'core-versions-related-group-item-files-list-checkbox',
    templateUrl: './related-group-item-files-list-checkbox.component.html',
    styleUrls: ['./related-group-item-files-list-checkbox.component.scss']
})
export class VersionsRelatedGroupItemFilesListCheckboxComponent implements OnInit {

    @Input() related: Related;

    @Input() disabled: boolean;
    @Input()  selected: Array<File>;

    @Output() selectedChange = new EventEmitter<Array<File>>();

    public opened: boolean = true;
    public firstOpened: boolean = false;

    constructor(private httpClient: HttpClient, private urlSrv: UrlService ) { }

    ngOnInit() {
    }
    // to check and transmit up
    onChange(files: Array<File>) {
        this.selected = files;
        this.selectedChange.emit(this.selected);
    }

    openVersions() {
        this.firstOpened = true;
        this.opened = !this.opened;
    }


}
