import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

@Component({
    selector: 'core-message-add',
    templateUrl: './message-add.component.html',
    styleUrls: ['./message-add.component.scss']
})
export class MessageAddComponent implements OnInit {
    @ViewChild("container", {read: ViewContainerRef}) containerRef: ViewContainerRef;
    @ViewChild("content", {read: TemplateRef}) contentRef: TemplateRef<any>;

    @Input() action: string;
    @Input() show: boolean = false;
    @Input() placeholder: any;

    @Output() menuAction = new EventEmitter<string>();
    @Output() onSaved = new EventEmitter<any>();

    // public menu: Array<ThreeDotsMenuItem> = [];
    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "Add Message",
            key: "add-message",
            icon: "add-message"
        }
    ];

    public defaultShow: boolean = false;
    public working: boolean = false;
    public error: boolean = false;
    public success: boolean = false;

    constructor(public httpClient: HttpClient) {
    }

    ngOnInit(){
        this.renderForm();
        this.defaultShow = this.show;

    }

    renderForm() {
        this.containerRef.clear();
        this.containerRef.createEmbeddedView(this.contentRef);
    }

    onSubmit(value: any){
        this.working = true;
        this.show = false;

        this.send(value).subscribe(
            (data:any) => {
                console.log('Message saved: '+data);
                this.working = false;
                this.setSuccess(true);
                //this.success = true;
                this.onSaved.emit(data);
            },
            (error:any) => {
                console.log('Error message: '+error);
                this.working = false;
                this.error = true;
            }
        );
    }

    try() {
        this.error = false;
        this.show = true;
    }

    onMenuClick(key: string) {
        switch(key) {
            case "add-message":
                console.debug(" open add message ", key)
                // if(!this.accessUpload)
                // break;
            
                this.show = !this.show;
                break;
        }
    }

    onCancel(){
        this.show = false;
    }

    private send(value:any) {
        let options = { headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};

        return this.httpClient.post<string>(this.action, value, options);
    }

    private setSuccess(success: any) {
        this.renderForm();
        this.success = success;
        setTimeout(()=>{
            this.success = false;
            this.show = this.defaultShow;
        }, 1000);
    }

}
