import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'core-helpers-box-alert-button',
    templateUrl: './alert-button.component.html',
    styleUrls: ['./alert-button.component.scss']
})
export class HelperBoxAlertButtonComponent {

    @Input() text: string;
    @Input() buttonText: string;

    @Output() action = new EventEmitter<boolean>();

    onAction() {
        this.action.emit(true);
    }

}
