import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelperNavigationModule } from '@core/components/helpers/navigation/navigation.module';
import { HelperIconsModule } from '@core/components/helpers/icons/icons.module';

import { FilesListDefaultItemComponent } from './default-item/default-item.component';
import { FilesListDefaultComponent } from './default/default.component';
import { FilesListNotesItemComponent } from './notes-item/notes-item.component';
import { FilesListNotesComponent } from './notes/notes.component';

@NgModule({
    imports: [
        CommonModule,
        HelperNavigationModule,
        HelperIconsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        FilesListDefaultItemComponent,
        FilesListDefaultComponent,
        FilesListNotesComponent,
        FilesListNotesItemComponent
    ],
    exports: [
        FilesListDefaultItemComponent,
        FilesListDefaultComponent,
        FilesListNotesComponent,
        FilesListNotesItemComponent
    ],

})
export class FilesListModule { }
