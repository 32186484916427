import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { MultiSelectItem } from '@core/models/select-item.model';

import { AvailableRuleItem, RuleAction, AvailableRuleActionVariable } from '@app/models/rules';

interface VariableInput {
    key: string,
    value: string | Array<string>
};

@Component({
  selector: 'settings-rules-rule-form-actions-variable-item',
  templateUrl: './actions-variable-item.component.html',
  styleUrls: ['./actions-variable-item.component.scss'],
})
export class SettingsRulesRuleFormActionsVariableItemComponent{


    @Input() availableVariables: Array<AvailableRuleActionVariable> = [];
    // @Input() : VariableInpu
    @Input() variable: VariableInput; // {key: string, value: any }
    // @Input() variable: RuleAction;
    // @Output() clickAdd = new EventEmitter();
    // @Output() clickRemove = new EventEmitter();
    @Output() selectValue = new EventEmitter<VariableInput>();

    public options: Array<MultiSelectItem> = [];
    // constructor(){}

    onSelectAction(e: AvailableRuleItem){
        console.log("select--->", e);

        let ret = {
            key: this.variable.key,
            value: e.key
        }

        this.selectValue.emit(ret);
    }

    onSelectActionMultiple(e: Array<AvailableRuleItem>){
        // console.log("multiselect--->", e);

        let ret = {
            key: this.variable.key,
            value: e.map((v: AvailableRuleItem) => v.key)
        }

        this.selectValue.emit(ret);
    }

    getAvailableVariable(key: string) {
        let variables = this.availableVariables.filter( v => v.key == key);

        return variables[0];
    }


}
