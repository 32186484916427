import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRouterModule } from '@app/router.module';

import { SettingsUsersModule } from '../users/users.module';

import { SettingsGroupsMainComponent } from './main/main.component';
import { SettingsGroupsListComponent } from './list/list.component';
import { SettingsGroupsAddFormComponent } from './add-form/add-form.component';
import { SettingsGroupsGroupUsersComponent } from './group-users/group-users.component';
import { SettingsGroupsGroupUsersAddFormComponent } from './group-users-add-form/group-users-add-form.component';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        // SettingsUsersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SettingsGroupsMainComponent,
        SettingsGroupsListComponent,
        SettingsGroupsAddFormComponent,
        SettingsGroupsGroupUsersComponent,
        SettingsGroupsGroupUsersAddFormComponent
    ],
    exports: [
        SettingsGroupsMainComponent,
        SettingsGroupsListComponent,
        SettingsGroupsAddFormComponent,
        SettingsGroupsGroupUsersComponent,
        SettingsGroupsGroupUsersAddFormComponent
    ],

})
export class SettingsGroupsModule { }
