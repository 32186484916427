import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { IconsBlueCircleComponent } from './blue-circle/blue-circle.component';
import { IconsColoredBubbleCircleLetterComponent } from './colored-bubble-circle-letter/colored-bubble-circle-letter.component';
import { IconsNotesCounterBubbleComponent } from './notes-counter-bubble/notes-counter-bubble.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        IconsBlueCircleComponent,
        IconsColoredBubbleCircleLetterComponent,
        IconsNotesCounterBubbleComponent
    ],
    exports: [
        IconsBlueCircleComponent,
        IconsColoredBubbleCircleLetterComponent,
        IconsNotesCounterBubbleComponent
    ],

})
export class HelperIconsModule { }
