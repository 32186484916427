import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@core/core.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { AppRouterModule } from '@app/router.module';

import { SettingsRulesMainComponent } from './main/main.component';

import { SettingsRulesRulesFormModule } from './form/rules-form.module';
// import { SettingsRulesRuleFormComponent } from './rule-form/rule-form.component';
// import { SettingsRulesRuleFormConditionsComponent } from './rule-form-conditions/rule-form-conditions.component';
// import { SettingsRulesRuleFormConditionsItemComponent } from './rule-form-condition-item/rule-form-condition-item.component';
// import { SettingsRulesRuleFormActionsComponent } from './rule-form-actions/rule-form-actions.component';
// import { SettingsRulesRuleFormActionsItemComponent } from './rule-form-actions-item/rule-form-actions-item.component';
import { SettingsRulesMainEditComponent } from './main-edit/main-edit.component';
import { SettingsRulesRuleListComponent } from './rule-list/rule-list.component';

@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        HelpersModule,
        SettingsRulesRulesFormModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SettingsRulesMainComponent,
        SettingsRulesRuleListComponent,
        SettingsRulesMainEditComponent
    ],
    exports: [
        SettingsRulesMainComponent,
        SettingsRulesRuleListComponent,
        SettingsRulesMainEditComponent
    ],

})
export class SettingsRulesModule { }
