import { Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'core-helpers-forms-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
})
export class InputNumberComponent {

    @Input() disabled: boolean;
    @Input() label: string = "";
    @Input() min: number;
    @Input() max: number;
    @Input() value: number;

    @Output() select = new EventEmitter<any>();

    onChange(event){
        if( event != null)
            this.select.emit(event);
    }
}
