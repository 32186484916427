import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LabelsModule } from '@core/components/labels/labels.module';

import { ProjectDetailsLogbookMainComponent } from './main/main.component';
import { ProjectsDetailsHelpersModule } from '../helpers/helpers.module';

import { ProjectDetailsLogbookEventsListModule } from './events-list/logbook-events-list.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreModule,
        HelpersModule,
        LabelsModule,
        ProjectsDetailsHelpersModule,
        ProjectDetailsLogbookEventsListModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsLogbookMainComponent
        
    ],
    exports: [
        ProjectDetailsLogbookMainComponent,
    ],

})
export class ProjectDetailsLogbookModule { }
