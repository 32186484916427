import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { UrlService } from '@core/services/url/url.service';
import { VersionsService } from '@core/services/versions/versions.service';


import { VersionFeedback } from '@core/models/version-feedback';

// import { User } from '@core/objects/user';
import { File } from '@core/objects/file';
import { Version } from '@core/objects/version';

// import { Request } from '@core/objects/request';
// import { Label } from '@core/objects/label';
// import { Related } from '@core/objects/related';

@Component({
    selector: 'plugin-share-feedback-core-version-files',
    templateUrl: './version-files.component.html',
    styleUrls: ['./version-files.component.scss']
})
export class PluginShareFeedbackCoreVersionFilesComponent implements OnInit, OnChanges {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_FOUND = 3;

    @Input() version: Version = null;
    @Input() canCreateNotes: boolean = true;
    @Input() notesActionText: string;
    @Input() notesActionStyle: string;
    @Input() prefixUrl: string = '';

    public files: Array<File> = [];
    public selectedFile: File = null;
    public status: number = this.STATUS_LOADING;

    constructor(private versionsSrv: VersionsService ) { }

    ngOnInit() {
        // this.loadApprovalsList();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.version && this.version != null)
            this.init();
        //     this.loadApprovalsList();
    }

    protected init() {
        this.status = this.STATUS_LOADING;
        this.selectedFile = null;
        this.loadFiles();

    }
    protected loadFiles(){
        this.versionsSrv.getFiles$(this.version).subscribe((files: Array<File>) => {
            this.files = files;
            this.status = this.STATUS_DONE;
        });
    }

    public openNotes(e: any){
        if(this.selectedFile != null && this.selectedFile.id == e.id)
            this.selectedFile = null;
        else
            this.selectedFile = e;
    }

    notesSaved(e:any) {
        // this update files and reload notes counter
        this.files = [...this.files];
    }


}
