import { EventEmitter } from '@angular/core';

import { ApolloContentComparisonResultMark } from './apollo-content-compared-result-mark.model';

class ApolloContentLupaSizes {
    image: any = {
        width: 0,
        height: 0
    };

    box: any = {
        width: 0,
        height: 0
    };
}
export class ApolloContentLupa {
    //public onChange = new EventEmitter<any>();

    public img: any;

    public sizes: any = new ApolloContentLupaSizes;
    public style: any = {
        'backgroud-size': "auto auto",
        'background-image': "",
        'background-position-x.px': 0,
        'background-position-y.px': 0,
        'width.px': 0,
        'height.px': 0,
        'top.px': 0,
        'left.px': 0,
    };

    public mark: ApolloContentComparisonResultMark = null;

    public class: string = "";

    /**
     * constructor: set data from comparison content result
     **/
    constructor(src:  string) {
        this.setImgSrc(src);
    }

    public setImgSrc(src: string) {
        this.loadImg(src);
    }

    public setContainerSize(size: any) {
        this.sizes.box = size;
    }

    protected loadImg(src: string){
        this.img = new Image();
        this.img.onload = () => {
                this.sizes.image = {
                    width: this.img.width,
                    height: this.img.height
                };
                console.debug("size lupa", this.sizes);
        };

        this.img.src = src;

        this.style = {...this.style, 'background-image': "url("+src+")" }
    }

    public setMark(event: any, mark: ApolloContentComparisonResultMark) {
        //let zoom = 1;
        let bgzoom = "auto";
        this.mark = mark;
        // class style css
        this.class = mark.change;

        // mouse position
        // var rect = event.currentTarget.getBoundingClientRect(); // Get offset with getBoundingClient.
        // var mleft = event.clientX - rect.left;
        // var mtop = event.clientY - rect.top;

        // centered mark  position
        var mleft = mark.x + (mark.width/2);
        var mtop = mark.y + (mark.height/2);

        // size and position of lupa (move with mouse)
        // let width = mark.width * 2;
        // let height = mark.height * 2;
        // let left = mleft - (width/2);
        // let top = mtop - (height/2);

        // size and position centered (wihtout move)
        let width = this.sizes.image.width * mark.width / this.sizes.box.width;
        let height = this.sizes.image.height * mark.height / this.sizes.box.height;
        let left = mleft - (width/2);
        let top = mtop - (height/2);

        // background position
        let pleft = mleft / this.sizes.box.width; // percent
        let ptop = mtop / this.sizes.box.height; // percent

        let mpleft = this.sizes.image.width * pleft;
        let mptop = this.sizes.image.height * ptop;

        let ppx = -(mpleft - width/2);
        let ppy = -(mptop - height/2);

        // padding on sizes
        width = width + 10;
        height = height + 10;
        left = left - 5;
        top = top - 5;

        // fix lupa position
        if(left<0)
            left = 0;
        if (left+width > this.sizes.box.width)
            left = this.sizes.box.width - width;

        if(top<0)
            top = 0;
        if(top+height > this.sizes.box.height)
            top = this.sizes.box.height - height;
        // end fix position

        // when area is bigger than box area
        if(width > this.sizes.box.width) {
            let scale = (this.sizes.box.width-10) / width;
            left = 5;
            top = top+(height-height*scale)/2;
            width = width*scale;
            height = height*scale;
            ppx = ppx*scale;
            ppy = ppy*scale;
            bgzoom = this.sizes.image.width * scale + "px";

        }

        this.style = {...this.style,
                     'background-size': bgzoom + " auto",
                     'width.px': width,
                     'height.px': height,
                     'left.px': left,
                     'top.px': top,
                     'background-position-x.px': ppx,
                     'background-position-y.px': ppy,
                    // 'transform': "scale("+zoom+")"
                 };
        console.debug("style", this.style);
    }

};
