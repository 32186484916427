
import { Component, Input } from '@angular/core';
import * as Objects from '@core/objects';


@Component({
    selector: 'project-details-helpers-stages-transition',
    templateUrl: './stages-transition.component.html',
    styleUrls: ['./stages-transition.component.scss']
})
export class ProjectDetailsHelpersStagesTransitionComponent{
	@Input() origin: Objects.Status;
	@Input() status: Objects.Status;

}