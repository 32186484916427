import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {SizeMeModule} from 'ngx-size-me';

import { FilesModule } from '@core/components/files/files.module';
import { PreviewModule } from '@core/components/preview/preview.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { CoreModule } from '@core/core.module';

import { PluginApolloHelpersGraphicInspectorComponent } from './graphic-inspector/graphic-inspector.component';
import { PluginApolloHelpersGraphicLayersComponent } from './graphic-layers/graphic-layers.component';
import { PluginApolloHelpersToolsBarComponent } from './tools-bar/tools-bar.component';
import { PluginApolloHelpersItemUploadComponent } from './item-upload/item-upload.component';
import { PluginApolloHelpersCompareButtonsComponent } from './compare-buttons/compare-buttons.component';
import { PluginApolloHelpersModalAlignComponent } from './modal-align/modal-align.component';
import { PluginApolloHelpersModalOcrInfoComponent } from './modal-ocr-info/modal-ocr-info.component';
import { PluginApolloHelpersContentExtractComponent } from './content-extract/content-extract.component';
import { PluginApolloHelpersContentExtractResultComponent } from './content-extract-result/content-extract-result.component';
import { PluginApolloHelpersContentComparedComponent } from './content-compared/content-compared.component';
import { PluginApolloHelpersContentInspectorComponent } from './content-inspector/content-inspector.component';
import { PluginApolloHelpersContentResultMarkComponent } from './content-result-mark/content-result-mark.component';
import { PluginApolloHelpersModalContentComponent } from './modal-content/modal-content.component';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SizeMeModule.forRoot(),
        FilesModule,
        PreviewModule,
        HelpersModule,
        CoreModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        PluginApolloHelpersGraphicInspectorComponent,
        PluginApolloHelpersGraphicLayersComponent,
        PluginApolloHelpersToolsBarComponent,
        PluginApolloHelpersItemUploadComponent,
        PluginApolloHelpersCompareButtonsComponent,
        PluginApolloHelpersModalAlignComponent,
        PluginApolloHelpersModalOcrInfoComponent,
        PluginApolloHelpersContentExtractComponent,
        PluginApolloHelpersContentExtractResultComponent,
        PluginApolloHelpersContentComparedComponent,
        PluginApolloHelpersContentInspectorComponent,
        PluginApolloHelpersContentResultMarkComponent,
        PluginApolloHelpersModalContentComponent

    ],
    exports: [
        PluginApolloHelpersGraphicInspectorComponent,
        PluginApolloHelpersGraphicLayersComponent,
        PluginApolloHelpersToolsBarComponent,
        PluginApolloHelpersItemUploadComponent,
        PluginApolloHelpersCompareButtonsComponent,
        PluginApolloHelpersModalAlignComponent,
        PluginApolloHelpersModalOcrInfoComponent,
        PluginApolloHelpersContentExtractComponent,
        PluginApolloHelpersContentExtractResultComponent,
        PluginApolloHelpersContentComparedComponent,
        PluginApolloHelpersContentInspectorComponent,
        PluginApolloHelpersContentResultMarkComponent,
        PluginApolloHelpersModalContentComponent
    ],

})
export class ApolloHelpersModule { }
