import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// dependencies
import { LabelsModule } from '@core/components/labels/labels.module';

//components
import { LayoutTitleSectionComponent } from './title-section/title-section.component';
import { LayoutTitleSectionLabelsComponent } from './title-section-labels/title-section-labels.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LabelsModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LayoutTitleSectionComponent,
        LayoutTitleSectionLabelsComponent
    ],
    exports: [
        LayoutTitleSectionComponent,
        LayoutTitleSectionLabelsComponent
    ],

})
export class LayoutTitlesModule { }
