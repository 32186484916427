import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

import * as Objects from '@core/objects'
import * as Search from '@app/models/search';


interface SearchAvailableUsersFilters extends Search.SearchAvailableFilters {
    items: Array<Objects.User>;
}
@Component({
    selector: 'app-helpers-search-filters-users-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class HelpersSearchFiltersUsersMainComponent implements OnInit, OnChanges{

    @Input()  available: SearchAvailableUsersFilters = null;
    @Input()  applied: Array<Search.SearchAppliedFilter> = [];

    @Output() apply = new EventEmitter<Search.SearchAppliedFilter>();

    public users : Objects.User[] = [];

    ngOnInit(): void {
       this.makeUsers();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.applied)
            this.makeUsers();
    }

    onSelect(user: Objects.User) {
        this.apply.emit({key: this.available.key,item: user});
    }

    private isApplied(user: Objects.User) : boolean {
        return this.applied.some((f: Search.SearchAppliedFilter) => f.key == this.available.key && f.item.id == user.id);
    }

    private makeUsers(): void {
        this.users = this.available.items.filter((user: Objects.User) => !this.isApplied(user));
    }

}
