import { Component, Input, Output, EventEmitter,OnInit } from '@angular/core';

@Component({
  selector: 'core-helpers-icons-colored-bubble-circle-letter',
  templateUrl: './colored-bubble-circle-letter.component.html',
  styleUrls: ['./colored-bubble-circle-letter.component.scss'],
})
export class IconsColoredBubbleCircleLetterComponent implements OnInit {

  @Input() title: string = '';
  @Input() letter: string = '';
  @Input() color: string = '';
  @Output() actionClick = new EventEmitter<any>();


  constructor() { }

  ngOnInit(){
    if (this.letter == '' && this.title != ''){
      this.letter = this.title.charAt(0).toUpperCase();
    }
  }

  onBubbleClick(event: any) {
      // console.log(" bubble item click",this.title,this.letter)
      this.actionClick.emit();
  }


}
