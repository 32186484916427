import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';
import { Status } from '@core/objects/status';

@Component({
    selector: 'core-status-name',
    templateUrl: './status-name.component.html',
    styleUrls: ['./status-name.component.scss']
})
export class StatusNameComponent implements OnInit {

    @Input() status: Status;

    constructor(private httpClient: HttpClient, private urlSrv: UrlService ) { }

    ngOnInit() {
    }

}
