import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import * as Objects from '@core/objects';


@Component({
    selector: 'project-details-logbook-events-list-item',
    templateUrl: './events-list-item.component.html',
    styleUrls: ['./events-list-item.component.scss']
})
export class ProjectDetailsLogbookEventsListItemComponent implements OnInit{

	@Input() event: Objects.Event;
	@Input() activatedGroup: string = '';
    @Output() selectGroup = new EventEmitter<string>();
    
    public opened: boolean = false;

    ngOnInit(): void {

    }

    onOpen() {
        this.opened = !this.opened;
    }

    onSelectGroup(group: string) : void {
        this.selectGroup.emit(group);
    }
}