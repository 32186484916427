// plugin-apollo-main-section-check
// PluginApolloMainSectionCheckComponent
import { Component } from '@angular/core';

@Component({
    selector: 'plugin-apollo-main-section-check',
    templateUrl: './check.component.html',
    styleUrls: ['./check.component.scss']
})
export class PluginApolloMainSectionCheckComponent {
    public show: boolean;
    public showColors: boolean;
}
