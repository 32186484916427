import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


import { WorkflowsService } from '../../../../../services/settings/workflows.service';
import { Subscription } from 'rxjs';


import { Workflow } from '@core/objects/workflow';

@Component({
    selector: 'settings-workflows-edit-name-form',
    templateUrl: './edit-name-form.component.html',
    styleUrls: ['./edit-name-form.component.scss']
})
export class SettingsWorkflowsEditNameFormComponent implements OnInit{

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

    @Input() workflow: Workflow
    @Output() cancel = new EventEmitter<any>();
    @Output() submit = new EventEmitter<any>();

    public status: Number = 0;

    public form: FormGroup;
    public showInstructions: boolean =  false;

	constructor(public workflowsSrv: WorkflowsService, private formBuilder: FormBuilder){}

	ngOnInit(){
		this.init();

	}

    init(){
        this.form = this.formBuilder.group({
            name: ['', [Validators.required]]
		});
    }

	onCancel(event) {
        console.log(" cancel triggered")
        this.cancel.emit(event);
        this.cleanForm();
	}

    updateName(){
        if(this.form.invalid)
			return;
        this.workflowsSrv.updateWorkflowName$(this.form.value.name,this.workflow.id).subscribe((workflow: Workflow) => {
            // console.debug(" post: new wf name updated",workflow);
            this.cleanForm();
            this.submit.emit(workflow)
        });

    }

    cleanForm(){
		this.form.reset({name: ''});
	}

}
