import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProjectsService } from 'app/services/app/projects.service';
import { ProjectsEditorService } from '@app/services/app/projects.service';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import * as Objects from '@core/objects';

@Component({
    selector: 'project-details-info-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class ProjectDetailsInfoHeaderComponent implements OnInit {
    public readonly ACCESS_EDIT_LABELS = "access_request_edit_labels";

	@Input() project: Objects.Request = null;
	@Input() title: string = '';
	public accessEditTags: boolean = false;
	public uploaded: boolean = false;

    public edit: boolean = false;

    public menu: Array<ThreeDotsMenuItem> = [];
    protected menuTags: ThreeDotsMenuItem = {
        title: "Edit tags",
        key: "edit",
        icon: "edit-tags"
    };

    protected accessTagsSubscriptor: Subscription;
    protected subscriptor: Subscription;
    constructor(
        private editorSrv: ProjectsEditorService,
        private projectsSrv: ProjectsService
    ){}

    ngOnInit(): void {
        this.accessTagsSubscriptor = this.editorSrv.selectAccess(this.ACCESS_EDIT_LABELS)
        .subscribe((access: boolean) => {
            this.accessEditTags = access;
            this.updateMenu(this.menuTags,this.accessEditTags);
        });

        this.subscriptor = this.projectsSrv.selectUploadedVersions().subscribe((versions: Array<Objects.Version>) => this.uploaded = (versions!= null && versions.length > 0)   );        
        
    }
    ngOnDestroy() {
        this.accessTagsSubscriptor.unsubscribe();
        this.subscriptor.unsubscribe();
    }

    onMenuSelect(key: string) {
        switch(key) {
            case "edit":
                this.toggleEdit();
        }
    }

    toggleEdit(){
        this.edit = !this.edit;
    }

    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

}
