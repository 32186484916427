import { NgModule, InjectionToken, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { StoreModule, ActionReducerMap } from '@ngrx/store';
// import { EffectsModule } from '@ngrx/effects';
import { ShareModule } from '@app/components/share/share.module';

import { ShareFeedbackAppComponentsModule } from './components/components.module';
import { ShareFeedbackAppRouterModule } from './share-feedback.router';

@NgModule({
    imports: [
        CommonModule,
        ShareModule,
        ShareFeedbackAppComponentsModule,
        ShareFeedbackAppRouterModule,
        // StoreModule.forFeature('apollo', fromStore.apolloReducer),
        // EffectsModule.forFeature([ApolloEffects])
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
    ],
    exports: [
        ShareFeedbackAppComponentsModule,
        ShareFeedbackAppRouterModule
    ],

})
export class ShareFeedbackAppModule { }
