import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRouterModule } from '@app/router.module';

import { SettingsUsersModule } from '../users/users.module';

import { SettingsLicensesMainComponent } from './main/main.component';
import { SettingsLicensesLicensesComponent } from './licenses/licenses.component';
// import { SettingsUsersInvitationsAddComponent } from './invitations-add/invitations-add.component';
// import { SettingsUsersInvitationsComponent } from './invitations/invitations.component';
// import { SettingsUsersListUsersComponent } from './list-users/list-users.component';
// import { SettingsUsersInfoUserComponent } from './info-user/info-user.component';
// import { SettingsUsersBoxLicenceComponent } from './box-licence/box-licence.component';



@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        SettingsUsersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SettingsLicensesMainComponent,
        SettingsLicensesLicensesComponent
		// SettingsUsersInvitationsAddComponent,
		// SettingsUsersInvitationsComponent,
        // SettingsUsersListUsersComponent,
        // SettingsUsersInfoUserComponent,
        // SettingsUsersBoxLicenceComponent
    ],
    exports: [
        SettingsLicensesMainComponent,
        SettingsLicensesLicensesComponent
		// SettingsUsersInvitationsAddComponent,
		// SettingsUsersInvitationsComponent,
        // SettingsUsersListUsersComponent,
        // SettingsUsersInfoUserComponent,
        // SettingsUsersBoxLicenceComponent
    ],

})
export class SettingsLicensesModule { }
