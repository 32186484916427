import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LabelsModule } from '@core/components/labels/labels.module';

import { AppRouterModule } from '@app/router.module';
import { AppHelpersSearchBarModule } from 'app/components/helpers/search/bar/bar.module';

// import { LibrarySectionsModule } from '../versions/details/sections/sections.module';

import { DashboardMainComponent } from './main/main.component';
import { DashboardSearchModule } from './search/search.module';
import { DashboardViewsModule } from './views/views.module';
import { DashboardWorkflowsMenuMainComponent } from './workflows-menu/main.component';


@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        HelpersModule,
        LabelsModule,
        DashboardSearchModule,
        DashboardViewsModule,
        AppHelpersSearchBarModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        DashboardMainComponent,
        DashboardWorkflowsMenuMainComponent
    ],
    exports: [
        DashboardMainComponent,
        DashboardWorkflowsMenuMainComponent
    ],

})
export class DashboardModule { }
