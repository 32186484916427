import { Component, Input, } from '@angular/core';
import { Label } from '@core/objects';

@Component({
    selector: 'core-labels-text',
    templateUrl: './labels-text.component.html',
    styleUrls: ['./labels-text.component.scss'],
    providers: []
})
export class LabelsTextComponent {
    @Input() labels: Array<Label>;
}
