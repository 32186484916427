import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { UrlService } from '@core/services/url/url.service';
import { Session } from '@app/store/models/session.model';
import { WindowService } from '@core/services/window.service';


import * as fromAppState from '@app/store/state/app/app.state';
import * as fromAppActions from '@app/store/state/app/app.actions';
import { AppStatus } from 'app/store/state/app/app.state';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    static readonly HTTP_ERROR_NOT_LOGGED = 401;
    static readonly HTTP_ERROR_TOO_MANY_CONNECTIONS = 509;

    protected httpError =  new EventEmitter<number>();

    //protected logged =  new EventEmitter<boolean>();
    protected disabled = new EventEmitter<boolean>();

    protected version = new EventEmitter<string>();


    constructor(private router:Router, private urlSrv : UrlService, private store: Store<fromAppState.App>, protected http: HttpClient) {
        this.urlSrv.setPrefix("/web/app");
    }

    public init() {
        this.store.dispatch(new fromAppActions.LoadSessionRequest());
    }

    public getSession() {
        return this.store.select(fromAppState.appSessionSelector);
    }

    // public getOrganization = () : Observable<number> => this.store.select(fromAppState.appSessionOrganizationSelector);

    public selectApp() {
        return this.store.select(fromAppState.appSelector);
    }

    public setHttpError(code: number) {
        this.httpError.emit(code);
    }

    public getHttpError() {
        return this.httpError;
    }

    public changeOrganization(newOrg) {
        this.store.dispatch(new fromAppActions.ChangeOrganization(newOrg));
    }

    // @deprecated
    // public setLogged(logged: boolean) {
    //     this.logged.emit(logged);
    // }
    //
    // public getLogged() {
    //     return this.logged;
    // }

    public setDisable(disabled: boolean){
        this.disabled.emit(disabled);
    }

    public getDisable(){
        return this.disabled;
    }

    // Version
    public getVersion(){
        return this.store.select(fromAppState.appVersionSelector);
    }

    public setVersion(version: string){
        this.store.dispatch(new fromAppActions.SetVersion(version));
    }

    public getStatus() : Observable<AppStatus> {
        return this.store.select(fromAppState.appStatusSelector);
    }

    public disconnect() {
        this.store.dispatch(new fromAppActions.Disconnected());
    }

    public updateUser(name: string,telephone: string, position:string){
        this.store.dispatch(new fromAppActions.UpdateUser(name,telephone,position));
    }

    public resetPassword(){
        return this.http.get(this.urlSrv.build("/password"));
    }

}
