import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { VersionsService } from '@app/services/app/versions.service';


import * as Objects from '@core/objects';
import { AppService } from '@app/services/app/app.service';
import { VersionFeedback } from '@core/models/version-feedback';


@Component({
    selector: 'versions-details-content-feedbacks',
    templateUrl: './feedbacks.component.html',
    styleUrls: ['./feedbacks.component.scss']
})
export class VersionsDetailsContentFeedbacksComponent implements OnInit, OnChanges{

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    @Input() version: Objects.Version;
    @Input() feedbacks: Array<VersionFeedback>;
    @Input() user: Objects.User = null; //current user.

    public status: number = 0;

    public feedbacksList: Array<VersionFeedback> = null;



    constructor (
        public appSrv: AppService,
        private router: Router
    ){}

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.feedbacks){
            this.feedbacksList = this.feedbacks;
        }
    }

    openFeedback(feedback: VersionFeedback): void {
        this.router.navigate(['/p/feedback/versions/'+this.version.id + "/users/"+feedback.user.id]);
    }


}
