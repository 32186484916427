import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';

@Component({
    selector: 'core-versions-related-group-item-list',
    templateUrl: './related-group-item-list.component.html',
    styleUrls: ['./related-group-item-list.component.scss']
})
export class VersionsRelatedGroupItemListComponent implements OnInit {

    @Input() related: Objects.Related;
    @Input() project: Objects.Request;
    @Input() opened: boolean = false;
    @Output() onClickOpen = new EventEmitter<boolean>();

    // public opened: boolean = true;
    public firstOpened: boolean = false; // ??
    public groupLabels: Array<Objects.Label> = [];

    constructor(private httpClient: HttpClient, private urlSrv: UrlService ) { }

    ngOnInit() {
        this.groupLabels = this.related.labels.filter((label: Objects.Label) => !this.project.labels.some((l) => l.id == label.id));
    }

    openVersions() {
        this.firstOpened = true;
        this.opened = !this.opened;
        this.onClickOpen.emit(this.opened);
    }


}
