import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { VersionsService } from '@app/services/app/versions.service';
import { AppRouterService } from '@app/services/app/router.service';
import { AppService } from 'app/services/app/app.service';

import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { VersionListItemMenuClick } from '@core/components/versions/list/version-list-item-menu-click';

@Component({
    selector: 'project-add-origin-version',
    templateUrl: './origin-version.component.html',
    styleUrls: ['./origin-version.component.scss']
})
export class ProjectAddOriginVersionComponent implements OnInit, OnDestroy {
    protected readonly ACCESS_REQUEST_FEEDBACK = 'share_feedback';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    @Input() version: Objects.Version = null;
    
    public feedbackPluginAccess: boolean = false;
    public feedbacks: Array<VersionFeedback> = [];
    
    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "See Artwork Details",
            key: "details",
            icon: "see-details"
        }
    ];


    public status: number = this.STATUS_LOADING;
    protected subscriptor: Subscription;
    protected feedbacksSubscriptor: Subscription; 

	constructor(private appRouter: AppRouterService,private app: AppService,private versionsSrv: VersionsService){}

	ngOnInit(){
        // get plugins access
        this.subscriptor = this.app.getSession().subscribe( (session: any) => {
            this.feedbackPluginAccess = session.options.plugins.some((pa) => pa == this.ACCESS_REQUEST_FEEDBACK);
            this.initView();
        });

        this.initView();

        
	}

    ngOnDestroy(): void {
        this.subscriptor.unsubscribe();
        if(this.feedbacksSubscriptor)
            this.feedbacksSubscriptor.unsubscribe();
    }

    initView(){
        // feedback needs an array of versions!!
        if(this.version != null)
            this.loadFeedbacks([this.version]);
    }

    public getFeedbacks(version: Objects.Version){
        return this.feedbacks.filter((f: VersionFeedback) => f.version.id == version.id);
    }

    onMenuClick(menu: VersionListItemMenuClick) {
        // console.debug(" menu original click",menu.key);
        switch(menu.key) {
            case "details":
                // TODO navigate without clean add project form
                // url to go to Artwork Details
                this.appRouter.navigate("/versions/"+this.version.id);
                break;
        }
    }

    onFileClick(file: Objects.File){
        if (file != null){
            // url to preview Artwork FILE
            this.appRouter.navigate("/versions/"+this.version.id + "/files/"+file.id);
        }
    }
    
    protected loadFeedbacks(versions: Array<Objects.Version>) {
        if(!this.feedbackPluginAccess) {
            this.status = this.STATUS_DONE;
            return;
        }

        this.feedbacksSubscriptor = this.versionsSrv.getFeedbacks$(versions).subscribe((feedbacks: Array<VersionFeedback>) => {
                this.feedbacks = feedbacks;
                this.status = this.STATUS_DONE;
        });
    }

}
