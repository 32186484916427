import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as Search from '@app/models/search';

@Component({
    selector: 'app-helpers-search-bar-fields-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})
export class HelpersSearchBarFieldsStatusComponent implements OnInit {

    @Input() filter: Search.SearchAppliedFilter;
    @Input() style: string = '';
    @Output() remove = new EventEmitter<Search.SearchAppliedFilter>();

    public type: string = '';

	ngOnInit(){
        if(this.filter.item.hasOwnProperty('workflow')){
            this.type = 'project';
        } else {
            this.type = 'version';
        }
    }

    onRemove(filter: Search.SearchAppliedFilter){
        this.remove.emit(filter);
    }


}
