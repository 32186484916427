import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UrlService } from '../url/url.service';
import { Note } from '../../objects/note';
import { File } from '../../objects/file';

@Injectable({
    providedIn: 'root'
})

export class NotesService {

    public addNoteControl:boolean = false;
    public displayNote:boolean = false;

    constructor(public urlSrv: UrlService, public http: HttpClient){}

    // setDisplayNote(data){
    //     this.displayNote = data;
    // }

    // addNote(fileId, data){
    //     return this.http.post(this.urlService.build("/notes/"+fileId+"/add"), data).subscribe(data => {
    //         console.log(data);
    //     });
    // }

    // getNotes(fileId, page){
    //     return this.http.get(this.urlService.build("/notes/"+fileId+"/"+page));
    // }

    // deleteNote(idNote){
    //     return this.http.get(this.urlService.build("/note/"+idNote+"/disable"));
    // }

    find(file: File, urlPrefix: string = "") {
        return this.http.get(this.urlSrv.build(urlPrefix + "/files/" + file.id+"/notes"));
    }

    save(file: File, notes: Array<Note>, urlPrefix: string = "") {

        let options = {headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};

        return this.http.post(
            this.urlSrv.build(urlPrefix + "/files/"+file.id+"/notes"), 
            notes, 
            options
        );
    }

    findByMultipleFiles(files: Array<File>, urlPrefix: string = "") {
        console.log("fileeesss", files);

        let fileIds = files.map((f: File) => f.id).join(',');
        console.log("joined", fileIds);
        return this.http.get(this.urlSrv.build(urlPrefix + "/notes/" + fileIds));
    }

    // remove(note: Note) {
    //     return this.http.delete(this.urlSrv.build("/notes/" + note.id));
    // }
}
