import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
//import { map } from 'rxjs/operators';

import { UrlService } from '@core/services/url/url.service';

import { AvailableRuleItem, AvailableRuleActionVariable, AvailableRuleEventCondition, AvailableRuleAction, RuleEvent, RuleListItem } from '@app/models/rules';

@Injectable({
  providedIn: 'root'
})
export class SettingsRulesRequests {
    private readonly availablePrefix = "/settings/rules";
	private readonly prefix = "/rules";

    constructor(private http: HttpClient, private urlSrv : UrlService) {}

	public loadAvailableEvents$ = () : Observable<AvailableRuleItem[]> =>
		this.http.get<AvailableRuleItem[]>(this.urlSrv.build(this.availablePrefix+"/events"));
	
	public loadAvailableConditions$ = (eventKey: string) : Observable<AvailableRuleEventCondition[]> =>
		this.http.get<AvailableRuleEventCondition[]>(this.urlSrv.build(this.availablePrefix+"/events/"+eventKey+"/conditions"));
	
	public loadAvailableActions$ = (eventKey: string) : Observable<AvailableRuleAction[]> =>
		this.http.get<AvailableRuleAction[]>(this.urlSrv.build(this.availablePrefix+"/events/"+eventKey+"/actions"));
	
	public loadRules$ = () : Observable<RuleListItem[]> =>
		this.http.get<RuleListItem[]>(this.urlSrv.build(this.prefix+"/events"));

	public setActiveRule$ = (ruleId: number, active: boolean) : Observable<any> =>
		this.http.put<any>(this.urlSrv.build(this.prefix+"/events/"+ruleId), {active: active});
	
	public loadRule$ = (ruleId: number) : Observable<RuleEvent> =>
		this.http.get<RuleEvent>(this.urlSrv.build(this.prefix+"/events/"+ruleId));
	
	public updateRule$ = (ruleId: number, rule: RuleEvent) : Observable<RuleEvent> =>
		this.http.put<RuleEvent>(this.urlSrv.build(this.prefix+"/events/" + ruleId ), rule, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });

	public createRule$ = (rule: RuleEvent) : Observable<RuleEvent> =>
		this.http.post<RuleEvent>(this.urlSrv.build(this.prefix+"/events"), rule, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });

	// public setDeactiveRule = (ruleId: number) : Observable<any> =>
	// 	this.http.delete<any>(this.urlSrv.build(this.prefix+"/events/"+ruleId));

	// public loadAllUsers$ = () : Observable <User[]> =>
	// 	// this.http.get<SettingsUser[]>(this.urlSrv.build(this.users));
	// 	this.http.get<User[]>(this.urlSrv.build(this.));

	// public loadAllInvitations$ = () : Observable <UserRequest[]> =>
	// 	this.http.get<UserRequest[]>(this.urlSrv.build(this.users + "/invitations"));

	// public loadOneUser$ = ( user: User ) : Observable <User> =>
	// 	this.http.get<User>(this.urlSrv.build(this.users + "/" + user.id));

	// public addNewInvitation$ = ( name:string, email:string, message:string, groups:Array<number>, extra: any) : Observable<string> => {
	// 	let data = {
	// 		name: name,
	// 		email: email,
	// 		message: message,
	// 		groups: groups
	// 	}

    //     data = {...data, ...extra};
	// 	return this.http.post(this.urlSrv.build(this.users + "/invitations"), data, {responseType: 'text'});
	// }

	// public deleteOneUser$ = ( id:string ) : Observable <string> => {
	// 	return this.http.delete(this.urlSrv.build(this.users + "/" + id), {responseType: 'text'});
	// }

	// public deleteInvitation$ = ( email:string ) : Observable <string> => {
	// 	return this.http.delete(this.urlSrv.build(this.users + "/invitations/" + email), {responseType: 'text'});
	// }
}
