import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { VersionsViewService } from '@app/services/app/versions.service';
import { AppRouterService } from '@app/services/app/router.service';
import { AppService } from '@app/services/app/app.service';

import * as Objects from '@core/objects';
import { TitleService } from '@core/services/title.service';
import { UrlService } from '@core/services/url/url.service';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';
import { VersionsService } from '@core/services/versions/versions.service';


@Component({
    selector: 'versions-related-file-preview',
    templateUrl: './related-file-preview.component.html',
    styleUrls: ['./related-file-preview.component.scss']
})
export class VersionsRelatedFilePreviewComponent implements OnInit, OnDestroy {
    protected readonly PLUGIN_SEND_FILES = 'share_transfer';
    protected readonly PLUGIN_APOLLO = 'apollo';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_ERROR = 2;
    public readonly STATUS_NOT_FOUND= 3;
    public readonly STATUS_NOT_ALLOWED= 4;

    public version: Objects.Version = null;
    public versionRelated: Objects.Version = null;
    public file: Objects.File = null;
    public pluginsAccess: Array<string> = [];
    public originProject: Objects.Request = null;

    public context: string = '';
    private relatedId: number = null;
    private fileId: number = null;
    public breadcrumbs: any = [
    ];

    public status:number = this.STATUS_LOADING;    
    public menu: Array<ThreeDotsMenuItem> = [{
        title: "Download File",
        key: "download",
        icon: "download",
        order: 1
    }];


    protected menuSendFiles: ThreeDotsMenuItem = {
        title: "Send",
        key: "send-files",
        icon: "send-files",
        order: 2
    };
    protected menuCompare: ThreeDotsMenuItem = {
        title: "Compare",
        key: "compare",
        icon: "compare",
        order: 3
    };


    protected urlSubscriptor: Subscription;
    protected versionSubscriptor: Subscription;
    protected versionRelatedSubscriptor: Subscription;
    protected appSubscriptor: Subscription;
    protected originProjectSubscriptor: Subscription;
    protected errorSubscriptor: Subscription;

    constructor(private title: TitleService,
        private app: AppService,
        private urlSrv: UrlService,
        private appRouter: AppRouterService,
        private activeRoute: ActivatedRoute,
        private viewVersionsSrv: VersionsViewService,
        private versionsSrv: VersionsService) { }

    ngOnInit() {
        this.setDefaultBredcrumb();
        this.title.set("Preview File... - Artwork...");

        this.urlSubscriptor = this.activeRoute.params.subscribe((params: any) => {
            console.debug(" RELATED FILE PREVIEW params: ",params)
            if(params.fid)
                this.fileId = +params.fid;
            if(params.rvid){
                this.relatedId = +params.rvid;
            }
        });

        // get the version
        this.versionSubscriptor = this.viewVersionsSrv.selectVersion().subscribe((version: Objects.Version) =>
        {
            if(version != null){
                this.version = version;
                // load realted all versions for the version in store
                this.viewVersionsSrv.loadRelated(this.version.labels.map((l: Objects.Label) => l.id).sort().join('-'));
            }
        });

        this.originProjectSubscriptor = this.viewVersionsSrv.selectProject().subscribe((projectOrigin: Objects.Request) => {
            this.originProject = projectOrigin;
        });

        this.versionRelatedSubscriptor = this.viewVersionsSrv.selectRelatedVersions().subscribe((relateds: Objects.Version[]) => {
            if(relateds != null && this.relatedId != null){
                let vr = relateds.filter((v: Objects.Version) => v.id == this.relatedId);
                if (vr.length > 0){
                    this.versionRelated = vr[0];
                    this.versionsSrv.getFiles$(this.versionRelated).subscribe((files: Array<Objects.File>) => {
                    if(files.length > 0 && this.fileId != null){
                        let match = files.filter((f: Objects.File) => {return f.id == this.fileId;});
                        if (match.length > 0 ){
                            this.file = match[0];
                        } else {
                            this.status = this.STATUS_NOT_FOUND;
                        }
                        this.initView();
                    }
                })
                }
               
            }

            this.initView();
        });
        this.appSubscriptor = this.app.getSession().subscribe( (session: any) => {
            this.pluginsAccess = session.options.plugins;
            this.updateMenu(this.menuSendFiles,this.checkPluginAccess(this.PLUGIN_SEND_FILES))
            this.updateMenu(this.menuCompare,this.checkPluginAccess(this.PLUGIN_APOLLO));
        });


    }

    ngOnDestroy() {
        this.urlSubscriptor.unsubscribe();
        this.versionSubscriptor.unsubscribe();
        this.appSubscriptor.unsubscribe();
        this.versionRelatedSubscriptor.unsubscribe()
        this.originProjectSubscriptor.unsubscribe();
    }

    onMenuClick(menu: FilesListItemMenuClick) {
        switch(menu.key) {
            case "download":
                window.location.href = this.urlSrv.build('/files/'+menu.file.id);
                break;
            case "send-files":
                if (this.checkPluginAccess(this.PLUGIN_SEND_FILES) && this.originProject != null){
                        this.appRouter.navigate('/p/transfer/'+this.originProject.id, { queryParams: { file: +menu.file.id} });
                }
                break;
            case "compare":
                if (this.checkPluginAccess(this.PLUGIN_APOLLO) && this.originProject != null)
                    this.appRouter.navigate('/p/apollo/request/'+this.originProject.id);
                break;
        }
    }

    protected checkPluginAccess(plugin: string){
        return this.pluginsAccess.some((pa) => pa == plugin);
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

    private initView() {
        if(this.version == null || this.versionRelated == null || this.file == null)
            return;

        this.title.set("Preview File "+this.file.name+ " - Artwork #"+ this.versionRelated.id);
        this.updateBreadcrumbs();
        this.status = this.STATUS_DONE;
    }

    protected updateBreadcrumbs(){ // update intermediate breadcrumb depending on context
        switch (this.context) {
            case "other":
                break;
            default:
                this.breadcrumbs[0] = {link: "/versions/"+this.version.id,
                    title: 'Artwork v.'+this.version.version+' <small>(#'+this.version.id+')</small>'};
                this.breadcrumbs[1] = {link: "/versions/"+this.version.id+"/related",
                    title: 'OTHER ARTWORKS OF v.'+this.version.version };
                break;
        }
    }    

    protected setDefaultBredcrumb(){
        this.breadcrumbs[0] = {link: '',
                            title: 'Artwork v. ...  <small>(# .  .  . )</small>'};
        this.breadcrumbs[1] = {link: "",
                            title: 'OTHER ARTWORKS OF v. ...' };
    }

}
