import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { AccessToken } from '@core/objects/access-token';

@Injectable({
    providedIn: 'root'
})
export class AccessTokensService {

    constructor(protected url: UrlService, protected http: HttpClient) {}

    getAccessTokens$(prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/settings/access-tokens"));
    }

    getAccessToken$(accessToken: AccessToken, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/settings/access-tokens/" + accessToken.id));
    }

    deleteAccessToken$(accessToken: AccessToken, prefixUrl: string = "") {
        return this.http
        .delete(this.url.build(prefixUrl+"/settings/access-tokens/" + accessToken.id ));
    }

    addAccessToken$(name: string, readonly: boolean, prefixUrl: string = "") {
        let options = {headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};
        let tokenData: any = {
            name: name,
            readonly: readonly
        };
        return this.http.post(
            this.url.build(prefixUrl+"/settings/access-tokens"),
            tokenData,
            options
        );
    }
}
