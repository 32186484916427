import { HostListener, Component, EventEmitter, Input, Output, OnChanges, SimpleChanges  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { share } from 'rxjs/operators';

import { UrlService } from '@core/services/url/url.service';
import { User } from '@core/objects/user';

@Component({
    selector: 'app-helpers-forms-select-users-autocomplete',
    templateUrl: './select-users-autocomplete.component.html',
    styleUrls: ['./select-users-autocomplete.component.scss'],
    providers: []
})
export class HelpersFormSelectUsersAutocompleteComponent implements OnChanges {

    @Input()  term: string = "";
    @Output() select = new EventEmitter<User>();

    //protected autocomplete$: Observable<any> = null;
    public users: Array<User> = [];
    public openEmails: boolean = false;

    private wasInside: boolean = false;
    public loading: boolean = false;


    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }
  
    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.openEmails = false;
        }
        this.wasInside = false;
    }

    constructor(private httpClient: HttpClient, private url: UrlService) {
        // this.autocomplete$ = this.httpClient
        //   .get(this.url.build('/propos/users'), {params: {email: this.input}})
        //   .pipe(share());
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.term) {
            if(typeof changes.term.currentValue == 'undefined' || changes.term.currentValue == ""){
                this.users = [];
            } else {
                if(changes.term.currentValue.length > 2)
                    this.onAutocomplete();
            }
        }
    }

    protected onAutocomplete() {
        if(this.loading)
            return;
        this.loading = true;
        this.httpClient
          .get(this.url.build('/propos/users'), {params: {email: this.term}})
          .pipe(share()).subscribe((data: Array<User>) => {
            this.users = data;
            this.openEmails = true;
            this.loading = false;
        } );
    }

    onSelect(user: User) {
        this.select.emit(user);
    }




}
