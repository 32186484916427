import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
// import { CommonsFilesListCheckboxComponent } from '@core/components/commons/files-list-checkbox/files-list-checkbox.component';
import { ThreeDotsMenuItem } from '../three-dots-menu-item';

@Component({
    selector: 'core-helpers-navigation-three-dots-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class HelperNavigationThreeDotsMenuComponent {
    // @Input() session: any;
    @Input()  menu: Array<ThreeDotsMenuItem> = [];

    @Output() action = new EventEmitter<string>();

    public open: boolean = false;
    private wasInside: boolean = false;

    @HostListener('click')
    clickInside() {
        // console.log("click inside")
        this.wasInside = true;
    }
  
    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.open = false;
        }
        // console.log("click outside")
        this.wasInside = false;
    }

    // ngOnInit(){

    // }

    onClick(key: string) {
        this.action.emit(key);
        this.open = false;
    }

}
