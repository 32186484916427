import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';

@Component({
    selector: 'core-commons-files-list-checkbox',
    templateUrl: './files-list-checkbox.component.html',
    styleUrls: ['./files-list-checkbox.component.scss']
})
export class CommonsFilesListCheckboxComponent implements OnInit, OnChanges {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly ERROR = 2;

    @Input() url: string;
    @Input() counter: boolean = true; // has counter
    @Input() counterPluralTitle: string = "files";
    @Input() counterSingularTitle: string = "file";
    @Input() maxSelected: number = 0; // Max files can be selected (0 = unlimited)
    @Input() disabled: boolean; // TODO

    @Input()  selected: Array<File>;
    @Output() selectedChange = new EventEmitter<Array<File>>();

    // some file is selected
    @Input()  checked: boolean = false;
    @Output() checkedChange = new EventEmitter<boolean>();

    //protected selectedObservable: Observable<Array<File>>;

    public status = this.LOADING;
    public files: Array<File> = [];

    constructor(private httpClient: HttpClient, private urlSrv: UrlService ) { }

    ngOnInit() {
        this.loadFiles();
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.selected) {
            if(!changes.selected.firstChange && changes.selected.currentValue.length != changes.selected.previousValue.length){
                setTimeout(() => {
                    // this.checkedChange.emit(this.isChecked());
                    this.updateChecked();
                }, 100);
           }

        }
    }

    private loadFiles() {
        this.httpClient.get(this.urlSrv.build(this.url)).subscribe(
            (data:Array<File>) => {
                this.files = data;
                this.status = this.DONE;
                this.updateChecked();
            },
            (error:any) => {
                this.status = this.ERROR;
            }
        );
    }

    onChange(files: Array<File>) {
        this.selected = files;
        this.selectedChange.emit(this.selected);

        this.updateChecked();
    }

    protected updateChecked() {
        this.checked = this.isChecked();
        this.checkedChange.emit(this.checked);
    }

    protected isChecked(): boolean {
        return this.getSelectedFiles().length>0;
    }

    protected getSelectedFiles(): Array<File> {
        return this.files.filter(f => this.selected.some(s => f.id == s.id));
    }

}
