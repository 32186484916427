import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '@core/objects/user';
import { UserRequest } from '@core/objects/user-request';
import { HttpStatus } from '@core/classes/http-status.class';

import { Settings } from '../settings.state';

export class SettingsUsersStatus {
    list: HttpStatus = new HttpStatus();
    invite: HttpStatus = new HttpStatus();
    addInvite:HttpStatus = new HttpStatus();
    delInvite: HttpStatus = new HttpStatus();
    delUser: HttpStatus = new HttpStatus();
};

export interface SettingsUsers {
    status: SettingsUsersStatus;
    users: Array<User>;
    invitations: Array<UserRequest>;
}

export const initialState: SettingsUsers = {
    status: new SettingsUsersStatus,
    users: [],
    invitations: []
};

// const settingsUsersFeatureSelector = createFeatureSelector('users');

const settingsFeatureSelector = createFeatureSelector('settings');

const settingsUsersFeatureSelector = createSelector(
    settingsFeatureSelector,
    (state: Settings) => state.users
);

// Selectors
export const usersGetAllUsersSelector = createSelector(
    settingsUsersFeatureSelector,
    (state: SettingsUsers) => state.users
);

export const usersGetAllInvitationsSelector = createSelector(
    settingsUsersFeatureSelector,
    (state: SettingsUsers) => state.invitations
);

// Status
export const usersStatusListSelector = createSelector(
    settingsUsersFeatureSelector,
    (state: SettingsUsers) => state.status.list
);

export const usersStatusInviteSelector = createSelector(
    settingsUsersFeatureSelector,
    (state: SettingsUsers) => state.status.invite
);

export const usersStatusAddInviteSelector = createSelector(
    settingsUsersFeatureSelector,
    (state: SettingsUsers) => state.status.addInvite
);

export const usersStatusDelInviteSelector = createSelector(
    settingsUsersFeatureSelector,
    (state: SettingsUsers) => state.status.delInvite
);

export const usersStatusDelUserSelector = createSelector(
    settingsUsersFeatureSelector,
    (state: SettingsUsers) => state.status.delUser
);
