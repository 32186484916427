import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LabelsModule } from '@core/components/labels/labels.module';

import { AppRouterModule } from '@app/router.module';

import { ProjectDetailsInfoMainComponent } from './main/main.component';
import { ProjectDetailsInfoOriginVersionComponent } from './origin-version/origin-version.component';

// import { ProjectDetailsInfoAttachmentsComponent } from './attachments/attachments.component';
import { ProjectsDetailsInfoAttachmentModule } from './attachments/attachments.module';

import { AppVersionsModule } from '../../../versions/versions.module';
import { ProjectsDetailsInfoFeaturesModule } from './features/features.module';
import { ProjectDetailsInfoEditLabelsComponent } from './edit-labels/edit-labels.component';
import { AppHelperLabelsModule } from '@app/components/helpers/labels/labels.module';
import { ProjectDetailsInfoHeaderComponent } from './header/header.component';
import { ProjectDetailsInfoDetailsComponent } from './details/details.component';

@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        HelpersModule,
        LabelsModule,
        AppHelperLabelsModule,
        AppVersionsModule,
        ProjectsDetailsInfoAttachmentModule,
        ProjectsDetailsInfoFeaturesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsInfoMainComponent,
        ProjectDetailsInfoOriginVersionComponent,
        ProjectDetailsInfoEditLabelsComponent,
        ProjectDetailsInfoHeaderComponent,
        ProjectDetailsInfoDetailsComponent
        
    ],
    exports: [
        ProjectDetailsInfoMainComponent,
        ProjectDetailsInfoHeaderComponent,
        ProjectDetailsInfoDetailsComponent,
        ProjectsDetailsInfoFeaturesModule
    ],

})
export class ProjectsDetailsInfoModule { }
