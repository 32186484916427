import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';

import * as fromLabelsState from '@app/store/state/labels/labels.state';
import * as fromLabelsActions from '@app/store/state/labels/labels.actions';

@Injectable({
    providedIn: 'root'
})
export class LabelsService {

    constructor(private url : UrlService, private store: Store<fromLabelsState.Labels>, private actions$: Actions,  protected http: HttpClient) {}

    search = (input: string) => this.store.dispatch(new fromLabelsActions.SearchLabels(input));

    selectSearch = (): Observable<Objects.Label[]> => this.store.select(fromLabelsState.labelsSelector);

    loadCategories = () => this.store.dispatch(new fromLabelsActions.LoadCategories());

    selectCategories = (): Observable<Objects.LabelsGroup[]> => this.store.select(fromLabelsState.categoriesSelector);

    loadAccess = () => this.store.dispatch(new fromLabelsActions.LoadAccess());

    selectAccess = (): Observable<Access.AccessLabels> => this.store.select(fromLabelsState.accessSelector);

    add = (name: string, category: Objects.LabelsGroup) => this.store.dispatch(new fromLabelsActions.AddLabel(name, category));
}
