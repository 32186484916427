import { EventTitle } from '@core/objects';

// TODO: complete list of correct events and texts
export const projectLogbookEventKeys : EventTitle[] = [
    {key: 'requests.added', title:'Project was created'},
    {key: 'requests.labels.added', title: 'Tag added'},
    {key: 'requests.labels.removed', title: 'Tag removed'},
    {key: 'requests.files.added', title: 'File added'},
    {key: 'requests.files.removed', title: 'File removed'},
    {key: 'requests.status.updated', title: 'Project Stage Change'},
    {key: 'requests.due_date.updated', title: 'Due date has been set'},
    {key: 'requests.assigned.updated', title: 'Project has been assigned'},
    {key: 'requests.messages.added', title: 'Message added'},
    {key: 'requests.messages.removed', title: 'Message removed'},
    {key: 'requests.versions.added', title: 'Artwork uploaded'},
    // versions only but related to the request UPLOADED versions
    {key: 'versions.labels.added', title: 'Tag added to artwork'},
    {key: 'versions.labels.removed', title: 'Tag removed from artwork'},  
    {key: 'versions.status.updated', title: 'Artwork Status Change'},
    {key: 'versions.messages.added', title: 'Message added to artwork'},
    {key: 'versions.messages.removed', title: 'Message removed from artwork'},
    // maybe use this instead the share one
    // {key: 'versions.approvals.added',title: 'Feedback requested'},
    {key: 'versions.approvals.updated', title: 'Artwork Approved/Rejected'},
    
    // feedbacks
    {key: 'share.feedbacks.entries.added', title: 'Feedback requested'},

]