import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';

import * as fromState from '@app/store/state/versions/versions.state';
import * as fromActions from '@app/store/state/versions/versions.actions';

@Injectable({
    providedIn: 'root'
})
export class VersionsViewService {

    constructor(
        private store: Store<fromState.Versions>,
        private actions$: Actions
    ) {}

    load = (id: number) => this.store.dispatch( new fromActions.LoadVersion(id));

    loadRelated = (link: string) => this.store.dispatch( new fromActions.LoadRelatedVersions(link));

    clear = () => this.store.dispatch( new fromActions.Clear());

    update = (id: number) => this.store.dispatch(new fromActions.UpdateVersion(id));
    
    // when leave a version file preview we need to clean version files to open a new one
    // because files of a previous version remain load when a new version is still loading
    // update 4th may 2022 ==> we need it to clear files when version loaded is updatd to a status without access
    clearFiles = () => this.store.dispatch( new fromActions.ClearFiles());

    selectError = (key: string): Observable<HttpErrorResponse> => this.store.select(fromState.errorSelector(key));

    selectVersion = (): Observable<Objects.Version> => this.store.select(fromState.versionSelector);
    
    selectFiles = (): Observable<Objects.File[]> => this.store.select(fromState.versionFilesSelector);

    selectRelatedVersions = (): Observable<Objects.Version[]> => this.store.select(fromState.versionRelatedsSelector);
    
    loadMessages = () => this.store.dispatch( new fromActions.LoadVersionMessages());

    loadComments = () => this.store.dispatch( new fromActions.LoadVersionComments());

    selectMessages = (): Observable<Objects.Message[]> => this.store.select(fromState.versionMessagesSelector);

    selectComments = (): Observable<Objects.VersionMessage[]> => this.store.select(fromState.versionCommentsSelector);

    selectProject = (): Observable<Objects.Request> => this.store.select(fromState.versionProjectSelector);

    setStatus = (status: Objects.Status) => this.store.dispatch( new fromActions.SetVersionStatus(status));

    setLabels = (labels: Array<Objects.Label>) => {
        if(labels.length == 0)
            return;
        this.store.dispatch( new fromActions.SetVersionLabels(labels));
    }
}

@Injectable({
    providedIn: 'root'
})
export class VersionsEditorService {

    constructor(
        private store: Store<fromState.Versions>,
        private actions$: Actions
    ) {}

    selectAccessAll = (): Observable<Access.AccessVersion> => this.store.select(fromState.editorAccessAllSelector);

    selectAccess = (key: string): Observable<boolean> => this.store.select(fromState.editorAccessSelector(key));
}

@Injectable({
    providedIn: 'root'
})
export class VersionsService {

    constructor(
        protected url: UrlService, 
        protected http: HttpClient,
    ) {}

    getById$(id: string | number, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/versions/" + id));
    }

    getHeadersById$(id: string | number, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/versions/" + id + "/headers"));
    }

    getVersionsGroup$(groupLink: string,prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/versions"),{params: {link: groupLink}});
    }

    getVersionShareFeedback$(version:Objects.Version, user: Objects.User, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/p/feedback/versions/"+version.id+"/users/"+user.id));
    }
    
    getFeedbacks$(versions: Array<Objects.Version>, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/p/feedback/versions/feedbacks/"+versions.map((v: Objects.Version) => v.id).join('-')));
    }

    giveFeedback$(version:Objects.Version, shareEntryHash: string, message: string, approve: boolean, pass: string = null, prefixUrl: string = "") {
        let options = {headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};

        let data: any = {
            message: message,
            status: approve? 1 : 2
        };
        if (pass != null){
            data.password = pass;
        }

        return this.http.post(
            // this.url.build("/versions/"+version.id+"/approvals"),
            "/web/share/p/feedback/"+shareEntryHash+"/versions/"+version.id+"/approvals",
            data,
            options
        );
    }

    // for version add because we have no version load in the store
    getAllStatus$(prefixUrl: string = "") {
        return this.http
            .get(this.url.build(prefixUrl+"/versions/status"));
    }
    // status to update
    getNextStatus$(id: string | number, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/versions/"+ id + "/status-next"));
    }

    setVersionsGroupLabels$(versions: Objects.Version[],labels: Objects.Label[], prefixUrl: string = "") {
        return this.http
        .put(this.url.build(prefixUrl+"/versions/labels/"+versions.map((v: Objects.Version) => v.id).join('-')),{labels: labels.map((l: Objects.Label) => l.id)});
    }

    // @deprecated
    getProject$(version: Objects.Version, prefixUrl: string = "") {
        return this.http
        .get<Objects.Request>(this.url.build(prefixUrl+"/versions/" + version.id + "/request"));
    }
    
    // setStatus$(version: Objects.Version, status: Objects.Status, prefixUrl: string = "") {
    //     return this.http.put(
    //         this.url.build(prefixUrl+"/versions/"+version.id),
    //         {status: status.id}
    //     );
    // }



    loadFeedbackMessages$(version: Objects.Version, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/p/feedback/versions/"+version.id+"/messages"));
    }

}
