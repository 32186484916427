import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { SelectItem } from '@core/models/select-item.model';

import { AvailableRuleItem, RuleAction, AvailableRuleActionVariable, AvailableRuleAction } from '@app/models/rules';

@Component({
  selector: 'settings-rules-rule-form-actions-item',
  templateUrl: './actions-item.component.html',
  styleUrls: ['./actions-item.component.scss'],
})
export class SettingsRulesRuleFormActionsItemComponent {
    public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

    @Input() availableActions: Array<AvailableRuleAction> = [];
    @Input() action: RuleAction;
    // @Output() actionChange = new EventEmitter<RuleAction>();
    @Output() clickAdd = new EventEmitter();
    @Output() clickRemove = new EventEmitter();
    // @Output() selectAction = new EventEmitter<RuleAction>();
    @Output() selectAction = new EventEmitter<string>();
    @Output() variablesChange = new EventEmitter<any>();

    public status: number = this.STATUS_LOADING;
    public actions: Array<SelectItem> = []; // actions loaded: do not change until event change
    public actionVariables: Array<any> = []; // varaibles of action loaded: do not change until action change
    public availableActionVariables: Array<AvailableRuleActionVariable> = [];

    constructor(){}

    ngOnInit(){
        this.init();
    }

    init(){
        this.availableActionVariables = this.getAvalaibleActionVariables();
    }

    getCurrentVariable(key: string) {
        let av = this.availableActionVariables.filter((v: AvailableRuleActionVariable) => v.key == key);
        
        if(av.length == 0)
            return {key: "", value: ""};

        let firstValue = av[0].values.length > 0 ? av[0].values[0].key : null;
        
        // find into values the default value if exist
        if(av[0].default !== null && av[0].values.length) {
            let dval = av[0].values.filter((v:{key: string}) => v.key == av[0].default);

            firstValue = dval.length>0? dval[0].key : firstValue;
        }

        // console.log("first valueeee---->", firstValue)

        return {
            key: key,
            value: this.action.parameters.hasOwnProperty(key) ? this.action.parameters[key] : firstValue
        };
    }

    getSelectOptions(): Array<SelectItem> {
        return this.availableActions.map((a: AvailableRuleItem) => {
            return {title: a.description, value: a.key, selected: false};
        });
    }

    onSelectAction(e){
        this.selectAction.emit(e.key);
    }

    addAction() {
        this.clickAdd.emit();
    }

    removeAction() {
        this.clickRemove.emit();
    }

    getAvalaibleActionVariables() : Array<AvailableRuleActionVariable> {
        let actions = this.availableActions.filter((a: AvailableRuleAction) => a.key == this.action.key);
        return actions[0].variables;
    }

    selectVariable(e) {
        this.action.parameters[e.key] = e.value;

        // this.actionChange.emit(this.action);
        // this.selectAction.emit(this.action);
        this.variablesChange.emit(this.action.parameters);
    }

    getActionVariables() {
        // console.log("actions que llegan --->", this.action);
        return Object.keys(this.action.parameters).map(k => {
            return {key: k, value: "" + this.action.parameters[k]}
        });
    }



}
