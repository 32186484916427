import { AvatarModule } from './avatar/avatar.module';
import { CommonsModule } from './commons/commons.module';
import { LayoutModule } from './layout/layout.module';
import { LoaderModule } from './loader/loader.module';
import { MessagesModule } from './messages/messages.module';
//import { ModalModule } from './modals/modal.module';
import { LegacyNotesModule } from './legacy-notes/legacy-notes.module';
import { NotesModule } from './notes/notes.module';
import { PreviewModule } from './preview/preview.module';
import { VersionsModule } from './versions/versions.module';
import { HelpersModule } from './helpers/helpers.module';
import { FilesModule } from './files/files.module';
import { ProjectsModule } from './projects/projects.module';

export const CoreComponents = [
    AvatarModule,
    LayoutModule,
    LoaderModule,
    MessagesModule,
    NotesModule,
    //ModalModule,
    LegacyNotesModule,
    PreviewModule,
    VersionsModule,
    HelpersModule,
    FilesModule,
    ProjectsModule
];
