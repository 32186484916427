import { Directive, Input, OnInit } from '@angular/core';

declare var FreshWidget: any;

@Directive({
  selector: '[coreFreshdeskButton]'
})
export class CoreFreshdeskButtonDirective implements OnInit {

    @Input() user: any = null;

    constructor() {
    }

    ngOnInit() {
        this.setButton();
    }

    protected setScript() {
        var scriptUrl = 'https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js';
        let node = document.createElement('script');
        node.type = 'text/javascript';
        node.src = scriptUrl;
        node.async = true;
        // node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
    }

    protected setButton() {
        console.debug("LOADING------> FRESHDESKKKK");
        let email = this.user==null? "" : this.user.email;

        let queryString = "&widgetType=popup&helpdesk_ticket[requester]="+email+"&formTitle=Help+me+with+Twona!&submitThanks=Thank+you+for+your+feedback!+We+work+hard+to+make+things+awesome%2C+and+you+are+making+the+awesomeness+possible.&responsive=no";

        FreshWidget.init("", {"queryString": queryString, "widgetType": "popup", "buttonType": "text", "buttonText": "Help Me!", "fontFamily" :"Montserrat,sans-serif", "buttonColor": "white", "buttonBg": "#2A42EC", "alignment": "4", "offset": "200px", "submitThanks": "Thank you for your feedback! We work hard to make things awesome, and you are making the awesomeness possible.", "formHeight": "500px", "responsive": "no", "url": "https://twonas.freshdesk.com"} );
    }
}
