import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VersionsModule } from '@core/components/versions/versions.module';
import { FilesModule } from '@core/components/files/files.module';
import { CommonsModule } from '@core/components/commons/commons.module';
// import { ShareModule } from '@core/components/share/share.module';

import { PluginShareTransferSelectFilesSectionComponent } from './select-files/select-files.component';


@NgModule({
    imports: [
      CommonModule,
      VersionsModule,
      FilesModule,
      CommonsModule,
      // ShareModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
      PluginShareTransferSelectFilesSectionComponent
    ],
    exports: [
      PluginShareTransferSelectFilesSectionComponent
    ],
    providers: [
    ]

})
export class ShareTransferSectionsModule { }
