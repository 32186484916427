import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

import { File } from '@core/objects/file';
import { Note } from '@core/objects/note';
import { FilesListItemMenuClick } from '../files-list-item-menu-click';

@Component({
    selector: 'core-files-list-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss']
})
export class FilesListNotesComponent implements OnInit {

    @Input() files: Array<File>;
    @Input() notes: Array<Note> = [];
    @Input() menu: Array<ThreeDotsMenuItem> = [];
    
    @Output() actionClick= new EventEmitter<File>();
    @Output() menuClick = new EventEmitter<FilesListItemMenuClick>();

    ngOnInit() {
    }

    public getNotes(file: File){
        return this.notes.filter((n: Note) => n.file.id == file.id).length;
    }
}
