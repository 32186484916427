import { Component, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'plugin-apollo-helpers-modal-content',
    templateUrl: './modal-content.component.html',
    styleUrls: ['./modal-content.component.scss']
})

export class PluginApolloHelpersModalContentComponent{
	
	@Output() action = new EventEmitter<any>();

	constructor(){}

	actionButton(type){
		this.action.emit(type);
	}
}