import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProjectDetailsMainComponent } from '@app/components/sections/projects/details/main/main.component';
import { ProjectDetailsInfoMainComponent } from '@app/components/sections/projects/details/info/main/main.component';
import { ProjectDetailsMessagesMainComponent } from '@app/components/sections/projects/details/messages/main/main.component';
import { ProjectDetailsVersionsMainComponent } from '@app/components/sections/projects/details/versions/main/main.component';
import { ProjectAddMainComponent } from '@app/components/sections/projects/add/main/main.component';
import { ProjectPreviewNotesMainComponent } from '@app/components/sections/projects/details/preview-notes/main/main.component';
// import { VersionsPreviewFileMainComponent } from '@app/components/sections/versions/content/preview/main/main.component';
import { ProjectDetailsVersionDetailsComponent } from '@app/components/sections/projects/details/sections/version/version.component';
import { ProjectDetailsVersionDetailsFilePreviewComponent } from '@app/components/sections/projects/details/sections/version-file-preview/version-file-preview.component';
import { ProjectDetailsVersionAddComponent } from '@app/components/sections/projects/details/sections/version-add/version-add.component';
import { CanLeaveAddProjectGuard } from '@app/components/sections/projects/add/can-leave/can-leave.guard';
import { ProjectDetailsLogbookMainComponent } from 'app/components/sections/projects/details/logbook/main/main.component';

export const projectRoutes: Routes = [
    {
        path: 'projects/add',
        component: ProjectAddMainComponent,
        canDeactivate: [CanLeaveAddProjectGuard]
    },
    {
        path: 'projects/:id',
        component: ProjectDetailsMainComponent,
        children: [{
                path: '',
                component: ProjectDetailsInfoMainComponent
            },{
                path: 'messages',
                component: ProjectDetailsMessagesMainComponent
            },
            {
                path: 'versions',
                component: ProjectDetailsVersionsMainComponent
            },
            {
                path: 'versions/add',
                component: ProjectDetailsVersionAddComponent
            },
            {
                path: 'files/:fid',
                component: ProjectPreviewNotesMainComponent
            },
            {
                path: 'versions/:vid',
                component: ProjectDetailsVersionDetailsComponent
            },
            {
                path: 'versions/:vid/files/:fid',
                component: ProjectDetailsVersionDetailsFilePreviewComponent
            },
            {
                path: 'logbook',
                component: ProjectDetailsLogbookMainComponent
            },


            
        ]
    },
    
];

@NgModule({
    imports: [
        RouterModule.forRoot(projectRoutes)
    ],
    exports: [RouterModule]
})
export class AppProjectsRouterModule { }
