import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import * as Objects from '@core/objects';
import { ProjectsService } from "@app/services/app/projects.service";
import { VersionsService } from "@app/services/app/versions.service";
import { AddVersion } from "@app/models/add-version";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AddVersionService } from "@app/services/app/add-version.service";

@Component({
    selector: 'versions-add-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class VersionsAddMainComponent implements OnInit, OnDestroy {
    
    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_ERROR = 2;

    @Input() project: Objects.Request;
    @Input() labels: Array<Objects.Label>;
    @Input() editTagsAccess:boolean = false;
    @Input() versionNumber: string = "1.0";

    public status: number = this.STATUS_LOADING;
    public creatingNewVersion: boolean = false;
    public versionSaved: boolean = false;

    public files: Array<Objects.File> = [];
    public form: FormGroup = null;
    public versionLabels: Array<Objects.Label> = [];

    public allVersionStatus: Array<Objects.Status> = null;

    protected statusSuscriber: Subscription;

    // new version default properties
    public addVersionDataDefault: AddVersion = {
        files: [],
        labels: [],
        version: 1,
        subversion: 0,
        status: null
    }
    public addVersionData: AddVersion;

    constructor(
        private location: Location,
        private router: Router,
        private addVersionSrv: AddVersionService,
        private versionsSrv: VersionsService,
        private projectsSrv: ProjectsService,
        private fb: FormBuilder
    ){}
   
    ngOnInit(): void {
        this.addVersionDataDefault.version = +this.versionNumber.split(".")[0];
        this.addVersionDataDefault.subversion = +this.versionNumber.split(".")[1] + 1;

        this.addVersionData = this.addVersionDataDefault;
        this.form = this.fb.group({});
        this.setLabels(this.labels);
        
        this.statusSuscriber = this.versionsSrv.getAllStatus$().subscribe((status: Array<Objects.Status>) => {
            if(status != null){
                this.addVersionData.status = +status[0].id;
            }
            this.allVersionStatus = status;
            this.status = this.STATUS_DONE;
        });
    }

    ngOnDestroy(): void {
        this.statusSuscriber.unsubscribe();
    }


    isVersionReady(){
        // todo check files >0 according rule -> upload version with  no files????
        return (this.addVersionData.labels.length > 0 &&
                this.addVersionData.files.length > 0 && // REVIEW THIS
                this.addVersionData.version != null &&
                this.addVersionData.subversion != null &&
                this.addVersionData.status != null);
    }

    onSubmit(){
        this.creatingNewVersion = true;
        this.form.disable();
        console.log("submit new version with data: ",this.addVersionData);
        this.addVersionSrv.addVersion$(this.addVersionData,'/requests/'+this.project.id).subscribe((version: Objects.Version) =>{
            if (version != null){
                this.creatingNewVersion = false;
                this.projectsSrv.loadUploadedVersions();
                this.router.navigate(["/projects/"+this.project.id+'/versions/'+version.id]);
            }
        })
        this.form.enable();
        this.reset();
    }

    onCancel(){   
        // this.reset();
        this.location.back();
    }

    onSetStatus(status: number) {
        this.onAddVersionChange({status: status});
    }

    addChildForm(name: string, group: FormGroup) {
        this.form.addControl(name, group);
      }

    onAddVersionChange(changes: Partial<AddVersion>){
        this.addVersionData = { ...this.addVersionData, ...changes };
        console.debug(" changes add version ", changes, this.addVersionData)
    }

    setFiles(files: Objects.File[]){
        this.files = files;
        this.onAddVersionChange({files: files.map((f: Objects.File) => {return f.id;})});
    }


    protected setLabels(labels: Array<Objects.Label>){
        // if no labels in links then take project labels
        if(this.project != null && labels.length == 0){
            labels = this.project.labels;
        } 
        this.versionLabels = Object.assign([], labels);
        this.onAddVersionChange({labels: labels.map((l: Objects.Label) => {return l.id;})});
    }

    protected reset(){
        this.form.reset();
        this.addVersionData = this.addVersionDataDefault;
        this.addVersionData.status = +this.allVersionStatus[0].id;
        this.setLabels(this.labels);
        this.setFiles([]);
    }

}