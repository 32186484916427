import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

//import { Subscription } from 'rxjs';

//import { ApolloGraphicLayer } from '../../../model/apollo-graphic-layer.model';
//import { ApolloContentComparison } from '../../../model/apollo-content-comparison.model';
import { ApolloContentComparisonResultMark } from '../../../model/apollo-content-compared-result-mark.model';
import { ApolloFilter } from '../../../model/apollo-filter.model';
import { ApolloService } from '../../../services/apollo/apollo.service';
//import { ApolloService } from '../../../services/apollo/apollo.service';
import { ApolloContentInspector, ApolloContentInspectorChange} from '../../../model/apollo-content-inspector.model';
import { ApolloContentInspectorProcessWord } from '../../../model/apollo-content-inspector-process-words.model';

import { Subscription } from 'rxjs';

import { ApolloContentApprovals, ApolloContentApprovalItem  } from '../../../model/apollo-content-approvals.model';

@Component({
  selector: 'plugin-apollo-helpers-content-inspector',
  templateUrl: './content-inspector.component.html',
  styleUrls: ['./content-inspector.component.scss']
})
export class PluginApolloHelpersContentInspectorComponent implements OnInit, OnChanges {
    readonly MIN_ZOOM = 0.2;
    readonly MAX_ZOOM = 1;

    @ViewChild('figure1', { read: ElementRef }) public figure1: ElementRef<any>;
    @ViewChild('figure2', { read: ElementRef }) public figure2: ElementRef<any>;

    @ViewChild('container', { read: ElementRef }) public container: ElementRef<any>;
    @ViewChild('content1', { read: ElementRef }) public content1: ElementRef<any>;
    @ViewChild('content2', { read: ElementRef }) public content2: ElementRef<any>;

    @Input() inspectorCtrl: ApolloContentInspector;
    @Input() approvalsCtrl: ApolloContentApprovals;

    @Output() eventApprovalMatch = new EventEmitter<any>();

    public tarZoom: number = 1;
    public refZoom: number = 1;
    protected refZoomBag: Array<number> = [];
    protected tarZoomBag: Array<number> = [];

    // IS IMPORTANT FOR NG-SIZE
    public targetSize: any = {};
    public refSize: any = {};

    public links: Array<any> = [];

    public loadRef:boolean = false;
    public loadTar:boolean = false;

    protected lastSelectType: string = null;

    // for centers marks first time
    protected openTar: boolean = false;
    protected openRef: boolean = false;

    public selectedMarkApproval : ApolloContentApprovalItem = null;
    public approvedAll: any;

    public hasMatch: any = {
        next: true,
        prev: true
    };

    public position: any = {
        current: 1,
        total: 0
    }

    constructor(public apolloSrv: ApolloService){}

    ngOnInit() {
        if(this.inspectorCtrl.selectRefWord == null)
            this.inspectorCtrl.setRefPage(1);

        this.inspectorCtrl.onChange.subscribe( (change: ApolloContentInspectorChange)  => this.onInspectorChange(change));
    }

    ngOnChanges(changes: SimpleChanges){
    }

    onInspectorChange(change: ApolloContentInspectorChange) {
        if(change.context == "marks")
            this.onMarksChange(change.type);

        if(change.context=="page")
            this.onPageChange(change.type, change.value);

        if(change.context=="word")
            // this.checkApprovalMark()
            this.onWordChange(change.type, change.value);

        if(change.context=="filters")
            this.onFiltersChange();

    }

    onPageChange(type: string, page: number) {
        switch(type) {
            case this.inspectorCtrl.REF:
                this.loadRef = false;
                this.openRef = false;
                this.resetRefZoom();
                this.updateLinks();
                break;
            case this.inspectorCtrl.TAR:
                this.loadTar = false;
                this.openTar = false;
                this.resetTarZoom();
                this.updateLinks();
                break;
        }

        //this.setHasNextorPreviousMark();
    }

    onMarksChange(type: string) {
        switch(type) {
            case this.inspectorCtrl.REF:
                if(!this.openRef)
                    this.centerRef();

                this.openRef = true;
                break;
            case this.inspectorCtrl.TAR:
                if(!this.openTar)
                    this.centerTar();

                this.openTar = true;

                //this.setHasNextorPreviousMark();
                break;
        }

        this.setHasNextorPreviousMark();
        this.setPosition()
    }

    onWordChange(type: string, word: any) {
        let marks: Array<any> = [];

        switch(type) {
            case this.inspectorCtrl.REF:
                this.centerRef();
                break;
            case this.inspectorCtrl.TAR:
                this.centerTar();

                break;
        }

        this.lastSelectType = null;
    }

    onFiltersChange() {
        this.setHasNextorPreviousMark();
    }

    protected centerRef() {
        if(this.inspectorCtrl.selectRefWord == null)
            return;

        let word = this.inspectorCtrl.selectRefWord
        let marks = this.inspectorCtrl.marks.ref;

        let smarks = marks.filter(m => m.id == word.id);

        if(smarks.length > 0)
            this.centerMark(smarks[0]);

        this.updateLinks();
    }

    protected centerTar() {
        if(this.inspectorCtrl.selectTarWord == null)
            return;

        let word = this.inspectorCtrl.selectTarWord
        let marks = this.inspectorCtrl.marks.tar;

        let smarks = marks.filter(m => m.id == word.id);

        if(smarks.length > 0)
            this.centerMark(smarks[0]);

        this.updateLinks();
    }

    refImageLoad(load) {
        this.loadRef = load;
        //this.openRef = load;
    }

    tarImageLoad(load) {
        this.loadTar = load;
        //this.openTar = load;
    }

    targetResize(size: any) {
        if(size.width == 0 && size.height == 0)
            return false;

        this.inspectorCtrl.setTarSize(size);

        // if(!this.openTar)
        //     this.centerTar();
        //
        // this.openTar = true;

    }

    refResize(size: any) {
        if(size.width == 0 && size.height == 0)
            return false;

        this.inspectorCtrl.setRefSize(size);

        // if(!this.openRef)
        //     this.centerRef();
        //
        // this.openRef = true;
    }

    protected setRefScroll(left: number, top: number) {
        this.figure1.nativeElement.scrollTop = top;
        this.figure1.nativeElement.scrollLeft = left;

        this.updateLinks();
    }
    //
    onRefScroll(event: any) {
        this.updateLinks();
    }

    protected setTarScroll(left: number, top: number) {
        this.figure2.nativeElement.scrollTop = top;
        this.figure2.nativeElement.scrollLeft = left;

        this.updateLinks();
    }

    onTarScroll(event: any) {
        this.updateLinks();
    }

    selectRefMark(mark: any) {
        this.inspectorCtrl.selectMark(mark);
        this.lastSelectType = this.inspectorCtrl.REF;
        // this.checkApprovalMark();
    }

    selectTarMark(mark: any) {
        this.inspectorCtrl.selectMark(mark);
        this.lastSelectType = this.inspectorCtrl.TAR;
        // this.checkApprovalMark();
    }

    protected centerMark(mark: ApolloContentComparisonResultMark) {
        let top: number = 0;
        switch(mark.type) {
            case this.inspectorCtrl.REF:
                if(this.lastSelectType == this.inspectorCtrl.REF) return;

                // fix center problem with marks too height
                top = mark.y * this.refZoom - this.figure1.nativeElement.offsetHeight/2;
                this.figure1.nativeElement.scrollTop = top; // mark.height<this.figure1.nativeElement.offsetHeight? top - (mark.height/2) : top;
                this.figure1.nativeElement.scrollLeft = mark.x * this.refZoom - 10; //this.figure1.nativeElement.offsetWidth/5;
                break;
            case this.inspectorCtrl.TAR:
                if(this.lastSelectType == this.inspectorCtrl.TAR) return;

                // fix center problem with marks too height
                top = mark.y * this.tarZoom - this.figure2.nativeElement.offsetHeight/2;
                this.figure2.nativeElement.scrollTop = top; // mark.height < this.figure2.nativeElement.offsetHeight? top - (mark.height/2) : top;

                //this.figure2.nativeElement.scrollTop = mark.y * this.tarZoom - this.figure2.nativeElement.offsetHeight/2 - mark.height/2;
                this.figure2.nativeElement.scrollLeft = mark.x * this.tarZoom -10; //+ mark.width - this.figure2.nativeElement.offsetWidth*4/5;
                break;
        }
    }

    saveRefZoom() {
        return;
        this.refZoomBag[this.inspectorCtrl.refPage] = this.refZoom;
        //return this.refZoom.hasOwnProperty(this.inspectorCtrl.refPage) ? this.refZoom[this.inspectorCtrl.refPage] : 1;
    }

    resetRefZoom() {
        return;
        this.refZoom = this.refZoomBag.hasOwnProperty(this.inspectorCtrl.refPage) ? this.refZoomBag[this.inspectorCtrl.refPage] : 1;
    }

    onRefZoom(zoom: number) {
        let left = this.figure1.nativeElement.scrollLeft / this.refZoom;
        let top = this.figure1.nativeElement.scrollTop / this.refZoom;

        if(!this.isZoomed(this.refZoom, zoom))
            return;

        this.refZoom = Math.round( (this.refZoom + zoom) * 10 ) / 10;
        this.saveRefZoom();
        this.setRefScroll(left*this.refZoom, top*this.refZoom);
    }

    saveTarZoom() {
        return;
        this.tarZoomBag[this.inspectorCtrl.tarPage] = this.tarZoom;
    }

    resetTarZoom() {
        return;
        this.tarZoom = this.tarZoomBag.hasOwnProperty(this.inspectorCtrl.tarPage) ? this.tarZoomBag[this.inspectorCtrl.tarPage] : 1;
    }

    onTarZoom(zoom: number) {
        let left = this.figure2.nativeElement.scrollLeft / this.tarZoom;
        let top = this.figure2.nativeElement.scrollTop / this.tarZoom;

        if(!this.isZoomed(this.tarZoom, zoom))
            return;

        this.tarZoom = Math.round( (this.tarZoom + zoom) * 10 ) / 10;
        this.saveTarZoom();
        this.setTarScroll(left*this.tarZoom, top*this.tarZoom);
    }

    protected isZoomed(zoom: number, change: number) {
        let rzoom = Math.round((zoom + change) * 10) / 10;
        return rzoom >= this.MIN_ZOOM && rzoom <= this.MAX_ZOOM;
    }

    protected getRefBox(): any {
        return {
            offset: {
                left: this.content1.nativeElement.offsetLeft + this.figure1.nativeElement.offsetLeft,
                top: this.content1.nativeElement.offsetTop + this.figure1.nativeElement.offsetTop
            },
            scroll: {
                left:  this.figure1.nativeElement.scrollLeft,
                top:  this.figure1.nativeElement.scrollTop
            },
            size: {
                width: this.figure1.nativeElement.offsetWidth,
                height: this.figure1.nativeElement.offsetHeight
            }
        };
    }

    protected getTarBox(): any {
        return {
            offset: {
                left: this.content2.nativeElement.offsetLeft + this.figure2.nativeElement.offsetLeft,
                top: this.content2.nativeElement.offsetTop + this.figure2.nativeElement.offsetTop
            },
            scroll: {
                left:  this.figure2.nativeElement.scrollLeft,
                top:  this.figure2.nativeElement.scrollTop
            },
            size: {
                width: this.figure2.nativeElement.offsetWidth,
                height: this.figure2.nativeElement.offsetHeight
            }
        };
    }
    protected updateLinks() {
        if(this.refSize.height == 0 || this.targetSize.height==0)
            return;

        console.debug("draw links");
        let refBox = this.getRefBox();
        let tarBox = this.getTarBox();

        this.links = this.inspectorCtrl.getSelectedLinks(refBox, tarBox, this.refZoom, this.tarZoom);
    }

    approvalMatch(status:number, all){
        //console.debug("match", all);
        let rid = this.inspectorCtrl.selectRefWord === null? null : this.inspectorCtrl.selectRefWord.id;
        let tid = this.inspectorCtrl.selectTarWord == null? null : this.inspectorCtrl.selectTarWord.id;

        this.approvalsCtrl.setApproval(rid, tid, status);

        // search similar matches
        if(all) {
            //console.debug("search all similar!");
            this.approvalSimilarMatches(rid, tid, status);

        }

        this.nextMatch();


    }

    protected approvalSimilarMatches(rid, tid, status) {
        // prepare data
        let ref = rid==null? null : this.inspectorCtrl.getRefWordsById(rid);
        let tar = tid==null? null : this.inspectorCtrl.getTarWordsById(tid);


        let rmatches = ref == null? [] : this.inspectorCtrl.words.reference
                                         .filter(w => JSON.stringify({type: ref.type, text: ref.text, styles: ref.styles}) === JSON.stringify({type: w.type, text: w.text, styles: w.styles}) && w.id != rid)
                                         .map(w => { return {id: w.id, matches: w.matches}});

        //console.debug("rmatches--->", rmatches);
        //let rmatchesids = rmatches.map(rm => rm.id);

        let tmatches = tar == null? [] : this.inspectorCtrl.words.target
                                      .filter(w => JSON.stringify({type: tar.type, text: tar.text, styles: tar.styles}) === JSON.stringify({type: w.type, text: w.text, styles: w.styles}) && w.id != tid)
                                      .map(w => { return {id: w.id, matches: w.matches}});
        let tmatchesids = tmatches.map(tm => tm.id);

        //console.debug("tmatches--->", tmatches, tmatchesids);

        // check if reference and target exist
        if(ref != null && tar != null) {
            //console.debug("both selected!")
            rmatches.forEach(m => {
                let tm = m.matches.filter(t => tmatchesids.indexOf(t) > -1).forEach(t => {
                    //console.debug("approval->", m.id, t);
                    this.approvalsCtrl.setApproval(m.id, t, status);
                });

            });
        }

        // if target doesnt exist
        if(ref != null && tar == null) {
            rmatches.forEach(m => {
                if(m.matches.length ==0)
                    this.approvalsCtrl.setApproval(m.id, null, status);
            });
        }

        // if reference doesnt exist
        if(ref == null && tar != null) {
            tmatches.forEach(m => {
                if(m.matches.length ==0)
                    this.approvalsCtrl.setApproval(null, m.id, status);
            });
        }
    }

    isApprovedStatus(status: number) {
        let rid = this.inspectorCtrl.selectRefWord === null? null : this.inspectorCtrl.selectRefWord.id;
        let tid = this.inspectorCtrl.selectTarWord === null? null : this.inspectorCtrl.selectTarWord.id;

        let appr = this.approvalsCtrl.getApprovalByIds(rid, tid);

        return appr.status == status;
    }

    checkApprovalMark() {
        this.selectedMarkApproval = this.approvalsCtrl.getApprovalByIds(this.inspectorCtrl.selectRefWord.id, this.inspectorCtrl.selectTarWord.id);
    }

    // public isReferenceFilterActive(reference: ApolloContentComparisonResultMark) : boolean {
    //     let appr = this.approvalsCtrl.getApprovalStatusByReferenceId(reference.id);
    //     if(appr.status > 0)
    //         return this.approvalsCtrl.isStatusFilterActive(appr.status);
    //     else
    //         return this.inspectorCtrl.isFilterActive(reference);
    // }
    //
    // public isTargetFilterActive(target: ApolloContentComparisonResultMark) : boolean {
    //     let appr = this.approvalsCtrl.getApprovalStatusByTargetId(target.id);
    //     if(appr.status > 0)
    //         return this.approvalsCtrl.isStatusFilterActive(appr.status);
    //     else
    //         return this.inspectorCtrl.isFilterActive(target);
    // }

    public isFilterActive(target: ApolloContentComparisonResultMark) : boolean {
        // let appr = this.approvalsCtrl.getApproval(target);
        // if(appr.status > 0 && this.approvalsCtrl.activeFilters.length > 0) {
        // //if(this.approvalsCtrl.activeFilters.length > 0 ) {
        //     return this.approvalsCtrl.isFilterActive(target);
        // } else
        //     return this.inspectorCtrl.isFilterActive(target);
        //
        // let appr = this.approvalsCtrl.getApproval(target);

        // if(this.approvalsCtrl.activeFilters.length > 0) {
        // //if(this.approvalsCtrl.activeFilters.length > 0 ) {
        //     return this.approvalsCtrl.isFilterActive(target);
        // } else {
        //     let appr = this.approvalsCtrl.getApproval(target);
        //     return appr.status > 0? false : this.inspectorCtrl.isFilterActive(target);
        //     //return this.inspectorCtrl.isFilterActive(target);
        // }

        if(this.isSelected(target))
            return true;

        return this.approvalsCtrl.isFilterActive(target) && this.inspectorCtrl.isFilterActive(target);
        // if(this.approvalsCtrl.activeFilters.length==0 && this.approvalsCtrl.isFilterActive(target)) {
        //     return this.inspectorCtrl.isFilterActive(target);
        // } else {
        //     return this.approvalsCtrl.isFilterActive(target) && this.inspectorCtrl.isFilterActive(target);
        // }
    }

    protected isSelected(mark: any) : boolean {

        let selected: any = null;
        switch(mark.type) {
            case "ref":
            case "reference":
                selected = this.inspectorCtrl.selectRefWord;
                break;
            case "tar":
            case "target":
                selected = this.inspectorCtrl.selectTarWord;
                break;
        }

        return typeof selected != 'undefined' && selected !=null && selected.id == mark.id;
    }

    protected getNextMark(): ApolloContentComparisonResultMark | null {
        if(this.inspectorCtrl.selectTarMark == null)
            return null;

        let index = this.inspectorCtrl.viewerMarks.findIndex(m => m.id === this.inspectorCtrl.selectTarMark.id && m.type === 'target');
        do {
            index = index+1;
            if (index > this.inspectorCtrl.viewerMarks.length-1)
                return null;

            if (this.isFilterActive(this.inspectorCtrl.viewerMarks[index]) && this.inspectorCtrl.viewerMarks[index].type === 'target') {
                return this.inspectorCtrl.viewerMarks[index];
            }

            if (index == this.inspectorCtrl.viewerMarks.length && !this.isFilterActive(this.inspectorCtrl.viewerMarks[index]) && this.inspectorCtrl.viewerMarks[index].type === 'target') {
                return null;
            }
        }while(index <= this.inspectorCtrl.viewerMarks.length);

        return null;
    }

    nextMatch(){

        let mark = this.getNextMark();
        if(mark !== null) {
            this.lastSelectType = null;
            this.inspectorCtrl.selectMark(mark);
        }

        // this.setHasNextorPreviousMark();


        // let index = this.inspectorCtrl.viewerMarks.findIndex(m => m.id === this.inspectorCtrl.selectTarMark.id && m.type === 'target');
        // do {
        //     index = index+1;
        //     if (index > this.inspectorCtrl.viewerMarks.length-1)
        //         return;
        //
        //     if (this.isFilterActive(this.inspectorCtrl.viewerMarks[index]) && this.inspectorCtrl.viewerMarks[index].type === 'target') {
        //         return this.inspectorCtrl.selectMark(this.inspectorCtrl.viewerMarks[index]);
        //     }
        //
        //     if (index == this.inspectorCtrl.viewerMarks.length && !this.isFilterActive(this.inspectorCtrl.viewerMarks[index]) && this.inspectorCtrl.viewerMarks[index].type === 'target') {
        //         return;
        //     }
        // }while(index <= this.inspectorCtrl.viewerMarks.length);
    }

    protected getPrevMark(): ApolloContentComparisonResultMark | null {
        if(this.inspectorCtrl.selectTarMark == null)
            return null;

        let index = this.inspectorCtrl.viewerMarks.findIndex(m => m.id === this.inspectorCtrl.selectTarMark.id && m.type === 'target');
        do {
            index = index-1;
            if (index < 0)
                return null;

            if (index > 0 && this.isFilterActive(this.inspectorCtrl.viewerMarks[index]) && this.inspectorCtrl.viewerMarks[index].type === 'target') {
                return this.inspectorCtrl.viewerMarks[index];
            }

            if (index == 0){
                if (!this.isFilterActive(this.inspectorCtrl.viewerMarks[index]) && this.inspectorCtrl.viewerMarks[index].type === 'target') {
                    return null;
                }else {
                    return this.inspectorCtrl.viewerMarks[index];
                }
            }
        }while(index >= 0);

        return null;
    }

    prevMatch(){
        let mark = this.getPrevMark();
        if(mark !== null) {
            this.lastSelectType = null;
            this.inspectorCtrl.selectMark(mark);
        }

        // this.setHasNextorPreviousMark();
        // let index = this.inspectorCtrl.viewerMarks.findIndex(m => m.id === this.inspectorCtrl.selectTarMark.id && m.type === 'target');
        // do {
        //     index = index-1;
        //     if (index < 0)
        //         return;
        //
        //     if (index > 0 && this.isFilterActive(this.inspectorCtrl.viewerMarks[index]) && this.inspectorCtrl.viewerMarks[index].type === 'target') {
        //         return this.inspectorCtrl.selectMark(this.inspectorCtrl.viewerMarks[index]);
        //     }
        //
        //     if (index == 0){
        //         if (!this.isFilterActive(this.inspectorCtrl.viewerMarks[index]) && this.inspectorCtrl.viewerMarks[index].type === 'target') {
        //             return;
        //         }else {
        //             return this.inspectorCtrl.selectMark(this.inspectorCtrl.viewerMarks[index]);
        //         }
        //     }
        // }while(index >= 0);
    }

    protected setHasNextorPreviousMark() {
        let next = this.getNextMark();
        let prev = this.getPrevMark();

        this.hasMatch = {
            next: next !== null,
            prev: prev !== null
        }
    }

    protected setPosition() {
        let visibleMarks = this.inspectorCtrl.viewerMarks.filter(m => this.isFilterActive(m));
        let index = this.inspectorCtrl.selectTarMark === null? 0 : visibleMarks.findIndex(m => m.id === this.inspectorCtrl.selectTarMark.id);

        this.position = {
            current: index+1,
            total: visibleMarks.length
        }
    }
}
