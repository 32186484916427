import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VersionsDetailsMainComponent } from '@app/components/sections/versions/main/main.component';
import { VersionsDetailsFilePreviewComponent } from '@app/components/sections/versions/details/sections/version-file-preview/version-file-preview.component';
import { VersionsDetailsVersionComponent } from '@app/components/sections/versions/details/sections/version/version.component';
import { VersionsRelatedFilePreviewComponent } from 'app/components/sections/versions/related/related-file-preview/related-file-preview.component';
import { VersionsRelatedMainComponent } from 'app/components/sections/versions/related/main/main.component';

export const versionsRoutes: Routes = [
    {
        path: 'versions/:vid',
        component: VersionsDetailsMainComponent,
        children: [{
                path: '',
                component: VersionsDetailsVersionComponent
            },
            {
                path: 'files/:fid',
                component: VersionsDetailsFilePreviewComponent
            },
            {
                path: 'related',
                component: VersionsRelatedMainComponent,
            },
            {
                path: 'related/versions/:rvid/files/:fid',
                component: VersionsRelatedFilePreviewComponent
            }
        ],
    },
    // {
    //     path: 'versions/:vid/related',
    //     component: VersionsRelatedMainComponent,
    // },
    // {
    //     path: 'versions/:vid/related/versions/:rvid/files/:fid',
    //     component: VersionsRelatedFilePreviewComponent
    // }
]

@NgModule({
    imports: [
        RouterModule.forRoot(versionsRoutes)
    ],
    exports: [RouterModule]
})
export class AppVersionsRouterModule { }