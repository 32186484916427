import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { UrlService } from '@core/services/url/url.service';

@Component({
    selector: 'core-helpers-navigation-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss']
})
export class HelperNavigationHeaderMenuComponent implements OnInit{
    @Input() session: any;

    @Output() newOrganization = new EventEmitter<number>();

    private wasInside: boolean = false;
    public menu: any[] = [];
    public show: boolean;
    public organizations = [];

    public showChangeOrg:boolean = false;

    public selectedOrg;

    protected settingsAccess = [
        'access_setting_tokens_access',
        'access_setting_groups_access',
        'access_setting_categories_access',
        'access_setting_users_access',
        'access_setting_rules_access',
        'access_setting_versions_access',
        'access_setting_workflows_access'
    ];

    protected appMenu: any = [
        // {
        //     access: 'access_setting_users_access',
        //     name: 'Manage users',
        //     link: '/settings/users'
        // },

        // {
        //     expose: this.isAccess('access_setting_users_access') && this.session.options.license === null,
        //     name: 'Manage users',
        //     link: '/settings/users'
        // },
        // {
        //     expose: this.isAccess('access_setting_users_access') && this.session.options.license !== null,
        //     name: 'Manage licenses',
        //     link: '/settings/licenses'
        // },
    ];

    @HostListener('click')
    clickInside() {
        // console.log("click inside")
        this.wasInside = true;
    }
  
    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.show = false;
        }
        // console.log("click outside")
        this.wasInside = false;
    }

    constructor(private http: HttpClient, private url: UrlService,private router: Router) { }

    ngOnInit(){
        this.selectedOrg = this.session.organization.id;
        this.session.options.organizations.length > 1 ? this.showChangeOrg = true : this.showChangeOrg = false;

        this.appMenu = [
            {
                expose: this.isProfileAccess(),
                name: 'Profile',
                class: 'tw2-drop-profile',
                link: '/profile'
            },
            {
                expose: this.isSettingsAccess(),
                name: 'System settings',
                class: 'tw2-drop-settings',
                link: '/settings'
            }
        ];

        for (let key of this.appMenu){
            //return this.isAccess(key.access) ? this.menu.push(key) : false;
            if(key.expose) this.menu.push(key);
        }
    }

    logout(event){
        event.stopPropagation();
        // window.location.href = "/web/logout";

        this.http.get("/web/logout").subscribe(
            (response: any) => {
                console.log(" =====>>>>> logout success")
                window.location.href = "/login";
            },
            (err: HttpErrorResponse )=> {
                console.log("=====>>>>> error on logout ",err)
            }
        )
    }

    isAccess(key: string) : boolean {
        return this.session.options.access.hasOwnProperty(key)? this.session.options.access[key] : false;
    }

    isSettingsAccess() : boolean {
        let access = this.settingsAccess.map((s: string) => this.isAccess(s)).filter((b: boolean) => b);
        return access.length > 0;
    }

    isProfileAccess() : boolean {
        return this.session.user != null;
    }

    changeOrg(){
        console.log(this.selectedOrg);
        if (this.session.organization.id == this.selectedOrg)
            return;
        this.newOrganization.emit(this.selectedOrg);
    }
}
