import { SelectItem } from '@core/models/select-item.model';

export const registerOptions : SelectItem[] = [
    {
        title: 'Affine',
        value: 'affine',
        selected: false
    },
    {
        title: 'Affine-Noscale',
        value: 'affine-noscale',
        selected: false
    },
    {
        title: 'Perspective',
        value: 'perspective',
        selected: false
    },
    {
        title: 'Keyline',
        value: 'keyline',
        selected: false
    }
];
