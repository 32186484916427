
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import * as Objects from '@core/objects';

import { LogbookService } from 'app/services/app/logbook.service';


@Component({
    selector: 'project-details-logbook-event-properties-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectDetailsLogbookEventPropertiesMainComponent implements OnInit, OnDestroy{
	@Input() event: Objects.Event;

    public properties: any = null; 
    public eventParts: string[] = [];
    public eventObject: string = '';
    public forbidden: boolean = false;

    protected lbSubscriptor: Subscription;
    constructor(private LogbookSrv: LogbookService){}
    
    ngOnInit(): void {
        this.lbSubscriptor =  this.LogbookSrv.getProperties$(this.event.id).subscribe(
            (properties: any) => {
                // console.log(" event propertiess for event id: "+this.event.id, properties)
                this.properties = properties;
                this.init();
            },
            // control error for properties call when not access to object
            (err: HttpErrorResponse) => {
                // console.debug(" error calling properties for event id:"+this.event.id,err);
                if(err.status == 403){
                    this.forbidden = true;
                } 
            })
    }

    init() : void {
        if(this.properties == null)
            return;
        this.eventParts = this.event.type.split('.');
        this.eventObject = this.eventParts[0];
    }

    ngOnDestroy(): void {
        this.lbSubscriptor.unsubscribe();
    }

    getObjectType() :  string{
        return this.event.type.substring(0,this.event.type.indexOf('.'));
    }
}