import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'core-layout-modal-feedback',
    templateUrl: './modal-feedback.component.html',
    styleUrls: ['./modal-feedback.component.scss']
})
export class LayoutModalFeedbackComponent {
    @Input() type: string; // error,done,warning
    @Input() text: string;
    @Input() message: string;
    @Input() buttonText: string;
    @Input() secondButtonText: string = "";
    @Input() disabled: boolean = false;

    @Output() action = new EventEmitter<boolean>();
    @Output() secondButtonAction = new EventEmitter<boolean>();

    onAction() {
        this.action.emit(true);
    }

    onSecondAction() {
        this.secondButtonAction.emit(true);
    }
}
