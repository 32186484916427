import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

import * as Objects from '@core/objects'
import * as Search from '@app/models/search';
import { DashboardStatusFilter } from 'app/store/state/dashboard/dashboard.state';

interface DashboardStatusFilters extends Search.SearchAvailableFilters {
    items: Array<DashboardStatusFilter>;
}
@Component({
    selector: 'app-helpers-search-filters-worlflow-status-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class HelpersSearchFiltersWorkflowStatusMainComponent implements OnInit, OnChanges{

    @Input()  available: DashboardStatusFilters = null;
    @Input()  applied: Array<Search.SearchAppliedFilter> = [];

    @Output() apply = new EventEmitter<Search.SearchAppliedFilter>();

    public opened: number = null;
    public statusFilters : DashboardStatusFilter[] = []; 
    public workflows : Objects.Workflow[] = [];

    ngOnInit(): void {
        this.workflows = this.available.items.map((sf: DashboardStatusFilter) => sf.workflow ).filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i);
        this.makeStatus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.applied)
            this.makeStatus();
    }

    onSelect(statusFilter: DashboardStatusFilter) {
        this.apply.emit({key: this.available.key,item: statusFilter});
    }

    onOpen(workflow: Objects.Workflow){
        this.opened = workflow.id;
    }

    getStatus(workflow: Objects.Workflow){
        return this.statusFilters.filter((statusFilter: DashboardStatusFilter) => statusFilter.workflow.id == workflow.id);
    }

    private isApplied(statusFilter: DashboardStatusFilter) : boolean {
        return this.applied.some((f: Search.SearchAppliedFilter) => f.key == this.available.key && f.item.id == statusFilter.id);
    }

    private makeStatus(): void {
        this.statusFilters = this.available.items.filter((statusFilter: DashboardStatusFilter) => !this.isApplied(statusFilter));
        
    }

}
