import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@core/core.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { AppRouterModule } from '@app/router.module';

import { SettingsRulesRuleFormMainComponent } from './main/main.component';
import { SettingsRulesRuleFormConditionsComponent } from './conditions/conditions.component';
import { SettingsRulesRuleFormConditionsItemComponent } from './condition-item/condition-item.component';
import { SettingsRulesRuleFormActionsComponent } from './actions/actions.component';
import { SettingsRulesRuleFormActionsItemComponent } from './actions-item/actions-item.component';
import { SettingsRulesRuleFormActionsVariableItemComponent } from './actions-variable-item/actions-variable-item.component';
import { SettingsRulesFormHelperSelectComponent } from './helper-select/helper-select.component';
import { SettingsRulesFormHelperSelectMultipleComponent } from './helper-select-multiple/helper-select-multiple.component';


@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        HelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SettingsRulesRuleFormMainComponent,
        SettingsRulesRuleFormConditionsComponent,
        SettingsRulesRuleFormConditionsItemComponent,
        SettingsRulesRuleFormActionsComponent,
        SettingsRulesRuleFormActionsItemComponent,
        SettingsRulesRuleFormActionsVariableItemComponent,
        SettingsRulesFormHelperSelectComponent,
        SettingsRulesFormHelperSelectMultipleComponent
    ],
    exports: [
        SettingsRulesRuleFormMainComponent,
        SettingsRulesRuleFormConditionsComponent,
        SettingsRulesRuleFormConditionsItemComponent,
        SettingsRulesRuleFormActionsComponent,
        SettingsRulesRuleFormActionsItemComponent,
        SettingsRulesRuleFormActionsVariableItemComponent,
        SettingsRulesFormHelperSelectComponent,
        SettingsRulesFormHelperSelectMultipleComponent
    ],

})
export class SettingsRulesRulesFormModule { }
