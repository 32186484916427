import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@core/core.module';
import { LabelsModule } from '@core/components/labels/labels.module';
import { LayoutModule } from '@core/components/layout/layout.module';

import { AppRouterModule } from '@app/router.module';

import { LegacyNotesRequestComponent } from './request/request.component';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        LabelsModule,
        LayoutModule,
        AppRouterModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LegacyNotesRequestComponent,

    ],
    exports: [
        LegacyNotesRequestComponent,

    ],

})
export class LegacyNotesModule { }
