import { Component, EventEmitter, Input, Output, SimpleChanges, OnInit, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { File } from '@core/objects/file';

@Component({
    selector: 'app-share-send-box',
    templateUrl: './send-box.component.html',
    styleUrls: ['./send-box.component.scss'],
    providers: []
})
export class ShareSendBoxComponent implements OnInit {
    @Input() files: Array<File> = [];
    @Input() subjectPlaceholder: string = "";
    @Input() defaultMessage: string = "";
    @Input() form: FormGroup;

    public message: FormControl = new FormControl();
    public emails = new FormControl([]);

    constructor(private fb: FormBuilder) {}

    ngOnInit(){
        this.init();
    }

    init() {
        this.message = new FormControl(this.defaultMessage);
        this.form.addControl("subject", new FormControl(this.subjectPlaceholder));
        this.form.addControl("message", this.message);
        this.form.addControl("emails", this.emails);
    }


}
