import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'core-helpers-forms-wysiwyg-simple',
    templateUrl: './wysiwyg-simple.component.html',
    styleUrls: ['./wysiwyg-simple.component.scss']
})
export class HelperFormWysiwygSimpleComponent implements OnInit {
    public content: string;

    @Input() placeholder:string;
    @Input() textControl: FormControl;
    @Output() textChange = new EventEmitter<string>();

    ngOnInit() {
        this.content = this.textControl.value;
    }

    onTextChange(value: string) {
        this.textControl.setValue(value);
        this.textChange.emit(value)
    }

}
