import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


import { LabelsGroupsService } from '../../../../../services/settings/labels-groups.service';
import { Subscription } from 'rxjs';


import { LabelsGroup } from '@core/objects/labels-group';

@Component({
    selector: 'settings-labels-groups-add-form',
    templateUrl: './add-form.component.html',
    styleUrls: ['./add-form.component.scss']
})
export class SettingsLabelsGroupsAddFormComponent implements OnInit{

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

    @Output() cancel = new EventEmitter<any>();
    @Output() submit = new EventEmitter<any>();

    public status: Number = 0;

    public form: FormGroup;

	constructor(public labelsGroupSrv: LabelsGroupsService, private formBuilder: FormBuilder){}

	ngOnInit(){
		this.init();

	}

    init(){
        this.form = this.formBuilder.group({
            name: ['', [Validators.required]]
		});
    }

	onCancel(event) {
        console.log(" cancel triggered")
        this.cancel.emit(event);
        this.cleanForm();
	}

    add(){
        if(this.form.invalid)
			return;

        this.labelsGroupSrv.addLabelsGroup$(this.form.value.name).subscribe((data: LabelsGroup) => {
            console.debug(" post: new lblgroup added",data);
            this.cleanForm();
            this.submit.emit()
        });

    }

    cleanForm(){
		this.form.reset({name: ''});
	}

}
