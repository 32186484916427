import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularDraggableModule } from 'angular2-draggable';
//import { AngularBlurhashModule } from 'angular-blurhash';
import { HelperBoxModule } from '@core/components/helpers/box/box.module';

import { PreviewFirstComponent } from './preview-first/preview-first.component';
import { PreviewPageComponent } from './preview-page/preview-page.component';
import { PreviewPageBlurComponent } from './preview-page-blur/preview-page-blur.component';
import { PreviewNotFoundComponent } from './preview-not-found/preview-not-found.component';
import { PreviewWaitingComponent } from './preview-waiting/preview-waiting.component';
//import { PreviewWrapperPageComponent } from './preview-wrapper-page/preview-wrapper-page.component';
//import { PreviewWrapperCarouselComponent } from './preview-wrapper-carousel/preview-wrapper-carousel.component';
import { PreviewsCarouselComponent } from './previews-carousel/previews-carousel.component';
//import { PreviewWrapperNoteComponent } from './preview-wrapper-note/preview-wrapper-note.component';

//import { ModalModule } from '../modals/modal.module';

import { PreviewPageViewerComponent } from './preview-page-viewer/preview-page-viewer.component';
import { PreviewViewerComponent } from './preview-viewer/preview-viewer.component';
// import { PreviewImageViewerComponent } from './preview-image-viewer/preview-image-viewer.component';

@NgModule({
    imports: [
        CommonModule,
        //NotesModule,
        //ModalModule,
        HelperBoxModule,
        AngularDraggableModule,
        //AngularBlurhashModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        PreviewFirstComponent,
        PreviewPageComponent,
        PreviewPageBlurComponent,
        //PreviewWrapperPageComponent,
        //PreviewWrapperCarouselComponent,
        PreviewsCarouselComponent,
        PreviewNotFoundComponent,
        PreviewWaitingComponent,
        PreviewPageViewerComponent,
        // PreviewImageViewerComponent
        //PreviewWrapperNoteComponent,
        PreviewViewerComponent
    ],
    exports: [
        PreviewFirstComponent,
        PreviewPageComponent,
        PreviewPageBlurComponent,
        //PreviewWrapperPageComponent,
        //PreviewWrapperCarouselComponent,
        PreviewsCarouselComponent,
        PreviewNotFoundComponent,
        PreviewWaitingComponent,
        PreviewPageViewerComponent,
        // PreviewImageViewerComponent
        //PreviewWrapperNoteComponent
        PreviewViewerComponent
    ],

})
export class PreviewModule { }
