import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';

// export interface ProjectLoaded {
//     id: number;
//     project: Request;
//     status: HttpStatus;
// }

// in snake style for help to find
interface ProjectsErrors {
    load_project: HttpErrorResponse,
    load_project_files: HttpErrorResponse,
    load_project_origin_version: HttpErrorResponse,
    load_project_messages: HttpErrorResponse,
    load_project_version_groups: HttpErrorResponse,
    load_project_uploaded_versions: HttpErrorResponse,
    upload_project_files: HttpErrorResponse,
    set_active_project_file: HttpErrorResponse,
    set_project_assigned: HttpErrorResponse,
    set_project_due: HttpErrorResponse,
    set_project_status: HttpErrorResponse,
    set_project_labels: HttpErrorResponse,
    load_editor_assigners: HttpErrorResponse,
    load_editor_access: HttpErrorResponse,
    load_project_files_notes: HttpErrorResponse,
    load_editor_workflow_transitions: HttpErrorResponse,
    load_header: HttpErrorResponse;
    load_events: HttpErrorResponse;
}

interface ProjectViewer {
    project: Objects.Request;
    files: Array<Objects.File>;
    files_notes: Array<Objects.Note>;
    messages: Array<Objects.Message>;
    origin_version: Objects.Version;
    version_groups: Array<Objects.Related>;
    uploaded_versions: Array<Objects.Version>;
    header: Objects.BasicRequest;
    events: Array<Objects.Event>;
}

interface ProjectEditor {
    assigners: Array<Objects.User>;
    access: Access.AccessProject;
    workflow_transitions: Array<Objects.WorkflowTransition>;
}

export interface Projects {
    editor:  ProjectEditor;
    view: ProjectViewer;
    errors: ProjectsErrors;
}

export const initialState: Projects = {
    //status: new SettingsLicensesStatus,
    // project: null,
    // files: [],
    // origin_version: null,

    editor: {
        assigners: [],
        access: null,
        workflow_transitions: null
    },
    view: {
        project: null,
        files: [],
        files_notes: null,
        messages: null,
        origin_version: null,
        version_groups: null,
        uploaded_versions: null,
        header: null,
        events: null
    },
    errors: {
        load_project: null,
        load_project_files: null,
        load_project_origin_version: null,
        load_project_messages: null, 
        load_project_version_groups: null,
        load_project_uploaded_versions: null, 
        upload_project_files: null,
        set_project_assigned: null, 
        set_project_due: null, 
        set_project_status: null,
        set_project_labels: null, 
        set_active_project_file: null,
        load_editor_assigners: null,
        load_editor_access: null,
        load_project_files_notes: null,
        load_editor_workflow_transitions: null,
        load_header: null,
        load_events: null,
    }
};

const projectsFeatureSelector = createFeatureSelector('projects');

// Selectors
export const viewSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.view
);

export const projectSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.view.project
);

export const projectFilesSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.view.files
);

export const projectMessagesSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.view.messages
);

export const projectOriginVersionSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.view.origin_version
);

export const projectVersionGroupsSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.view.version_groups
);

export const projectUploadedVersionsSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.view.uploaded_versions
);

export const editorAssignersSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.editor.assigners
);

export const editorWorkflowTransitionsSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.editor.workflow_transitions
);

export const editorAccessAllSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.editor.access
);

export const projectFilesNotesSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.view.files_notes
);

export const projectHeaderSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.view.header
);

export const projectEventsSelector = createSelector(
    projectsFeatureSelector,
    (state: Projects) => state.view.events
);

export const editorAccessSelector = (key: string) => 
    createSelector(
        projectsFeatureSelector,
        (state: Projects) => state.editor.access!=null && state.editor.access.hasOwnProperty(key) ? state.editor.access[key] : false
);

export const errorSelector = (key: string) => 
    createSelector(
        projectsFeatureSelector,
        (state: Projects) => state.errors.hasOwnProperty(key) ? state.errors[key] : null
    );