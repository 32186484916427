import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { AddVersion } from '@app/models/add-version';


@Injectable({
    providedIn: 'root'
})
export class AddVersionService {

    constructor(private url : UrlService,  protected http: HttpClient) {}

    addVersion$(data: AddVersion, prefixUrl: string = "") {
        return this.http
        .post(this.url.build(prefixUrl+"/versions"),data);
    }

}