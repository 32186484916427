import { Library, initialState } from './library.state';
import { LibraryActions, LibraryActionType, LoadFilterStatus } from './library.actions';

import * as Objects from '@core/objects';
import * as Search from '@app/models/search';
import { STATE_PROVIDERS } from '@ngrx/store/src/state';

export function libraryReducer(state = initialState, action: LibraryActions): Library {
    switch (action.type) {
		// EVENTS STATUS
        case LibraryActionType.Clear:
            return {
                ...state,
                available_filters: initialState.available_filters,
                search: initialState.search,
                search_query_params: initialState.search_query_params,
                results: initialState.results
            };

        case LibraryActionType.Init:
            if(state.starting.initialized)
                return state;

            let term = action.params!=null? action.params.term : "";
            return {...state, starting : {...state.starting, initialized: true}, search_query_params: action.params, search: {...state.search, term: term}};

        case LibraryActionType.LoadFilterLabelsSuccess:
            return {...state, available_filters: {...state.available_filters, labels: {...state.available_filters.labels, items: action.labels }}};
        
        case LibraryActionType.LoadFilterLabelsError:
            return state;
        
        case LibraryActionType.LoadFilterStatusSuccess:
            return {...state, available_filters: {...state.available_filters, status: {...state.available_filters.status, items: action.status }}};
        
        case LibraryActionType.LoadFilterStatusError:
            return state;

        case LibraryActionType.PushSearchFilters:
            let filters = action.filters.filter((e: Search.SearchAppliedFilter) => !state.search.filters.some((ef: Search.SearchAppliedFilter)=> ef.key == e.key && ef.item.id == e.item.id));
            return {...state, search: {...state.search, filters: [...state.search.filters, ...filters]}};

        case LibraryActionType.ApplySearchFilter: 
            return {...state, search: {...state.search, filters: [...state.search.filters, action.filter] }};

        case LibraryActionType.RemoveSearchFilter: 
            return {...state, search: {...state.search, filters: state.search.filters.filter((f: Search.SearchAppliedFilter) => !(f.key == action.filter.key && f.item.id == action.filter.item.id)) }};
	    
        case LibraryActionType.SetSearchTerm:
            return {...state, search: {...state.search, term: action.term}};

        // case LibraryActionType.SetSearchQuery:
        //     // reset page also
        //     return {...state, search: {...state.search, query: action.query}, results: {...state.results, next_page: 1 }};
        
        case LibraryActionType.SetQueryParams:
            // reset page also
            return {...state, search_query_params: action.params, results: {...state.results, next_page: 1, status: Search.SearchHttpStatus.INITIAL }};
        // case LibraryActionType.LoadResults:
        //     // reset page also
        //     return {...state, results: {...state.results, status: Search.SearchHttpStatus.LOADING }};

        case LibraryActionType.LoadResultsSuccess:
            let status = action.versions.length < state.search.limit? Search.SearchHttpStatus.FINAL : Search.SearchHttpStatus.DONE;
            switch(state.results.next_page ) {
                case 1:
                    return {...state, results: {...state.results, items: action.versions, next_page: 2, status: status }};
                default:
                    return {
                        ...state, 
                        results: {
                            ...state.results, 
                            items: [...state.results.items.filter((v: Objects.Version) => !action.versions.some((vv: Objects.Version) => v.id == vv.id)), ...action.versions], 
                            next_page: state.results.next_page + 1, 
                            status: status
                        }
                    };
            }
        
        case LibraryActionType.ReloadResults:
            return {...state, results: {...state.results, next_page: 1}};
        
        // case LibraryActionType.UpdateVersion:
        //     return {
        //         ...state,
        //         events: [...[{id: action.versionId, name: action.eventName}], ...state.events]
        //     };
        // case LibraryActionType.UpdateVersionSuccess:
        //     return {
        //         ...state, 
        //         results: {
        //             ...state.results, 
        //             items: [...state.results.items.filter((ev: Objects.Version) => ev.id != action.versionId), ...action.versions], 
        //         }
        //     };

        default:
           return state;
    }
}
