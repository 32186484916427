import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SizeMeModule } from 'ngx-size-me';
import { AngularDraggableModule } from 'angular2-draggable';

import { FilesModule } from '@core/components/files/files.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { PreviewModule } from '@core/components/preview/preview.module';
import { LayoutModule } from '@core/components/layout/layout.module';
import { CommonsModule } from '@core/components/commons/commons.module';
import { VersionsModule } from '@core/components/versions/versions.module';

import { ApolloHelpersModule } from '../../helpers/helpers.module';

import { PluginApolloMainSectionUploadComponent } from './upload/upload.component';
import { PluginApolloMainSectionCompareContentComponent } from './compare-content/compare-content.component';
import { PluginApolloMainSectionCheckComponent } from './check/check.component';
import { PluginApolloMainSectionCompareGraphicComponent } from './compare-graphic/compare-graphic.component';
import { PluginApolloMainSectionHistoryComponent } from './history/history.component';
import { PluginApolloMainSectionRequestComponent } from './request/request.component';

// Hammer
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';



export class MyHammerConfig extends HammerGestureConfig  {
    overrides = <any>{
        // override hammerjs default configuration
        'swipe': { direction: Hammer.DIRECTION_ALL  }
    }
  }



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SizeMeModule.forRoot(),
        AngularDraggableModule,
        FilesModule,
        CommonsModule,
        VersionsModule,
        HelpersModule,
        PreviewModule,
        //NotesModule,
        LayoutModule,
        ApolloHelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        PluginApolloMainSectionUploadComponent,
        PluginApolloMainSectionCheckComponent,
        PluginApolloMainSectionCompareContentComponent,
        PluginApolloMainSectionCompareGraphicComponent,
        PluginApolloMainSectionHistoryComponent,
        PluginApolloMainSectionRequestComponent
    ],
    exports: [
        PluginApolloMainSectionUploadComponent,
        PluginApolloMainSectionCheckComponent,
        PluginApolloMainSectionCompareContentComponent,
        PluginApolloMainSectionCompareGraphicComponent,
        PluginApolloMainSectionHistoryComponent,
        PluginApolloMainSectionRequestComponent
    ],
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        }
    ]

})
export class ApolloMainSectionsModule { }
