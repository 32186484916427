import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatus } from '@core/classes/http-status.class';

import * as Objects from '@core/objects';
import * as Search from '@app/models/search';

import * as Access from '@app/models/access';

export enum LibraryActionType {

	// EVENT CALLS
    Init = "[Library] first call",
    // InitSuccess = "[Library] first call SUCCESS",

    LoadFilters = "[Library] Obtain filters",

    LoadFilterLabels = "[Library] GET - Obtain labels filters - GET",
    LoadFilterLabelsSuccess = "[Library] GET - Obtain labels filter SUCCESS",
    LoadFilterLabelsError = "[Library] GET - Obtain labels filter ERROR",

    LoadFilterStatus = "[Library] GET - Obtain status filters - GET",
    LoadFilterStatusSuccess = "[Library] GET - Obtain status filter SUCCESS",
    LoadFilterStatusError = "[Library] GET - Obtain status filter ERROR",

    PushSearchFilters = "[Library] Add filter (silence mode)",
    ApplySearchFilter = "[Library] Add filter",
    RemoveSearchFilter = "[Library] Remove filter",

    SetSearchTerm = "[Library] set search term",
    // UpdateSearchQuery = "[Library] update search query",
    // SetSearchQuery = "[Library] set search query",

    UpdateQueryParams = "[Library] update search query params",
    SetQueryParams = "[Library] set search query params",

    LoadResults = "[Library] dispatch search / load results - GET",
    LoadResultsSuccess = "[Library] dispatch search / load results - GET SUCCESS",
    LoadResultsError = "[Library] dispatch search / load results - GET ERROR",

    ReloadResults = "[Library] set page to 1 and reload results - GET",

    UpdateVersion = "[Library] dispatch search a version - GET",
    // UpdateVersionSuccess = "[Library] dispatch search a version - GET SUCCESS",

    Clear = "[Library] clear data"
}

// Load Available Events
export class Init implements Action {
    readonly type = LibraryActionType.Init;
    constructor(public params: Search.SearchQueryParams) {}
}

export class LoadFilters implements Action {
    readonly type = LibraryActionType.LoadFilters;
    constructor() {}
}

export class LoadFilterLabels implements Action {
    readonly type = LibraryActionType.LoadFilterLabels;
    constructor() {}
}

export class LoadFilterLabelsSuccess implements Action {
    readonly type = LibraryActionType.LoadFilterLabelsSuccess;
    constructor(public labels: Array<Objects.Label>) {}
}

export class LoadFilterLabelsError implements Action {
    readonly type = LibraryActionType.LoadFilterLabelsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadFilterStatus implements Action {
    readonly type = LibraryActionType.LoadFilterStatus;
    constructor() {}
}

export class LoadFilterStatusSuccess implements Action {
    readonly type = LibraryActionType.LoadFilterStatusSuccess;
    constructor(public status: Array<Objects.Status>) {}
}

export class LoadFilterStatusError implements Action {
    readonly type = LibraryActionType.LoadFilterStatusError;
    constructor(public error: HttpErrorResponse) {}
}

export class ApplySearchFilter implements Action {
    readonly type = LibraryActionType.ApplySearchFilter;
    constructor(public filter: Search.SearchAppliedFilter) {}
}

export class PushSearchFilters implements Action {
    readonly type = LibraryActionType.PushSearchFilters;
    constructor(public filters: Search.SearchAppliedFilter[]) {}
}

export class RemoveSearchFilter implements Action {
    readonly type = LibraryActionType.RemoveSearchFilter;
    constructor(public filter: Search.SearchAppliedFilter) {}
}

export class SetSearchTerm implements Action {
    readonly type = LibraryActionType.SetSearchTerm;
    constructor(public term: string) {}
}

// export class UpdateSearchQuery implements Action {
//     readonly type = LibraryActionType.UpdateSearchQuery;
//     constructor() {}
// }

// export class SetSearchQuery implements Action {
//     readonly type = LibraryActionType.SetSearchQuery;
//     constructor(public query: string) {}
// }

export class UpdateQueryParams implements Action {
    readonly type = LibraryActionType.UpdateQueryParams;
    constructor() {}
}

export class SetQueryParams implements Action {
    readonly type = LibraryActionType.SetQueryParams;
    constructor(public params: Search.SearchQueryParams) {}
}

export class LoadResults implements Action {
    readonly type = LibraryActionType.LoadResults;
    constructor() {}
}

export class LoadResultsSuccess implements Action {
    readonly type = LibraryActionType.LoadResultsSuccess;
    constructor(public versions: Array<Objects.Version>) {}
}


export class LoadResultsError implements Action {
    readonly type = LibraryActionType.LoadResultsError;
    constructor(public error:  HttpErrorResponse) {}
}

export class ReloadResults implements Action {
    readonly type = LibraryActionType.ReloadResults;
    constructor() {}
}

export class UpdateVersion implements Action {
    readonly type = LibraryActionType.UpdateVersion;
    constructor(public versionId: number) {}
}

// export class UpdateVersionSuccess implements Action {
//     readonly type = LibraryActionType.UpdateVersionSuccess;
//     constructor(public versionId, public versions: Array<Objects.Version>) {}
// }

// export class UpdateVersionError implements Action {
//     readonly type = LibraryActionType.UpdateVersionError;
//     constructor(public versionId, public error:  HttpErrorResponse) {}
// }

export class Clear implements Action {
    readonly type = LibraryActionType.Clear;
    constructor() {}
}

export type LibraryActions = 	Init |
                                LoadFilters |
                                
                                LoadFilterLabels |
                                LoadFilterLabelsSuccess |
                                LoadFilterLabelsError |

                                LoadFilterStatus |
                                LoadFilterStatusSuccess |
                                LoadFilterStatusError | 
                                
                                PushSearchFilters |
                                ApplySearchFilter |
                                RemoveSearchFilter |

                                SetSearchTerm |
                                // UpdateSearchQuery |
                                // SetSearchQuery | 

                                UpdateQueryParams |
                                SetQueryParams |

                                LoadResults |
                                LoadResultsSuccess |
                                LoadResultsError |

                                ReloadResults | 
                                
                                UpdateVersion |
                                // UpdateVersionSuccess |
                                // UpdateVersionError |

                                Clear
                                ;
