import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { Subscription } from 'rxjs';

import { AppService } from '@app/services/app/app.service';
import { ProjectsEditorService, ProjectsService } from '@app/services/app/projects.service';
import { WorkflowsService } from '@app/services/settings/workflows.service';

import { TitleService } from '@core/services/title.service';

import { File } from '@core/objects/file';
import { Request } from '@core/objects/request';
import { Version } from '@core/objects/version';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';


@Component({
    selector: 'project-details-info-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectDetailsInfoMainComponent implements OnInit, OnDestroy{
    public readonly ACCESS_UPLOAD_FILE = "access_request_upload_file";

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

	public accessList: Array<any> = null;
    public accessUpload: boolean = false;
    public showUpload: boolean = false;
    public status: number = 0;

    public project: Request;

    public selectedAttachment: File = null;

    public originVersion: Version = null;
    public menu: Array<ThreeDotsMenuItem> = [];
    protected menuUpload: ThreeDotsMenuItem = {
            title: "Attach Files",
            key: "upload",
            icon: "upload-file"
        };
    protected subscriptor: Subscription;
    protected accessSubscriptor: Subscription;

	constructor(
        public app: AppService,
        private projectsSrv: ProjectsService,
        private editorSrv: ProjectsEditorService,
        private title: TitleService
    ){}

	ngOnInit(){

        this.accessSubscriptor = this.editorSrv.selectAccess(this.ACCESS_UPLOAD_FILE)
        .subscribe((access: boolean) => {
            this.accessUpload = access;
            this.updateMenu(this.menuUpload,this.accessUpload);
        });

        this.subscriptor = this.projectsSrv.selectProject().subscribe((project: Request ) => {
            if(project == null) return;

            this.setProject(project);
        });
	}

    init() {
        this.title.set("Info - Project "+this.project.id);
        // this.loadOriginVersion()
    }

    protected setProject(project: Request) {
        this.project = project;
        console.log(" PROJECT GOT ",project);
        this.init()

        this.status = this.STATUS_DONE;
    }

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
        this.accessSubscriptor.unsubscribe();
    }

    onUploadDone(){
        if(!this.accessUpload)
            return;

        this.showUpload = !this.showUpload;
    }

    onMenuClick(key: string) {
        switch(key) {
            case "upload":
                console.debug(" open upload click on files ", key)
                if(!this.accessUpload)
                    break;

                this.showUpload = !this.showUpload;
                break;
        }
    }

    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

}
