import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'core-loader-fisherman',
  templateUrl: './fisherman.component.html',
  styleUrls: ['./fisherman.component.scss']
})
export class LoaderFishermanComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
