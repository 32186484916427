import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../../../services/settings/users.service';
import { Subscription } from 'rxjs';
import { HttpStatus } from '@core/classes/http-status.class';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

@Component({
    selector: 'settings-users-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SettingsUsersMainComponent implements OnInit{

	public showForm:boolean = false;
	public access:boolean = true;

	// Subscription status
	public subscriptionStatusInv: Subscription;
	public subscriptionStatusUsers: Subscription;
	public subscriptionStatusAddInv: Subscription;

	// Subscription getData


	// HTTPSTATUS
	public httpStatusInv: HttpStatus;
	public httpStatusUser: HttpStatus;
	public httpStatusAdd: HttpStatus;


	public loadingUser:boolean = true;
	public loadingInv:boolean = true;
	public loadingAdd:boolean = false;
    
    public breadcrumbs: any = [
        {
            title: "System Settings",
            link: ['/settings']
        }
    ];

	// TODO review menu icons
	public menu: Array<ThreeDotsMenuItem> = [{
		title: "Add user",
		key: "add-user",
		icon: "add",
		order: 1
	}];

	constructor(public userSrv: UsersService){}

	ngOnInit(){
		this.userSrv.loadAllInvitations();
		this.userSrv.loadAllUsers();

		this.subscriptionStatusInv = this.userSrv.selectorStatusInvite().subscribe((statusInv: HttpStatus) => {
			this.httpStatusInv = statusInv;
			if (this.httpStatusInv.code != 0) {
				this.loadingInv = false;

				if (this.httpStatusInv.code != 0 && this.loadingAdd) {
					this.loadingAdd = false;
				}
			}
			switch (this.httpStatusInv.message){
				case "403":
					return this.access = false;
			};
		});

		this.subscriptionStatusUsers = this.userSrv.selectorStatusList().subscribe((statusUser: HttpStatus) => {
			this.httpStatusUser = statusUser;
			if (this.httpStatusUser.code != 0) {
				this.loadingUser = false;
			}
		});

		this.subscriptionStatusAddInv = this.userSrv.selectorStatusAddInvite().subscribe((addInv: HttpStatus) => {
			this.httpStatusAdd = addInv;
			switch (this.httpStatusAdd.code){
				case 0:
					this.loadingAdd = true;
					break;
				case 2:
					this.loadingAdd = false;
					break;
			}
		});
	}

	controlForm(event){
		console.log('eveeeent -- ', event);
		this.showForm = !this.showForm;
	}

	newInvitation(event){
		this.userSrv.addNewInvitation(event.name, event.email, event.message, event.groups, {});
	}

	tryAgain(event){
		switch (event){
			case 'users':
				this.loadingUser = true;
				this.userSrv.loadAllUsers();
				break;

			case 'invitations':
				this.loadingInv = true;
				this.userSrv.loadAllInvitations();
				break;
		}
	}

	onMenuClick(key: string) {
			switch(key) {
					case "add-user":
							this.showForm = !this.showForm;
							break;
			}
	}
}
