import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LabelsGroup } from '@core/objects/labels-group';

import { LabelsGroupsService } from '../../../../../services/settings/labels-groups.service';

import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';


@Component({
    selector: 'settings-labels-group-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SettingsLabelsGroupsMainComponent implements OnInit{
    private readonly ACCESS = 'access_setting_categories_access';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

    public labelsGroups: Array<LabelsGroup>;
	public showForm:boolean = false;
	public access:boolean = true;
    public status: number = 0;
    public breadcrumbs: any = [
        {
            title: "System Settings",
            link: ['/settings']
        }
    ];    
    
    // TODO review menu icons
    public menu: Array<ThreeDotsMenuItem> = [{
        title: "Add a new category",
        key: "add-category",
        icon: "add",
        order: 1
    }];

    public colorOptions: Array<string> = ['#E87DB0', '#E88C7D', '#E8A97D', '#E8D57D','#8CE87D','#7DE8C2','#7DD5E8','#7D9BE8','#B97DE8' ];


	constructor(public labelsGroupsSrv: LabelsGroupsService, public app: AppService, private title: TitleService){}

	ngOnInit(){
        this.title.set("Categories - System settings");

        this.app.getSession().subscribe( (session: any) => {
            this.access = session.options.access.hasOwnProperty(this.ACCESS)? session.options.access[this.ACCESS] : false;
        });
        if (this.access){
            this.load();
        }
	}

    onSaved(event){
        this.showForm = false;
        this.load();
    }

    onColorChange(color: string ,labelsGroup: LabelsGroup){
        console.log(" color title change",event)
        this.updateColor(labelsGroup,color);
    }

	protected load(){
        this.labelsGroupsSrv.getAllLabelsGroups$().subscribe((labelsGroups: Array<LabelsGroup>) => {
            this.labelsGroups = labelsGroups;
            console.log(" result status",labelsGroups)
            this.status = this.STATUS_DONE;
        });

	}

    deactivate(labelsGroup: LabelsGroup) {
        console.log(" todoooo deactivate...",labelsGroup);
        this.labelsGroupsSrv.deactivateLabelsGroup$(labelsGroup).subscribe(data => {
            console.log(" result deactivate lblgrp",data);
            this.load()
        });
    }

    activate(labelsGroup: LabelsGroup) {
        console.log(" todoooo deactivate...",labelsGroup);
        this.labelsGroupsSrv.activateLabelsGroup$(labelsGroup).subscribe(data => {
            console.log(" result activate lblgrp",data);
            this.load()
        });
    }

    updateColor(labelsGroup: LabelsGroup, color: string) {
        console.log(" todoooo update color...",labelsGroup,color);
        this.labelsGroupsSrv.updateLabelsGroupColor$(labelsGroup,color).subscribe(data => {
            console.log(" result delete lblgrp",data);
            this.load()
        });
    }

    onMenuClick(key: string) {
        switch(key) {
            case "add-category":
                this.showForm = !this.showForm;
                break;
        }
    }
}
