import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Note } from '@core/objects';

import { UrlService } from '@core/services/url/url.service';

@Injectable({
    providedIn: 'root'
})
export class LogbookService {

    constructor(private url : UrlService,  protected http: HttpClient) {}

    getProperties$(eventId: string) {
        return this.http
        .get(this.url.build("/events/" + eventId + "/properties"));
    }


    getProjectFilesNotes$(id: number,prefix: string = ''){
        return this.http
        .get(this.url.build("/requests/" + id + "/notes"));
    }

}