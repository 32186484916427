import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { Status } from '@core/objects/status';

@Injectable({
    providedIn: 'root'
})
export class VersionStatusService {

    constructor(protected url: UrlService, protected http: HttpClient) {}

    getAllVersionStatus$(prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/versions/status"),{params: {all: 'true'}});
    }

    getVersionStatus$(versionStatus: Status, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/versions/status/" + versionStatus.id));
    }

    deactivateVersionStatus$(versionStatus: Status, prefixUrl: string = "") {
        return this.http
        .delete(this.url.build(prefixUrl+"/versions/status/" + versionStatus.id ));
    }

    activateVersionStatus$(versionStatus: Status, prefixUrl: string = "") {
        return this.http
        .put(this.url.build(prefixUrl+"/versions/status/" + versionStatus.id ),
            {active: true}
        );
    }

    updateVersionStatusColor$(versionStatus: Status,color: string = "", prefixUrl: string = "") {
        return this.http
        .put(this.url.build(prefixUrl+"/versions/status/" + versionStatus.id ),
            {color: color}
        );
    }



    addVersionStatus$(name: string, prefixUrl: string = "") {
        let options = {headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};
        let vsData: any = {
            name: name
        };
        return this.http.post(
            this.url.build(prefixUrl+"/versions/status"),
            vsData,
            options
        );
    }
}
