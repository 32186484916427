//import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
//import { CommonModule } from "@angular/common";

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BaseUrlInterceptor } from './interceptors/BaseUrl.interceptor';

/*@NgModule({
    declarations: [],
    imports: [
        HttpClientModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BaseUrl, multi: true }
    ]
})

export class CoreInterceptors { }
*/

/* "Barrel" of Http Interceptors */
/*import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth-interceptor';
import { CachingInterceptor } from './caching-interceptor';
import { EnsureHttpsInterceptor } from './ensure-https-interceptor';
import { LoggingInterceptor } from './logging-interceptor';
import { NoopInterceptor } from './noop-interceptor';
import { TrimNameInterceptor } from './trim-name-interceptor';
import { UploadInterceptor } from './upload-interceptor';
*/

export const CoreInterceptorsProvider = [
  { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },

  //{ provide: HTTP_INTERCEPTORS, useClass: EnsureHttpsInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: TrimNameInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: UploadInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },

];
