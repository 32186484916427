import { createFeatureSelector, createSelector } from '@ngrx/store';
// import { HttpStatus } from '@core/classes/http-status.class';
import { HttpErrorResponse } from '@angular/common/http';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';
// import { Request } from '@core/objects/request';
// import { File } from '@core/objects/file';
// import { Version } from '@core/objects/version';
// import { Message } from '@core/objects/message';
// import { Related } from '@core/objects/related';
// import { User } from '@core/objects/user';

// export interface ProjectLoaded {
//     id: number;
//     project: Request;
//     status: HttpStatus;
// }

// export interface LabelsAccess {
//     access_label_add: boolean;
// }

export interface Labels {
    error: HttpErrorResponse;
    labels: Array<Objects.Label>;
    input: string;
    categories: Array<Objects.LabelsGroup>;
    access: Access.AccessLabels;
}

export const initialState: Labels = {
   error: null,
   labels: null,
   input: "",
   categories: [],
   access: {
       access_label_add: false
   }
};

const labelsFeatureSelector = createFeatureSelector('labels');

// Selectors
export const errorSelector = createSelector(
    labelsFeatureSelector,
    (state: Labels) => state.error
);

export const labelsSelector = createSelector(
    labelsFeatureSelector,
    (state: Labels) => state.labels
);

export const inputSelector = createSelector(
    labelsFeatureSelector,
    (state: Labels) => state.input
);

export const categoriesSelector = createSelector(
    labelsFeatureSelector,
    (state: Labels) => state.categories
);

export const accessSelector = createSelector(
    labelsFeatureSelector,
    (state: Labels) => state.access
);