import { EventEmitter } from '@angular/core';


export class ApolloInspector {
    public onChange = new EventEmitter<any>();

    public originalSize: any = {
        width: 0,
        height: 0
    };

    public viewSize: any = {
        width: 0,
        height: 0
    };

    public viewPosition: any = {
        top: 0,
        left: 0
    };

    public inspectorSize: any = {
        width: 0,
        height: 0
    };

    public inspectorPosition: any = {
        top: 0,
        left: 0
    };

    public marker: any = {
        width: 0,
        height: 0,
        top: 0,
        left: 0
    };

    public moving: boolean = false;

    public setOriginalSize(width: number, height: number) {
        this.originalSize = {
            width: width,
            height: height
        };
    }

    public setZoom(zoom: number) {
        this.setViewSize((this.originalSize.width*zoom)/100, (this.originalSize.height*zoom)/100);
    }

    public setViewSize(width: number, height: number) {
        this.viewSize = {
            width: width,
            height: height
        };

        this.calcMarkerSize();
    }


    public setInspectorSize(width: number, height: number) {
        this.inspectorSize = {
            width: width,
            height: height
        };

        this.calcMarkerSize();
    }

    public setInspectorPosition( left: number, top: number) {
        this.inspectorPosition = {
            top: top,
            left: left
        };

        this.calcMarkerSize();
    }

    public setMarkerPosition(left: number, top: number) {
        //this.marker.top = top;
        //this.marker.left = left;
        this.inspectorPosition.left = left * this.inspectorSize.width / this.marker.width;
        this.inspectorPosition.top = top * this.inspectorSize.height / this.marker.height;

        this.onChange.emit(true);

        //this.calcInspectorPosition();
    }

    protected calcMarkerSize() {
        if(this.moving)
            return false;

        let width = this.viewSize.width * this.inspectorSize.width/ this.originalSize.width;
        let height = this.viewSize.height * this.inspectorSize.height/ this.originalSize.height;

        let top = this.inspectorPosition.top * height / this.inspectorSize.height;
        let left = this.inspectorPosition.left * width / this.inspectorSize.width;

        this.marker = {
            width: width,
            height: height,
            top: top,
            left: left
        };
    }

    protected calcInspectorPosition() {
        this.inspectorPosition.left = this.marker.left * this.inspectorSize.width / this.marker.width;
        this.inspectorPosition.top = this.marker.top * this.inspectorSize.height / this.marker.height;

        this.onChange.emit(true);
    }
};
