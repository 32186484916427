import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import {VersionsService } from '@core/services/versions/versions.service';


import * as  Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';

@Component({
    selector: 'core-projects-summary-item',
    templateUrl: './summary-item.component.html',
    styleUrls: ['./summary-item.component.scss']
})
export class ProjectsSummaryItemComponent implements OnInit {
    // public readonly STATUS_LOADING = 0;
    // public readonly STATUS_DONE = 1;

    @Input() project: Objects.ExtendedRequest;
    @Input() exclude: Array<Objects.Label> = [];
    @Input() badge: string = '';
    @Input() opened: boolean = false;

    @Output() open = new EventEmitter<Objects.ExtendedRequest>();
    @Output() openSummary = new EventEmitter<Objects.ExtendedRequest>();

    // public status: number = this.STATUS_LOADING;
    public displayOpen: boolean = false;
    public files: Array<Objects.File> = [];
    public diffLabels: Array<Objects.Label> = [];
    public feedbacksList: Array<VersionFeedback> = [];

    constructor(private versionSrv: VersionsService ) { }

    ngOnInit() {
        if(this.project != null)
            this.updateDiffLabels();

    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.exclude)
            this.updateDiffLabels();
    }

    toogleDetails() {
        this.opened = !this.opened; // maybe not needed
        
        this.openSummary.emit(this.project)
    }


    onOpen() {
        this.open.emit(this.project);
    }

    private updateDiffLabels() {
        this.diffLabels = this.project.labels.filter((label: Objects.Label) => !this.exclude.some((l) => l.id == label.id));
    }

}
