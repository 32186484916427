import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UrlService } from '@core/services/url/url.service';
import { Subscription } from 'rxjs';

// import { AppService } from '@app/services/app/app.service';
import { WorkflowsService } from '@app/services/settings/workflows.service';
import { VersionsService } from '@app/services/app/versions.service';
import { TitleService } from '@core/services/title.service';

import * as Objects from '@core/objects';

import { ProjectsService } from '@app/services/app/projects.service';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';
import { AppRouterService } from 'app/services/app/router.service';
import { AppService } from 'app/services/app/app.service';

@Component({
    selector: 'project-preview-notes-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectPreviewNotesMainComponent implements OnInit, OnDestroy{
    protected readonly PLUGIN_SEND_FILES = 'share_transfer';
    protected readonly PLUGIN_APOLLO = 'apollo';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    public status: number = 0;
    public file: Objects.File = null;
    public project: Objects.Request = null;
    public notesUrlPrefix: string = '';
    public canCreateNotes: boolean = true;
    public pluginsAccess: Array<string> = [];

    protected urlSubscriptor: Subscription;
    protected filesSubscriptor: Subscription;
    protected projectSubscriptor: Subscription;
    protected appSubscriptor: Subscription;

    public breadcrumbs: any = [
        {
            title: "",
            link: ['']
        }
    ];

    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "Download",
            key: "download",
            icon: "download",
            order: 1
        }
    ];
    protected menuSendFiles: ThreeDotsMenuItem = {
        title: "Send",
        key: "send-files",
        icon: "send-files",
        order: 2
    };
    protected menuCompare: ThreeDotsMenuItem = {
        title: "Compare",
        key: "compare",
        icon: "compare",
        order: 3
    };
    protected projectNotesSubscriptor: Subscription;


    constructor (private app: AppService,
        private title: TitleService,
        private activeRoute: ActivatedRoute,
        private projectsSrv: ProjectsService,
        private urlSrv: UrlService,
        private appRouter: AppRouterService){}

    ngOnInit(): void {
        this.title.set("Project #");

        this.appSubscriptor = this.app.getSession().subscribe( (session: any) => {
            this.pluginsAccess = session.options.plugins;
            this.updateMenu(this.menuSendFiles,this.checkPluginAccess(this.PLUGIN_SEND_FILES))
            this.updateMenu(this.menuCompare,this.checkPluginAccess(this.PLUGIN_APOLLO));
        });

        this.projectSubscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.Request) => {
            if(project == null) return;

            this.setProject(project);
        });

        this.urlSubscriptor = this.activeRoute.params.subscribe((params: any) => {
            // console.log(" params ", params);
            let fid: any = +params.fid;
            this.filesSubscriptor = this.projectsSrv.selectProjectFiles().subscribe((files: Objects.File[] ) => {
                if(files.length > 0){
                    this.file = files.filter((f: Objects.File) => {return f.id == fid;})[0];
                    // File must be loaded on the project store                  
                    this.status = this.STATUS_DONE;
                }
            });
        });


    }

    ngOnDestroy() {
        this.urlSubscriptor.unsubscribe();
        this.projectSubscriptor.unsubscribe();
        this.appSubscriptor.unsubscribe();
        if(this.filesSubscriptor)
            this.filesSubscriptor.unsubscribe();
    }


    notesSaved(notes: Array<Objects.Note>){
        this.projectsSrv.loadFilesNotes();
    }

    protected setProject(project:  Objects.Request) {
        // console.log(" PROJECT GOT ",project);
        this.updateBreadcrumbs(project);
        this.notesUrlPrefix = '/requests/'+project.id;
        this.project = project;
        this.title.set("Preview & Notes - Project #"+this.project.id);
    }

    protected updateBreadcrumbs(project: Objects.Request){
        this.breadcrumbs[0].link = '/projects/'+project.id;
        this.breadcrumbs[0].title = 'Project <small>(#'+project.id+ ')</small>';
    }

    onMenuClick(menu: FilesListItemMenuClick) {
        switch(menu.key) {
            case "download":
                console.debug(" download click on file ", menu.file)
                window.location.href = this.urlSrv.build('/files/'+menu.file.id);
                break;
            case "send-files":
                if (this.checkPluginAccess(this.PLUGIN_SEND_FILES))
                    this.appRouter.navigate('/p/transfer/'+this.project.id, { queryParams: { file: +menu.file.id} });
                break;
            case "compare":
                if (this.checkPluginAccess(this.PLUGIN_APOLLO))
                    this.appRouter.navigate('/p/apollo/request/'+this.project.id);//, { queryParams: { reference: +menu.file.id} });
                break;
        }
    }

    protected checkPluginAccess(plugin: string){
        return this.pluginsAccess.some((pa) => pa == plugin);
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }


}
