import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';

@Component({
    selector: 'core-file-download-three-action-list-item',
    templateUrl: './file-download-three-action-list-item.component.html',
    styleUrls: ['./file-download-three-action-list-item.component.scss']
})
export class FileDownloadThreeActionListItemComponent implements OnInit, OnChanges {

    @Input() file: File;
    @Input() actionOneText: string = "";
    @Input() actionOneStyle: string = "";
    @Input() actionTwoText: string = "";
    @Input() actionTwoStyle: string = "";
    @Input() actionThreeText: string = "";
    @Input() actionThreeStyle: string = "";
    @Input() bubble: boolean = false;
    @Input() marked: boolean = false;
    @Input() bubbleNumber: Number = 0;

    @Output() actionOneClick = new EventEmitter<File>();
    @Output() actionTwoClick = new EventEmitter<File>();
    @Output() actionThreeClick = new EventEmitter<File>();
    @Output() bubbleClick = new EventEmitter<any>(); //bubbleClick
    @Output() markClick = new EventEmitter<any>(); //marckClick

    public displayMark: boolean = false;

    constructor() { }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.marked) {
            this.displayMark = this.marked;
        }
    }

    onActionOneClick(file: File) {
        // console.debug(" core-file-download-three-action-list-ITEM onActiononeClick ",file);
        this.actionOneClick.emit(file)
    }

    onActionTwoClick(file: File) {
        // console.debug(" core-file-download-three-action-list-ITEM onActiontwoClick ",file);
        this.actionTwoClick.emit(file)
    }
    onActionThreeClick(file: File) {
        // console.debug(" core-file-download-three-action-list-ITEM onActionthreeClick ",file);
        this.actionThreeClick.emit(file)
    }

    onBubbleClick(file: File) {
        // console.debug(" core-file-download-three-action-list-ITEM onBubbleClick ",file);
        this.bubbleClick.emit(file)
    }

    onMarkClick(file: File) {
        // console.debug(" core-file-download-three-action-list-ITEM onMarkClick ",file);
        this.bubbleClick.emit(file)
    }


}
