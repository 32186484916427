import { Dashboard, DashboardStatusFilter, initialState } from './dashboard.state';
import { DashboardActions, DashboardActionType, LoadFilterUsers } from './dashboard.actions';

import * as Objects from '@core/objects';
import * as Search from '@app/models/search';
import { STATE_PROVIDERS } from '@ngrx/store/src/state';
import { resultItemsSelector } from '../library/library.state';

export function dashboardReducer(state = initialState, action: DashboardActions): Dashboard {
    switch (action.type) {
		// EVENTS STATUS
        case DashboardActionType.Clear:
            return {
                ...state, 
                starting: initialState.starting, 
                available_filters: initialState.available_filters,
                search: initialState.search,
                workflows: initialState.workflows,
                search_query_params: initialState.search_query_params,
                results: initialState.results,
                view: initialState.view,
                events: []
            };

        case DashboardActionType.InitParams:
            let ipar = { value: (action.params == null || Object.keys(action.params).length == 0)? null : action.params, loaded: true};

            if(state.starting.contexts[action.where].loaded)
                return state;

            return {
                ...state,
                starting: {
                    ...state.starting,
                    contexts: {
                        ...state.starting.contexts,
                        [action.where]: ipar
                    }
                }
            };

        case DashboardActionType.Init:
            let term = action.params!=null? action.params.term : "";
            let params = {...action.params, workflow: ""};
            // check if sort_by is set in user init params, if it's not set we need to set the initialState search sort order
            let sort_by = (action.params!=null && action.params.sort_by && action.params.sort_by!="") ? action.params.sort_by : initialState.search.sort;

            // let selectWorkflows = action.params != null && action.params.hasOwnProperty("workflow")? state.workflows.items.filter((w: Objects.Workflow) => +action.params['workflow'] == w.id) : [];
            // let workflow = selectWorkflows.length > 0 ? selectWorkflows[0] : state.workflows.items[0];

            return {
                ...state,
                starting: {
                    ...state.starting,
                    initialized: true
                },
                workflows: {
                    ...state.workflows,
                    // current: workflow,
                    // current_id: workflow != null? workflow.id : null
                },
                search_query_params: params,
                search: {
                    ...state.search, term: term, sort: sort_by // added the sort to set inital search
                }
            };

        case DashboardActionType.LoadWorkflowsSuccess:

            let first: Objects.Workflow = action.workflows.length > 0? action.workflows[0] : state.workflows.current;

            let curr: Objects.Workflow = state.workflows.current_id != null? action.workflows.filter((w: Objects.Workflow) => w.id == state.workflows.current_id).shift() : null;

            let current: Objects.Workflow  = curr == null? first : curr;
            // prevent orgs with no workflows throw error on set current id of null (13th july 2022)
            if (first == null && curr == null){
                return {...state,
                    workflows: {
                        ...state.workflows,
                        items: action.workflows,
                        empty: true
                    }
                }
            }
            return {
                ...state,
                workflows: {
                    ...state.workflows,
                    items: action.workflows,
                    current_id: current.id,
                    current: current
                }
            }

        case DashboardActionType.LoadFilterStatusSuccess:
            let fstatus = [
                ...state.available_filters.status.items.filter((filter: DashboardStatusFilter) => filter.workflow.id != action.workflow.id),
                ...action.status.map((s: Objects.Status) => { return {id: s.id, status: s, workflow: action.workflow}})
            ];

            return {...state, available_filters: {...state.available_filters, status: {...state.available_filters.status, items: fstatus}}};
        case DashboardActionType.LoadFilterLabelsSuccess:
            return {...state, available_filters: {...state.available_filters, labels: {...state.available_filters.labels, items: action.labels }}};

        case DashboardActionType.LoadFilterLabelsError:
            return state;

        case DashboardActionType.LoadFilterUsersSuccess:
            return {...state, available_filters: {...state.available_filters,
                        owners: {...state.available_filters.owners, items: action.users },
                        asignees: {...state.available_filters.asignees, items: action.users }}};

        case DashboardActionType.LoadFilterUsersError:
            return state;

        case DashboardActionType.PushSearchFilters:
            let filters = action.filters.filter((e: Search.SearchAppliedFilter) => !state.search.filters.some((ef: Search.SearchAppliedFilter)=> ef.key == e.key && ef.item.id == e.item.id));
            return {...state, search: {...state.search, filters: [...state.search.filters, ...filters]}};

        case DashboardActionType.ApplySearchFilter:
            return {...state, search: {...state.search, filters: [...state.search.filters, action.filter] }};

        case DashboardActionType.RemoveSearchFilter:
            return {...state, search: {...state.search, filters: state.search.filters.filter((f: Search.SearchAppliedFilter) => !(f.key == action.filter.key && f.item.id == action.filter.item.id)) }};

        case DashboardActionType.SetCurrentWorkflow:
            if(action.workflowId == state.workflows.current_id)
                return state;
                
            let ws = state.workflows.items.filter((w: Objects.Workflow) => w.id == action.workflowId);

            return {...state, results: {...state.results, items: [], next_page: 1, status: Search.SearchHttpStatus.INITIAL}, workflows: {...state.workflows, current_id: action.workflowId, current: ws.length>0? ws[0] : state.workflows.current}};

        case DashboardActionType.SetSearchTerm:
            return {...state, search: {...state.search, term: action.term}};

        case DashboardActionType.SetSearchSort:
            return {...state, search: {...state.search, sort: action.sort}};

        // case DashboardActionType.SetSearchQuery:
        //     // reset page also
        //     return {...state, search: {...state.search, query: action.query}, results: {...state.results, next_page: 1 }};

        case DashboardActionType.SetQueryParams:
            // reset page also
            return {...state, search_query_params: action.params, results: {...state.results, items: [], next_page: 1, status: Search.SearchHttpStatus.INITIAL }};

        case DashboardActionType.LoadResults:
            // reset page also
            return {...state, results: {...state.results, status: Search.SearchHttpStatus.LOADING }};

        case DashboardActionType.LoadResultsSuccess:
            let status = action.requests.length < state.search.limit? Search.SearchHttpStatus.FINAL : Search.SearchHttpStatus.DONE;

            switch(state.results.next_page ) {
                case 1:
                    return {
                        ...state,
                        results: {
                            ...state.results,
                            items: action.requests,
                            next_page: 2,
                            status: status,
                        },
                        events: []
                    };
                default:
                    return {
                        ...state,
                        results: {
                            ...state.results,
                            items: [...state.results.items.filter((er: Objects.ExtendedRequest) => !action.requests.some((eer: Objects.ExtendedRequest) => eer.id == er.id)), ...action.requests],         //[...state.results.items, ...action.requests],
                            next_page: state.results.next_page + 1,
                            status: status
                        }
                    };
            }
        case DashboardActionType.UpdateRequest:
            return {
                ...state,
                events: [...[{id: action.requestId, name: action.eventName}], ...state.events]
            };
        case DashboardActionType.UpdateRequestSuccess:
            return {
                ...state,
                results: {
                    ...state.results,
                    items: [...state.results.items.filter((er: Objects.ExtendedRequest) => er.id != action.requestId), ...action.requests],
                }
            };

        default:
           return state;
    }
}
