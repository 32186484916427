export const errorsFullScreen : Array<any> = [
    {
        key: "organization-not-allowed",
        text: "Organization not allowed.",
        code: 403
    },
    {
        key: "internal-server-error",
        text: "Internal server error, please refresh your browser. If the error persist contact the supplier.",
        code: 500
    },
    {
        key: "not-found",
        text: "Not found. Check your URL.",
        code: 404
    }
];
