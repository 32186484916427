import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';

@Component({
    selector: 'core-file-uploaded-list-item',
    templateUrl: './uploaded-list-item.component.html',
    styleUrls: ['./uploaded-list-item.component.scss']
})
export class FileUploadedListItemComponent implements OnInit {

    @Input() file: File;
    @Input() remove: boolean = false;
    @Input() download: boolean = false;

    @Output() onRemove = new EventEmitter<any>();
    @Output() onSelect = new EventEmitter<any>();

    public downloadText: string = "Download";
    public mainText: string = "";


    constructor(public urlService: UrlService) { }

    ngOnInit() {

        //console.log("file list item: ",this.file);
        this.mainText = "<span class=\"name\">" + this.file.name +"</span> <span class=\"file-size\">(" + this.file.filesize + ")</span>";

        if(!this.download)
            this.downloadText = "";
    }

    downloadAction() {
        if(!this.download)
            return false;

        window.location.href = this.urlService.build('/files/'+this.file.id);
    }

}
