import { ApolloItem } from './apollo-item.model';
import { ApolloGraphicComparison } from './apollo-graphic-comparison.model';
import { ApolloContentComparison } from './apollo-content-comparison.model';

export class ApolloRequest {
    apollo_graphic : ApolloGraphicComparison = null;
    apollo_content : ApolloContentComparison = null;
    date: any = new Date();
    hash: string;
    target: ApolloItem = null;
    reference: ApolloItem = null;
}
