import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';

import { VersionsViewService } from '@app/services/app/versions.service';

import * as Objects from '@core/objects';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';


@Component({
    selector: 'project-details-breadcrumb-version-header',
    templateUrl: './breadcrumb-version-header.component.html',
    styleUrls: ['./breadcrumb-version-header.component.scss']
})
export class ProjectDetailsBreadcrumbVersionHeaderComponent implements OnInit, OnChanges {
	@Input() project: Objects.Request = null;
    @Input() version: Objects.Version = null;
    @Input() exclude: Array<Objects.Label> = []; // those are the tags that don't go after + under header
	@Input() title: string = '';
	@Input() breadcrumbs: any = [];
	@Input() menu: Array<ThreeDotsMenuItem> = [];
    @Input() status: Array<Objects.Status> = [];
    @Output() action = new EventEmitter<string>();
    @Output() statusAction = new EventEmitter<Objects.Status>();

    public diffLabels: Array<Objects.Label> = [];
    public futureStatus: Array<Objects.Status> = [];

    constructor( private versionsSrv: VersionsViewService) { }


    ngOnInit() {
        // Here we extract exclusive version labels to put separated in header
        if(this.version !== null && this.project != null){
            this.setFutureStatus();
            
            // console.log(" version exclusive tags are: ",this.versionLabels);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.project || changes.version){
            this.updateDiffLabels();
            this.setFutureStatus();
        }    
        if(changes.status){
            this.setFutureStatus();
        }
        if(changes.exclude){
            this.updateDiffLabels();
        }
}

    private setFutureStatus() {
        if(this.version != null)
            this.futureStatus = this.status.filter((s: Objects.Status) => s.id != this.version.status.id);
    }

    private updateDiffLabels() {
        if(this.version == null || this.exclude == null)
            return;
        this.diffLabels = this.version.labels.filter((label: Objects.Label) => !this.exclude.some((l) => l.id == label.id));
    }
    onMenuSelect(key: string) {
        this.action.emit(key);
    }

    onStatusMenuSelect(status: Objects.Status){
        this.statusAction.emit(status);
    }

    setLabels(labels: Array<Objects.Label>){
        console.log("new labels for version are", labels)
        // this.versionsSrv.setLabels(labels);
        this.versionsSrv.setLabels(labels);
    }

}
