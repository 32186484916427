// plugin-apollo-main
// PluginApolloMainComponent
import { Component, OnInit } from '@angular/core';
//import { apolloMenu } from '../../../apollo.menu';
import { ApolloService } from '../../../services/apollo/apollo.service';
import { AppService } from '@app/services/app/app.service';

@Component({
    selector: 'plugin-apollo-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class PluginApolloMainComponent implements OnInit {
    //public menuApollo:any = apolloMenu;
    public disabled:boolean;
    public menuApollo: any = null;

    public itemsCompare: any = null;

    public permission:boolean = false;

    constructor(private apollo: ApolloService, private appSrv: AppService){
        console.log(this.menuApollo);
    }

    ngOnInit(){
        this.apollo.getMenu().subscribe( tabs => {
            this.menuApollo = tabs;
        });

        this.appSrv.getSession().subscribe(session => {

            // for (let p of session.options.plugins){
            //     if (p === "apollo"){
            //         this.permission = true;
            //     }

            // }
            let apollo = session.options.plugins.filter(p => {
                if (p === "apollo"){
                    return p;
                }
            });
            apollo == "apollo" ? this.permission = true : this.permission = false;
        })

        // this.app.getSession().subscribe( (session: any) => {
        // this.apollo.getDisable().subscribe(
        //     (disabled:any) => {
        //         this.disabled = disabled;
        //         console.debug("disable", disabled);
        //     }
        // );
    }
}
