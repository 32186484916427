import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanDeactivate,
         ActivatedRouteSnapshot,
         RouterStateSnapshot } from '@angular/router';
import { ProjectAddMainComponent } from '../main/main.component';

@Injectable({
  providedIn: 'root',
})
export class CanLeaveAddProjectGuard implements CanDeactivate<ProjectAddMainComponent> {

  
  canDeactivate(
    component: ProjectAddMainComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // Get the current url
    // console.log("Get the current url route",route.url);

    // Get the current URL
    // console.debug("Get the current URL",state.url);

    // Allow synchronous navigation (`true`) if not guard
     // Meanwhile we ask the user with the modla feedback and set allowLeave 
    //  which resolves to true or false when the user decides
    // return if project is saved or modal set to true modalLeave;
    return (component.saved || component.allowLeave)    
  }
}
