import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LabelsModule } from '@core/components/labels/labels.module';

import { ProjectsDetailsHelpersModule } from '../../helpers/helpers.module';
import { ProjectDetailsLogbookEventsListComponent } from './list/events-list.component';
import { ProjectDetailsLogbookEventsListItemComponent } from './list-item/events-list-item.component';
import { ProjectDetailsLogbookEventModule } from '../event/logbook-event.module';
import { ProjectDetailsLogbookEventPropertiesModule } from '../event/properties/properties.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreModule,
        HelpersModule,
        LabelsModule,
        ProjectsDetailsHelpersModule,
        ProjectDetailsLogbookEventModule,
        ProjectDetailsLogbookEventPropertiesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsLogbookEventsListComponent,
        ProjectDetailsLogbookEventsListItemComponent,
        
    ],
    exports: [
        ProjectDetailsLogbookEventsListComponent,
        ProjectDetailsLogbookEventsListItemComponent,
    ],

})
export class ProjectDetailsLogbookEventsListModule { }
