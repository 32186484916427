import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { ApolloRouterModule } from '../../apollo.router';

import { ApolloMainSectionsModule } from './sections/sections.module';
import { PluginApolloMainComponent } from './main/main.component';
import { PluginApolloMainAppComponent } from './app/app.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        ApolloRouterModule,
        ApolloMainSectionsModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        PluginApolloMainComponent,
        PluginApolloMainAppComponent
    ],
    exports: [
        ApolloMainSectionsModule,
        PluginApolloMainComponent,
        PluginApolloMainAppComponent
    ],

})
export class ApolloMainModule { }
