import { HostListener, Component, EventEmitter, Input, Output, SimpleChanges, OnInit, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { HttpClient } from '@angular/common/http';
// import {Observable} from 'rxjs';
// import { share } from 'rxjs/operators';

// import { UrlService } from '@core/services/url/url.service';
import { User } from '@core/objects/user';

@Component({
    selector: 'app-helpers-forms-input-emails-autocomplete',
    templateUrl: './input-emails-autocomplete.component.html',
    styleUrls: ['./input-emails-autocomplete.component.scss'],
    providers: []
})
export class HelpersFormInputEmailAutocompleteComponent {

    @Input()  emails: Array<string> = [];
    @Output() emailsChange = new EventEmitter<Array<string>>();

    @Input() placeholder: string = "Start typing the email address";
    @Input() emailsControl: FormControl = new FormControl([]);

    public input;

    public showEmails = [];

    protected addEmail(email: string, name: string) {
        let data = {
            email: email,
            name: name
        }

        // check repeat
        this.showEmails = this.showEmails.filter(e => e.email !== data.email);

        this.showEmails.push(data);

        this.updateEmails();
    }

    onInputChange(event) {
        this.input = event;

        let emails = this.input.split(",");

        if(emails.length==1)
            emails = this.input.split(" ");

        // if(emails.length>1 && this.isEmail(emails[0])) {
        //     this.addEmail(emails[0], emails[0]);
        //     //this.input = ""; // clear input
        //     this.onClear();
        // }
        if(emails.length>1) {
            this.processInput(emails[0]);
        }
        // else {
        //     this.onAutocomplete();
        // }
    }

    protected processInput(email: string) : boolean {
        if(this.isEmail(email)) {
            this.addEmail(email, email);
            //this.input = ""; // clear input
            this.onClear();
            return true;
        }

        return false;
    }

    protected onClear() {
        this.input = "";
        // TODO remove autocomplete
    }

    onBackspace(event) {
        if(this.input === "")
            this.removeLastEmail();
    }

    onEnter(event) {
        event.preventDefault();
        this.processInput(this.input);

    }

    onRemove(data) {
        this.showEmails = this.showEmails.filter(e => e.email !== data.email);
        this.updateEmails();
    }

    protected removeLastEmail() {
        this.showEmails.splice(-1,1);
        this.updateEmails();
    }

    protected updateEmails() {
        this.emails = this.showEmails.map(e => e.email);
        this.emailsChange.emit(this.emails);
        this.emailsControl.setValue(this.emails);
    }

    selectUser(user: User) {
        this.addEmail(user.email, user.name);
        this.onClear();
    }


    protected isEmail(search: string):boolean
    {
        var  serchfind:boolean;
        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        serchfind = regexp.test(search);
        return serchfind
    }


}
