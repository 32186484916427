import { Entity } from '@core/objects/entity';

export interface SearchAvailableFilters {
    key: string;
    items: Array<Entity>;
}

export interface SearchAppliedFilter {
    key: string;
    item: Entity;
}

export interface SearchOutput {
    term: string;
    q: string
} 

export interface SearchQueryParams {
    q: string;
    term: string;
    sort_by: string;
}

export class SearchHttpStatus {
    static readonly ERROR: number = 0;
    static readonly INITIAL: number = 1;
    static readonly LOADING: number = 2;
    static readonly DONE: number = 3;
    static readonly FINAL: number = 4;
}

export class SearchConvert {
   static paramsFromFilters(filters: SearchAppliedFilter[], term: string, sort: string): SearchQueryParams | null {
        // if(term == "")
        //     return null;

        let query = filters.map((f: SearchAppliedFilter) => f.key + ":" + f.item.id).join("+");
        // console.log("query---->", query);
        return {
            term: term,
            // q: encodeURIComponent(query),
            q: query,
            sort_by: sort
        };
    }

    // remove void params
    static removeVoidParams(params: SearchQueryParams): any {
        if(params == null)
            return {};
            
        let query = {};

        Object.keys(params).forEach((key: string) => { if(params[key]!="" && params[key]!=null) query[key] = params[key]; });

        return query;
    }

    static paramsFromRouteParams(routeParams: any ) :  SearchQueryParams | null {
        if(!routeParams.hasOwnProperty('q') && !routeParams.hasOwnProperty('term'))
            return null;

        return {
            q: routeParams.hasOwnProperty('q')? routeParams.q : "",
            sort_by: routeParams.hasOwnProperty('sort_by')? routeParams.sort_by : "",
            term: routeParams.hasOwnProperty('term')? routeParams.term : ""
        };
    }

    // static cleanTerm(termWithAsterisks: string) : string {
    //     if (termWithAsterisks.length<3) return "";

    //     let term = termWithAsterisks.charAt(0)=="*"? termWithAsterisks.substring(1) : termWithAsterisks;

    //     term = term.charAt(term.length-1) == "*" ? term.substring(0, term.length -1) : term;

    //     return term;
    // }

    // queryFromFilters(filters: SearchAppliedFilter[], term: string, sort: string) : string {
    //     if (term == "")
    //         return "";

    //     let q = encodeURIComponent(filters.map((f: SearchAppliedFilter) => f.key + ":" + f.item.id).join("+"));
        
    //     // let q = q == ""? "" : "q="+f;

    //     let query = "term=*" + term +"*" + (q == ""? "" : "&q="+q);
    //     return query;
    // }

    static filtersFromParams(params: SearchQueryParams, availableFilters: SearchAvailableFilters) : Array<SearchAppliedFilter> {
        if (params == null || params.q == "" || params.q == null)
            return [];
        
        let filters = params.q.split("+").map((s: string) => { 
            let t = s.split(':');
            return { key: t[0], value: t[1]}
        });

        let applied = filters.filter(f => f.key == availableFilters.key).map(v => v.value);

        return availableFilters.items.filter((f: Entity) => applied.indexOf(f.id.toString())>-1).map((en: Entity) => { return { key: availableFilters.key, item: en}; });
        
        
    }

    // fromQueryToFilters(query: string, availableFilters: SearchAvailableFilters): SearchAppliedFilter[] {
    //     // TODO
    // }
}