
import { SettingsRules, initialState, settingsRulesEditingSelector } from './rules.state';
import { SettingsRulesActions, SettingsRulesActionType } from './rules.actions';
import { HttpStatus } from '@core/classes/http-status.class';

import { AvailableRuleItem, AvailableRuleActionVariable, AvailableRuleEventCondition, AvailableRuleAction, RuleEvent, RuleListItem } from '@app/models/rules';

import { AvailableRule, SettingsRulesEditScroll } from './rules.state';
import { AccessTokensService } from '@app/services/settings/access-tokens.service';

export function settingsRulesReducer(state = initialState, action: SettingsRulesActions): SettingsRules {
    switch (action.type) {
		// EVENTS STATUS

		// Users
		case SettingsRulesActionType.LoadAvailableEventsSuccess:

			let avents = action.events.map((e: AvailableRuleItem ) => ({
				event: e,
				conditions: [],
				actions: [] 
			}));

			// let eitems = action.events.map((e: AvailableRuleItem ) => ({
			// 	title: e.description,
			// 	value: e.key
			// }));  

			return {...state, availables: avents}

		case SettingsRulesActionType.LoadAvailableConditionsSuccess:

			return {
				...state, 
				availables: state.availables.map(
					(e: AvailableRule) => e.event.key == action.eventKey? {...e, conditions: action.conditions} : e 
				)
			};
		
		case SettingsRulesActionType.LoadAvailableActionsSuccess:

			return {
				...state, 
				availables: state.availables.map(
						(e: AvailableRule) => e.event.key == action.eventKey? {...e, actions: action.actions} : e 
				)
			};
		
		case SettingsRulesActionType.LoadRulesSuccess:

			return {...state, rules: action.rules};
		
		case SettingsRulesActionType.SetActiveRule:

			return {
				...state, 
				rules: state.rules.map(
					(r: RuleListItem) => r.id == action.ruleId ? {...r, active: action.active } : r
				)
			};
		
		case SettingsRulesActionType.SetActiveRuleError:

			return {
				...state, 
				rules: state.rules.map(
					(r: RuleListItem) => r.id == action.ruleId ? {...r, active: !action.active} : r
				)
			};
		
		case SettingsRulesActionType.LoadRuleSuccess:
		
			return {
				...state, 
				editing: action.rule
			};

		case SettingsRulesActionType.ClearRule:
	
			return {
				...state, 
				editing: initialState.editing,
				editing_scroll: []
			};
		
		case SettingsRulesActionType.SetRuleEventKey:

			return {
				...state, 
				editing: { ...initialState.editing, key: action.key, name: state.editing.name, id: state.editing.id, actions: [], conditions: [] }
			};
		
		case SettingsRulesActionType.SetRuleDescription:

			return {
				...state, 
				editing: { ...state.editing, name: action.description }
			};

		case SettingsRulesActionType.SetRuleConditionsControl:

			return {
				...state, 
				editing: { ...state.editing, conditions_control: action.control }
			};
			
		
		case SettingsRulesActionType.AddRuleAction:

			let available: Array<AvailableRule> = state.availables.filter((a: AvailableRule) => a.event.key == state.editing.key);

			if(available[0].actions.length==0)
				return state;

			let aaction: AvailableRuleAction  = available[0].actions[0];

			// console.log("aaacttiooonnn---->", aaction);
			let parameters = {};
			aaction.variables.forEach((v: AvailableRuleActionVariable) => {
				parameters[v.key] = v.type == 'select'? v.values[0].key.toString() : [];
				parameters[v.key] = typeof parameters[v.key] === 'string' && parameters[v.key].indexOf('disabled')>-1 ? "" : parameters[v.key];
			});

			let paction = {
				id: 0,
				key: aaction.key,
				parameters: parameters
			}
			
			let currentEdit = {...state.editing };

			if(currentEdit.actions.hasOwnProperty(action.position)) {
				currentEdit.actions.splice(action.position, 0, paction);
			}
			else {
				// state.editing.actions[action.position] = paction;
				currentEdit.actions.push(paction);
			}


			return {
				...state,
				editing: {
					...state.editing,
					actions: currentEdit.actions
				}
			}
		
		case SettingsRulesActionType.ChangeRuleAction:
			if(state.editing.actions[action.position].key != action.action.key)
				return state;

			state.editing.actions[action.position] = action.action;

			return state;
		
		case SettingsRulesActionType.ChangeRuleActionKey:
			let available1: Array<AvailableRule> = state.availables.filter((a: AvailableRule) => a.event.key == state.editing.key);

			if(available1[0].actions.length==0)
				return state;

			let aaactions: Array<AvailableRuleAction>  = available1[0].actions.filter((a: AvailableRuleAction) => a.key == action.key);

			if(aaactions.length == 0)
				return state;

			
			let pparameters = {};
			aaactions[0].variables.forEach((v: AvailableRuleActionVariable) => {
				pparameters[v.key] = v.type == 'select'? v.values[0].key.toString() : [];
				pparameters[v.key] = typeof pparameters[v.key] === 'string' && pparameters[v.key].indexOf('disabled')>-1 ? "" : pparameters[v.key];
			});

			let ppaction = {
				id: 0,
				key: aaactions[0].key,
				parameters: pparameters
			}
			
			state.editing.actions[action.position] = ppaction;

			return state;
		
		case SettingsRulesActionType.RemoveRuleAction:
			if(state.editing.actions.length < 2)
				return state;

			// state.editing.actions.splice(action.position, 1);

			return {
				...state,
				editing: {
					...state.editing,
					actions: state.editing.actions.filter((e: any, index: number) => index !== action.position)
				}
			}
		
		case SettingsRulesActionType.AddRuleCondition:

			let aavailable: Array<AvailableRule> = state.availables.filter((a: AvailableRule) => a.event.key == state.editing.key);

			if(aavailable[0].conditions.length==0)
				return state;

			let acondition: AvailableRuleEventCondition  = aavailable[0].conditions[0];

			console.log("condition---->", acondition);
			let pcondition = {
				variable: acondition.key,
				condition: acondition.options[0].key,
				value: acondition.values[0].key.toString()
			}
			
			if(state.editing.actions.hasOwnProperty(action.position)) {
				state.editing.conditions.splice(action.position, 0, pcondition);
			}
			else {
				// state.editing.actions[action.position] = paction;
				state.editing.conditions.push(pcondition);
			}


			return state;

		case SettingsRulesActionType.ChangeRuleCondition:
			// if `variable` is different we need dispatch new event in effects
			if(state.editing.conditions[action.position].variable != action.condition.variable)
				return state;

			// console.log("NOORRRRR");
			state.editing.conditions[action.position] = action.condition;

			return state;
		
		case SettingsRulesActionType.ChangeRuleConditionVariable:
			let aaavailable: Array<AvailableRule> = state.availables.filter((a: AvailableRule) => a.event.key == state.editing.key);

			if(aaavailable[0].conditions.length==0)
				return state;

			let aacondition: AvailableRuleEventCondition[]  = aaavailable[0].conditions.filter((c: AvailableRuleEventCondition) => action.variable == c.key);

			if(aacondition.length == 0)
				return state;

			// console.log("variable condition---->", aacondition);
			let ppcondition = {
				variable: action.variable,
				condition: aacondition[0].options[0].key,
				value: aacondition[0].values[0].key.toString()
			}
			
			
			state.editing.conditions[action.position] = ppcondition;

			return state;
		
		case SettingsRulesActionType.RemoveRuleCondition:
			return {
				...state,
				editing: {
					...state.editing,
					conditions: state.editing.conditions.filter((e: any, index: number) => index !== action.position)
				}
			}

		case SettingsRulesActionType.SaveRuleSuccess:
	
			return {
				...state, 
				editing: action.rule
			};
		
		case SettingsRulesActionType.SetEditSelectScroll:

			let scroll = state.editing_scroll.filter((e: SettingsRulesEditScroll) => e.key != action.key);
			
			scroll.push({
				key: action.key,
				value: action.scroll
			});

			return {
				...state, 
				editing_scroll: scroll
			};
			
		// case SettingsRulesActionType.LoadAllUsersError:
		// 	return {...state, status: { ...state.status, list: new HttpStatus(HttpStatus.ERROR, "")}};

		// // Invitations
		// case SettingsRulesActionType.LoadAllInvitations:
		// 	return {...state, status: { ...state.status, invite: new HttpStatus(HttpStatus.PENDING, "")}};

		// case SettingsRulesActionType.LoadAllInvitationsSuccess:
		// 	return {...state, status: { ...state.status, invite: new HttpStatus(HttpStatus.DONE, "")}};

		// case SettingsRulesActionType.LoadAllInvitationsError:
		// 	return {
		// 		...state,
		// 		status: {
		// 			...state.status,
		// 			invite: new HttpStatus(HttpStatus.ERROR, action.error.status.toString())
		// 		}};

		// // AddInvitation
		// case SettingsRulesActionType.AddNewInvitation:
		// 	return {...state, status: { ...state.status, addInvite: new HttpStatus(HttpStatus.PENDING, "")}};

		// case SettingsRulesActionType.AddNewInvitationSuccess:
		// 	return {...state, status: { ...state.status, addInvite: new HttpStatus(HttpStatus.DONE, "")}};

		// case SettingsRulesActionType.AddNewInvitationError:
		// 	return {
		// 		...state,
		// 		status: {
		// 			...state.status,
		// 			addInvite: new HttpStatus(HttpStatus.ERROR, action.error.status.toString())
		// 		}};


		// // DeleteUser
		// case SettingsRulesActionType.DeleteOneUser:
		// 	return {...state, status: { ...state.status, delUser: new HttpStatus(HttpStatus.PENDING, "")}};

		// case SettingsRulesActionType.DeleteOneUserSuccess:
		// 	return {...state, status: { ...state.status, delUser: new HttpStatus(HttpStatus.DONE, "")}};

		// case SettingsRulesActionType.DeleteOneUserError:
		// 	return {...state, status: { ...state.status, delUser: new HttpStatus(HttpStatus.ERROR, "")}};

		// // DeleteInvitation
		// case SettingsRulesActionType.DeleteInvitation:
		// 	return {...state, status: { ...state.status, delInvite: new HttpStatus(HttpStatus.PENDING, "")}};

		// case SettingsRulesActionType.DeleteInvitationSuccess:
		// 	return {...state, status: { ...state.status, delInvite: new HttpStatus(HttpStatus.DONE, "")}};

		// case SettingsRulesActionType.DeleteInvitationError:
		// 	return {...state, status: { ...state.status, delInvite: new HttpStatus(HttpStatus.ERROR, "")}};

		// case SettingsRulesActionType.SetAllUsers:
		// 	return {
		// 		...state,
		// 		users: action.users
		// 	};

		// case SettingsRulesActionType.SetAllInvitations:

        //     // let deleted = state.invitations.filter(i => action.invitations.indexOf(i) < 0);
        //     // let added   = action.invitations.filter(i => state.invitations.indexOf(i) < 0);
        //     //
        //     // console.log(deleted, added);
        //     //
        //     // for(let e of deleted) {
        //     //     var i = state.invitations.indexOf(e);
        //     //     state.invitations.splice(i, 1);
        //     // }
        //     //
        //     // //state.invitations.concat(added);
        //     // for(let e of added) {
        //     //     state.invitations.push(e);
        //     // }
        //     //
        //     // return state;

		// 	return {
		// 		...state,
		// 		invitations: action.invitations
		// 	};


	    default:
	        return state;
    }
}
