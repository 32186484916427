import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, empty, of, throwError, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, distinctUntilChanged, retryWhen } from 'rxjs/operators';

import { GroupsRequests } from './groups.requests';
import { Group } from '@core/objects/group';

import * as fromStore from './groups.state';


import {
    SettingsGroupsActions,
    SettingsGroupsActionType,
    LoadAllGroups,
    LoadAllGroupsSuccess,
    LoadAllGroupsError,
    SaveGroup,
    SaveGroupSuccess,
    SaveGroupError,
    ActiveGroup,
    DeleteGroup,
    SetAllGroups
} from './groups.actions';

@Injectable()
export class SettingsGroupsEffects {

    constructor(private actions$: Actions, private store$: Store<fromStore.SettingsGroups>, private groupsReq: GroupsRequests) {}

    @Effect()
    public loadAllGroups$: Observable<SettingsGroupsActions> =  this.actions$.pipe(
        ofType<LoadAllGroups>(SettingsGroupsActionType.LoadAllGroups),
        switchMap(() => {
            return this.groupsReq.loadAllGroups$().pipe(   
                map((groups: Group[]) => {
                    return new LoadAllGroupsSuccess(groups)
                }),
                catchError((error) => {
                    console.log(error);
                    throwError(error);
                    return of(new LoadAllGroupsError());
                })
            )
        }),
    );

    @Effect()
    public saveGroup$: Observable<SettingsGroupsActions> =  this.actions$.pipe(
        ofType<SaveGroup>(SettingsGroupsActionType.SaveGroup),
        switchMap((action) => {
            return this.groupsReq.saveGroup$(action.name).pipe(   
                map((group: Group) => {
                    return new SaveGroupSuccess(group)
                }),
                catchError((error) => {
                    console.log(error);
                    throwError(error);
                    return of(new SaveGroupError());
                })
            )
        }),
    );


    @Effect()
    public saveGroupSuccess$: Observable<SettingsGroupsActions> = this.actions$.pipe(
        ofType<SaveGroupSuccess>(SettingsGroupsActionType.SaveGroupSuccess),
        mergeMap((action) => {
            return of(new LoadAllGroups());
        })
    );

    @Effect()
    public activeGroup$: Observable<SettingsGroupsActions> =  this.actions$.pipe(
        ofType<ActiveGroup>(SettingsGroupsActionType.ActiveGroup),
        switchMap((action) => {
            action.group.active = true;
            return this.groupsReq.updateGroup$(action.group).pipe(   
                map(() => {
                    return new LoadAllGroups();
                }),
                // catchError((error) => {
                //     // console.log(error);
                //     // throwError(error);
                //     // return of(new SaveGroupError());
                // })
            )
        }),
    );

    @Effect()
    public deleteGroup$: Observable<SettingsGroupsActions> =  this.actions$.pipe(
        ofType<DeleteGroup>(SettingsGroupsActionType.DeleteGroup),
        switchMap((action) => {
            return this.groupsReq.deleteGroup$(action.group).pipe(   
                map(() => {
                    return new LoadAllGroups();
                }),
                // catchError((error) => {
                //     // console.log(error);
                //     // throwError(error);
                //     // return of(new SaveGroupError());
                // })
            )
        }),
    );

    @Effect()
    public loadAllGroupsSuccess$: Observable<SettingsGroupsActions> = this.actions$.pipe(
        ofType<LoadAllGroupsSuccess>(SettingsGroupsActionType.LoadAllGroupsSuccess),
        mergeMap((action) => {
            return of(new SetAllGroups(action.groups));
        })
    );
}
