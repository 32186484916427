import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';
import { PreviewsService } from '@core/services/previews/previews.service';
import { File } from '@core/objects/file';

import { AppService } from '@app/services/app/app.service';

class AvatarDragEditor {

    protected position: any;
    public diff: any;

    constructor(x: number, y: number) {
        this.position = {
            x: x,
            y: y
        };

        this.diff = {
            x: 0,
            y: 0
        }
    }

    setPosition(x: number, y: number) {
        this.diff = {
            x: x - this.position.x,
            y: y - this.position.y
        };

        console.log('position-->', this.position);
        this.position = {
            x: x,
            y: y
        }

        console.log('drag -->', this.diff, this.position);
    }
}

class AvatarEditor {
    public sizes: any;
    public position: any;
    public styles: any;
    public zoom: any;
    protected lastZoom: number = null;
    protected dragger: AvatarDragEditor;

    protected imageLoad: boolean = false;

    constructor() {
        this.sizes = {
            original: {width: 0, height: 0},
            image: {width: 0, height: 0},
            area: {width: 0, height: 0}
        }

        this.position = {
            // relative: {
            //     top: 0,
            //     left: 0
            // },

            image: {
                left: 0,
                top: 0
            }
        };

        this.zoom = {
            relative: 0,
            current: 0
        }

        this.styles = {
            image: {
                'height.px': 0,
                'width.px': 0,
                'top.px': 0,
                'left.px':0
            }
        }

        this.dragger = null;
    }

    setAreaSize(width: number, height: number) {
        this.sizes.area = {
            width: width,
            height: height
        };

        this.update();
    }

    setOriginalSize(width: number, height: number) {
        this.sizes.original = {
            width: width,
            height: height
        };

        this.imageLoad = true;

        this.update();
    }

    setZoom(zoom: number) {
        this.lastZoom = zoom;
        this.zoom.relative = Math.round((this.zoom.relative + zoom)*10)/10;

        if(this.zoom.relative>1)
            this.zoom.relative = 1;

        if(this.zoom.relative<0)
            this.zoom.relative = 0;

        this.update();
    }

    drag(eventName, event) {
        if(this.dragger!=null)
            console.log(eventName + "-->", event, this);

        switch(eventName) {
            case 'start':
                this.dragger = new AvatarDragEditor(event.screenX, event.screenY);
                break;
            case 'move':
                if(this.dragger!= null) {
                    this.dragger.setPosition(event.screenX, event.screenY);
                }
                break;
            case 'stop':
                this.dragger = null;
                break;
        }

        if(this.dragger!=null)
            this.update();
    }

    update() {

        let minp = this.sizes.area.height/this.sizes.original.height;
        let diffpu = (1-minp) / 10; // diferencia de porcentaje mínimo entre diez partes (0, 0.1, 0.2... hasta 1)
        let sump = diffpu * (10*this.zoom.relative);

        this.zoom.current = minp + sump;

        this.sizes.image = {
            height: this.sizes.original.height * this.zoom.current, //this.sizes.area.height,
            width: this.sizes.original.width*this.zoom.current //(this.sizes.original.width*this.sizes.area.height) / this.sizes.original.height
        }

        // center image on init
        if(this.imageLoad) {
            this.position.image = {
                top: 0,
                left: (this.sizes.area.width-this.sizes.image.width)/2
            };

            this.imageLoad = false;
        }

        // if zoomed (center image if zoom)
        let offsetPosition = {
            top: 0,
            left: 0
        };

        if(this.lastZoom != null) {
            // calc previous width
            let psump = diffpu * (10*(this.zoom.relative - this.lastZoom));
            let previousZoom = minp + psump;
            let previousSizes = {
                width: this.sizes.original.width * previousZoom,
                height: this.sizes.original.height * previousZoom
            }

            offsetPosition = {
                top: (previousSizes.height - this.sizes.image.height) / 2,
                left: (previousSizes.width - this.sizes.image.width) / 2
            }

            this.lastZoom = 0;
        }
        // end zoomed

        // fix position with zoom

        this.position.image = {
            top: this.position.image.top + offsetPosition.top,
            left: this.position.image.left + offsetPosition.left,
        }

        if(this.dragger!= null) {
            this.position.image = {
                top: this.position.image.top + this.dragger.diff.y,
                left: this.position.image.left + this.dragger.diff.x
            }
        }

        // positions limits
        let maxLeft = (this.sizes.area.width/2) - (this.sizes.area.height/2);
        let minLeft = maxLeft + this.sizes.area.height - this.sizes.image.width;
        let minTop = -(this.sizes.image.height-this.sizes.area.height);
        let maxTop = 0;

        if(this.position.image.left > maxLeft)
            this.position.image.left = maxLeft;

        if(this.position.image.left < minLeft)
            this.position.image.left = minLeft;

        if(this.position.image.top > maxTop)
            this.position.image.top = maxTop;

        if(this.position.image.top < minTop)
            this.position.image.top = minTop;

        this.updateStyle();
    }

    updateStyle() {
        this.styles.image = {
            'height.px': this.sizes.image.height,
            'width.px': this.sizes.image.width,
            'top.px': this.position.image.top,
            'left.px': this.position.image.left
        }

        // console.log("editor update-->", this);
    }

    getOptions() : any {
        let top: number = (this.sizes.original.height * Math.abs(this.position.image.top)) / this.sizes.image.height;

        let spaceBetween = (this.sizes.area.width/2) - (this.sizes.area.height/2);
        let offsetLeft = this.position.image.left<0? spaceBetween + Math.abs(this.position.image.left) : spaceBetween - this.position.image.left;
        let left: number = (this.sizes.original.width * offsetLeft) / this.sizes.image.width;

        let width = (this.sizes.original.height * Math.abs(this.sizes.area.height)) / this.sizes.image.height;
        let height = width; //square image

        // absolut crop / original = percent
        let crop = {
            top: Math.round(top) / this.sizes.original.height,
            left: Math.round(left) / this.sizes.original.width,
            width: Math.round(width) / this.sizes.original.width,
            height: Math.round(height) / this.sizes.original.height
        };

        return {
            crop: crop
        }


    }

    setOptions(options: any) {
        // apply crop
        if(options.hasOwnProperty('crop')) {
            // set zoom
            // let minp = this.sizes.area.height/this.sizes.original.height;
            // let diffpu = (1-minp) / 10; // diferencia de porcentaje mínimo entre diez partes (0, 0.1, 0.2... hasta 1)
            // // let sump = diffpu * (10*this.zoom.relative);

            //let pcurrent = this.sizes.area.height/options.crop.height;
            this.sizes.image.height = this.sizes.area.height/options.crop.height;
            // this.sizes.original.height * (1-options.crop.height);
            // this.sizes.image.width = this.sizes.original.width * (1-options.crop.width);
            this.sizes.image.width = this.sizes.area.height/options.crop.width;

            let left = this.sizes.image.width * options.crop.left;
            let maxLeft = (this.sizes.area.width/2) - (this.sizes.area.height/2);

            // left = left > maxLeft? maxLeft - left : maxLeft - left ;
            left = maxLeft - left;
            
            let spaceBetween = (this.sizes.area.width/2) - (this.sizes.area.height/2);
            let top = -1*(this.sizes.image.height * options.crop.top);
            this.position.image.left = left;
            this.position.image.top = top;

            // zoom
            let minp = this.sizes.area.height/this.sizes.original.height;
            let diffpu = (1-minp) / 10; // diferencia de porcentaje mínimo entre diez partes (0, 0.1, 0.2... hasta 1)

            this.zoom.current = this.sizes.image.height / this.sizes.original.height;
            // this.zoom.relative = Math.round((this.zoom.current/diffpu)*10)/10;
            this.zoom.relative = Math.round((this.zoom.current-minp)/diffpu)/10;
            console.log("zooooom--->",this);

            this.lastZoom = 0;
            // this.zoom.current = minp + sump;
        }

        // this.update();
        this.updateStyle();
    }

}

@Component({
    selector: 'profile-avatar-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss']
})
export class ProfileAvatarEditComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_FOUND = 2;

    @ViewChild('drag') area: ElementRef;

    @Input() file: File;
    @Input() options: any;

    @Output() cancel = new EventEmitter<any>();
    @Output() save = new EventEmitter<any>();

    protected previewSubscriptor$: Subscription;
    // image properties
    public urlImage:string = null; //URL image
    protected img: any; // object for load image and get original sizes;
    public imgLoaded: boolean = false;
    // public imgFullLoaded: boolean = false;
    protected imgRendered = false;

    public editor: AvatarEditor;
    public styles: any;

    constructor(public urlSrv: UrlService, protected prwSrv: PreviewsService) {
        this.editor = new AvatarEditor();

        this.styles = {
            area: {
                'transform-origin': "0% 0%",
                'background-size': "100%",
                'background-position-x.px': 0,
                'background-position-y.px': 0,
                'transform': null,
                'width': 'inherit'
            },
            container: {
                //'width.px': 0, // nee, this size is always 100%
                'height.px': 0
            },
            image: {
                'height.px': 250,
                'width': 'inherit'
            }
        };
    }

	ngOnInit(){
        this.previewSubscriptor$ = this.prwSrv.getSubscriptor(this.file).subscribe(p => {
            if(p.status_code == 1)
                this.urlImage = this.urlSrv.build('/files/'+this.file.id+'/preview/big-width/1');
        });

        this.prwSrv.load(this.file);

        // this.img = new Image();
        // /* LISTENERS */

        // // listener for get original image size
        // this.img.onload = () => {
        //     this.urlImage = this.img.src;

        //     // get sizes
        //     if(!this.imgLoaded) {
        //         // this.init();
        //         this.editor.size = {
        //             width: this.img.width,
        //             height: this.img.height
        //         };

        //         // this.setImageUrl('big-width');
        //     }

        //     this.onImageLoaded();

        //     // TODO quit this timeout
        //     //setTimeout(()=>{
        //     //    this.applyFilters();
        //     //}, 100);
        // };

        /* CODE */


        // this.loadImage();
    }

    ngOnDestroy() {
        this.previewSubscriptor$.unsubscribe();
    }

    imageLoaded(event) {
        this.onImageLoaded();
        // console.log("loaded--->", event.currentTarget.width);
        // // this.editor.size = {
        // //     width: event.currentTarget.width,
        // //     height: event.currentTarget.height
        // // };

        // console.log("areaaaa--->", this.area.nativeElement);

        this.editor.setOriginalSize(event.currentTarget.width, event.currentTarget.height);
        this.editor.setOptions(this.options);


    }

    resizeArea(event) {
        this.editor.setAreaSize(event.width, event.height);
    }

    /**
     * Request load image
     **/
    //  protected loadImage() {
    //     this.imgRendered = false;
    //     this.imgLoaded = false;
    //     // this.imgFullLoaded = false;
    //     this.setImageUrl('big-width');
    // }

    /**
     * Set img src
     **/
    //  protected setImageUrl(type: string){
    //     this.img.src = this.urlSrv.build('/files/'+this.file.id+'/preview/' + type + '/1');
    // }

    /**
     * Dispatched when image is loaded
     **/
     protected onImageLoaded() {
        // this.initFilters();
        // this.emitFilterChanges();

        // if(this.imgLoaded) {
        //     this.imgFullLoaded = true;
        // }

        if(!this.imgLoaded) {
            this.imgLoaded = true;
        }
    }

    // startDragInCropArea(event) {
    //     // is into the white circle??

    //     let min = (this.editor.sizes.area.width/2) - (this.editor.sizes.area.height/2);
    //     let max = (this.editor.sizes.area.width/2) + this.editor.sizes.area.height;

    //     if(event.offsetX > min && event.offsetX < max)
    //         console.log("cliiiiiick--->", event);
    // }

    submit() {
        console.log("submit", this.editor.getOptions());
        this.save.emit(this.editor.getOptions());

    }

    onCancel() {
        this.cancel.emit(true);
    }
}
