import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRouterModule } from '@app/router.module';

import { SettingsLabelsGroupsMainComponent } from './main/main.component';
import { SettingsLabelsGroupsAddFormComponent } from './add-form/add-form.component';



@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SettingsLabelsGroupsMainComponent,
        SettingsLabelsGroupsAddFormComponent
    ],
    exports: [
        SettingsLabelsGroupsMainComponent,
        SettingsLabelsGroupsAddFormComponent
    ],

})
export class SettingsLabelsGroupsModule { }
