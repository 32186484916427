import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';


import { AppService } from '@app/services/app/app.service';
import { UrlService } from '@core/services/url/url.service';
import { VersionsEditorService, VersionsViewService } from '@app/services/app/versions.service';

import * as Objects from '@core/objects';
import { AccessVersion } from '@app/models/access';

import { VersionFeedback } from '@core/models/version-feedback';
import { VersionFeedbackMessage } from '@core/models/version-feedback-message';

@Component({
    selector: 'version-details-content-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class VersionsDetailsContentMessagesComponent implements OnInit, OnDestroy, OnChanges {


    @Input() version: Objects.Version;
    @Input() feedbackAccess: boolean = false;
    @Input() feedbacks: Array<VersionFeedback>;

    public access: AccessVersion;

    public user: Objects.User = null;
    public versionMessages: Array<Objects.VersionMessage> = null;
    public messagesList: Array<VersionFeedbackMessage> = [];
    public prefixUrl: string = '';
    public messageAddUrl: string = '';

    protected messagesSubscriptor: Subscription;
    protected appSubscriptor: Subscription;
    protected accessSubscriptor: Subscription;

    constructor(
        public appSrv: AppService,
        private versionsViewSrv: VersionsViewService, 
        private urlSrv: UrlService,
        private editorVersionSrv: VersionsEditorService 
    ) { }

    ngOnInit() {
        this.prefixUrl = "/versions/"+ this.version.id;
        this.messageAddUrl = this.urlSrv.build(this.prefixUrl + "/messages");

        this.appSubscriptor = this.appSrv.getSession().subscribe(session => this.user = session.user);

        this.messagesSubscriptor = this.versionsViewSrv.selectComments().subscribe((messages: Array<Objects.VersionMessage>) => {
            this.versionMessages = messages;
            
            this.updateMessageList();
        });

        if(!this.feedbackAccess){
            this.messagesList = this.versionMessages.map((msg: Objects.VersionMessage) => {  return{message: msg.message, date:msg.message.date } });
        }

        this.accessSubscriptor = this.editorVersionSrv.selectAccessAll().subscribe((access: AccessVersion)=> this.access = access);
    }

    ngOnDestroy() {
        this.appSubscriptor.unsubscribe();
        this.messagesSubscriptor.unsubscribe();
        this.accessSubscriptor.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.feedbacks){
            if(this.feedbackAccess)
                this.updateMessageList();
        }

    }


    onUpdateMessages(message: Objects.Message) {
        this.versionsViewSrv.loadComments();
    }

    onDeleteMessage(message: Objects.Message) {
        this.versionsViewSrv.loadComments();
    }

    protected updateMessageList() : void {
        if(this.versionMessages == null)
            return;


        let msgList: VersionFeedbackMessage[]  =  this.versionMessages.map((msg: Objects.VersionMessage) => {
            if (msg.context == 'feedback') {
                let feedbacks = (this.feedbacks.length > 0) ? this.feedbacks.filter((f:VersionFeedback) => f.user.id == msg.message.user.id) : null;
                if(feedbacks.length > 0){
                    return {message: msg.message,feedback: feedbacks[0], date:msg.message.date };
                } else {
                    //console.log("Raro si hay este caso:",feedbacks);
                    return {message: msg.message, date:msg.message.date };
                }
            } else {
                return {message: msg.message, date:msg.message.date };
            }
        });
        let approvalList:VersionFeedbackMessage[] = this.feedbacks.filter((f:VersionFeedback) => f.approval != null && f.approval.status_code != 0)
                                         .map((f:VersionFeedback) => {
                                             return {message: null, feedback: f, date:f.approval.date};
                                         });

        this.messagesList = [...msgList,...approvalList];

        // console.debug(" VersionFeedbackMsgs to display ===>",this.messagesList);

        
    }




}
