import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// import { Subscription } from 'rxjs';

// import { ProjectsEditorService } from 'app/services/app/projects.service';



import * as Objects from '@core/objects';


@Component({
    selector: 'dashboard-views-table-details-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class DashboardViewsTableDetailsMainComponent implements OnInit, OnDestroy {

    @Input() project: Objects.ExtendedRequest;

    // protected subscriptor: Subscription;

    constructor(
        // private editorSrv: ProjectsEditorService
    ){}

    ngOnInit(): void {
        // pendign doing things
        
    }

    ngOnDestroy(): void {
        // this.subscriptor.unsubscribe();
    }
}