import { Component, Input , Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'core-helpers-box-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss']
})
export class HelperBoxListItemComponent {

    @Input() close: boolean = false;
    @Output() onCloseClick = new EventEmitter<any>();

    @Input() mainText: string;
    @Output() onMainTextClick = new EventEmitter<any>();

    @Input() actionText: string = "";
    @Output() onActionTextClick = new EventEmitter<any>();

    @Input() rightText: string = "";
    @Output() onRightTextClick = new EventEmitter<any>();


    
}
