import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';

@Component({
    selector: 'core-files-list-download',
    templateUrl: './files-list-download.component.html',
    styleUrls: ['./files-list-download.component.scss']
})
export class FilesListDownloadComponent {

    @Input() files: any;

    @Output() onClick = new EventEmitter<any>();

    public selected:boolean;

    constructor(public urlService: UrlService) { }

    click(file: any, i) {
        this.onClick.emit(file);
        this.selected = i;
    }

    download(file: any) {
        window.location.href = this.urlService.build('/files/'+file.id);
    }

}
