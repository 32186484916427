import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { VersionsViewService, VersionsEditorService, VersionsService } from '@app/services/app/versions.service';
import { AppService } from '@app/services/app/app.service';
import { UrlService } from '@core/services/url/url.service';


import * as Objects from '@core/objects';

import { AccessVersion } from '@app/models/access';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';
import { AppRouterService } from '@app/services/app/router.service';

import { VersionFeedback } from '@core/models/version-feedback';

@Component({
    selector: 'version-details-content',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class VersionsDetailsContentMainComponent implements OnInit, OnDestroy {
    protected readonly PLUGIN_SEND_FILES = 'share_transfer';
    protected readonly PLUGIN_FEEDBACK = 'share_feedback';
    protected readonly PLUGIN_APOLLO = 'apollo';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_ERROR = 2;

    @Input() project: Objects.Request = null;
    public version: Objects.Version = null;

    public pluginsAccess: Array<string> = [];
    public access: AccessVersion;
    public accessFeedbacks: boolean = null;
    public feedbacks: Array<VersionFeedback> = null;
    /*
    access_version_write_comments: boolean;
    access_version_read_comments: boolean;
    access_version_change_status: boolean;
    access_version_original_name: boolean;
    access_version_upload_no_file: boolean;
    access_version_edit_labels: boolean;
     */

    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "Download File",
            key: "download",
            icon: "download",
            order: 1
        }
    ];

    protected menuSendFiles: ThreeDotsMenuItem = {
        title: "Send",
        key: "send-files",
        icon: "send-files",
        order: 2
    };
    protected menuCompare: ThreeDotsMenuItem = {
        title: "Compare",
        key: "compare",
        icon: "compare",
        order: 3
    };

    public status:number = this.STATUS_LOADING;

    protected accessSubscriptor: Subscription;
    protected versionSubscriptor: Subscription;
    protected appSubscriptor: Subscription;

    constructor(
        private app: AppService,
        private urlSrv: UrlService,
        private versionsSrv: VersionsService,
        private editorVersionSrv: VersionsEditorService,
        private viewVersionSrv: VersionsViewService,
        private appRouter: AppRouterService
    ) { }

    ngOnInit() {


        this.versionSubscriptor = this.viewVersionSrv.selectVersion().subscribe((version: Objects.Version)=> {
            this.version = version;
            this.initView();
        });

        this.accessSubscriptor = this.editorVersionSrv.selectAccessAll().subscribe((access: AccessVersion)=> this.access = access);

        this.appSubscriptor = this.app.getSession().subscribe( (session: any) => {
            this.pluginsAccess = session.options.plugins;
            this.updateMenu(this.menuSendFiles,this.checkPluginAccess(this.PLUGIN_SEND_FILES))
            this.updateMenu(this.menuCompare,this.checkPluginAccess(this.PLUGIN_APOLLO));
            this.accessFeedbacks = this.checkPluginAccess(this.PLUGIN_FEEDBACK);
            if(this.accessFeedbacks)
                this.loadFeedbacks();
            else 
                this.initView();
        });
    }

    initView(){
        if(this.version == null)
            return;
        if ((this.accessFeedbacks != null && !this.accessFeedbacks) || (this.accessFeedbacks && this.feedbacks != null))
            this.status = this.STATUS_DONE;
    }

    ngOnDestroy() {
        this.versionSubscriptor.unsubscribe();
        this.accessSubscriptor.unsubscribe();
        this.appSubscriptor.unsubscribe();
    }

    onMenuClick(menu: FilesListItemMenuClick) {
        switch(menu.key) {
            case "download":
                console.debug(" download click on file ", menu.file)
                window.location.href = this.urlSrv.build('/files/'+menu.file.id);
                break;
            case "send-files":
                if (this.checkPluginAccess(this.PLUGIN_SEND_FILES))
                    this.appRouter.navigate('/p/transfer/'+this.project.id, { queryParams: { file: +menu.file.id} });
                break;
            case "compare":
                if (this.checkPluginAccess(this.PLUGIN_APOLLO))
                    this.appRouter.navigate('/p/apollo/request/'+this.project.id);//, { queryParams: { reference: +menu.file.id} });
                break;
        }
    }

    public onFeedbackDone() : void {
        if(this.accessFeedbacks)
            this.loadFeedbacks();
        else 
            this.initView();
    }

    protected checkPluginAccess(plugin: string){
        return this.pluginsAccess.some((pa) => pa == plugin);
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

    protected loadFeedbacks(){
        this.versionsSrv.getFeedbacks$([this.version]).subscribe((feedbacks: Array<VersionFeedback>) => {
            this.feedbacks = feedbacks;
            this.initView();
        });
    }

}
