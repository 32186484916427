import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
// import { LabelsModule } from '@core/components/labels/labels.module';

// import { AppRouterModule } from '@app/router.module';

import { ProjectDetailsInfoAttachmentsComponent } from './main/main.component';
import { ProjectDetailsInfoAttachmentsListComponent } from './list/list.component';
import { ProjectDetailsInfoAttachmentsUploadComponent } from './upload/upload.component';
import { FilesListModule } from '@core/components/files/lists/lists.module';


@NgModule({
    imports: [
        CommonModule,
        FilesListModule,
        // FormsModule,
        // ReactiveFormsModule,
        CoreModule,
        HelpersModule,
        // LabelsModule,
        // AppVersionsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsInfoAttachmentsComponent,
        ProjectDetailsInfoAttachmentsListComponent,
        ProjectDetailsInfoAttachmentsUploadComponent
        
    ],
    exports: [
        ProjectDetailsInfoAttachmentsComponent
    ],

})
export class ProjectsDetailsInfoAttachmentModule { }
