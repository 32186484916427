import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'sortByMenuOrder' })
export class SortByPipeMenuOrder implements PipeTransform {

	transform(items: any[], order = 'asc'): any[] {
    	if (!items || order === '') { return items; } // no array
		if (items.length <= 1) { return items; } // array with only one item
		return _.orderBy(items, [item => item.order], [order]);
  	}
}