import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UrlService {
    public prefix: string;

    public setPrefix(prefix: string){
        this.prefix = prefix;
    }

    public build(url: string): string{
        return environment.twonaBaseUrl + this.prefix + url;
    }
}
