import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LabelsModule } from '@core/components/labels/labels.module';
import { PipesModule } from '@core/pipes/pipes.module'

import { HelpersSearchFiltersLabelsMainComponent } from './main/main.component';
import { HelpersSearchFiltersLabelsGroupComponent } from './group/group.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LabelsModule,
        PipesModule

    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        HelpersSearchFiltersLabelsMainComponent,
        HelpersSearchFiltersLabelsGroupComponent
    ],
    exports: [
        HelpersSearchFiltersLabelsMainComponent
    ],

})
export class AppHelpersSearchFiltersLabelsModule { }