import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'usersSortByName' })
export class UsersSortByNamePipe implements PipeTransform {

  	transform(users: any[], order = ''): any[] {
    	if (!users || order === '') { return users; } // no array
		if (users.length <= 1) { return users; } // array with only one item
		return _.orderBy(users, [user => user.name.toLowerCase()], [order]);
  	}
}