import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, empty, of, throwError, Subscription } from 'rxjs';
import { catchError, map, concatMap, mergeMap, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import * as Objects from '@core/objects';

// import { Request } from '@core/objects/request';
// import * as Object from '@core/objects/file';
// import { Version } from '@core/objects/version';
// import { Message } from '@core/objects/message';
// import { Related } from '@core/objects/related';
// import { User } from '@core/objects/user';

import * as Access from '@app/models/access';

import { ProjectsRequests } from './projects.requests';

import * as fromStore from './projects.state';
import * as fromActions from './projects.actions';

// import fromActions to avoid duplicated code here.

@Injectable()
export class ProjectsEffects {

    constructor(private actions$: Actions, private store$: Store<fromStore.Projects>, private requests: ProjectsRequests) {}

    @Effect()
    public loadProject$: Observable<fromActions.ProjectsActions> = this.actions$.pipe(
        ofType<fromActions.LoadProject>(fromActions.ProjectsActionType.LoadProject),
        switchMap((action) => {
            return this.requests.load$(action.projectId).pipe(
                map((project: Objects.Request) => {
                    return new fromActions.LoadProjectSuccess(project);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadProjectError(err,action.projectId));
                })
            );
        })
    );

    @Effect()
    public loadProjectSuccess$ = this.actions$.pipe(
        ofType<fromActions.LoadProjectSuccess>(fromActions.ProjectsActionType.LoadProjectSuccess),
         //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            new fromActions.LoadProjectFiles(),
            // new fromActions.LoadProjectFilesNotes(),
            new fromActions.LoadProjectOriginVersion(),
            new fromActions.LoadEditorAssigners(),
            new fromActions.LoadEditorAccess(),
            new fromActions.LoadEditorWorkflowTransitions(),
            new fromActions.LoadProjectUploadedVersions()
        ])
    );

    @Effect()
    public loadProjectError$ = this.actions$.pipe(
        ofType<fromActions.LoadProjectError>(fromActions.ProjectsActionType.LoadProjectError),
        // withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap((action) => {
            
            if (action.error.status == 403)
                return [new fromActions.LoadProjectHeader(action.projectId)];
            else 
                return []
        })
    );

    @Effect()
    public loadProjectHeader$: Observable<fromActions.ProjectsActions> = this.actions$.pipe(
        ofType<fromActions.LoadProjectHeader>(fromActions.ProjectsActionType.LoadProjectHeader),
        switchMap((action) => {
            return this.requests.loadHeader$(action.projectId).pipe(
                map((header: Objects.BasicRequest) => {
                    return new fromActions.LoadProjectHeaderSuccess(header);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadProjectHeaderError(err));
                })
            );
        })
    );

    @Effect()
    public loadProjectOriginVersion$ = this.actions$.pipe(
        ofType<fromActions.LoadProjectOriginVersion>(fromActions.ProjectsActionType.LoadProjectOriginVersion),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.loadOrigin$(project.id).pipe(
                map((versions: Array<Objects.Version>) => {
                    return new fromActions.LoadProjectOriginVersionSuccess(versions);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadProjectOriginVersionError(err));
                })
            );
          
        })
    );

    @Effect()
    public loadProjectFiles$ = this.actions$.pipe(
        ofType<fromActions.LoadProjectFiles>(fromActions.ProjectsActionType.LoadProjectFiles),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.loadFiles$(project.id).pipe(
                map((files: Array<Objects.File>) => {
                    return new fromActions.LoadProjectFilesSuccess(files);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadProjectFilesError(err));
                })
            );
          
        })
    );

    @Effect()
    public loadProjectFilesSuccess$ = this.actions$.pipe(
        ofType<fromActions.LoadProjectFilesSuccess>(fromActions.ProjectsActionType.LoadProjectFilesSuccess),
         //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            new fromActions.LoadProjectFilesNotes(),
        ])
    );

    @Effect()
    public loadProjectMessages$ = this.actions$.pipe(
        ofType<fromActions.LoadProjectMessages>(fromActions.ProjectsActionType.LoadProjectMessages),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.loadMessages$(project.id).pipe(
                map((messages: Array<Objects.Message>) => {
                    return new fromActions.LoadProjectMessagesSuccess(messages);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadProjectMessagesError(err));
                })
            );
          
        })
    );

    // Load a single event acording an object and an id. 
    @Effect()
    public loadEvents$ = this.actions$.pipe(
        ofType<fromActions.LoadEvents>(fromActions.ProjectsActionType.LoadEvents),
        // withLatestFrom(this.store$.select(fromStore.projectSelector)),
        mergeMap((action) => {
            return this.requests.loadEvents$(action.object,action.id).pipe(
                map((events: Array<Objects.Event>) => {
                    return new fromActions.LoadEventsSuccess(events);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadEventsError(err));
                })
            );
          
        })
    );


    @Effect()
    public loadProjectEvents$ = this.actions$.pipe(
        ofType<fromActions.LoadProjectEvents>(fromActions.ProjectsActionType.LoadProjectEvents),
        withLatestFrom(this.store$.select(fromStore.viewSelector)),
        mergeMap(([ action, view ]) => {
            let effectEvents = [];
            // add effect to events for the project
            effectEvents.push(new fromActions.LoadEvents('request',view.project.id));
            // console.log(" Those are the versions in project store ", view.uploaded_versions);
            if(view.uploaded_versions !=  null){
                let uploadedIds = view.uploaded_versions.map((v: Objects.Version) => v.id);
                uploadedIds.forEach((vid: number) => {
                    // add effect to events for a single version
                    effectEvents.push(new fromActions.LoadEvents('version',vid));
                }); 
            }
            return effectEvents;
          
        })
    );

    @Effect()
    public loadProjectVersionGroups$ = this.actions$.pipe(
        ofType<fromActions.LoadProjectVersionGroups>(fromActions.ProjectsActionType.LoadProjectVersionGroups),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        concatMap(([action, project]) => { // concat force to make the requests by order to load 
            return this.requests.loadVersionGroups$(project.id).pipe(
                map((relateds: Array<Objects.Related>) => {
                    return new fromActions.LoadProjectVersionGroupsSuccess(relateds);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadProjectVersionGroupsError(err));
                })
            );
          
        })
    );

    // @Effect()
    // public loadProjectVersionGroupsSuccess$ = this.actions$.pipe(
    //     ofType<fromActions.LoadProjectVersionGroupsSuccess>(fromActions.ProjectsActionType.LoadProjectVersionGroupsSuccess),
    //      //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
    //     switchMap((action) => [
    //         new fromActions.LoadProjectUploadedVersions()
    //     ])
    // );

    @Effect()
    public loadProjectUploadedVersions$ = this.actions$.pipe(
        ofType<fromActions.LoadProjectUploadedVersions>(fromActions.ProjectsActionType.LoadProjectUploadedVersions),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.loadUploadedVersions$(project.id).pipe(
                map((versions: Array<Objects.Version>) => {
                    return new fromActions.LoadProjectUploadedVersionsSuccess(versions);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadProjectUploadedVersionsError(err));
                })
            );
          
        })
    );

    @Effect()
    public setActiveProjectFile$ = this.actions$.pipe(
        ofType<fromActions.SetActiveProjectFile>(fromActions.ProjectsActionType.SetActiveProjectFile),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.activeFile$(project.id, action.file, action.active).pipe(
                map((file: Objects.File) => {
                    return new fromActions.SetActiveProjectFileSuccess(file);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.SetActiveProjectFileError(err));
                })
            );
          
        })
    );

    @Effect()
    public setActiveProjectFileError$ = this.actions$.pipe(
        ofType<fromActions.SetActiveProjectFileError>(fromActions.ProjectsActionType.SetActiveProjectFileError),
        switchMap((action) => [
            new fromActions.LoadProjectFiles()
        ])
    );

    @Effect()
    public setActiveProjectFileSuccess$ = this.actions$.pipe(
        ofType<fromActions.SetActiveProjectFileSuccess>(fromActions.ProjectsActionType.SetActiveProjectFileSuccess),
        switchMap((action) => [
            new fromActions.LoadProjectFiles()
        ])
    );

    @Effect()
    public uploadProjectFile$ = this.actions$.pipe(
        ofType<fromActions.UploadProjectFiles>(fromActions.ProjectsActionType.UploadProjectFiles),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.addFiles$(project.id, action.files).pipe(
                map((files: Objects.File[]) => {
                    return new fromActions.UploadProjectFilesSuccess(files);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.UploadProjectFilesError(err));
                })
            );
          
        })
    );

    @Effect()
    public loadEditorAssigners$ = this.actions$.pipe(
        ofType<fromActions.LoadEditorAssigners>(fromActions.ProjectsActionType.LoadEditorAssigners),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.loadAssigners$(project.id).pipe(
                map((users: Array<Objects.User>) => {
                    return new fromActions.LoadEditorAssignersSuccess(users);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadEditorAssignersError(err));
                })
            );
          
        })
    );

    @Effect()
    public loadWorkflowTransitions$ = this.actions$.pipe(
        ofType<fromActions.LoadEditorWorkflowTransitions>(fromActions.ProjectsActionType.LoadEditorWorkflowTransitions),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.loadWorkflowTransitions$(+project.status.id).pipe(
                map((transitions: Array<Objects.WorkflowTransition>) => {
                    return new fromActions.LoadEditorWorkflowTransitionsSuccess(transitions);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadEditorWorkflowTransitionsError(err));
                })
            );
          
        })
    );

    @Effect()
    public loadEditorAccess$ = this.actions$.pipe(
        ofType<fromActions.LoadEditorAccess>(fromActions.ProjectsActionType.LoadEditorAccess),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.loadAccess$(project.id).pipe(
                map((access: Access.AccessProject) => {
                    return new fromActions.LoadEditorAccessSuccess(access);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadEditorAccessError(err));
                })
            );
          
        })
    );

    @Effect()
    public setProjectAssigned$ = this.actions$.pipe(
        ofType<fromActions.SetProjectAssigned>(fromActions.ProjectsActionType.SetProjectAssigned),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.setAssign$(project.id, action.user).pipe(
                map((project: Objects.Request) => {
                    return new fromActions.SetProjectAssignedSuccess(project);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.SetProjectAssignedError(err));
                })
            );
          
        })
    );

    @Effect()
    public setProjectAssignedError$ = this.actions$.pipe(
        ofType<fromActions.SetProjectAssignedError>(fromActions.ProjectsActionType.SetProjectAssignedError),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => [
            new fromActions.LoadProject(project.id)
        ])
    );

    @Effect()
    public setProjectDue$ = this.actions$.pipe(
        ofType<fromActions.SetProjectDue>(fromActions.ProjectsActionType.SetProjectDue),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.setDue$(project.id, action.date).pipe(
                map((project: Objects.Request) => {
                    return new fromActions.SetProjectDueSuccess(project);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.SetProjectDueError(err));
                })
            );
          
        })
    );

    @Effect()
    public setProjectDueError$ = this.actions$.pipe(
        ofType<fromActions.SetProjectDueError>(fromActions.ProjectsActionType.SetProjectDueError),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => [
            new fromActions.LoadProject(project.id)
        ])
    );

    @Effect()
    public setProjectStatus$ = this.actions$.pipe(
        ofType<fromActions.SetProjectStatus>(fromActions.ProjectsActionType.SetProjectStatus),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.setStatus$(project.id, action.status).pipe(
                map((project: Objects.Request) => {
                    return new fromActions.SetProjectStatusSuccess(project);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.SetProjectStatusError(err));
                })
            );
          
        })
    );

    @Effect()
    public setProjectStatusSuccess$ = this.actions$.pipe(
        ofType<fromActions.SetProjectStatusSuccess>(fromActions.ProjectsActionType.SetProjectStatusSuccess),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            if (project == null){
                return [];
            }
            return [new fromActions.LoadProject(project.id)] // reload project
   
            // new fromActions.LoadEditorAssigners(),
            // new fromActions.LoadEditorAccess(),
            // new fromActions.LoadEditorWorkflowTransitions(),
        })
    );

    @Effect()
    public setProjectStatusError$ = this.actions$.pipe(
        ofType<fromActions.SetProjectStatusError>(fromActions.ProjectsActionType.SetProjectStatusError),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => [
            new fromActions.LoadProject(project.id)
        ])
    );


    @Effect()
    public setProjectLabels$ = this.actions$.pipe(
        ofType<fromActions.SetProjectLabels>(fromActions.ProjectsActionType.SetProjectLabels),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            return this.requests.setLabels$(project.id, action.labels).pipe(
                map((project: Objects.Request) => {
                    return new fromActions.SetProjectLabelsSuccess(project);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.SetProjectLabelsError(err));
                })
            );
          
        })
    );

    @Effect()
    public setProjectLabelsSuccess$ = this.actions$.pipe(
        ofType<fromActions.SetProjectLabelsSuccess>(fromActions.ProjectsActionType.SetProjectLabelsSuccess),
         //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            // new fromActions.LoadEditorAssigners(),
            // new fromActions.LoadEditorAccess(),
        ])
    );

    @Effect()
    public setProjectLabelsError$ = this.actions$.pipe(
        ofType<fromActions.SetProjectLabelsError>(fromActions.ProjectsActionType.SetProjectLabelsError),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => [
            new fromActions.LoadProject(project.id)
        ])
    );

    @Effect()
    public loadProjectFilesNotes$ = this.actions$.pipe(
        ofType<fromActions.LoadProjectFilesNotes>(fromActions.ProjectsActionType.LoadProjectFilesNotes),
        withLatestFrom(this.store$.select(fromStore.projectSelector)),
        switchMap(([action, project]) => {
            if (project == null){
                return [];
            }
            return this.requests.loadFilesNotes$(project.id).pipe(
                map((notes: Array<Objects.Note>) => {
                    return new fromActions.LoadProjectFilesNotesSuccess(notes);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadProjectFilesNotesError(err));
                })
            );
          
        })
    );

    @Effect()
    public updateProject$ = this.actions$.pipe(
        ofType<fromActions.UpdateProject>(fromActions.ProjectsActionType.UpdateProject),
        withLatestFrom(this.store$.select(fromStore.viewSelector)),
        mergeMap(([action, view]) => { // merge force to do all
            let events = [];

            if((view.project != null && view.project.id == action.projectId) ||
                (view.header != null && view.header.id == action.projectId)) {
                console.log("update request", action);
                events.push(new fromActions.LoadProject(action.projectId));

                // if(action.event === "request.labels.updated")
                //     events.push(new fromActions.LoadProjectVersionGroups());
            }
            
            return events;
        })
    );

}