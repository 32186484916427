import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { HelperBoxModule } from '@core/components/helpers/box/box.module';
import { LabelsModule } from '@core/components/labels/labels.module';
import { HelpersLabelsEditBoxComponent } from './edit-box/edit-box.component';
import { HelpersLabelsSearchBoxComponent } from './search-box/search-box.component';
import { HelpersLabelsAddBoxComponent } from './add-box/add-box.component';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { HelpersLabelsEditDiffBoxComponent } from './edit-diff-box/edit-diff-box.component';
//import { CoreModule } from '@core/core.module';

// components

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LabelsModule,
        HelpersModule
        // HelperBoxModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        HelpersLabelsEditBoxComponent,
        HelpersLabelsSearchBoxComponent,
        HelpersLabelsAddBoxComponent,
        HelpersLabelsEditDiffBoxComponent

    ],
    exports: [
        HelpersLabelsEditBoxComponent,
        HelpersLabelsSearchBoxComponent,
        HelpersLabelsEditDiffBoxComponent
    ],

})
export class AppHelperLabelsModule { }
