import { Component, ElementRef, ViewChild, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
// import { FormControl } from '@angular/forms';

// import { Subscription } from 'rxjs';

import { File } from '@core/objects/file';
import { Note } from '@core/objects/note';
// import { PreviewRequest} from '@core/objects/preview-request';
// import { UrlService } from '@core/services/url/url.service';
import { NotesService } from '@core/services/notes/notes.service';

@Component({
    selector: 'core-file-notes',
    templateUrl: './file-notes.component.html',
    styleUrls: ['./file-notes.component.scss']
})
export class FileNotesComponent implements OnInit, OnChanges {

    @Input() file: File;
    @Input() urlPrefix: string = "";
    @Input() canCreateNotes: boolean = true;
    @Output() saved = new EventEmitter<Array<Note>>();

    public notes: Array<Note> = [];

    constructor(private notesSrv: NotesService){}

    ngOnInit(){
       this.init();
    }

    ngOnChanges(changes: SimpleChanges){

        if(changes.file) {
            this.notes = [];
            this.init();
        }
    }

    protected init() {
        this.notesSrv.find(this.file, this.urlPrefix).subscribe((data: Array<Note>) => { this.notes = data});
    }

    save(notes: Array<Note>) {
        console.log("notas", notes);
        this.notesSrv.save(this.file, notes, this.urlPrefix).subscribe((data: Note[]) => {
            // TODO
            this.init();
            this.saved.emit(data);
        });
    }
}
