import { Component, OnInit } from '@angular/core';
// import { ViewportScroller } from '@angular/common';
// import { UsersService } from '../../../../../services/settings/users.service';
import { Group } from '@core/objects/group';

import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';

// import { GroupsService } from '@app/services/settings/groups.service';

import { Subscription } from 'rxjs';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

@Component({
    selector: 'settings-groups-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SettingsGroupsMainComponent implements OnInit{
    private readonly ACCESS = 'access_setting_groups_access';

    public showForm: boolean = false;
	public access:boolean = true;

    public selectedGroup: Group = null;
    public breadcrumbs: any = [
        {
            title: "System Settings",
            link: ['/settings']
        }
    ];

    // TODO review menu icons
    public menu: Array<ThreeDotsMenuItem> = [{
        title: "Create a new user group",
        key: "add-group",
        icon: "add",
        order: 1
    }];

	constructor(public app: AppService, private title: TitleService){}

	ngOnInit(){
        this.title.set("User groups - System settings");

        this.app.getSession().subscribe( (session: any) => {
            // this.session = session;
            this.access = session.options.access.hasOwnProperty(this.ACCESS)? session.options.access[this.ACCESS] : false;
            // this.licensed = this.session.options.license !== null;
            // this.loaded = true;
        });
        // this.grpSrv.loadAllGroups();

        // this.subscriptionGroups = this.userSrv.selectorGetAllUsers().subscribe((users: User[]) => {
        //     this.userSrv.loadAllLicenses();
        // });

        // this.subscriptionInvitation = this.userSrv.selectorGetAllInvitations().subscribe((inv: UserRequest[]) => {
		// 	this.userSrv.loadAllLicenses();
        // });
	}

    onMenuClick(key: string) {
        switch(key) {
            case "add-group":
                this.showForm = !this.showForm;
                break;
        }
    }

	// controlForm(event){
	// 	//console.log('eveeeent -- ', event);
	// 	this.showForm = !this.showForm;
	// }

	// newInvitation(event){
	// 	this.userSrv.addNewInvitation(event.name, event.email, event.message, event.groups, event.extra);
	// }

    // selectLicense(license: License) {
    //     // this.invitationExtraFields = {
    //     //     license: license.id
    //     // };

    //     this.selected = {
    //         users: license.users_email.length>0? license.users_email : ['x'],
    //         requests: license.requests_email.length>0? license.requests_email : ['y']
    //     };
    // }

    // inviteLicense(license: License) {
    //     this.invitationExtraFields = {
    //         license: license.id
    //     };

    //     //this.selectLicense(license);

    //     this.showForm = true;

    //     //window.scrollTo(0, 0)
    //     setTimeout( () => this.viewportScroller.scrollToAnchor('AddInvitationForm'), 500 );
    // }

	// tryAgain(event){
	// 	switch (event){
	// 		case 'users':
	// 			this.loadingUser = true;
	// 			this.userSrv.loadAllUsers();
	// 			break;

	// 		case 'invitations':
	// 			this.loadingInv = true;
	// 			this.userSrv.loadAllInvitations();
	// 			break;
	// 	}
	// }
}
