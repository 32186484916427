import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { VersionsService } from '@core/services/versions/versions.service';

import { Version } from '@core/objects/version';
// import { File } from '@core/objects/file';
// import { Request } from '@core/objects/request';
// import { Label } from '@core/objects/label';
// import { Related } from '@core/objects/related';

import { PreviewRequest } from '@core/objects/preview-request';


@Component({
    selector: 'core-version-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss']
})
export class VersionPreviewComponent implements OnInit, OnChanges {
    public readonly PREVIEW_NOT_EXISTS: number = 3;
    public readonly PREVIEW_EXISTS: number = 1;
    
    @Input() version: Version;
    @Input() format: string;

    public preview: PreviewRequest;

    constructor(private versionsSrv: VersionsService) { }

    ngOnInit() {
        this.init();
    }

    init(){
        this.preview = null;
        this.versionsSrv.getPreview$(this.version).subscribe((request: PreviewRequest) => {
            this.preview = request;
        });
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.version){
            this.init();
        }
    }
}
