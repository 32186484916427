import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';
import { TitleService } from '@core/services/title.service';

import { File } from '@core/objects/file';
import { User } from '@core/objects/user';
import { Request } from '@core/objects/request';
import { Version } from '@core/objects/version';

import { VersionFeedback } from '@core/models/version-feedback';


// import { FeedbackService } from '../../../Share/services/feedback.service';

import { Session } from '@app/store/models/session.model';
import { AppService } from '@app/services/app/app.service';

export interface ExtendedVersionFeedback extends VersionFeedback {
    share: any;
    entry: any;
}

@Component({
    selector: 'plugin-share-feedback-main-review',
    templateUrl: './main-review.component.html',
    styleUrls: ['./main-review.component.scss']
})
export class PluginShareFeedbackReviewMainAppComponent implements OnInit {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly NOT_FOUND = 3;

    public status: number = this.LOADING;
    // public entry: any = null;

    public feedbacks: Array<ExtendedVersionFeedback> = [];
    public feedbackSelected: ExtendedVersionFeedback;

    protected userId: string;
    protected versionId: string;

    protected currentUser: User = null;

//     public displayStatus: number = this.LOADING;

//     // public request: Request = null;
//     public shareUrl: string = "";
//     protected versionFilesUrl: string = "";
//     public request:  any = null;
//     public show: boolean; // instructions
//     public feedback: any;
//     public date: Date;
//     protected hash: string;
    // public versionSelected: Version = null;
    
//     // for files list
//     public bubbles: boolean = false;
// // TO remove -> then take it from the feedback
//     public versionsDefault: Array<any>;
//     public versions: Array<any> = [];
//     protected feedbackDefault: any;



    constructor(private appSrv: AppService, private http: HttpClient, private router: Router, private activeRoute: ActivatedRoute, private title: TitleService) {}

    // public fileAction(e:any){
    //     console.debug(" fileAction here ", e);
    // }

    // public bubbleAction(e:any){
    //     console.debug(" bubbleAction here ", e);
    // }

    ngOnInit() {
        //this.bubbles = true; // TODO manage to HIDE bubbles
        this.title.set("Feedback review");

        // get data from url
        this.userId = this.activeRoute.snapshot.paramMap.get('user');
        this.versionId = this.activeRoute.snapshot.paramMap.get('version');

        if(this.versionId == null || this.userId == null) {
            this.status = this.NOT_FOUND;
            return;
        }

        this.appSrv.getSession().subscribe( (session: Session) => {
            this.currentUser = session.user;
        });

        this.loadFeedbacks();

        //private readonly ROOT = "/web/share/p/feedback/";
        // this.feedbackSrv.load(this.hash);

        // we need to select real version from url
        // let versionId = this.activeRoute.snapshot.paramMap.get('version');
        
        // if(this.status != this.NOT_FOUND)
        //     this.loadShare();

            // this.status = this.DONE;
        // this.http.get("/web/app/p/feedback/versions/"+versionId+"/users/"+this.userId).subscribe(
        //     (data:any) => {

        //         //this.data = data;
        //         console.debug(" data response ",data);
        //         if(data.length > 0){
        //             this.feedbacks = data;
        //             // if version is not in url
        //             if(versionId == null) {
        //                 // this.goTo(this.feedbacks[0].version);
        //                 this.status = this.NOT_FOUND;
        //                 // this.goToFeedback(this.feedbacks[0]);
        //             }
        //             else {
        //                 let currentFeedback = data.filter( f => f.version.id.toString() == versionId);

        //                 if(currentFeedback.length > 0) {
        //                     console.debug(" current feedback ",currentFeedback[0]);
        //                     this.feedbackSelected = currentFeedback[0];
        //                     //this.feedbacks.push(currentFeedback[0]);
        //                     this.status = this.DONE;
        //                     // this.version = currentFeedback[0].version;
        //                     // this.goTo(currentFeedback[0].version);
        //                     // this.goToFeedback(currentFeedback[0]);
        //                 }
        //                 else
        //                     this.status = this.NOT_FOUND;
        //             }
        //         }
        //     },
        //     (error:any) => {
        //         this.status = this.NOT_FOUND;
        //     }
        // );
    }

    protected loadFeedbacks() {
        this.http.get("/web/app/p/feedback/versions/"+this.versionId+"/users/"+this.userId).subscribe(
            (data:any) => {
                if(data.length == 0) {
                    this.status = this.NOT_FOUND;
                    return;
                }

                this.feedbacks = data;
                let currentFeedback = data.filter( f => f.version.id.toString() == this.versionId);

                if(currentFeedback.length > 0) {
                    let feedback = currentFeedback[0];
                    if(this.currentUser.id != feedback.user.id) {
                        this.feedbackSelected = feedback;
                        this.status = this.DONE;
                    } else
                        this.redirectToPublic(feedback);
                }
                else
                    this.status = this.NOT_FOUND;
               
            },
            (error:any) => {
                this.status = this.NOT_FOUND;
            }
        );
    }

    protected redirectToPublic(feedback: ExtendedVersionFeedback) {
        let hash = feedback.share.entry.hash;
        let idVersion = feedback.version.id;
        window.location.href = '/share/p/feedback/' + hash +'/' + idVersion;
    }

    // // public goTo(version: Version) {
    protected onClickVersion(version: Version) {
        console.debug(" selecting version ",version.id);
        //this.versionSelected = version;
        this.status = this.LOADING;

        let currentFeedback = this.feedbacks.filter( f => f.version.id == version.id);

        if(currentFeedback.length > 0) {
            this.feedbackSelected = null;
            console.debug(" current updated feedback ",currentFeedback[0]);
            this.feedbackSelected = currentFeedback[0];
            this.status = this.DONE;
        }
        else
            this.status = this.NOT_FOUND;
    }


    // protected getFeedbackByVersion(version: Version) : VersionFeedback | null {
    //     let feeds = this.feedbacks.filter((f: VersionFeedback) => f.version.id == version.id);
    //
    //     if(feeds.length > 0)
    //         return feeds[0];
    //
    //     return null;
    // }

    // protected loadShare(){
    //     this.feedback = this.feedbackDefault;
    //     this.versions = this.versionsDefault;
    //     this.date = new Date("2021-04-19T07:44:10+0000");
    //     console.debug(" LOADINGGGG ", this.feedback);
    //     if (this.versions.length > 0){
    //         this.showVersion(this.versions[0]);
    //     }
    //     this.status = this.DONE;
    // //     this.http.get(this.shareUrl).subscribe(
    // //         (data:any) => {
    // //             this.entry = data;
    // //             console.debug(" entry response ",this.entry);
    // //             this.date = new Date(this.entry.date);
    // //             this.status = this.DONE;
    // //         },
    // //         (error:any) => {
    // //             this.status = this.NOT_FOUND;
    // //         }
    // //     );
    // }

    // public showVersion(v: any) {
    //     this.versionSelected = v;
    //     console.debug(" showing version ",v.id);



    // }

    // public openNotes(e: any){
    //     console.debug(" Open notes of file ",e.id);
    // }

    // protected loadFiles(){
    //     this.versionSelected.files = [
    //         {
    //             "active": true,
    //             "date": "2021-02-26T09:55:04+00:00",
    //             "extension": "pdf",
    //             "filesize": "66.37kB",
    //             "id": 306520,
    //             "mimetype": "application/pdf",
    //             "name": "Envelop Product-2021-test.pdf",
    //             "size": 67964,
    //             "metadata": []
    //         },
    //         {
    //             "active": true,
    //             "date": "2021-02-26T09:55:04+00:00",
    //             "extension": "pdf",
    //             "filesize": "66.27kB",
    //             "id": 306521,
    //             "mimetype": "application/pdf",
    //             "name": "Envelop Product-2021-test.pdf",
    //             "size": 67863,
    //             "metadata": []
    //         }
    //     ];
    //     console.debug(" loading files for version ",this.versionSelected.id);
    //     this.displayStatus = this.DONE;
    //     // this.http.get(this.versionFilesUrl+this.versionSelected.id+"/files").subscribe(
    //     //        (data:any) => {
    //     //            this.versionSelected.files = data;
    //     //            console.debug(" files response ",v.id,data);
    //     //            this.displayStatus = this.DONE;
    //     //        },
    //     //        (error:any) => {
    //     //            this.displayStatus = this.NOT_FOUND;
    //     //        }
    //     //    );
    // }






}
