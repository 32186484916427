import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';

import { AddVersion } from '@app/models/add-version';

import { SelectItem } from '@core/models/select-item.model';

import * as  Objects from '@core/objects';

@Component({
    selector: 'versions-add-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})
export class VersionsAddStatusComponent implements OnInit, OnChanges {
    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

    @Input() addVersion: AddVersion;
    @Input() status: Array<Objects.Status> = [];
    @Output() statusAction = new EventEmitter<Objects.Status>();
    @Output() addVersionChange = new EventEmitter<Partial<AddVersion>>(); 

    public pageStatus: number = this.STATUS_LOADING;

    public availableStatus: Array<SelectItem>;
    // public currentStatusId: number = null;
    public currentStatus: Objects.Status = null;
    
    constructor() { }

    ngOnInit() {
        this.setAvailableStatus();
        this.pageStatus = this.STATUS_DONE;
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.addVersion){ // TODO
            if(this.currentStatus != null && this.currentStatus.id != this.addVersion.status) // avoid on initial change
                this.setAvailableStatus();
        }
    }

    private setAvailableStatus(sid?:  number) {
        if(sid){
            this.currentStatus = this.status.filter((s: Objects.Status) => s.id == sid)[0];
            this.statusAction.emit(this.currentStatus);
        } else {
            this.currentStatus = this.status.filter((s: Objects.Status) => s.id == this.addVersion.status)[0];
        }       
        this.availableStatus = this.status.map((st: Objects.Status) => {
            return {
                title: st.name,
                value: st.id.toString(),
                selected: this.currentStatus.id.toString() == st.id.toString() ? true : false
            };
        });
    }


    onStatusMenuSelect(status: SelectItem){ // from selector output come an Satus object
        this.setAvailableStatus(+status.value);
        this.statusAction.emit(this.currentStatus);
        this.addVersionChange.emit({status: +status.value});
        
    }

}
