import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';

@Injectable({
  providedIn: 'root'
})
export class VersionsRequests {
	private readonly prefix = "/versions";

    constructor(private http: HttpClient, private urlSrv : UrlService) {}

    // public create$ = (info: string, workflowId: number, labels: number[], files: Array<string|number>, dueDate: string = null) : Observable<Request> => {
    //     let request = {
    //         info: info ,
    //         workflow: workflowId ,
    //         labels: labels ,
    //         files: files 
    //     };

    //     return this.http.post<Request>(this.urlSrv.build(this.prefix), request, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });
    // }
        
	public load$ = (id: number) : Observable<Objects.Version> =>
		this.http.get<Objects.Version>(this.urlSrv.build(this.prefix+"/"+id));

    public loadHeader$ = (id: number) : Observable<Objects.Version> =>
		this.http.get<Objects.Version>(this.urlSrv.build(this.prefix+"/"+id+"/headers"));

    public loadRelateds$ = (link: string) : Observable<Objects.Version[]> =>
        this.http.get<Objects.Version[]>(this.urlSrv.build(this.prefix),{params: new HttpParams().set('link', link)});

    public loadFiles$ = (id: number) : Observable<Objects.File[]> =>
        this.http.get<Objects.File[]>(this.urlSrv.build(this.prefix + "/" + id + "/files"), { params: new HttpParams().set('all', "true")});

    public loadMessages$ = (id: number) =>
        this.http.get<Objects.Message[]>(this.urlSrv.build(this.prefix + "/" + id + "/messages"));

    // new request to get version messages with context (default or feedback)
    public loadComments$ = (id: number) =>
        this.http.get<Objects.VersionMessage[]>(this.urlSrv.build(this.prefix + "/" + id + "/comments"));
    
    public loadAccess$ = (id: number) =>
        this.http.get<Access.AccessVersion>(this.urlSrv.build(this.prefix + "/" + id + "/permissions"));
    
    public loadProject$ = (id: number) =>
        this.http.get<Objects.Request>(this.urlSrv.build(this.prefix + "/" + id + "/request"));
    
    public setStatus$ = (id: number, status: Objects.Status) => 
        this.http.put<Objects.Version>(this.urlSrv.build(this.prefix+"/"+id ), {status: status.id}, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });

    public setLabels$ = (id: number, labels: Array<Objects.Label>) => 
        this.http.put<Objects.Version>(this.urlSrv.build(this.prefix+"/"+id ), {labels: labels.map((l: Objects.Label) => l.id)}, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });
    
}