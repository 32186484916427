import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LibraryMainComponent } from '@app/components/sections/library/main/main.component';

export const libraryRoutes: Routes = [
    {
        path: 'library',
        component: LibraryMainComponent,
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(libraryRoutes)
    ],
    exports: [RouterModule]
})
export class AppLibraryRouterModule { }