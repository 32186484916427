import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, empty, of, throwError, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { AvailableRuleItem, AvailableRuleActionVariable, AvailableRuleEventCondition, AvailableRuleAction, RuleEvent, RuleListItem } from '@app/models/rules';

import { SettingsRulesRequests } from './rules.requests';

import * as fromStore from './rules.state';

import {
    SettingsRulesActions,
    SettingsRulesActionType,

	LoadAvailableEvents,
    LoadAvailableEventsSuccess,
    LoadAvailableEventsError,

    LoadAvailableEvent,

    LoadAvailableConditions,
    LoadAvailableConditionsSuccess,
    LoadAvailableConditionsError,

    LoadAvailableActions,
    LoadAvailableActionsSuccess,
    LoadAvailableActionsError,

    LoadRules,
    LoadRulesSuccess,
    LoadRulesError,

    SetActiveRule,
    SetActiveRuleSuccess,
    SetActiveRuleError,

    LoadRule,
    LoadRuleSuccess,
    LoadRuleError,

    SetRuleEventKey,
    AddRuleAction,
    AddRuleCondition,
    ChangeRuleAction,
    ChangeRuleActionKey, 
    ChangeRuleCondition,
    ChangeRuleConditionVariable,

    SaveRule,
    CreateRule,
    UpdateRule,
    SaveRuleSuccess,
    SaveRuleError

} from './rules.actions';

@Injectable()
export class SettingsRulesEffects {

    constructor(private actions$: Actions, private store$: Store<fromStore.SettingsRules>, private requests: SettingsRulesRequests) {}

    @Effect()
    public loadAvailableEvents$: Observable<SettingsRulesActions> = this.actions$.pipe(
        ofType<LoadAvailableEvents>(SettingsRulesActionType.LoadAvailableEvents),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap(() => {
            return this.requests.loadAvailableEvents$().pipe(
                map((events:AvailableRuleItem[]) => {
                    return new LoadAvailableEventsSuccess(events);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new LoadAvailableEventsError());
                })
            );
        })
    );

    @Effect()
    public loadAvailableEvent$ = this.actions$.pipe(
        ofType<LoadAvailableEvent>(SettingsRulesActionType.LoadAvailableEvent),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            new LoadAvailableActions(action.eventKey),
            new LoadAvailableConditions(action.eventKey)
        ])
    );

    @Effect()
    public loadAvailableConditions$ : Observable<SettingsRulesActions> = this.actions$.pipe(
        ofType<LoadAvailableConditions>(SettingsRulesActionType.LoadAvailableConditions),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        mergeMap((action) => {
            return this.requests.loadAvailableConditions$(action.eventKey).pipe(
                map((conditions: AvailableRuleEventCondition[]) => {
                    return new LoadAvailableConditionsSuccess(action.eventKey, conditions);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new LoadAvailableConditionsError());
                })
            );
        })
    );

    @Effect()
    public loadAvailableActions$ : Observable<SettingsRulesActions> = this.actions$.pipe(
        ofType<LoadAvailableActions>(SettingsRulesActionType.LoadAvailableActions),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        mergeMap((action) => {
            return this.requests.loadAvailableActions$(action.eventKey).pipe(
                map((actions: AvailableRuleAction[]) => {
                    return new LoadAvailableActionsSuccess(action.eventKey, actions);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new LoadAvailableActionsError());
                })
            );
        })
    );

    @Effect()
    public loadRules$ : Observable<SettingsRulesActions> = this.actions$.pipe(
        ofType<LoadAvailableActions>(SettingsRulesActionType.LoadRules),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        mergeMap((action) => {
            return this.requests.loadRules$().pipe(
                map((rules: RuleListItem[]) => {
                    return new LoadRulesSuccess(rules);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new LoadRulesError());
                })
            );
        })
    );

    @Effect()
    public setActiveRule$ : Observable<SettingsRulesActions> = this.actions$.pipe(
        ofType<SetActiveRule>(SettingsRulesActionType.SetActiveRule),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        mergeMap((action) => {
            return this.requests.setActiveRule$(action.ruleId, action.active).pipe(
                map((rule: any) => {
                    return new SetActiveRuleSuccess();
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new SetActiveRuleError(action.ruleId, action.active));
                })
            );
        })
    );

    @Effect()
    public loadRule$ : Observable<SettingsRulesActions> = this.actions$.pipe(
        ofType<LoadRule>(SettingsRulesActionType.LoadRule),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        mergeMap((action) => {
            return this.requests.loadRule$(action.ruleId).pipe(
                map((rule: RuleEvent) => {
                    return new LoadRuleSuccess(rule);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new LoadRuleError());
                })
            );
        })
    );

    @Effect()
    public loadRuleSuccess$ = this.actions$.pipe(
        ofType<LoadRuleSuccess>(SettingsRulesActionType.LoadRuleSuccess),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            new LoadAvailableEvent(action.rule.key)
        ])
    );

    @Effect()
    public setRuleEventKey$ = this.actions$.pipe(
        ofType<SetRuleEventKey>(SettingsRulesActionType.SetRuleEventKey),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            new LoadAvailableEvent(action.key),
            // new AddRuleAction(),
            // new AddRuleCondition()
        ])
    );

    @Effect()
    public changeRuleAction$ = this.actions$.pipe(
        ofType<ChangeRuleAction>(SettingsRulesActionType.ChangeRuleAction),
        withLatestFrom(this.store$.select(fromStore.settingsRulesEditingSelector)),
        switchMap(([action, editing]) => {
            if(action.action.key != editing.actions[action.position].key)
                return [new ChangeRuleActionKey(action.position, action.action.key)]

            return []
          
        })
    );

    @Effect()
    public changeRuleCondition$ = this.actions$.pipe(
        ofType<ChangeRuleCondition>(SettingsRulesActionType.ChangeRuleCondition),
        withLatestFrom(this.store$.select(fromStore.settingsRulesEditingSelector)),
        switchMap(([action, editing]) => {
            if(action.condition.variable != editing.conditions[action.position].variable)
                return [new ChangeRuleConditionVariable(action.position, action.condition.variable)]

            return []
          
        })
    );

    @Effect()
    public saveRule$ = this.actions$.pipe(
        ofType<SaveRule>(SettingsRulesActionType.SaveRule),
        withLatestFrom(this.store$.select(fromStore.settingsRulesEditingSelector)),
        mergeMap(([action, editing]) : Observable<any> => {
            if(editing.id > 0)
                return of(new UpdateRule(editing.id, editing))
            else
                return of(new CreateRule(editing))
          
        })
    );

    @Effect()
    public updateRule$ : Observable<SettingsRulesActions> = this.actions$.pipe(
        ofType<UpdateRule>(SettingsRulesActionType.UpdateRule),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        mergeMap((action) => {
            return this.requests.updateRule$(action.id, action.rule).pipe(
                map((rule: RuleEvent) => {
                    return new SaveRuleSuccess(rule);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new SaveRuleError());
                })
            );
        })
    );

    @Effect()
    public createRule$ : Observable<SettingsRulesActions> = this.actions$.pipe(
        ofType<CreateRule>(SettingsRulesActionType.CreateRule),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        mergeMap((action) => {
            return this.requests.createRule$(action.rule).pipe(
                map((rule: RuleEvent) => {
                    return new SaveRuleSuccess(rule);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new SaveRuleError());
                })
            );
        })
    );

}
