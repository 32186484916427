import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { map } from 'rxjs/operators';
import { UrlService } from '@core/services/url/url.service';

import { User } from '@core/objects/user';
import { Group } from '@core/objects/group';


@Injectable({
  providedIn: 'root'
})
export class GroupsRequests {
    private readonly groups = "/groups";
	
	constructor(private http: HttpClient, private urlSrv : UrlService) {
	}

	public loadAllGroups$ = () : Observable <Group[]> => 
		this.http.get<Group[]>(this.urlSrv.build(this.groups), { params: new HttpParams().set('all', "true")});
	
	// public loadAllInactiveGroups$ = () : Observable <Group[]> => 
	// 	this.http.get<Group[]>(this.urlSrv.build(this.groups), { params: new HttpParams().set('deleted', "true")});
	
	public saveGroup$ = (name: string) : Observable <Group> => 
		this.http.post<Group>(this.urlSrv.build(this.groups), {name: name});

	public updateGroup$ = (group: Group) : Observable <Group> => 
		this.http.put<Group>(this.urlSrv.build(this.groups)+"/"+group.id, {name: group.name, active: group.active});
	
	public deleteGroup$ = (group: Group) : Observable<string> => 
		this.http.delete(this.urlSrv.build(this.groups)+"/"+group.id, {responseType: 'text'});

	public findUsers$ = (group: Group) : Observable<User[]> => 
		this.http.get<User[]>(this.urlSrv.build(this.groups)+"/"+group.id+"/users");
	
	public deleteUser$ = (group: Group, user: User) : Observable<string> => 
		this.http.delete(this.urlSrv.build(this.groups)+"/"+group.id+"/users/"+user.id, {responseType: 'text'});
	
	public addUser$ = (group: Group, user: User) : Observable<User> => 
		this.http.post<User>(this.urlSrv.build(this.groups)+"/"+group.id+"/users", {id: user.id});
}
