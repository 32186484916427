import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { HelperBoxModule } from '@core/components/helpers/box/box.module';

import { AppRouterModule } from '@app/router.module';

import { AppMainComponent } from './main/main.component';
import { AppDisconnectedComponent } from './disconnected/disconnected.component';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        AppRouterModule,
        HelperBoxModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        AppMainComponent,
        AppDisconnectedComponent
    ],
    exports: [
        AppMainComponent,
        AppDisconnectedComponent
    ],

})
export class LayoutModule { }
