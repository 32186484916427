import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import {SizeMeModule} from 'ngx-size-me';

import { CoreModule } from '@core/core.module';
import { AvatarModule } from '@core/components/avatar/avatar.module';
import { FilesModule } from '@core/components/files/files.module';

import { ProfileAvatarMainComponent } from './main/main.component';
import { ProfileAvatarEditComponent } from './edit/edit.component';
import { ProfileAvatarUploadComponent } from './upload/upload.component';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        CoreModule,
        SizeMeModule.forRoot()
        // AvatarModule,
        // FilesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProfileAvatarMainComponent,
        ProfileAvatarEditComponent,
        ProfileAvatarUploadComponent
    ],
    exports: [
		ProfileAvatarMainComponent,
        ProfileAvatarEditComponent,
        ProfileAvatarUploadComponent
	],

})
export class ProfileAvatarModule { }
