import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelsModule } from '@core/components/labels/labels.module';
import { PreviewModule } from '@core/components/preview/preview.module';
//import { CoreModule } from '@core/core.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilesModule } from '@core/components//files/files.module';

import { VersionApprovalFormComponent } from './form/form.component';
import { VersionApprovalResponseComponent } from './response/response.component';
import { VersionApprovalComponent } from './approval/approval.component';

@NgModule({
    imports: [
        CommonModule,
        LabelsModule,
        PreviewModule,
        HelpersModule,
        FilesModule,
        FormsModule,
        ReactiveFormsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionApprovalFormComponent,
        VersionApprovalResponseComponent,
        VersionApprovalComponent
    ],
    exports: [
        VersionApprovalFormComponent,
        VersionApprovalResponseComponent,
        VersionApprovalComponent
    ],

})
export class VersionApprovalModule { }
