import { Component, OnInit, OnDestroy, Input,Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, RouterModule } from "@angular/router";

// import { LabelsGroup } from '@core/objects/labels-group';

import { AvailableRule } from '@app/store/state/settings/rules/rules.state';
import { RuleEvent } from '@app/models/rules';

import { RulesService } from '@app/services/settings/rules.service';
import { SettingsRulesService } from '@app/services/settings/srules.service';

import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';

@Component({
    selector: 'settings-rules-main-edit',
    templateUrl: './main-edit.component.html',
    styleUrls: ['./main-edit.component.scss']
})
export class SettingsRulesMainEditComponent implements OnInit, OnDestroy {
    private readonly ACCESS = 'access_setting_rules_access';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

    // public availableEvents: Array<AvailableRule>;
    public ruleId: number = null;
    // public rule: RuleEvent = null;
	public access:boolean = true;
    // public status: number = 0;
    public status: number = 0; //TODO change to 0
    public breadcrumbs: any = [
        {
            title: "System Settings",
            link: ['/settings']
        },
        {
            title: "Rules",
            link: ['/settings/rules']
        }
    ];
    public titleText: string = "Add New Rule"; //header title of the page
    protected availableEventsSubscriptor: Subscription;
    protected editingSubscriptor: Subscription;

	constructor(public srulesSrv: SettingsRulesService,
        public rulesSrv: RulesService,
        public app: AppService,
        private title: TitleService,
        private activeRoute: ActivatedRoute,
        private router: Router){}

	ngOnInit(){
        // let title = "Add new Rule - Rules - System settings";

        this.app.getSession().subscribe( (session: any) => {
            this.access = session.options.access.hasOwnProperty(this.ACCESS)? session.options.access[this.ACCESS] : false;
        });

        this.editingSubscriptor = this.srulesSrv.selectEditing().subscribe((edit: RuleEvent) => {
            if(edit.id > 0) {
                this.title.set("Edit: "+edit.name+" - Rules - System settings");
                this.titleText = "Editing: " + edit.name;
                this.status = this.STATUS_DONE;
            }

            // TODO redirect to edit page
            // if(this.ruleId == null && edit.id > 0) 
        });

        this.availableEventsSubscriptor = this.srulesSrv.selectAvailableEvents().subscribe((availables: AvailableRule[] ) => {
            // this.availableEvents = availables;
            this.status = availables.length > 0? this.STATUS_DONE : this.STATUS_LOADING;
        });

        this.title.set("Add new Rule - Rules - System settings");


        if(this.activeRoute.snapshot.paramMap.has('id')) {
            this.ruleId = +this.activeRoute.snapshot.paramMap.get('id');

            this.title.set("Edit - Rules - System settings");
            this.titleText = "Editing: ... ";
            this.status = this.STATUS_LOADING;

            this.srulesSrv.loadRule(this.ruleId);
        }  

        if (this.access){
            this.srulesSrv.loadAvailableEvents();
        }
	}

    ngOnDestroy() {
        this.editingSubscriptor.unsubscribe();
        this.availableEventsSubscriptor.unsubscribe();
    }

    cancel(event){
        this.router.navigate(['/settings/rules']);
    }
}
