import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ViewportScroller } from '@angular/common';


import { WorkflowsService } from '../../../../../services/settings/workflows.service';


import { Workflow } from '@core/objects/workflow';


@Component({
    selector: 'settings-workflows-workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.scss']
})
export class SettingsWorkflowsWorkflowComponent implements OnInit{

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

    @Input() workflow: Workflow;

    public status: Number = 0;

    public transitions: Array<any>;
    protected projectStages: Array<any> = [];
    public editorSaved: Subject<void> = new Subject<void>();

	constructor(public workflowsSrv: WorkflowsService){}

	ngOnInit(){
		this.init();

	}

    init(){

        // this.loadTransitions(this.workflow);
        // console.debug(" initialized editor ",this.transitions)

    }

    emitSavedToEditor() {
      this.editorSaved.next();
    }

    // protected loadTransitions(workflow: Workflow){

    //     this.workflowsSrv.getWorkflowTransitions$(workflow.id).subscribe((data: Array<any>) => {
    //         console.debug(" transitions ",data);
    //         this.transitions = data;
    //         this.parseTransitions();
    //         console.debug(" procesed data projects for editor ",this.projectStages);
    //     });
    // }

    // protected parseTransitions(){
    //     this.transitions.forEach(t => {
    //         // console.debug(" transition on loop is ",t.origin.id," -->",t.target.id,"initial is:",this.workflow.initial_status.id)
    //         let stages = null;
    //         let stage = null;
    //         // add each stage of transition one time only to list of stages
    //         // check origin
    //         stages = this.projectStages.filter((st) => { return st.id == t.origin.id});
    //         if (stages.length == 0){
    //             stage = t.origin;
    //             // stage.initialStatus = (stage.name == this.workflow.initial_status.name)? true: false;
    //             // I suppose this is what @diego wanted to change. I fix the serializer and now the initial_status.id comes
    //             stage.initialStatus = (stage.id == this.workflow.initial_status.id)? true: false;
    //             this.projectStages.push(stage);
    //         }
    //         // check target
    //         stages = this.projectStages.filter((st) => { return st.id == t.target.id});
    //         if (stages.length == 0){
    //             stage = t.target;
    //             // stage.initialStatus = (stage.name == this.workflow.initial_status.name)? true: false;
    //             // I suppose this is what @diego wanted to change. I fix the serializer and now the initial_status.id comes
    //             stage.initialStatus = (stage.id == this.workflow.initial_status.id)? true: false;
    //             this.projectStages.push(stage);
    //         }
    //     });
    //     this.status = this.STATUS_DONE;
    // }



    onSaved(transitions){
        // console.log(" saving workflow transitions",transitions);

        this.workflowsSrv.updateWorkflowTransitions$(transitions,this.workflow.id).subscribe((data: Array<any>) => {
            //console.debug(" transitions updated",data);
            this.emitSavedToEditor();
        });

    }



}
