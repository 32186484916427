import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, empty, of, throwError, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SettingsUsersRequests } from './users.requests';

import { User } from '@core/objects/user';
import { UserRequest } from '@core/objects/user-request';

import * as fromStore from './users.state';

import {
	SettingsUsersActions,
    SettingsUsersActionType,

    LoadAllUsers,
    LoadAllUsersSuccess,
    LoadAllUsersError,

    LoadAllInvitations,
    LoadAllInvitationsSuccess,
    LoadAllInvitationsError,

    AddNewInvitation,
    AddNewInvitationSuccess,
    AddNewInvitationError,

    DeleteOneUser,
    DeleteOneUserSuccess,
    DeleteOneUserError,

    DeleteInvitation,
    DeleteInvitationSuccess,
    DeleteInvitationError,

	SetAllUsers,
	SetAllInvitations

} from './users.actions';

@Injectable()
export class SettingsUsersEffects {

    constructor(private actions$: Actions, private store$: Store<fromStore.SettingsUsers>, private usersReq: SettingsUsersRequests) {}

    @Effect()
    public loadAllUsers$: Observable<SettingsUsersActions> = this.actions$.pipe(
        ofType<LoadAllUsers>(SettingsUsersActionType.LoadAllUsers),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap(() => {
            return this.usersReq.loadAllUsers$().pipe(
                map((users: User[]) => {
                    return new LoadAllUsersSuccess(users);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new LoadAllUsersError());
                })
            );
        })
    );

    @Effect()
    public loadAllUsersSuccess$: Observable<SettingsUsersActions> = this.actions$.pipe(
        ofType<LoadAllUsersSuccess>(SettingsUsersActionType.LoadAllUsersSuccess),
        mergeMap((action) => {
            return of(new SetAllUsers(action.users));
        })
    );


	@Effect()
    public loadAllInvitations$: Observable<SettingsUsersActions> = this.actions$.pipe(
        ofType<LoadAllInvitations>(SettingsUsersActionType.LoadAllInvitations),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap(() => {
            return this.usersReq.loadAllInvitations$().pipe(
                map((invitations: UserRequest[]) => {
                    return new LoadAllInvitationsSuccess(invitations);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new LoadAllInvitationsError(err));
                })
            );
        })
    );

    @Effect()
    public loadAllInvitationsSuccess$: Observable<SettingsUsersActions> = this.actions$.pipe(
        ofType<LoadAllInvitationsSuccess>(SettingsUsersActionType.LoadAllInvitationsSuccess),
        mergeMap((action) => {
            return of(new SetAllInvitations(action.invitations));
        })
    );


    @Effect()
    public addNewInvitation$: Observable<SettingsUsersActions> = this.actions$.pipe(
        ofType<AddNewInvitation>(SettingsUsersActionType.AddNewInvitation),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        mergeMap((action) => {
            return this.usersReq.addNewInvitation$(action.name, action.email, action.message, action.groups, action.extra).pipe(
                map(() => {
                    return new AddNewInvitationSuccess();
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new AddNewInvitationError(err));
                })
            );
        })
    );

    @Effect()
    public addNewInvitationSuccess$: Observable<SettingsUsersActions> = this.actions$.pipe(
        ofType<AddNewInvitationSuccess>(SettingsUsersActionType.AddNewInvitationSuccess),
        mergeMap(() => {
            return of(new LoadAllInvitations());
        })
    );

    @Effect()
    public deleteOneUser$ : Observable<SettingsUsersActions> = this.actions$.pipe(
        ofType<DeleteOneUser>(SettingsUsersActionType.DeleteOneUser),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        mergeMap((action) => {
            return this.usersReq.deleteOneUser$(action.id).pipe(
                map(() => {
                    return new DeleteOneUserSuccess()
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new DeleteOneUserError());
                })
            );
        })
    );


    @Effect()
    public deleteOneUserSuccess$: Observable<SettingsUsersActions> = this.actions$.pipe(
        ofType<DeleteOneUserSuccess>(SettingsUsersActionType.DeleteOneUserSuccess),
        mergeMap(() => {
            return of(new LoadAllUsers());
        })
    );

    @Effect()
    public deleteInvitation$ : Observable<SettingsUsersActions> = this.actions$.pipe(
        ofType<DeleteInvitation>(SettingsUsersActionType.DeleteInvitation),
        //  withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        mergeMap((action) => {
            return this.usersReq.deleteInvitation$(action.email).pipe(
                map(() => {
                    return new DeleteInvitationSuccess();
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new DeleteInvitationError());
                })
            );
        })
    );

    @Effect()
    public deleteInvitationSuccess$: Observable<SettingsUsersActions> = this.actions$.pipe(
        ofType<DeleteInvitationSuccess>(SettingsUsersActionType.DeleteInvitationSuccess),
        mergeMap(() => {
            return of(new LoadAllInvitations());
        })
    );
}
