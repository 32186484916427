import { Component, Input, Output, OnInit,EventEmitter, ViewChild, ViewContainerRef, TemplateRef, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { ProjectsService } from '@app/services/app/projects.service';

import { File } from '@core/objects/file';
import { Request } from '@core/objects/request';


@Component({
    selector: 'project-details-info-attachments-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class ProjectDetailsInfoAttachmentsUploadComponent implements OnInit,OnDestroy {
    @ViewChild("container", {read: ViewContainerRef}) containerRef: ViewContainerRef;
    @ViewChild("content", {read: TemplateRef}) contentRef: TemplateRef<any>;

    @Output() done = new EventEmitter<boolean>();
   
    public files: File[] = [];
    public listFiles: File[] = [];
    public uploading: boolean = false;
    public submitted: boolean = false;

    protected subscriptor: Subscription;
    protected errorSubscriptor: Subscription;

	constructor(protected projectsSrv: ProjectsService ){}

	ngOnInit(){
        // // TODO: control access to activate/deactivat files in components
        this.renderUploader();

        this.errorSubscriptor = this.projectsSrv.selectError('upload_project_files').subscribe((error: HttpErrorResponse) => {
            console.log("error---->", error);
        });

        this.subscriptor = this.projectsSrv.selectProjectFiles().subscribe((files: File[] ) => {
            if(this.submitted) {
                if(files.length > this.listFiles.length) {
                    this.renderUploader();
                    this.sendDone();
                }
                this.files = []; //clean
                this.submitted = false;
            }
            this.listFiles = files;
            // this.status = this.STATUS_DONE;
        });
	}

    protected renderUploader() {
        this.containerRef.clear();
        this.containerRef.createEmbeddedView(this.contentRef);
    }

    protected removeUploader() {
        this.containerRef.clear();
    }

    public onSubmit(){
        this.submitted = true;
        console.debug("the files to upload are ", this.files);

        this.projectsSrv.uploadFiles(this.files);
    }

    public onCancel(){
        this.files = [];
        this.renderUploader();
        this.done.emit(); // the boolean is a flag to open the files list
    }

    protected sendDone() {
        this.done.emit(true);
    }

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
        this.errorSubscriptor.unsubscribe();
    }


}
