import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HttpStatus } from '@core/classes/http-status.class';

import { SelectItem } from '@core/models/select-item.model';

import { AvailableRuleItem, AvailableRuleActionVariable, AvailableRuleEventCondition, AvailableRuleAction, RuleEvent, RuleListItem } from '@app/models/rules';

import { Settings } from '../settings.state';
// export interface AvailableRuleEventConditionExtended extends AvailableRuleEventCondition {
//     options_items: SelectItem[]; // *
//     values_items: SelectItem[]; // *
// }

// export interface AvailableRuleActionExtended extends AvailableRuleItem {
//     variables: Array<AvailableRuleActionVariableExtended>;
// }

// export interface AvailableRuleActionVariableExtended extends AvailableRuleActionVariable {
//     values_items: SelectItem[]; // *
// }

export interface AvailableRule {
    event: AvailableRuleItem;
    conditions: Array<AvailableRuleEventCondition>;
    actions: Array<AvailableRuleAction>;
}

export interface SettingsRulesEditScroll {
    key: string;
    value: number;
}

// export interface Avalaibles {
//     events: Array<AvailableRule>
// }

export interface SettingsRules {
    availables: Array<AvailableRule>;
    rules: Array<RuleListItem>;
    editing: RuleEvent;
    editing_scroll: Array<SettingsRulesEditScroll>;
}

export const initialState: SettingsRules = {
    availables: [],
    rules: [],
    editing: {
        id: 0,
        name: "",
        key: "",
        conditions_control: "OR",
        conditions: [],
        actions: []
    },
    editing_scroll: []
};

const settingsFeatureSelector = createFeatureSelector('settings');

const settingsRulesFeatureSelector = createSelector(
    settingsFeatureSelector,
    (state: Settings) => state.rules
);

// Selectors
export const settingsRulesAvailableSelector = createSelector(
    settingsRulesFeatureSelector,
    (state: SettingsRules) => state.availables
);

export const settingsRulesRulesSelector = createSelector(
    settingsRulesFeatureSelector,
    (state: SettingsRules) => state.rules
);

export const settingsRulesEditingSelector = createSelector(
    settingsRulesFeatureSelector,
    (state: SettingsRules) => state.editing
);

export const settingsRulesEditingScrollSelector = createSelector(
    settingsRulesFeatureSelector,
    (state: SettingsRules) => state.editing_scroll
);

// export const usersGetAllInvitationsSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.invitations
// );

// // Status
// export const usersStatusListSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.status.list
// );

// export const usersStatusInviteSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.status.invite
// );

// export const usersStatusAddInviteSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.status.addInvite
// );

// export const usersStatusDelInviteSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.status.delInvite
// );

// export const usersStatusDelUserSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.status.delUser
// );
