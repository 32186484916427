import { MenuItem } from '@core/models/menu-item.model';

export const projectDetailsMenu : MenuItem[] = [
    {
        title: 'Info',
        link: '/projects/:id',
        // link: 'info',
        disabled: false
    },
    {
         title: 'Messages',
         link: '/projects/:id/messages',
        //  link: 'comments',
         disabled: false
    },
    {
        title: 'Artworks',
        link: '/projects/:id/versions',
        // link: 'versions',
        disabled: false
    },
    {
        title: 'Logbook',
        link: '/projects/:id/logbook',
        disabled: false
    }
];
