import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProjectsService } from '@app/services/app/projects.service';

import { AppService } from '@app/services/app/app.service';
import { VersionsService } from '@app/services/app/versions.service';

import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';

import { ActivatedRoute, Router } from '@angular/router';
import { VersionListItemMenuClick } from '@core/components/versions/list/version-list-item-menu-click';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { AppRouterService } from '@app/services/app/router.service';
import { AddProjectService } from '@app/services/app/add-project.service';
import { ProjectsEditorService } from 'app/services/app/projects.service';


@Component({
    selector: 'project-details-versions-relateds-list-versions',
    templateUrl: './relateds-list-versions.component.html',
    styleUrls: ['./relateds-list-versions.component.scss']
})
export class ProjectDetailsVersionsRelatedsListVersionsComponent implements OnInit, OnDestroy {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly ERROR = 2;

    public readonly RELATED_VERSIONS_CONTEXT = 'project-related-versions-group';
    protected readonly ACCESS_REQUEST_FEEDBACK = 'share_feedback';
    protected readonly ACCESS_ADD_PROJECT = "access_global_new_request";
    protected readonly ACCESS_UPLOAD_VERSION = "access_request_upload_version";

    @Input() related: Objects.Related;
    @Input() project: Objects.Request;

    public status:number = this.LOADING;
    public feedbackPluginAccess: boolean = false;
    public addProjectAccess: boolean = false;
    public versions: Array<Objects.Version> = [];
    public uploadedVersions: Array<Objects.Version> = [];
    public feedbacks: Array<VersionFeedback> = [];


    protected subscriptor: Subscription;
    protected accessUploadSubscriptor: Subscription;
    protected relatedsSubscriptor: Subscription;

    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "See Artwork Details",
            key: "details",
            icon: "see-details",
            order: 2
        },
        // MAYBE CHECK EITHER ARTWORK IS UPLOADED OR NOT TO REMOVEE THIS MENU
        {
            title: "See Original Project",
            key: "see-project",
            icon: "see-project",
            order: 3
        }
    ];

    // optional menu item
    protected menuAddProject: ThreeDotsMenuItem = {
        title: "Start New Project",
        key: "add-project",
        icon: "add",
        order: 4
    };

    protected uploadMenuItem: ThreeDotsMenuItem = {
        title: "Upload Artwork",
        key: "upload-artwork",
        icon: "upload_artwork",
        order: 1
    };


    constructor( public app: AppService, private versionsSrv: VersionsService, private projectsSrv: ProjectsService,
        private AddProjectSrv: AddProjectService,
        private appRouter: AppRouterService,
        private activatedRoute: ActivatedRoute,
        private editorSrv: ProjectsEditorService ) { }

    ngOnInit() {
        // get plugins access
        this.app.getSession().subscribe( (session: any) => {
            this.feedbackPluginAccess = session.options.plugins.some((pa) => pa == this.ACCESS_REQUEST_FEEDBACK);
            this.addProjectAccess = session.options.access.hasOwnProperty(this.ACCESS_ADD_PROJECT)? session.options.access[this.ACCESS_ADD_PROJECT] : false;
            this.updateMenu(this.menuAddProject,this.addProjectAccess);
        });

        // subscribe to access of upload versions
        this.accessUploadSubscriptor = this.editorSrv.selectAccess(this.ACCESS_UPLOAD_VERSION).subscribe((access: boolean) => {
            this.updateMenu(this.uploadMenuItem,access);
        });

        this.relatedsSubscriptor = this.versionsSrv.getVersionsGroup$(this.related.id).subscribe((versions: Array<Objects.Version>) => {
            this.versions = versions;
            if(this.versions.length > 0)
                this.loadFeedbacks(versions);
        });

        this.subscriptor = this.projectsSrv.selectUploadedVersions().subscribe((versions: Array<Objects.Version>) => this.uploadedVersions = versions);

    }

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
        this.accessUploadSubscriptor.unsubscribe();
        this.relatedsSubscriptor.unsubscribe();
        this.projectsSrv.clearVersionsGroups();
    }

    onMenuClick(menu: VersionListItemMenuClick) {
        console.debug(" menu version item  click ",menu);
        switch(menu.key) { 
            case "upload-artwork":
                this.appRouter.navigate('/projects/'+this.project.id+'/versions/add', { queryParams: { links: this.related.id }});
                break;
            case "details":
                if(this.project != null)
                    // go to version Details
                    this.appRouter.navigate('/projects/'+this.project.id + "/versions/"+menu.version.id, { queryParams: { context: 'related' } });
                break;
            case "see-project":
                // TODO navigate
                if(menu.version != null){
                    this.versionsSrv.getProject$(menu.version).subscribe((originalProject: Objects.Request) => {
                        console.debug(" the origin project of the version is ",originalProject);
                        if(originalProject != null)
                            this.appRouter.navigate('/projects/'+originalProject.id);
                    });
                }
                break;
            case "add-project":
                if(menu.version != null){
                    this.AddProjectSrv.clear();
                    this.appRouter.navigate('/projects/add', { queryParams: { version: menu.version.id } });
                }
                break;
        }
    }

    onFileClick(version: Objects.Version,file: Objects.File){
        // console.debug(" versions click file preview ",file);
        if (file != null && this.project != null){
            // TODO set this url to preview version FILE
            this.appRouter.navigate(version.id + "/files/"+file.id,{ relativeTo: this.activatedRoute});
        }
    }
    // feedback still come one by one for each version!! it should be by gorup of versions
    protected loadFeedbacks(versions: Array<Objects.Version>) {
        if(!this.feedbackPluginAccess) {
            this.status = this.DONE;
            return;
        }
        // TODO maybe use context here!!  && this.context == this.FEEDBACK_CONTEXT ('project-related-versions-group')

        this.versionsSrv.getFeedbacks$(versions).subscribe((feedbacks: Array<VersionFeedback>) => {
               this.feedbacks = feedbacks;
               this.status = this.DONE;
        });
    }

    public getFeedbacks(version: Objects.Version){
        return this.feedbacks.filter((f: VersionFeedback) => f.version.id == version.id);
    }

    public uploadedCheck(version: Objects.Version){
        return this.uploadedVersions.some((uploadVersion: Objects.Version) => uploadVersion.id == version.id);
    }

    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

}
