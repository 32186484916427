import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//import { PluginApolloUnoComponent } from './components/component1/component1.component';
//import { PluginApolloDosComponent } from './components/component2/component2.component';

import { PluginApolloMainAppComponent } from './components/main/app/app.component';
import { PluginApolloMainSectionUploadComponent } from './components/main/sections/upload/upload.component';
import { PluginApolloMainSectionCompareContentComponent } from './components/main/sections/compare-content/compare-content.component';
import { PluginApolloMainSectionCheckComponent } from './components/main/sections/check/check.component';
import { PluginApolloMainSectionCompareGraphicComponent } from './components/main/sections/compare-graphic/compare-graphic.component';
import { PluginApolloMainSectionHistoryComponent } from './components/main/sections/history/history.component';
import { PluginApolloMainSectionRequestComponent } from './components/main/sections/request/request.component';


export const apolloRoutes : Routes = [
    {
        path: 'p/apollo',
        // component: PluginApolloUnoComponent
        component: PluginApolloMainAppComponent,
        children: [
        {
            path: '',
            redirectTo: 'upload',
            pathMatch: 'full'
        },
        {
            path: 'upload',
            component: PluginApolloMainSectionUploadComponent
        },
        {
            path: 'upload/:hash',
            component: PluginApolloMainSectionUploadComponent
        },
        {
            path: 'upload?reference=id&target=id',
            component:PluginApolloMainSectionUploadComponent
        },
        {
            path: 'upload?reference=id',
            component:PluginApolloMainSectionUploadComponent
        },
        {
            path: 'upload?target=id',
            component:PluginApolloMainSectionUploadComponent
        },
        {
            path: 'compare-content',
            component: PluginApolloMainSectionCompareContentComponent
        },
        {
            path: 'compare-graphics',
            component: PluginApolloMainSectionCompareGraphicComponent
        },
        {
            path: 'check',
            component: PluginApolloMainSectionCheckComponent
        },
        {
            path: 'history',
            component: PluginApolloMainSectionHistoryComponent
        },
        {
            path: 'request/:id',
            component: PluginApolloMainSectionRequestComponent
        }
    ]
    },
    // {
    //     path: '**',
    //     component: PluginApolloDosComponent
    // },
];

@NgModule({
  imports: [RouterModule.forChild(apolloRoutes)],
  exports: [RouterModule]
})
export class ApolloRouterModule { }
