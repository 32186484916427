import { EventEmitter } from '@angular/core';

import { Viewer } from '@core/components/preview/preview-page-viewer/viewer';

export class NotesViewer {
    public viewers: any = {};
    public status: any = {};

    public subscriptors = {};

    public onChange = new EventEmitter<NotesViewer>();

    public constructor() {
        // TODO
    }

    public setPage(page: number) {
        if(!this.viewers.hasOwnProperty(page.toString())) {
            this.viewers[page] = new Viewer();
            this.subscriptors[page] = this.viewers[page].onChange.subscribe((viewer: Viewer) => this.update());
        }
        
        if(!this.status.hasOwnProperty(page.toString()))
            this.status[page] = new NotesViewerStatus();
    }

    public getViewerByPage(page: number) : Viewer{
        this.setPage(page);

        return this.viewers[page];
    }

    public getStatusByPage(page: number) : NotesViewerStatus {
        this.setPage(page);

        return this.status[page];
    }

    public setViewerByPage(page: number, viewer: Viewer) {
        // TODO
    }

    protected update() {
        let nsize: any = {};
        for(const key in this.viewers) {
            nsize = this.viewers[key].getNormalizeSize();

            // this.status[key].setContainerSize(nsize.width, nsize.height);
            // No es necesario aplicar el size normalizado
            // ya que el click se hace sobre la imagen y recupera los valores
            // reales de dimensiones
            // PROPUESTA: NotesViewerStatus puede ser una interfaz que cumpla
            // el contrato de Viewer (patrón Repository)
            this.status[key].setContainerSize(this.viewers[key].size.width, this.viewers[key].size.height);
            this.status[key].setRotation(this.viewers[key].rotation);
        }

        this.onChange.emit(this);
    }
}

export class NotesViewerStatus {

    public rotation: number = 0;
    public scale = 1;
    public containerSize: any = {
        width: 0,
        height: 0
    };

    public onChange = new EventEmitter<NotesViewerStatus>();

    public setContainerSize(width: number, height: number) {
        this.containerSize = {...this.containerSize, width: width, height: height};
    }

    public setRotation(rotation: number) {
        this.rotation = rotation;
        this.changed();
    }

    changed() {
        this.onChange.emit(this);
    }
}