import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { File } from '@core/objects/file';
import { Request } from '@core/objects/request';
import { Label } from '@core/objects/label';
import { Related } from '@core/objects/related';

@Component({
    selector: 'core-versions-related-groups-files-list-checkbox',
    templateUrl: './related-groups-files-list-checkbox.component.html',
    styleUrls: ['./related-groups-files-list-checkbox.component.scss']
})
export class VersionsRelatedGroupsFilesListCheckboxComponent implements OnInit {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly ERROR = 2;

    @Input() request: Request;

    @Input() disabled: boolean; 
    @Input()  selected: Array<File>;
    @Output() selectedChange = new EventEmitter<Array<File>>();

    public status:number = this.LOADING;
    public total: number = 0;
    public groups: Array<Related> = [];

    constructor(private httpClient: HttpClient, private urlSrv: UrlService ) { }

    ngOnInit() {
        this.loadRelatedGroups();
    }

    private loadRelatedGroups() {
        this.httpClient.get(this.urlSrv.build("/requests/" + this.request.id +"/versions/links")).subscribe(
            (data:Array<Related>) => {
                this.groups = data.map( l => { return { ...l, labels: this.diffLabels(l.labels)}})
                this.groups.forEach(g => this.total = this.total + g.total);
                this.status = this.DONE;
            },
            (error:any) => {
                this.status = this.ERROR;
            }
        );
    }

    private diffLabels(labels: Array<Label>): Array<Label> {
        return labels.filter(vl => !this.request.labels.some(rl => vl.id == rl.id));
    }

    onChange(files: Array<File>) {
        this.selected = files;
        this.selectedChange.emit(this.selected);
    }


}
