import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
// import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';
import { FilesListItemMenuClick } from '../files-list-item-menu-click';

@Component({
    selector: 'core-files-list-default-item',
    templateUrl: './default-item.component.html',
    styleUrls: ['./default-item.component.scss']
})
export class FilesListDefaultItemComponent implements OnInit {

    @Input() file: File;
    @Input() menu: Array<ThreeDotsMenuItem> = [];
    
    @Output() actionClick= new EventEmitter<File>();
    @Output() menuClick = new EventEmitter<FilesListItemMenuClick>();

    constructor() { }

    ngOnInit() {

        //console.log("file list item: ",this.file);
        // this.mainText = "<span class=\"name\">" + this.file.name +"</span> <span class=\"file-size\">(" + this.file.filesize + ")</span>";

        // if(!this.download)
        //     this.downloadText = "";
    }

    onMenuSelect(key: string) {
        this.menuClick.emit({key: key, file: this.file});
    }
}
