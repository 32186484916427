import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import * as Objects from '@core/objects';

import * as Access from '@app/models/access';
import { AddRequest } from '@app/models/add-project';

export enum AddProjectActionType {

	// EVENT CALLS
    InitAddProject = "[AddProject] Initialize new project data",

	// GetAddProject = "[AddProject] GET - Obtain saved project data",

    UpdateAddProject = "[AddProject] Update new project data",

    Clear = "[AddProject] Clear data"
}

export class InitAddProject implements Action {
    readonly type = AddProjectActionType.InitAddProject;
    constructor(public workflowId: number, public versionId: number | null) {}
}

// Load Available Events
// export class GetAddProject implements Action {
//     readonly type = AddProjectActionType.GetAddProject;
//     constructor() {}
// }

export class UpdateAddProject implements Action {
    readonly type = AddProjectActionType.UpdateAddProject;
    constructor(public property: Partial<AddRequest>) {}
}

export class Clear implements Action {
    readonly type = AddProjectActionType.Clear;
    constructor() {}
}


// export type AddProjectActions = InitAddProject |	
//                                 GetAddProject |
//                                 UpdateAddProject |
//                                 Clear;

export type AddProjectActions = InitAddProject |
                                UpdateAddProject |
                                Clear;