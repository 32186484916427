import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatus } from '@core/classes/http-status.class';

import * as Objects from '@core/objects';

import * as Access from '@app/models/access';

export enum VersionsActionType {

	// EVENT CALLS
	LoadVersion = "[Versions] GET - Obtain a version by id",
	LoadVersionSuccess = "[Versions] GET - Obtain a version by id SUCCESS",
	LoadVersionError = "[Versions] GET - Obtain a version by id ERROR",

	LoadRelatedVersions = "[Versions] GET - Obtain a related versions by link",
	LoadRelatedVersionsSuccess = "[Versions] GET - Obtain a related versions by link SUCCESS",
	LoadRelatedVersionsError = "[Versions] GET - Obtain a related version by link ERROR",

    LoadVersionFiles = "[Versions] GET - Obtain a version files",
    LoadVersionFilesSuccess = "[Versions] GET - Obtain a version files SUCCESS",
    LoadVersionFilesError = "[Versions] GET - Obtain a version files ERROR",

    LoadVersionMessages = "[Versions] GET - Obtain a version messages",
    LoadVersionMessagesSuccess = "[Versions] GET - Obtain a version messages SUCCESS",
    LoadVersionMessagesError = "[Versions] GET - Obtain a version messages ERROR",

    LoadVersionComments = "[Versions] GET - Obtain a version messages with context",
    LoadVersionCommentsSuccess = "[Versions] GET - Obtain a version messages with context SUCCESS",
    LoadVersionCommentsError = "[Versions] GET - Obtain a version messages with context ERROR",

    LoadVersionFeedbacks = "[Versions] GET - Obtain a version feedbacks",
    LoadVersionFeedbacksSuccess = "[Versions] GET - Obtain a version feedbacks SUCCESS",
    LoadVersionFeedbacksError = "[Versions] GET - Obtain a version feedbacks ERROR",

    LoadVersionProject = "[Versions] GET - Obtain a version project",
    LoadVersionProjectSuccess = "[Versions] GET - Obtain a version project SUCCESS",
    LoadVersionProjectError = "[Versions] GET - Obtain a version project ERROR",

    SetVersionStatus = "[Versions] PUT - Set version status",
    SetVersionStatusSuccess = "[Versions] PUT - Set version status SUCCESS",
    SetVersionStatusError = "[Versions] PUT - Set version status ERROR",

    SetVersionLabels = "[Versions] PUT - Set labels in version",
    SetVersionLabelsSuccess = "[Versions] PUT - Set labels in version SUCCESS",
    SetVersionLabelsError = "[Versions] PUT - Set labels in version ERROR",

    LoadEditorAccess = "[Versions - Editor] GET - Obtain access",
    LoadEditorAccessSuccess = "[Versions - Editor] GET - Obtain access SUCCESS",
    LoadEditorAccessError = "[Versions - Editor] GET - Obtain access ERROR",

    LoadVersionHeader = "[Versions] GET - Obtain a Version header by id",
	LoadVersionHeaderSuccess = "[Versions] Obtain a Version header by id SUCCESS",
	LoadVersionHeaderError = "[Versions] Obtain a Version header by id ERROR",

    UpdateVersion = "[Versions] Update version by id",

    Clear = "[Versions] clear data",
    ClearFiles = "[Versions] clear files"
}

// Load Available Events
export class LoadVersion implements Action {
    readonly type = VersionsActionType.LoadVersion;
    constructor(public versionId: number) {}
}
export class LoadVersionSuccess implements Action {
    readonly type = VersionsActionType.LoadVersionSuccess;
    constructor(public version: Objects.Version) {}
}

export class LoadVersionError implements Action {
    readonly type = VersionsActionType.LoadVersionError;
    constructor(public versionId: number, public error: HttpErrorResponse) {}
}

export class LoadRelatedVersions implements Action {
    readonly type = VersionsActionType.LoadRelatedVersions;
    constructor(public link: string) {}
}
export class LoadRelatedVersionsSuccess implements Action {
    readonly type = VersionsActionType.LoadRelatedVersionsSuccess;
    constructor(public versions: Objects.Version[]) {}
}

export class LoadRelatedVersionsError implements Action {
    readonly type = VersionsActionType.LoadRelatedVersionsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadVersionFiles implements Action {
    readonly type = VersionsActionType.LoadVersionFiles;
    constructor() {}
}

export class LoadVersionFilesSuccess implements Action {
    readonly type = VersionsActionType.LoadVersionFilesSuccess;
    constructor(public files: Array<Objects.File>) {}
}

export class LoadVersionFilesError implements Action {
    readonly type = VersionsActionType.LoadVersionFilesError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadVersionProject implements Action {
    readonly type = VersionsActionType.LoadVersionProject;
    constructor() {}
}

export class LoadVersionProjectSuccess implements Action {
    readonly type = VersionsActionType.LoadVersionProjectSuccess;
    constructor(public project: Objects.Request) {}
}

export class LoadVersionProjectError implements Action {
    readonly type = VersionsActionType.LoadVersionProjectError;
    constructor(public error: HttpErrorResponse) {}
}

export class SetVersionStatus implements Action {
    readonly type = VersionsActionType.SetVersionStatus;
    constructor(public status: Objects.Status) {}
}

export class SetVersionStatusSuccess implements Action {
    readonly type = VersionsActionType.SetVersionStatusSuccess;
    constructor(public version: Objects.Version) {}
}

export class SetVersionStatusError implements Action {
    readonly type = VersionsActionType.SetVersionStatusError;
    constructor(public error: HttpErrorResponse) {}
}

export class Clear implements Action {
    readonly type = VersionsActionType.Clear;
    constructor() {}
}

export class ClearFiles implements Action {
    readonly type = VersionsActionType.ClearFiles;
    constructor() {}
}

export class LoadEditorAccess implements Action {
    readonly type = VersionsActionType.LoadEditorAccess;
    constructor() {}
}

export class LoadEditorAccessSuccess implements Action {
    readonly type = VersionsActionType.LoadEditorAccessSuccess;
    constructor(public access: Access.AccessVersion) {}
}

export class LoadEditorAccessError implements Action {
    readonly type = VersionsActionType.LoadEditorAccessError;
    constructor(public error: HttpErrorResponse) {}
}


export class SetVersionLabels implements Action {
    readonly type = VersionsActionType.SetVersionLabels;
    constructor(public labels: Array<Objects.Label>) {}
}

export class SetVersionLabelsSuccess implements Action {
    readonly type = VersionsActionType.SetVersionLabelsSuccess;
    constructor(public version: Objects.Version) {}
}

export class SetVersionLabelsError implements Action {
    readonly type = VersionsActionType.SetVersionLabelsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadVersionMessages implements Action {
    readonly type = VersionsActionType.LoadVersionMessages;
    constructor() {}
}

export class LoadVersionMessagesSuccess implements Action {
    readonly type = VersionsActionType.LoadVersionMessagesSuccess;
    constructor(public messages: Array<Objects.Message>) {}
}

export class LoadVersionMessagesError implements Action {
    readonly type = VersionsActionType.LoadVersionMessagesError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadVersionComments implements Action {
    readonly type = VersionsActionType.LoadVersionComments;
    constructor() {}
}

export class LoadVersionCommentsSuccess implements Action {
    readonly type = VersionsActionType.LoadVersionCommentsSuccess;
    constructor(public messages: Array<Objects.VersionMessage>) {}
}

export class LoadVersionCommentsError implements Action {
    readonly type = VersionsActionType.LoadVersionCommentsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadVersionHeader implements Action {
    readonly type = VersionsActionType.LoadVersionHeader;
    constructor(public versionId: number) {}
}
export class LoadVersionHeaderSuccess implements Action {
    readonly type = VersionsActionType.LoadVersionHeaderSuccess;
    constructor(public version: Objects.Version) {}
}

export class LoadVersionHeaderError implements Action {
    readonly type = VersionsActionType.LoadVersionHeaderError;
    constructor(public error: HttpErrorResponse) {}
}

export class UpdateVersion implements Action {
    readonly type = VersionsActionType.UpdateVersion;
    constructor(public versionId: number) {}
}

export type VersionsActions = 	LoadVersion |
                                LoadVersionSuccess |
								LoadVersionError |
                                LoadVersionFiles |
                                LoadVersionFilesSuccess | 
                                LoadVersionFilesError |

                                LoadRelatedVersions |
                                LoadRelatedVersionsSuccess |
								LoadRelatedVersionsError |

                                LoadVersionProject |
                                LoadVersionProjectSuccess |
                                LoadVersionProjectError |
                        
                                LoadEditorAccess |
                                LoadEditorAccessSuccess |
                                LoadEditorAccessError | 
                                
                                SetVersionStatus |
                                SetVersionStatusSuccess |
                                SetVersionStatusError |

                                SetVersionLabels |
                                SetVersionLabelsSuccess |
                                SetVersionLabelsError |
                               
                                LoadVersionMessages |
                                LoadVersionMessagesSuccess |
                                LoadVersionMessagesError | 

                                LoadVersionComments |
                                LoadVersionCommentsSuccess |
                                LoadVersionCommentsError |

                                LoadVersionHeader |
                                LoadVersionHeaderSuccess |
                                LoadVersionHeaderError | 

                                UpdateVersion | 

                                Clear |
                                ClearFiles 
                                ;
