import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SizeMeModule } from 'ngx-size-me';
import { AngularDraggableModule } from 'angular2-draggable';

// external dependencies
import { FileUploadModule } from 'ng2-file-uploading-with-chunk';
import { FileSizeModule} from 'ngx-filesize';


import { HelperBoxModule } from '@core/components/helpers/box/box.module';
import { HelperFormModule } from '@core/components/helpers/form/form.module';
import { NotesModule } from '@core/components/notes/notes.module';
//import { UploadxModule } from 'ngx-uploadx';

import { FilesListModule } from './lists/lists.module';

// components
import { FileUploadedListItemComponent } from './uploaded-list-item/uploaded-list-item.component';
import { FilesListDownloadComponent } from './files-list-download/files-list-download.component';
import { FilesUploadComponent } from './files-upload/files-upload.component';
import { FilePageFiltersComponent } from './file-page-filters/file-page-filters.component';
import { FileListCheckboxComponent } from './files-list-checkbox/files-list-checkbox.component';
import { FileSelectedBoxComponent } from './file-selected-box/file-selected-box.component'; // @deprecated??
import { FilesSelectedBoxComponent } from './files-selected-box/files-selected-box.component';
import { FileDownloadOneActionListComponent } from './file-download-one-action-list/file-download-one-action-list.component';
import { FileDownloadOneActionListItemComponent } from './file-download-one-action-list-item/file-download-one-action-list-item.component';
import { FileDownloadThreeActionListComponent } from './file-download-three-action-list/file-download-three-action-list.component';
import { FileDownloadThreeActionListItemComponent } from './file-download-three-action-list-item/file-download-three-action-list-item.component';
import { FileNameDownloadComponent } from './file-name-download/file-name-download.component';
import { FilesNotesActionListComponent } from './files-notes-action-list/files-notes-action-list.component';
import { FileNotesComponent } from './file-notes/file-notes.component';
// import { FilesUploadMainComponent } from './upload/upload.component';

@NgModule({
    imports: [
        CommonModule,
        SizeMeModule,
        AngularDraggableModule,
        FileUploadModule,
        FileSizeModule,
        HelperBoxModule,
        HelperFormModule,
        NotesModule,
        FilesListModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        FileUploadedListItemComponent,
        FilesListDownloadComponent,
        FilesUploadComponent,
        FilePageFiltersComponent,
        FileListCheckboxComponent,
        FileSelectedBoxComponent,
        FilesSelectedBoxComponent,
        FileDownloadOneActionListComponent,
        FileDownloadOneActionListItemComponent,
        FileDownloadThreeActionListComponent,
        FileDownloadThreeActionListItemComponent,
        FileNameDownloadComponent,
        FilesNotesActionListComponent,
        FileNotesComponent,
        // FilesUploadMainComponent
    ],
    exports: [
        FileUploadedListItemComponent,
        FilesListDownloadComponent,
        FilesUploadComponent,
        FilePageFiltersComponent,
        FileListCheckboxComponent,
        FileSelectedBoxComponent,
        FilesSelectedBoxComponent,
        FileDownloadOneActionListComponent,
        FileDownloadOneActionListItemComponent,
        FileDownloadThreeActionListComponent,
        FileDownloadThreeActionListItemComponent,
        FileNameDownloadComponent,
        FilesNotesActionListComponent,
        FileNotesComponent,
        // FilesUploadMainComponent
    ],

})
export class FilesModule { }
