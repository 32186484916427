import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { VersionsViewService } from '@app/services/app/versions.service';
import { AppRouterService } from '@app/services/app/router.service';
import { AppService } from '@app/services/app/app.service';

import { UrlService } from '@core/services/url/url.service';
import { TitleService } from '@core/services/title.service';

import * as Objects from '@core/objects';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
    selector: 'version-details-file-preview-main',
    templateUrl: './version-file-preview.component.html',
    styleUrls: ['./version-file-preview.component.scss']
})
export class VersionsDetailsFilePreviewComponent implements OnInit, OnDestroy {

    protected readonly PLUGIN_SEND_FILES = 'share_transfer';
    protected readonly PLUGIN_APOLLO = 'apollo';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_ERROR = 2;
    public readonly STATUS_NOT_LINKED = 3;
    public readonly STATUS_FILE_NOT_FOUND = 4;
    public readonly STATUS_NOT_ALLOWED= 5;
    public readonly STATUS_NOT_FOUND= 6;

    public version: Objects.Version = null;
    public file: Objects.File = null;
    public pluginsAccess: Array<string> = [];
    public originProject: Objects.Request = null;

    public context: string = '';

    public breadcrumbs: any = [{
        link: "/",
        title: 'Artwork v. . . .  <small>(#  .  .  . )</small>'
    }];

    public status:number = this.STATUS_LOADING;
    public menu: Array<ThreeDotsMenuItem> = [{
        title: "Download File",
        key: "download",
        icon: "download",
        order: 1
    }];


    protected menuSendFiles: ThreeDotsMenuItem = {
        title: "Send",
        key: "send-files",
        icon: "send-files",
        order: 2
    };
    protected menuCompare: ThreeDotsMenuItem = {
        title: "Compare",
        key: "compare",
        icon: "compare",
        order: 3
    };

    protected urlSubscriptor: Subscription;
    protected urlParamsSubscriptor: Subscription;
    protected filesSubscriptor: Subscription;
    protected versionSubscriptor: Subscription;
    protected appSubscriptor: Subscription;
    protected originProjectSubscriptor: Subscription;
    protected errorSubscriptor: Subscription;

    constructor(private title: TitleService,
        private app: AppService,
        private urlSrv: UrlService,
        private appRouter: AppRouterService,
        private activeRoute: ActivatedRoute,
        private viewVersionsSrv: VersionsViewService) { }

    ngOnInit() {
        this.title.set("Preview File... - Artwork...");

        this.urlSubscriptor = this.activeRoute.queryParams.subscribe((params: any) => {
            // console.log("query params ===>", params);
            if (params.context) {
                this.context = params.context;
            }
        });

        // get the version
        this.versionSubscriptor = this.viewVersionsSrv.selectVersion().subscribe((version: Objects.Version) =>
        {
            // console.log(" VERSION LOADED in file PREVIEW ",version);
            this.version = version;
            this.initView();
        });

        this.originProjectSubscriptor = this.viewVersionsSrv.selectProject().subscribe((projectOrigin: Objects.Request) => {
            this.originProject = projectOrigin;
        });

        this.urlParamsSubscriptor = this.activeRoute.params.subscribe((params: any) => {
            let fid: any = +params.fid;
            this.filesSubscriptor = this.viewVersionsSrv.selectFiles().subscribe((files: Objects.File[] ) => {
                if(files.length > 0){
                    let match = files.filter((f: Objects.File) => {return f.id == fid;});
                    if (match.length > 0 ){
                        this.file = match[0];
                    } else {
                        this.status = this.STATUS_FILE_NOT_FOUND;
                    }
                    this.initView();
                }

            });

        });


        this.appSubscriptor = this.app.getSession().subscribe( (session: any) => {
            this.pluginsAccess = session.options.plugins;
            this.updateMenu(this.menuSendFiles,this.checkPluginAccess(this.PLUGIN_SEND_FILES))
            this.updateMenu(this.menuCompare,this.checkPluginAccess(this.PLUGIN_APOLLO));
        });

        this.errorSubscriptor = this.viewVersionsSrv.selectError('load_version').subscribe((error: HttpErrorResponse) => {
            this.setError(error);
        });
    }

    ngOnDestroy() {
        this.urlSubscriptor.unsubscribe();
        this.urlParamsSubscriptor.unsubscribe();
        this.versionSubscriptor.unsubscribe();
        this.appSubscriptor.unsubscribe();
        if(this.filesSubscriptor)
            this.filesSubscriptor.unsubscribe();
        this.originProjectSubscriptor.unsubscribe();
        this.errorSubscriptor.unsubscribe();
        // this.viewVersionsSrv.clear();
    }

    onMenuClick(menu: FilesListItemMenuClick) {
        switch(menu.key) {
            case "download":
                window.location.href = this.urlSrv.build('/files/'+menu.file.id);
                break;
            case "send-files":
                if (this.checkPluginAccess(this.PLUGIN_SEND_FILES) && this.originProject != null){
                    this.appRouter.navigate('/p/transfer/'+this.originProject.id, { queryParams: { file: +menu.file.id} });
                }
                break;
            case "compare":
                if (this.checkPluginAccess(this.PLUGIN_APOLLO) && this.originProject != null)
                    this.appRouter.navigate('/p/apollo/request/'+this.originProject.id);//, { queryParams: { reference: +menu.file.id} });
                break;
        }
    }

    protected checkPluginAccess(plugin: string){
        return this.pluginsAccess.some((pa) => pa == plugin);
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

    private initView() {
        if(this.version != null)
            this.updateBreadcrumbs();
        if(this.version == null || this.file == null)
            return;

         this.title.set("Preview File "+this.file.name+ " - Artwork #"+ this.version.id);
        this.status = this.STATUS_DONE;
    }

    protected updateBreadcrumbs(){ // update intermediate breadcrumb depending on context
        switch (this.context) {
            case "other":
                break;
            default:
                this.breadcrumbs[0] = {link: "/versions/"+this.version.id,
                    title: 'Artwork v.'+this.version.version+' <small>(#'+this.version.id+')</small>'};
                break;
        }
    }

    protected setError(error: HttpErrorResponse) {
        // console.log("we have file or version errors ",error)
        if(error == null)
            return;
        switch(error.status) {
            case 500:
                this.status = this.STATUS_ERROR;
                break;
            case 403:
                this.viewVersionsSrv.clearFiles();
                this.file = null;
                this.status = this.STATUS_NOT_ALLOWED;
                break;
        }
    }

}
