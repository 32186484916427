import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'core-helper-box-notification-message',
    templateUrl: './notification-message.component.html',
    styleUrls: ['./notification-message.component.scss']
})

/*
 An horizontal width 100% bar to display a message after an action is performed,
 @type: string to set up a class to change box message appearence.
 @message: the text of the message.
 https://www.youtube.com/watch?v=5p8wTOr8AbU
 */

export class HelperBoxNotificationMessageComponent {
    @Input() type: string;
    @Input() message: string;

}
