import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { VersionsViewService } from '@app/services/app/versions.service';

import { WebsocketService } from '@core/services/websocket/websocket.service';

import * as Objects from '@core/objects';
import { TitleService } from '@core/services/title.service';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AppService } from 'app/services/app/app.service';


@Component({
    selector: 'version-details-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class VersionsDetailsMainComponent implements OnInit, OnDestroy {
    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;
    public readonly STATUS_NOT_FOUND = 3;
    public readonly STATUS_ERROR = 4;

    public version: Objects.Version = null;
    protected versionId: number;

    public context: string = '';

    
    public status:number = this.STATUS_LOADING;
    public statusHeader:number = this.STATUS_LOADING;

    protected urlSubscriptor: Subscription;
    protected versionSubscriptor: Subscription;
    protected errorSubscriptor: Subscription;
    protected errorHeaderSubscriptor: Subscription;
    protected wsSubscription: any = null;
    protected appSubscriptor: Subscription;

    constructor(private title: TitleService,
        public app: AppService,
        private activeRoute: ActivatedRoute,
        private viewVersionsSrv: VersionsViewService, 
        public websocket: WebsocketService
    ) { }

    ngOnInit() {
        this.title.set("Version ....");

        // get the version id and load it in the store.
        this.urlSubscriptor = this.activeRoute.params.subscribe((params: any) => {
            if(params.vid){
                this.viewVersionsSrv.clear(); // must clear to avoid interference on data at library navigation other versions
                this.viewVersionsSrv.load(+params.vid);
                this.versionId = +params.vid;
            }

        });
        // get the version
        this.versionSubscriptor = this.viewVersionsSrv.selectVersion().subscribe((version: Objects.Version) =>
        {
            if(version == null) 
                return;
            this.version = version;
            this.title.set("Version #"+this.version.id);
            this.status = this.STATUS_DONE;
            
        });

        this.appSubscriptor = this.app.getSession().subscribe( (session: any) => {
            if(session == null)
                return;

        });

        this.errorSubscriptor = this.viewVersionsSrv.selectError('load_version').subscribe((error: HttpErrorResponse) => this.setError(error));
        this.errorHeaderSubscriptor = this.viewVersionsSrv.selectError('load_header').subscribe((error: HttpErrorResponse) => this.setHeaderError(error));

        
    }

    ngOnDestroy() {
        this.urlSubscriptor.unsubscribe();
        this.versionSubscriptor.unsubscribe();
        this.errorSubscriptor.unsubscribe();
        this.errorHeaderSubscriptor.unsubscribe();
        this.appSubscriptor.unsubscribe();
        if(this.wsSubscription)
            this.wsSubscription.unsubscribe();
        this.viewVersionsSrv.clear();
    }

    //TODO SET ERRORS
    protected setError(error: HttpErrorResponse) {

        // console.log("errrroooooorrrr--->", error);
        if(error == null)
            return;

        switch(error.status) {
            case 500:
                this.status = this.STATUS_ERROR;
                break;
            case 404:
                this.status = this.STATUS_NOT_FOUND;
                break;
            case 403:
                this.status = this.STATUS_NOT_ALLOWED;
                break;
        }
    }

    protected setHeaderError(error: HttpErrorResponse) {

        // console.log("errrroooooorrrr--->", error);
        if(error == null)
            return;

        switch(error.status) {
            case 500:
                this.statusHeader = this.STATUS_ERROR;
                break;
            case 404:
                this.statusHeader = this.STATUS_NOT_FOUND;
                break;
            case 403:
                this.statusHeader = this.STATUS_NOT_ALLOWED;
                break;
        }
    }

}
