import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';

import { SettingsRulesService, SettingsRulesEditorService } from '@app/services/settings/srules.service';

import { AvailableRuleItem, RuleEvent, AvailableRuleEventCondition, RuleCondition } from '@app/models/rules';
import { AvailableRule } from '@app/store/state/settings/rules/rules.state';

import { RuleCreator } from '../../rule-creator';

@Component({
  selector: 'settings-rules-rule-form-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss'],
})
export class SettingsRulesRuleFormConditionsComponent implements OnInit, OnDestroy {

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

    // @Input() creator: RuleCreator;
    @Input() form: FormGroup;

    protected rule: RuleEvent = null;

    public availableConditions: Array<AvailableRuleEventCondition> = [];
    // public conditions: Array<RuleCondition> = [];
    public conditionsControlValues: Array<any> = [{label:'OR',value:'OR'},{label:'AND',value:'AND'}];

    public status: number = 0;

    private availablesSubscriptor: Subscription;
    private editingSubscriptor: Subscription;

    constructor(public srulesSrv: SettingsRulesService, protected editorSrv: SettingsRulesEditorService){}

    ngOnInit(){
        this.availablesSubscriptor = this.srulesSrv.selectAvailableEvents().subscribe((availables: AvailableRule[] ) => {
            this.availableConditions = [];
            if(this.rule != null && this.rule.key!="") {
                let arule = availables.filter((a:AvailableRule) => a.event.key == this.rule.key);
                this.availableConditions = arule[0].conditions;
            }

            this.status = this.availableConditions.length > 0? this.STATUS_DONE : this.STATUS_LOADING;
        });

        this.editingSubscriptor = this.srulesSrv.selectEditing().subscribe((edit: RuleEvent) => {
            this.rule = edit;
        });

    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.editingSubscriptor.unsubscribe();
        this.availablesSubscriptor.unsubscribe();
    }

    private getFirstAvailableCondition() : RuleCondition {
        let first = this.availableConditions[0];

        return {
            variable: first.key,
            condition: first.options[0].key,
            value: first.values[0].key
        }
    }

    addCondition(position: number) {
        this.editorSrv.addCondition(position);
    }

    removeCondition(position: number){
        this.editorSrv.removeCondition(position);
    }

    onConditionChange(position: number, condition: RuleCondition) {
        // console.log("ppooooosition--->", position, "condition--->", condition);

        // this control should be in store, but doesnt work for me
        // let condit = { ...this.rule.conditions[position]}
        // console.log("current--->", condit, condition);

        this.editorSrv.changeCondition(position, condition);
    }

    onConditionsControlChange(conditionsControl: string){
        this.editorSrv.setConditionsControl(conditionsControl);
    }

}
