import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { LabelsGroup } from '@core/objects/labels-group';

@Injectable({
    providedIn: 'root'
})
export class LabelsGroupsService {

    constructor(protected url: UrlService, protected http: HttpClient) {}

    getAllLabelsGroups$(prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/labels/categories"),{params: {all: 'true'}});
    }

    getLabelsGroup$(labelsGroup: LabelsGroup, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/labels/categories/" + labelsGroup.id));
    }

    deactivateLabelsGroup$(labelsGroup: LabelsGroup, prefixUrl: string = "") {
        return this.http
        .delete(this.url.build(prefixUrl+"/labels/categories/" + labelsGroup.id ));
    }

    activateLabelsGroup$(labelsGroup: LabelsGroup, prefixUrl: string = "") {
        return this.http
        .put(this.url.build(prefixUrl+"/labels/categories/" + labelsGroup.id ),
            {active: true}
        );
    }

    updateLabelsGroupColor$(labelsGroup: LabelsGroup,color: string = "", prefixUrl: string = "") {
        return this.http
        .put(this.url.build(prefixUrl+"/labels/categories/" + labelsGroup.id ),
            {color: color}
        );
    }



    addLabelsGroup$(name: string, prefixUrl: string = "") {
        let options = {headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};
        let lgrpData: any = {
            name: name
        };
        return this.http.post(
            this.url.build(prefixUrl+"/labels/categories"),
            lgrpData,
            options
        );
    }
}
