import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// import { HttpStatus } from '@core/classes/http-status.class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router} from "@angular/router";


import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';

@Component({
    selector: 'settings-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SettingsMainComponent implements OnInit{
    private readonly SETTINGS_LIST = ['tokens',
                                        'groups',
                                        'categories',
                                        'users',
                                        'rules',
                                        'versions',
                                        'workflows'];

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

	public accessList: Array<any> = null;
    public forbidden: boolean = true;
    public status: number = 0;


	constructor(public app: AppService, private title: TitleService, private router: Router){}

	ngOnInit(){
        this.title.set("System settings");
        this.app.getSession().subscribe( (session: any) => {
            this.accessList = session.options.access;
            this.SETTINGS_LIST.forEach((item) => {
                let access = session.options.access.hasOwnProperty("access_setting_"+item+"_access")? session.options.access["access_setting_"+item+"_access"] : false;
                if(access){
                    this.forbidden = false;
                }

            });
        });
        if (this.accessList){
            this.status = this.STATUS_DONE;
        }
	}


	protected open(setting: String){
        this.router.navigate(['/settings/', setting]);
	}

    public allowed(setting: String){
        let access = "access_setting_"+setting+"_access";
        return this.accessList.hasOwnProperty(access)?  this.accessList[access] : false;

    }

}
