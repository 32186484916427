import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

//import { HttpClient } from '@angular/common/http';
import { SelectItem } from '@core/models/select-item.model';
import { languagesOptions } from '../../../data/languages';

import { Subscription } from 'rxjs';
import { ApolloService } from '../../../services/apollo/apollo.service';
import { ApolloRequests } from '../../../services/apollo/apollo-requests.service';
//import { UrlService } from '@core/services/url/url.service';
import { ApolloContentComparisonExtract, ApolloContentComparison } from '../../../model/apollo-content-comparison.model';

@Component({
    selector: 'plugin-apollo-helpers-content-extract-result',
    templateUrl: './content-extract-result.component.html',
    styleUrls: ['./content-extract-result.component.scss']
})
export class PluginApolloHelpersContentExtractResultComponent implements OnInit, OnDestroy {

    @Input() title:string;
    @Input() hash:string;
    @Input() type:string;
    @Output() infoClick = new EventEmitter<boolean>();
    @Output() extract = new EventEmitter<ApolloContentComparisonExtract>();


    //private readonly compare = "/p/apollo/compare";

    public readonly PENDING = 0;
    public readonly DONE = 10;
    public readonly EMPTY = 11;
    public readonly USER_ERROR = 20;
    public readonly UNRELIABLE = 21;
    public readonly ERROR = 30;
    public readonly UNAVAILABLE = 40;

    private readonly ALLOWED_EXTRACT_TYPES = ['tetml', 'tetml-unordered', 'hocr'];

    private readonly DEFAULT_STATUS = { code: this.PENDING, message: "" };
    // private readonly PENDING = 0;
    // private readonly DONE = 1;
    // private readonly ERROR = 2;
    // private readonly EMPTY = 3;

    public extractionBtn:boolean = false;

    public subscriptionExtract: Subscription;

    //public controlStatus:number = this.PENDING;

    public response:string;

    public apolloContent : ApolloContentComparison;
    public contentExtract : ApolloContentComparisonExtract;

    // public refExt:boolean = false;
    // public tarExt:boolean = false

    protected infoOpened: boolean = false;

    public textNewExtractionError:string = '';

    // new variables
    public status: any = this.DEFAULT_STATUS;

    constructor(public apolloSrv: ApolloService, protected apolloReq: ApolloRequests){}

    ngOnInit() {
        this.subscriptionExtract = this.apolloSrv.selectorContentCompare().subscribe((contentCompare : ApolloContentComparison) => {
            this.apolloContent = contentCompare;
            if (this.apolloContent != null) {
                //this.contentExtract = this.type === "reference" ? this.apolloContent["reference_extract"] : this.apolloContent["target_extract"];
                this.contentExtract = this.apolloContent[this.type + "_extract"] ;
                // if (this.apolloContent.status_code == 0 || this.apolloContent.status_code == 5) {
                //     this.extractionBtn = false;
                // } else {
                //     this.extractionBtn = true;
                //     if (this.apolloContent.status_code === 6) {
                //         this.errorExtraction();
                //     }
                //     // this.apolloContent.status_code === 6 ? this.errorExtraction(): this.extractionBtn = true;
                // }

                console.debug("extraction--", this.type, "--->", this.contentExtract);

                this.process(this.contentExtract, this.apolloContent.status_code);
            }
        });

        if(this.DONE == this.status.code)
            this.getText();
    }

    ngOnDestroy(){
        this.subscriptionExtract.unsubscribe();
    }

    private process(extract: ApolloContentComparisonExtract, status: number) {
        // this.status = {
        //     code: extract.status_code,
        //     message: extract.status_message
        // };
        //
        // // button check
        // if(this.DONE == this.status.code) {
        //     this.getText();
        // }
        if(extract.status_code == this.DONE) {
            this.getText();
        } else {
            this.status = {
                code: extract.status_code,
                message: extract.status_message
            };
        }

        // if (status == 0 || this.apolloContent.status_code == 5) {
        //     this.controlStatus = this.PENDING;
        // } else {
        //     // this.extractionBtn = true;
        //     // if (this.apolloContent.status_code === 6) {
        //     //     this.controlStatus = this.ERROR;
        //     //     this.errorExtraction();
        //     // }
        //     // this.apolloContent.status_code === 6 ? this.errorExtraction(): this.extractionBtn = true;
        //     this.controlStatus = this.getStatusControl(extract);
        // }
        //
        // switch(this.controlStatus) {
        //     case this.NOT_ERROR_FOUND:
        //         this.getText();
        //         break;
        //     case this.USER_ERROR:
        //         this.textNewExtractionError = extract.user_error
        //         break;
        // }
        //
        // // if(this.controlStatus === this.NOT_ERROR_FOUND)
        // //     this.getText();
        //
        //
        // // button check
        // // if(this.PENDING === this.controlStatus)
        // //     this.extractionBtn = false;
        // // else
        // //     this.extractionBtn = true;
        this.buttonControl();

    }

    // private getStatusControl(extract: ApolloContentComparisonExtract) : number {
    //     if(!extract.empty && extract.user_error != null)
    //         return this.USER_ERROR;
    //
    //     if (this.contentExtract.empty)
    //         return this.EMPTY;
    //
    //     return this.NOT_ERROR_FOUND;
    // }

    private buttonControl() {
        // button check

        if(this.PENDING === this.status.code)
            this.extractionBtn = false;
        else
            this.extractionBtn = true;
    }

    protected select(items: SelectItem[], selected: any) : SelectItem[] {
        return items.map((i: SelectItem) => {
            i.selected = i.value == selected;
            return i;
        })
    }

    private getText(){
        this.apolloReq.getContentComparisonExtractResult$(this.hash, this.type).subscribe(
            (data:any) => {
                //data == '' ? this.controlStatus = this.EMPTY : this.controlStatus = this.DONE;

                this.status.code = data == ''? this.EMPTY :  this.DONE;
                this.response = data;
                this.buttonControl();
            },
            (error:any) => {
                //this.controlStatus = this.ERROR;
                this.status.code = this.ERROR;
            }
        );
    }

    tryAgain(){
        //this.controlStatus = this.PENDING;
        this.status.code = this.DEFAULT_STATUS;
        this.getText();
    }

    newExtract(typeExt:string){
        this.status = this.DEFAULT_STATUS;
        this.response = ""; // clean text
        if(this.ALLOWED_EXTRACT_TYPES.indexOf(typeExt) === -1)
           typeExt = 'tetml';

        let wtf: any = {
           extraction_type: typeExt
        };
        this.extract.emit(wtf);
    }

    switchInfo() {
        this.infoOpened = !this.infoOpened
        this.infoClick.emit(this.infoOpened);
    }

    // errorExtraction() {
    //     if (this.contentExtract.empty) {
    //         this.contentExtract.extraction_type == 'tetml' ?
    //             this.textNewExtractionError = 'We could not extract the content directly, you might want to try OCR.' :
    //             this.textNewExtractionError = 'It looks like OCR could not extract any content. Please make sure the document has some text for us to extract.';
    //         this.controlStatus = this.EMPTY;
    //     }
    // }
}
