import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ProjectsEditorService, ProjectsService } from '@app/services/app/projects.service';
import { VersionsService, VersionsViewService } from '@app/services/app/versions.service';

import * as Objects from '@core/objects';
import { TitleService } from '@core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@app/services/app/app.service';
import { AccessProject } from '@app/models/access';

@Component({
    selector: 'version-add-main',
    templateUrl: './version-add.component.html',
    styleUrls: ['./version-add.component.scss']
})
export class ProjectDetailsVersionAddComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_ERROR = 2;
    public readonly STATUS_NOT_ALLOWED = 3;

    public readonly ACCESS_UPLOAD_VERSION = "access_request_upload_version";
    public readonly ACCESS_VERSION_NO_FILE = "access_version_upload_no_file"; // will be required file allways
    public readonly ACCESS_UPLOAD_VERSION_EDIT_TAGS = "access_request_upload_version_edit_labels";


    public project: Objects.Request = null;
    public labels: Array<Objects.Label> = null;
    public breadcrumbTitle: string = 'UPLOAD NEW ARTWORK'; //  TO NEW TAG GROUP


    public uploadVersionAccess: boolean = null;
    public editVersionTagsAccess: boolean = false;
    public context: string = '';
    public versionNumber: string = '1.0';
    public breadcrumbs: any = [
        {
            title: "Project <small>(# .  .  . )</small>",
            link: ''
        }
    ];

    public status:number = this.STATUS_LOADING;

    protected urlSubscriptor: Subscription;
    protected projectSubscriptor: Subscription;
    protected accessProjectSubscriptor: Subscription;


    constructor(
        public app: AppService,
        private title: TitleService,
        private activeRoute: ActivatedRoute,
        private projectsSrv: ProjectsService,
        private viewVersionsSrv: VersionsViewService,
        private versionsSrv: VersionsService,
        private projectEditorSrv: ProjectsEditorService,
        private router: Router
    ) { }

    ngOnInit() {
        // set temporal header title
        this.title.set("Version Add - Project...");

        this.projectSubscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.Request) => {
            // console.log(" PROJECT UPDATED ?????  ",project.labels)
            this.project = project;
            this.initView();
        });

        this.urlSubscriptor = this.activeRoute.queryParams.subscribe((params: any) => {
            // console.log(" params ", params);

            if (params.links != null){
                this.versionsSrv.getVersionsGroup$(params.links).subscribe((versions: Array<Objects.Version>) => {
                    console.debug(" versions in group loaded ",versions);
                    if(versions != null && versions.length >0 ){
                        this.labels = versions[0].labels;
                        this.versionNumber = versions[0].version;
                        this.initView();
                    } else{
                        console.error("the call for links is not getting veeersions!!!!",versions)
                        this.labels = [];
                        this.initView();
                    }
                });
            } else {
                this.labels = [];
                this.initView();
            }


        });
        // subscribe to upload version access
        this.accessProjectSubscriptor = this.projectEditorSrv.selectAccessAll().subscribe((access: AccessProject) => {
            if(access != null){
                console.log("access",access)
                this.uploadVersionAccess = access[this.ACCESS_UPLOAD_VERSION];
                if (!this.uploadVersionAccess)
                    this.status = this.STATUS_NOT_ALLOWED;
                this.editVersionTagsAccess = access[this.ACCESS_UPLOAD_VERSION_EDIT_TAGS];

                this.initView();
            }
        });

    }

    ngOnDestroy() {
        this.urlSubscriptor.unsubscribe();
        this.projectSubscriptor.unsubscribe();
        this.accessProjectSubscriptor.unsubscribe();
        this.viewVersionsSrv.clear();
    }

    private initView() {
        if( this.status == this.STATUS_NOT_ALLOWED || this.project == null || this.labels == null || this.uploadVersionAccess == null)
            return;
        this.setHeaderTitle();
        this.updateBreadcrumbs();
        this.status = this.STATUS_DONE;
    }

    private setHeaderTitle() {
        this.title.set("Version Add - Project #" + this.project.id);
    }

    protected updateBreadcrumbs(){
        if(this.project == null)
            return;

        this.breadcrumbs[0].link = '/projects/'+this.project.id;
        this.breadcrumbs[0].title = 'Project <small>(#'+this.project.id+ ')</small>';
    }

}
