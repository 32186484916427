import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRouterModule } from '@app/router.module';

import { SettingsWorkflowsMainComponent } from './main/main.component';
import { SettingsWorkflowsMainEditComponent } from './main-edit/main-edit.component';
import { SettingsWorkflowsEditNameFormComponent } from './edit-name-form/edit-name-form.component';
import { SettingsWorkflowsAddFormComponent } from './add-form/add-form.component';
import { SettingsWorkflowsWorkflowEditorComponent } from './editor/editor.component';
import { SettingsWorkflowsWorkflowComponent } from './workflow/workflow.component';



@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SettingsWorkflowsMainComponent,
        SettingsWorkflowsMainEditComponent,
        SettingsWorkflowsAddFormComponent,
        SettingsWorkflowsWorkflowEditorComponent,
        SettingsWorkflowsWorkflowComponent,
        SettingsWorkflowsEditNameFormComponent
    ],
    exports: [
        SettingsWorkflowsMainComponent,
        SettingsWorkflowsMainEditComponent,
        SettingsWorkflowsAddFormComponent,
        SettingsWorkflowsWorkflowEditorComponent,
        SettingsWorkflowsWorkflowComponent,
        SettingsWorkflowsEditNameFormComponent
    ],

})
export class SettingsWorkflowsModule { }
