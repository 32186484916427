import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, RouterModule } from "@angular/router";
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription, interval } from 'rxjs';

//import {Observable} from 'rxjs/Observable';
//import 'rxjs/add/observable/interval';
import { takeWhile } from 'rxjs/operators';

import { UrlService } from '@core/services/url/url.service';
import { TitleService } from '@core/services/title.service';

import { ApolloService } from '../../../../services/apollo/apollo.service';
import { ApolloRequest } from '../../../../model/apollo-request.model';
import { ApolloGraphicLayer } from '../../../../model/apollo-graphic-layer.model';
import { ApolloInspector } from '../../../../model/apollo-inspector.model';
import { ApolloGraphicComparison } from '../../../../model/apollo-graphic-comparison.model';


@Component({
    selector: 'plugin-apollo-main-section-compare-graphic',
    templateUrl: './compare-graphic.component.html',
    styleUrls: ['./compare-graphic.component.scss']
})
export class PluginApolloMainSectionCompareGraphicComponent implements OnInit, OnDestroy {


    @ViewChild('myBounds', { read: ElementRef }) public container: ElementRef<any>;
    //@ViewChild("myBounds") figure: any;

    readonly ZOOM_PINCH_DIFFERENCE = 0.75;
    readonly ZOOM_WHEEL_STEP = 5;
    readonly REFERENCE_LAYER_KEY = "reference";
    readonly TARGET_LAYER_KEY = "target";
    readonly COMPARED_LAYER_KEY = "compared";

    public show: boolean;
    public viewTool = "";
    protected oldViewTool = ""; // this variable is used for reopen tools when action is over
    public error: boolean = false;

    public urlLocation :string;

    //public apolloRequest: ApolloRequest;

    // Selects
    public options: any = {
         zoom: 50,
         resolution: 150,
         register: "affine"
    };

    public layers: ApolloGraphicLayer[] = [];

    public reference: ApolloGraphicLayer;
    public target: ApolloGraphicLayer;
    public compared: ApolloGraphicLayer;

    // inspector tool
    public inspector: ApolloInspector = new ApolloInspector;

    // Subscriptions
    public subscriptionGraphic: Subscription;
    public subscriptionRequest: Subscription;
    public hash: string;

    // graphic comparison
    protected graphic: ApolloGraphicComparison;
    public status: number = 0; // status in a variable is better
    public apolloReady: boolean = true; // check if request is valid
    public reported: boolean = false; // check if is compared for download report
    public reportUrl: string = "";


    public countScrollNumber: number = 0;


    public dragPosition = {
        x: 0,
        y: 0,
        scroll: {
            left: 0,
            top: 0
        }
    }

    public savedScale:number = 0;

    public sizeFigure:any = {};
    public sizeContainer:any = {};

    public showModal:boolean = true;
    public buttonAlignText:string = "Align";

    public flicker: any = {
        flickering: false,
        run: null
    };

    constructor(public apolloSrv: ApolloService, public urlSrv: UrlService, private router: Router, private title: TitleService){}

    /**
     * toogle tool
     **/
    openTool(toolName: string) {
        if(toolName == this.viewTool)
            this.viewTool = "";
        else
            this.viewTool = toolName;
    };

    /*hideTools() {
        this.oldViewTool = this.viewTool;
        this.viewTool = "";
    }

    showTools() {
        this.viewTool = this.viewTool;
    }*/


    ngOnInit() {
        this.title.set("Graphic comparison - XRay");
        this.subscriptionRequest = this.apolloSrv.selectRequest().subscribe((request: ApolloRequest) => {
            if(!request.reference || !request.target) {
                this.apolloReady = false;
                return false;
            }

            this.apolloReady = true;
            this.hash = request.hash; // for download report
            this.loadReportUrl();

        });

        this.subscriptionGraphic = this.apolloSrv.selectorGraphicCompare().subscribe((apolloGraphic : ApolloGraphicComparison) => {
            this.apolloSrv.loadGraphicComparison(apolloGraphic);

            if (apolloGraphic != null) {
                if (apolloGraphic.status_code == 1 || apolloGraphic.status_code == 2) {
                    this.setImagesCompare(apolloGraphic);
                }
                if(apolloGraphic.register_layer != "") {
                    this.showModal = false;
                    this.buttonAlignText = "Undo";
                }
            }

            this.setGraphic(apolloGraphic);
        })
    }

    ngOnDestroy(){
        this.subscriptionGraphic.unsubscribe();
        this.subscriptionRequest.unsubscribe();
    }

    returnToUpload() {
        this.router.navigate(["/p/apollo/upload"]);
    }

    scrollToTop(location: string){
        switch (location) {
            case "tools":
                document.documentElement.scrollTop = 0;
                break;

            // case "inspector":
            //     let scrollInspector = this.inspectorElRef.nativeElement.clientHeight;
            //     document.documentElement.scrollTop = scrollInspector;
            //     break;

            // case "layers":
            //     let scrollLayers = this.layersElRef.nativeElement.clientHeight;
            //     document.documentElement.scrollTop = scrollLayers;
            //     break;
        }
    }

    protected setGraphic(graphic: ApolloGraphicComparison){
        this.graphic = graphic;

        if(graphic) {
            this.options = {
                ...this.options,
                resolution: graphic.resolution,
                register: graphic.register_type && graphic.register_type!==""? graphic.register_type : this.options.register
            }

            this.setStatus(graphic.status_code);
        }
    }

    protected setStatus(status: number){
        this.status = status == 2? 1 : status;
        this.reported = status == 2;

        // switch(this.status) {
        //     case 1:
        //         //this.showTools();
        //         break;
        // }
    }

    protected setImagesCompare(apolloGraphic : ApolloGraphicComparison){
        if(apolloGraphic.id_reference && apolloGraphic.id_target) {
            let reference = new ApolloGraphicLayer("reference");
            reference.id_file = apolloGraphic.id_reference;

            let target = new ApolloGraphicLayer("target");
            target.id_file = apolloGraphic.id_target;

            this.addLayer(reference);
            this.addLayer(target);
            //this.imageReference = this.apolloSrv.setImageCompareGraphic(apolloGraphic.id_reference);
            //this.imageTarget = this.apolloSrv.setImageCompareGraphic(apolloGraphic.id_target);
        }

        if (apolloGraphic.id_compared) {
            let compared = new ApolloGraphicLayer("compared");
            compared.id_file = apolloGraphic.id_compared;
            compared.opacity = 60;
            this.addLayer(compared);
        }

        if (apolloGraphic.id_xray) {
            let xray = new ApolloGraphicLayer("xray");
            xray.id_file = apolloGraphic.id_xray;
            this.addLayer(xray);
        }
    }

    /**
     * Add new layer
     **/
    protected addLayer(layer: ApolloGraphicLayer) {
        // remove layer with same key
        let layers = this.layers = this.layers.filter(l => { return l.key != layer.key; });
        layers.push(layer);

        this.loadImagesUrls(layers);

        this.layers = layers;

        this.setLayers();
    }

    /**
     * Set individual layer
     **/
    protected setLayers() {
        let layers = [];

        layers = this.layers.filter(l => { return l.key == this.REFERENCE_LAYER_KEY; });
        this.reference = layers.length > 0? layers[0]: null;

        layers = this.layers.filter(l => { return l.key == this.TARGET_LAYER_KEY; });
        this.target = layers.length > 0? layers[0]: null;

        layers = this.layers.filter(l => { return l.key == this.COMPARED_LAYER_KEY; });
        this.compared = layers.length > 0? layers[0]: null;
    }

    // 14 - 06 -2022 activate back the download report action.
    protected loadReportUrl() {
        this.reportUrl = this.urlSrv.build("/p/apollo/compare/" + this.hash + "/graphic/report");
    }

    protected loadImagesUrls(layers: ApolloGraphicLayer[]) {

        this.layers = this.layers.map(l => {
            l.url = this.urlSrv.build("/files/" + l.id_file + "?f=inline");
            return l;
        });
    }

    changeTool(values: any) {
        let options = {...this.options};

        console.debug("optioooonnnns---->", options, values);
        if(values.resolution != this.graphic.resolution) {
            //this.hideTools();
            this.setStatus(0); // fix delay
            this.apolloSrv.setResolutionGraphicComparison(values.resolution);
        }

        if(values.zoom != this.options.zoom) {
            this.inspector.setZoom(values.zoom);
        }

        if(this.graphic.register_type!= "" && values.register != this.graphic.register_type) {
            this.setStatus(0);
            this.apolloSrv.setRegisterLayer(values.register, this.graphic.register_layer);
        }

        this.options = {...values};

    }


    newRegister(){
        this.setStatus(0); //Set status pending
        if(this.graphic.register_layer == "target"){
            this.apolloSrv.setRegisterLayer("", "");
            this.buttonAlignText = "Align";
            return;
        }

        this.apolloSrv.setRegisterLayer(this.options.register, 'target');

        // if(data.type != data.layer){
        //     this.apolloSrv.setRegisterLayer(this.options.register, data.layer);
        // } else {
        //     // UNREGISTER
        //     this.apolloSrv.setRegisterLayer("", "");
        //     this.apolloSrv.getGraphicComparison();
        // }
    }

    setInspectorReferenceSizes(event) {
        console.debug("resize zooom --->", event);
        if(this.inspector
            && event.height != 0
            && Math.abs(event.width-this.inspector.originalSize.width) >= 1
        ) {
            this.inspector.setOriginalSize(event.width, event.height);
            this.inspector.setZoom(this.options.zoom);
        }
    }

    setInspectorPosition(event) {
        if(this.viewTool!='inspector')
            return false;

        this.inspector.setMarkerPosition(
            event.layerX - this.inspector.marker.width / 2,
            event.layerY - this.inspector.marker.height / 2
        );
    }

    onInspectorMarkerMoving(event) {
        this.inspector.setMarkerPosition(event.x, event.y);
    }

    onInspectorMarkerMove(value: boolean) {
        this.inspector.moving = value;
    }


    newCompare(){
        this.setStatus(0); // fix delay
        this.apolloSrv.getGraphicCompare();
    }

    actionModal(type){
        if (type === 'align'){
            this.newRegister();
            return;
        }

        this.showModal = false;
    }


    // setCenter(x, y) {
    //     let sizeX = this.inspector.originalSize.width*(this.options.zoom/100);
    //     let sizeY = this.inspector.originalSize.height*(this.options.zoom/100);
    //
    //     let moveX = sizeX - x
    //
    // }

    onZoom(event){
        switch(event.type) {
            case "wheel":
                this.setZoom(this.scrollZoomEvent(event));
                break;
            case "pinch":
                this.setZoom(this.pinchZoomEvent(event));
                break;
        }
    }


    protected zoomOffset(x:number, y:number, zoom: number, change: number) {
        let cursor = {
            x: x,
            y: y
        };

        let mouse = {
            x: cursor.x * (zoom + change)/zoom,
            y: cursor.y * (zoom + change)/zoom
        };

        return {
            x: (mouse.x - cursor.x),
            y: (mouse.y - cursor.y)
        };
    }

    protected scrollZoomEvent(event: any): any {
        console.log("scroll-->", event);
        this.countScrollNumber++;
        let zoom: number = this.options.zoom;
        let offset: any = {x: 0, y: 0};

        if (this.countScrollNumber % this.ZOOM_WHEEL_STEP ) { // each 5

            let zoomCount = 0;

            // down
            if (event.deltaY > 0) {
                zoomCount = - 1;
                //zoom--;
            }
            // up
            if (event.deltaY < 0) {
                zoomCount = 1;
            }

            offset = this.zoomOffset(event.layerX, event.layerY, zoom, zoomCount);

            this.countScrollNumber = 0;

            zoom = zoom + zoomCount;
        }

        return {zoom: zoom, offset: offset};
    }

    protected pinchZoomEvent(event: any): any {
        console.log("pinch--->", event);

        var count = 0;
        let zoom: number = this.options.zoom;
        let offset: any = {x: 0, y: 0};

        // zoom in
        if (event.scale > this.savedScale) {
            let sum = this.savedScale;
            while(sum < event.scale) {
                sum = sum + this.ZOOM_PINCH_DIFFERENCE;
                count++;
            }

            //zoom = zoom + count;
        }

        // zoom out
        if (event.scale < this.savedScale) {
            let sum = event.scale;
            while(sum < this.savedScale) {
                sum = sum + this.ZOOM_PINCH_DIFFERENCE;
                count++;
            }

            count = -count;


            //zoom = zoom - count;
        }

        zoom = zoom + count;
        offset = this.zoomOffset(event.srcEvent.offsetX, event.srcEvent.offsetY, zoom, count);

        this.savedScale = event.scale;

        return {zoom: zoom, offset: offset};
    }


    /*protected pinchZoomEvent(event: any): any {
        var count = 0;
        let zoom: number = this.options.zoom;
        let offset: any = {x: 0, y: 0};

        // zoom in
        if (event.scale > this.savedScale) {
            let sum = this.savedScale;
            while(sum < event.scale) {
                sum = sum + this.ZOOM_PINCH_DIFFERENCE;
                count++;
            }

            zoom = zoom + count;
        }

        // zoom out
        if (event.scale < this.savedScale) {
            let sum = event.scale;
            while(sum < this.savedScale) {
                sum = sum + this.ZOOM_PINCH_DIFFERENCE;
                count++;
            }


            zoom = zoom - count;
        }

        this.savedScale = event.scale;

        return {zoom: zoom, offset: {}};
    }*/

    protected setZoom(zoom: any) {
        if(zoom.zoom > 100)
            return false;
            //zoom.zoom = 100;

        if(zoom.zoom <1)
            return false;
            //zoom.zoom = 1;

        this.options = {...this.options, zoom: zoom.zoom};
        this.inspector.setZoom(zoom.zoom);

        // CENTER
        let scroll = this.getScroll();
        let left = scroll.left + zoom.offset.x;
        let top = scroll.top + zoom.offset.y;
        this.scrollTo(left, top);
    }

    increaseZoom(inc: number) {
        if(inc > 0 && this.options.zoom > 95)
            return false;
        if(inc < 0 && this.options.zoom < 5)
            return false;

        let current = this.options.zoom + inc;

        this.setZoom({zoom: current, offset: {x: 0, y: 0}});
    }

    dragEvent(event) {
        if (this.inspector.moving)
            return false;

        switch(event.type){
            case "mousedown": // desktop
                this.dragPosition = {
                    x: event.clientX,
                    y: event.clientY,
                    scroll: this.getScroll()
                };
                //console.log("mousedown --->", this.dragPosition);
                break;

            case "mousemove": // desktop
                if(event.buttons!=1)
                    return false;

                var left = this.dragPosition.scroll.left + -(event.clientX - this.dragPosition.x);
                var top = this.dragPosition.scroll.top + -(event.clientY - this.dragPosition.y);
                //console.log("mousemove --->", this.dragPosition);
                this.scrollTo(left, top);
                break;

            case "panstart": // mobile
                if(event.pointerType == "mouse")
                    return false;

                this.dragPosition = {
                    x: 0,
                    y: 0,
                    scroll: this.getScroll()
                };
                //console.log("panstart --->", this.dragPosition);
                break;

            case "panmove": // mobile
                var left = this.dragPosition.scroll.left + -( event.deltaX);
                var top = this.dragPosition.scroll.top + -(event.deltaY);
                //console.log("panmove --->", this.dragPosition);
                this.scrollTo(left, top);
                break;
        }

    }

    protected scrollTo(left: number, top: number) {
        console.debug("scrolling to--->", left, top);
        //left = left > 0? left : 0;
        //top = top > 0? top : 0;
        this.container.nativeElement.scrollLeft = left;
        this.container.nativeElement.scrollTop = top;
    }

    protected getScroll(): any {
        return {
            left: this.container.nativeElement.scrollLeft,
            top: this.container.nativeElement.scrollTop
        };
    }

    // flick(seconds: number) {
    //     this.flicker.flickering = true;
    //     this.target.visible = false;
    //     setTimeout(() => {
    //         this.flicker.flickering = false;
    //         this.target.visible = true;
    //     }, seconds*1000);
    //
    // }

    startFlick(seconds: number) {
        this.flicker.flickering = true;
        this.target.visible = false;
        // setTimeout(() => {
        //     this.flicker.flickering = false;
        //     this.target.visible = true;
        // }, seconds*1000);

        this.flicker.run = interval(seconds*1000).pipe(
            takeWhile(() => this.flicker.flickering))
            .subscribe(i => {
                this.target.visible = !this.target.visible;
            })

        // while(this.flicker.flickering) {
        //     setTimeout(() => {
        //     //this.flicker.flickering = false;
        //         this.target.visible = this.target.visible;
        //     }, seconds*1000);
        // }

    }

    stopFlick(seconds: number) {
        this.flicker.flickering = false;
        this.target.visible = true;
        this.flicker.run.unsubscribe();
    }

}
