import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LabelsService } from '@app/services/app/labels.service';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';

@Component({
    selector: 'app-helpers-labels-edit-diff-box',
    templateUrl: './edit-diff-box.component.html',
    styleUrls: ['./edit-diff-box.component.scss']
})
export class HelpersLabelsEditDiffBoxComponent implements OnInit, OnChanges, OnDestroy {

    // texts 
    @Input() searchPlaceholder: string = "Search for tags here";
    @Input() headerText: string = "";
    @Input() searchFoundText: string = "";
    // control variables 
    @Input() minLabels: number = 0;
    @Input() edit: boolean = true; // in case edition is not allowed form parent - labels are fixed
    @Input() addControl: boolean = true; // allow add labels or not for future 
    @Input() allowClose: boolean = false; // allow close editor in the x
    // data inputs
    @Input() project: Objects.Request;
    @Input() labels: Array<Objects.Label> = []; // additional labels or labels to edit
    // action emitters
    @Output() labelsChange = new EventEmitter<Array<Objects.Label>>();
    @Output() close = new EventEmitter();

    public input: string = ''
    // to different labels
    public diffLabels: Array<Objects.Label> = [];
    public matchLabels: Array<Objects.Label> = [];
    // permissions to add tags (in combination with given addControl )
    public access: Access.AccessLabels = {
        access_label_add: false
    }
    public labelsToExclude: Array<Objects.Label> = [];
    protected subscriptor: Subscription;

	constructor(protected labelsSrv: LabelsService){}

	ngOnInit(){  
        // load access for add label
        this.labelsSrv.loadAccess();
        this.subscriptor = this.labelsSrv.selectAccess().subscribe((access: Access.AccessLabels) => {
            this.access = access;
            console.debug( " access labels loadeed ",access)
        });
        this.updateCheckLabels();
	}

    ngOnChanges(changes: SimpleChanges) {
        if(changes.labels){ // TODO
            this.updateCheckLabels();
        }
    }

    ngOnDestroy(): void {
        this.subscriptor.unsubscribe();
        this.input = ''; // clean typed search
    }

    removeLabel(label: Objects.Label) {
        this.labels = this.labels.filter((l: Objects.Label) => label.id != l.id);
        this.updateCheckLabels();
        this.emit();
    }

    addLabel(label: Objects.Label) {
        let labels = this.labels.filter((l: Objects.Label) => label.id == l.id);
        if(labels.length>0)
            return;

        this.labels.push(label);
        this.updateCheckLabels();
        this.emit();
    }

    emit() {
        this.labelsChange.emit(this.labels);
    }

    private updateCheckLabels() {
        if(this.project == null){
            this.diffLabels = this.labels;
            this.labelsToExclude = [...this.diffLabels]; // update exclude in search
            return;
        }
        
        this.diffLabels = this.labels.filter((label: Objects.Label) => !this.project.labels.some((l) => l.id == label.id));
        this.matchLabels = this.labels.filter((label: Objects.Label) => this.project.labels.some((l) => l.id == label.id));
        this.labelsToExclude = [...this.matchLabels,...this.diffLabels];
    }

}
