import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, RouterModule } from "@angular/router";

// import { LabelsGroup } from '@core/objects/labels-group';

// import { RulesService } from '@app/services/settings/rules.service';
import { SettingsRulesService } from '@app/services/settings/srules.service';

import { AvailableRule } from '@app/store/state/settings/rules/rules.state';

import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';


@Component({
    selector: 'settings-rules-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SettingsRulesMainComponent implements OnInit, OnDestroy {
    private readonly ACCESS = 'access_setting_rules_access';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

    public availableEvents: Array<AvailableRule>;
    public selectedRule: any = null;
	public access:boolean = true;
    // public status: number = 0;
    public status: number = 0; //TODO change to 0
    public breadcrumbs: any = [
        {
            title: "System Settings",
            link: ['/settings']
        }
    ];
    // TODO review menu icons
    public menu: Array<ThreeDotsMenuItem> = [{
        title: "Add a new rule",
        key: "add-rule",
        icon: "add",
        order: 1
    }];

    protected availableEventsSubscriptor: Subscription;

	constructor(public srulesSrv: SettingsRulesService, public app: AppService, private title: TitleService, private router: Router){}

	ngOnInit(){
        this.title.set("Rules - System settings");

        this.availableEventsSubscriptor = this.srulesSrv.selectAvailableEvents().subscribe((availables: AvailableRule[] ) => {
            this.availableEvents = availables;
            this.status = this.availableEvents.length > 0? this.STATUS_DONE : this.STATUS_LOADING;
        });

        this.app.getSession().subscribe( (session: any) => {
            this.access = session.options.access.hasOwnProperty(this.ACCESS)? session.options.access[this.ACCESS] : false;
        });

        if (this.access){
            this.loadEvents();
        }


	}

    ngOnDestroy() {
        this.availableEventsSubscriptor.unsubscribe();
    }

    // onSaved(event){
    //     this.showForm = false;
    //     this.load();
    // }
    cancel(event){
        this.loadEvents();
    }

    // save(event: RuleEvent) {
    //     this.rulesSrv.addRuleEvent$(event).subscribe((event: RuleEvent) => {
    //         this.selectedRule = event;
    //         this.loadEvents();
    //     });
    // }

    private loadEvents() {
        this.srulesSrv.loadAvailableEvents();

		// this.rulesSrv.findSettingsEvents$().subscribe((events: Array<AvailableRuleItem>) => {
        //     console.debug("set available events",events)
        //     this.availableEvents = events.map((e: any) => { return { title: e.description, value: e.key}; });
		// 	this.status = this.STATUS_DONE;
		// });
	}


    // selectRule(e){
    //     console.debug("set selected ruleevent id",e)
    //     this.selectedRule = e;
    // }

    // selectRuleById(id: Number) {
    //     if(this.selectedRule != null && this.selectedRule.id == id)
    //     {
    //         this.selectedRule = null;
    //         return;
    //     }

    //     this.rulesSrv.getRuleEvent$(id).subscribe((event: RuleEvent) => {
    //         this.loadEvents();
    //         this.selectedRule = event;
    //     });
    // }

    openRule(id: Number){
        this.router.navigate(['/settings/rules/',id]);
    }

    openAddForm() {
        this.selectedRule = null;
        this.router.navigate(['/settings/rules/add']);
        // this.loadEvents();
    }

    onMenuClick(key: string) {
        switch(key) {
            case "add-rule":
                this.openAddForm()
                break;
        }
    }

}
