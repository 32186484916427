import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsersService } from '@app/services/settings/users.service';
import { UserRequest } from '@core/objects/user-request';
import { HttpStatus } from '@core/classes/http-status.class';


@Component({
    selector: 'settings-users-invitations',
    templateUrl: './invitations.component.html',
    styleUrls: ['./invitations.component.scss']
})
export class SettingsUsersInvitationsComponent implements OnInit{

	@Output() reload = new EventEmitter<string>();
    @Input() selected: Array<string> = [];

	public invitations: UserRequest[] = null;

	public subscriptionInvitation:Subscription;
	public subscriptionStatusInv: Subscription;
	public subscriptionStatusCancel: Subscription;
	public httpStatus:HttpStatus;

	public dataDisabled:string = null;
	public cancelEmail:string = null;

	constructor(public userSrv: UsersService){}

	ngOnInit(){
		this.subscriptionStatusCancel = this.userSrv.selectorStatusDelInvite().subscribe((status: HttpStatus) => {
			if ( status.code == 0 && this.cancelEmail != null) {
				this.dataDisabled = this.cancelEmail;
			} else {
				this.dataDisabled = null;
				this.cancelEmail = null;
			}
		});

		this.subscriptionStatusInv = this.userSrv.selectorStatusInvite().subscribe((status: HttpStatus) => {
			this.httpStatus = status;
		});

		this.subscriptionInvitation = this.userSrv.selectorGetAllInvitations().subscribe((inv: UserRequest[]) => {
			if( inv != null ){
				this.invitations = inv.filter(function(i) { return i.status_code === 0; });
			}
        });
	}

	ngOnDestroy(){
		this.subscriptionStatusInv.unsubscribe();
		this.subscriptionInvitation.unsubscribe();
		this.subscriptionStatusCancel.unsubscribe();
	}

	cancelInvitation(email){
		this.cancelEmail = email;
		this.userSrv.deleteInvitation(email);
	}

	tryAgain(){
		this.reload.emit('invitations');
	}

    isActive(request: UserRequest): boolean {
        return !(this.selected.length > 0 && this.selected.indexOf(request.email) == -1);
    }
}
