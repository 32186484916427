import { ApolloRequest } from '../../../model/apollo-request.model';
import { ApolloActions, ApolloActionTypes } from './apollo.actions';
import { Apollo, initialState } from './apollo.state';
import { HttpStatus } from '@core/classes/http-status.class';

export function apolloReducer(state = initialState, action: ApolloActions): Apollo {
    switch (action.type) {

        case ApolloActionTypes.ActiveTabsItem:
            var next = { ...state };

            next.tabs = next.tabs.map( i => {
                i.disabled = i.title == action.title ? false : i.disabled;

                return i;
            })

            return next;
        case ApolloActionTypes.DeactiveTabsItem:
            var next = { ...state };

            next.tabs = next.tabs.map( i => {
                i.disabled = i.title == action.title ? true : i.disabled;

                return i;
            })

            return next;

        case ApolloActionTypes.RemoveItemRequest:
            return {
                ...state,
                request: {
                    ...state.request,
                    [action.propertyName] : null,
                    apollo_graphic: null,
                    apollo_content: null,
                    hash: null
                },
                [action.propertyName]: null
            }

        case ApolloActionTypes.ResetRequest:
            return {
                ...state,
                request: new ApolloRequest,
                reference: null,
                target: null
            }
        case ApolloActionTypes.LoadSavedRequestSuccess:
            return {
                ...state,
                request: action.request
            }
        case ApolloActionTypes.SetItemReferenceRequest:

            return {
                ...state,
                request : {
                    ...state.request,
                    reference : action.reference
                }
            }

        case ApolloActionTypes.SetItemTargetRequest:

            return {
                ...state,
                request : {
                    ...state.request,
                    target : action.target
                }
            }

        case ApolloActionTypes.AddItemFilterRequest:
            var item = {...state.request[action.propertyName]};

            item.filters = item.filters.filter( f => {
                return !(f.type == action.filter.type && f.page == action.filter.page);
            });

            item.filters.push(action.filter);

            return {...state,
                request: {
                    ...state.request,
                    [action.propertyName] : item,
                    apollo_graphic: null,
                    apollo_content: null
                }
            };

            /*
            // ---
            return {
                ..state,
                request: {
                    ...state.request,
                    [action.propertyName] : {
                        ...state.request
                    }
                }
            }

            // ---
            var next = { ...state };

            // delete if exist the filter
            next.request[action.propertyName].filters = next.request[action.propertyName].filters.filter( f => {
                return f.page != action.filter.page && f.type != action.filter.type;
            });

            next.request[action.propertyName].filters.push(action.filter);
            next.request.apollo_graphic = null;

            return next;
            */

        case ApolloActionTypes.SelectItemPageRequest:
            if (state.request[action.propertyName].page_selected != action.page) {
                return {
                    ...state,
                    request: {
                        ...state.request,
                        [action.propertyName] : {
                            ...state.request[action.propertyName],
                            page_selected: action.page
                        },
                        apollo_content: null,
                        apollo_graphic: null
                    }
                }
            } else {
                return {
                    ...state,
                    request: {
                        ...state.request,
                        [action.propertyName] : {
                            ...state.request[action.propertyName],
                            page_selected: action.page
                        },
                    }
                }
            }

        case ApolloActionTypes.AddRequestSuccess:
            return {...state, request : action.request}

        case ApolloActionTypes.SetItemRequest:
            return {
                ...state,
                request : {
                    ...state.request,
                    [action.propertyName] : action.value
                }
            }

        case ApolloActionTypes.SetFileReference:
            return {...state, reference: action.reference}

        case ApolloActionTypes.SetFileTarget:
            return {...state, target: action.target}

        case ApolloActionTypes.SetGraphicComparison:

            return {
                ...state,
                request: {
                    ...state.request,
                    apollo_graphic : action.apolloGraphic
                }
            }

        case ApolloActionTypes.LoadFileSuccess:
            let itemObject = state.request[action.propertyName] != null &&
                        state.request[action.propertyName].id_file == action.item.id_file?
                        state.request[action.propertyName] : action.item;

            return {
                ...state,
                request: {
                    ...state.request,
                    //[action.propertyName]: action.item
                    [action.propertyName]: itemObject
                },
                [action.propertyName]: action.file
            }

        case ApolloActionTypes.SetContentComparison:

            return {
                ...state,
                request: {
                    ...state.request,
                    apollo_content : action.apolloContent
                }
            }

        case ApolloActionTypes.RemoveContentCompare:
            console.log('RemoveContentCompare reducer');
            return state;
        case ApolloActionTypes.UpdateHistory:
            if(action.page == 1)
                return {
                    ...state,
                    history: action.history
                }

            return {
                ...state,
                history: {
                    ...state.history,
                    last: action.history.last,
                    items: [
                        ...state.history.items,
                        ...action.history.items
                    ]
                }
            }
        case ApolloActionTypes.UpdateNameToCompareHistory:
            //let index = state.history.items.findIndex(x => x.hash == action.compare.hash);

            return {
                ...state,
                history: {
                    ...state.history,
                    items: state.history.items.map(x => x.hash == action.compare.hash ? action.compare : x)
                }
            }
        case ApolloActionTypes.OnUpdateContentComparisonExtract:
            //let index = state.history.items.findIndex(x => x.hash == action.compare.hash);

            return {
                ...state,
                request: {
                    ...state.request,
                    apollo_content: {
                        ...state.request.apollo_content,
                        [action.typeFile + "_extract"]: action.comparisonExtract
                    }
                }
            }
        default:
            return state;
    }
}
