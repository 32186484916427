import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
//import { CoreModule } from '@core/core.module';
import { DpDatePickerModule } from 'ng2-date-picker';
// external dependencies
import { NgxWigModule } from 'ngx-wig';

// components
import { ButtonComponent } from './button/button.component';
import { ButtonWhiteComponent } from './button-white/button-white.component';
import { ButtonIconComponent } from './button-icon/button-icon.component';
import { SelectComponent } from './select/select.component';
import { SelectColorComponent } from './select-color/select-color.component';
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { HelperFormWysiwygSimpleComponent } from './wysiwyg-simple/wysiwyg-simple.component';
import { HelperFormPasswordValidationComponent } from './password-validation/password-validation.component';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { InputRadioComponent } from './input-radio/input-radio.component';
import { ZoomButtonsComponent } from './zoom-buttons/zoom-buttons.component';
import { DatepickerComponent } from './datepicker/datepicker.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxWigModule,
        DpDatePickerModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        HelperFormWysiwygSimpleComponent,
        ButtonComponent,
        ButtonWhiteComponent,
        ButtonIconComponent,
        SelectComponent,
        SelectColorComponent,
        SelectMultipleComponent,
        InputNumberComponent,
        HelperFormPasswordValidationComponent,
        InputCheckboxComponent,
        InputRadioComponent,
        ZoomButtonsComponent,
        DatepickerComponent
    ],
    exports: [
        HelperFormPasswordValidationComponent,
        HelperFormWysiwygSimpleComponent,
        ButtonComponent,
        ButtonWhiteComponent,
        ButtonIconComponent,
        SelectComponent,
        SelectColorComponent,
        SelectMultipleComponent,
        InputNumberComponent,
        InputCheckboxComponent,
        InputRadioComponent,
        ZoomButtonsComponent,
        DatepickerComponent
    ],

})
export class HelperFormModule { }
