import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

//import { UrlService } from '@core/services/url/url.service';
import { User } from '@core/objects/user';
import { AvatarsService } from '@core/services/avatars/avatars.service';

@Component({
    selector: 'core-avatar-img',
    templateUrl: './avatar-img.component.html',
    styleUrls: ['./avatar-img.component.scss'],
})
export class AvatarImgComponent implements OnInit, OnDestroy, OnChanges {

    @Input() user: User = null;
    @Input() size: number;

    @Input() email:string = null;

    public image: string = 'assets/fake_avatar.png';

    protected subscription: Subscription = null;


    constructor(protected avatarsSrv: AvatarsService, public http: HttpClient) { }

    ngOnInit() {
        if (this.user != null){
            // return this.newAvatarUser();
            this.subscribeAvatar();

        }

        this.newAvatarEmail();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.user && changes.user.currentValue != null) {
            this.ngOnDestroy();
            this.subscribeAvatar();
        }
    }

    ngOnDestroy() {
        if(this.subscription !== null)
            this.subscription.unsubscribe();
    }
    
    newAvatarEmail(){
        if(this.email == null)
            return;
            
        const uri = "/web/avatar/" + this.email + "/" + this.size + "/base64";
        this.http.get(uri, { responseType: 'text' }).subscribe(
            (data: any) => {
                this.image = data;
            }
        );
    }

    // newAvatarUser(){
    //     const uri = "/web/avatar/" + this.user.id + "/" + this.size + "/base64";
    //     this.http.get(uri, { responseType: 'text' }).subscribe(
    //         (data: any) => {
    //             this.image = data;
    //         }
    //     );
    // }

    subscribeAvatar() {
        if(this.user == null)
            return;

        this.subscription = this.avatarsSrv.getSubscriptor(this.user, this.size).subscribe((avatar: string) => {
            this.image = avatar;
        });

        this.avatarsSrv.load(this.user, this.size);
    }
}
