import { Component, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'plugin-apollo-helpers-modal-ocr-info',
    templateUrl: './modal-ocr-info.component.html',
    styleUrls: ['./modal-ocr-info.component.scss']
})

export class PluginApolloHelpersModalOcrInfoComponent{

	@Output() action = new EventEmitter<any>();

	constructor(){}

	actionButton(type){
		this.action.emit(type);
	}
}
