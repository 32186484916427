import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter} from '@angular/core';

import { PreviewRequest, PreviewPageProperty } from '@core/objects/preview-request';

interface Page {
    page: number;
    fileId: string | number;
    property: PreviewPageProperty;
}

@Component({
    selector: 'core-previews-carousel',
    templateUrl: './previews-carousel.component.html',
    styleUrls: ['./previews-carousel.component.scss'],
    providers: []
})
export class PreviewsCarouselComponent implements OnInit,  OnChanges {

    @Input() preview: PreviewRequest;
    @Input() rotateAction: boolean = false; // set rotate action feature
    @Input() activateAction: boolean = false; // set activate/deactivate action feature

    //@Input() activateDefault: boolean = true; // set default active pages
    @Input() deactivatePages: Array<number> = []; // pages deactivates
    @Input() hiddenPages: Array<number> = []; // hidden pages on carrusel

    @Output() onClickPage = new EventEmitter<any>();
    @Output() onActivateClick = new EventEmitter<any>();

    @Input() selectedPage: number;
    //@Input() ignore: Array<number>;

    public pages: Array<Page>;

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.preview) {
            this.preview = changes.preview.currentValue;
            this.init();
        }
    }

    init() {
        this.pages = [];
        let index = 1;
        while(index <= this.preview.total_pages){
            this.pages.push({
                page: index,
                fileId: this.preview.id_file,
                property: this.getPageProperty(index)
            });
            index++;
        }
        while(this.selectedPage === null || this.selectedPage === undefined){
            this.selectedPage = 1;
        }
    }

    click(page) {
        this.selectedPage = page.page;
        this.onClickPage.emit(page);
        console.debug(page);
    }


    activePage(page: number, active: boolean){
        this.onActivateClick.emit({
            page: page,
            active: active
        });
    }

    getPageProperty(page: number) : PreviewPageProperty {
        return this.preview.page_properties[page-1];
    }
}
