import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'core-version-approval-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class VersionApprovalFormComponent implements OnInit {

    @Input() version: any;
    @Input() files: Array<any>;

    @Output() onFileClick = new EventEmitter<any>();
    @Output() onFileShow = new EventEmitter<any>();
    @Output() onAction = new EventEmitter<any>();

    // previews
    public showFilesList:boolean = false;

    // TextArea
    public valueText: FormControl;
    public disabled:boolean = true;


    ngOnInit() {
        this.valueText = new FormControl('');

        this.valueText.valueChanges.subscribe(value => {
            this.disabled = true;
            if(value.trim() != '')
                this.disabled = false;
        });
    }

    fileClick(file){
        console.debug('file ->', file);
        this.onFileClick.emit(file);
    }

    toggleShow() {
        this.showFilesList = !this.showFilesList;
        this.onFileShow.emit(this.showFilesList);
    }

    action(approved: boolean){
        let action = {
            version: this.version,
            approved: approved,
            message: this.valueText.value
        };

        this.onAction.emit(action);
    }

}
