import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';

import { HomeComponent } from './home/home.component';
import { AppRouterModule } from '@app/router.module';
import { SettingsModule } from './settings/settings.module';
import { ProfileModule } from './profile/profile.module';
import { LegacyNotesModule } from './legacy-notes/legacy-notes.module';
import { ProjectsModule } from './projects/projects.module';
import { AppVersionsModule } from './versions/versions.module';
import { LibraryModule } from './library/library.module';
import { DashboardModule } from './dashboard/dashboard.module';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
		AppRouterModule,
		SettingsModule,
        LegacyNotesModule,
        ProfileModule,
        ProjectsModule,
        AppVersionsModule,
        LibraryModule,
        DashboardModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        HomeComponent
    ],
    exports: [
		SettingsModule,
        LegacyNotesModule,
        ProfileModule,
        ProjectsModule,
        AppVersionsModule,
        LibraryModule,
        DashboardModule
	],

})
export class SectionsModule { }
