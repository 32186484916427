import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectDetailsVersionDetailsComponent } from './version/version.component';
import { ProjectDetailsVersionDetailsFilePreviewComponent } from './version-file-preview/version-file-preview.component';
import { ProjectsDetailsHelpersModule } from '../helpers/helpers.module';
import { VersionsDetailsContentModule } from '@app/components/sections/versions/details/content/content.module';
import { VersionsModule } from '@core/components/versions/versions.module';
import { ProjectDetailsVersionAddComponent } from './version-add/version-add.component';
import { VersionAddModule } from '@app/components/sections/versions/add/version-add.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

@NgModule({
    imports: [
        CommonModule,
        HelpersModule,
        ProjectsDetailsHelpersModule,
        VersionsDetailsContentModule,
        VersionsModule,
        VersionAddModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsVersionDetailsComponent,
        ProjectDetailsVersionAddComponent,
        ProjectDetailsVersionDetailsFilePreviewComponent
    ],
    exports: [
    ],

})
export class ProjectsDetailsSectionsModule{ }
