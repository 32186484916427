import { UrlService } from './url/url.service';
import { WebsocketService } from './websocket/websocket.service';
import { PreviewsService } from './previews/previews.service';
import { WindowService } from './window.service';
import { TitleService } from './title.service';
import { BrowserService } from './browser.service';


//import { EventDispatcherService } from './event-dispatcher/event-dispatcher.service';
//import { MainLoaderService } from './main-loader/main-loader.service';

/*@NgModule({
    declarations: [],
    imports: [
        HttpClientModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BaseUrl, multi: true }
    ]
})

export class CoreInterceptors { }
*/

/* "Barrel" of Http Interceptors */
/*import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth-interceptor';
import { CachingInterceptor } from './caching-interceptor';
import { EnsureHttpsInterceptor } from './ensure-https-interceptor';
import { LoggingInterceptor } from './logging-interceptor';
import { NoopInterceptor } from './noop-interceptor';
import { TrimNameInterceptor } from './trim-name-interceptor';
import { UploadInterceptor } from './upload-interceptor';
*/

export const CoreServicesProvider = [
    UrlService,
    WebsocketService,
    PreviewsService,
    WindowService,
    TitleService,
    BrowserService
//    EventDispatcherService,
//    MainLoaderService

  //{ provide: HTTP_INTERCEPTORS, useClass: EnsureHttpsInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: TrimNameInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: UploadInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },

];
