import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AddVersion } from '@app/models/add-version';

@Component({
    selector: 'versions-add-number',
    templateUrl: './number.component.html',
    styleUrls: ['./number.component.scss']
})
export class VersionsAddNumberComponent implements OnInit, OnChanges {
    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

    @Input() addVersion: AddVersion;
    @Output() addVersionChange = new EventEmitter<Partial<AddVersion>>(); 
    @Output() formReady = new EventEmitter<FormGroup>();

    public pageStatus: number = this.STATUS_LOADING;
    public form: FormGroup;

    
    constructor(private fb: FormBuilder) { }

    ngOnInit() {
        this.form = this.fb.group({
            version: [this.addVersion.version, [Validators.required]],
            subversion: [this.addVersion.subversion, [Validators.required]],
        });
        // send this local form to parent to let parent reset it
        this.formReady.emit(this.form);

        this.pageStatus = this.STATUS_DONE;
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.addVersion){ // TODO
            if(this.form != null) // avoid on initial change
                this.reset();
        }
    }

    onVersionChange(){
        if (this.form.get('version').value > 0)
            this.addVersionChange.emit({version: +this.form.get('version').value });
        else
            this.reset();
    }

    onSubVersionChange(){
        if (this.form.get('subversion').value > 0)
            this.addVersionChange.emit({subversion: +this.form.get('subversion').value });
        else 
            this.reset();
        
    }

    protected reset(){
        this.form.reset({version: this.addVersion.version,subversion: this.addVersion.subversion});
    }
}
