import { Component, EventEmitter, Input, Output, SimpleChanges, OnInit, OnChanges, HostListener} from '@angular/core';

import { User } from '@core/objects/user';

@Component({
  selector: 'core-helper-box-header-user-assignee',
  templateUrl: './header-user-assignee.component.html',
  styleUrls: ['./header-user-assignee.component.scss']
})
export class HelperBoxHeaderUserAsigneeComponent implements OnInit, OnChanges{

    @Input() user: User = null;
    @Input() posText: string;
    @Input() preText: string;
    @Input() users: Array<User>;
    @Output() onSelectAssignee = new EventEmitter<User>();
    @Output() onAssigneeClick = new EventEmitter();
    @Output() onCancel = new EventEmitter();


    private wasInside: boolean = false;
    public options: Array<User>;
    public displayUsers: boolean = false;

    constructor(){}
    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }

    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.displayUsers = false;
        }
        this.wasInside = false;
    }
  ngOnInit(){

  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.users || changes.user) {
      this.filterList();
    }
  }

  protected filterList() {
    // this.options = this.users;

    // if( this.user!= null)
      this.options = this.users.filter((u: User) => this.user==null || u.id != this.user.id);
  }

  public assigneeClick(){
    this.onAssigneeClick.emit();
    if(this.options.length > 0)
      this.displayUsers = true;
  }

  public userSelect(user: User){
    console.debug(" %%%%%  USER selected", user);
    this.onSelectAssignee.emit(user);
    this.cancel();
  }

  public removeAsignee(){
    this.onSelectAssignee.emit(null);
    this.cancel();
  }

  public cancel(){
    this.displayUsers = !this.displayUsers;
  }

}
