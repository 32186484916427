import { Component, Input} from '@angular/core';

@Component({
    selector: 'core-version-approval-response',
    templateUrl: './response.component.html',
    styleUrls: ['./response.component.scss']
})
export class VersionApprovalResponseComponent  {
    @Input() approval: any;
}
