import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { DashboardSearchService, DashboardService } from 'app/services/app/dashboard.service';


import * as Objects from '@core/objects';
import * as Search from '@app/models/search';


@Component({
    selector: 'dashboard-views-table-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class DashboardViewsTableHeaderComponent implements OnInit, OnDestroy {

    public order: string = '';
    public showNavOptions: boolean = false;

    protected appliedSortSubscription: Subscription;

    constructor(protected searchSrv: DashboardSearchService,
        protected dashSrv: DashboardService){}

    ngOnInit(): void {
        this.appliedSortSubscription = this.dashSrv.selectOrder().subscribe((order: string) => {
            this.order = order;
        });
    }

    ngOnDestroy(): void {
        this.appliedSortSubscription.unsubscribe();
    }
   

    setSort(property: string){
        this.showNavOptions = false;
        if(this.order.includes(property)){
            this.dashSrv.setOrder(this.getOrderPrefix()+property);
        }  else {
            this.dashSrv.setOrder(property);
        }
        
    }

    getOrderPrefix(){
        return this.order[0] == '-' ? '': '-';
    }
}