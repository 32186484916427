import { Component } from '@angular/core';

@Component({
    selector: 'deprecated-browser-screen',
    templateUrl: './deprecated-browser-screen.component.html',
    styleUrls: ['./deprecated-browser-screen.component.scss']
})
export class DeprecatedBrowserScreenComponent {
    constructor() { }
}
