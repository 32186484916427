import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { ProjectDetailsInfoFeaturesComponent } from './main/main.component';
import { ProjectDetailsInfoFeaturesStagesComponent } from './stages/stages.component';
// import { AppHelperLabelsModule } from '@app/components/helpers/labels/labels.module';

@NgModule({
    imports: [
        CommonModule,
        // AppRouterModule,
        // FormsModule,
        // ReactiveFormsModule,
        CoreModule,
        HelpersModule,
        // AppHelperLabelsModule
        // LabelsModule,
        // AppVersionsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsInfoFeaturesComponent,
        ProjectDetailsInfoFeaturesStagesComponent
        
    ],
    exports: [
        ProjectDetailsInfoFeaturesComponent,
        ProjectDetailsInfoFeaturesStagesComponent
    ],

})
export class ProjectsDetailsInfoFeaturesModule { }
