import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { LabelsModule } from '@core/components/labels/labels.module';
//import { CoreModule } from '@core/core.module';
import { PreviewModule } from '@core/components/preview/preview.module';

import { VersionPreviewComponent } from './preview/preview.component';
import { VersionPreviewTitleComponent } from './preview-title/preview-title.component';

@NgModule({
    imports: [
        CommonModule,
        PreviewModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionPreviewComponent,
        VersionPreviewTitleComponent
    ],
    exports: [
        VersionPreviewComponent,
        VersionPreviewTitleComponent
    ],

})
export class VersionPreviewModule { }
