import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import * as Objects from '@core/objects';

import { projectLogbookEventKeys } from '../../event-keys';

@Component({
    selector: 'project-details-logbook-events-list',
    templateUrl: './events-list.component.html',
    styleUrls: ['./events-list.component.scss']
})
export class ProjectDetailsLogbookEventsListComponent implements OnInit, OnChanges{
	@Input() project: Objects.Request = null;
	@Input() events: Array<Objects.Event> = null;

    public eventsList: Array<Objects.Event> = [];
    public activatedGroup: string = '';
    public eventKeys: Objects.EventTitle[] = projectLogbookEventKeys;
    
    protected allowedEventKeys: Array<string> = [];

    

    ngOnInit(): void {
        this.setAllowedEventTypes();
        if(this.events != null){
            this.updateEventList();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.events){
            this.updateEventList();
        }
    }

    onSelectGroup(group: string) : void {
        console.log("group to set/quit -->"+group);
        this.activatedGroup = (group == this.activatedGroup) ? '' : group;
    }

    isAllowedEvent(event: Objects.Event) : boolean{
        return this.allowedEventKeys.some((key: string) => key == event.type);
    }

    //FILTER EVENTS by a custom list of events that we will not display not included eevnts (For instance priority)
    protected setAllowedEventTypes(){
        // this is used only to filter events by type to avoid unnecessary
        
        this.allowedEventKeys = this.eventKeys.map((et: Objects.EventTitle) => et.key);
        
    }

    updateEventList(){
        this.eventsList = this.events.filter((event: Objects.Event) => this.allowedEventKeys.some((key: string) => key == event.type))
    }


}