import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { map } from 'rxjs/operators';
import { UrlService } from '@core/services/url/url.service';

import { User } from '@core/objects/user';
import { License } from '@core/objects/license';

import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingsLicensesRequests {
    private readonly licenses = "/licenses";

    constructor(private http: HttpClient, private urlSrv : UrlService) {
	}

	public loadAllLicenses$ = () : Observable <License[]> =>
		// this.http.get<SettingsUser[]>(this.urlSrv.build(this.users));
		this.http.get<License[]>(this.urlSrv.build(this.licenses));
    //
	// public loadAllInvitations$ = () : Observable <UserRequest[]> =>
	// 	this.http.get<UserRequest[]>(this.urlSrv.build(this.users + "/invitations"));
    //
	// public loadOneUser$ = ( user: User ) : Observable <User> =>
	// 	this.http.get<User>(this.urlSrv.build(this.users + "/" + user.id));
    //
	// public addNewInvitation$ = ( name:string, email:string, message:string, groups:Array<number>) : Observable<string> => {
	// 	let data = {
	// 		name: name,
	// 		email: email,
	// 		message: message,
	// 		groups: groups
	// 	}
	// 	return this.http.post(this.urlSrv.build(this.users + "/invitations"), data, {responseType: 'text'});
	// }
    //
	// public deleteOneUser$ = ( id:string ) : Observable <string> => {
	// 	return this.http.delete(this.urlSrv.build(this.users + "/" + id), {responseType: 'text'});
	// }
    //
	// public deleteInvitation$ = ( email:string ) : Observable <string> => {
	// 	return this.http.delete(this.urlSrv.build(this.users + "/invitations/" + email), {responseType: 'text'});
	// }
}
