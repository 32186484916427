import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabelsModule } from '@core/components/labels/labels.module';

import { HelpersSearchBarFieldsLabelComponent } from './label/label.component';
import { HelpersSearchBarFieldsUserComponent } from './user/user.component';
import { HelpersSearchBarFieldsStatusComponent } from './status/status.component';

@NgModule({
    imports: [
        CommonModule,
        LabelsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        HelpersSearchBarFieldsLabelComponent,
        HelpersSearchBarFieldsUserComponent,
        HelpersSearchBarFieldsStatusComponent
    ],
    exports: [
        HelpersSearchBarFieldsLabelComponent,
        HelpersSearchBarFieldsUserComponent,
        HelpersSearchBarFieldsStatusComponent

    ],

})
export class AppHelpersSearchBarFieldsModule { }