import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';

@Injectable({
  providedIn: 'root'
})
export class LabelsRequests {
	private readonly prefix = "/labels";

    constructor(private http: HttpClient, private urlSrv : UrlService) {}

	public search$ = (input:string) : Observable<Objects.Label[]> => {
        // let search = "name:*" + input + "*";

        let search = input.split(" ").filter((s: string) => s != "").map((ss: string) => "name:*" + ss + "*").join("|");

		return this.http.get<Objects.Label[]>(
            this.urlSrv.build(this.prefix), 
            {
                params: {
                    q: search, 
                    sort_by: 'name'
                }
            }
        );
    }

    public loadCategories$ = () : Observable<Objects.LabelsGroup[]> => 
	    this.http.get<Objects.LabelsGroup[]>(this.urlSrv.build(this.prefix + "/categories"));

    public add$ = (name: string, category: Objects.LabelsGroup): Observable<Objects.Label> => 
        this.http.post<Objects.Label>(this.urlSrv.build(this.prefix+"/categories/"+category.id), {name: name}, { headers: new HttpHeaders({ 'Content-Type':  'application/json'}) });
    
    public loadAccess$ = () : Observable<Access.AccessLabels> => 
	    this.http.get<Access.AccessLabels>(this.urlSrv.build(this.prefix + "/permissions"));
}
