
import { SettingsGroups, initialState, groupsGetAllGroupsSelector } from './groups.state';
import { SettingsGroupsActionType, SettingsGroupsActions, ActiveGroup } from './groups.actions';
import { HttpStatus } from '@core/classes/http-status.class';

export function settingsGroupsReducer(state = initialState, action: SettingsGroupsActions): SettingsGroups {
    switch (action.type) {

		// Groups
		case SettingsGroupsActionType.LoadAllGroups:
			return {...state, status: { ...state.status, groups: new HttpStatus(HttpStatus.PENDING, "")}};

		case SettingsGroupsActionType.LoadAllGroupsSuccess:
			return {...state, status: { ...state.status, groups: new HttpStatus(HttpStatus.DONE, "")}};
		
		case SettingsGroupsActionType.LoadAllGroupsError:
			return {...state, status: { ...state.status, groups: new HttpStatus(HttpStatus.ERROR, "")}};
	
		case SettingsGroupsActionType.SaveGroup:
			return {...state, status: { ...state.status, save: new HttpStatus(HttpStatus.PENDING, "")}};
		
		case SettingsGroupsActionType.SaveGroupSuccess:
			return {...state, status: { ...state.status, save: new HttpStatus(HttpStatus.DONE, "")}};

		case SettingsGroupsActionType.SaveGroupError:
			return {...state, status: { ...state.status, save: new HttpStatus(HttpStatus.ERROR, "")}};

		case SettingsGroupsActionType.SetAllGroups:	
			return {
				...state,
				groups: action.groups
			};
		
		// case SettingsGroupsActionType.ActiveGroup:
		// 	action.group.active = true;

		// 	return {
		// 		...state,
		// 		groups: state.groups.map((u) => u.id != action.group.id? u : action.group)
		// 	};
		// 	// action.group.active = true;
		// 	// return {
		// 	// 	...state,
		// 	// 	groups: state.groups.filter((u) => u.id != action.group.id).push(action.group)
		// 	// }

	    default:
	        return state;
    }
}
