import { Action } from '@ngrx/store';

import { MenuItem } from '@core/models/menu-item.model';

import { ApolloItem } from '../../../model/apollo-item.model';
import { ApolloFilter } from '../../../model/apollo-filter.model';
import { ApolloRequest } from '../../../model/apollo-request.model';
import { ApolloHistory } from '../../../model/apollo-history.model';
import { ApolloCompare } from '../../../model/apollo-compare.model';
import { ApolloGraphicComparison } from '../../../model/apollo-graphic-comparison.model';
import { ApolloContentComparison, ApolloContentComparisonExtract } from '../../../model/apollo-content-comparison.model';

export enum ApolloActionTypes {
    ActiveTabsItem = '[Apollo] Active Tabs Item request',
    DeactiveTabsItem = '[Apollo] Deactive Tabs Item request',
    ChangeTabsItemRequest = '[Apollo] Change Tabs Item request',
    SetFileTarget = '[Apollo] Set file target',
    SetFileReference = '[Apollo] Set file reference',
    SetItemRequest = '[Apollo] Set any item by string request',
    ResetRequest = '[Apollo] Reset request and upload files',
    AddRequest = '[Apollo] Load a new request',
    AddRequestSuccess = '[Apollo] Set apollo request',
    GetRequest = '[Apollo] Get request',
    LoadSavedRequest = '[Apollo] Load saved request',
    LoadSavedRequestSuccess = '[Apollo] Success load saved request',
    RemoveItemRequest = '[Apollo] Remove item from request',
    SetItemReferenceRequest = '[Apollo] Change item reference',
    SetItemTargetRequest = '[Apollo] Change item target',
    AddItemFilterRequest = '[Apollo] Add filter to item',
    SelectItemPageRequest = '[Apollo] Select page from item',
    //AddGraphicComparison = '[Apollo] Load a new graphic comparison',
    CheckAndGetComparison = '[Apollo] Load request graphic or content comparison',
    GetGraphicComparison = '[Apollo] Get graphic comparison',
    SetGraphicComparison = '[Apollo] Set graphic comparison',
    UpdateResolutionGraphicComparison = '[Apollo] Update resolution graphic comparison',
    RegisterGraphicComparison = '[Apollo] Update register layer',
    CompareGraphicComparison = '[Apollo] Get compare',
    LoadFile = '[Apollo] Load file',
    LoadFileSuccess = '[Apollo] Load File success',

    // Content
    GetContentComparison = '[Apollo] Get content comparison',
    GetContentComparisonSuccess = '[Apollo] Get content comparison - SUCCESS',
    SetContentComparison = '[Apollo] Set content comparison',
    UpdateContentComparisonExtract = '[Apollo] Update content comparison extract',
    OnUpdateContentComparisonExtract = '[Apollo] Update content comparison extract success',
    UpdateContentComparisonExtractError = '[Apollo] Update content comparison extract error',
    UpdateContentComparison = '[Apollo] Update method content compare',
    GetContentCompare = '[Apollo] Get Content compare',
    RemoveContentCompare = '[Apollo] Remove content compare',
    GetHistory = '[Apollo] Load history per page',
    UpdateHistory = '[Apollo] Set history',
    SetNameToCompareHistory = '[Apollo] Set name to compare',
    UpdateNameToCompareHistory = '[Apollo] Update name to compare'
}

/*export class ChangeTabsItemRequest implements Action {
  readonly type = ApolloActionTypes.ChangeTabsItemRequest;
  constructor() {}
}*/

export class ActiveTabsItem implements Action {
    readonly type = ApolloActionTypes.ActiveTabsItem;
    constructor(public title: string) {}
}

export class DeactiveTabsItem implements Action {
    readonly type = ApolloActionTypes.DeactiveTabsItem;
    constructor(public title: string) {}
}


export class ChangeTabsItemRequest implements Action {
    readonly type = ApolloActionTypes.ChangeTabsItemRequest;
    constructor(public payload: MenuItem) {}
}

export class RemoveItemRequest implements Action {
    readonly type = ApolloActionTypes.RemoveItemRequest;
    constructor(public propertyName: string) {}
}

export class SetItemReferenceRequest implements Action {
    readonly type = ApolloActionTypes.SetItemReferenceRequest;
    constructor(public reference: ApolloItem) {}
}

export class SetItemTargetRequest implements Action {
    readonly type = ApolloActionTypes.SetItemTargetRequest;
    constructor(public target: ApolloItem) {}
}

export class SetItemRequest implements Action {
    readonly type = ApolloActionTypes.SetItemRequest;
    constructor(public propertyName: string, public value: ApolloItem) {}
}

export class AddItemFilterRequest implements Action {
    readonly type = ApolloActionTypes.AddItemFilterRequest;
    constructor(public propertyName: string, public filter: ApolloFilter) {}
}

export class SelectItemPageRequest implements Action {
    readonly type = ApolloActionTypes.SelectItemPageRequest;
    constructor(public propertyName: string, public page: number) {}
}

export class ResetRequest implements Action {
    readonly type = ApolloActionTypes.ResetRequest;
    constructor() {}
}

export class AddRequest implements Action {
    readonly type = ApolloActionTypes.AddRequest;
    constructor(public event: string) {}
}

export class AddRequestSuccess implements Action {
    readonly type = ApolloActionTypes.AddRequestSuccess;
    constructor(public request: ApolloRequest, public event?: string) {}
}

export class GetRequest implements Action {
    readonly type = ApolloActionTypes.GetRequest;
    constructor() {}
}

export class LoadSavedRequest implements Action {
    readonly type = ApolloActionTypes.LoadSavedRequest;
    constructor(public hash: string) {}
}

export class LoadSavedRequestSuccess implements Action {
    readonly type = ApolloActionTypes.LoadSavedRequestSuccess;
    constructor(public request: ApolloRequest) {}
}

export class SetFileReference implements Action {
    readonly type = ApolloActionTypes.SetFileReference;
    constructor(public reference:any){}
}

export class SetFileTarget implements Action {
    readonly type = ApolloActionTypes.SetFileTarget;
    constructor(public target:any){}
}

export class CheckAndGetComparison implements Action {
    readonly type = ApolloActionTypes.CheckAndGetComparison;
    constructor(public hash: string, public event: string) {}
}

// Graphic Comparison
// export class AddGraphicComparison implements Action {
//     readonly type = ApolloActionTypes.AddGraphicComparison;
//     constructor() {}
// }

export class GetGraphicComparison implements Action {
    readonly type = ApolloActionTypes.GetGraphicComparison;
    constructor() {}
}

export class SetGraphicComparison implements Action {
    readonly type = ApolloActionTypes.SetGraphicComparison;
    constructor(public apolloGraphic: ApolloGraphicComparison) {}
}

export class UpdateResolutionGraphicComparison implements Action {
    readonly type = ApolloActionTypes.UpdateResolutionGraphicComparison;
    constructor (public resolution: number){}
}

export class RegisterGraphicComparison implements Action {
    readonly type = ApolloActionTypes.RegisterGraphicComparison;
    constructor (public registerType: string, public registerLayer: string) {}
}

export class CompareGraphicComparison implements Action {
    readonly type = ApolloActionTypes.CompareGraphicComparison;
    constructor () {}
}

export class LoadFile implements Action {
    readonly type = ApolloActionTypes.LoadFile;
    constructor (public propertyName:string, public id:string) {}
}

export class LoadFileSuccess implements Action {
    readonly type = ApolloActionTypes.LoadFileSuccess;
    constructor (public propertyName:string, public file: any, public item: ApolloItem) {}
}

// Content
export class GetContentComparison implements Action {
    readonly type = ApolloActionTypes.GetContentComparison;
    constructor() {}
}

export class GetContentComparisonSuccess implements Action {
    readonly type = ApolloActionTypes.GetContentComparisonSuccess;
    constructor(public apolloContent: ApolloContentComparison) {}
}

export class SetContentComparison implements Action {
    readonly type = ApolloActionTypes.SetContentComparison;
    constructor(public apolloContent: ApolloContentComparison) {}
}

export class UpdateContentComparisonExtract implements Action {
    readonly type = ApolloActionTypes.UpdateContentComparisonExtract;
    constructor (public comparisonExtract: ApolloContentComparisonExtract, public typeFile:string){}
}


export class OnUpdateContentComparisonExtract implements Action {
    readonly type = ApolloActionTypes.OnUpdateContentComparisonExtract;
    constructor (public comparisonExtract: ApolloContentComparisonExtract, public typeFile:string){}
}

export class UpdateContentComparisonExtractError implements Action {
    readonly type = ApolloActionTypes.UpdateContentComparisonExtractError;
    constructor (){}
}

export class UpdateContentComparison implements Action {
    readonly type = ApolloActionTypes.UpdateContentComparison;
    constructor (public method: string){}
}

export class GetContentCompare implements Action {
    readonly type = ApolloActionTypes.GetContentCompare;
    constructor (){}
}

export class RemoveContentCompare implements Action {
    readonly type = ApolloActionTypes.RemoveContentCompare;
    constructor(){}
}

export class GetHistory implements Action {
    readonly type = ApolloActionTypes.GetHistory;
    constructor(public page: number){}
}

export class UpdateHistory implements Action {
    readonly type = ApolloActionTypes.UpdateHistory;
    constructor(public page: number, public history: ApolloHistory){}
}

export class SetNameToCompareHistory implements Action {
    readonly type = ApolloActionTypes.SetNameToCompareHistory;
    constructor(public hash: string, public name: string){}
}

export class UpdateNameToCompareHistory implements Action {
    readonly type = ApolloActionTypes.UpdateNameToCompareHistory;
    constructor(public compare: ApolloCompare){}
}


//export type AppActions = GetSession | ClearUserMessage | IsLoginNeeded | StoreToken;
export type ApolloActions = ActiveTabsItem |
                            DeactiveTabsItem |
                            SetItemReferenceRequest |
                            SetItemTargetRequest |
                            SetItemRequest |
                            ResetRequest |
                            AddRequest |
                            AddRequestSuccess |
                            SetFileReference |
                            SetFileTarget |
                            //AddGraphicComparison |
                            CheckAndGetComparison |
                            GetGraphicComparison |
                            SetGraphicComparison |
                            UpdateResolutionGraphicComparison |
                            RegisterGraphicComparison |
                            CompareGraphicComparison |
                            RemoveItemRequest |
                            AddItemFilterRequest |
                            SelectItemPageRequest |
                            LoadFile |
                            LoadFileSuccess |
                            GetContentComparison |
                            GetContentComparisonSuccess |
                            SetContentComparison |
                            UpdateContentComparisonExtract |
                            OnUpdateContentComparisonExtract |
                            UpdateContentComparisonExtractError |
                            UpdateContentComparison |
                            GetContentCompare |
                            RemoveContentCompare |
                            GetHistory |
                            UpdateHistory |
                            SetNameToCompareHistory |
                            UpdateNameToCompareHistory |
                            LoadSavedRequest |
                            LoadSavedRequestSuccess;
