import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as Search from '@app/models/search';
import { DashboardStatusFilter } from 'app/store/state/dashboard/dashboard.state';

interface DashboardStatusFilters extends Search.SearchAvailableFilters {
    items: Array<DashboardStatusFilter>;
}
@Component({
    selector: 'app-helpers-search-filters-workflow-status-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})
export class HelpersSearchFiltersWorkflowStatusStatusComponent{

    @Input()  available: DashboardStatusFilters = null;
    @Input()  applied: Array<Search.SearchAppliedFilter> = [];

    @Output() choose = new EventEmitter<DashboardStatusFilter>();

    onSelect(statusFilter: DashboardStatusFilter) {
        this.choose.emit(statusFilter);
    }

}
