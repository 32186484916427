import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppRouterService } from '@app/services/app/router.service';
import { VersionsService } from '@app/services/app/versions.service';
import { ProjectsRequests } from 'app/store/state/projects/projects.requests';
import { AppService } from 'app/services/app/app.service';


import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';

@Component({
    selector: 'dashboard-views-table-details-artworks',
    templateUrl: './artworks.component.html',
    styleUrls: ['./artworks.component.scss']
})
export class DashboardViewsTableDetailsArtworksComponent implements OnInit, OnDestroy {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly ERROR = 2;

    public readonly ACCESS_REQUEST_FEEDBACK = 'share_feedback';
    public readonly ACCESS_ADD_PROJECT = "access_global_new_request";

    @Input() project: Objects.ExtendedRequest;

    public status:number = this.LOADING;
    public feedbackPluginAccess: boolean = false;
    public versions: Array<Objects.Version> = null;
    public feedbacks: Array<VersionFeedback> = [];


    protected subscriptor: Subscription;
    protected appSubscriptor: Subscription;


    constructor( public app: AppService,private versionsSrv: VersionsService, private requestsSrv: ProjectsRequests,
        private appRouter: AppRouterService ) { }

    ngOnInit() {
        // get plugins access
        this.appSubscriptor = this.app.getSession().subscribe( (session: any) => {
            this.feedbackPluginAccess = session.options.plugins.some((pa) => pa == this.ACCESS_REQUEST_FEEDBACK);
        });
        this.subscriptor =  this.requestsSrv.loadUploadedVersions$(this.project.id).subscribe((versions: Objects.Version[]) => {
            this.versions = versions;
            if(versions.length > 0)
                this.loadFeedbacks(versions);
            this.init();
        });

    }

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
        this.appSubscriptor.unsubscribe();
    }

    init(){

        if(this.project == null || this.versions == null)
            return;
        this.status = this.DONE;
    }

    // feedbacks come  by group of versions
    protected loadFeedbacks(versions: Array<Objects.Version>) {
        if(!this.feedbackPluginAccess) {
            return;
        }
        this.versionsSrv.getFeedbacks$(versions).subscribe((feedbacks: Array<VersionFeedback>) => {
               this.feedbacks = feedbacks;
        });
    }

}