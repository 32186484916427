import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpersModule } from '../helpers/helpers.module';
import { FilesModule } from '../files/files.module';

import { CommonsFilesListCheckboxComponent } from './files-list-checkbox/files-list-checkbox.component';

@NgModule({
    imports: [
        CommonModule,
        HelpersModule,
        FilesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        CommonsFilesListCheckboxComponent
    ],
    exports: [
        CommonsFilesListCheckboxComponent
    ],

})
export class CommonsModule { }
