import { Component,Input } from '@angular/core';

import { Label } from '@core/objects/label';

import { TitleBreadcrumb } from '../title-section/title-section.component';

@Component({
    selector: 'core-title-section-labels',
    templateUrl: './title-section-labels.component.html',
    styleUrls: ['./title-section-labels.component.scss']
})
export class LayoutTitleSectionLabelsComponent {
    @Input() text: any;
    @Input() description: string = "";
    @Input() breadcrumbs: Array<TitleBreadcrumb> = [];
    @Input() labels: Array<Label>;
}
