//import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import { Root } from './root.state';

// import * as fromSettingsUsers from '../settings/users/users.reducer';
// import * as fromSettingsGroups from '../settings/groups/groups.reducer';
// import * as fromSettingsLicenses from '../settings/licenses/licenses.reducer';
// import * as fromSettingsRules from '../settings/rules/rules.reducer';

import * as fromSettingsReducer from '../settings/settings.reducers';
import * as fromProjectsReducer from '../projects/projects.reducer';
import * as fromLabelsReducer from '../labels/labels.reducer';
import * as fromVersionsReducer from '../versions/versions.reducer';
import * as fromAddProjectReducer from '../add-project/add-project.reducer';
import * as fromLibraryReducer from '../library/library.reducer';
import * as fromDasboardReducer from '../dashboard/dashboard.reducer';

export const rootReducers = {
    app: fromApp.appReducer,
    settings: fromSettingsReducer.settingsReducer,
    projects: fromProjectsReducer.projectsReducer,
    versions: fromVersionsReducer.versionsReducer,
    labels: fromLabelsReducer.labelsReducer,
    new_project: fromAddProjectReducer.addProjectReducer,
    library: fromLibraryReducer.libraryReducer,
    dashboard: fromDasboardReducer.dashboardReducer
};

export const metaReducers: MetaReducer<Root>[] = [];
