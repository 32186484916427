import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { AvailableRuleEventCondition, AvailableRuleItem, RuleCondition } from '@app/models/rules';
import { AccessTokensService } from '@app/services/settings/access-tokens.service';

@Component({
  selector: 'settings-rules-rule-conditions-item',
  templateUrl: './condition-item.component.html',
  styleUrls: ['./condition-item.component.scss'],
})
export class SettingsRulesRuleFormConditionsItemComponent {

    @Input() position: Number;
    @Input() condition: RuleCondition;
    @Input() availableConditions: Array<AvailableRuleEventCondition>;
    @Output() clickAdd = new EventEmitter();
    @Output() clickRemove = new EventEmitter();
    @Output() change = new EventEmitter<RuleCondition>();

    constructor(){}

    // update variable means update also condition and value selected
    selectVariable(event){
        // console.debug("select variabl---->e",event);

        let condition = {
            ...this.condition,
            variable: event.key
        };

        this.change.emit(condition);
    }

    selectOption(event){
        // console.debug("select option--->",event);

        let condition = {
            ...this.condition,
            condition: event.key
        };

        this.change.emit(condition);
    }

    selectValue(event){
        // console.log("select value---->", event);

        let condition = {
            ...this.condition,
            value: event.key
        };

        this.change.emit(condition);
    }

    addCondition(){
        this.clickAdd.emit();
    }

    removeCondition(){
        this.clickRemove.emit();
    }

    getCondition(key: string) : AvailableRuleEventCondition {
        let cs = this.availableConditions.filter((ac:AvailableRuleEventCondition) => ac.key == key );

        return cs[0];
    }
}
