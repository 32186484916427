import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppHelperFormModule } from './form/form.module';
import { AppHelperLabelsModule } from './labels/labels.module';
import { AppHelpersSearchModule } from './search/search.module';

@NgModule({
    imports: [
        CommonModule,
        AppHelperFormModule,
        AppHelperLabelsModule,
        AppHelpersSearchModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
    ],
    exports: [
        AppHelperFormModule,
        AppHelperLabelsModule,
        AppHelpersSearchModule
    ],

})
export class AppHelpersModule { }