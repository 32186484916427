import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import * as Objects from '@core/objects'
import * as Search from '@app/models/search';


interface SearchAvailableLabelsFilters extends Search.SearchAvailableFilters {
    items: Array<Objects.Label>;
}

@Component({
    selector: 'app-helpers-search-filters-labels-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class HelpersSearchFiltersLabelsMainComponent implements OnInit, OnChanges {

    @Input()  available: SearchAvailableLabelsFilters = null;
    @Input()  applied: Array<Search.SearchAppliedFilter> = [];

    @Output() apply = new EventEmitter<Search.SearchAppliedFilter>();

    public opened: number = null;
    public labelsGroups: Array<Objects.LabelsGroup> = [];
    public labels: Objects.Label[] = [];

    ngOnInit(): void {
        // get groups from actual labels
        this.labelsGroups = this.available.items.map((l: Objects.Label) => l.group).filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i);
        this.makeLabels();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.applied)
            this.makeLabels();
    }

    private isApplied(label: Objects.Label) : boolean {
        return this.applied.some((f: Search.SearchAppliedFilter) => f.key == this.available.key && f.item.id == label.id);
    }

    private makeLabels() {
        this.labels =   this.available.items.filter((label: Objects.Label) => !this.isApplied(label));
    }


    onSelect(label: Objects.Label) {
        this.apply.emit({key: this.available.key, item: label});
    }

    onOpen(group: Objects.LabelsGroup){
        this.opened = group.id;
    }

    getGroupLabels(group: Objects.LabelsGroup){
        return this.labels.filter((l: Objects.Label) => l.group.id == group.id);
    }

}
