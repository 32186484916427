import { Projects, initialState, viewSelector} from './projects.state';
import { ProjectsActions, ProjectsActionType } from './projects.actions';

import { HttpStatus } from '@core/classes/http-status.class';

import { Request } from '@core/objects/request';
import { errorsFullScreen } from '@core/components/layout/errors/errors-full-screen';
import { Viewer } from '@core/components/preview/preview-page-viewer/viewer';

import * as Objects from '@core/objects';

export function projectsReducer(state = initialState, action: ProjectsActions): Projects {
    switch (action.type) {
		// EVENTS STATUS

        // case ProjectsActionType.LoadProject:
        //     // TODO create LoadProject object if not exist and status loading
        //     if(!state.items.map((e: ProjectLoaded) => e.id).includes(action.projectId)) {
        //         let pl = {
        //             id: action.projectId,
        //             project: null,
        //             status: null
        //         };

        //         state.items.push(pl);
        //     }
        //     return state;
        case ProjectsActionType.LoadProjectSuccess:
            // return {
			// 	...state, 
			// 	items: state.items.map(
			// 		(e: ProjectLoaded) => e.id == action.project.id? {...e, project: action.project} : e 
			// 	)
			// };

            // return {...state, project: action.project, status: state.status.filter((error) => error.key !=)};


            return {...state, view: {...state.view, project: action.project }, errors: {...state.errors, load_project: null }};
        case ProjectsActionType.LoadProjectHeaderSuccess:
            return {...state, view: {...state.view, header: action.header }, errors: {...state.errors, load_header: null }};
        case ProjectsActionType.LoadProjectError:
            return {...state, view: {...state.view, project: null}, errors: {...state.errors, load_project: action.error }};
            // return state;
        
        case ProjectsActionType.LoadProjectFilesSuccess:
            return {...state, view: {...state.view, files: action.files.filter((file: Objects.File) => file.active)}, errors: {...state.errors, load_project_files: null }};
            // return state;
        
        case ProjectsActionType.LoadProjectFilesError:
            return {...state, view: {...state.view, files: []}, errors: {...state.errors, load_project_files: action.error }};
            // return state;

        case ProjectsActionType.LoadProjectOriginVersionSuccess:
            return {...state, view: {...state.view, origin_version: action.versions.length>0? action.versions[0] : null}, errors: {...state.errors, load_project_origin_version: null }};
            // return state;
        
        case ProjectsActionType.LoadProjectOriginVersionError:
            return {...state, view: {...state.view, origin_version: null}, errors: {...state.errors, load_project_origin_version: action.error }};
            // return state;

                
        case ProjectsActionType.SetActiveProjectFile:
            return {
                ...state, 
                view: {
                    ...state.view, 
                    files: state.view.files.map((file: Objects.File) => file.id == action.file.id? {...file, active: action.active} : file) 
                }, 
                errors: {
                    ...state.errors, 
                    set_active_project_file: null 
                }
            };
            // return state;
        case ProjectsActionType.SetActiveProjectFileError:
            return {...state, errors: {...state.errors, set_active_project_file: action.error }};
            // return state;

        case ProjectsActionType.UploadProjectFilesSuccess:
            return {...state, view: {...state.view, files: [...state.view.files, ...action.files]}, errors: {...state.errors, upload_project_files: null }};
        
        case ProjectsActionType.UploadProjectFilesError:
            return {...state, errors: {...state.errors, upload_project_files: action.error }};

        case ProjectsActionType.LoadEditorAssignersSuccess:
            return {...state, editor: {...state.editor, assigners: action.users}, errors: {...state.errors, load_editor_assigners: null}};
        
        case ProjectsActionType.LoadEditorAssignersError:
            return {...state, errors: {...state.errors, load_editor_assigners: action.error}};
        
        case ProjectsActionType.LoadEditorWorkflowTransitionsSuccess:
            return {...state, editor: {...state.editor, workflow_transitions: action.transitions}, errors: {...state.errors, load_editor_workflow_transitions: null}};
        
        case ProjectsActionType.LoadEditorWorkflowTransitionsError:
            return {...state, errors: {...state.errors, load_editor_workflow_transitions: action.error}};
        
        case ProjectsActionType.LoadEditorAccessSuccess:
            return {...state, editor: {...state.editor, access: action.access}, errors: {...state.errors, load_editor_access: null}};
        
        case ProjectsActionType.LoadEditorAccessError:
            return {...state, errors: {...state.errors, load_editor_access: action.error}};

        case ProjectsActionType.SetProjectAssigned:
            return {...state, view: {...state.view, project: {...state.view.project, user_assigned: action.user}}}
        
        case ProjectsActionType.SetProjectAssignedSuccess:
            return {...state, errors: {...state.errors, set_project_assigned: null}}
        
        case ProjectsActionType.SetProjectAssignedError:
            return {...state, errors: {...state.errors, set_project_assigned: action.error}}

        case ProjectsActionType.SetProjectDue:
            return {...state, view: {...state.view, project: {...state.view.project, due_date: action.date}}}
        
        case ProjectsActionType.SetProjectDueSuccess:
            return {...state, errors: {...state.errors, set_project_due: null}}
        
        case ProjectsActionType.SetProjectDueError:
            return {...state, errors: {...state.errors, set_project_due: action.error}}

        case ProjectsActionType.SetProjectStatus:
            return {...state, view: {...state.view, project: {...state.view.project, status: action.status}}}
        
        case ProjectsActionType.SetProjectStatusSuccess:
            return {...state, errors: {...state.errors, set_project_status: null}}
        
        case ProjectsActionType.SetProjectStatusError:
            return {...state, errors: {...state.errors, set_project_status: action.error}}

        case ProjectsActionType.SetProjectLabels:
            return {...state, view: {...state.view, project: {...state.view.project, labels: action.labels}}}
        
        case ProjectsActionType.SetProjectLabelsSuccess:
            return {...state, errors: {...state.errors, set_project_status: null}}
        
        case ProjectsActionType.SetProjectLabelsError:
            return {...state, errors: {...state.errors, set_project_labels: action.error}}

        case ProjectsActionType.LoadProjectMessagesSuccess:
            return {...state, view: {...state.view, messages: action.messages}, errors: {...state.errors, load_project_messages: null }};

        case ProjectsActionType.LoadProjectMessagesError:
            return {...state, view: {...state.view, messages: []}, errors: {...state.errors, load_project_messages: action.error }};
        
        case ProjectsActionType.LoadProjectVersionGroupsSuccess:
            return {...state, view: {...state.view, version_groups: action.relateds}, errors: {...state.errors, load_project_version_groups: null }};
    
        case ProjectsActionType.LoadProjectVersionGroupsError:
            return {...state, errors: {...state.errors, load_project_version_groups: action.error }};
        
        case ProjectsActionType.LoadProjectUploadedVersionsSuccess:
            return {...state, view: {...state.view, uploaded_versions: action.versions}, errors: {...state.errors, load_project_uploaded_versions: null }};
    
        case ProjectsActionType.LoadProjectUploadedVersionsError:
            return {...state, errors: {...state.errors, load_project_uploaded_versions: action.error }};

        case ProjectsActionType.LoadProjectFilesNotesSuccess:
            return {...state, view: {...state.view, files_notes: action.notes}, errors: {...state.errors, load_project_files_notes: null }};
            // return state;
        
        case ProjectsActionType.LoadProjectFilesNotesError:
            return {...state, view: {...state.view}, errors: {...state.errors, load_project_files_notes: action.error }};
            // return state;
        case ProjectsActionType.LoadEventsSuccess:
            if(state.view.events == null){
                return {...state, view: {...state.view, events: action.events}, errors: {...state.errors, load_events: null }};
            } else {
                return { ...state, 
                    view: { 
                        ...state.view,
                        events: [...state.view.events.filter((event: Objects.Event) => !action.events.some((ev: Objects.Event) => event.id == ev.id)), ...action.events]
                    }
                };
            }
        
        case ProjectsActionType.LoadEventsError:
            return {...state, errors: {...state.errors, load_events: action.error }};
        
        case ProjectsActionType.Clear:
            return {...state, view: initialState.view, errors: initialState.errors};

        case ProjectsActionType.ClearEditorTransitions:
            // console.log(" cleaning editor transitions")
            return {...state, editor: {...state.editor, workflow_transitions: initialState.editor.workflow_transitions}, errors: initialState.errors};

        case ProjectsActionType.ClearVersionsGroups:
            return {...state, view :{...state.view, version_groups: initialState.view.version_groups}, errors: initialState.errors};
        
	    default:
	        return state;
    }
}
