import { Component, EventEmitter, Input, Output, OnInit  } from '@angular/core';
import { Subscription } from 'rxjs';

import { LabelsService } from '@app/services/app/labels.service';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';

@Component({
    selector: 'app-helpers-labels-edit-box',
    templateUrl: './edit-box.component.html',
    styleUrls: ['./edit-box.component.scss'],
    providers: []
})
export class HelpersLabelsEditBoxComponent implements OnInit {

    @Input() addControl: boolean = true;
    @Input() allowClose: boolean = true;
    @Input() searchPlaceholder: string = "Search for tags here";
    @Input() searchFoundText: string = "";
    @Input() headerText: string = "";

    @Input()  labels: Array<Objects.Label> = [];
    @Output() labelsChange = new EventEmitter<Objects.Label[]>();
    @Output() close = new EventEmitter();


    public input: string = ''
    public access: Access.AccessLabels = {
        access_label_add: false
    }

    protected subscriptor: Subscription;

    constructor(protected labelsSrv: LabelsService) {}

    ngOnInit() {
        this.subscriptor = this.labelsSrv.selectAccess().subscribe((access: Access.AccessLabels) => this.access = access);

        console.log("edit label +++");
        this.labelsSrv.loadAccess();
    }

    remove(label: Objects.Label) {
        console.log("remove--->", label);

        this.labels = this.labels.filter((l: Objects.Label) => label.id != l.id);
        this.emit();
    }

    add(label: Objects.Label) {
        let labels = this.labels.filter((l: Objects.Label) => label.id == l.id);
        if(labels.length>0)
            return;

        this.labels.push(label);

        this.emit();
    }

    emit() {
        this.labelsChange.emit(this.labels);
    }
}
