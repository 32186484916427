import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
// import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';
import { FilesListItemMenuClick } from '../files-list-item-menu-click';

@Component({
    selector: 'core-files-list-notes-item',
    templateUrl: './notes-item.component.html',
    styleUrls: ['./notes-item.component.scss']
})
export class FilesListNotesItemComponent implements OnInit {

    @Input() file: File;
    @Input() notes: number = null;
    @Input() menu: Array<ThreeDotsMenuItem> = [];
    
    @Output() actionClick= new EventEmitter<File>();
    @Output() menuClick = new EventEmitter<FilesListItemMenuClick>();

    constructor() { }

    ngOnInit() {
    }

    onMenuSelect(key: string) {
        this.menuClick.emit({key: key, file: this.file});
    }
}
