import { Component, Input, Output, EventEmitter} from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'core-version-approval',
    templateUrl: './approval.component.html',
    styleUrls: ['./approval.component.scss']
})
export class VersionApprovalComponent {
    static readonly ACTION_TRUE  = "approve";
    static readonly ACTION_FALSE = "decline";

    @Input() approval: any;
    @Input() action: any;

    @Output() onSaved = new EventEmitter<any>();
    @Output() onFileClick = new EventEmitter<any>();
    @Output() onFileShow = new EventEmitter<any>();

    public working: boolean = false;
    public error: boolean = false;

    constructor(protected httpClient: HttpClient) {}


    fileClick(file){
        console.debug('file ->', file);
        this.onFileClick.emit(file);
    }

    toggleShow(toggle) {
        this.onFileShow.emit(toggle);
    }

    // change status of approval
    onAction(approval: any){
        this.working = true;
        this.onFileShow.emit(false);

        this.send(approval).subscribe(
            (data:any) => {
                console.debug('Approval saved', approval);
                this.approval.approval_status = ""; // hack
                this.working = false;
                this.onSaved.emit(approval.approved);
            },
            (error:any) => {
                console.debug('Error approval', error);
                this.working = false;
                this.error = true;
            }
        );


    }

    private send(approval: any) {
        let options = { headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};

        let url = this.getUrlAction(approval);
        let value = {
            message: approval.message
        };

        return this.httpClient.post<string>(url, value, options);
    }

    protected getUrlAction(approval: any) {
        let action = VersionApprovalComponent.ACTION_TRUE;
        if(!approval.approved) action = VersionApprovalComponent.ACTION_FALSE;

        return this.action + "/" + action + "/" + approval.version.id;
    }


}
