import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { errorsFullScreen } from '../errors-full-screen';


@Component({
    selector: 'core-layout-errors-full-screen',
    templateUrl: './errors-full-screen.component.html',
    styleUrls: ['./errors-full-screen.component.scss']
})
export class ErrorsFullScreenComponent implements OnInit {

    private allErrors = errorsFullScreen;
    private key:string = null;

    public error:any;

    constructor(public router: Router, public route: ActivatedRoute){}

    ngOnInit(){
        this.key = this.route.snapshot.paramMap.get("key");
        let err = this.allErrors.filter( e => this.key == e.key);
        err.length == 0 ? this.error = {text: 'An error has ocurred', code: ''} : this.error = err[0];
    }

}
