import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AppService } from '@app/services/app/app.service';

import { MenuItem,WorkflowMenuItem } from '@core/models/menu-item.model';

import * as Objects from '@core/objects';
import { workflowsMenu,workflowsDefaultItem } from './workflows.menu';
import { DashboardService } from 'app/services/app/dashboard.service';


@Component({
    selector: 'dashboard-workflows-menu-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class DashboardWorkflowsMenuMainComponent implements OnInit {
    @Input() workflows: Objects.Workflow[];
    @Input() view: string;
    
    public dashboardMenu: WorkflowMenuItem[] = workflowsMenu;
    public match: boolean = false; // to not match full uls on vetrical tabs

	constructor(
        public appSrv: AppService,
        private dashSrv: DashboardService
    ){}

	ngOnInit(){
        if(this.workflows)
            this.updateMenu()
    }
    // not used
    onMenuClick(item: WorkflowMenuItem ){
        // console.log(" LISTEN TO NEW WORKFLOW =====> ",item);

        // TODO: what should do HERE ???
        // this.dashSrv.setCurrentWorkflow(+item.workflow);
    }

    protected updateMenu(){
        // set ordered workflows in left menu
        this.dashboardMenu = this.workflows.sort((a, b) => a.id - b.id).map((workflow: Objects.Workflow) => {
            return {...workflowsDefaultItem, 
                        title: workflow.name,
                        link: workflowsDefaultItem.link.replace(':id', workflow.id.toString()+'/'+this.view),
                        workflow: workflow.id
                     }
        });
    }



}
