import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { LabelsModule } from '@core/components/labels/labels.module';
import { LayoutTitlesModule } from '@core/components/layout/titles/titles.module';
import { FilesModule } from '@core/components/files/files.module';
import { ProjectPreviewNotesMainComponent } from './main/main.component';
import { ProjectsDetailsInfoModule } from '../info/info.module';
import { ProjectsDetailsHelpersModule } from '../helpers/helpers.module';
import { FilesListModule } from '@core/components/files/lists/lists.module';

@NgModule({
    imports: [
        CommonModule,
        LayoutTitlesModule,
        LabelsModule,
        CoreModule,
        FilesModule,
        FilesListModule, // if not import this, it does not work with the import FilesModule
        ProjectsDetailsInfoModule,
        ProjectsDetailsHelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectPreviewNotesMainComponent
    ],
    exports: [
        ProjectPreviewNotesMainComponent
    ],

})
export class ProjectsDetailsPreviewNotesModule { }


