import { Component, Output, EventEmitter, OnInit } from '@angular/core';

import { File } from '@core/objects/file';
import { User } from '@core/objects/user';

import { AppService } from '@app/services/app/app.service';

@Component({
    selector: 'profile-avatar-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class ProfileAvatarUploadComponent implements OnInit{

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_FOUND = 2;

    @Output() cancel = new EventEmitter<any>();
    @Output() uploaded = new EventEmitter<File>();

	// constructor(){}

	ngOnInit(){
    }

    upload(files: Array<File>) {
        if(files.length > 0)
            this.uploaded.emit(files[0] );
    }

    uploading(event: any) {
        // console.log("uploading --->", event);
    }

    onCancel() {
        this.cancel.emit(true);
    }

}
