import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'core-helper-box-done-close-tab',
    templateUrl: './done-close-tab.component.html',
    styleUrls: ['./done-close-tab.component.scss']
})
export class HelperBoxDoneCloseTabComponent {
    @Input() type: string; // maybe never used
    @Input() text: string;
    @Input() message: string;

}
