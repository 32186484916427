import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PluginShareTransferMainAppComponent } from './components/main/main.component';

export const shareTransferAppRoutes : Routes = [
    {
        path: 'p/transfer/:request',
        // component: PluginApolloUnoComponent
        component: PluginShareTransferMainAppComponent,

    },
];

@NgModule({
  imports: [RouterModule.forChild(shareTransferAppRoutes)],
  exports: [RouterModule]
})
export class ShareTransferAppRouterModule { }
