export class HttpStatus {
    static readonly PENDING = 0;
    static readonly DONE = 1;
    static readonly ERROR = 2;


    public code: number = 1;
    public message: string = "";

    constructor(code: number = 1, message: string = "") {
        this.code = code;
        this.message = message;
    }
}
