import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LabelsModule } from '@core/components/labels/labels.module';

import { AppRouterModule } from '@app/router.module';

import { ProjectsAddModule } from './add/projects-add.module';
import { ProjectsDetailsModule } from './details/projects-details.module';
// import { AppVersionsModule } from '../versions/versions.module';


@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        HelpersModule,
        LabelsModule,
        ProjectsAddModule,
        ProjectsDetailsModule,
        // AppVersionsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
    ],
    exports: [
    ],

})
export class ProjectsModule { }
