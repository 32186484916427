import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRouterModule } from '@app/router.module';

import { SettingsVersionStatusMainComponent } from './main/main.component';
import { SettingsVersionStatusAddFormComponent } from './add-form/add-form.component';



@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SettingsVersionStatusMainComponent,
        SettingsVersionStatusAddFormComponent
    ],
    exports: [
        SettingsVersionStatusMainComponent,
        SettingsVersionStatusAddFormComponent
    ],

})
export class SettingsVersionStatusModule { }
