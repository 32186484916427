import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import * as Search from '@app/models/search';

import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

import { LibrarySearchService, LibraryService } from '@app/services/app/library.service';
import { AppService } from '@app/services/app/app.service';
import { VersionListItemMenuClick } from '@core/components/versions/list/version-list-item-menu-click';
import { AppRouterService } from '@app/services/app/router.service';
import { VersionsService } from '@app/services/app/versions.service';
import { AddProjectService } from '@app/services/app/add-project.service';

import { Session } from '@app/store/models/session.model';


@Component({
    selector: 'library-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})
export class LibraryResultsComponent implements OnInit, OnDestroy {

    public readonly ACCESS_ADD_PROJECT = "access_global_new_request";
    public readonly PLUGIN_REQUEST_FEEDBACK = 'share_feedback';

    public readonly SEARCH_STATUS = Search.SearchHttpStatus;

    @Input() params: Search.SearchQueryParams = null;
    
    public addProjectAccess: boolean = false;
    public feedbacksAccess: boolean = false;
    public term: string = '';
    public loadingMore: boolean = false;
    public searching: boolean = false;
    public hasSearch: boolean = false;
    public resultsStatus: number = Search.SearchHttpStatus.INITIAL;
    public excludedLabels: Objects.Entity[] = [];

    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "See Artwork Details",
            key: "details",
            icon: "see-details",
            order: 1
        },
        {
            title: "See Original Project",
            key: "see-project",
            icon: "see-project",
            order: 2
        },
        {
            title: "See Older Artworks",
            key: "see-older",
            icon: "see-older",
            order: 4
        }
    ];

    // optional menu item
    protected menuAddProject: ThreeDotsMenuItem = {
        title: "Start New Project",
        key: "add-project",
        icon: "add",
        order: 3
    };

    protected resultsSubscriptor: Subscription;
    protected termSubscription: Subscription;
    protected appliedFiltersSubscription: Subscription;
    protected resultsStatusSubscriptor: Subscription;

    items: Array<Objects.Version> = [];
    feedbacks: Array<VersionFeedback> = [];

	constructor(
        private app: AppService,
        private appRouter: AppRouterService,
        private librarySrv: LibraryService,
        private versionsSrv: VersionsService,
        private addProjectSrv: AddProjectService,
        private searchSrv: LibrarySearchService
    ){}

	ngOnInit(){
        this.resultsSubscriptor = this.librarySrv.selectResults().subscribe((versions: Objects.Version[]) => {
            this.loadingMore = false; // for stop loading more 
            this.items = versions; 
            this.searching = false;
            this.loadFeedbacks(versions);
        });

        this.resultsStatusSubscriptor = this.librarySrv.selectResultStatus().subscribe((rStatus: number) => {
            this.resultsStatus = rStatus;
        });
        // Fix for the HAS search we need to take into account the params allways to show the proper message
        this.appliedFiltersSubscription = this.searchSrv.selectFilters().subscribe((filters: Search.SearchAppliedFilter[]) => {
            this.hasSearch = (filters.length >0 || this.params != null)
            this.excludedLabels = filters.filter((item) => item.key == 'label').map((f)=> f.item);
            // console.log(" excluded areeee ",this.excludedLabels);
        });

        this.termSubscription = this.searchSrv.selectTerm().subscribe((term: string) => {
            this.hasSearch = (term !='' || this.params != null)
        } );

        // get q, global access and plugins access
        this.app.getSession().subscribe( (session: Session) => {
            this.feedbacksAccess = session.options.plugins.some((pa: string) => pa == this.PLUGIN_REQUEST_FEEDBACK);
            this.addProjectAccess = session.options.access.hasOwnProperty(this.ACCESS_ADD_PROJECT)? session.options.access[this.ACCESS_ADD_PROJECT] : false;
            if(this.addProjectAccess){
                this.addToMenu(this.menuAddProject);
            }
            // let savedParams = null;
            // console.log("params in results--->", this.params, session.options.custom.library_search);
            if(this.params==null) 
                this.params = session.options.custom.library_search!=null? session.options.custom.library_search : this.params;

            this.librarySrv.init(this.params);

            
        });

        
	}

    ngOnDestroy() {
        this.resultsSubscriptor.unsubscribe();
        this.termSubscription.unsubscribe();
        this.appliedFiltersSubscription.unsubscribe();
    }

    loadMore() {
        this.loadingMore = true;
        this.librarySrv.loadResults();
    }

    onMenuClick(menu: VersionListItemMenuClick) {
        switch(menu.key) {
            case "details":
                // go to version Details
                if(menu.version != null)
                    this.appRouter.navigate("/versions/"+menu.version.id);
                break;
            case "see-project":
                // TODO navigate
                if(menu.version != null){
                    this.versionsSrv.getProject$(menu.version).subscribe((originalProject: Objects.Request) => {
                        if(originalProject != null)
                            this.appRouter.navigate('/projects/'+originalProject.id);
                    });
                }
                break;
            case "add-project":
                if(menu.version != null){
                    this.addProjectSrv.clear();
                    this.appRouter.navigate('/projects/add', { queryParams: { version: menu.version.id } });
                }
                break;
            case "see-older":
                if(menu.version != null){
                    this.appRouter.navigate('/versions/'+menu.version.id+"/related");
                }
                break;
        }
    }

    onFileClick(version: Objects.Version,file: Objects.File){
        if (file != null && version != null){
            // set this url to preview version FILE
            this.appRouter.navigate("/versions/"+version.id + "/files/"+file.id);
        }
    }

    onScroll(){
        if(this.resultsStatus ==  Search.SearchHttpStatus.DONE ){
            this.loadMore();
        }
    }

    protected addToMenu(item: ThreeDotsMenuItem) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        this.menu.push(item);
    }

    private loadFeedbacks(versions: Array<Objects.Version>) {
        if (!this.feedbacksAccess)
            return;
        // here we got unique version ids that already have feedbacks loaded
        let vWithFeedbacks = this.feedbacks.map((f: VersionFeedback) => f.version.id).filter((x, i, a) => a.indexOf(x) === i);
        // here we got versions to load that are not already loaded.
        let vdiff = versions.filter((v: Objects.Version) => vWithFeedbacks.indexOf(v.id) == -1);

        if (vdiff.length == 0)
            return;
        // here we call only for the new versions on the results
        this.versionsSrv.getFeedbacks$(vdiff).subscribe((feedbacks: VersionFeedback[]) => {
            this.feedbacks = [...this.feedbacks, ...feedbacks];
        });
    }

    public getFeedbacks(version: Objects.Version) : VersionFeedback[] {
        return this.feedbacks.filter((f: VersionFeedback) => f.version.id == version.id);
    }

}
