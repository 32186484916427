import { Component } from '@angular/core';

@Component({
    selector: 'core-preview-waiting',
    templateUrl: './preview-waiting.component.html',
    styleUrls: ['./preview-waiting.component.scss'],
    providers: []
})
export class PreviewWaitingComponent {

}
