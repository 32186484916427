import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import * as Objects from '@core/objects';
import { SearchFilterMenuItem } from '@app/components/helpers/search/filters/models/filters-menu';
import { SearchAppliedFilter, SearchAvailableFilters } from '@app/models/search';

import { DashboardFiltersService, DashboardSearchService } from 'app/services/app/dashboard.service';

@Component({
    selector: 'dashboard-search-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class DashboardSearchFiltersComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    public status: number = 0;
    private wasInside: boolean = false;

    public selectedFilter: string = '';

    // menu
    public filtersMenu: Array<SearchFilterMenuItem> = [
        {
            title: 'Filter by Tag',
            key: 'label',
            order: 1
        },{
            title: 'Filter by Project Stage',
            key: 'status',
            order: 2
        },{
            title: 'Filter by Assignee',
            key: 'user_assigned',
            order: 3
        },{
            title: 'Filter by Owner',
            key: 'user_owner',
            order: 4
        }
    ];

    public availableLabels: SearchAvailableFilters = null;
    public availableAsignees: SearchAvailableFilters = null;
    public availableOwners: SearchAvailableFilters = null;
    public availableStatus: SearchAvailableFilters = null;
    public appliedFilters: SearchAppliedFilter[] = [];

    private labelsSubscription: Subscription;
    private usersSubscription: Subscription;
    private ownersSubscription: Subscription;
    private statusSubscription: Subscription;
    private appliedFiltersSubscription: Subscription;

    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }
  
    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.selectedFilter = '';
        }
        this.wasInside = false;
    }

	constructor(
        private filtersSrv: DashboardFiltersService,
        private searchSrv: DashboardSearchService
    ){}

	ngOnInit(){

        this.labelsSubscription = this.filtersSrv.selectLabels().subscribe((filter: SearchAvailableFilters) => this.availableLabels = filter);
        this.usersSubscription = this.filtersSrv.selectAsignees().subscribe((filter: SearchAvailableFilters) => this.availableAsignees = filter);
        this.ownersSubscription = this.filtersSrv.selectOwners().subscribe((filter: SearchAvailableFilters) => this.availableOwners = filter);
        this.statusSubscription = this.filtersSrv.selectStatus().subscribe((filter: SearchAvailableFilters) => this.availableStatus = filter);

        this.appliedFiltersSubscription = this.searchSrv.selectFilters().subscribe((filters: SearchAppliedFilter[]) => this.appliedFilters = filters);
       
    }

    ngOnDestroy() {
        this.labelsSubscription.unsubscribe();
        this.usersSubscription.unsubscribe();
        this.ownersSubscription.unsubscribe();
        this.statusSubscription.unsubscribe();
        this.appliedFiltersSubscription.unsubscribe();
    }

    onSelectFilter(key: string){
        this.selectedFilter = key;
    }

    onApplyFilter(filter: SearchAppliedFilter){
        this.searchSrv.applyFilter(filter);
    }

}
