import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';

import { VersionsViewService } from '@app/services/app/versions.service';

import * as Objects from '@core/objects';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';


@Component({
    selector: 'version-breadcrumb-header',
    templateUrl: './version-breadcrumb-header.component.html',
    styleUrls: ['./version-breadcrumb-header.component.scss']
})
export class VersionBreadcrumbHeaderComponent implements OnInit, OnChanges {
    @Input() version: Objects.Version = null;
    @Input() exclude: Array<Objects.Label> = []; // those are the tags that don't go after + under header
	@Input() title: string = '';
    @Input() alertText: string = 'The tags of all uploaded Artworks will be modified.';
	@Input() bulkEdition: boolean = false;
	@Input() breadcrumbs: any = [];
	@Input() menu: Array<ThreeDotsMenuItem> = [];
    @Input() status: Array<Objects.Status> = [];
    @Output() action = new EventEmitter<string>();
    @Output() statusAction = new EventEmitter<Objects.Status>();
    @Output() setLabels = new EventEmitter<Objects.Label[]>();

    public edit: boolean = false;
    public matchLabels: Array<Objects.Label> = [];
    public diffLabels: Array<Objects.Label> = [];
    public futureStatus: Array<Objects.Status> = [];

    constructor( private versionsSrv: VersionsViewService) { }


    ngOnInit() {
        // Here we extract exclusive version labels to put separated in header
        this.setFutureStatus();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.version){
            this.updateDiffLabels();
            this.setFutureStatus();
        }    
        if(changes.status){
            this.setFutureStatus();
        }
}

    private setFutureStatus() {
        this.futureStatus = this.status.filter((s: Objects.Status) => s.id != this.version.status.id);
    }

    private updateDiffLabels() {
        if(this.version == null || this.exclude == null)
            return;
        // labels after + are the exclude 
        this.matchLabels = this.version.labels.filter((label: Objects.Label) => !this.exclude.some((l) => l.id == label.id));
        this.diffLabels = this.version.labels.filter((label: Objects.Label) => this.exclude.some((l) => l.id == label.id));
    }
    onMenuSelect(key: string) {
        if(key == 'edit'){
            this.toggleEdit();
            return;
        }
        this.action.emit(key);
    }

    onStatusMenuSelect(status: Objects.Status){
        this.statusAction.emit(status);
    }

    toggleEdit(){
        this.edit = !this.edit;
    }

    onSetLabels(labels: Array<Objects.Label>){
        this.setLabels.emit(labels);
    }

}
