export interface PasswordValidation {
    length: boolean;
    uppercase: boolean;
    number: boolean;
    special: boolean;
};

export class PasswordValidatorController {
     readonly MIN_LENGTH = 5;
     readonly NUMBERS_PATTERN = /([0-9])/g;
     readonly UPPERCASE_PATTERN = /([A-Z])/g;
     readonly SPECIAL_PATTERN = /([\-!@#$%^&*(),.?":{}|<>])/g;

    validate(value: string) : PasswordValidation {
        return {
            length: value.length > this.MIN_LENGTH,
            uppercase: value.match(this.UPPERCASE_PATTERN)? true : false,
            number: value.match(this.NUMBERS_PATTERN)? true : false,
            special: value.match(this.SPECIAL_PATTERN)? true : false,
        }
    }
}
