import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MenuItem } from '@core/models/menu-item.model';

import { ApolloRequest } from '../../../model/apollo-request.model';
import { ApolloHistory, apolloHistoryInit } from '../../../model/apollo-history.model';
import { ApolloItem } from '../../../model/apollo-item.model';

import { apolloMenu } from '../../../apollo.menu';
import { HttpStatus } from '@core/classes/http-status.class';

/*export class ApolloStatus {
    extract: HttpStatus = new HttpStatus();
    compare: HttpStatus = new HttpStatus();
};*/

export interface Apollo {
    tabs: Array<MenuItem>;
    history: ApolloHistory;
    request: ApolloRequest;
    reference:any;
    target:any;
    //status: ApolloStatus;
}



export const initialState: Apollo = {
    tabs: apolloMenu,
    history: apolloHistoryInit,
    request: new ApolloRequest,
    reference: null,
    target: null,
//    status: new ApolloStatus()
};

const apolloFeatureSelector = createFeatureSelector('apollo');


// Selectors
export const apolloSelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state
);

export const apolloTabsSelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state.tabs
);

export const apolloRequestSelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state.request
);

export const apolloReferenceSelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state.request.reference
);

export const apolloTargetSelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state.request.target
);



export const apolloFileTargetSelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state.target
);

export const apolloFileReferenceSelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state.reference
);


export const apolloGraphicCompareSelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state.request.apollo_graphic
);

export const apolloContentCompareSelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state.request.apollo_content
);

export const apolloHistorySelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state.history
);

/*export const apolloContentStatusSelector = createSelector(
    apolloFeatureSelector,
    (state: Apollo) => state.status
)*/
