import { Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'core-helpers-forms-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
})
export class InputCheckboxComponent implements OnInit {

    @Input() disabled: boolean;
    @Input() label: string = "";
    @Input() name: string;
    @Input() form: FormGroup;
    @Input() defaultChecked: boolean = false;


    @Output() checkedChange = new EventEmitter<boolean>();
    public checked: boolean = false;
    public checkControl: FormControl;

    constructor(private fb: FormBuilder) { }

    ngOnInit() {
        this.init();
    }

    toogleCheck() {
        console.debug(" toogleCheck on ",this.name, this.checked);
        this.checked = !this.checked;
        this.checkControl.setValue(this.checked);
        this.checkedChange.emit(this.checked);
    }

    init() {
        this.checked = this.defaultChecked;
        this.checkControl = new FormControl(this.defaultChecked);
        this.form.addControl(this.name,this.checkControl);

    }
}
