import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatus } from '@core/classes/http-status.class';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';

export enum LabelsActionType {

	// EVENT CALLS
	SearchLabels = "[Labels] GET - Search labels by string",
    SearchLabelsSuccess = "[Labels] GET - Search labels by string SUCCESS",
    SearchLabelsError = "[Labels] GET - Search labels by string ERROR",

    LoadCategories = "[Labels] GET - Find labels categories",
    LoadCategoriesSuccess = "[Labels] GET - Find labels categories SUCCESS",
    LoadCategoriesError = "[Labels] GET - Find labels categories ERROR",

    LoadAccess = "[Labels] GET - get access to labels edit",
    LoadAccessSuccess = "[Labels] GET - get access to labels edit SUCCESS",
    LoadAccessError = "[Labels] GET - get access to labels edit ERROR",

    AddLabel = "[Labels] POST - Add label to category",
    AddLabelSuccess = "[Labels] POST - Add label to category SUCCESS",
    AddLabelError = "[Labels] POST - Add label to category ERROR",

    Clear = "[Labels] clear data"
}

// Load Available Events
export class SearchLabels implements Action {
    readonly type = LabelsActionType.SearchLabels;
    constructor(public input: string) {}
}

export class SearchLabelsSuccess implements Action {
    readonly type = LabelsActionType.SearchLabelsSuccess;
    constructor(public labels: Array<Objects.Label>) {}
}

export class SearchLabelsError implements Action {
    readonly type = LabelsActionType.SearchLabelsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadCategories implements Action {
    readonly type = LabelsActionType.LoadCategories;
    constructor() {}
}

export class LoadCategoriesSuccess implements Action {
    readonly type = LabelsActionType.LoadCategoriesSuccess;
    constructor(public categories: Array<Objects.LabelsGroup>) {}
}

export class LoadCategoriesError implements Action {
    readonly type = LabelsActionType.LoadCategoriesError;
    constructor(public error: HttpErrorResponse) {}
}

export class AddLabel implements Action {
    readonly type = LabelsActionType.AddLabel;
    constructor(public name: string, public category: Objects.LabelsGroup) {}
}

export class AddLabelSuccess implements Action {
    readonly type = LabelsActionType.AddLabelSuccess;
    constructor(public label: Objects.Label) {}
}

export class AddLabelError implements Action {
    readonly type = LabelsActionType.AddLabelError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadAccess implements Action {
    readonly type = LabelsActionType.LoadAccess;
    constructor() {}
}

export class LoadAccessSuccess implements Action {
    readonly type = LabelsActionType.LoadAccessSuccess;
    constructor(public access: Access.AccessLabels) {}
}

export class LoadAccessError implements Action {
    readonly type = LabelsActionType.LoadAccessError;
    constructor(public error: HttpErrorResponse) {}
}

export class Clear implements Action {
    readonly type = LabelsActionType.Clear;
    constructor() {}
}

export type LabelsActions = 	SearchLabels |
                                SearchLabelsSuccess |
                                SearchLabelsError |
                                LoadCategories |
                                LoadCategoriesSuccess |
                                LoadCategoriesError | 
                                AddLabel |
                                AddLabelSuccess |
                                AddLabelError | 
                                LoadAccess |
                                LoadAccessSuccess |
                                LoadAccessError | 
                                Clear
                                ;
