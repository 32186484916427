import { ApolloCompare } from './apollo-compare.model';
/**
 * Object for history
 **/
export interface ApolloHistory {
    last: boolean;
    items: Array<ApolloCompare>;
}

export const apolloHistoryInit = {
    last: false,
    items: []
}
