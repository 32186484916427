import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabelsModule } from '@core/components/labels/labels.module';
import { StatusModule } from '@core/components/status/status.module';
//import { CoreModule } from '@core/core.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { CommonsModule } from '@core/components/commons/commons.module';

import { VersionFilesListCheckboxComponent } from './list-checkbox/list-checkbox.component';
@NgModule({
    imports: [
        CommonModule,
        LabelsModule,
        StatusModule,
        HelpersModule,
        CommonsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionFilesListCheckboxComponent
    ],
    exports: [
        VersionFilesListCheckboxComponent
    ],

})
export class VersionFilesModule { }
