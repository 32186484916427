import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import * as PusherTypes from 'pusher-js';

import { WebsocketService } from '@core/services/websocket/websocket.service';
import * as Objects from '@core/objects';

import { DashboardService } from './dashboard.service';
import { LibraryService } from './library.service';
import { ProjectsService } from './projects.service';
import { VersionsViewService } from './versions.service';
import { App } from 'app/store/state/app/app.state';
import { AppService } from './app.service';
import { Session } from 'app/store/models/session.model';

// class SessionPackage {
//     constructor(private session: Session){
//         console.log("session ---->", session);
//     }

//     empty = () : boolean => this.session == null;

//     organization = () : Objects.Organization => this.session.organization;

//     user = () : Objects.User => this.session.user;

//     options = () : any => this.session.options;

//     configuration = (): any => this.session.configuration; 
// }

@Injectable({
    providedIn: 'root'
})
export class AppBehaviorService {
    private usrChannel: PusherTypes.Channel;
    private orgChannel: PusherTypes.Channel;

    private sessionSubscriptor: Subscription;
    private organizationSubscriptor: Subscription;


    // private session: SessionPackage = null;
    private session: Session = null;
    private organization: number = null;

    constructor(
        private appSrv: AppService,
        private dashSrv: DashboardService,
        private libSrv: LibraryService,
        private prjSrv: ProjectsService,
        private verSrv: VersionsViewService,
        private websocket: WebsocketService
    ) {}
    
    start() {
        // this.appSubscriptor = this.appSrv.selectApp().subscribe((app: App) => this.init(app));
        this.sessionSubscriptor = this.appSrv.getSession().subscribe((session: Session) => this.init(session));
        
        this.appSrv.init();
    }

    end() {
        // unbind channels
        this.websocket.unbind(this.usrChannel);
        this.websocket.unbind(this.orgChannel);

        if(this.sessionSubscriptor != null)
            this.sessionSubscriptor.unsubscribe();

        if(this.organizationSubscriptor != null)
            this.organizationSubscriptor.unsubscribe();
        
        this.session = null;
    }

    private init(session: Session) {

        // first time
        if(session == null)
            return;
        
        // check if change org
        if(this.session !== null && this.session.organization === session.organization)
            return;
        
        this.session = session;

        let config = this.session.configuration;
        this.websocket.connect(config.websocket.pusher.key, config.websocket.pusher.cluster);

        this.websocket.unbind(this.usrChannel);
        this.usrChannel = this.websocket.addChannel('private-usr_'+this.session.user.id);

        this.websocket.unbind(this.orgChannel);
        this.orgChannel = this.websocket.addChannel('private-org_'+this.session.organization.id);

        this.general();
        this.dashboard();
        this.library();

        console.log("App behavior initialitzed");

        // Websocket disconnect
        this.websocket.getState$().subscribe(state => {
            if(state.current == 'unavailable'){
                this.appSrv.disconnect();
            }
        })
    }

    // private initOrganization(org: number) { 

    // }

    private general() {
        this.usrChannel.bind('pusher:subscription_error', status => this.appSrv.setHttpError(status));
    }

    private dashboard() {
        // this.dashSrv.reset();
        this.dashSrv.loadWorkflows();
        this.dashSrv.start(this.session.options.custom.dashboard_search, "session");
        this.orgChannel.bind('request.updated', data => {
            this.dashSrv.updateProject(+data.request, data.source_event);
            this.prjSrv.update(+data.request, data.source_event);
        });
    }

    private library() {
        // this.libSrv.reset();
        // TODO: manage to bind version updated ws events (source_event is not coming)
        this.orgChannel.bind('version.updated', data => {
            this.libSrv.updateVersion(+data.version);
            this.verSrv.update(+data.version);
        });
    }

    // dispatch change org
    changeOrganization(org: number)
    {
        this.dashSrv.reset();
        this.libSrv.reset();
        this.appSrv.changeOrganization(org);
    }

}