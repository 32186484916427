import { Component, OnInit, OnDestroy } from '@angular/core';
import {formatDate} from '@angular/common';
import { Subscription } from 'rxjs';

import * as Objects from '@core/objects';

import { ProjectsService, ProjectsEditorService } from '@app/services/app/projects.service';


@Component({
    selector: 'project-details-info-features',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectDetailsInfoFeaturesComponent implements OnInit, OnDestroy { 
    private readonly DEFAULT_DUE_TEXT = "Set Due Date";
    private readonly DEFAULT_DUE_ICON = "calendar";

    protected subscriptor: Subscription;
    protected eAssignersSubscriptor: Subscription;

    public project: Objects.Request = null;
    public assigners: Array<Objects.User> = [];
    public showCalendar: boolean = false;
    public due: any = {
        icon: this.DEFAULT_DUE_ICON, // not used
        text: this.DEFAULT_DUE_TEXT
    };

	constructor(
        private projectsSrv: ProjectsService,
        private editorSrv: ProjectsEditorService
    ){}

	ngOnInit(){ 
        this.subscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.Request ) => {
            if(project != null){
                this.project = project;
                this.setDuedate();
            }
        });

        this.eAssignersSubscriptor = this.editorSrv.selectAssigners().subscribe((users: Objects.User[]) => {
            // console.debug("asigneersssss->", users);
            this.assigners = users;
        })
	}

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
        this.eAssignersSubscriptor.unsubscribe();
    }

    onSelectAssignee(user: Objects.User) {
        this.projectsSrv.setAssign(user);
    }

    public toggleCalendar(){
        this.showCalendar = !this.showCalendar;
    }

    public onSelectDate(date: string){
        this.projectsSrv.setDue(date);
    }

    public onClose(){
        this.showCalendar = false;
    }

    protected setDuedate() {
        let today: Date = new Date();
        today.setHours(0,0,0,0);

        if(this.project.due_date == null) {
            this.due = {
                icon: this.DEFAULT_DUE_ICON,
                text: this.DEFAULT_DUE_TEXT
            };

            return;
        } 
       
        let dueDate: Date = new Date(this.project.due_date);
        dueDate.setHours(0,0,0,0);
        let formattedDate = formatDate(dueDate,'dd MMM yyyy', 'en_US');
        this.due.text = formattedDate;

    }

}