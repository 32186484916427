import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// import { HttpStatus } from '@core/classes/http-status.class';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AccessToken } from '@core/objects/access-token';

import { AccessTokensService } from '../../../../../services/settings/access-tokens.service';
import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

@Component({
    selector: 'settings-access-tokens-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SettingsAccessTokensMainComponent implements OnInit{
    private readonly ACCESS = 'access_setting_tokens_access';

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

    public accessTokens: Array<AccessToken>;
	public showForm:boolean = false;
	public access:boolean = true;
    public status: number = 0;
    public breadcrumbs: any = [
        {
            title: "System Settings",
            link: ['/settings']
        }
    ];

    // TODO review menu icons
    public menu: Array<ThreeDotsMenuItem> = [{
        title: "Add new Access Token",
        key: "add-token",
        icon: "add",
        order: 1
    }];

	constructor(public accessTokensSrv: AccessTokensService, public app: AppService, private title: TitleService){}

	ngOnInit(){
        this.title.set("API Tokens - System settings")
        this.app.getSession().subscribe( (session: any) => {
            // this.session = session;
            this.access = session.options.access.hasOwnProperty(this.ACCESS)? session.options.access[this.ACCESS] : false;
            // this.licensed = this.session.options.license !== null;
            // this.loaded = true;
        });
        console.log(" ========  loading tokens settings access tokens",this.access);
        // this.form =  new FormGroup({});
        if (this.access)
            this.load();
	}

    onSaved(event){
        this.load();
    }

	protected load(){
        this.accessTokensSrv.getAccessTokens$().subscribe((tokens: Array<AccessToken>) => {
            this.accessTokens = tokens;
            console.log(" result tokens",tokens)
            this.status = this.STATUS_DONE;
        });

	}

    remove(accessToken: AccessToken) {
        console.log(" todoooo deactivate...",accessToken);
        this.accessTokensSrv.deleteAccessToken$(accessToken).subscribe(data => {
            console.log(" result delete token",data);
            this.load();
        });
    }

    onMenuClick(key: string) {
        switch(key) {
            case "add-token":
                this.showForm = !this.showForm;
                break;
        }
    }

}
