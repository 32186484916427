import { Component, OnInit, OnDestroy, Input,Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import { LabelsGroup } from '@core/objects/labels-group';

import { RulesService } from '../../../../../services/settings/rules.service';
import { SettingsRulesService } from '@app/services/settings/srules.service';

import { AvailableRule } from '@app/store/state/settings/rules/rules.state';

import { AppService } from '@app/services/app/app.service';
import { TitleService } from '@core/services/title.service';

import { RuleListItem } from '@app/models/rules';


@Component({
    selector: 'settings-rules-rule-list',
    templateUrl: './rule-list.component.html',
    styleUrls: ['./rule-list.component.scss']
})
export class SettingsRulesRuleListComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;

    @Input() availableEvents: Array<AvailableRule>;
    @Output() selectRule = new EventEmitter<any>();
    public rules: Array<RuleListItem>;
    public selectedRule: any = null;
	public showForm:boolean = false;
	public access:boolean = true;
    // public status: number = 0;
    public status: number = 0; //TODO change to 0

    protected rulesSubscriptor: Subscription;

	constructor(public srulesSrv: SettingsRulesService){}

	ngOnInit(){
        this.rulesSubscriptor = this.srulesSrv.selectRules().subscribe((rules: RuleListItem[]) => { 
            this.rules = rules;
            this.status = this.rules.length > 0? this.STATUS_DONE : this.STATUS_LOADING;
        });

        this.loadRules();
	}

    // ngOnChanges(changes: SimpleChanges){
    //     if(changes.avalaibleEvents)
    //         this.loadRules();
    // }

    ngOnDestroy() {
        this.rulesSubscriptor.unsubscribe();
    }

    protected loadRules(){
        this.srulesSrv.loadRules();
        // this.rulesSrv.findRules$().subscribe((rules: Array<any>) => {
        //     this.rules = rules.map((rule: any) => {
        //         rule.description = this.getEvent(rule.key)
        //         return rule });
        //     console.log(" result rules", this.rules)
        //     this.status = this.STATUS_DONE;
        // });
    }

    getDescription(eventKey: string) {
        let event = this.availableEvents.filter((r: AvailableRule) => r.event.key == eventKey)
        if(event.length>0)
            return event[0].event.description;
        
        return "Event deprecated!"
    }

    // protected getEvent(key: string){
    //     // todo: get event description by key
    //     let event = this.availableEvents.filter((event: any) => event.value == key)
    //     if(event.length>0)
    //         return event[0].title;
    //     // if note even defined/loaded return key only
    //     return key;
    // }


    onSelectRule(rule: any){
        if(!rule.active)
            return;

        this.selectedRule = rule;
        this.selectRule.emit(rule.id)
    }

    deactivate(rule: RuleListItem) {
        this.srulesSrv.deactiveRule(rule.id);
    }

    activate(rule: any) {
        this.srulesSrv.activeRule(rule.id);
    }


}
