import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UsersService } from '@app/services/settings/users.service';
import { GroupsService } from '@app/services/settings/groups.service';
import { Group } from '@core/objects/group';
import { HttpStatus } from '@core/classes/http-status.class';
import { MultiSelectItem } from '@core/models/select-item.model';

@Component({
    selector: 'settings-users-invitations-add',
    templateUrl: './invitations-add.component.html',
    styleUrls: ['./invitations-add.component.scss']
})
export class SettingsUsersInvitationsAddComponent implements OnInit, OnDestroy{
    @Input() extra: any = {};

	public form: FormGroup;
	public groups: MultiSelectItem[] = null;
	public selectedGroup = [];

	public textError:string = "";

	public subscriptionGetGroups: Subscription;
	public subscriptionStatusGetGroups: Subscription;
	public subscriptionStatusAddInv: Subscription;

	public buttonDisabled:boolean = false;

	public httpStatusGetGroups:HttpStatus;
	public httpStatusAddInvite:HttpStatus;

	public errorAddInvite :boolean = false;

	public errorLowerCase:boolean = false;

	@Output() cancelForm = new EventEmitter<any>();
	@Output() newInvitation = new EventEmitter<any>();

	constructor(public userSrv: UsersService, public groupsSrv: GroupsService, private formBuilder: FormBuilder){}

	ngOnInit(){
		this.form = this.formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', Validators.compose([
				Validators.required,
				Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
			 ])],
			message: ['']
		});


		this.subscriptionGetGroups = this.groupsSrv.selectorGetAllGroups().subscribe((groups: Group[]) => {
			if (groups != null) {
				this.createSelectGroup(groups);
			} else {
				this.groupsSrv.loadAllGroups();
			}
		});
		this.subscriptionStatusGetGroups = this.groupsSrv.selectorStatusGroups().subscribe((status: HttpStatus) => {
			this.httpStatusGetGroups = status;
		});

		this.subscriptionStatusAddInv = this.userSrv.selectorStatusAddInvite().subscribe((status: HttpStatus) => {
			this.httpStatusAddInvite = status;
			switch (this.httpStatusAddInvite.code) {
				case 0:
					// Loading
					this.buttonDisabled = true;
					break;
				case 1:
					// Success
					this.buttonDisabled = false;
					this.errorAddInvite = false;
					this.cleanForm();
					break;
				case 2:
					// Error - TODO type Error
					this.errorAddInvite = true;
					this.buttonDisabled = false;
					this.controlErrors();
					break;
			}
		});
	}

	ngOnDestroy(){
		this.subscriptionGetGroups.unsubscribe();
		this.subscriptionStatusAddInv.unsubscribe();
		this.subscriptionStatusGetGroups.unsubscribe();
	}

	checkUpperCase(event){
		(event.target.value.toLowerCase() == event.target.value) || event.target.value === '' ? this.errorLowerCase = false : this.errorLowerCase = true;
	}

	controlErrors(){
		console.log(this.httpStatusAddInvite.message);
		switch (this.httpStatusAddInvite.message){
			case "403":
				this.textError = "You have no permissions to perform this action. Try cancelling and starting again, or contact support"
				break;
			case "409":
				this.textError = "Oops! It looks like this user is already a member or that an invitation is still pending"
				break;
			default:
				this.textError = "An error has occurred"
				break;
		}
	}

	createSelectGroup(groups){
		// var newSelect: SelectItem[] = [];
		// var groupSelect;

		let newSelect = groups.map(g => {
			return {
				title: g.name,
				value: g.id,
				selected: false,
				disabled: false
			};
		});

		if(newSelect.length > 0)
			newSelect[0].selected = true;
		// for (let group of groups){
		// 	if (newSelect.length == 0) {
		// 		groupSelect = {
		// 			title: group.name,
		// 			value: group.id,
		// 			selected: true
		// 		}
		// 	} else {
		// 		groupSelect = {
		// 			title: group.name,
		// 			value: group.id,
		// 			selected: false
		// 		}
		// 	}
		// 	newSelect.push(groupSelect);
		// }
		this.groups = newSelect;

		// this.selectedGroup.push(this.groups[0].value);   //ONLY SIMPLE SELECT
	}

	setGroupSelected(groups){
		if(groups instanceof Array){
			this.selectedGroup = groups;
		} else {
			this.selectedGroup.push(groups.value);
		}
	}

	onSubmit(){
		if(this.form.invalid)
			return;

		var message = "";
		if(this.form.value.message != null) {
			message = this.form.value.message;
		}
		let data = {
			name: this.form.value.name,
			email: this.form.value.email,
			message: message,
			groups: this.selectedGroup,
            extra: this.extra
		};

		this.newInvitation.emit(data);
	}

	cleanForm(){
		this.selectedGroup = [];
		this.form.reset({value: ''});
	}

	cancel(){
		let cancel = true;
		this.errorAddInvite = false;
		this.cleanForm();
		this.cancelForm.emit(cancel);
	}

	tryAgain(){
		this.groupsSrv.loadAllGroups();
	}
}
