import { Component, OnInit, Output, EventEmitter } from '@angular/core';
// import { ViewportScroller } from '@angular/common';
// import { UsersService } from '../../../../../services/settings/users.service';
import { GroupsService } from '../../../../../services/settings/groups.service';
import { Subscription } from 'rxjs';
// import { HttpStatus } from '@core/classes/http-status.class';

// import { License } from '@core/objects/license';
import { User } from '@core/objects/user';
import { Group } from '@core/objects/group';
// import { UserRequest } from '@core/objects/user-request';

@Component({
    selector: 'settings-groups-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class SettingsGroupsListComponent implements OnInit{

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

	@Output() select = new EventEmitter<Group>();

	public status: Number = 0;
    public subscriptionGroups: Subscription;
    

	public groups: Array<Group> = [];

	constructor(public grpSrv: GroupsService){}

	ngOnInit(){
        this.grpSrv.loadAllGroups();

        this.subscriptionGroups = this.grpSrv.selectorGetAllGroups().subscribe((groups: Group[]) => {
			this.groups = groups;
			if(groups !== null) {
				this.status = this.STATUS_DONE;
				// this.loadInactiveGroups();
			}
				
			// this.userSrv.loadAllLicenses();
        });
	}

	// loadInactiveGroups() {
	// 	this.grpSrv.findInactiveGroups().subscribe((groups: Group[]) => this.groups.push(...groups));
	// }

	onSelect(group: Group) {
		if(group.active)
			this.select.emit(group);
	}

	remove(group: Group) {
		// console.log("grooupp-->", group)
		if(group.active)
			this.grpSrv.deleteGroup(group);
		else
			this.grpSrv.activeGroup(group);
	}
}
