import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, empty, of, throwError, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import * as Objects from '@core/objects';

import * as Access from '@app/models/access';
import * as Search from '@app/models/search';

import { DashboardRequests } from './dashboard.requests';

import * as fromStore from './dashboard.state';
import * as fromActions from './dashboard.actions';

@Injectable()
export class DashboardEffects {

    constructor(private actions$: Actions, private store$: Store<fromStore.Dashboard>, private requests: DashboardRequests) {}

    @Effect()
    public initParams$ = this.actions$.pipe(
        ofType<fromActions.InitParams>(fromActions.DashboardActionType.InitParams),
        withLatestFrom(this.store$.select(fromStore.startingSelector)),
        switchMap(([action, starting]) => {
            if(starting.initialized)
                return [];
            
            if(!starting.contexts.url.loaded || !starting.contexts.session.loaded)
                return [];
            
            let params = starting.contexts.url.value != null? starting.contexts.url.value : starting.contexts.session.value;

            // console.error("params--->", params, starting);
            return [new fromActions.Init(params)];
        })
    );

    @Effect()
    public init$ = this.actions$.pipe(
        ofType<fromActions.Init>(fromActions.DashboardActionType.Init),
         //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => {
            let act: Array<any> = [
                new fromActions.LoadWorkflows(),
                new fromActions.LoadFilters(),
                // new fromActions.LoadResults()
            ];
            // if(action.params.hasOwnProperty("workflow")) {
            //     act.push(new fromActions.SetCurrentWorkflow(action.params['workflow']));
            // }

            return act;

            // new fromActions.LoadWorkflows()
        })
    );

    @Effect()
    public loadWorkflows$: Observable<fromActions.DashboardActions> = this.actions$.pipe(
        ofType<fromActions.LoadWorkflows>(fromActions.DashboardActionType.LoadWorkflows),
        switchMap((action) => {
            return this.requests.workflows$().pipe(
                map((workflows: Objects.Workflow[]) => {
                    return new fromActions.LoadWorkflowsSuccess(workflows);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadWorkflowsError(err));
                })
            );
        })
    );

    @Effect()
    public loadWorkflowsSuccess$ = this.actions$.pipe(
        ofType<fromActions.LoadWorkflowsSuccess>(fromActions.DashboardActionType.LoadWorkflowsSuccess),
        withLatestFrom(this.store$.select(fromStore.workflowsSelector)),
        switchMap(([action, workflows]) => [
            ...workflows.items.map((w: Objects.Workflow) => new fromActions.LoadFilterStatus(w)),
            // ...[new fromActions.LoadResults()]
        ])
    );

    @Effect()
    public loadFilters$ = this.actions$.pipe(
        ofType<fromActions.LoadFilters>(fromActions.DashboardActionType.LoadFilters),
         //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            new fromActions.LoadFilterLabels(),
            new fromActions.LoadFilterUsers()
        ])
    );

    @Effect()
    public loadFilterStatus$: Observable<fromActions.DashboardActions> = this.actions$.pipe(
        ofType<fromActions.LoadFilterStatus>(fromActions.DashboardActionType.LoadFilterStatus),
        mergeMap((action) => {
            return this.requests.filterStatus$(action.workflow).pipe(
                map((status: Objects.Status[]) => {
                    return new fromActions.LoadFilterStatusSuccess(action.workflow, status);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadFilterStatusError(action.workflow, err));
                })
            );
        })
    );

    @Effect()
    public loadFilterLabels$: Observable<fromActions.DashboardActions> = this.actions$.pipe(
        ofType<fromActions.LoadFilterLabels>(fromActions.DashboardActionType.LoadFilterLabels),
        switchMap((action) => {
            return this.requests.filterLabels$().pipe(
                map((labels: Objects.Label[]) => {
                    return new fromActions.LoadFilterLabelsSuccess(labels);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadFilterLabelsError(err));
                })
            );
        })
    );

    @Effect()
    public loadFiltersLabelsSuccess$ = this.actions$.pipe(
        ofType<fromActions.LoadFilterLabelsSuccess>(fromActions.DashboardActionType.LoadFilterLabelsSuccess),
        withLatestFrom(this.store$.select(fromStore.dashboardSelector)),
        switchMap(([action, dashboard]) => [
            new fromActions.PushSearchFilters(Search.SearchConvert.filtersFromParams(dashboard.search_query_params, dashboard.available_filters.labels)),
        ])
    );

    @Effect()
    public loadFilterUsers$: Observable<fromActions.DashboardActions> = this.actions$.pipe(
        ofType<fromActions.LoadFilterUsers>(fromActions.DashboardActionType.LoadFilterUsers),
        switchMap((action) => {
            return this.requests.filterUsers$().pipe(
                map((users: Objects.User[]) => {
                    return new fromActions.LoadFilterUsersSuccess(users);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadFilterUsersError(err));
                })
            );
        })
    );

    // @Effect()
    // public LoadFilterUsersSuccess$ = this.actions$.pipe(
    //     ofType<fromActions.LoadFilterUsersSuccess>(fromActions.DashboardActionType.LoadFilterUsersSuccess),
    //     withLatestFrom(this.store$.select(fromStore.dashboardSelector)),
    //     switchMap(([action, dashboard]) => [
    //         // new fromActions.PushSearchFilters(Search.SearchConvert.filtersFromParams(dashboard.search_query_params, dashboard.available_filters.asignees)),
    //         // new fromActions.PushSearchFilters(Search.SearchConvert.filtersFromParams(dashboard.search_query_params, dashboard.available_filters.owners)),
    //     ])
    // );

    @Effect()
    public setSearchTerm$ = this.actions$.pipe(
        ofType<fromActions.SetSearchTerm>(fromActions.DashboardActionType.SetSearchTerm),
        // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
        switchMap((action) => [
            new fromActions.UpdateQueryParams()
        ])
    );

    @Effect()
    public setSearchSort$ = this.actions$.pipe(
        ofType<fromActions.SetSearchSort>(fromActions.DashboardActionType.SetSearchSort),
        // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
        switchMap((action) => [
            new fromActions.UpdateQueryParams()
        ])
    );

    @Effect()
    public applySearchFilter$ = this.actions$.pipe(
        ofType<fromActions.ApplySearchFilter>(fromActions.DashboardActionType.ApplySearchFilter),
        // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
        switchMap((action) => [
            // new fromActions.UpdateSearchQuery()
            new fromActions.UpdateQueryParams()
        ])
    );

    @Effect()
    public removeSearchFilter$ = this.actions$.pipe(
        ofType<fromActions.RemoveSearchFilter>(fromActions.DashboardActionType.RemoveSearchFilter),
        // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
        switchMap((action) => [
            // new fromActions.UpdateSearchQuery(),
            new fromActions.UpdateQueryParams()
        ])
    );

    // @Effect()
    // public updateSearchQuery$ = this.actions$.pipe(
    //     ofType<fromActions.UpdateSearchQuery>(fromActions.DashboardActionType.UpdateSearchQuery),
    //     withLatestFrom(this.store$.select(fromStore.searchSelector)),
    //     switchMap(([action, search]) => {
    //         let convert = new Search.SearchConvert();
    //         return [
    //             new fromActions.SetSearchQuery(convert.queryFromFilters(search.filters, search.term, search.sort)),
    //         ]
    //     })
    // );

    @Effect()
    public setCurrentWorkflow$ = this.actions$.pipe(
        ofType<fromActions.SetCurrentWorkflow>(fromActions.DashboardActionType.SetCurrentWorkflow),
        withLatestFrom(this.store$.select(fromStore.resultStatusSelector)),
        switchMap(([action, status]) => {
            // let convert = new Search.SearchConvert();
            return status == Search.SearchHttpStatus.INITIAL? [new fromActions.LoadResults()] : [];
            // return [
            //     new fromActions.LoadResults()
            // ]
        })
    );

    @Effect()
    public updateQueryParams$ = this.actions$.pipe(
        ofType<fromActions.UpdateQueryParams>(fromActions.DashboardActionType.UpdateQueryParams),
        withLatestFrom(this.store$.select(fromStore.searchSelector)),
        switchMap(([action, search]) => {
            // let convert = new Search.SearchConvert();
            let params = Search.SearchConvert.paramsFromFilters(search.filters, search.term, search.sort);
            return [
                new fromActions.SetQueryParams(Search.SearchConvert.paramsFromFilters(search.filters, search.term, search.sort)),
            ]
        })
    );

    // @Effect()
    // public setSearchQuery$ = this.actions$.pipe(
    //     ofType<fromActions.SetSearchQuery>(fromActions.DashboardActionType.SetSearchQuery),
    //     // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
    //     switchMap((action) => [
    //         new fromActions.LoadResults()
    //     ])
    // );

    @Effect()
    public setQueryParams$ = this.actions$.pipe(
        ofType<fromActions.SetQueryParams>(fromActions.DashboardActionType.SetQueryParams),
        // withLatestFrom(this.store$.select(fromStore.searchFiltersSelector)),
        switchMap((action) => [
            new fromActions.LoadResults()
        ])
    );

    @Effect()
    public loadResultItems$: Observable<fromActions.DashboardActions> = this.actions$.pipe(
        ofType<fromActions.LoadResults>(fromActions.DashboardActionType.LoadResults),
        withLatestFrom(this.store$.select(fromStore.dashboardSelector)),
        switchMap(([action, dashboard]) => {
            return this.requests.results$(Search.SearchConvert.removeVoidParams(dashboard.search_query_params), dashboard.results.next_page, dashboard.search.limit, dashboard.workflows.current_id, null).pipe(
                map((requests: Objects.ExtendedRequest[]) => {
                    return new fromActions.LoadResultsSuccess(requests);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadResultsError(err));
                })
            );
        })
    );

    @Effect()
    public updateRequest$: Observable<fromActions.DashboardActions> = this.actions$.pipe(
        ofType<fromActions.UpdateRequest>(fromActions.DashboardActionType.UpdateRequest),
        withLatestFrom(this.store$.select(fromStore.dashboardSelector)),
        mergeMap(([action, dashboard]) => {
            return this.requests.results$(Search.SearchConvert.removeVoidParams(dashboard.search_query_params), dashboard.results.next_page, dashboard.search.limit, dashboard.workflows.current_id, action.requestId).pipe(
                map((requests: Objects.ExtendedRequest[]) => {
                    return new fromActions.UpdateRequestSuccess(action.requestId, requests);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.UpdateRequestError(action.requestId, err));
                })
            );
        })
    );

    // @Effect()
    // public loadVersionSuccess$ = this.actions$.pipe(
    //     ofType<fromActions.LoadVersionSuccess>(fromActions.VersionsActionType.LoadVersionSuccess),
    //      //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
    //     switchMap((action) => [
    //         new fromActions.LoadVersionFiles(),
    //         new fromActions.LoadVersionMessages(),
    //         new fromActions.LoadEditorAccess(),
    //         new fromActions.LoadVersionProject(),
    //     ])
    // );
}
