import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';

// import { Request } from '@core/objects/request';
// import { Status } from '@core/objects/status';
// import { User } from '@core/objects/user';
// import { File } from '@core/objects/file';

import * as fromProjectsState from '@app/store/state/projects/projects.state';
import * as fromProjectsActions from '@app/store/state/projects/projects.actions';

// export interface ProjectItem extends fromProjectsState.ProjectLoaded {};

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {

    
    constructor(private url : UrlService, private store: Store<fromProjectsState.Projects>, private actions$: Actions,  protected http: HttpClient) {}

    load = (id: number) => this.store.dispatch( new fromProjectsActions.LoadProject(id));

    clear = () => this.store.dispatch( new fromProjectsActions.Clear());
    // to use when leave versions section in project screen to avoid double load when come back
    clearVersionsGroups = () => this.store.dispatch( new fromProjectsActions.ClearVersionsGroups());

    update = (id: number, event: string) => this.store.dispatch( new fromProjectsActions.UpdateProject(id, event));

    // selectError = (key: string): Observable<HttpErrorResponse> => this.actions$.pipe(ofType(fromProjectsActions.ProjectsActionType.LoadProjectError));

    selectError = (key: string): Observable<HttpErrorResponse> => this.store.select(fromProjectsState.errorSelector(key));

    selectProject = (): Observable<Objects.Request> => this.store.select(fromProjectsState.projectSelector);
    
    selectProjectHeader = (): Observable<Objects.BasicRequest> => this.store.select(fromProjectsState.projectHeaderSelector);

    selectProjectFiles = (): Observable<Objects.File[]> => this.store.select(fromProjectsState.projectFilesSelector); 

    selectOriginVersion = (): Observable<Objects.Version> => this.store.select(fromProjectsState.projectOriginVersionSelector); 

    setActiveFile = (file: Objects.File, active: boolean) => this.store.dispatch( new fromProjectsActions.SetActiveProjectFile(file, active));
    
    uploadFiles = (files: Objects.File[]) => this.store.dispatch( new fromProjectsActions.UploadProjectFiles(files));

    setAssign = (user: Objects.User) => this.store.dispatch( new fromProjectsActions.SetProjectAssigned(user));
    
    setDue = (date: string) => this.store.dispatch( new fromProjectsActions.SetProjectDue(date));

    setStatus = (status: Objects.RequestStatus) => this.store.dispatch( new fromProjectsActions.SetProjectStatus(status));

    setLabels = (labels: Array<Objects.Label>) => {
        if(labels.length == 0)
            return;
        this.store.dispatch( new fromProjectsActions.SetProjectLabels(labels));
    }

    loadMessages = () => this.store.dispatch( new fromProjectsActions.LoadProjectMessages());

    loadFilesNotes = () => this.store.dispatch( new fromProjectsActions.LoadProjectFilesNotes());

    selectProjectMessages = (): Observable<Objects.Message[]> => this.store.select(fromProjectsState.projectMessagesSelector);

    loadRelatedVersions= () => this.store.dispatch( new fromProjectsActions.LoadProjectVersionGroups());
    
    loadUploadedVersions= () => this.store.dispatch( new fromProjectsActions.LoadProjectUploadedVersions() );

    selectProjectRelatedVersions = (): Observable<Objects.Related[]> => this.store.select(fromProjectsState.projectVersionGroupsSelector);
    
    selectUploadedVersions = (): Observable<Objects.Version[]> => this.store.select(fromProjectsState.projectUploadedVersionsSelector);

    selectProjectFilesNotes = (): Observable<Objects.Note[]> => this.store.select(fromProjectsState.projectFilesNotesSelector); 
    
    loadProjectEvents = () => this.store.dispatch( new fromProjectsActions.LoadProjectEvents());
    
    selectProjectEvents = (): Observable<Objects.Event[]> => this.store.select(fromProjectsState.projectEventsSelector);

}

@Injectable({
    providedIn: 'root'
})
export class ProjectsEditorService {

    constructor(private store: Store<fromProjectsState.Projects>, private actions$: Actions) {}

    // loadAssigners = () => this.store.dispatch( new fromProjectsActions.LoadEditorAssigners());

    selectAssigners = (): Observable<Objects.User[]> => this.store.select(fromProjectsState.editorAssignersSelector);

    selectAccess = (key: string): Observable<boolean> => this.store.select(fromProjectsState.editorAccessSelector(key));

    selectAccessAll = () : Observable<Access.AccessProject> => this.store.select(fromProjectsState.editorAccessAllSelector);

    selectWorkflowTransitionsByOrigin = () : Observable<Objects.WorkflowTransition[]> => this.store.select(fromProjectsState.editorWorkflowTransitionsSelector);


    clearTransitions = () => this.store.dispatch( new fromProjectsActions.ClearEditorTransitions());

}
