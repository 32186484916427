import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Search from '@app/models/search';

@Component({
    selector: 'app-helpers-search-bar-fields-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss']
})
export class HelpersSearchBarFieldsLabelComponent{

    @Input() filter: Search.SearchAppliedFilter;
    @Output() remove = new EventEmitter<Search.SearchAppliedFilter>();

    onRemove(filter: Search.SearchAppliedFilter){
        this.remove.emit(filter);
    }

}