import { EventEmitter } from '@angular/core';

import { UrlService } from '@core/services/url/url.service';

import { ApolloContentComparisonResult,  ApolloContentComparisonResultMatch, ApolloContentComparisonResultWord, ApolloContentComparisonResultWordStyle } from './apollo-content-compared-result.model';
import { ApolloContentComparisonImages } from './apollo-content-comparison.model';

import { ApolloContentComparisonResultMark } from './apollo-content-compared-result-mark.model';

import { ApolloContentInspectorProcessWords, ApolloContentInspectorProcessWord } from './apollo-content-inspector-process-words.model';


interface ApolloContentInspectorMarks {
    ref: Array<ApolloContentComparisonResultMark>,
    tar: Array<ApolloContentComparisonResultMark>,
};

class ApolloContentInspectorSizes {
    viewer: any = {
        width: 0,
        height: 0
    };

    // inspector sizes
    ref: any = {
        width: 0,
        height: 0
    };

    tar: any = {
        width: 0,
        height: 0
    };
}

// export interface ApolloContentInspectorPageChange {
//     type: string;
//     page: number;
// }

export interface ApolloContentInspectorChange {
    value: any;
    type: string;
    context: string;
}


export class ApolloContentInspector {
    readonly TAR = "target";
    readonly REF = "ref";

    //readonly MIN_ZOOM = 0.2;
    //readonly MAX_ZOOM = 1;

    //public onPageChange = new EventEmitter<ApolloContentInspectorPageChange>();
    public onChange = new EventEmitter<ApolloContentInspectorChange>();

    protected urlSrv: UrlService;
    protected images: ApolloContentComparisonImages;
    public data: ApolloContentComparisonResult;

    protected sizes: ApolloContentInspectorSizes = new ApolloContentInspectorSizes();

    // public marks:  ApolloContentInspectorWords = {
    //     reference: [],
    //     target: []
    // };

    public words:  ApolloContentInspectorProcessWords = {
        reference: [],
        target: []
    };

    public selectRefWord: ApolloContentInspectorProcessWord = null;
    public selectTarWord: ApolloContentInspectorProcessWord = null;

    public selectRefMark: ApolloContentComparisonResultMark = null;
    public selectTarMark: ApolloContentComparisonResultMark = null;


    public tarPage: number = 0;
    public refPage: number = 0;

    protected countSelectRef: number = 0;
    protected countSelectTar: number = 0;

    /**
     * Front variables
     **/

    // viewer
    public changeList: Array<ApolloContentInspectorProcessWord> = [];
    public orphanRefList: Array<ApolloContentInspectorProcessWord> = [];
    public viewerMarks: Array<ApolloContentComparisonResultMark> = [];

    // images
    public refImageSrc: string = "";
    public tarImageSrc: string = "";

    // inspector
    // public zoom: any = {
    //     tar: 1,
    //     ref: 1
    // };


    public marks: ApolloContentInspectorMarks = { ref: [], tar: []};

    public avalaibleFilters: Array<string> = ["ins", "del", "subst", "onlystyle", "none"];
    public activeFilters: Array<string> = ["ins", "del", "subst", "onlystyle"];


    public acceptedList: Array<ApolloContentInspectorProcessWord> = [];
    public rejectedList: Array<ApolloContentInspectorProcessWord> = [];
    /**
     * constructor: set data from comparison content result
     **/
    constructor(urlSrv: UrlService, images: ApolloContentComparisonImages, data:  ApolloContentComparisonResult) {
        this.urlSrv = urlSrv;
        this.images = images;
        this.data = data;

        this.init();
    }

    protected init() {
        this.processWords();

        this.setTarImage();
        this.setRefImage();
    }

    protected dispatchChange(context: string, type: string, value: any = null) {
        this.onChange.emit({context: context, type: type, value: value});
    }


    /**
     * Get references words from data
     **/
    protected getReferenceWords() : Array<ApolloContentComparisonResultWord>{
        return this.data.reference_words;
    }

    /**
     * Get target words from data
     **/
    protected getTargetWords() : Array<ApolloContentComparisonResultWord>{
        return this.data.target_words;
    }

    public getMatches() {
        return this.data.matches;
    }

    /**
     * Process data for get words
     **/
    protected processWords() {
        this.words.reference = this.getReferenceWords().map((e:ApolloContentComparisonResultWord) => {
            let type = this.REF;

            let matches: Array<number> = this.getMatches().filter(m => m[this.REF] == e.id && m[this.TAR]> -1).map(m => m[this.TAR]);

            return {...e, matches: matches, pageNumber: +e.page, selected: false, type: type, details: this.stylesDetails(e.styles, e.text) }
        });

        this.words.target = this.getTargetWords().map((e:ApolloContentComparisonResultWord) => {
            let type = this.TAR;

            let matches: Array<number> = this.getMatches().filter(m => m[this.TAR] == e.id && m[this.REF]> -1).map(m => m[this.REF]);

            return {...e, matches: matches, pageNumber: +e.page, type: type, details: this.stylesDetails(e.styles, e.text) }
        });

        this.loadOrphanRefList();
        console.debug("Words processed!!", this.words);
    }

    public getRefWordsById(id: number) : ApolloContentInspectorProcessWord | null {
        return this.getWordById(this.words.reference, id);
    }

    public getTarWordsById(id: number) : ApolloContentInspectorProcessWord | null {
        return this.getWordById(this.words.target, id);
    }

    protected getWordById(words: Array<ApolloContentInspectorProcessWord>, id: number) : ApolloContentInspectorProcessWord | null {
        let word = words.filter(w => w.id == id);

        return word.length > 0? word[0] : null;
    }

    /**
     * set target page
     **/
    public setTarPage(page: number) {
        if (page === this.tarPage) return;

        this.tarPage = page;
        this.loadChangeList();
        this.setTarImage();

        // for bug when sizes is not exec
        this.loadViewerMarks();
        this.loadTarMarks();

        this.dispatchChange("page", this.TAR, page);
    }

    /**
     * set target page
     **/
    public setRefPage(page: number) {
        if (page === this.refPage) return;

        this.refPage = page;
        this.setRefImage();

        this.loadRefMarks();

        this.dispatchChange("page", this.REF, page);
    }

    /**
     * load change list
     **/
    protected loadChangeList() {
        // target words on page selected
        let targets = this.words.target.filter(e => e.pageNumber == this.tarPage);

        // let links: Array<number> = [];
        // let linksIds = targets.map(e => e.matches).map(m => { links = links.concat(m); });
        //
        // console.log("links ids",links);
        //
        // let references = this.words.reference.filter(r => links.indexOf(r.id) > -1);
        //
        // this.changeList = targets.concat(references);
        this.changeList = targets;
        console.log("changes list!!", this.changeList);
    }

    protected loadOrphanRefList() {
        this.orphanRefList = this.words.reference.filter(e => e.matches.length == 0);
    }

    protected setTarImage() {
        this.tarImageSrc = this.urlSrv.build("/files/" + this.images.target[this.tarPage-1] + "?f=inline");
    }

    protected setRefImage() {
        this.refImageSrc = this.urlSrv.build("/files/" + this.images.reference[this.refPage-1] + "?f=inline");
    }

    public setViewerSize(size: any) {
        console.debug("viewer sized!!", size);
        this.sizes.viewer = size;

        this.loadViewerMarks();
    }

    protected loadViewerMarks() {
        // (type: string, page: number, width: number, height: number) : Array<ApolloContentComparisonResultMark>
        this.viewerMarks = this.getMarks(this.TAR, this.tarPage, this.sizes.viewer.width, this.sizes.viewer.height);
        this.dispatchChange("marks", this.TAR);
    }

    public isSelectedMark(mark: ApolloContentComparisonResultMark) {
        return this.isSelectedWordByIdAndType(mark.id, mark.type);
    }

    public isSelectedWord(word: ApolloContentInspectorProcessWord) {
        return this.isSelectedWordByIdAndType(word.id, word.type);
    }

    public selectMark(mark: ApolloContentComparisonResultMark) {
        this.selectWordByIdAnType(mark.id, mark.type);
    }

    public selectWord(word: ApolloContentInspectorProcessWord) {
        this.selectWordByIdAnType(word.id, word.type);
    }

    protected isSelectedWordByIdAndType(id: number, type: string) {
        let word: ApolloContentInspectorProcessWord = null;
        switch(type){
            case this.REF:
                word = this.selectRefWord;
                break;
            case this.TAR:
                word = this.selectTarWord;
                break;
        }

        return word!=null && word.id == id;
    }


    protected selectWordByIdAnType(id: number, type: string) {
        switch(type){
            case this.REF:
                this.selectRefWordById(id);
                break;
            case this.TAR:
                this.selectTarWordById(id);
                break;
        }
    }

    public clearSelectedWords() {
        this.selectRefWord = null;
        this.selectTarWord = null;
        this.setSelectMarks();
    }

    /**
     * public select
     **/
    protected selectRefWordById(id: number, links: boolean = true) {
        //let selectWord = this.getSelectedRefWord();
        //let selectWord = this.selectRefWord;

        // select the same, count more
        if(this.selectRefWord != null && this.selectRefWord.id != id)
            this.countSelectRef = 0;

        // select ref
        //this.words.reference = this.words.reference.map(w => { return {...w, selected: w.id == id }});
        let selectWord = this.getRefWordsById(id);
        this.selectRefWord = selectWord;

        this.dispatchChange("word", this.REF, this.selectRefWord);

        if(selectWord != null) {

            this.setRefPage(selectWord.pageNumber);

            // this.countSelectRef++;
            //
            // if(selectWord.matches.length < this.countSelectRef)
            //     this.countSelectRef = 1;

            if(links)
                this.selectLinkRefByWord(selectWord);
        }
        this.setSelectMarks();
    }

    protected selectLinkRefByWord(word: ApolloContentInspectorProcessWord) {
        this.countSelectRef++;

        if(word.matches.length < this.countSelectRef)
            this.countSelectRef = 1;

        let selectId = word.matches.length>0? word.matches[this.countSelectRef-1] : -1;

        this.selectTarWordById(selectId, false);
    }

    protected selectTarWordById(id: number, links: boolean = true) {
        if(this.selectTarWord != null && this.selectTarWord.id != id)
            this.countSelectTar = 0;

        let selectWord = this.getTarWordsById(id);
        this.selectTarWord = selectWord;

        this.dispatchChange("word", this.TAR, this.selectTarWord);
        if(selectWord != null) {
            this.setTarPage(selectWord.pageNumber);

            if(links)
                this.selectLinkTarByWord(selectWord);
        }
        this.setSelectMarks();
    }

    protected selectLinkTarByWord(word: ApolloContentInspectorProcessWord) {
        this.countSelectTar++;

        if(word.matches.length < this.countSelectTar)
            this.countSelectTar = 1;

        let selectId = word.matches.length>0? word.matches[this.countSelectTar-1] : -1;

        this.selectRefWordById(selectId, false);
    }

    /**
     * Inspector
     * Things
     **/

    protected setSelectMarks() {
        let nextRefMark = this.getMarkByWord(this.marks.ref, this.selectRefWord);

        if(JSON.stringify(nextRefMark) !== JSON.stringify(this.selectRefMark)) {
            this.selectRefMark = this.getMarkByWord(this.marks.ref, this.selectRefWord);
            this.dispatchChange("marks", this.REF);
        }

        let nextTarMark = this.getMarkByWord(this.marks.tar, this.selectTarWord);

        if(JSON.stringify(nextTarMark) !== JSON.stringify(this.selectTarMark)) {
            this.selectTarMark = this.getMarkByWord(this.marks.tar, this.selectTarWord);
            this.dispatchChange("marks", this.TAR);
        }
    }

    public setRefSize(size: any) {
        this.sizes.ref = size;

        this.loadRefMarks();
    }

    public setTarSize(size: any) {
        this.sizes.tar = size;

        this.loadTarMarks();
    }

    protected loadRefMarks() {
        this.marks.ref = this.getMarks(this.REF, this.refPage, this.sizes.ref.width, this.sizes.ref.height);
        this.setSelectMarks();
        // this.dispatchChange("marks", this.REF);
    }

    protected loadTarMarks() {
        this.marks.tar = this.getMarks(this.TAR, this.tarPage, this.sizes.tar.width, this.sizes.tar.height);
        this.setSelectMarks();
        // this.dispatchChange("marks", this.TAR);
    }

    public getSelectedLinks(refBox: any, tarBox: any, refZoom: number, tarZoom: number) : Array<any> {
        //console.debug("inputs-->", refOffset, tarOffset);
            if(this.selectRefWord == null || this.selectTarWord == null)
                return [];

            // let ref = this.getMarkById(this.marks.ref, this.selectRefWord.id);
            // let tar = this.getMarkById(this.marks.tar, this.selectTarWord.id);
            let ref = this.selectRefMark;
            let tar = this.selectTarMark;

            //let ref = refMark;
            //let tar = tarMark;

            if(ref==null || tar== null)
                return [];

            //console.debug("ref", ref.x, ref.y);
            //console.debug("tar", tar.x, tar.y);
            let Ax = ref.x;
            let Ay = ref.y;
            let Bx = tar.x;
            let By = tar.y;


            // for mobile
            //let mobile = false;
            if(tarBox.offset.top > refBox.offset.top) { // mobile
                Ax = Ax + ref.width/2;
                Ay = Ay + ref.height;
                Bx = Bx + tar.width/2;
                //mobile = true;
            } else { // no mobile
                Ax = Ax + ref.width;
                Ay = Ay + ref.height/2;
                By = By + tar.height/2;
            }

            // zoom
            Ax = Ax * refZoom;
            Ay = Ay * refZoom;

            Bx = Bx * tarZoom;
            By = By * tarZoom;

            // check if mark is in view
            if(!this.inView(Ax, Ay, refBox) || !this.inView(Bx, By, tarBox))
                return null;

            Ax = Ax + refBox.offset.left - refBox.scroll.left;
            Ay = Ay + refBox.offset.top - refBox.scroll.top;
            Bx = Bx + tarBox.offset.left - tarBox.scroll.left;
            By = By + tarBox.offset.top - tarBox.scroll.top;


            //console.debug("A -->", Ax, Ay)
            let calc = this.calcUnion(Ax, Ay, Bx, By);
            //console.debug("calc--->", calc);

            return [{
                ref: ref.id,
                target: tar.id,
                left: calc.left,
                top: calc.top,
                width: calc.width,
                rotate: calc.rotation
            }];
        //}).filter((e) => { return e !== null});
    }


    protected inView(x: number, y: number, box: any) {
        //let x = mobile? e.x + e.width/2 : e.x + e.width;
        //let y = mobile? e.y + e.height : e.y + e.height/2;

        if(x-box.scroll.left < 0
        || x-box.scroll.left > box.size.width
        || y-box.scroll.top > box.size.height
        || y-box.scroll.top < 0)
            return false;

        return true;
    }

    protected calcUnion(Ax: number, Ay: number, Bx: number, By: number) : any {
        let rotation = Math.PI/2-(Math.atan((Ax-Bx)/(Ay-By)));

        let length = Math.abs((Ax-Bx)/Math.sin(90*Math.PI/180-rotation));

        if(rotation>Math.PI/2){
            var x = Ax-(length/2-length*Math.cos(Math.PI-rotation)/2);
            var y = Ay-length*Math.sin(rotation)/2;
        }
        else{
            var x = Ax-(length/2-length*Math.cos(rotation)/2);
            var y = Ay+length*Math.sin(Math.PI-rotation)/2;
        }
        //var origin = new Array();
        //origin[‘x’] = x;
        //origin[‘y’] = y;

        //return origin;

        return {
            rotation: rotation,
            left: x,
            top: y,
            width: length
        };
    }


    public getMarks(type: string, page: number, width: number, height: number) : Array<ApolloContentComparisonResultMark> {

        let words = [];
        switch(type) {
            case this.REF:
                //words = this.getReferenceWordsByPage(page);
                words = this.words.reference.filter(e => e.pageNumber == this.refPage);
                break;
            case 'target':
                //words = this.getTargetWordsByPage(page);
                words = this.words.target.filter(e => e.pageNumber == this.tarPage);
                break;
        }

        return words.map((e: ApolloContentInspectorProcessWord) => {
            let m = {
                x: width * e.coords.x,
                y: height * e.coords.y - (height * e.size.height),
                width: width * e.size.width,
                height: height * e.size.height,
                text: e.text,
                id: e.id,
                page: +e.page,
                type: type,
                change: e.change,
                //details: this.stylesDetails(e.style)
            };

            return m;

        })
    }

    protected stylesDetails(styles: Array<ApolloContentComparisonResultWordStyle>, word: string) : string {
        let string: string = "";

        let i = 0;
        for(let s of styles) {
            string = i>0? string + "<br/>" : string;
            string = s.text != word? string + "<span class=\"letter\">(" + s.text + ")</span> " : string;
            string = string + "<span class=\"name\">" + s.font_name + "</span><span class=\"size\">, " + s.size +"pt</span> <span class=\"weight\">, "+s.font_weight+"w</span>";
            string = s.font_italicangle != "0"? string + ", <span class=\"italic\">italic</span>" : string;
            i++;
        }
        // if("font_name" in style) {
        //     string = "<span class=\"name\">" + style.font_name + "</span><span class=\"size\">, " + style.size +"pt</span> <span class=\"weight\">, "+style.font_weight+"w</span>";
        //     string = style.font_italicangle != "0"? string + ", <span class=\"italic\">italic</span>" : string;
        // }

        return string;
    }

    protected getMarkByWord(marks: Array<ApolloContentComparisonResultMark>, word: ApolloContentInspectorProcessWord) {
        if(word == null)
            return null;

        return this.getMarkById(marks, word.id);
    }

    getMarkById(marks: Array<ApolloContentComparisonResultMark>, id: number): any {
        let matches = marks.filter((e) => {
            return e.id == id;
        });

        if(matches.length > 0)
            return matches[0];

        return null;
    }

    public setFilter(filter: string) {
        let actives = this.activeFilters.filter(f => f==filter);
        let avalaible = this.avalaibleFilters.filter(f => f==filter);

        if(avalaible.length > 0 && actives.length == 0)
            this.activeFilters.push(filter);

        this.dispatchChange("filters", "", true);
    }

    public unsetFilter(filter: string) {
        this.activeFilters = this.activeFilters.filter(f => f!=filter);
        this.dispatchChange("filters", "", true);
    }

    public isFilterActive(mark: ApolloContentComparisonResultMark | ApolloContentInspectorProcessWord) {
        return this.activeFilters.indexOf(mark.change) > -1;
    }
};
