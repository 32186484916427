import { Component, OnInit, OnDestroy,Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';
import { Request } from '@core/objects/request';

@Component({
    selector: 'legacy-notes-request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss']
})
export class LegacyNotesRequestComponent implements OnInit, OnDestroy {

    public loaded: boolean = false;
    public failed: boolean = false;

    public file: File = null;
    public request: Request = null;
    public notes: Array<any> = null;

    protected urlNotes: string;

    constructor(private http: HttpClient, private urlSrv: UrlService, private activatedRoute: ActivatedRoute){}

	ngOnInit(){
        // get data from url
        let requestId = this.activatedRoute.snapshot.paramMap.get('request');
        let fileId = this.activatedRoute.snapshot.paramMap.get('file');

        this.urlNotes = this.urlSrv.build("/requests/" + requestId + "/files/" + fileId + "/notes");

        //console.debug("file-->", fileId, "request-->", requestId);

        // get request
        this.http.get(this.urlSrv.build('/requests/'+requestId)).subscribe(
            (data:Request) => {
                this.request = data;
                //console.debug("request-->", this.request);
                this._checkLoad();
            },
            (error:any) => {
                this.failed = true;
                console.error("Load request crashed.")
            }
        );
        // get file
        this.http.get(this.urlSrv.build('/files/'+fileId+'?f=json')).subscribe(
            (data:File) => {
                this.file = data;
                //console.debug("file-->", this.file);
                this._checkLoad();
            },
            (error:any) => {
                this.failed = true;
                console.error("Load file crashed.")
            }
        );

        this.loadNotes();
    }

    protected _checkLoad() {
        this.loaded = this.request !== null && this.file !== null && this.notes !== null;
    }

    protected loadNotes() {
        // get notes
        this.http.get(this.urlNotes).subscribe(
            (data:any) => {
                this.notes = data;
                //console.debug("notes-->", this.notes);
                this._checkLoad();
            },
            (error:any) => {
                this.failed = true;
                console.error("Load notes crashed.")
            }
        );
    }

    ngOnDestroy(){
	}

    onSave(event) {
        console.debug("save--->", event);
        let saveNotes = event.filter(n => { return n.id == 0; });
        console.debug("save notes--->", saveNotes);
        //let url = this.url.build("/requests/"+this.request.id+"/files/"+this.file.id+/notes");

        let i= 0;
        for(let saveNote of saveNotes){
            console.debug("saving---->", saveNote);
            this.http.post(this.urlNotes, saveNote).subscribe(
                (data:any) => {
                    console.debug("saved notes", data);
                    i++;
                    if(i === saveNotes.length)
                        this.loadNotes();
                },
                (error:any) => {
                    console.error('Error saving notes: '+ error);
                }
            );
        }
    }
}
