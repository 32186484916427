import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { File } from '@core/objects/file';
import { Version } from '@core/objects/version';
import { PreviewRequest } from '@core/objects/preview-request';

@Injectable({
    providedIn: 'root'
})
export class VersionsService {

    constructor(protected url: UrlService, protected http: HttpClient) {}

    /**
     * This could be processed by PreviewService
     * for that we need get only the files of the preview
    */
    getPreview$(version: Version, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/versions/" + version.id + "/preview"));
    }

    getFiles$(version: Version, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/versions/" + version.id + "/files"));
    }

    getMessages$(version: Version, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/versions/" + version.id + "/messages"));
    }
}
