import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabelsModule } from '@core/components/labels/labels.module';
import { StatusModule } from '@core/components/status/status.module';

import { MessagesModule } from '@core/components/messages/messages.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { CommonsModule } from '@core/components/commons/commons.module';
import { FilesModule } from '@core/components/files/files.module';
import { PreviewModule } from '@core/components/preview/preview.module';

// import { VersionsDetailsMainComponent } from '../main/main.component';


import { VersionsModule } from '@core/components/versions/versions.module';
import { FilesListModule } from '@core/components/files/lists/lists.module';
import { VersionsDetailsContentModule } from './content/content.module';
import { VersionsSectionsModule } from './sections/sections.module';
import { VersionHelpersModule } from '../helpers/helpers.module';


@NgModule({
    imports: [
        CommonModule,
        LabelsModule,
        StatusModule,
        MessagesModule,
        HelpersModule,
        CommonsModule,
        FilesModule,
        PreviewModule,
        VersionsModule,
        FilesListModule,
        VersionsDetailsContentModule,
        VersionHelpersModule,
        VersionsSectionsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
    ],
    exports: [
        VersionHelpersModule,
        VersionsSectionsModule
    ],

})
export class VersionsDetailsModule { }