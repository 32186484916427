import { WorkflowMenuItem } from '@core/models/menu-item.model';

export const workflowsMenu : WorkflowMenuItem[] = [
    {
        title: 'default',
        link: '/dashboard',
        disabled: false,
        workflow: null
    }
];

export const workflowsDefaultItem : WorkflowMenuItem = 
    {
        title: '',
        link: '/dashboard/:id',
        disabled: false,
        workflow: null
    }
;
