import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';

import { SelectItem } from '@core/models/select-item.model';

@Component({
  selector: 'core-helpers-forms-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {

    @Input() disabled: boolean;
    @Input() optionSelected: string = null;
    @Input() label: string = "";
    @Input() options: SelectItem[] = [];
    @Input() placeholder: string = "";
    @Output() select = new EventEmitter<any>();

    public selected = "";

    public typeSafe:string;
    ngOnInit() {
        // if(this.optionSelected != null)
            this.selected = this.getValueSelected();
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.options) {
            this.selected = this.getValueSelected();
        }
    }

    onChange(value: any){
        let option = this.options.filter((o: SelectItem)=> {
            return o.value == value;
        });

        this.select.emit(option[0]);
    }

    protected getValueSelected() {
        let options;
        if (this.optionSelected != null || this.optionSelected != undefined) {
            let optSelected = this.options.map( opt => {
                if (opt.value === this.optionSelected) {
                    return { ...opt, selected : true}
                } else {
                    return {...opt, selected: false}
                }
            });
            options = optSelected.filter(o => { return o.selected});
        } else {
            options = this.options.filter( o => { return o.selected});
        }
        

        return options.length > 0? options[0].value : "";
    }

    /**
     * 
     * workaround for disabled some select options
     */
    disabledOption(option: SelectItem) {
        return option.value.indexOf('disabled')> -1;
    }
}
