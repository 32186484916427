import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProjectsEditorService } from 'app/services/app/projects.service';



import * as Objects from '@core/objects';


@Component({
    selector: 'dashboard-views-table-details-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class DashboardViewsTableDetailsInfoComponent implements OnInit, OnDestroy {

    @Input() project: Objects.ExtendedRequest;

    public previousVersionsAccess: boolean = false;

    protected subscriptor: Subscription;

    constructor(private editorSrv: ProjectsEditorService){}

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        // this.subscriptor.unsubscribe();
    }
}