import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardMainComponent } from '@app/components/sections/dashboard/main/main.component';
import { DashboardViewsMainComponent } from 'app/components/sections/dashboard/views/main/main.component';
import { DashboardViewsTableMainComponent } from 'app/components/sections/dashboard/views/table/main/main.component';

export const dashboardRoutes: Routes = [
    {
        path: 'dashboard',
        component: DashboardMainComponent,
        // children: [{
        //         path: '',
        //         // main screen would have project search and results comps, then other goes in sections
        //         component: DashboardSearchMainComponent
        //     },
            
        // ]
    },
    {
        path: 'dashboard/:wid',
        component: DashboardMainComponent,
        children: [
            {
                path: 'table',
                // main screen would have project search and results comps, then other goes in sections
                component: DashboardViewsTableMainComponent
            }
        ]
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(dashboardRoutes)
    ],
    exports: [RouterModule]
})
export class AppDashboardRouterModule { }


