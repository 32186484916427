import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { Subscription } from 'rxjs';

import { ProjectsService } from '@app/services/app/projects.service';

import * as Objects from '@core/objects';

@Component({
    selector: 'project-details-info-edit-labels',
    templateUrl: './edit-labels.component.html',
    styleUrls: ['./edit-labels.component.scss']
})
export class ProjectDetailsInfoEditLabelsComponent implements OnInit, OnDestroy {


    @Output() close = new EventEmitter();

    public project: Objects.Request;

    protected subscriptor: Subscription;

	constructor(        
        private projectsSrv: ProjectsService
    ){}

	ngOnInit(){        
        this.subscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.Request ) => {
            if(project == null) return;
                
            this.project = project;
        });
	}

    setLabels(labels: Array<Objects.Label>) {
        this.projectsSrv.setLabels(labels);
    }

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
    }



}
