import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { VersionsEditorService, VersionsViewService } from '@app/services/app/versions.service';
import { AppRouterService } from '@app/services/app/router.service';

// import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
import { TitleService } from '@core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { AppService } from '@app/services/app/app.service';
import { VersionsService } from '@app/services/app/versions.service';
import { AccessVersion } from '@app/models/access';
import { AddProjectService } from '@app/services/app/add-project.service';

@Component({
    selector: 'version-details-version-main',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionsDetailsVersionComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_ERROR = 2;
    public readonly STATUS_NOT_FOUND= 3;
    public readonly STATUS_NOT_ALLOWED= 4;

    public readonly PLUGIN_REQUEST_FEEDBACK = 'share_feedback';
    public readonly PLUGIN_SEND_FILES = 'share_transfer';
    public readonly ACCESS_ADD_PROJECT = "access_global_new_request";
    public readonly ACCESS_EDIT_TAGS =  'access_version_edit_labels';
    public readonly ACCESS_CHANGE_STATUS =  'access_version_change_status';

    public version: Objects.Version = null;
    public project: Objects.Request = null;
    public bcTitle: string = 'ARTWORK v. . . . <small>(# .  .  . )</small>';
    public excludedLabels: Array<Objects.Label> = [];
    public allVersionStatus: Array<Objects.Status> = null;
    public allowedVersionStatus: Array<Objects.Status> = [];

    public pluginsAccess: Array<string> = [];
    public versionAccess: AccessVersion = null;
    public addProjectAccess: boolean = false;
    public context: string = '';

    // TODO review menu icons
    public menu: Array<ThreeDotsMenuItem> = [];
    // optional menu items
    protected menuSendFiles: ThreeDotsMenuItem = {
        title: "Send",
        key: "send-files",
        icon: "send-files",
        order: 1
    };
    protected menuRequestFeedback: ThreeDotsMenuItem = {
        title: "Request Feedback",
        key: "feedback",
        icon: "feedback",
        order: 2
    };
    protected menuAddProject: ThreeDotsMenuItem = {
        title: "Start New Project",
        key: "add-project",
        icon: "add",
        order: 3
    };
    protected menuSeeOriginalProject: ThreeDotsMenuItem = {
        title: "See Original Project",
        key: "see-project",
        icon: "see-project",
        order: 4
    };
    protected menuEditTags: ThreeDotsMenuItem = {
        title: "Edit Tags",
        key: "edit",
        icon: "edit-tags",
        order: 5
    };



    public status:number = this.STATUS_LOADING;

    protected urlSubscriptor: Subscription;
    protected statusSubscriptor: Subscription;
    // protected projectSubscriptor: Subscription;
    protected versionSubscriptor: Subscription;
    protected originProjectSubscriptor: Subscription;
    protected accessProjectSubscriptor: Subscription;
    protected accessVersionSubscriptor: Subscription;
    protected errorSubscriptor: Subscription;


    constructor(
        public app: AppService,
        private title: TitleService,
        private activeRoute: ActivatedRoute,
        private viewVersionsSrv: VersionsViewService,
        private editorVersionSrv: VersionsEditorService,
        private versionsSrv: VersionsService,
        private AddProjectSrv: AddProjectService,
        private appRouter: AppRouterService
    ) { }

    ngOnInit() {
        // set temporal header title
        this.title.set("Version...");

        // https://youtu.be/ocliZj7ZXb0?t=93
        this.urlSubscriptor = this.activeRoute.queryParams.subscribe((params: any) => {
            console.log("query params ===>", params);
            if (params.context) {
                this.context = params.context;
            }
        });
        // get the version
        this.versionSubscriptor = this.viewVersionsSrv.selectVersion().subscribe((version: Objects.Version) =>
        {
            if(version != null ){ 
                this.version = version;
                this.bcTitle = 'ARTWORK v.' + version.version + ' <small>(#' + version.id + ')</small>';
                this.statusSubscriptor = this.versionsSrv.getNextStatus$(this.version.id).subscribe((status: Array<Objects.Status>) => {
                    console.debug(" version status ===> ", status)
                    this.allVersionStatus = status;
                    this.updateAllowedStatus();
                    this.initView();
                });
            }
            this.initView();
        });
        // get the project of the version.
        this.originProjectSubscriptor = this.viewVersionsSrv.selectProject().subscribe((projectOrigin: Objects.Request) => {
            this.project = projectOrigin
            if(projectOrigin != null )
                this.updateMenu(this.menuSeeOriginalProject);
            this.initView();
        });

        this.accessVersionSubscriptor = this.editorVersionSrv.selectAccessAll().subscribe((access: AccessVersion)=> {
            if(access != null){
                this.versionAccess = access;
                // console.debug(" version access ---> ",this.versionAccess);
                this.updateAllowedStatus();
                this.updateMenu(this.menuEditTags,this.versionAccess.hasOwnProperty(this.ACCESS_EDIT_TAGS) ? this.versionAccess[this.ACCESS_EDIT_TAGS]: false);
                this.initView();
            }
        });


        // plugins & add project from session
        this.app.getSession().subscribe( (session: any) => {
            this.pluginsAccess = session.options.plugins;
            this.addProjectAccess = session.options.access.hasOwnProperty(this.ACCESS_ADD_PROJECT)? session.options.access[this.ACCESS_ADD_PROJECT] : false;

            this.updateMenu(this.menuSendFiles,this.checkPluginAccess(this.PLUGIN_SEND_FILES));
            this.updateMenu(this.menuRequestFeedback,this.checkPluginAccess(this.PLUGIN_REQUEST_FEEDBACK));
            this.updateMenu(this.menuAddProject,this.addProjectAccess);
        });

        

        this.errorSubscriptor = this.viewVersionsSrv.selectError('load_version').subscribe((error: HttpErrorResponse) => {
            this.setError(error)
        });
    }

    ngOnDestroy() {
        this.urlSubscriptor.unsubscribe();
        this.versionSubscriptor.unsubscribe();
        this.originProjectSubscriptor.unsubscribe();
        if(this.statusSubscriptor)
            this.statusSubscriptor.unsubscribe();
        this.accessVersionSubscriptor.unsubscribe();
        this.errorSubscriptor.unsubscribe();
    }

    private initView() {
        if(this.versionAccess == null || this.allVersionStatus == null || this.version == null  || this.project == null)
            return;
        this.setHeaderTitle();
        this.status = this.STATUS_DONE;
    }

    private setHeaderTitle() {
        this.title.set("Version #"+ this.version.id);
    }

    onMenuClick(key: string) {
        switch(key) {
            case "upload-artwork":
                this.appRouter.navigate('/projects/'+this.project.id+'/versions/add', { queryParams: { links: this.version.labels.map((l: Objects.Label) => l.id).sort().join("-") } });
                break;
            case "add-project":
                if(this.version != null){
                    this.AddProjectSrv.clear();
                    this.appRouter.navigate('/projects/add', { queryParams: { version: this.version.id} });
                }
                break;
            case "see-project":
                this.appRouter.navigate('/projects/'+this.project.id);

                break;
            case "feedback":
                if (this.checkPluginAccess(this.PLUGIN_REQUEST_FEEDBACK))
                this.appRouter.navigate('/p/feedback/'+this.project.id, { queryParams: { version: this.version.id} });
                break;
            case "send-files":
                if (this.checkPluginAccess(this.PLUGIN_SEND_FILES))
                    this.appRouter.navigate('/p/transfer/'+this.project.id, { queryParams: { version: this.version.id} });
                break;
        }
    }


    onStatusUpdate(status: Objects.Status) {
        this.viewVersionsSrv.setStatus(status);
    }

    onSetLabels(labels: Array<Objects.Label>){
        console.debug(" new labels for version are ===>", labels)
        this.viewVersionsSrv.setLabels(labels);
    }

    protected checkPluginAccess(plugin: string){
        return this.pluginsAccess.some((pa) => pa == plugin);
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

    protected updateAllowedStatus(){
        if(this.allVersionStatus != null && this.versionAccess != null){
            this.allowedVersionStatus = this.versionAccess[this.ACCESS_CHANGE_STATUS] ? this.allVersionStatus : [];
        }
    }

    protected setError(error: HttpErrorResponse) {
        if(error == null)
            return;

        switch(error.status) {
            case 500:
                this.status = this.STATUS_ERROR;
                break;
            case 404:
                this.status = this.STATUS_NOT_FOUND;
                break;
            case 403:
                this.status = this.STATUS_NOT_ALLOWED;
                break;
        }
    }


}
