import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
// import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

// import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';
import * as Search from '@app/models/search';

import * as fromState from '@app/store/state/dashboard/dashboard.state';
import * as fromActions from '@app/store/state/dashboard/dashboard.actions';

@Injectable({
    providedIn: 'root'
})
export class DashboardFiltersService {

    constructor(private store: Store<fromState.Dashboard>, private actions$: Actions) {}

    load = (query: string) => this.store.dispatch( new fromActions.LoadFilters());

    selectStatus = (): Observable<Search.SearchAvailableFilters> => this.store.select(fromState.availableFilterStatusSelector);

    selectLabels = ():  Observable<Search.SearchAvailableFilters> => this.store.select(fromState.availableFilterLabelsSelector);

    selectAsignees = ():  Observable<Search.SearchAvailableFilters> => this.store.select(fromState.availableFilterAsigneesSelector);

    selectOwners = ():  Observable<Search.SearchAvailableFilters> => this.store.select(fromState.availableFilterOwnersSelector);
}

@Injectable({
    providedIn: 'root'
})
export class DashboardSearchService {

    constructor(private store: Store<fromState.Dashboard>, private actions$: Actions) {}

    applyFilter = (filter: Search.SearchAppliedFilter) => this.store.dispatch( new fromActions.ApplySearchFilter(filter));

    removeFilter = (filter: Search.SearchAppliedFilter) => this.store.dispatch( new fromActions.RemoveSearchFilter(filter));

    setTerm = (term: string) => this.store.dispatch( new fromActions.SetSearchTerm(term));

    selectFilters = () :  Observable<Search.SearchAppliedFilter[]> => this.store.select(fromState.searchFiltersSelector);

    selectTerm = () :  Observable<string> => this.store.select(fromState.searchTermSelector);

//     // selectQuery = () :  Observable<string> => this.store.select(fromState.searchQuerySelector);
}

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private store: Store<fromState.Dashboard>, private actions$: Actions) {}

    start = (params: any, context: string) => this.store.dispatch( new fromActions.InitParams(params, context));

    loadWorkflows = () => this.store.dispatch( new fromActions.LoadWorkflows());

    selectWorkflows = () : Observable<fromState.DashboardWorkflows> => this.store.select(fromState.workflowsSelector);

    selectCurrentWorkflow = () : Observable<Objects.Workflow> => this.store.select(fromState.currentWorkflowSelector);

    setCurrentWorkflow = (wid: number) => this.store.dispatch(new fromActions.SetCurrentWorkflow(wid));
    
    setOrder = (sort: string) => this.store.dispatch(new fromActions.SetSearchSort(sort));

    selectOrder = () : Observable<string> => this.store.select(fromState.searchSortSelector); 

    loadResults = () => this.store.dispatch( new fromActions.LoadResults());

    updateProject = (rid: number | string, eventName: string) => this.store.dispatch( new fromActions.UpdateRequest(rid, eventName));

    selectResults = () : Observable<Objects.ExtendedRequest[]> => this.store.select(fromState.resultItemsSelector);
    
    selectResultStatus = () : Observable<number> => this.store.select(fromState.resultStatusSelector);

    selectView = () : Observable<string> => this.store.select(fromState.viewSelector);

    selectEvents = () : Observable<{name: string, id: number | string}[]> => this.store.select(fromState.eventsSelector);

    // selectFinal = () : Observable<boolean> => this.store.select(fromState.resultFinalSelector);

    selectQueryParams = () :  Observable<Search.SearchQueryParams> => this.store.select(fromState.queryParamsSelector);

    reset = () => this.store.dispatch(new fromActions.Clear());

}
