import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LabelsModule } from '@core/components/labels/labels.module';

import { AppRouterModule } from '@app/router.module';

import { ProjectDetailsMainComponent } from './main/main.component';

import { ProjectsDetailsInfoModule } from './info/info.module';
import { ProjectsDetailsVersionsModule } from './versions/versions.module';

import { AppVersionsModule } from '../../versions/versions.module';
import { ProjectsDetailsHelpersModule } from './helpers/helpers.module';
import { ProjectsDetailsMessagesModule } from './messages/messages.module';
import { ProjectsDetailsPreviewNotesModule } from './preview-notes/preview-notes.module';
import { ProjectsDetailsSectionsModule } from './sections/sections.module';
import { ProjectsDetailsForbiddenHeaderModule } from './forbidden-header/forbidden-header.module';
import { ProjectDetailsLogbookModule } from './logbook/logbook.module';

@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        HelpersModule,
        LabelsModule,
        AppVersionsModule,
        ProjectsDetailsInfoModule,
        ProjectsDetailsVersionsModule,
        ProjectsDetailsHelpersModule,
        ProjectsDetailsMessagesModule,
        ProjectsDetailsPreviewNotesModule,
        ProjectsDetailsSectionsModule,
        ProjectsDetailsForbiddenHeaderModule,
        ProjectDetailsLogbookModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsMainComponent,
        // ProjectDetailsMessagesMainComponent,
        
    ],
    exports: [
        ProjectDetailsMainComponent,
        // ProjectDetailsMessagesMainComponent,
    ],

})
export class ProjectsDetailsModule { }
