import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';
import { MessagesService } from '@core/services/messages/messages.service';

import { Version } from '@core/objects/version';
import { User } from '@core/objects/user';
import { Message } from '@core/objects/message';
// import { Request } from '@core/objects/request';
// import { Label } from '@core/objects/label';
// import { Related } from '@core/objects/related';

@Component({
    selector: 'plugin-share-feedback-core-version-approval-message-list',
    templateUrl: './version-approval-message-list.component.html',
    styleUrls: ['./version-approval-message-list.component.scss']
})
export class PluginShareFeedbackCoreVersionsMessageResponseListComponent implements OnInit {

    @Input() messages: Array<any>;
    @Input() user: User;
    @Input() notesActionText: string;
    @Input() notesActionStyle: string;
    @Input() canCreateNotes: boolean = true;
    @Input() prefixUrl: string = '';

    @Output() onDelete: EventEmitter<Message> = new EventEmitter<Message>();

    constructor() { }

    ngOnInit() {
        // this.loadMessageList();
    }

}
