import { Action } from '@ngrx/store';
import { Group } from '@core/objects/group';

export enum SettingsGroupsActionType {

	// EVENT CALLS
	LoadAllGroups= "[SettingsGroups] GET - Obtain all groups",
	LoadAllGroupsSuccess= "[SettingsGroups] - Obtain all groups SUCCESS",
	LoadAllGroupsError= "[SettingsGroups] - Obtain all groups ERROR",

	SaveGroup = "[SettingsGroups] POST - Save group",
	SaveGroupSuccess = "[SettingsGroups] Save group success",
	SaveGroupError = "[SettingsGroups] Save group error",

	ActiveGroup = "[SettingsGroups] PUT - Active Update group",
	DeleteGroup = "[SettingsGroups] DELETE - Delete group",
	
	// SET STORE
	SetAllGroups = "[SettingsGroups] - Set all groups in the store",
	
}

export class LoadAllGroups implements Action {
    readonly type = SettingsGroupsActionType.LoadAllGroups;
    constructor() {}
}

export class LoadAllGroupsSuccess implements Action {
    readonly type = SettingsGroupsActionType.LoadAllGroupsSuccess;
    constructor(public groups: Group[]) {}
}

export class LoadAllGroupsError implements Action {
    readonly type = SettingsGroupsActionType.LoadAllGroupsError;
    constructor() {}
}

export class SaveGroup implements Action {
    readonly type = SettingsGroupsActionType.SaveGroup;
    constructor(public name: string) {}
}

export class SaveGroupSuccess implements Action {
    readonly type = SettingsGroupsActionType.SaveGroupSuccess;
    constructor(public group: Group) {}
}

export class SaveGroupError implements Action {
    readonly type = SettingsGroupsActionType.SaveGroupError;
    constructor() {}
}

export class ActiveGroup implements Action {
    readonly type = SettingsGroupsActionType.ActiveGroup;
    constructor(public group: Group) {}
}

export class DeleteGroup implements Action {
    readonly type = SettingsGroupsActionType.DeleteGroup;
    constructor(public group: Group) {}
}

export class SetAllGroups implements Action {
    readonly type = SettingsGroupsActionType.SetAllGroups;
    constructor(public groups: Group[]) {}
}



export type SettingsGroupsActions = LoadAllGroups |
									LoadAllGroupsSuccess |
									LoadAllGroupsError |
									SaveGroup |
									SaveGroupSuccess |
									SaveGroupError | 
									ActiveGroup | 
									DeleteGroup | 
									SetAllGroups;
									