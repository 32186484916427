import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { UsersService } from '../../../../../services/settings/users.service';
import { Subscription } from 'rxjs';
import { HttpStatus } from '@core/classes/http-status.class';

import { License } from '@core/objects/license';
import { User } from '@core/objects/user';
import { UserRequest } from '@core/objects/user-request';

@Component({
    selector: 'settings-licenses-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class SettingsLicensesMainComponent implements OnInit{

	public showForm:boolean = false;
	public access:boolean = true;
	public inviteSent: boolean = false;

	// Subscription status
	public subscriptionStatusInv: Subscription;
	public subscriptionStatusUsers: Subscription;
	public subscriptionStatusAddInv: Subscription;
    public subscriptionUsers: Subscription;
    public subscriptionInvitation: Subscription;

	// Subscription getData


	// HTTPSTATUS
	public httpStatusInv: HttpStatus;
	public httpStatusUser: HttpStatus;
	public httpStatusAdd: HttpStatus;


	public loadingUser: boolean = true;
	public loadingInv: boolean = true;
	public loadingAdd: boolean = false;

    public selected: any = {
        users: [],
        requests: []
    };

    public breadcrumbs: any = [
        {
            title: "System Settings",
            link: ['/settings']
        }
    ];

    public invitationExtraFields: any = {};

	constructor(public userSrv: UsersService, private viewportScroller: ViewportScroller){}

	ngOnInit(){
		this.userSrv.loadAllInvitations();
		this.userSrv.loadAllUsers();
        //this.userSrv.loadAllLicenses();

		this.subscriptionStatusInv = this.userSrv.selectorStatusInvite().subscribe((statusInv: HttpStatus) => {
			this.httpStatusInv = statusInv;
			if (this.httpStatusInv.code != 0) {
				this.loadingInv = false;

				if (this.httpStatusInv.code != 0 && this.loadingAdd) {
					this.loadingAdd = false;
					this.inviteSent = true;
				}
			}
			switch (this.httpStatusInv.message){
				case "403":
					return this.access = false;
			};
		});

		this.subscriptionStatusUsers = this.userSrv.selectorStatusList().subscribe((statusUser: HttpStatus) => {
			this.httpStatusUser = statusUser;
			if (this.httpStatusUser.code != 0) {
				this.loadingUser = false;
			}
		});

		this.subscriptionStatusAddInv = this.userSrv.selectorStatusAddInvite().subscribe((addInv: HttpStatus) => {
			this.httpStatusAdd = addInv;
			switch (this.httpStatusAdd.code){
				case 0:
					this.loadingAdd = true;
					break;
				case 1:
					this.showForm = false; // ???
					break;
				case 2:
					this.loadingAdd = false;
					break;
			}
		});

        this.subscriptionUsers = this.userSrv.selectorGetAllUsers().subscribe((users: User[]) => {
            this.userSrv.loadAllLicenses();
        });

        this.subscriptionInvitation = this.userSrv.selectorGetAllInvitations().subscribe((inv: UserRequest[]) => {
			this.userSrv.loadAllLicenses();
        });
	}

	controlForm(event){
		//console.log('eveeeent -- ', event);
		this.showForm = !this.showForm;
		this.inviteSent = false;
	}

	newInvitation(event){
		this.userSrv.addNewInvitation(event.name, event.email, event.message, event.groups, event.extra);
	}

    selectLicense(license: License) {
        // this.invitationExtraFields = {
        //     license: license.id
        // };

        this.selected = {
            users: license.users_email.length>0? license.users_email : ['x'],
            requests: license.requests_email.length>0? license.requests_email : ['y']
        };
    }

    inviteLicense(license: License) {
        this.invitationExtraFields = {
            license: license.id
        };

        //this.selectLicense(license);

        this.showForm = true;
		this.inviteSent = false;

        //window.scrollTo(0, 0)
        setTimeout( () => this.viewportScroller.scrollToAnchor('AddInvitationForm'), 500 );
    }

	tryAgain(event){
		switch (event){
			case 'users':
				this.loadingUser = true;
				this.userSrv.loadAllUsers();
				break;

			case 'invitations':
				this.loadingInv = true;
				this.userSrv.loadAllInvitations();
				break;
		}
	}
}
