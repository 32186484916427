import { Component, OnInit, Inject, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { TitleService } from '@core/services/title.service';
import { WebsocketService } from '@core/services/websocket/websocket.service';
import { WindowService } from '@core/services/window.service';
import { KeyPressService } from '@app/services/app/keypress.service';
import { Router } from '@angular/router';

import { environment } from '@environment/environment';

import { AppService } from '@app/services/app/app.service';
import { AppBehaviorService } from 'app/services/app/app-behavior.service';
import { App, AppStatus, AppStatusValues } from 'app/store/state/app/app.state';
// import { LibraryService } from 'app/services/app/library.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class AppMainComponent implements OnInit, OnDestroy {

    //public urlGet:string = '/web/app/session';
    public status: AppStatus;
    public session: any = null;
    public logged: boolean = true;
    public disconnected: boolean = false;

    public readonly appStatus = AppStatusValues;


    private appSubscriber: Subscription;

    constructor(
        private app: AppService,
        private behavior: AppBehaviorService,
        // private library: LibraryService,
        // public websocket: WebsocketService,
        private window: WindowService,
        protected title: TitleService,
        private router: Router,
        private keyPressSrv: KeyPressService) {

        //this.url.setPrefix("/web");
        this.title.setPrefix("Twona");
    }

    @HostListener('window:blur', ['$event'])
    onBlur(event: any): void {
        this.keyPressSrv.distributeKeyPressed(null); // clean keydown and keypressed on leave page
    }

    @HostListener('document:keydown', ['$event'])
    handleKeydown(event: KeyboardEvent) {
        // event.keyCode is deprecated
        this.keyPressSrv.distributeKeyPressed(event);
    }
    @HostListener('document:keyup', ['$event'])
    handleKeyup(event: KeyboardEvent): void {
        // console.log(" handle keyup ",event);
        this.keyPressSrv.distributeKeyPressed(event);
    }

    ngOnInit(){
        this.behavior.start();

        // this.websocket.getState$().subscribe(state => {
        //     if(state.current == 'unavailable'){
        //         this.disconnected = true; // it suppose that user reload to quit the message
        //     }
        // })

        this.app.getSession().subscribe( (session: any) => {
            this.session = session;

            if (session == null)
                return;

            // this.websocket.connect(session.configuration.websocket.pusher.key, session.configuration.websocket.pusher.cluster);
            // this.websocket.addChannel('previews');
            // let privateChannel = this.websocket.addChannel('private-usr_'+session.user.id);
            // privateChannel.bind('pusher:subscription_error', status => {
            //     this.app.setHttpError(status); // auth error
            // });

            // wootric
            this.window.nativeWindow['wootricSettings'] = {
                email: this.session.user.email, // TODO: Required to uniquely identify a user. Email is recommended but this can be any unique identifier.
                created_at: new Date(this.session.user.date_created).getTime() / 1000,
                account_token: 'NPS-d8cba788' // This is your unique account token.
            };

            this.window.nativeWindow['wootric']('run');

        });


        // this.app.init();

        // this.app.getLogged().subscribe(
        //     (logged:any) => {
        //         this.logged = !(!logged && this.session != null);
        //         //console.debug("logged", logged);
        //     }
        // );

        this.app.getHttpError().subscribe(
            (error:number) => {
                switch(error) {
                    case AppService.HTTP_ERROR_NOT_LOGGED:
                        this.logged = false;
                        if(this.session == null)
                            window.location.href = environment.loginBasePath + '?href='+encodeURIComponent(window.location.href);

                        break;
                    case AppService.HTTP_ERROR_TOO_MANY_CONNECTIONS:
                        window.location.href = environment.loginBasePath + '/sessions?href='+encodeURIComponent(window.location.href);
                        break;
                }
                //this.logged = !(!logged && this.session != null);
                //console.debug("logged", logged);
            }
        );

        // this.app.getVersion().subscribe(
        //     (version: any) => {
        //         this.version = version;
        //         //console.debug("version: ", version);
        //     }
        // );

        this.app.getStatus().subscribe((status: AppStatus) => {
            this.status = status;
            // switch(status.value) {
            //     case AppStatusValues.DISCONNECTED:
            //         this.disconnected = true;
            //         break;
            //     case AppStatusValues.VERSION_CHANGED:
            //         this.version = true;
            // }
        });



        /*let options = { headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};

        this.httpClient.get<string>(this.urlGet, options)
        .subscribe(
            (data:any) => {
                console.log('user success: '+JSON.stringify(data));
                this.user = data.user;
                this.loading = false;
            },
            (error:any) => {
                console.log('Error user: '+JSON.stringify(error));
                this.user = null;
                this.loading = false;
            }
        );*/

    }

    ngOnDestroy(): void {
        this.behavior.end();
    }

    changeOrg(value: number){
        this.session = null;
        this.behavior.changeOrganization(value);
        this.router.navigate(['/']);
    }
    getLayoutClasses(){
        if (this.router.url.includes('/library') || this.router.url.startsWith('/versions'))
            return {'with-overlay': !this.logged,'library-bg':true};
        if (this.router.url.startsWith('/settings'))
            return {'with-overlay': !this.logged,'settings-bg':true};
        if (this.router.url.startsWith('/projects/add'))
            return {'with-overlay': !this.logged,'add-project-bg':true};

        return {'with-overlay': !this.logged,'dashboard-bg':true};
    }
}
