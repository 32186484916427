import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HelperFormModule } from '@core/components/helpers/form/form.module';

import { LayoutModalFeedbackComponent } from './modal-feedback/modal-feedback.component';
import { LayoutModalMessageOneActionComponent } from './modal-message-one-action/modal-message-one-action.component';
import { LayoutModalMessageTwoActionComponent } from './modal-message-two-action/modal-message-two-action.component';
import { LayoutModalInputComponent } from './modal-input/modal-input.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HelperFormModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LayoutModalFeedbackComponent,
        LayoutModalMessageOneActionComponent,
        LayoutModalMessageTwoActionComponent,
        LayoutModalInputComponent
    ],
    exports: [
        LayoutModalFeedbackComponent,
        LayoutModalMessageOneActionComponent,
        LayoutModalMessageTwoActionComponent,
        LayoutModalInputComponent
    ],

})
export class LayoutModalsModule { }
