import { Component, Input, Output, OnInit,EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProjectsEditorService, ProjectsService } from '@app/services/app/projects.service';

import * as Objects from '@core/objects';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { FilesListItemMenuClick } from '@core/components/files/lists/files-list-item-menu-click';
import { UrlService } from '@core/services/url/url.service';
import { AppService } from '@app/services/app/app.service';
import { AppRouterService } from '@app/services/app/router.service';


@Component({
    selector: 'project-details-info-attachments-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ProjectDetailsInfoAttachmentsListComponent implements OnInit, OnDestroy {

    protected readonly ACCESS_ACTIVATE_FILES = "access_request_set_activate_file";
    protected readonly PLUGIN_SEND_FILES = 'share_transfer';
    protected readonly PLUGIN_APOLLO = 'apollo';

    @Input() project: Objects.Request = null;
    @Input() files: Array<Objects.File> = [];
    @Input() notes: Array<Objects.Note> = [];
    @Output() actionClick = new EventEmitter<Objects.File>();
    // TODO maybe import project to set url for notes...
    //      or set output notes btn click with file to redirect in father component


    public pluginsAccess: Array<string> = [];
    // we need order here for multiple items on menu
    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "Download",
            key: "download",
            icon: "download",
            order: 1
        },
        {
            title: "See Notes",
            key: "notes",
            icon: "notes",
            order: 4
        }
    ];
    protected menuSendFiles: ThreeDotsMenuItem = {
        title: "Send",
        key: "send-files",
        icon: "send-files",
        order: 2
    };
    protected menuCompare: ThreeDotsMenuItem = {
        title: "Compare",
        key: "compare",
        icon: "compare",
        order: 3
    };
    protected menuDeactivateFile: ThreeDotsMenuItem = {
        title: "Remove",
        key: "remove-files",
        icon: "remove-files",
        order: 5
    };

    public showInactives: boolean = false;
    public accessActivation: boolean = false;

    protected accessSubscriptor: Subscription;
    protected appSubscriptor: Subscription;

	constructor( public app: AppService,
        protected projectsSrv: ProjectsService,
        private editorSrv: ProjectsEditorService,
        private urlSrv: UrlService,
        private appRouter: AppRouterService ){}



	ngOnInit(){
        // control access to activate/deactivat files in components
        this.accessSubscriptor = this.editorSrv.selectAccess(this.ACCESS_ACTIVATE_FILES)
            .subscribe((access: boolean) => {
                this.accessActivation = access;
                this.updateMenu(this.menuDeactivateFile,this.accessActivation)
            });

        this.appSubscriptor = this.app.getSession().subscribe( (session: any) => {
            this.pluginsAccess = session.options.plugins;
            this.updateMenu(this.menuSendFiles,this.checkPluginAccess(this.PLUGIN_SEND_FILES))
            this.updateMenu(this.menuCompare,this.checkPluginAccess(this.PLUGIN_APOLLO));
        });
	}


    onClickDeactivate(file: Objects.File){
        console.debug("click deactivate",file,this.accessActivation);
        if(!this.accessActivation)
            return;
        this.projectsSrv.setActiveFile(file, false);
    }

    onMenuClick(menu: FilesListItemMenuClick) {
        switch(menu.key) {
            case "remove-files":
                this.onClickDeactivate(menu.file);
                break;
            case "notes":
                this.actionClick.emit(menu.file);
                break;
            case "download":
                window.location.href = this.urlSrv.build('/files/'+menu.file.id);
                break;
            case "send-files":
                if (this.checkPluginAccess(this.PLUGIN_SEND_FILES))
                    this.appRouter.navigate('/p/transfer/'+this.project.id, { queryParams: { file: +menu.file.id} });
                break;
            case "compare":
                if (this.checkPluginAccess(this.PLUGIN_APOLLO))
                    this.appRouter.navigate('/p/apollo/request/'+this.project.id);//, { queryParams: { reference: +menu.file.id} });
                break;
        }
    }

    ngOnDestroy() {
        this.accessSubscriptor.unsubscribe();
        this.appSubscriptor.unsubscribe();
    }

    protected checkPluginAccess(plugin: string){
        return this.pluginsAccess.some((pa) => pa == plugin);
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }




}
