import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { PreviewModule } from '@core/components/preview/preview.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

//import { ModalModule } from '../modals/modal.module';
import { AngularDraggableModule } from 'angular2-draggable';


//import { NoteBoxComponent } from './note-box/note-box.component';
//import { NoteInsertComponent } from './note-insert/note-insert.component';
import { NotesLegacyComponent } from './notes-legacy/notes-legacy.component';
import { NoteComponent } from './note/note.component';
import { NotesPaginationComponent } from './notes-pagination/notes-pagination.component';


@NgModule({
    imports: [
        CommonModule,
        PreviewModule,
        AngularDraggableModule,
        ReactiveFormsModule,
        FormsModule,
        //ModalModule,
        HelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        NotesLegacyComponent,
        NoteComponent,
        NotesPaginationComponent
    ],
    exports: [
        NotesLegacyComponent,
        NoteComponent,
        NotesPaginationComponent
    ],

})
export class LegacyNotesModule { }
