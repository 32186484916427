import { Component, Input, Output, OnInit,EventEmitter, ViewChild, ViewContainerRef, TemplateRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';


import { File } from '@core/objects/file';


@Component({
    selector: 'app-helpers-add-object-files-upload',
    templateUrl: './add-object-files-upload.component.html',
    styleUrls: ['./add-object-files-upload.component.scss']
})
export class HelpersFormAddObjectFilesUploadComponent implements OnInit,OnChanges {

    @Input() files: File[] = [];
    @Input() title: string = '';
    @Output() changed = new EventEmitter<File[]>();
    @Output() uploading = new EventEmitter<boolean>();
    public removed: File = null;

	ngOnInit(){
        // this.renderUploader();
	}
    ngOnChanges(changes: SimpleChanges){
        // if(changes.files && changes.files.currentValue.length == 0){
        //     // console.debug(" upload add clean")
        //     this.onCancel();
        // }
    }
    
    upload(files: Array<File>){
        this.files = files;
        this.changed.emit(this.files);
    }

    remove(file: File) {
        this.removed = file;
        this.files = this.files.filter((f: File) => file.id != f.id);
        this.changed.emit(this.files);
    }

    // send up the flag when a file is uploading
    onUploading(uploading: boolean){
        this.uploading.emit(uploading);
    }

}