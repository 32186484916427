import { Component, Input } from '@angular/core';

import * as Objects from '@core/objects';


@Component({
    selector: 'project-details-default-header',
    templateUrl: './default-header.component.html',
    styleUrls: ['./default-header.component.scss']
})
export class ProjectDetailsDefaultHeaderComponent {
	@Input() project: Objects.Request = null;

}
