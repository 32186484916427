import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PreviewRequest } from '../../../objects/preview-request';

import { UrlService } from '../../../services/url/url.service';
import { PreviewsService } from '../../../services/previews/previews.service';
//import { WebsocketService } from '../../../services/websocket/websocket.service';
import { File } from '../../../objects/file';

@Component({
    selector: 'core-preview-first',
    templateUrl: './preview-first.component.html',
    styleUrls: ['./preview-first.component.scss'],
    providers: []
})
export class PreviewFirstComponent implements OnInit, OnDestroy{

    @Input() files: Array<File>;
    @Input() format: string;

    public exist: boolean = true;
    public pending: boolean = true;
    public fileIdFound: number = 0;
    //protected metadatas: Array<PreviewRequest> = [];
    protected metadata: any = null;
    // Temporal page
    public page: number = 1;
    public nameFile:string;

    protected loading: boolean = false; // not use

    //protected websocketSubscriptor;
    protected preview$: Subscription;

    constructor(protected preview: PreviewsService, public http: HttpClient, public url: UrlService,) {}

    ngOnInit(){
        if(this.files.length > 0) {
            // this.websocketSubscriptor = this.ws.subscribe("preview.ready", data => {
            //     this.previewReady(data.id);
            // });
            this.preview$ = this.preview.getFirstSubscriptor(this.files).subscribe(preview => {
                console.debug("preview first ---->", preview);
                this.setMetadata(preview);
                //this.previewReady(preview.id);
                // if (this.preview === null){
                //     this.setPage(1);
                // } else {
                //     this.setPage(this.item.page_selected);
                // }
                // this.preview = preview;
                // this.filtersActions.ignore = preview.total_pages > 1;
            });

            this.preview.loadFirst(this.files);
        }

        // this.preview$ = this.prwSrv.getSubscriptor(file).subscribe(preview => {
        //             console.debug("preview subscriber---->", preview);
        //             if (this.preview === null){
        //                 this.setPage(1);
        //             } else {
        //                 this.setPage(this.item.page_selected);
        //             }
        //             this.preview = preview;
        //             this.filtersActions.ignore = preview.total_pages > 1;
        //         // })

    }

    ngOnDestroy(){
        if(typeof this.preview$ != 'undefined')
            this.preview$.unsubscribe();
    }

    // previewReady(id: number) {
    //     let founds = this.files.filter(f => f.id == id);
    //     if(this.pending && founds.length > 0) {
    //         this.initMetadatas();
    //     }
    // }
    //
    // initMetadatas() {
    //     this.obtainFirstMetadata(this.files).subscribe(
    //         (data: any) => {
    //             this.setMetadata(data);
    //         }
    //     );
    //     /*for(let file of this.files) {
    //         this.obtainMetadata(file).subscribe(
    //             (data: any) => {
    //                 this.addMetadata(data);
    //             }
    //         );
    //     }*/
    //
    // }

    protected setMetadata(metadata: any) {
        this.metadata = metadata;
        this.checkMetadata();
    }

    checkMetadata() {
        let nonexist = 0;
        let cont = 0;

        if(!this.metadata)
            return;

        this.pending = false;
        switch(this.metadata.status_code) {
            case 1: // done
                this.setFileIdFound(this.metadata.id_file);
                //this.pending = false;
                break;
            case 3: // non exist
                this.exist = false;
                break;
            case 0: // pending
                this.pending = true;
                break;
        }

        return;
    }

    obtainFirstMetadata(files: Array<any>) {
        let ids = files.map(f => { return f.id;}).join(",");
        return this.http.get(this.url.build("/preview/first/" + ids));
        //return this.http.get(this.url.build("/files/" + file.id + "/preview"));
    }

    //obtainMetadata(file: any) {
    //    return this.http.get(this.url.build("/files/" + file.id + "/preview"));
    //}

    //addMetadata(data: PreviewRequest) {
    //    let updateItem = this.metadatas.find( e => e.id_file == data.id_file);

        // new metadata
    //    if(typeof updateItem === 'undefined') {
    //        this.metadatas.push(data);
        // existing metadata
    //    } else {
    //        let index = this.metadatas.indexOf(updateItem);
    //        this.metadatas[index] = data;
    //    }

        // last file metadata preview is loaded
    //    if(this.metadatas.length == this.files.length)
    //        this.checkMetadatas();
    //}

    /*checkMetadatas() {
        let nonexist = 0;
        console.log("METADATAS", this.metadatas);
        let cont = 0;
        while(cont < this.metadatas.length && this.fileIdFound == 0) {
            let meta = this.metadatas[cont];
            if(meta.status_code == 1)
                this.setFileIdFound(meta.id_file);
                this.pending = false;
            if(meta.status_code == 3)
                nonexist++;
                this.pending = false;
            if(meta.status_code == 0)
                this.pending = true;

            cont++;
        }

        if(this.metadatas.length == nonexist)
            this.exist = false;
    }*/

    setFileIdFound(id: number) {
        console.log("FOUND -->", id);
        this.fileIdFound = id;
        //.websocketSubscriptor.unsubscribe();
        this.preview$.unsubscribe();
    }

}
