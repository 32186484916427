import { Component, EventEmitter, Input, Output} from '@angular/core';

import * as Objects from '@core/objects';

@Component({
    selector: 'core-labels-list',
    templateUrl: './labels-list.component.html',
    styleUrls: ['./labels-list.component.scss'],
    providers: []
})
export class LabelsListComponent {
    @Input() labels: Array<Objects.Label>;
    @Output() choose  = new EventEmitter<Objects.Label>();

    action(label: Objects.Label) {
        this.choose.emit(label);
    }
}
