import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';

import { User } from '@core/objects/user';
import { Group } from '@core/objects/group';

import * as fromSettingsGroupsState from '../../store/state/settings/groups/groups.state';
import * as fromSettingsGroupsActions from '../../store/state/settings/groups/groups.actions';

import { GroupsRequests } from '../../store/state/settings/groups/groups.requests';


@Injectable({
    providedIn: 'root'
})
export class GroupsService {

    constructor(private store: Store<fromSettingsGroupsState.SettingsGroups>, private requests: GroupsRequests) {}

	// Selectors store
	selectorGetAllGroups(){
		return this.store.select(fromSettingsGroupsState.groupsGetAllGroupsSelector);
	}	

	selectorStatusGroups(){
		return this.store.select(fromSettingsGroupsState.groupsStatusGetGroups);
	}

	selectorStatusSaveGroup(){
		return this.store.select(fromSettingsGroupsState.groupsStatusSaveGroup);
	}

	// Actions
	loadAllGroups(){
		this.store.dispatch( new fromSettingsGroupsActions.LoadAllGroups());
	}

	saveGroup(name: string){
		this.store.dispatch( new fromSettingsGroupsActions.SaveGroup(name));
	}

	activeGroup(group: Group){
		this.store.dispatch( new fromSettingsGroupsActions.ActiveGroup(group));
	}

	deleteGroup(group: Group){
		this.store.dispatch( new fromSettingsGroupsActions.DeleteGroup(group));
	}

	// findInactiveGroups() {
	// 	return this.requests.loadAllInactiveGroups$();
	// }

	findGroupUsers(group: Group) {
		return this.requests.findUsers$(group);
	}

	deleteUser(group: Group, user: User) {
		return this.requests.deleteUser$(group, user);
	}

	addUser(group: Group, user: User) {
		return this.requests.addUser$(group, user);
	}
}
