import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as Objects from '@core/objects';
import { projectLogbookEventKeys } from '../../event-keys';


@Component({
    selector: 'project-details-logbook-event-header',
    templateUrl: './event-header.component.html',
    styleUrls: ['./event-header.component.scss']
})
export class ProjectDetailsLogbookEventHeaderComponent {
	@Input() event: Objects.Event;
    @Output() open = new EventEmitter();
    @Output() selectGroup = new EventEmitter<string>();

    public opened: boolean = false;
    public titles: Objects.EventTitle[] = projectLogbookEventKeys;

    public toogleDetails() {
        this.opened = !this.opened;
        
        this.open.emit();
    }

    getTitle(event: Objects.Event){
        let text = this.titles.filter((e: Objects.EventTitle)=> e.key == event.type);
        return text.length > 0 ? text[0].title : 'Not defined yet ==> '+event.type;
    }

    displayGroup(group: string) : void {
        this.selectGroup.emit(group);
    }

}