import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import {VersionsViewService } from '@app/services/app/versions.service';
import { AppRouterService } from '@app/services/app/router.service';

import { VersionListItemMenuClick } from '@core/components/versions/list/version-list-item-menu-click';

import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';
import { TitleService } from '@core/services/title.service';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { AppService } from '@app/services/app/app.service';
import { VersionsService } from '@app/services/app/versions.service';
import { AccessVersion } from '@app/models/access';
import { AddProjectService } from '@app/services/app/add-project.service';

@Component({
    selector: 'versions-related-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class VersionsRelatedMainComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_ERROR = 2;
    public readonly STATUS_NOT_FOUND= 3;
    public readonly STATUS_NOT_ALLOWED= 4;

    protected readonly PLUGIN_REQUEST_FEEDBACK = 'share_feedback';
    protected readonly PLUGIN_SEND_FILES = 'share_transfer';
    protected readonly ACCESS_ADD_PROJECT = "access_global_new_request";
    protected readonly ACCESS_BULK_EDIT_TAGS =  'access_global_bulk_edit_versions_labels';

    public version: Objects.Version = null;
    public project: Objects.Request = null;
    public bcTitle: string = 'OTHER ARTWORKS OF v. ...';
    public relateds: Objects.Version[] = null;
    public feedbacks: Array<VersionFeedback> = [];
    private feedbacksTmpVersions: Array<Objects.Version> = [];

    public excludedLabels: Array<Objects.Label> = [];

    public pluginsAccess: Array<string> = [];
    public versionAccess: AccessVersion = null;
    public uploadVersionAccess: boolean = false;
    public addProjectAccess: boolean = false;
    public editBulkTagsAccess: boolean = false;
    
    public context: string = '';

    // TODO review menu icons
    public menuHeader: Array<ThreeDotsMenuItem> = [];
    public menu: Array<ThreeDotsMenuItem> = [{
        title: "See Artwork Details",
        key: "details",
        icon: "see-details",
        order: 1
    }];
    // optional header menu items
    // protected menuUploadVersion: ThreeDotsMenuItem = {
    //     title: "Upload Artwork",
    //     key: "upload-artwork",
    //     icon: "upload_artwork",
    //     order: 1
    // };
    protected menuHeaderSendFiles: ThreeDotsMenuItem = {
        title: "Send",
        key: "send-files",
        icon: "send-files",
        order: 2
    };
    protected menuHeaderRequestFeedback: ThreeDotsMenuItem = {
        title: "Request Feedback",
        key: "feedback",
        icon: "feedback",
        order: 3
    };
    protected menuHeaderEditTags: ThreeDotsMenuItem = {
        title: "Edit Group Tags",
        key: "edit",
        icon: "edit-tags",
        order: 4
    };
    // optional versions items menus 
    protected menuSeeOriginalProject: ThreeDotsMenuItem = {
        title: "See Original Project",
        key: "see-project",
        icon: "see-project",
        order: 2
    };
    protected menuAddProject: ThreeDotsMenuItem = {
        title: "Start New Project",
        key: "add-project",
        icon: "add",
        order: 3
    };



    public status:number = this.STATUS_LOADING;
    public breadcrumbs: any = [];
    protected urlSubscriptor: Subscription;
    protected versionSubscriptor: Subscription;
    protected versionRelatedSubscriptor: Subscription;
    protected originProjectSubscriptor: Subscription;
    protected accessVersionSubscriptor: Subscription;
    protected errorSubscriptor: Subscription;
    protected groupSubscriber: Subscription;

    constructor(
        public app: AppService,
        private router: Router,
        private title: TitleService,
        private activeRoute: ActivatedRoute,
        private viewVersionsSrv: VersionsViewService,
        private versionsSrv: VersionsService,
        private AddProjectSrv: AddProjectService,
        private appRouter: AppRouterService
    ) { }

    ngOnInit() {
        this.setDefaultBredcrumb();
        // set temporal header title
        this.title.set("Other Artworks...");

        // get the version
        this.versionSubscriptor = this.viewVersionsSrv.selectVersion().subscribe((version: Objects.Version) =>
        {
            this.version = version; 
            if(version != null){
                this.bcTitle = 'OTHER ARTWORKS OF v.'+ version.version;
                this.viewVersionsSrv.loadRelated(this.version.labels.map((l: Objects.Label) => l.id).sort().join('-'));
            }
        });

        this.versionRelatedSubscriptor = this.viewVersionsSrv.selectRelatedVersions().subscribe((relateds: Objects.Version[]) => {
            this.relateds = relateds; 
            this.initView();
        });

        this.originProjectSubscriptor = this.viewVersionsSrv.selectProject().subscribe((projectOrigin: Objects.Request) => {
            this.project = projectOrigin
            this.initView();
        });


        // plugins & add project from session
        this.app.getSession().subscribe( (session: any) => {
            this.pluginsAccess = session.options.plugins;
            this.addProjectAccess = session.options.access.hasOwnProperty(this.ACCESS_ADD_PROJECT)? session.options.access[this.ACCESS_ADD_PROJECT] : false;
            this.editBulkTagsAccess = session.options.access.hasOwnProperty(this.ACCESS_BULK_EDIT_TAGS)? session.options.access[this.ACCESS_BULK_EDIT_TAGS] : false;
            

            this.updateMenuHeader(this.menuHeaderSendFiles,this.checkPluginAccess(this.PLUGIN_SEND_FILES));
            this.updateMenuHeader(this.menuHeaderRequestFeedback,this.checkPluginAccess(this.PLUGIN_REQUEST_FEEDBACK));
            this.updateMenu(this.menuAddProject,this.addProjectAccess);
            this.updateMenuHeader(this.menuHeaderEditTags,this.editBulkTagsAccess);
        });


    }

    ngOnDestroy() {
        this.versionSubscriptor.unsubscribe();
        this.originProjectSubscriptor.unsubscribe();
        this.versionRelatedSubscriptor.unsubscribe();
        if(this.groupSubscriber)
            this.groupSubscriber.unsubscribe();
        // this.viewVersionsSrv.clear();
    }

    private initView() {
        if(this.version == null || this.project == null || this.relateds == null)
            return;
        this.loadFeedbacks(this.relateds);
        this.setHeaderTitle();
        this.breadcrumbs[0] = {link: "/versions/"+this.version.id,
            title: 'Artwork v.'+this.version.version+' <small>(#'+this.version.id+')</small>'};
        this.updateMenu(this.menuSeeOriginalProject);
        this.status = this.STATUS_DONE;
    }

    private setHeaderTitle() {
        this.title.set("Other Artworks - Artwork #"+ this.version.id);
    }
    // TODO separate in header menu and list related menus
    onMenuClick(menu: VersionListItemMenuClick) {
        console.log(" menu click "+menu.key,menu)
        switch(menu.key) {
            case "details":
                // go to version Details
                this.appRouter.navigate("/versions/"+menu.version.id);
                break;
                
            case "add-project":
                if(this.version != null){
                    this.AddProjectSrv.clear();
                    this.appRouter.navigate('/projects/add', { queryParams: { version: menu.version.id} });
                }
                break;
            case "see-project":
                if(menu.version != null){
                    this.versionsSrv.getProject$(menu.version).subscribe((originalProject: Objects.Request) => {
                        if(originalProject != null)
                            this.appRouter.navigate('/projects/'+originalProject.id);
                    });
                }
                break;
        }
    }

    onMenuHeaderClick(key: string) {
        switch(key) {
            // case "upload-artwork":
            //     this.appRouter.navigate('/projects/'+this.project.id+'/versions/add', { queryParams: { links: this.version.labels.map((l: Objects.Label) => l.id).sort().join("-") } });
            //     break;
            case "edit":
                break;
            case "feedback":
                if (this.checkPluginAccess(this.PLUGIN_REQUEST_FEEDBACK))
                    this.appRouter.navigate('/p/feedback/'+this.project.id, { queryParams: { version: this.version.id} });
                break;
            case "send-files":
                if (this.checkPluginAccess(this.PLUGIN_SEND_FILES))
                    this.appRouter.navigate('/p/transfer/'+this.project.id, { queryParams: { version: this.version.id} });
                break;
        }
    }

    getFeedbacks(version : Objects.Version){
        return this.feedbacks.filter((f: VersionFeedback) => f.version.id == version.id);
    }

    onFileClick(version: Objects.Version,file: Objects.File){
        if (file != null && this.project != null){
            //  relative to this url to preview version FILE
            this.router.navigate(["versions/"+version.id+"/files/"+file.id], { relativeTo: this.activeRoute });
        }
    }

    onSetGroupLabels(labels: Array<Objects.Label>){
        this.versionsSrv.setVersionsGroupLabels$(this.relateds, labels).subscribe((versions: Objects.Version[]) => {
            // console.debug(" related updated ",versions);
            this.relateds = versions;
        });
    }


    protected checkPluginAccess(plugin: string){
        return this.pluginsAccess.some((pa) => pa == plugin);
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

    protected updateMenuHeader(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menuHeader = this.menuHeader.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menuHeader.push(item);
        }
    }

    private loadFeedbacks(versions: Array<Objects.Version>) {
        if (!this.checkPluginAccess(this.PLUGIN_REQUEST_FEEDBACK))
            return;
        let vdiff = versions.filter((v: Objects.Version) => !this.feedbacksTmpVersions.some((vf: Objects.Version) => vf.id == v.id));

        if (vdiff.length == 0)
            return;

        this.versionsSrv.getFeedbacks$(vdiff).subscribe((feedbacks: VersionFeedback[]) => {
            this.feedbacks = [...this.feedbacks, ...feedbacks];
        });

        this.feedbacksTmpVersions = versions;
    }

    protected setDefaultBredcrumb(){
        this.breadcrumbs[0] = {link: '',
                            title: 'Artwork v. ...  <small>(# .  .  . )</small>'};
    }


}
