import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { AppService } from '@app/services/app/app.service';
import { ProjectsService } from '@app/services/app/projects.service';

import { TitleService } from '@core/services/title.service';

import * as Objects from '@core/objects';

import { User } from '@core/objects/user';
import { Message } from '@core/objects/message';
import { Request } from '@core/objects/request';

@Component({
    selector: 'project-details-messages-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectDetailsMessagesMainComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    public status: number = 0;
    public showAdd: boolean = false;
    public showAuditTrail: boolean = false;
    public messageAddUrl: string = '';
    public messagesPrefixUrl: string = '';
    public project: Request = null;
    public user: User;
    public comments: Array<Message> = [];


    public breadcrumbs: any = [
        {
            title: "",
            link: ['']
        }
    ];

    protected subscriptor: Subscription;
    protected accessSubscriptor: Subscription;

	constructor(
        public appSrv: AppService, 
        private projectsSrv: ProjectsService,
        private title: TitleService
    ){}

	ngOnInit(){
        this.title.set("Messages - Project Details - Projects");

        this.appSrv.getSession().subscribe(session => {
            this.user = session.user;
        });
        // get request
        this.subscriptor = this.projectsSrv.selectProject().subscribe((project: Request ) => {
            if(project == null) return;
            
            this.setProject(project);
        });
    }

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
    }

    public toggleAuditTrail(){
        this.showAuditTrail = !this.showAuditTrail;
    }

    // check either message is a traking mesage saved by rule action
    public isTracking(message: Message){
        if(message.text != undefined)
            return message.text.indexOf("(TWONA)")!= -1 || message.text.indexOf("(TWONAS)")!= -1;
        return false;
    }

    protected setProject(project: Request) {
        this.updateBreadcrumbs(project);
        this.project = project;
        this.title.set("Messages - Project "+this.project.id);

    }

    protected updateBreadcrumbs(project: Objects.Request){
        this.breadcrumbs[0].link = '/projects/'+project.id;
        this.breadcrumbs[0].title = 'Project <small>(#'+project.id+ ')</small>';
    }

}
