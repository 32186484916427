import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Label } from '@core/objects/label';

@Component({
    selector: 'core-label-action',
    templateUrl: './label-action.component.html',
    styleUrls: ['./label-action.component.scss'],
    providers: []
})
export class LabelActionComponent {
    @Input() label: Label;
    @Input() style: string = '';
    @Input() actionText: string = 'X';
    @Output() actionClick = new EventEmitter<Label>();

    constructor(){}


    action(label: Label){
        this.actionClick.emit(label);
    }
    
}


