import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';


// Graphic
import { keylineOptions } from '../../../data/keyline';
import { registerOptions } from '../../../data/register';
import { resolutionOptions } from '../../../data/resolution';
import { zoomOptions } from '../../../data/zoom';


// Content
import {methodOptions} from '../../../data/method';

import { SelectItem } from '@core/models/select-item.model';

@Component({
    selector: 'plugin-apollo-helpers-tools-bar',
    templateUrl: './tools-bar.component.html',
    styleUrls: ['./tools-bar.component.scss']
})
export class PluginApolloHelpersToolsBarComponent implements OnInit, OnChanges{

    @Input() values: any = { zoom: 50};
    @Input() optionSelected:string;

	@Input() disabled: boolean;

    @Output() onChange = new EventEmitter<any>();

    public options = {
        keyline: keylineOptions,
        register: registerOptions,
		resolution: resolutionOptions,
		method: methodOptions
    };

    ngOnInit() {
        this.setValues();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setValues();
    }

    protected setValues() {
        if(this.values.keyline != null && this.values.keyline != undefined) {
            this.options.keyline = this.select(this.options.keyline, this.values.keyline);
        }

        if(this.values.register != null && this.values.register != undefined) {
            this.options.register = this.select(this.options.register, this.values.register);
        }

        if(this.values.resolution != null && this.values.resolution != undefined) {
            this.options.resolution = this.select(this.options.resolution, this.values.resolution);
		}

		if (this.values.method != null && this.values.method != undefined) {
			this.options.method = this.select(this.options.method, this.values.method);
		}
    }

    protected select(items: SelectItem[], selected: any) : SelectItem[] {
        return items.map((i: SelectItem) => {
            i.selected = i.value == selected;
            return i;
        })
    }

    toolSelect(title, option){
        console.log('toolSelect ----', title, option);

        switch(title) {
            case "zoom":
                var value = option;
                break;
            default:
                var value = option.value
        }

        this.values[title] = value;

        let val = {...this.values};
        this.onChange.emit(val);
    }
}
