import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

import { SearchFilterMenuItem } from '../models/filters-menu';

@Component({
    selector: 'app-helpers-search-filters-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class HelpersSearchFiltersMainComponent {

    @Input() filters: Array<SearchFilterMenuItem> = [];
    @Output() chooseFilter = new EventEmitter<string>();
    
    public open: boolean = false;
    private wasInside: boolean = false;
    public selectedFilter: string = '';

    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }
  
    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.open = false;
        }
        this.wasInside = false;
    }


    onSelect(key: string){
        this.selectedFilter = key;
        this.chooseFilter.emit(key);
    }

    


}
