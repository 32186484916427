import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, throwError, Subscription } from 'rxjs';

import { UrlService } from '@core/services/url/url.service';
import { TitleService } from '@core/services/title.service';

import { File } from '@core/objects/file';
import { Request } from '@core/objects/request';

class SelectedFilesToCompare {
    public target: File = null;
    public reference: File = null;
    //protected groups: Array<SelectedFilesToCompareGroupFiles> = [];
    public selected: Array<File> = [];
    public completed: boolean = false;

    isComplete(): boolean {
        //return this.reference!= null && this.target != null;
        return this.selected.length == 2;
    }

    changeOrder() {
        let tmp = this.target;
        this.target = this.reference;
        this.reference = tmp;
    }

    setSelected(files: Array<File>) {
        this.selected = files;

        this.applyChanges();
    }

    applyChanges() {
        //return from(this.selected)
        this.removeIfNotExist('reference');
        this.removeIfNotExist('target');

        this.setIfExist('reference');
        this.setIfExist('target');

        this.completed = this.isComplete();
    }

    remove(type: string) {
        let file = this.get(type);
        let files = this.selected.filter(f => f.id != file.id);
        this.setSelected(files);
    }

    protected has(type: string): boolean {
        return this[type] != null;
    }

    protected get(type:string): File | null {
        return this[type];
    }

    protected removeIfNotExist(type: string ) {
        if(this.has(type)) {
            let file = this.get(type);
            let files = this.selected.filter(f => f.id == file.id);
            if(files.length == 0)
                this[type] = null;
        }
    }

    protected setIfExist(type: string) {
        let opposite = type == 'reference'? 'target' : 'reference';
        if(!this.has(type)) {
            let files = [...this.selected];
            if(this.has(opposite)) {
                let remove = this.get(opposite);
                files = files.filter(f => f.id != remove.id);
            }

            if(files.length > 0)
                this[type] = files[0];

        }
    }

}

@Component({
    selector: 'plugin-apollo-main-section-request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss']
})
export class PluginApolloMainSectionRequestComponent implements OnInit, OnDestroy {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly NOT_FOUND = 3;

    readonly uploadUrl: string = "/p/apollo/upload/";

    public reference: File = null;
    public target: File = null;

    public status:number = this.LOADING;
    public request: Request = null;
    public filesUrl: string = "";

    public files: SelectedFilesToCompare = new SelectedFilesToCompare();


    constructor(private http: HttpClient, public urlSrv: UrlService, private router: Router, private activeRoute: ActivatedRoute, private title: TitleService){}

    ngOnInit() {
        let requestId = this.activeRoute.snapshot.paramMap.get('id');

        this.title.set("Select files to compare from Project #"+requestId+" - XRay");
        this.filesUrl = "/requests/" + requestId +"/files";
        this.loadRequest(Number(requestId));

        // let obser = of(this.files.selected);
        //
        // obser.subscribe(f => {
        //     console.log("cambiaaaaaaaa", f);
        //     this.reference = this.files.get('reference');
        //     this.target = this.files.get('target');
        // })
    }

    onChange(e) {
        //this.files.selected = e;
        if(e.length<3)
            this.files.setSelected(e);
        console.log("something change", e, this.files.selected);
        //this.reference = this.files.get('reference');
        //this.target = this.files.get('target');
    }
    ngOnDestroy(){

    }

    cancel() {
        this.files.remove('reference');
        this.files.remove('target');
    }

    goToCompare() {
        if(!this.files.isComplete())
            return;
        // router to upload
        let query = {
            reference: this.files.reference.id,
            target: this.files.target.id
        };

        this.router.navigate([this.uploadUrl], { queryParams: query});

    }
    // changeOrder() {
    //     this.files = {
    //         target: this.files.reference,
    //         reference: this.files.target
    //     }
    // }

    protected loadRequest(requestId: number){
        this.http.get(this.urlSrv.build('/requests/'+requestId)).subscribe(
            (data:Request) => {
                this.request = data;
                this.status = this.DONE;
            },
            (error:any) => {
                this.status = this.NOT_FOUND;
            }
        );
    }

    selectFiles(files: Array<File>){
    }
}
