import { Component, ElementRef, ViewChild, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HostListener } from '@angular/core';

import { LegacyNote } from '@core/objects/legacy-note';

class VNote {
    // public index: number = 0;
    // public note: LegacyNote = null;
    constructor(public index: number, public note: LegacyNote) {}
}

class VNoteController {
    public vnotes: Array<VNote> = [];

    constructor(notes: Array<LegacyNote>) {
        notes.forEach(n => this.add(n))
        //this.vnotes = notes.map(n => this.makeVNote(n));
    }

    add(note: LegacyNote) {
        this.vnotes.push(this.makeVNote(note));
    }

    protected makeVNote(note: LegacyNote) : VNote {
        let index = this.vnotes.length+1;
        if(this.vnotes.length>0)
            index = this.vnotes[this.vnotes.length-1].index + 1;

        return new VNote(index, note);
    }

    getNotes() : Array<LegacyNote> {
        return this.vnotes.map(vn => vn.note);
    }

    getUnsavedNotes(): Array<LegacyNote> {
        return this.getNotes().filter(n => n.id == 0);
    }

    hasUnsavedNotes() : boolean {
        return this.getUnsavedNotes().length > 0;
    }

    getByPage(page: number) : Array<VNote> {
        return this.vnotes.filter(vn => vn.note.page == page);
    }

    remove(vnote: VNote) {
        this.vnotes = this.vnotes.filter(vn => vn.index != vnote.index);
    }

    setPosition(vnote: VNote, x: number, y: number) {
        let index = this.vnotes.map(vn => vn.index).indexOf(vnote.index);
        this.vnotes[index].note = {...this.vnotes[index].note, x_pos: x, y_pos: y};
    }


}

@Component({
    selector: 'core-notes-legacy',
    templateUrl: './notes-legacy.component.html',
    styleUrls: ['./notes-legacy.component.scss']
})
export class NotesLegacyComponent implements OnInit, OnChanges {
    @ViewChild('scroll') scroll: ElementRef;
    //@ViewChild('figure1', { read: ElementRef }) public figure1: ElementRef<any>;
    @ViewChild('notesContainer') notesContainer: ElementRef;
    @ViewChild('bounds') notesBounds: ElementRef;

    @Input() file: any;
    @Input() page: any;
    @Input() notes: Array<LegacyNote> = [];
    @Input() selected: any = null;

    @Output() onClick = new EventEmitter<any>();

    public legacyPage: number = 0;
    public previewLoaded = false;

    protected indexNotes: Array<string> = []; // this is an array for search the index of the note

    //public vnotes: Array<VNote> = [];

    public vnotesCtrl: VNoteController;

    ngOnInit() {
        this.vnotesCtrl = new VNoteController(this.notes);
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.page) {
            this.legacyPage = changes.page.currentValue - 1;
            this.previewLoaded = false;
        }

        if(changes.notes) {
            //this.indexNotes = this.notes.map( n=> JSON.stringify(n));
            //this.makeIndexes();
            this.vnotesCtrl = new VNoteController(this.notes);
        }

        if(changes.selected) {
            console.debug("Note selected!");
            if(this.selected !== null && this.selected.page+1 == this.page)
                this.onScrollToNote();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    onWindowClose(event: any): void {

        if(this.vnotesCtrl.hasUnsavedNotes()) {
            event.preventDefault();
            event.returnValue = false;
        }
    }

    protected onScrollToNote() {
        if(this.selected === null)
            return;

        this.scroll.nativeElement.scrollTop = this.selected.y_pos * this.notesContainer.nativeElement.clientHeight - (this.scroll.nativeElement.clientHeight/2);
    }

    protected resetScroll() {
        this.scroll.nativeElement.scrollTop = 0;
        //this.scroll.nativeElement.scrollLeft = 0;
    }


    makeNote(event) {
        // this.notes.push({
        //     //page: this.page,
        //     page: this.legacyPage,
        //     x_pos: event.offsetX / this.notesContainer.nativeElement.offsetWidth,
        //     y_pos: event.offsetY / this.notesContainer.nativeElement.offsetHeight,
        //     text: "Please, delete this sentence.",
        //     temporal_id: Date.now()
        // });

        this.vnotesCtrl.add({
            id: 0,
            page: this.legacyPage,
            x_pos: event.offsetX / this.notesContainer.nativeElement.offsetWidth,
            y_pos: event.offsetY / this.notesContainer.nativeElement.offsetHeight,
            text: "Please, delete this sentence.",
        })

        //this.makeIndexes();
        console.debug(this.vnotesCtrl.vnotes);
    }

    protected makeIndexes() {
        this.indexNotes = this.notes.map( n=> JSON.stringify(n));
    }

    sendNotes(){
        //console.debug(this.notes);
        //this.onClick.emit(this.notes);
        //this.onClick.emit(this.vnotesCtrl.getNotes());
        this.onClick.emit(this.vnotesCtrl.getUnsavedNotes());
    }

    deleteNote(note: LegacyNote, vnote:VNote) {
        this.vnotesCtrl.remove(vnote);
        // this.notes = this.notes.filter(n => {
        //     return !('temporal_id' in n) || n.temporal_id != note.temporal_id;
        // });
    }

    private getNewNotes() : Array<any> {
        return this.vnotesCtrl.getNotes().filter(n => n.id == 0);
    }

    previewLoad(load) {
        this.previewLoaded = load;
        if(load) {
            this.resetScroll();
            this.onScrollToNote();
        }
    }

    moveNote(position: any, vnote: VNote){
        //console.debug("old percent", position.note.x_pos, position.note.y_pos);
        //console.debug("new move", position.x_move, position.y_move);
        //console.debug(" old position in px", position.note.x_pos * this.getLayerWidth(), position.note.y_pos * this.getLayerHeight());
        //console.debug(" new position in px", (position.note.x_pos * this.getLayerWidth()) + position.x_move, (position.note.y_pos * this.getLayerHeight()) + position.y_move);

        let x = ((position.note.x_pos * this.getLayerWidth() ) + position.x_move) / this.getLayerWidth();
        let y = ((position.note.y_pos * this.getLayerHeight()) + position.y_move) / this.getLayerHeight();

        this.vnotesCtrl.setPosition(vnote, x, y);
        // let notes = this.notes;
        // this.notes = [];
        //
        // notes.map(n => {
        //     //if( n.x_pos == position.note.x_pos &&
        //     //    n.y_pos == position.note.y_pos &&
        //     //    n.page == position.note.page) {
        //     if(('temporal_id' in n) && n.temporal_id == position.note.temporal_id) {
        //         n.x_pos = x;
        //         n.y_pos = y;
        //     }
        //
        //     this.notes.push(n);
        // });
        //
        // console.log("notas movidas", this.notes);

    }

    protected getContainerLayer() {
        return this.notesContainer.nativeElement;
    }

    protected getLayerWidth(): number {
        return this.getContainerLayer().offsetWidth;
    }

    protected getLayerHeight(): number {
        return this.notesContainer.nativeElement.offsetHeight;
    }

    // public selectNotesPerPage(notes) {
    //     return notes.filter(n => { return n.page == this.legacyPage; });
    // }

    public index(note): number {
        //let indexNotes = this.notes.map( n=> JSON.stringify(n));
        let index = this.indexNotes.indexOf(JSON.stringify(note));
        if(index === -1)
            index = this.indexNotes.length;
        return index + 1;
    }

    isSelected(note: LegacyNote) : boolean {
        if(this.selected === null)
            return false;

        if('id' in note) {
            return this.selected.id === note.id;
        } else
            return true;
    }

}
