import { NgModule, InjectionToken, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { StoreModule, ActionReducerMap } from '@ngrx/store';
// import { EffectsModule } from '@ngrx/effects';
import { ShareModule } from '@app/components/share/share.module';

import { ShareTransferAppComponentsModule } from './components/components.module';
import { ShareTransferAppRouterModule } from './share-transfer.router';

@NgModule({
    imports: [
        CommonModule,
        ShareModule,
        ShareTransferAppComponentsModule,
        ShareTransferAppRouterModule,
        // StoreModule.forFeature('apollo', fromStore.apolloReducer),
        // EffectsModule.forFeature([ApolloEffects])
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
    ],
    exports: [
        ShareTransferAppComponentsModule,
        ShareTransferAppRouterModule
    ],

})
export class ShareTransferAppModule { }
