import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';

@Component({
    selector: 'core-file-selected-box',
    templateUrl: './file-selected-box.component.html',
    styleUrls: ['./file-selected-box.component.scss']
})
export class FileSelectedBoxComponent implements OnInit {

    @Input() title: string;
    @Input() boxDefaultText: string = 'select a file below'; // default text inside the box 
    @Input() file: File = null;

    @Input() buttonAction: boolean = false; // if extra button is necessary
    @Input() buttonActionText: string = "";
    @Output() onButtonActionClick = new EventEmitter<any>();

    @Input() fileAction: string = "";
    @Output() onFileActionClick = new EventEmitter<any>();
    constructor() { }

    ngOnInit() {

    }


}
