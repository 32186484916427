import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'core-helpers-forms-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {

    @Input() type: string = "";
    @Input() disabled:any = false;
    @Input() text:any;
    @Output() submit = new EventEmitter<any>();

    sendParent(){
        this.submit.emit(true);
    }
}
