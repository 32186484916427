import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Status } from '@core/objects';
// import { VersionThreeDotsMenuItem } from '../models/version-three-dots-menu-item';
// import { VersionThreeDotsStatusSubMenuItem } from './models/version-three-dots-status-menu-item';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

@Component({
    selector: 'core-version-status-update-menu',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class VersionStatusUpdateMenuMainComponent {
    
    @Input() status: Array<Status> = [];
    @Input() menu: Array<ThreeDotsMenuItem> = [];

    @Output() action = new EventEmitter<string>();
    @Output() statusAction = new EventEmitter<Status>();

    public open: boolean = false;
    public statusOpen: boolean = false;

    private wasInside: boolean = false;

    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }
  
    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.open = false;
        }
        this.wasInside = false;
    }

    onClick(key: string) {
        this.action.emit(key);
        this.open = false;
    }

    onStatusMenuSelect(status: Status){
        this.statusAction.emit(status);
        this.statusOpen = false;
        this.open = false;
    }

    toggleStatus() {
        this.statusOpen = !this.statusOpen;
    }

}
