import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'core-helpers-forms-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
})
/**
    icon:  add --> icon with a plus 
*/
export class ButtonIconComponent {

    @Input() disabled:any = false;
    @Input() text:any;
    @Input() icon:string;
    @Output() submit = new EventEmitter<any>();

    sendParent(){
        this.submit.emit(true);
    }
}
