import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges  } from '@angular/core';
import { Subscription } from 'rxjs';

import * as Objects from '@core/objects';
import { LabelsService } from '@app/services/app/labels.service';

@Component({
    selector: 'app-helpers-labels-add-box',
    templateUrl: './add-box.component.html',
    styleUrls: ['./add-box.component.scss'],
    providers: []
})
export class HelpersLabelsAddBoxComponent implements OnInit, OnChanges {

    @Input() input: string = "";
    @Input() buttonText: string = "Add one Now" 

    public categories: Array<Objects.LabelsGroup> = [];
    public showCategories: boolean = false;

    protected subscriptor: Subscription;


    constructor(protected labelsSrv: LabelsService) {}

    ngOnInit() {
        this.subscriptor = this.labelsSrv.selectCategories().subscribe((categories: Objects.LabelsGroup[]) => {
            this.categories = categories;
        });

        this.labelsSrv.loadCategories();
    }

    ngOnChanges(changes: SimpleChanges) {}

    select(category: Objects.LabelsGroup){
        // console.log("selected--->", category, this.input);
        this.labelsSrv.add(this.input,category); // add already place new tag on search results
        this.showCategories = !this.showCategories;
    }

}
