import { Component, Input, } from '@angular/core';

import { Label } from '@core/objects/label';

@Component({
    selector: 'core-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
    providers: []
})
export class LabelComponent {
    @Input() label: Label;
}
