import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';


import { HelperBoxModule } from '@core/components/helpers/box/box.module';
import { HelperFormModule } from '@core/components/helpers/form/form.module';
import { LayoutModule } from '@core/components/layout/layout.module';
import { VersionsModule } from '@core/components/versions/versions.module';
import { MessagesModule } from '@core/components/messages/messages.module';
import { FilesModule } from '@core/components/files/files.module';


//import { ApolloMainModule } from './main/main.module';

import { PluginShareFeedbackCoreVersionsThumbnailVerticalListComponent } from './version-thumbnail-vertical-list/version-thumbnail-vertical-list.component';
import { PluginShareFeedbackCoreVersionsApprovalResponseListComponent } from './version-approval-response-list/version-approval-response-list.component';
import { PluginShareFeedbackCoreVersionsMessageResponseListComponent } from './version-approval-message-list/version-approval-message-list.component';
import { PluginShareFeedbackCoreVersionFilesComponent } from './version-files/version-files.component';
import { PluginShareFeedbackCoreVersionMessagesComponent } from './version-messages/version-messages.component';

@NgModule({
    imports: [
        CommonModule,
        HelperBoxModule,
        HelperFormModule,
        LayoutModule,
        VersionsModule,
        MessagesModule,
        FilesModule
        //HelperBoxModule,
        ////ApolloMainModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        PluginShareFeedbackCoreVersionsThumbnailVerticalListComponent,
        PluginShareFeedbackCoreVersionsApprovalResponseListComponent,
        PluginShareFeedbackCoreVersionsMessageResponseListComponent,
        PluginShareFeedbackCoreVersionFilesComponent,
        PluginShareFeedbackCoreVersionMessagesComponent
    ],
    exports: [
        PluginShareFeedbackCoreVersionsThumbnailVerticalListComponent,
        PluginShareFeedbackCoreVersionsApprovalResponseListComponent,
        PluginShareFeedbackCoreVersionsMessageResponseListComponent,
        PluginShareFeedbackCoreVersionFilesComponent,
        PluginShareFeedbackCoreVersionMessagesComponent
    ],

})
export class ShareFeedbackCoreComponentsModule { }
