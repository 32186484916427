import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatus } from '@core/classes/http-status.class';

import * as Objects from '@core/objects';
import * as Search from '@app/models/search';

import * as Access from '@app/models/access';

export enum DashboardActionType {

	// EVENT CALLS
    Init = "[Dashboard] first call",
    InitParams = "[Dashboard] set params from sources",

    LoadFilters = "[Dashboard] Obtain filters",

    LoadWorkflows = "[Dashboard] GET - Obtain workflows - GET",
    LoadWorkflowsSuccess = "[Dashboard] GET - Obtain workflows SUCCESS",
    LoadWorkflowsError = "[Dashboard] GET - Obtain workflows ERROR",

    LoadFilterStatus = "[Dashboard] GET - Obtain status filters - GET",
    LoadFilterStatusSuccess = "[Dashboard] GET - Obtain status filter SUCCESS",
    LoadFilterStatusError = "[Dashboard] GET - Obtain status filter ERROR",

    LoadFilterLabels = "[Dashboard] GET - Obtain labels filters - GET",
    LoadFilterLabelsSuccess = "[Dashboard] GET - Obtain labels filter SUCCESS",
    LoadFilterLabelsError = "[Dashboard] GET - Obtain labels filter ERROR",

    LoadFilterUsers = "[Dashboard] GET - Obtain users filters - GET",
    LoadFilterUsersSuccess = "[Dashboard] GET - Obtain users filter SUCCESS",
    LoadFilterUsersError = "[Dashboard] GET - Obtain users filter ERROR",

    PushSearchFilters = "[Dashboard] Add filter (silence mode)",
    ApplySearchFilter = "[Dashboard] Add filter",
    RemoveSearchFilter = "[Dashboard] Remove filter",

    SetCurrentWorkflow = "[Dashboard] change current workflow",

    SetSearchTerm = "[Dashboard] set search term",
    SetSearchSort = "[Dashboard] set search sort",

    UpdateQueryParams = "[Dashboard] update search query params",
    SetQueryParams = "[Dashboard] set search query params",

    LoadResults = "[Dashboard] dispatch search / load results - GET",
    LoadResultsSuccess = "[Dashboard] dispatch search / load results - GET SUCCESS",
    LoadResultsError = "[Dashboard] dispatch search / load results - GET ERROR",

    UpdateRequest = "[Dashboard] dispatch search a request - GET",
    UpdateRequestSuccess = "[Dashboard] dispatch search a request - GET SUCCESS",
    UpdateRequestError = "[Dashboard] dispatch search a request - GET ERROR",


    Clear = "[Dashboard] clear data"
}

// Load Available Events
export class Init implements Action {
    readonly type = DashboardActionType.Init;
    constructor(public params: Search.SearchQueryParams) {}
}

export class InitParams implements Action {
    readonly type = DashboardActionType.InitParams;
    constructor(public params: any, public where: string) {}
}

export class LoadWorkflows implements Action {
    readonly type = DashboardActionType.LoadWorkflows;
    constructor() {}
}

export class LoadWorkflowsSuccess implements Action {
    readonly type = DashboardActionType.LoadWorkflowsSuccess;
    constructor(public workflows: Array<Objects.Workflow>) {}
}

export class LoadWorkflowsError implements Action {
    readonly type = DashboardActionType.LoadWorkflowsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadFilters implements Action {
    readonly type = DashboardActionType.LoadFilters;
    constructor() {}
}

export class LoadFilterStatus implements Action {
    readonly type = DashboardActionType.LoadFilterStatus;
    constructor(public workflow: Objects.Workflow) {}
}

export class LoadFilterStatusSuccess implements Action {
    readonly type = DashboardActionType.LoadFilterStatusSuccess;
    constructor(public workflow: Objects.Workflow, public status: Array<Objects.Status>) {}
}

export class LoadFilterStatusError implements Action {
    readonly type = DashboardActionType.LoadFilterStatusError;
    constructor(public workflow: Objects.Workflow, public error: HttpErrorResponse) {}
}

export class LoadFilterLabels implements Action {
    readonly type = DashboardActionType.LoadFilterLabels;
    constructor() {}
}

export class LoadFilterLabelsSuccess implements Action {
    readonly type = DashboardActionType.LoadFilterLabelsSuccess;
    constructor(public labels: Array<Objects.Label>) {}
}

export class LoadFilterLabelsError implements Action {
    readonly type = DashboardActionType.LoadFilterLabelsError;
    constructor(public error: HttpErrorResponse) {}
}

export class LoadFilterUsers implements Action {
    readonly type = DashboardActionType.LoadFilterUsers;
    constructor() {}
}

export class LoadFilterUsersSuccess implements Action {
    readonly type = DashboardActionType.LoadFilterUsersSuccess;
    constructor(public users: Array<Objects.User>) {}
}

export class LoadFilterUsersError implements Action {
    readonly type = DashboardActionType.LoadFilterUsersError;
    constructor(public error: HttpErrorResponse) {}
}


export class ApplySearchFilter implements Action {
    readonly type = DashboardActionType.ApplySearchFilter;
    constructor(public filter: Search.SearchAppliedFilter) {}
}

export class PushSearchFilters implements Action {
    readonly type = DashboardActionType.PushSearchFilters;
    constructor(public filters: Search.SearchAppliedFilter[]) {}
}

export class RemoveSearchFilter implements Action {
    readonly type = DashboardActionType.RemoveSearchFilter;
    constructor(public filter: Search.SearchAppliedFilter) {}
}

export class SetCurrentWorkflow implements Action {
    readonly type = DashboardActionType.SetCurrentWorkflow;
    constructor(public workflowId: number) {}
}

export class SetSearchTerm implements Action {
    readonly type = DashboardActionType.SetSearchTerm;
    constructor(public term: string) {}
}

export class SetSearchSort implements Action {
    readonly type = DashboardActionType.SetSearchSort;
    constructor(public sort: string) {}
}

export class UpdateQueryParams implements Action {
    readonly type = DashboardActionType.UpdateQueryParams;
    constructor() {}
}

export class SetQueryParams implements Action {
    readonly type = DashboardActionType.SetQueryParams;
    constructor(public params: Search.SearchQueryParams) {}
}

export class LoadResults implements Action {
    readonly type = DashboardActionType.LoadResults;
    constructor() {}
}

export class LoadResultsSuccess implements Action {
    readonly type = DashboardActionType.LoadResultsSuccess;
    constructor(public requests: Array<Objects.ExtendedRequest>) {}
}

export class LoadResultsError implements Action {
    readonly type = DashboardActionType.LoadResultsError;
    constructor(public error:  HttpErrorResponse) {}
}


export class UpdateRequest implements Action {
    readonly type = DashboardActionType.UpdateRequest;
    constructor(public requestId: number | string, public eventName: string) {}
}

export class UpdateRequestSuccess implements Action {
    readonly type = DashboardActionType.UpdateRequestSuccess;
    constructor(public requestId, public requests: Array<Objects.ExtendedRequest>) {}
}

export class UpdateRequestError implements Action {
    readonly type = DashboardActionType.UpdateRequestError;
    constructor(public requestId, public error:  HttpErrorResponse) {}
}

export class Clear implements Action {
    readonly type = DashboardActionType.Clear;
    constructor() {}
}


export type DashboardActions = 	Init |
                                InitParams |

                                LoadWorkflows |
                                LoadWorkflowsSuccess |
                                LoadWorkflowsError |

                                LoadFilters |

                                LoadFilterStatus |
                                LoadFilterStatusSuccess |
                                LoadFilterStatusError |

                                LoadFilterLabels |
                                LoadFilterLabelsSuccess |
                                LoadFilterLabelsError |

                                LoadFilterUsers |
                                LoadFilterUsersSuccess |
                                LoadFilterUsersError | 
                                
                                PushSearchFilters |
                                ApplySearchFilter |
                                RemoveSearchFilter |

                                SetCurrentWorkflow |

                                SetSearchTerm |
                                SetSearchSort | 
                                UpdateQueryParams |
                                SetQueryParams | 

                                LoadResults |
                                LoadResultsSuccess |
                                LoadResultsError |

                                UpdateRequest |
                                UpdateRequestSuccess |
                                UpdateRequestError |

                                Clear
                                ;
