import { Component,Input } from '@angular/core';

export interface TitleBreadcrumb {
    title: string;
    link: Array<any>;
}

@Component({
    selector: 'core-title-section',
    templateUrl: './title-section.component.html',
    styleUrls: ['./title-section.component.scss']
})
export class LayoutTitleSectionComponent {
    @Input() title: string = "";
    @Input() description: string = "";
    @Input() breadcrumbs: Array<TitleBreadcrumb>;
}
