import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'core-helpers-forms-button-white',
  templateUrl: './button-white.component.html',
  styleUrls: ['./button-white.component.scss'],
})
export class ButtonWhiteComponent {

    @Input() disabled:any = false;
    @Input() text:any;
    @Output() submit = new EventEmitter<any>();

    sendParent(){
        this.submit.emit(true);
    }
}
