import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelperBoxModule } from '@core/components/helpers/box/box.module';

import { ApolloMainModule } from './main/main.module';

@NgModule({
    imports: [
        CommonModule,
        HelperBoxModule,
        ApolloMainModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
    ],
    exports: [
        ApolloMainModule
    ],

})
export class ApolloComponentsModule { }
