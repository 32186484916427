import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'core-helpers-box-alert-message-icon',
    templateUrl: './alert-message-icon.component.html',
    styleUrls: ['./alert-message-icon.component.scss']
})
export class HelperBoxAlertMessageIconComponent {

    @Input() text: string = "";
    /* Posible Icons
    stop
    stop_move (red hand)
    launched (rocket)
    success
    no_messages 
    warning_notice (pink triangle)
    no_linked (folder)
    warning (yellow triangle)
    alert (default - red triangle)
    */
    @Input() icon: string = "alert";
}
