import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UsersService } from '../../../../../services/settings/users.service';
import { Subscription } from 'rxjs';
import { HttpStatus } from '@core/classes/http-status.class';

import { License } from '@core/objects/license';

@Component({
    selector: 'settings-licenses-licenses',
    templateUrl: './licenses.component.html',
    styleUrls: ['./licenses.component.scss']
})
export class SettingsLicensesLicensesComponent implements OnInit, OnDestroy{

    @Output() select = new EventEmitter<License>();
    @Output() invite = new EventEmitter<License>();
	// Subscription status
    public subscriptionLicenses: Subscription;

    public licenses: Array<License> = [];
    public selected: License = null;

    public avalaible: number = 0;

	constructor(public userSrv: UsersService){}

	ngOnInit(){

        this.subscriptionLicenses = this.userSrv.selectorGetAllLicenses().subscribe((licenses: License[]) => {
		    this.licenses = licenses;
            this.setAvalaible();
		});
    }

    ngOnDestroy(){
		this.subscriptionLicenses.unsubscribe();
	}

    protected setAvalaible() {
        let total = 0;

        this.licenses.forEach(l => total = total + l.seats_free);

        this.avalaible = total;
    }

    public selectLicense(license: License){
        this.selected = license;
        this.select.emit(license)
    }

    public inviteLicense(license: License){
        this.invite.emit(license);
    }

    public isSelected(license: License) : boolean {
        return this.selected != null && this.selected.id === license.id;
    }
}
