import { AddProject, initialState} from './add-project.state';
import { AddProjectActions, AddProjectActionType } from './add-project.actions';
import * as Objects from '@core/objects';
export function addProjectReducer(state = initialState, action: AddProjectActions): AddProject {
    switch (action.type) {
        case AddProjectActionType.InitAddProject:

            return {
                ...state, 
                data:  {
                    info: '',
                    due_date: null,
                    files: [],
                    labels: [],
                    version: action.versionId,
                    workflow: action.workflowId
                }
            };

        case AddProjectActionType.UpdateAddProject:
            // console.log("update on store ",action.newProject);
            return {...state, data: {...state.data, ...action.property}}

        case AddProjectActionType.Clear:
            return {...state, data: null};
            
	    default:
	        return state;
    }
}