import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
// import { HttpStatus } from '@core/classes/http-status.class';
// import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AccessToken } from '@core/objects/access-token';

// import { AccessTokensService } from '../../../../../services/settings/access-tokens.service';
// import { AppService } from '@app/services/app/app.service';

@Component({
    selector: 'settings-access-tokens-new',
    templateUrl: './new-access-token.component.html',
    styleUrls: ['./new-access-token.component.scss']
})
export class SettingsAccessTokensNewComponent implements OnInit{

    @Input() accessToken: AccessToken = null;
    @Output() done =  new EventEmitter<any>();

    public copied: boolean = false;

	constructor(){}

	ngOnInit(){
	}

    copyToClipboard(accessToken: AccessToken) {
        console.debug(" copy trigger...",accessToken.token);
        //copyToClipboard(item) {
        document.addEventListener('copy', (e: ClipboardEvent) => {
          e.clipboardData.setData('text/plain', (accessToken.token));
          e.preventDefault();
          document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        this.copied= true;
        setTimeout(()=>{
            this.copied= false;
        }, 4000);

    }

    onDone(event){
        this.done.emit(event);
    }

}
