import { Note } from '@core/objects/note';

import { NotesViewerStatus } from './notes-viewer';

export class NoteBuilder {
    // constructor(private containerWidth: number, private containerHeight: number){}

    calcPosition(x, y, status: NotesViewerStatus) {
        // return {
        //     x: x / this.containerWidth,
        //     y: y / this.containerHeight
        // };

        console.log("new note--->", x, y, status);
        let position = {
            x: x / (status.containerSize.width),
            y: y / status.containerSize.height
        };

        // let xtmp = position.x;
        // switch(status.rotation) {
        //     case 90:
        //         position.x = 1-position.y;
        //         position.y = xtmp;
        //         break;
        //     case 180:
        //         position.x = 1-position.x;
        //         position.y = 1-position.y;
        //         break;
        //     case 270:
        //         position.x = position.y;
        //         position.y=1-xtmp;
        //         break;
        // }

        // case 90:
        //         this.x = 100-this.y;
        //         this.y = x;
        //         break;
            
        //     case 180:
        //         this.x = 100-this.x;
        //         this.y = 100-this.y;
        //         break;
        //     case 270:
        //         this.x = this.y;
        //         this.y = 100-x;
        //         break;

        return position;
    }

    create(event,  status: NotesViewerStatus, page: number, type: string) : Note {
        console.log("event--->", event);
        
        let note = {
            id: 0,
            page: page,
            type: type,
            description: "Please, delete this sentence.",
            options: {},
            user: null,
            file: null,
            date_created: Date.toString()
        };

        let position = {x: 0, y: 0}
        switch(note.type) {
            case "point":
                position = this.calcPosition(event.offsetX, event.offsetY, status);
                break;
        }

        note.options = {...note.options, x: position.x, y: position.y };

        return note;
    }
}