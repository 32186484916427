import { createFeatureSelector, createSelector } from '@ngrx/store';
// import { HttpStatus } from '@core/classes/http-status.class';
import { HttpErrorResponse } from '@angular/common/http';

import * as Objects from '@core/objects';
import * as Access from '@app/models/access';
import * as Search from '@app/models/search';

// interface VersionsErrors {
//     load_version: HttpErrorResponse;
//     load_version_files: HttpErrorResponse;
//     load_version_messages: HttpErrorResponse;
//     load_editor_access: HttpErrorResponse;
//     load_version_feedbacks: HttpErrorResponse;
//     load_version_project: HttpErrorResponse;
//     set_version_status: HttpErrorResponse;
//     set_version_labels: HttpErrorResponse,
// }

interface LibraryAvailableFilters {
    status: Search.SearchAvailableFilters;
    labels: Search.SearchAvailableFilters;
    // owners: Search.SearchAvailableFilters;
}

interface LibrarySearch {
    term: string;
    sort: string;
    limit: number;
    filters: Array<Search.SearchAppliedFilter>;
    // query: string;
}

interface LibraryResults {
    next_page: number;
    // final: boolean;
    items: Array<Objects.Version>;
    status: number;
}

export interface Library {
    starting: {initialized: boolean};
    available_filters:  LibraryAvailableFilters;
    search: LibrarySearch;
    search_query_params: Search.SearchQueryParams;
    results: LibraryResults;
    errors: any;
}

export const initialState: Library = {
    starting: {
        initialized: false
    },
    available_filters : {
        status: {
            key: "status",
            items: []
        },
        labels: {
            key: "label",
            items: []
        }
    },
    search: {
        term: "",
        sort: "",
        limit: 50,
        filters: [],
        // query: null
    },
    search_query_params: null,
    results: {
        next_page: 1,
        // final: false,
        status: Search.SearchHttpStatus.INITIAL,
        items: []
    },
    errors: {
        // load_version: null,
        // load_version_files: null,
        // load_version_messages: null,
        // load_editor_access: null,
        // load_version_feedbacks: null,
        // load_version_project: null,
        // set_version_status: null,
        // set_version_labels: null,
    }
};

const libraryFeatureSelector = createFeatureSelector('library');

// Selectors
export const librarySelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state
);

export const availableFiltersSelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state.available_filters
);

export const availableFilterLabelsSelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state.available_filters.labels
);

export const availableFilterStatusSelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state.available_filters.status
);

export const searchSelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state.search
);

export const searchFiltersSelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state.search.filters
);

export const searchTermSelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state.search.term
);

// export const searchQuerySelector = createSelector(
//     libraryFeatureSelector,
//     (state: Library) => state.search.query
// );

export const queryParamsSelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state.search_query_params
);

export const resultSelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state.results
);

export const resultItemsSelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state.results.items
);

export const resultStatusSelector = createSelector(
    libraryFeatureSelector,
    (state: Library) => state.results.status
);
