import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output, SimpleChanges, OnChanges, AfterViewInit, ViewChild, ViewContainerRef, ElementRef, TemplateRef, ChangeDetectorRef } from '@angular/core';

import { UrlService } from '@core/services/url/url.service';
import { PreviewPageProperty } from '@core/objects/preview-request';

import { decode } from 'blurhash';

@Component({
    selector: 'core-preview-page-blur',
    templateUrl: './preview-page-blur.component.html',
    styleUrls: ['./preview-page-blur.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewPageBlurComponent implements OnChanges, AfterViewInit {
    // @ViewChild("container", {read: ViewContainerRef}) containerRef: ViewContainerRef;
    // @ViewChild("content", {read: TemplateRef}) contentRef: TemplateRef<any>;
    @ViewChild('canvas', {read:  ElementRef}) canvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('img', {read: ElementRef}) img: ElementRef<HTMLImageElement>;

    @Input() format;
    @Input() page: number;
    @Input() fileId: number;
    @Input() property: PreviewPageProperty;

    // not work very well fandango
    @Output() onLoad = new EventEmitter<any>();

    public resolve: boolean;

    protected src: string;
    protected alt: string;

    constructor(public url: UrlService, private changeDetectorRef: ChangeDetectorRef) {
        this.resolve = false;
    }

    ngAfterViewInit(): void {
        this.init();
    }

    // renderImg() {
    //     this.containerRef.clear();
    //     this.containerRef.createEmbeddedView(this.contentRef);
    // }

    ngOnChanges(changes: SimpleChanges){
        if(this.resolve) {
            //this.resolve = false;
            this.init();
        }
    }

    init() {
        this.src = this.getSrc();
        this.alt  = "preview-" + this.fileId + "(page "+this.page+")";

        this.createImgInstance();
        this.drawCanvas();

    }

    getSrc(){
        return this.url.build("/files/" + this.fileId + "/preview/" + this.format + "/" + this.page);
    }

    private createImgInstance(): void {
        const img = new Image();
        img.src = this.src;
        img.onload = () => this.onLoadImg();
    }

    // loadImage(url: string){
    //     let img = new Image();
    //     img.src = url;
    //     img.onload = () => this.onLoadImg();
    // }

    private onLoadImg(): void {
        this.resolve = true;
        // this.changeDetectorRef.detectChanges();
        this.img.nativeElement.src = this.src;
        if (this.alt) {
            this.img.nativeElement.alt = this.alt;
        }

        this.onLoad.emit(true);
    }

    // private calcSize() {
    //     if(this.width>0) {
    //         this.height = this.width * this.property.height_ratio;
    //     }
    //
    //     if(this.height>0) {
    //         this.width = this.height / this.property.height_ratio;
    //     }
    //
    //     console.debug("h", this.height, "w", this.width);
    // }

    private drawCanvas(): void {
        const pixels = decode(this.property.blur.hash, this.property.blur.width, this.property.blur.height);
        const ctx = this.canvas.nativeElement.getContext('2d');
        const imageData = ctx.createImageData(this.property.blur.width, this.property.blur.height);
        //console.debug(pixels);
        imageData.data.set(pixels);
        ctx.putImageData(imageData, 0, 0);
    }
}
