import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'settings-users-box-licence',
    templateUrl: './box-licence.component.html',
    styleUrls: ['./box-licence.component.scss']
})
export class SettingsUsersBoxLicenceComponent implements OnInit{
	
	constructor(){}

	ngOnInit(){
		
	}
}
