import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { AppRouterModule } from '@app/router.module';

import { DashboardViewsTableModule } from './table/table.module';
import { DashboardViewsMainComponent } from './main/main.component';


@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        AppRouterModule,
        DashboardViewsTableModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        DashboardViewsMainComponent,
        
    ],
    exports: [
        DashboardViewsMainComponent,
        DashboardViewsTableModule
    ],

})
export class DashboardViewsModule { }