import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { PasswordValidation, PasswordValidatorController } from '@core/controllers/password-validator.controller';

@Component({
  selector: 'core-helpers-forms-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss'],
})
export class HelperFormPasswordValidationComponent implements OnChanges {

    @Input() password: string = "";
    @Output() valid = new EventEmitter<boolean>();

    protected validator: PasswordValidatorController = new PasswordValidatorController();
    public validate: PasswordValidation = {
        length: false,
        uppercase: false,
        number: false,
        special: false
    }

    ngOnChanges(changes: SimpleChanges) {
        if(typeof changes['password'] !== 'undefined') {
            this.validate = this.validator.validate(this.password);
            this.onValid();
        }
    }

    protected onValid() {
        let notValid = Object.keys(this.validate).filter(key => !this.validate[key]);

        this.valid.emit(notValid.length === 0);
    }

}
