import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

import { File } from '@core/objects/file';
import { FilesListItemMenuClick } from '../files-list-item-menu-click';

@Component({
    selector: 'core-files-list-default',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss']
})
export class FilesListDefaultComponent implements OnInit {

    @Input() files: Array<File>;
    @Input() menu: Array<ThreeDotsMenuItem> = [];
    
    @Output() actionClick= new EventEmitter<File>();
    @Output() menuClick = new EventEmitter<FilesListItemMenuClick>();

    ngOnInit() {
    }
}
