import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LabelsModule } from '@core/components/labels/labels.module';

import { AppRouterModule } from '@app/router.module';

import { ProjectDetailsVersionsMainComponent } from './main/main.component';
// import { VersionsRelatedGroupListComponent } from './related-group-list/related-group-list.component';


import { AppVersionsModule } from '../../../versions/versions.module';
import { ProjectsDetailsHelpersModule } from '../helpers/helpers.module';
import { ProjectDetailsVersionsRelatedsListComponent } from './relateds-list/relateds-list.component';
import { ProjectDetailsVersionsRelatedsListVersionsComponent } from './relateds-list-versions/relateds-list-versions.component';
import { ProjectDetailsVersionsUploadedComponent } from './uploaded/uploaded.component';

@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        HelpersModule,
        AppVersionsModule,
        ProjectsDetailsHelpersModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsVersionsMainComponent,
        ProjectDetailsVersionsRelatedsListComponent,
        ProjectDetailsVersionsRelatedsListVersionsComponent,
        ProjectDetailsVersionsUploadedComponent
        
    ],
    exports: [
        ProjectDetailsVersionsMainComponent,
        ProjectDetailsVersionsUploadedComponent
    ],

})
export class ProjectsDetailsVersionsModule { }
