import { Component, Input} from '@angular/core';

@Component({
  selector: 'core-helper-box-user-email',
  templateUrl: './user-email.component.html',
  styleUrls: ['./user-email.component.scss']
})
export class HelperBoxUserEmailComponent {

    @Input() user: any;
    // @Input() open: boolean;
    // @Input() date: any;
    // @Input() posText: string;
    // @Input() preText: string;

}
