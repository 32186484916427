import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// modules
import { HelperFormModule  } from '../form/form.module';

// components
import { OrderedListComponent } from './ordered-list/ordered-list.component';
import { OneActionListItemComponent } from './one-action-list-item/one-action-list-item.component';
import { OneActionColorListItemComponent } from './one-action-color-list-item/one-action-color-list-item.component';

@NgModule({
    imports: [
        CommonModule,
        HelperFormModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        OrderedListComponent,
        OneActionListItemComponent,
        OneActionColorListItemComponent
    ],
    exports: [
        OrderedListComponent,
        OneActionListItemComponent,
        OneActionColorListItemComponent

    ],

})
export class HelperListModule { }
