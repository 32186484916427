import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';

import { UrlService } from '@core/services/url/url.service';

@Component({
    selector: 'core-preview-page',
    templateUrl: './preview-page.component.html',
    styleUrls: ['./preview-page.component.scss'],
    providers: []
})
export class PreviewPageComponent implements OnChanges {
    @ViewChild("container", {read: ViewContainerRef}) containerRef: ViewContainerRef;
    @ViewChild("content", {read: TemplateRef}) contentRef: TemplateRef<any>;

    @Input() format;
    @Input() page: number;
    @Input() fileId: number;

    // not work very well fandango
    @Output() onLoad = new EventEmitter<any>();

    public src: string;
    public alt: string;

    public min:boolean = true;

    constructor(public url: UrlService) {}

    renderImg() {
        this.containerRef.clear();
        this.containerRef.createEmbeddedView(this.contentRef);
    }

    ngOnChanges(changes: SimpleChanges){
        this.init();
    }

    init() {
        this.renderImg();
        this.src = this.buildSrc("min");
        this.min = true;

        this.loadLargeImage(this.buildSrc(this.format));
        this.alt  = "preview-" + this.fileId + "(page "+this.page+")";
    }

    buildSrc(format: string){
        return this.url.build("/files/" + this.fileId + "/preview/" + format + "/" + this.page);
    }

    loadLargeImage(url){
        let largeImage = new Image();
        largeImage.src = url;
        largeImage.onload = () => {
            this.src = url;
            this.min = false;
        }
    }

    onImgLoad() {
        if (!this.min)
            this.onLoad.emit(true);
    }
}
