import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VersionsModule } from '@core/components/versions/versions.module';
import { VersionAddModule } from '@app/components/sections/versions/add/version-add.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { AppHelpersSearchModule } from '@app/components/helpers/search/search.module';

import { LibrarySearchMainComponent } from './main/main.component';
import { LibrarySearchFiltersComponent } from './filters/filters.component';
import { AppHelpersSearchFiltersModule } from '@app/components/helpers/search/filters/filters.module';
import { AppHelpersSearchBarModule } from '@app/components/helpers/search/bar/bar.module';
import { LayoutTitlesModule } from '@core/components/layout/titles/titles.module';

@NgModule({
    imports: [
        CommonModule,
        HelpersModule,
        LayoutTitlesModule,
        VersionsModule,
        VersionAddModule,
        AppHelpersSearchFiltersModule,
        AppHelpersSearchBarModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LibrarySearchMainComponent,
        LibrarySearchFiltersComponent
    ],
    exports: [
        LibrarySearchMainComponent
    ],

})
export class LibrarySearchModule{ }
