import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HelpersSearchFiltersStatusMainComponent } from './main/main.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        HelpersSearchFiltersStatusMainComponent
    ],
    exports: [
        HelpersSearchFiltersStatusMainComponent
    ],

})
export class AppHelpersSearchFiltersStatusModule { }