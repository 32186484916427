import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';

import { AppRouterModule } from '@app/router.module';
import { SettingsUsersModule } from './users/users.module';
import { SettingsLicensesModule } from './licenses/licenses.module';
import { SettingsGroupsModule } from './groups/groups.module';
import { SettingsAccessTokensModule } from './access-tokens/access-tokens.module';
import { SettingsVersionStatusModule } from './version-status/version-status.module';
import { SettingsLabelsGroupsModule } from './labels-groups/labels-group.module';
import { SettingsRulesModule } from './rules/rules.module';
import { SettingsWorkflowsModule } from './workflows/workflows.module';
import { SettingsMainModule } from './settings-main/settings-main.module';

import { SettingsManageUsersComponent } from './manage-users/manage-users.component';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
		AppRouterModule,
        SettingsUsersModule,
        SettingsLicensesModule,
        SettingsGroupsModule,
        SettingsAccessTokensModule,
        SettingsVersionStatusModule,
        SettingsLabelsGroupsModule,
        SettingsRulesModule,
        SettingsWorkflowsModule,
        SettingsMainModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        SettingsManageUsersComponent
    ],
    exports: [
		SettingsUsersModule,
        SettingsLicensesModule,
        SettingsManageUsersComponent,
        SettingsGroupsModule,
        SettingsAccessTokensModule,
        SettingsVersionStatusModule,
        SettingsLabelsGroupsModule,
        SettingsRulesModule,
        SettingsWorkflowsModule,
        SettingsMainModule
	],

})
export class SettingsModule { }
