import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';


import { HelperBoxModule } from '@core/components/helpers/box/box.module';
import { HelperFormModule } from '@core/components/helpers/form/form.module';
import { LayoutModule } from '@core/components/layout/layout.module';
import { ShareModule } from '@app/components/share/share.module';
//import { ApolloMainModule } from './main/main.module';

import { PluginShareTransferMainAppComponent } from './main/main.component';
import { ShareTransferSectionsModule } from './sections/sections.module';
@NgModule({
    imports: [
        CommonModule,
        HelperBoxModule,
        HelperFormModule,
        LayoutModule,
        ShareModule,
        ShareTransferSectionsModule,
        //HelperBoxModule,
        ////ApolloMainModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        PluginShareTransferMainAppComponent
    ],
    exports: [
        PluginShareTransferMainAppComponent,
        ShareTransferSectionsModule
    ],

})
export class ShareTransferAppComponentsModule { }
