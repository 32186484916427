import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { Subscription } from 'rxjs';

import { AppService } from '@app/services/app/app.service';
import { ProjectsEditorService, ProjectsService } from '@app/services/app/projects.service';
import { WorkflowsService } from '@app/services/settings/workflows.service';

import { TitleService } from '@core/services/title.service';

import { File } from '@core/objects/file';
import { BasicRequest } from '@core/objects/request';
import { Version } from '@core/objects/version';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';


@Component({
    selector: 'project-details-forbidden-header-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class ProjectDetailsForbiddenHeaderMainComponent implements OnInit, OnDestroy{

    // public readonly STATUS_LOADING = 0;
    // public readonly STATUS_DONE = 1;
    // public readonly STATUS_NOT_ALLOWED = 2;

	@Input() projectHeader: BasicRequest



	constructor(private title: TitleService
    ){}

	ngOnInit(){
        if(this.projectHeader != null){
            this.title.set("Info - Project "+this.projectHeader.id);
        }
 
	}

    // init() {
    //     this.title.set("Info - Project "+this.projectHeader.id);
    //     // this.loadOriginVersion()
    // }

    ngOnDestroy() {
        // this.subscriptor.unsubscribe();
        // this.accessSubscriptor.unsubscribe();
    }

}
