import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { File } from '@core/objects/file';
import { Version } from '@core/objects/version';

@Component({
    selector: 'core-version-files-list-checkbox',
    templateUrl: './list-checkbox.component.html',
    styleUrls: ['./list-checkbox.component.scss']
})
export class VersionFilesListCheckboxComponent implements OnInit {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly ERROR = 2;

    @Input() version: Version;
    @Input() disabled: boolean; // TODO
    @Input()  selected: Array<File>;
    @Output() selectedChange = new EventEmitter<Array<File>>();

    public opened: boolean = false;
    public firstOpened: boolean = false;
    public filesUrl: string = "";
    public checked: boolean = false;


    constructor(private httpClient: HttpClient, private urlSrv: UrlService ) { }

    ngOnInit() {
        this.filesUrl = "/versions/"+ this.version.id +"/files";
    }
    onChange(files: Array<File>) {
        this.selected = files;
        this.selectedChange.emit(this.selected);
    }

    openFiles() {
        this.firstOpened = true;
        this.opened = !this.opened;
    }


}
