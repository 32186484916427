import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { GroupsService } from '../../../../../services/settings/groups.service';

import { User } from '@core/objects/user';
import { Group } from '@core/objects/group';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

@Component({
    selector: 'settings-groups-group-users',
    templateUrl: './group-users.component.html',
    styleUrls: ['./group-users.component.scss']
})
export class SettingsGroupsGroupUsersComponent implements OnInit, OnChanges {

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

	@Input() group: Group = null;

	public status: Number = 0;

	public showForm: boolean = false;
	public showInstructions: boolean = false;

	public users: Array<User> = [];

	// TODO review menu icons
	public menu: Array<ThreeDotsMenuItem> = [{
		title: "Add user to group",
		key: "add-user-to-group",
		icon: "add",
		order: 1
	}];

	constructor(public grpSrv: GroupsService){}

	ngOnInit(){
		this.loadUsers();
	}

	ngOnChanges(changes: SimpleChanges){
		if(changes.group) {
			this.status = this.STATUS_LOADING;
			this.showForm = false;
            this.loadUsers();
        }
	}

	loadUsers() {
		this.grpSrv.findGroupUsers(this.group).subscribe((users: Array<User>) => {
			this.users = users;
			this.status = this.STATUS_DONE;
		});
	}

	remove(user: User) {
		this.users = this.users.filter((u) => u.id != user.id);

		this.grpSrv.deleteUser(this.group, user).subscribe(() => {
			this.loadUsers();
		});
	}

	select(group: Group) {
		// console.log(group);
	}

	onMenuClick(key: string) {
		switch(key) {
				case "add-user-to-group":
						this.showForm = !this.showForm;
						break;
		}
	}
}
