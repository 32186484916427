import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';


import { SelectItem } from '@core/models/select-item.model';

import { AvailableRuleItem, AvailableRuleActionVariable, AvailableRuleEventCondition, AvailableRuleAction, RuleEvent } from '@app/models/rules';

@Component({
  selector: 'settings-rules-rule-form-helper-select',
  templateUrl: './helper-select.component.html',
  styleUrls: ['./helper-select.component.scss'],
})
export class SettingsRulesFormHelperSelectComponent implements OnInit, OnChanges {

    @Input() disabled: boolean;
    @Input() optionSelected: string = null;
    @Input() label: string = "";
    @Input() options: AvailableRuleItem[] = [];
    @Input() placeholder: string = "";
    @Output() select = new EventEmitter<AvailableRuleItem>();

    public items: Array<SelectItem> = [];

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.options) {
            this.init();
        }
    }

    protected init() {
        this.items = this.options.map((o: AvailableRuleItem) => ({
            title: o.description,
            value: o.key.toString(),
            selected: this.optionSelected.toString() == o.key.toString()
        }));
    }

    onSelect(select: SelectItem) {   
        let option = this.options.filter((o: AvailableRuleItem)=> o.key == select.value);

        this.select.emit(option[0]);
    }
}
