import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { AppRouterModule } from '@app/router.module';
import { AppHelperLabelsModule } from '@app/components/helpers/labels/labels.module';
import { LayoutModule } from '@core/components/layout/layout.module';
import { LabelsModule } from '@core/components/labels/labels.module';
import { LayoutTitlesModule } from '@core/components/layout/titles/titles.module';

import { VersionsAddStatusComponent } from './status/status.component';
import { VersionsAddNumberComponent } from './number/number.component';


@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        LabelsModule,
        HelpersModule,
        LayoutModule,
        AppHelperLabelsModule,
        LayoutTitlesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionsAddNumberComponent,
        VersionsAddStatusComponent
    ],
    exports: [
        VersionsAddNumberComponent,
        VersionsAddStatusComponent
    ],

})
export class VersionAddDetailsModule { }
