import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'core-helpers-lists-one-action-color-list-item',
  templateUrl: './one-action-color-list-item.component.html',
  styleUrls: ['./one-action-color-list-item.component.scss'],
})
export class OneActionColorListItemComponent {

    @Input() title: string;
    @Input() subtitle: string = "";
    @Input() actionText: string = "";
    @Input() type: string = "";
    @Output() titleClick = new EventEmitter<any>();
    @Output() actionClick = new EventEmitter<any>();

    // colors 
    @Input() colorOptions: Array<string>;
    @Input() color: string;
    @Output() colorChange = new EventEmitter<string>();

    onSelect(event){
        this.titleClick.emit(event);
    }

    onAction(event){
        this.actionClick.emit(event);
    }

    select(color: string) {
        this.color = color;
        this.colorChange.emit(this.color);
    }
}
