import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { map } from 'rxjs/operators';
import { UrlService } from '@core/services/url/url.service';

import { ApolloRequest } from '../../model/apollo-request.model';
import { ApolloHistory } from '../../model/apollo-history.model';
import { ApolloCompare } from '../../model/apollo-compare.model';
import { ApolloGraphicComparison } from '../../model/apollo-graphic-comparison.model';
import { ApolloContentComparison, ApolloContentComparisonExtract } from '../../model/apollo-content-comparison.model';
import { ApolloContentComparisonResult } from '../../model/apollo-content-compared-result.model';

@Injectable({
  providedIn: 'root'
})
export class ApolloRequests {
    private readonly compare = "/p/apollo/compare";
    private readonly file = "/files";

    protected requestStore: ApolloRequest;

    constructor(private http: HttpClient, private urlSrv : UrlService) {
    }

    public updateCompare$ = (request: ApolloRequest) : Observable <ApolloRequest> =>
        this.http.put<ApolloRequest>(this.urlSrv.build(this.compare+ "/"+ request.hash), request);

    public setCompare$ = (request: ApolloRequest) : Observable<ApolloRequest> =>
        this.http.post<ApolloRequest>(this.urlSrv.build(this.compare), request);

    public getCompare$ = (request: ApolloRequest): Observable<ApolloRequest> =>
        this.http.get<ApolloRequest>(this.urlSrv.build(this.compare + "/" + request.hash ));

    public getCompareByHash$ = (hash: string): Observable<ApolloRequest> =>
        this.http.get<ApolloRequest>(this.urlSrv.build(this.compare + "/" + hash ));

    // public getAccess$ = (event: string): Observable<Session> => this.http.get<Session>(this.urlSrv.build(this.access + "/" + event));

    // Graphic
    public updateGraphic$ = (data: any, hash:string) : Observable<ApolloGraphicComparison> =>
        this.http.put<ApolloGraphicComparison>(this.urlSrv.build(this.compare + "/" + hash + "/graphic"), data);

    // @deprecated
    public setGraphic$ = (apolloGraphic: ApolloGraphicComparison, hashRequest:string) : Observable<ApolloGraphicComparison> =>
        this.http.post<ApolloGraphicComparison>(this.urlSrv.build(this.compare + "/" + hashRequest + "/graphic"), apolloGraphic);

    public getGraphic$ = (hashRequest:string) : Observable<ApolloGraphicComparison> =>
		this.http.get<ApolloGraphicComparison>(this.urlSrv.build(this.compare + "/" + hashRequest + "/graphic"));


	//Register
	public updateRegisterGraphic$ = (type:string, layer:string, hash:string) : Observable<ApolloGraphicComparison> => {
        let data = {
            register_type: type,
            register_layer: layer
        }
        return this.http.put<ApolloGraphicComparison>(this.urlSrv.build(this.compare + "/" + hash + "/graphic/register"), data);
    }


    //Compare
	public getCompareGraphic$ = (hash: string) : Observable<ApolloGraphicComparison> =>
        this.http.get<ApolloGraphicComparison>(this.urlSrv.build(this.compare + "/" + hash + "/graphic/compare"));

    // GetFiles
    public loadFile$ = (id:string) : Observable<any> =>
        this.http.get<any>(this.urlSrv.build(this.file +'/'+id+'?f=json'));

    //public downloadFile$ = (id: string) : Observable<any> =>
    //    this.http.get<any>(this.urlSrv.build(this.file +'/'+id));

    // Content
    public getContent$ = (hashRequest:string) : Observable<ApolloContentComparison> =>
        this.http.get<ApolloContentComparison>(this.urlSrv.build(this.compare + "/" + hashRequest + "/content"));

    public updateContentComparisonExtract$ = (comparisonExtract:ApolloContentComparisonExtract, typeFile:string, hash:string) : Observable<ApolloContentComparison> =>
        this.http.put<ApolloContentComparison>(this.urlSrv.build(this.compare+"/"+hash+'/content/extract/'+typeFile), comparisonExtract);

    public updateContentComparison$ = (method:string, hash:string) : Observable<ApolloContentComparison> =>{
        let data = {
            comparison_type: method
        }
        return this.http.put<ApolloContentComparison>(this.urlSrv.build(this.compare+"/"+hash+'/content'), data);
    }

    public getContentCompare$ = (hash:string) : Observable<ApolloContentComparison> =>
        this.http.get<ApolloContentComparison>(this.urlSrv.build(this.compare+"/"+hash+'/content/compare'));

    public getContentComparisonExtractResult$ = (hash: string, type: string) : Observable<any> =>
        this.http.get(this.urlSrv.build(this.compare+"/" + hash + '/content/extract/' + type + '/text'), { responseType: 'text'});

    public getContentComparisonResult$ = (hash: string) : Observable<ApolloContentComparisonResult> =>
        this.http.get<ApolloContentComparisonResult>(this.urlSrv.build(this.compare+"/" + hash + '/content/compare/result'));

    public getHistory$ = (page:number) : Observable<ApolloHistory> =>
        this.http.get<ApolloHistory>(this.urlSrv.build(this.compare+"s?page="+page));

    public updateNameCompareHistory$ = (hash: string, name: string) : Observable<ApolloCompare> =>
        this.http.put<ApolloCompare>(this.urlSrv.build(this.compare+"s/"+hash), {name: name});
}
