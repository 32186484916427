import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '@core/objects/user';
import { License } from '@core/objects/license';
import { HttpStatus } from '@core/classes/http-status.class';

import { Settings } from '../settings.state';

// export class SettingsUsersStatus {
//     list: HttpStatus = new HttpStatus();
//     invite: HttpStatus = new HttpStatus();
//     addInvite:HttpStatus = new HttpStatus();
//     delInvite: HttpStatus = new HttpStatus();
//     delUser: HttpStatus = new HttpStatus();
// };

export interface SettingsLicenses {
    //status: SettingsUsersStatus;
    licenses: Array<License>;
}

export const initialState: SettingsLicenses = {
    //status: new SettingsLicensesStatus,
    licenses: []
};

// const settingsLicensesFeatureSelector = createFeatureSelector('licenses');

const settingsFeatureSelector = createFeatureSelector('settings');

const settingsLicensesFeatureSelector = createSelector(
    settingsFeatureSelector,
    (state: Settings) => state.licenses
);


// Selectors
export const licensesGetAllLicensesSelector = createSelector(
    settingsLicensesFeatureSelector,
    (state: SettingsLicenses) => state.licenses
);
//
// export const usersGetAllInvitationsSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.invitations
// );
//
// // Status
// export const usersStatusListSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.status.list
// );
//
// export const usersStatusInviteSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.status.invite
// );
//
// export const usersStatusAddInviteSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.status.addInvite
// );
//
// export const usersStatusDelInviteSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.status.delInvite
// );
//
// export const usersStatusDelUserSelector = createSelector(
//     settingsUsersFeatureSelector,
//     (state: SettingsUsers) => state.status.delUser
// );
