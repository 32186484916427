import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// import * as Objects from '@core/objects';

import { LibraryFiltersService, LibrarySearchService } from 'app/services/app/library.service';
import { SearchFilterMenuItem } from '@app/components/helpers/search/filters/models/filters-menu';
import { SearchAppliedFilter, SearchAvailableFilters } from '@app/models/search';

@Component({
    selector: 'library-search-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class LibrarySearchFiltersComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    public status: number = 0;
    private wasInside: boolean = false;

    public selectedFilter: string = '';

    // menu
    public filtersMenu: Array<SearchFilterMenuItem> = [
        {
            title: 'Filter by Tag',
            key: 'label',
            order: 1
        },{
            title: 'Filter by Version Status',
            key: 'status',
            order: 2
        }
    ];

    public availableLabels: SearchAvailableFilters = null;
    public availableStatus: SearchAvailableFilters = null;
    public appliedFilters: SearchAppliedFilter[] = [];

    private labelsSubscription: Subscription;
    private statusSubscription: Subscription;
    private appliedFiltersSubscription: Subscription;

    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }
  
    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.selectedFilter = '';
        }
        this.wasInside = false;
    }


	constructor(private filtersSrv: LibraryFiltersService, private searchSrv: LibrarySearchService){}

	ngOnInit(){
        this.labelsSubscription = this.filtersSrv.selectLabels().subscribe((filter: SearchAvailableFilters) => this.availableLabels = filter);
        this.statusSubscription = this.filtersSrv.selectStatus().subscribe((filter: SearchAvailableFilters) => this.availableStatus = filter);

        this.appliedFiltersSubscription = this.searchSrv.selectFilters().subscribe((filters: SearchAppliedFilter[]) => this.appliedFilters = filters);
    }

    ngOnDestroy() {
        this.labelsSubscription.unsubscribe();
        this.statusSubscription.unsubscribe();
        this.appliedFiltersSubscription.unsubscribe();
    }

    onSelectFilter(key: string){
        this.selectedFilter = key;
    }

    onApplyFilter(filter: SearchAppliedFilter){
        console.log("received filter isssss ",filter);
        this.searchSrv.applyFilter(filter);
    }

}
