import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
// import { LabelsModule } from '@core/components/labels/labels.module';
// import { FilesListModule } from '@core/components/files/lists/lists.module';

import { ProjectsDetailsHelpersModule } from '../../helpers/helpers.module';
import { ProjectsDetailsInfoFeaturesModule } from '../../info/features/features.module';

import { ProjectDetailsLogbookEventHeaderComponent } from './header/event-header.component';
// import { ProjectDetailsLogbookEventPropertiesComponent } from './properties/event-properties.component';
import { ProjectDetailsLogbookEventPropertiesModule } from './properties/properties.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreModule,
        HelpersModule,
        // LabelsModule,
        // FilesListModule,
        ProjectsDetailsHelpersModule,
        ProjectsDetailsInfoFeaturesModule,
        ProjectDetailsLogbookEventPropertiesModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsLogbookEventHeaderComponent
        
    ],
    exports: [
        ProjectDetailsLogbookEventHeaderComponent
    ],

})
export class ProjectDetailsLogbookEventModule { }
