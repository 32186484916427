import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '@core/components/helpers/helpers.module';
import { LabelsModule } from '@core/components/labels/labels.module';

import { AppRouterModule } from '@app/router.module';

import { ProjectDetailsMessagesMainComponent } from './main/main.component';


import { ProjectsDetailsHelpersModule } from '../helpers/helpers.module';
import { ProjectDetailsMessagesListComponent } from './list/list.component';
import { ProjectDetailsMessagesAddComponent } from './add/add.component';

@NgModule({
    imports: [
        CommonModule,
        AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        HelpersModule,
        ProjectsDetailsHelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProjectDetailsMessagesMainComponent,
        ProjectDetailsMessagesListComponent,
        ProjectDetailsMessagesAddComponent
        
    ],
    exports: [
        ProjectDetailsMessagesMainComponent,
    ],

})
export class ProjectsDetailsMessagesModule { }
