import { NgModule, InjectionToken, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ApolloRouterModule } from './apollo.router';
import { ApolloComponentsModule } from './components/components.module';
//import { MainSectionsModule } from './components/main/sections/sections.module';
//import { MainMainModule } from './components/main/main.module';

//import * as fromStore from './store/state/root/root.reducer';
import * as fromStore from './store/state/apollo/apollo.reducer';
import { ApolloEffects } from './store/state/apollo/apollo.effects';



@NgModule({
    imports: [
        CommonModule,
        ApolloComponentsModule,
        //ApolloComponentsModule,
        //MainSectionsModule,
        //MainMainModule,
        ApolloRouterModule,
        StoreModule.forFeature('apollo', fromStore.apolloReducer),
        EffectsModule.forFeature([ApolloEffects])
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
    ],
    exports: [
        ApolloComponentsModule,
        //ApolloComponentsModule,
        //MainSectionsModule,
        //MainMainModule,
        ApolloRouterModule
    ],

})
export class ApolloAppModule { }
