
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'core-helpers-forms-zoom-buttons',
  templateUrl: './zoom-buttons.component.html',
  styleUrls: ['./zoom-buttons.component.scss'],
})
export class ZoomButtonsComponent {

    @Input() inLabel: string = "+";
    @Input() outLabel: string = "-";
    @Input() inStep: number = 0.1;
    @Input() outStep: number = -0.1;
    @Input() inDisabled: boolean = false;
    @Input() outDisabled: boolean = false;
    
    @Output() in = new EventEmitter<number>();
    @Output() out = new EventEmitter<number>();

    onIn(){
        if(this.inDisabled)
            return;

        this.in.emit(this.inStep);
    }

    onOut(){
        if(this.outDisabled)
            return;

        this.out.emit(this.outStep);
    }
}
