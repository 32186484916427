import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';

@Component({
    selector: 'core-file-name-download',
    templateUrl: './file-name-download.component.html',
    styleUrls: ['./file-name-download.component.scss']
})
export class FileNameDownloadComponent {

    @Input() file: File;

    // @Output() onClick = new EventEmitter<File>();

    constructor(public urlService: UrlService) { }

    download(file: File) {
        console.debug("-> core-file-name-download ",file);
        // this.onClick.emit(file);
        let link:string = this.urlService.build('/files/'+file.id);
        console.debug("-> core-file-name-download URL WILL BE ",link);

        window.location.href = this.urlService.build('/files/'+file.id);
    }

}
