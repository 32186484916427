import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { VersionStatusUpdateMenuMainComponent } from './main/main.component';
import { VersionStatusUpdateMenuStatusListComponent } from './status-list/status-list.component';
import { HelperThreeDotsMenuModule } from '../../helpers/navigation/three-dots-menu/three-dots-menu.module';
import { PipesModule } from '@core/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        HelperThreeDotsMenuModule,
        PipesModule

    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
    declarations: [
        VersionStatusUpdateMenuMainComponent,
        VersionStatusUpdateMenuStatusListComponent
    ],
    exports: [
        VersionStatusUpdateMenuMainComponent
    ],

})
export class VersionStatusUpdateMenuModule { }