import { Action } from '@ngrx/store';
import { User } from '@core/objects/user';
import { UserRequest } from '@core/objects/user-request';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatus } from '@core/classes/http-status.class';

export enum SettingsUsersActionType {

	// EVENT CALLS
	LoadAllUsers= "[SettingsUsers] GET - Obtain all users",
	LoadAllUsersSuccess= "[SettingsUsers] - Obtain all users SUCCESS",
	LoadAllUsersError= "[SettingsUsers] - Obtain all users ERROR",

	LoadAllInvitations = "[SettingsUsers] GET - Obtain all invitations",
	LoadAllInvitationsSuccess = "[SettingsUsers] - Obtain all invitations SUCCESS",
	LoadAllInvitationsError = "[SettingsUsers] - Obtain all invitations ERROR",

	AddNewInvitation = "[SettingsUsers] POST - Create new invitation",
	AddNewInvitationSuccess = "[SettingsUsers] - Create new invitation SUCCESS",
	AddNewInvitationError = "[SettingsUsers] - Create new invitation ERROR",

	DeleteOneUser = "[SettingsUsers] DELETE - Remove user",
	DeleteOneUserSuccess = "[SettingsUsers] - Remove user SUCCESS",
	DeleteOneUserError = "[SettingsUsers] - Remove user ERROR",

	DeleteInvitation = '[SettingsUsers] DELETE - Cancel invitation',
	DeleteInvitationSuccess = '[SettingsUsers] - Cancel invitation SUCCESS',
	DeleteInvitationError = '[SettingsUsers] - Cancel invitation ERROR',

	// SET STORE
	SetAllUsers = "[SettingsUsers] - Set all users in the store",
	SetAllInvitations = "[SettingsUsers] - Set all invitations in the store",
	CreateNewUser = "[SettingsUsers] - Add new user in the store",
}

// LOAD-USERS
export class LoadAllUsers implements Action {
    readonly type = SettingsUsersActionType.LoadAllUsers;
    constructor() {}
}
export class LoadAllUsersSuccess implements Action {
    readonly type = SettingsUsersActionType.LoadAllUsersSuccess;
    constructor(public users: User[]) {}
}
export class LoadAllUsersError implements Action {
    readonly type = SettingsUsersActionType.LoadAllUsersError;
    constructor() {}
}

// LOAD-INVITATIONS
export class LoadAllInvitations implements Action {
    readonly type = SettingsUsersActionType.LoadAllInvitations;
    constructor() {}
}
export class LoadAllInvitationsSuccess implements Action {
    readonly type = SettingsUsersActionType.LoadAllInvitationsSuccess;
    constructor(public invitations: UserRequest[]) {}
}
export class LoadAllInvitationsError implements Action {
    readonly type = SettingsUsersActionType.LoadAllInvitationsError;
    constructor(public error: any) {}
}

// ADD-INVITATION
export class AddNewInvitation implements Action {
    readonly type = SettingsUsersActionType.AddNewInvitation;
    constructor(public name:string, public email:string, public message:string, public groups:Array<number>, public extra: any) {}
}
export class AddNewInvitationSuccess implements Action {
    readonly type = SettingsUsersActionType.AddNewInvitationSuccess;
    constructor() {}
}
export class AddNewInvitationError implements Action {
    readonly type = SettingsUsersActionType.AddNewInvitationError;
    constructor(public error? : HttpErrorResponse) {}
}

// DELETE-USER
export class DeleteOneUser implements Action {
    readonly type = SettingsUsersActionType.DeleteOneUser;
    constructor(public id: string) {}
}
export class DeleteOneUserSuccess implements Action {
    readonly type = SettingsUsersActionType.DeleteOneUserSuccess;
    constructor() {}
}
export class DeleteOneUserError implements Action {
    readonly type = SettingsUsersActionType.DeleteOneUserError;
    constructor() {}
}

// DELETE-INVITATION
export class DeleteInvitation implements Action {
    readonly type = SettingsUsersActionType.DeleteInvitation;
    constructor(public email: string) {}
}
export class DeleteInvitationSuccess implements Action {
    readonly type = SettingsUsersActionType.DeleteInvitationSuccess;
    constructor() {}
}
export class DeleteInvitationError implements Action {
    readonly type = SettingsUsersActionType.DeleteInvitationError;
    constructor() {}
}


// STORE
export class SetAllUsers implements Action {
	readonly type = SettingsUsersActionType.SetAllUsers;
	constructor(public users: User[]){}
}

export class SetAllInvitations implements Action {
	readonly type = SettingsUsersActionType.SetAllInvitations;
	constructor(public invitations: UserRequest[]){}
}

export class CreateNewUser implements Action {
	readonly type = SettingsUsersActionType.CreateNewUser;
	constructor(public invitation: UserRequest){}
}

export type SettingsUsersActions = 	LoadAllUsers |
									LoadAllUsersSuccess |
									LoadAllUsersError |

									LoadAllInvitations |
									LoadAllInvitationsSuccess |
									LoadAllInvitationsError |

									AddNewInvitation |
									AddNewInvitationSuccess |
									AddNewInvitationError |

									DeleteOneUser |
									DeleteOneUserSuccess |
									DeleteOneUserError |

									DeleteInvitation |
									DeleteInvitationSuccess |
									DeleteInvitationError |

									// SET STORE
									SetAllUsers |
									SetAllInvitations |
									CreateNewUser;
