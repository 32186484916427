import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';


import { ProjectsModule } from '@core/components/projects/projects.module';
import { DashboardViewsTableDetailsMainComponent } from './main/main.component';
import { DashboardViewsTableDetailsInfoComponent } from './info/info.component';
import { DashboardViewsTableDetailsArtworksComponent } from './artworks/artworks.component';
import { ProjectsDetailsInfoModule } from 'app/components/sections/projects/details/info/info.module';
import { ProjectsDetailsVersionsModule } from 'app/components/sections/projects/details/versions/versions.module';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        ProjectsModule,
        ProjectsDetailsInfoModule,
        ProjectsDetailsVersionsModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        DashboardViewsTableDetailsMainComponent,
        DashboardViewsTableDetailsInfoComponent,
        DashboardViewsTableDetailsArtworksComponent
    ],
    exports: [
        DashboardViewsTableDetailsMainComponent
    ],

})
export class DashboardViewsTableDetailsModule { }
