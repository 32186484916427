import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsRulesService, SettingsRulesEditorService } from '@app/services/settings/srules.service';

import { AvailableRuleItem, RuleEvent, RuleAction, AvailableRuleAction } from '@app/models/rules';
import { AvailableRule } from '@app/store/state/settings/rules/rules.state';

// import { RuleCreator } from '../../rule-creator';
import { OneActionListItemComponent } from '@core/components/helpers/list/one-action-list-item/one-action-list-item.component';

@Component({
  selector: 'settings-rules-rule-form-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class SettingsRulesRuleFormActionsComponent implements OnInit , OnDestroy {

    public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;

    // @Input() creator: RuleCreator;

    // @Input() conditionsList: Array<any> = [];
    // @Input() conditonSelected: any = null;
    // @Input() variableSelected: string = '';
    // @Input() optionSelected: string = '';
    // @Input() valueSelected: string = '';
    // @Output() conditionChange = new EventEmitter<any>();

    // public variable: string = '';
    // public condition: string = '';
    // public value: string = '';
    protected rule: RuleEvent = null;

    public availableActions: Array<AvailableRuleAction> = [];


    public status: number = this.STATUS_LOADING;
    // public actions: Array<RuleAction> = [];

    private availablesSubscriptor: Subscription;
    private editingSubscriptor: Subscription;


    constructor(public srulesSrv: SettingsRulesService, public editorSrv: SettingsRulesEditorService){}

    ngOnInit(){
        this.availablesSubscriptor = this.srulesSrv.selectAvailableEvents().subscribe((availables: AvailableRule[] ) => {

            this.availableActions = [];
            if(this.rule != null && this.rule.key!="") {
                let arule = availables.filter((a:AvailableRule) => a.event.key == this.rule.key);
                this.availableActions = arule[0].actions;

                if(this.rule.actions.length == 0)
                    this.editorSrv.addAction(0);
            }


            this.status = this.availableActions.length > 0? this.STATUS_DONE : this.STATUS_LOADING;
        });

        this.editingSubscriptor = this.srulesSrv.selectEditing().subscribe((edit: RuleEvent) => {
            this.rule = edit;

            if(this.rule != null && this.rule.key!="") {
                // this is feo pero está claro https://youtu.be/1HJIJ_Chi9Q
                if(this.availableActions.length == 0)
                    this.srulesSrv.loadAvailableEventProperties(this.rule.key);
            }

        });

    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.editingSubscriptor.unsubscribe();
        this.availablesSubscriptor.unsubscribe();
    }

    addAction(position: number) {
        this.editorSrv.addAction(position);
    }

    removeAction(position: number) {
        console.log("remove", position);
        this.editorSrv.removeAction(position);
    }

    selectAction(position: number, actionKey: string) {
        // console.log("select action", actionKey);

        let action = {
            ...this.rule.actions[position],
            key: actionKey
        }

        this.editorSrv.changeAction(position, action);
    }

    selectParameters(position: number, parameters: any) {
        console.log("select parameters!!", parameters);

        let action = {
            ...this.rule.actions[position],
            parameters: parameters
        }

        this.editorSrv.changeAction(position, action);
    }

    getAvalaibleAction(key: string ) : AvailableRuleAction {
        let actions = this.availableActions.filter((a: AvailableRuleAction) => a.key == key);

        return actions[0];
    }

}
