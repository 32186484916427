
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { VersionListItemMenuClick } from '@core/components/versions/list/version-list-item-menu-click';

import { AppRouterService } from 'app/services/app/router.service';
import { AppService } from 'app/services/app/app.service';
import { VersionsService } from 'app/services/app/versions.service';


@Component({
    selector: 'project-details-logbook-properties-versions-event',
    templateUrl: './versions-event.component.html',
    styleUrls: ['./versions-event.component.scss']
})
export class ProjectDetailsLogbookVersionsEventPropertiesComponent implements OnInit, OnDestroy{
    protected readonly ACCESS_REQUEST_FEEDBACK = 'share_feedback';

    public readonly LABEL_ADDED = 'versions.labels.added';
    public readonly LABEL_REMOVED = 'versions.labels.removed';
    public readonly STATUS_UPDATED = 'versions.status.updated';
    public readonly MESSAGE_ADDED = 'versions.messages.added';
    public readonly MESSAGE_REMOVED = 'versions.messages.removed';    
    // APPROVALS actions
    public readonly APPROVAL_STATUS_UPDATED = 'versions.approvals.updated';
    // public readonly APPROVAL_STATUS_ADDED = 'versions.approvals.updated'; // not used by now yet

	@Input() event: Objects.Event;

    @Input() properties: any = null;

    public messagePrefixUrl: string = '';

    // version details
    public feedbackPluginAccess: boolean = false;
    public feedbacks: Array<VersionFeedback> = [];

    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "See Artwork Details",
            key: "details",
            icon: "see-details"
        }
    ];

    protected subscriptor: Subscription;
    protected feedbacksSubscriptor: Subscription;

    constructor(private appRouter: AppRouterService,private app: AppService,private versionsSrv: VersionsService){}

    ngOnInit(): void {
        if(this.event != null){
            this.init();
        }
    }

    ngOnDestroy(): void {
        if(this.feedbacksSubscriptor)
            this.feedbacksSubscriptor.unsubscribe();
    }
    private init() : void {

        // set prefix url for messages...
        if(this.event.type == this.MESSAGE_ADDED || this.event.type == this.MESSAGE_REMOVED ){
            this.messagePrefixUrl = '/versions/'+ this.properties.version.id;
        }

        // if(this.event.type == this.ARTWORK_ADDED){
        //     // get plugins access
        //     this.subscriptor = this.app.getSession().subscribe( (session: any) => {
        //         this.feedbackPluginAccess = session.options.plugins.some((pa) => pa == this.ACCESS_REQUEST_FEEDBACK);
        //         this.loadFeedbacks([this.properties.version]);
        //     });
        // }


    }

    onClickPreview(file: Objects.File){
        if (file != null && this.properties.version != null){
            this.appRouter.navigate('/versions/'+this.properties.version.id + "/files/"+file.id);
        }
    }

    onMenuClick(menu: VersionListItemMenuClick) {
        // console.debug(" menu original click",menu.key);
        switch(menu.key) {
            case "details":
                // url to go to Artwork Details
                this.appRouter.navigate("/versions/"+menu.version.id);
                break;
        }
    }

    protected loadFeedbacks(versions: Array<Objects.Version>) {
        if(!this.feedbackPluginAccess) {
            // this.status = this.STATUS_DONE;
            return;
        }

        this.feedbacksSubscriptor = this.versionsSrv.getFeedbacks$(versions).subscribe((feedbacks: Array<VersionFeedback>) => {
            this.feedbacks = feedbacks.filter((f: VersionFeedback) => f.version.id == this.properties.version.id);
                // this.status = this.STATUS_DONE;
        });
    }
}
