import { SelectItem } from '@core/models/select-item.model';

export const keylineOptions : SelectItem[] = [
    {
        title: 'Non',
        value: '',
        selected: true
    },
    {
        title: 'Reference',
        value: 'reference',
        selected: false
    },
    {
        title: 'Target',
        value: 'target',
        selected: false
    }
];
