import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// external dependencies
import { PreviewModule } from '@core/components/preview/preview.module';
import { FilesModule } from '@core/components/files/files.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

// components
import { MessageLegacyComponent } from './message-legacy/message.component';
import { MessageAddFormComponent } from './message-add-form/message-add-form.component';
import { MessageAddComponent } from './message-add/message-add.component';
import { MessageBodyComponent } from './message-body/message-body.component';
import { MessageFilesComponent } from './message-files/message-files.component';
import { MessageComponent } from './message/message.component';
import { MessageFeedbackComponent } from './message-feedback/message-feedback.component';

import { HelperNavigationModule } from '../helpers/navigation/navigation.module';
import { FilesListModule } from '../files/lists/lists.module';

@NgModule({
    imports: [
        CommonModule,
        PreviewModule,
        FilesModule,
        FilesListModule,
        HelpersModule,
        HelperNavigationModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        MessageLegacyComponent,
        MessageAddFormComponent,
        MessageAddComponent,
        MessageBodyComponent,
        MessageFilesComponent,
        MessageComponent,
        MessageFeedbackComponent
        //TextareaCustomComponent,
        //FilesUploadComponent
    ],
    exports: [
        MessageLegacyComponent,
        MessageAddFormComponent,
        MessageAddComponent,
        MessageBodyComponent,
        MessageFilesComponent,
        MessageComponent,
        MessageFeedbackComponent
        //TextareaCustomComponent,
        //FilesUploadComponent
    ],

})
export class MessagesModule { }
