import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { PreviewModule } from '@core/components/preview/preview.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { AngularDraggableModule } from 'angular2-draggable';

// notes types 
import { NoteTypePointComponent } from './note-type-point/note-type-point.component';

import { NotesViewerComponent } from './notes-viewer/notes-viewer.component';
import { NotesPaginationComponent } from './notes-pagination/notes-pagination.component';


@NgModule({
    imports: [
        CommonModule,
        PreviewModule,
        AngularDraggableModule,
        ReactiveFormsModule,
        FormsModule,
        //ModalModule,
        HelpersModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        NoteTypePointComponent,
        NotesViewerComponent,
        NotesPaginationComponent
    ],
    exports: [
        NoteTypePointComponent,
        NotesViewerComponent,
        NotesPaginationComponent
    ],

})
export class NotesModule { }
