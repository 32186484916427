import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRouterModule } from '@app/router.module';

import { ProfileAvatarModule } from './avatar/avatar.module';
import { ProfileMainComponent } from './main/main.component';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
		AppRouterModule,
        FormsModule,
        ReactiveFormsModule,
        ProfileAvatarModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        ProfileMainComponent
    ],
    exports: [
		ProfileMainComponent
	],

})
export class ProfileModule { }
