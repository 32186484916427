import { Component, OnInit } from '@angular/core';
//import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

//import { MustMatch } from '@core/forms/validators/must-match.validator';
import { Router } from '@angular/router';

import { UrlService } from '@core/services/url/url.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    private readonly DEFAULT = "/dashboard";

    protected url: string;

    public error: number = 0;

    constructor(private router: Router, public httpClient: HttpClient, private urlSrv: UrlService ) {}

    ngOnInit() {
        this.url = this.urlSrv.build("/home");

        this.load();
    }

    protected load() {
        this.httpClient.get(this.url, { responseType: 'text'})
        .subscribe(
            (data:string) => {
                console.debug("home --> ", data);
                    let route = data.indexOf('/') == 0? data : this.DEFAULT;

                    this.router.navigate([route]);
                // }

            },
            (error:any) => {
                this.router.navigate([this.DEFAULT]);
                //this.error = error.status;
            }
        );
    }
}
