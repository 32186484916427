import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, OnDestroy  } from '@angular/core';
import { Subscription } from 'rxjs';

import * as Objects from '@core/objects';
import { LabelsService } from '@app/services/app/labels.service';

@Component({
    selector: 'app-helpers-labels-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    providers: []
})
export class HelpersLabelsSearchBoxComponent implements OnInit, OnChanges, OnDestroy {

    @Input() allowClose: boolean = true;
    @Input() add: boolean = false;
    @Input() exclude: Array<Objects.Label> = [];
    @Input() foundText: string = "";
    @Input() placeholder: string = "Search for tags here";
    @Input() input: string = "";
    @Input() headerText: string = "";


    @Output() inputChange = new EventEmitter<string>();
    @Output() choose = new EventEmitter<Objects.Label>();
    @Output() close = new EventEmitter();

    // public input: string = "";
    public labels: Array<Objects.Label> = null;
    public labelsExcluded: Array<Objects.Label> = [];
    public searching: boolean = false;
    

    protected subscriptor: Subscription;

    constructor(private labelsSrv: LabelsService) {}

    ngOnInit() {
        this.subscriptor = this.labelsSrv.selectSearch().subscribe((labels: Array<Objects.Label>) => {
            if (labels != null){

                this.searching = false;
                this.labels = labels.filter((label: Objects.Label) => !this.exclude.some((l: Objects.Label) => l.id == label.id));
                this.labelsExcluded = labels.filter((label: Objects.Label) => this.exclude.some((l: Objects.Label) => l.id == label.id));
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.exclude){
            // TODO see if empty or spaces triggers the search
            if(this.input.trim() != '' ) {
                this.labelsSrv.search(this.input);
                this.searching = true;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptor.unsubscribe();
        this.onInputChange(''); // reset input
    }

    onInputChange(event: string) {
        this.input = event;
        this.inputChange.emit(this.input);

        if(event.length == 0 || event.trim() == '') {
            this.labels = null;
            return;
        }

        this.labelsSrv.search(this.input);
        this.searching = true;

    }

    selectLabel(label: Objects.Label) {
        console.log("select---->", label);

        this.choose.emit(label);
    }
    // onBackspace(event) {
    // }

    onEnter(event) {
        event.preventDefault();

    }

}
