import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// external dependencies

// components
import { LabelComponent } from './label/label.component';
import { LabelsTextComponent } from './labels-text/labels-text.component';
import { LabelsListComponent } from './labels-list/labels-list.component';
import { LabelActionComponent } from './label-action/label-action.component';
import { LabelsListExcludedComponent } from './labels-list-excluded/labels-list-excluded.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        LabelComponent,
        LabelsTextComponent,
        LabelsListComponent,
        LabelActionComponent,
        LabelsListExcludedComponent
    ],
    exports: [
        LabelComponent,
        LabelsTextComponent,
        LabelsListComponent,
        LabelActionComponent,
        LabelsListExcludedComponent
    ],

})
export class LabelsModule { }
