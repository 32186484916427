import { Component, Input } from '@angular/core';

import { Message } from '@core/objects/message';
import { VersionFeedback } from '@core/models/version-feedback';

@Component({
    selector: 'core-message-body',
    templateUrl: './message-body.component.html',
    styleUrls: ['./message-body.component.scss']
})
export class MessageBodyComponent {

    @Input() message: Message;
    @Input() feedback:  VersionFeedback = null;

}
