import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, empty, of, throwError, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import * as Objects from '@core/objects';

import * as Access from '@app/models/access';

import { VersionsRequests } from './versions.requests';

import * as fromStore from './versions.state';
import * as fromActions from './versions.actions';

@Injectable()
export class VersionsEffects {

    constructor(private actions$: Actions, private store$: Store<fromStore.Versions>, private requests: VersionsRequests) {}

    @Effect()
    public loadVersion$: Observable<fromActions.VersionsActions> = this.actions$.pipe(
        ofType<fromActions.LoadVersion>(fromActions.VersionsActionType.LoadVersion),
        switchMap((action) => {
            return this.requests.load$(action.versionId).pipe(
                map((version: Objects.Version) => {
                    return new fromActions.LoadVersionSuccess(version);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadVersionError(action.versionId,err));
                })
            );
        })
    );

    @Effect()
    public loadVersionSuccess$ = this.actions$.pipe(
        ofType<fromActions.LoadVersionSuccess>(fromActions.VersionsActionType.LoadVersionSuccess),
         //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            new fromActions.LoadVersionFiles(),
            new fromActions.LoadVersionMessages(),
            new fromActions.LoadVersionComments(),
            new fromActions.LoadEditorAccess(),
            new fromActions.LoadVersionProject(),
        ])
    );

    @Effect()
    public loadVersionError$ = this.actions$.pipe(
        ofType<fromActions.LoadVersionError>(fromActions.VersionsActionType.LoadVersionError),
        
        switchMap((action) => {
            
            if (action.error.status == 403)
                return [new fromActions.LoadVersionHeader(action.versionId)];
            else 
                return []
        })
    );

    @Effect()
    public loadVersionHeader$:Observable<fromActions.VersionsActions> = this.actions$.pipe(
        ofType<fromActions.LoadVersionHeader>(fromActions.VersionsActionType.LoadVersionHeader),
        switchMap((action) => {
            return this.requests.loadHeader$(action.versionId).pipe(
                map((header: Objects.Version) => {
                    return new fromActions.LoadVersionHeaderSuccess(header);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadVersionHeaderError(err));
                })
            );
        })
    );

    @Effect()
    public loadRelateds$: Observable<fromActions.VersionsActions> = this.actions$.pipe(
        ofType<fromActions.LoadRelatedVersions>(fromActions.VersionsActionType.LoadRelatedVersions),
        switchMap((action) => {
            return this.requests.loadRelateds$(action.link).pipe(
                map((versions: Objects.Version[]) => {
                    return new fromActions.LoadRelatedVersionsSuccess(versions);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadRelatedVersionsError(err));
                })
            );
        })
    );

    @Effect()
    public loadVersionFiles$ = this.actions$.pipe(
        ofType<fromActions.LoadVersionFiles>(fromActions.VersionsActionType.LoadVersionFiles),
        withLatestFrom(this.store$.select(fromStore.versionSelector)),
        switchMap(([action, version]) => {
            return this.requests.loadFiles$(version.id).pipe(
                map((files: Array<Objects.File>) => {
                    return new fromActions.LoadVersionFilesSuccess(files);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadVersionFilesError(err));
                })
            );
          
        })
    );

    @Effect()
    public loadVersionMessages$ = this.actions$.pipe(
        ofType<fromActions.LoadVersionMessages>(fromActions.VersionsActionType.LoadVersionMessages),
        withLatestFrom(this.store$.select(fromStore.versionSelector)),
        switchMap(([action, version]) => {
            return this.requests.loadMessages$(version.id).pipe(
                map((messages: Array<Objects.Message>) => {
                    return new fromActions.LoadVersionMessagesSuccess(messages);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadVersionMessagesError(err));
                })
            );
          
        })
    );

    @Effect()
    public loadVersionContextComments$ = this.actions$.pipe(
        ofType<fromActions.LoadVersionComments>(fromActions.VersionsActionType.LoadVersionComments),
        withLatestFrom(this.store$.select(fromStore.versionSelector)),
        switchMap(([action, version]) => {
            return this.requests.loadComments$(version.id).pipe(
                map((messages: Array<Objects.VersionMessage>) => {
                    return new fromActions.LoadVersionCommentsSuccess(messages);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadVersionCommentsError(err));
                })
            );
          
        })
    );

    @Effect()
    public loadVersionProject$ = this.actions$.pipe(
        ofType<fromActions.LoadVersionProject>(fromActions.VersionsActionType.LoadVersionProject),
        withLatestFrom(this.store$.select(fromStore.versionSelector)),
        switchMap(([action, version]) => {
            return this.requests.loadProject$(version.id).pipe(
                map((project: Objects.Request) => {
                    return new fromActions.LoadVersionProjectSuccess(project);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadVersionProjectError(err));
                })
            );
          
        })
    );

    @Effect()
    public loadEditorAccess$ = this.actions$.pipe(
        ofType<fromActions.LoadEditorAccess>(fromActions.VersionsActionType.LoadEditorAccess),
        withLatestFrom(this.store$.select(fromStore.versionSelector)),
        switchMap(([action, version]) => {
            return this.requests.loadAccess$(version.id).pipe(
                map((access: Access.AccessVersion) => {
                    return new fromActions.LoadEditorAccessSuccess(access);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.LoadEditorAccessError(err));
                })
            );
          
        })
    );

    @Effect()
    public setVersionStatus$ = this.actions$.pipe(
        ofType<fromActions.SetVersionStatus>(fromActions.VersionsActionType.SetVersionStatus),
        withLatestFrom(this.store$.select(fromStore.versionSelector)),
        switchMap(([action, version]) => {
            return this.requests.setStatus$(version.id, action.status).pipe(
                map((version: Objects.Version) => {
                    return new fromActions.SetVersionStatusSuccess(version);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.SetVersionStatusError(err));
                })
            );
          
        })
    );

    @Effect()
    public setVersionStatusSuccess$ = this.actions$.pipe(
        ofType<fromActions.SetVersionStatusSuccess>(fromActions.VersionsActionType.SetVersionStatusSuccess),
         //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            new fromActions.LoadEditorAccess(),
        ])
    );

    @Effect()
    public setVersionStatusError$ = this.actions$.pipe(
        ofType<fromActions.SetVersionStatusError>(fromActions.VersionsActionType.SetVersionStatusError),
        withLatestFrom(this.store$.select(fromStore.versionSelector)),
        switchMap(([action, version]) => [
            new fromActions.LoadVersion(version.id)
        ])
    );

    @Effect()
    public setVersionLabels$ = this.actions$.pipe(
        ofType<fromActions.SetVersionLabels>(fromActions.VersionsActionType.SetVersionLabels),
        withLatestFrom(this.store$.select(fromStore.versionSelector)),
        switchMap(([action, version]) => {
            return this.requests.setLabels$(version.id, action.labels).pipe(
                map((version: Objects.Version) => {
                    return new fromActions.SetVersionLabelsSuccess(version);
                }),
                catchError((err: any) => {
                    console.log(err);
                    throwError(err);
                    return of(new fromActions.SetVersionLabelsError(err));
                })
            );
          
        })
    );

    @Effect()
    public setVersionLabelsSuccess$ = this.actions$.pipe(
        ofType<fromActions.SetVersionLabelsSuccess>(fromActions.VersionsActionType.SetVersionLabelsSuccess),
         //withLatestFrom(this.store$.select(fromStore.usersGetAllUsersSelector)),
        switchMap((action) => [
            // new LoadEditorAssigners(),
            // new LoadEditorAccess(),
        ])
    );

    @Effect()
    public setVersionLabelsError$ = this.actions$.pipe(
        ofType<fromActions.SetVersionLabelsError>(fromActions.VersionsActionType.SetVersionLabelsError),
        withLatestFrom(this.store$.select(fromStore.versionSelector)),
        switchMap(([action, version]) => [
            new fromActions.LoadVersion(version.id)
        ])
    );

    @Effect()
    public updateVersion$ = this.actions$.pipe(
        ofType<fromActions.UpdateVersion>(fromActions.VersionsActionType.UpdateVersion),
        withLatestFrom(this.store$.select(fromStore.viewSelector)),
        switchMap(([action, view]) => {
            let events = [];
            if(view.id === action.versionId)
                events.push(new fromActions.LoadVersion(action.versionId));
            
            return events;
            
        })
    );
}
