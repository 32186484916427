import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ProjectsEditorService, ProjectsService } from '@app/services/app/projects.service';
import { VersionsEditorService, VersionsViewService } from '@app/services/app/versions.service';

// import { UrlService } from '@core/services/url/url.service';

import * as Objects from '@core/objects';
import { TitleService } from '@core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { AppService } from '@app/services/app/app.service';
import { VersionsService } from '@app/services/app/versions.service';
import { AccessVersion } from '@app/models/access';
import { AppRouterService } from '@app/services/app/router.service';
import { AddProjectService } from '@app/services/app/add-project.service';

@Component({
    selector: 'project-version-details-main',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class ProjectDetailsVersionDetailsComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_ERROR = 2;
    public readonly STATUS_NOT_LINKED = 3;
    public readonly STATUS_NOT_FOUND= 4;
    public readonly STATUS_NOT_ALLOWED= 5;

    public readonly PLUGIN_REQUEST_FEEDBACK = 'share_feedback';
    public readonly PLUGIN_SEND_FILES = 'share_transfer';
    public readonly ACCESS_UPLOAD_VERSION = "access_request_upload_version";
    public readonly ACCESS_ADD_PROJECT = "access_global_new_request";
    public readonly ACCESS_EDIT_TAGS =  'access_version_edit_labels';
    public readonly ACCESS_CHANGE_STATUS =  'access_version_change_status';

    public version: Objects.Version = null;
    public project: Objects.Request = null;
    public originProject: Objects.Request = null;
    public bcTitle: string = 'ARTWORK v. . . . <small>(# .  .  . )</small>';

    public excludedLabels: Array<Objects.Label> = [];
    public allVersionStatus: Array<Objects.Status> = null;
    public allowedVersionStatus: Array<Objects.Status> = [];

    public pluginsAccess: Array<string> = [];
    public versionAccess: AccessVersion = null;
    public uploadVersionAccess: boolean = false;
    public addProjectAccess: boolean = false;
    public context: string = '';

    public breadcrumbs: any = [];
    // TODO review menu icons
    public menu: Array<ThreeDotsMenuItem> = [];
    // optional menu items
    protected menuUploadVersion: ThreeDotsMenuItem = {
        title: "Upload Artwork",
        key: "upload-artwork",
        icon: "upload_artwork",
        order: 1
    };
    protected menuSendFiles: ThreeDotsMenuItem = {
        title: "Send",
        key: "send-files",
        icon: "send-files",
        order: 2
    };
    protected menuRequestFeedback: ThreeDotsMenuItem = {
        title: "Request Feedback",
        key: "feedback",
        icon: "feedback",
        order: 3
    };
    protected menuAddProject: ThreeDotsMenuItem = {
        title: "Start New Project",
        key: "add-project",
        icon: "add",
        order: 4
    };
    protected menuSeeOriginalProject: ThreeDotsMenuItem = {
        title: "See Original Project",
        key: "see-project",
        icon: "see-project",
        order: 5
    };
    // protected menuEditTags: ThreeDotsMenuItem = {
    //     title: "Edit Tags",
    //     key: "edit",
    //     icon: "edit-tags",
    //     order: 6
    // };



    public status:number = this.STATUS_LOADING;

    protected urlSubscriptor: Subscription;
    protected urlqSubscriptor: Subscription;
    protected projectSubscriptor: Subscription;
    protected versionSubscriptor: Subscription;
    protected originProjectSubscriptor: Subscription;
    protected accessProjectSubscriptor: Subscription;
    protected accessVersionSubscriptor: Subscription;
    protected errorSubscriptor: Subscription;


    constructor(
        public app: AppService,
        private title: TitleService,
        private activeRoute: ActivatedRoute,
        private projectsSrv: ProjectsService,
        private viewVersionsSrv: VersionsViewService,
        private editorVersionSrv: VersionsEditorService,
        private projectEditorSrv: ProjectsEditorService,
        private AddProjectSrv: AddProjectService,
        private versionsSrv: VersionsService,
        private appRouter: AppRouterService
    ) { }

    ngOnInit() {
        this.setDefaultBredcrumb();
        // set temporal header title
        this.title.set("Artwork... - Project...");
        // TE QUIERO RIDE COMO A MI BIKE - CHICKEN TERIYAKI
        this.urlqSubscriptor = this.activeRoute.queryParams.subscribe((params: any) => {
            console.log("query params ===>", params);
            if (params.context) {
                this.context = params.context;
            }
        });

        this.projectSubscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.Request) => {
            this.project = project;
            this.initView();
        });

        this.versionSubscriptor = this.viewVersionsSrv.selectVersion().subscribe((version: Objects.Version) =>
        {
            this.version = version;
            if(version != null){
                this.bcTitle = 'ARTWORK v.'+ version.version+' <small>(#'+ version.id+')</small>';
                this.versionsSrv.getNextStatus$(this.version.id).subscribe((status: Array<Objects.Status>) => {
                    this.allVersionStatus = status;
                    this.updateAllowedStatus();
                    this.initView();
                });
            }
            this.initView();
        });

        this.originProjectSubscriptor = this.viewVersionsSrv.selectProject().subscribe((projectOrigin: Objects.Request) => {
            this.originProject = projectOrigin
            this.initView();
        });

        this.urlSubscriptor = this.activeRoute.params.subscribe((params: any) => {
            // console.log(" params ", params);
            this.viewVersionsSrv.load(+params.vid);

        });
        // subscribe to upload version access
        this.accessProjectSubscriptor = this.projectEditorSrv.selectAccess(this.ACCESS_UPLOAD_VERSION).subscribe((access: boolean) => {
            this.uploadVersionAccess = access;
            this.updateMenu(this.menuUploadVersion,this.uploadVersionAccess);
        });

        this.accessVersionSubscriptor = this.editorVersionSrv.selectAccessAll().subscribe((access: AccessVersion)=> {
            if(access != null && this.status != this.STATUS_NOT_ALLOWED){
                this.versionAccess = access;
                this.updateAllowedStatus();
                console.log(" version access---->",this.versionAccess);
                this.initView();
            }
        });


        // plugins & add project from session
        this.app.getSession().subscribe( (session: any) => {
            this.pluginsAccess = session.options.plugins;
            this.addProjectAccess = session.options.access.hasOwnProperty(this.ACCESS_ADD_PROJECT)? session.options.access[this.ACCESS_ADD_PROJECT] : false;

            this.updateMenu(this.menuSendFiles,this.checkPluginAccess(this.PLUGIN_SEND_FILES));
            this.updateMenu(this.menuRequestFeedback,this.checkPluginAccess(this.PLUGIN_REQUEST_FEEDBACK));
            this.updateMenu(this.menuAddProject,this.addProjectAccess);
        });

        this.errorSubscriptor = this.viewVersionsSrv.selectError('load_version').subscribe((error: HttpErrorResponse) => {
            this.setError(error)
        });
    }

    ngOnDestroy() {
        this.urlSubscriptor.unsubscribe();
        this.urlqSubscriptor.unsubscribe();
        this.projectSubscriptor.unsubscribe();
        this.versionSubscriptor.unsubscribe();
        this.originProjectSubscriptor.unsubscribe();
        this.accessProjectSubscriptor.unsubscribe();
        this.accessVersionSubscriptor.unsubscribe();
        this.errorSubscriptor.unsubscribe();
        this.viewVersionsSrv.clear();
    }

    private initView() {
        if(this.project != null && this.version != null){
            if (!this.isRelated()){
                console.debug(" The Artwork opened is not related to this project");
                this.status = this.STATUS_NOT_LINKED;
                return;
            }
            this.updateBreadcrumbs();
        }

        if(this.versionAccess == null || this.allVersionStatus == null || this.project == null || this.version == null  || this.originProject == null)
            return;
        this.setHeaderTitle();
        if(this.originProject.id != this.project.id){
            this.updateMenu(this.menuSeeOriginalProject);
        }
        this.status = this.STATUS_DONE;
    }

    private setHeaderTitle() {
        this.title.set("Artwork #"+ this.version.id + "- Project #" + this.project.id);
    }

    onMenuClick(key: string) {
        switch(key) {
            case "upload-artwork":
                this.appRouter.navigate('/projects/'+this.project.id+'/versions/add', { queryParams: { links: this.version.labels.map((l: Objects.Label) => l.id).sort().join("-") } });
                break;
            case "add-project":
                if(this.version != null){
                    // clean new project store just in case
                    this.AddProjectSrv.clear();
                    this.appRouter.navigate('/projects/add', { queryParams: { version: this.version.id} });
                }
                break;
            case "see-project":
                this.appRouter.navigate('/projects/'+this.originProject.id);

                break;
            case "feedback":
                if (this.checkPluginAccess(this.PLUGIN_REQUEST_FEEDBACK))
                    this.appRouter.navigate('/p/feedback/'+this.project.id, { queryParams: { version: this.version.id} });
                break;
            case "send-files":
                if (this.checkPluginAccess(this.PLUGIN_SEND_FILES))
                    this.appRouter.navigate('/p/transfer/'+this.project.id, { queryParams: { version: this.version.id} });
                break;
        }
    }

    protected updateBreadcrumbs(){
        if(this.project == null)
            return;
        // PENDING IF THE CONTEXT IS FINALLY NECESSARY
        switch (this.context) {
            // case "origin":
                
            //     when came from origin no project breadcrumb all tags as excluded labels
            //     this.excludedLabels = this.version.labels;
            //     break;
            case "related":
            default:
                this.excludedLabels = this.project.labels;
                this.breadcrumbs[0] = {link: '/projects/'+this.project.id,
                                        title: 'Project <small>(#'+this.project.id+ ')</small>'};
                break;
        }
    }

    onStatusUpdate(status: Objects.Status) {
        this.viewVersionsSrv.setStatus(status);
    }

    protected checkPluginAccess(plugin: string){
        return this.pluginsAccess.some((pa) => pa == plugin);
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

    // the common tags between project and version should be all of the project.
    protected isRelated(){
        let common = this.project.labels.filter((pl: Objects.Label) => this.version.labels.some((vl: Objects.Label) => vl.id == pl.id));
        if (common.length == this.project.labels.length)
            return true;
        return false;
    }

    protected updateAllowedStatus(){
        if(this.allVersionStatus != null && this.versionAccess != null){
            this.allowedVersionStatus = this.versionAccess[this.ACCESS_CHANGE_STATUS] ? this.allVersionStatus : [];
        }
    }

    protected setError(error: HttpErrorResponse) {
        // console.log("we have errors ",error)
        if(error == null)
            return;
        switch(error.status) {
            case 500:
                this.status = this.STATUS_ERROR;
                break;
            case 404:
                this.status = this.STATUS_NOT_FOUND;
                break;
            case 403:
                this.viewVersionsSrv.clear();
                this.status = this.STATUS_NOT_ALLOWED;
                break;
        }
    }

    protected setDefaultBredcrumb(){
        this.breadcrumbs[0] = {link: '',
                            title: 'Project <small>( # . . . )</small>'};
    }


}
