import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsRulesEditorService } from '@app/services/settings/srules.service';
import { MultiSelectItem } from '@core/models/select-item.model';

import { AvailableRuleItem, AvailableRuleActionVariable, AvailableRuleEventCondition, AvailableRuleAction, RuleEvent } from '@app/models/rules';
import { SettingsRulesEditScroll } from '@app/store/state/settings/rules/rules.state';

@Component({
  selector: 'settings-rules-rule-form-helper-select-multiple',
  templateUrl: './helper-select-multiple.component.html',
  styleUrls: ['./helper-select-multiple.component.scss'],
})
export class SettingsRulesFormHelperSelectMultipleComponent implements OnInit, OnChanges {

    @Input() disabled: boolean;
    @Input() optionsSelected: Array<string | number> = [];
    @Input() label: string = "";
    @Input() options: AvailableRuleItem[] = [];
    @Input() placeholder: string = "";
    @Input() key: string = '';
    // @Output() selectScrollTop = new EventEmitter<number>();
    @Output() select = new EventEmitter<AvailableRuleItem[]>();

    public items: Array<MultiSelectItem> = [];
    public scrollTopValue: number = 0;

    private scrollSubscriptor: Subscription;

    constructor (public editorSrv: SettingsRulesEditorService){}

    ngOnInit() {
        this.init();

        this.scrollSubscriptor = this.editorSrv.selectScroll().subscribe((scroll: Array<SettingsRulesEditScroll>) => {
            let selectScroll = scroll.filter((s: SettingsRulesEditScroll) => s.key == this.key);

            this.scrollTopValue = selectScroll.length > 0 ? selectScroll[0].value : 0;
        })
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.options) {
            this.init();
        }
    }

    protected init() {
        this.items = this.options.map((o: AvailableRuleItem) => ({
            title: o.description,
            value: o.key.toString(),
            selected: this.isSelected(o.key.toString()),
            disabled: o.key.toString().indexOf('disabled')>-1
        }));
    }

    private isSelected(key: string) : boolean {
        return this.optionsSelected.map((o: string | number) => o.toString()).indexOf(key) > -1;
    }

    onSelect(select: Array<string | number>) {
        let selected = select.map((v: string | number) => v.toString());

        let oselected = this.options.filter((o: AvailableRuleItem)=> selected.indexOf(o.key.toString()) > -1);

        this.select.emit(oselected);
    }

    onScrollTop(value: number){
        this.editorSrv.setScroll(this.key, value);
    }
}
