import { SelectItem } from '@core/models/select-item.model';

export const methodOptions : SelectItem[] = [
    {
        title: 'ORDERED',
        value: 'comparison',
        selected: false
    },
    {
        title: 'UN-ORDERED',
        value: 'is-contained',
        selected: false
    }
];
