import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';

// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// import { UrlService } from '@core/services/url/url.service';

import { AvailableRuleItem, AvailableRuleActionVariable, AvailableRuleAction, RuleEvent, RuleAction, RuleCondition } from '@app/models/rules';

import * as fromSettingsRulesState from '@app/store/state/settings/rules/rules.state';
import * as fromSettingsRulesActions from '@app/store/state/settings/rules/rules.actions';

// import { Rule } from '@core/objects/rule';



@Injectable({
    providedIn: 'root'
})
export class SettingsRulesService {

    constructor(private store: Store<fromSettingsRulesState.SettingsRules>) {}
 
    loadAvailableEvents = () => this.store.dispatch( new fromSettingsRulesActions.LoadAvailableEvents());

    loadAvailableEventProperties = (eventKey: string) => this.store.dispatch( new fromSettingsRulesActions.LoadAvailableEvent(eventKey));

    selectAvailableEvents = () => this.store.select(fromSettingsRulesState.settingsRulesAvailableSelector);

    // load rules list
    loadRules = () => this.store.dispatch( new fromSettingsRulesActions.LoadRules());

    selectRules = () => this.store.select(fromSettingsRulesState.settingsRulesRulesSelector);

    // active / deactive
    activeRule = (ruleId: number) => this.store.dispatch(new fromSettingsRulesActions.SetActiveRule(ruleId, true));

    deactiveRule = (ruleId: number) => this.store.dispatch(new fromSettingsRulesActions.SetActiveRule(ruleId, false));

    // load and edit rule
    selectEditing = () => this.store.select(fromSettingsRulesState.settingsRulesEditingSelector);

    loadRule = (ruleId: number) => this.store.dispatch(new fromSettingsRulesActions.LoadRule(ruleId));
}

@Injectable({
    providedIn: 'root'
})
export class SettingsRulesEditorService {

    constructor(private store: Store<fromSettingsRulesState.SettingsRules>) {}

    clear = () => this.store.dispatch( new fromSettingsRulesActions.ClearRule());
 
    setEventKey = (eventKey: string) => this.store.dispatch( new fromSettingsRulesActions.SetRuleEventKey(eventKey));

    setDescription = (description: string) => this.store.dispatch( new fromSettingsRulesActions.SetRuleDescription(description));

    setConditionsControl = (control: string) => this.store.dispatch( new fromSettingsRulesActions.SetRuleConditionsControl(control));

    addAction = (position: number) => this.store.dispatch( new fromSettingsRulesActions.AddRuleAction(position));

    changeAction = (position: number, action: RuleAction) => this.store.dispatch( new fromSettingsRulesActions.ChangeRuleAction(position, action));

    removeAction = (position: number) => this.store.dispatch( new fromSettingsRulesActions.RemoveRuleAction(position));

    addCondition = (position: number) => this.store.dispatch( new fromSettingsRulesActions.AddRuleCondition(position));

    changeCondition = (position: number, condition: RuleCondition) => this.store.dispatch( new fromSettingsRulesActions.ChangeRuleCondition(position, condition));
    
    // changeConditionVariable = (position: number, variable: string) => this.store.dispatch( new fromSettingsRulesActions.ChangeRuleConditionVariable(position, variable));

    removeCondition = (position: number) => this.store.dispatch( new fromSettingsRulesActions.RemoveRuleCondition(position));

    save = () => this.store.dispatch( new fromSettingsRulesActions.SaveRule());

    // scroll for multiselect
    setScroll = (key: string, scroll: number) => this.store.dispatch( new fromSettingsRulesActions.SetEditSelectScroll(key, scroll));
    
    selectScroll = () => this.store.select(fromSettingsRulesState.settingsRulesEditingScrollSelector);
}
