import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UrlService } from '@core/services/url/url.service';

import { Workflow } from '@core/objects/workflow';
import { Status } from '@core/objects/status';

@Injectable({
    providedIn: 'root'
})
export class WorkflowsService {

    constructor(protected url: UrlService, protected http: HttpClient) {}

    getAllWorkflows$(prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/workflows"),{params: {all: 'true'}});
    }

    getWorkflows$(prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/workflows"));
    }

    getAllWorkflowTransitions$(id: number, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/workflows/" + id + "/transitions"), {params: {all: 'true'}});
    }

    // TODO this call should not be in settings service
    getWorkflowTransitionsByOrigin$(status: Status, prefixUrl: string = "") {
        return this.http
        .get(this.url.build(prefixUrl+"/workflows/transitions?origin="+status.id));
    }

    deactivateWorkflow$(id: number, prefixUrl: string = "") {
        return this.http
        .delete(this.url.build(prefixUrl+"/workflows/" + id ));
    }

    activateWorkflow$(id: number, prefixUrl: string = "") {
        return this.http
        .put(this.url.build(prefixUrl+"/workflows/" + id ),
            {active: true}
        );
    }

    updateWorkflowName$(name: string,id: number, prefixUrl: string = "") {
        let wfData: any = {
            name: name
        };
        return this.http
        .put(this.url.build(prefixUrl+"/workflows/" + id ),
            wfData
        );
    }

    updateWorkflowTransitions$(transitions: any,id: number, prefixUrl: string = "") {
        return this.http
        .post(this.url.build(prefixUrl+"/workflows/" + id + "/transitions"),
            transitions
        );
    }



    addWorkflow$(name: string, prefixUrl: string = "") {
        let options = {headers: new HttpHeaders({
            'Content-Type':  'application/json',
        })};
        let wfData: any = {
            name: name
        };
        return this.http.post(
            this.url.build(prefixUrl+"/workflows"),
            wfData,
            options
        );
    }
}
