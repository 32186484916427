import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApolloContentComparison, ApolloContentComparisonExtract } from '../../../model/apollo-content-comparison.model';

import { Router } from "@angular/router";
import { ApolloService } from '../../../services/apollo/apollo.service';
import { ApolloRequest } from '../../../model/apollo-request.model';

@Component({
    selector: 'plugin-apollo-helpers-content-extract',
    templateUrl: './content-extract.component.html',
    styleUrls: ['./content-extract.component.scss']
})
export class PluginApolloHelpersContentExtractComponent implements OnInit, OnDestroy {

    // @Output() newExtractProcess = new EventEmitter<boolean>(); // @deprecated Emit to hide component compare
    @Output() ready = new EventEmitter<boolean>(); // Emit to hide component compare

    public subscriptionRequest: Subscription;

    public loading:boolean = true;
    public openExtraction:boolean = false;
    //public tmpOpenExtraction: boolean = false;

    // public disabledBtn:boolean = true;
    public request: ApolloRequest;

    // public httpStatus = {
    //     code: null,
    //     text: null
    // };

    public infoOpened: boolean = false;

    //public errorExtract: boolean = false;
    public textError = {
        text: 'An error has ocurred. Please try again',
        button: 'Try again'
    }

    constructor(public apolloSrv: ApolloService, private router: Router){}

    ngOnInit() {
        this.subscriptionRequest = this.apolloSrv.selectRequest().subscribe((request : ApolloRequest) => {
            this.request = request

            if(request.apollo_content != null) {
                // extracting
                if(request.apollo_content.status_code == 5)
                {
                    this.ready.emit(false);
                    //this.openExtraction = false;
                    this.loading = true;
                    // if(request.apollo_content.reference_extract.status_code == 0 &&
                    //   request.apollo_content.target_extract.status_code == 0) {
                    //
                    // }
                    return;
                }

                this.loading = false;
                // done
                if( request.apollo_content.reference_extract != null &&
                    request.apollo_content.target_extract != null &&
                    request.apollo_content.reference_extract.status_code == 10 &&
                    request.apollo_content.target_extract.status_code == 10)
                {
                    this.ready.emit(true);
                    //this.openExtraction = this.tmpOpenExtraction;
                    return;
                }

                this.openExtraction = true;
                this.ready.emit(false);

            }
            // //--
            // if (this.request.apollo_content != null) {
            //
            //
            //
            //     this.httpStatus = {
            //         code: this.request.apollo_content.status_code,
            //         text: this.request.apollo_content.status_text
            //     }
            //
            //
            //     switch (this.httpStatus.code) {
            //         case 5:
            //             this.loadingExtraction();
            //             break;
            //         case 6:
            //             this.errorExtraction();
            //             break;
            //         default:
            //             this.extractionComplete();
            //             break;
            //     }
            // }
        });
    }

    ngOnDestroy(){
        this.subscriptionRequest.unsubscribe();
    }

    changeMethod(event) {
        this.apolloSrv.updateContentComparison(event.method);
    }

    newExtract(data, type){
        //this.tmpOpenExtraction = this.openExtraction;
        //this.openExtraction = false;
        this.loading = true;
        //this.loadingExtraction();
        //this.newExtractProcess.emit(true);
        this.ready.emit(false);
        this.apolloSrv.updateExtractComparison(data, type);
    }

    // extractContent(){
    //     this.openExtraction = !this.openExtraction;
    // }

    setInfo(info: boolean) {
        this.infoOpened = info;
    }


    tryAgain(){
        if (this.request.apollo_content.error_message != '') {
            return this.router.navigate(['/p/apollo/upload']);
        }
        //this.loadingExtraction();
        this.apolloSrv.loadContentComparison();
    }

    // loadingExtraction() {
    //     // Restore buttons, collapse...
    //     this.openExtraction = false;
    //     this.errorExtract = false;
    //     this.loading = true;
    // }

    // extractionComplete() {
    //     this.loading = false;
    //     this.errorExtract = false;
    // }

    // errorExtraction(){
    //     if (this.request.apollo_content.error_message != '') {
    //         this.textError.text = this.request.apollo_content.error_message;
    //         this.textError.button = "Back to upload";
    //         this.errorExtract = true;
    //     } else {
    //         this.textError.text = 'An error has ocurred. Please try again';
    //         this.textError.button = "Try again";
    //         this.request.apollo_content.target_extract.status_code == 11 || this.request.apollo_content.reference_extract.status_code == 11 ?
    //             this.errorExtract = false : this.errorExtract = true;
    //         this.loading = false;
    //     }
    //     this.openExtraction = true;
    //     this.newExtractProcess.emit(true);
    //     this.ready.emit(false);
    // }
}
