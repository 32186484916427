import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Pusher from 'pusher-js';
import { UrlService } from '../url/url.service';
import { Subject} from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    private pusher: any;
    private events: Array<WebsocketSubscriptor> = [];
    private authEndpoint =  "/websocket/auth";
    private endpointPrefix = environment.twonaBaseUrl + "/web/websocket";
    // websocket state
    static state$ = new Subject<any>(); // any ==> {previous: '', current: ''};

    constructor(public url: UrlService, public http: HttpClient) {}

    public connect(key:any, cluster:any) {

        let authEndpoint = this.url.build(this.authEndpoint);

        // start authorizer function
        let authorizer = (channel, options) => {
            return {
                authorize: (socketId, callback) => {
                    const data = new URLSearchParams();
                    data.append('socket_id', socketId);
                    data.append('channel_name', channel.name);
                    
                    fetch(authEndpoint, {
                        method: 'POST',
                        credentials: 'include',
                        headers: new Headers({"Content-Type": "application/x-www-form-urlencoded"  }),
                        body: data
                    })
                    .then(res => {
                        if (!res.ok) {
                            throw new Error(`Received ${res.status} from ${authEndpoint}`);
                        }
                        return res.json();
                    })
                    .then(data => {
                        callback(null, data);
                    })
                    .catch(err => {
                        callback(new Error(`Error calling auth endpoint: ${err}`), {
                            auth: ''
                        });
                    });
                }
            };
        }; // end authorizer function

        this.pusher = new Pusher(key, {
          cluster: cluster,
          forceTLS: true,
          authorizer: authorizer
        //   authEndpoint: authEndpoint
        });
        this.pusher.connection.bind('state_change', function(states) {
            WebsocketService.state$.next(states);
            // states = {previous: 'oldState', current: 'newState'}
            console.log(" >>>Temp Message<<< pusher connection current state is  "+ states.current )
          });
    }

    public getState$(){
        return WebsocketService.state$.asObservable();
    }

    /**
     * @deprecated
     */
    public addChannel(channelName: string) : Pusher.Channel {
        return this.channel(channelName);
    }

    public channel(channelName: string) : Pusher.Channel {
        let channel = this.pusher.subscribe(channelName);
        channel.bind_global((event, data) => { this.onEvent (event, data)});

        return channel;
    }

    public unbind(channel: Pusher.Channel) {
        if(channel == null)
            return;
            
        channel.unbind(null, this.pusher);
    }

    protected onEvent(event: string, data: any) {
        this.cleanEvents();

        if(typeof data.hash != 'undefined' && typeof data.checksum != 'undefined' ) {

            // change for general endpoint
            //let url = this.url.build("/websocket/"+data.hash+"/"+data.checksum);
            let url = this.endpointPrefix + "/" + data.hash + "/" + data.checksum;

            this.http.get<any>(url).subscribe(
                (response:any) => {
                    console.log(data);
                    this.onResponse(event, response);
                },
                (error:any) => {
                    console.log(error);
                }
            );
        } else { // pusher events?
            this.onResponse(event, data);
        }

    }

    protected onResponse(event: string, data: any) {
        let callbacks = this.events.filter(sub => sub.event == event && sub.active);

        for(let sub of callbacks) {
            // TODO maybe implement other property for say if this event its mine
            sub.callback(data);
        }
    }

    public cleanEvents() {
        this.events = this.events.filter(sub => sub.active);
    }

    public subscribe(eventName: string, callback: any) {
        let subscriptor = new WebsocketSubscriptor(eventName, callback);
        this.events.push(subscriptor);
        return subscriptor;
    }

}

class WebsocketSubscriptor {
    public active: boolean;
    public event: string;
    public callback: (data?:any) => void;

    constructor(eventName: string, callback: any) {
        this.active = true;
        this.event = eventName;
        this.callback = callback;
    }

    public unsubscribe() {
        this.active = false;
    }
}
