import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';


import { HelperBoxModule } from '@core/components/helpers/box/box.module';
import { HelperFormModule } from '@core/components/helpers/form/form.module';
import { LayoutModule } from '@core/components/layout/layout.module';
import { ShareModule } from '@app/components/share/share.module';
//import { ApolloMainModule } from './main/main.module';

import { ShareFeedbackCoreComponentsModule } from '../../Core/components/components.module';

import { PluginShareFeedbackMainAppComponent } from './main/main.component';
import { PluginShareFeedbackReviewMainAppComponent } from './main-review/main-review.component';
import { PluginShareFeedbackFeedbackReviewComponent } from './feedback-review/feedback-review.component';
import { ShareFeedbackSectionsModule } from './sections/sections.module';
@NgModule({
    imports: [
        CommonModule,
        HelperBoxModule,
        HelperFormModule,
        LayoutModule,
        ShareModule,
        ShareFeedbackSectionsModule,
        ShareFeedbackCoreComponentsModule,
        //HelperBoxModule,
        ////ApolloMainModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        PluginShareFeedbackMainAppComponent,
        PluginShareFeedbackReviewMainAppComponent,
        PluginShareFeedbackFeedbackReviewComponent
    ],
    exports: [
        PluginShareFeedbackMainAppComponent,
        PluginShareFeedbackReviewMainAppComponent,
        PluginShareFeedbackFeedbackReviewComponent,
        ShareFeedbackSectionsModule
    ],

})
export class ShareFeedbackAppComponentsModule { }
