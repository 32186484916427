import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { GroupsService } from '../../../../../services/settings/groups.service';
import { Subscription } from 'rxjs';

import { HttpStatus } from '@core/classes/http-status.class';

import { User } from '@core/objects/user';
import { Group } from '@core/objects/group';

@Component({
    selector: 'settings-groups-add-form',
    templateUrl: './add-form.component.html',
    styleUrls: ['./add-form.component.scss']
})
export class SettingsGroupsAddFormComponent implements OnInit {

	public readonly STATUS_LOADING = 0;
	public readonly STATUS_DONE = 1;
	public readonly STATUS_ERROR = 2;

	public status: Number = 1;

	protected subscriptionStatus: Subscription;

	public form: FormGroup;

	@Output() cancel = new EventEmitter<any>();
	@Output() submit = new EventEmitter<any>();

	constructor(public grpSrv: GroupsService, private formBuilder: FormBuilder){}

	ngOnInit(){
		this.form = this.formBuilder.group({
            name: ['', [Validators.required]]
		});

        this.subscriptionStatus = this.grpSrv.selectorStatusSaveGroup().subscribe((status: HttpStatus) => {
			if(status.code == HttpStatus.DONE) {
				this.cleanForm();
				this.submit.emit(true);
			}
        });
	}

	onCancel() {
		this.cleanForm();
		this.cancel.emit(true);
	}

	onSubmit() {
		if(this.form.invalid)
			return;

		this.grpSrv.saveGroup(this.form.value.name);
	}

	cleanForm(){
		this.form.reset({name: ''});
	}
}
