import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'project-add-duedate',
    templateUrl: './duedate.component.html',
    styleUrls: ['./duedate.component.scss']
})
export class ProjectAddDuedateComponent{

    @Input() date: string = null;
    @Output() selectDate = new EventEmitter<string>();
    
	constructor(){}

    public onSelectDate(date: string){
        this.selectDate.emit(date);
    }

}
