import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';

@Component({
    selector: 'core-files-list-checkbox',
    templateUrl: './files-list-checkbox.component.html',
    styleUrls: ['./files-list-checkbox.component.scss']
})
export class FileListCheckboxComponent implements OnInit {

    @Input() files: Array<File>;
    @Input() maxSelected: number = 0; // Max files can be selected (0 = unlimited)
    @Input() disabled: boolean; // TODO

    @Input()  selected: Array<File>;
    @Output() selectedChange = new EventEmitter<Array<File>>();

    constructor() { }

    ngOnInit() {

    }

    toogleFile(file: File) {
        console.log(file, this.selected);
        if(this.existsFile(file)) {
            this.selected = this.selected.filter(f => f.id != file.id);
            console.log("entra", file, this.selected);
        }
        else {
            if(!this.disabled)
                this.selected.push(file);
        }

        this.selectedChange.emit(this.selected);
    }

    existsFile(file: File) : boolean {
        let files = this.selected.filter(f => f.id == file.id);

        return files.length > 0;
    }

}
