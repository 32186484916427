import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'core-layout-modal-input',
    templateUrl: './modal-input.component.html',
    styleUrls: ['./modal-input.component.scss']
})
export class LayoutModalInputComponent {
    @Input() title: string;
    @Input() placeholder: string; 
    @Input() type: string; // not used: just in case of more icons in the future (error,done,warning)
    @Input() inputType: string; //text,number,password,email
    @Input() text: string;
    @Input() label: string = '';
    @Input() required: boolean = true;
    @Input() buttonText: string;
    @Input() secondButtonText: string = ""; //LEFT GREY BUTTON
    @Input() disabled: boolean = false; // white button

    @Output() action = new EventEmitter<string>();
    @Output() secondAction = new EventEmitter<boolean>(); //LEFT GREY BUTTON


    public input: string = ''; // input value

    onAction() {
        if (this.isInputReady())
            this.action.emit(this.input);
    }

    onSecondAction() {
        this.secondAction.emit(true);
    }

    isInputReady() : boolean {
        if(this.required)
            return this.input != '';
        return true;  
    }
}
