import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import {HelpersModule } from '../helpers/helpers.module';

//import { PreviewModule } from '../preview/preview.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// components
import { StatusNameComponent } from './status-name/status-name.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HelpersModule,
        ReactiveFormsModule
        //PreviewModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        StatusNameComponent,


    ],
    exports: [
        StatusNameComponent,
    ],

})
export class StatusModule { }
// Module for status components
