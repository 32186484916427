import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

import { MultiSelectItem } from '@core/models/select-item.model';

@Component({
  selector: 'core-helpers-forms-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.scss'],
})
export class SelectMultipleComponent implements OnInit, AfterViewInit  {

    @Input() disabled: boolean;
    @Input() scrollTop: number = 0;
    @Input() label: string = "";
    @Input() options: MultiSelectItem[] = [];
    @Output() select = new EventEmitter<any>();
    @Output() selectScrollTop = new EventEmitter<number>();
    // list contianer element reference
    @ViewChild("listContainer") private listContainer: ElementRef;


    public selected: any = null;
    // public itemsSelected = [];

    ngOnInit() {
        this.selected = this.getValueSelected();
        // console.log("selected -->",this.scrollTop, this.selected);
    }

    ngAfterViewInit(){
        this.listContainer.nativeElement.scrollTo(0, this.scrollTop);
    }

    onChange(){
        this.selected = this.options.filter( o => { return o.selected}).map(o => o.value);
        this.select.emit(this.selected);
        // emit scroll value to in case we eant to save it
        this.selectScrollTop.emit(this.listContainer.nativeElement.scrollTop);
    }

    protected getValueSelected() {
        return this.options.filter( o => { return o.selected}).map(o => o.value);
    }
}
