// plugin-apollo-main
// PluginApolloMainComponent
import { Component } from '@angular/core';
//import { apolloMenu } from '../../../apollo.menu';
import { ApolloService } from '../../../services/apollo/apollo.service';

@Component({
    selector: 'plugin-apollo-main-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class PluginApolloMainAppComponent {
    //public menuApollo:any = apolloMenu;
    public disabled:boolean;
    public menuApollo: any;

    constructor(private apollo: ApolloService){
        console.debug("APOLLOOO APPPPP");
        //console.log(this.menuApollo);
    }

    /*ngOnInit(){
        this.apollo.getMenu().subscribe( tabs => {
            this.menuApollo = tabs;
        });
        //this.app.getSession().subscribe( (session: any) => {
        this.apollo.getDisable().subscribe(
            (disabled:any) => {
                this.disabled = disabled;
                console.debug("disable", disabled);
            }
        );
    }*/

}
