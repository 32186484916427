import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsersService } from '@app/services/settings/users.service';
import { User } from '@core/objects/user';
import { HttpStatus } from '@core/classes/http-status.class';
import { AppService } from '@app/services/app/app.service';

@Component({
    selector: 'settings-users-list-users',
    templateUrl: './list-users.component.html',
    styleUrls: ['./list-users.component.scss']
})
export class SettingsUsersListUsersComponent implements OnInit, OnDestroy{

	@Output() reload = new EventEmitter<string>();
    @Input() selected: Array<string> = [];

	public users: User[] = null;

	public subscriptionUsers: Subscription;
	public subscriptionStatusList: Subscription;
	public subscriptionStatusDelUser: Subscription;
	public subscriptionOwner: Subscription;

	public httpStatus:HttpStatus;


	public dataDisabled:string = null;
	public idAction:string = null;

	public owner: User = null;


	constructor(public userSrv: UsersService, public appSrv: AppService){}

	ngOnInit(){
		this.subscriptionStatusDelUser = this.userSrv.selectorStatusDelUser().subscribe((status: HttpStatus) => {
			if ( status.code == 0 && this.idAction != null) {
				this.dataDisabled = this.idAction;
			} else {
				this.dataDisabled = null;
				this.idAction = null;
			}
		});
		this.subscriptionStatusList = this.userSrv.selectorStatusList().subscribe((status: HttpStatus) => {
			this.httpStatus = status;

		});

		this.subscriptionOwner = this.appSrv.getSession().subscribe((session) => {
			this.owner = session.user;

		});

		this.subscriptionUsers = this.userSrv.selectorGetAllUsers().subscribe((users: User[]) => {
			if( users != null ){
				this.users = users.filter((user) => {
					return user.email != this.owner.email && user.id != this.owner.id
				});
			}
        });
	}

	ngOnDestroy(){
		this.subscriptionStatusList.unsubscribe();
		this.subscriptionUsers.unsubscribe();
		this.subscriptionStatusDelUser.unsubscribe();
	}

	deactivateUser(id){
		console.log('hash Deactivate ---- ', id);
		this.idAction = id;
		this.userSrv.deleteOneUser(id);
	}


	tryAgain(){
		this.reload.emit('users');
	}

    isActive(user: User): boolean {
        return !(this.selected.length > 0 && this.selected.indexOf(user.email) == -1);
    }
}
