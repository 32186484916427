import {Component, OnInit, SimpleChanges, OnChanges, Input, Optional, Inject, Output, EventEmitter, HostListener} from '@angular/core';
// import { ImageViewerConfig, CustomEvent } from './image-viewer-config.model';

import { UrlService } from '@core/services/url/url.service';

import { Viewer } from './viewer';

@Component({
  selector: 'core-preview-page-viewer',
  templateUrl: './preview-page-viewer.component.html',
  styleUrls: ['./preview-page-viewer.component.scss']
})
export class PreviewPageViewerComponent implements OnInit, OnChanges {

  // @Input() src: string;

  @Input() buttons: boolean = true;

  @Input() format;
  @Input() page: number;
  @Input() fileId: number;

  // not work very well fandango
  @Output() onLoad = new EventEmitter<boolean>();

  @Input() viewer: Viewer = new Viewer();
  @Output() viewerChange: EventEmitter<Viewer> = new EventEmitter();

  public loading = true;
  public src: string;
  // private prevX: number;
  // private prevY: number;
  // private hovered = false;

  constructor(public url: UrlService) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.page || changes.fileId || changes.format)
      this.init();
  }

  init() {
    this.loading = true;
    this.src = this.buildSrc(this.format);
  }

  buildSrc(format: string) : string {
    return this.url.build("/files/" + this.fileId + "/preview/" + format + "/" + this.page);
  }

  // button actions below
  zoomIn() {
    this.viewer.zoomIn();
    this.viewerChange.emit(this.viewer);
  }

  zoomOut() {
    this.viewer.zoomOut();
    this.viewerChange.emit(this.viewer);
  }

  rotate() {
    this.viewer.rotate();
    this.viewerChange.emit(this.viewer);
  }

  // image load
  imgLoaded(e) {
    this.loading = false;
    this.onLoad.emit(true);
    this.viewer.setSize(e.currentTarget.width, e.currentTarget.height);
  }

  imgLoadStart() {
    this.loading = true;
  }

  reset() {
    this.viewer.reset();
  }

}
