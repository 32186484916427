import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as Objects from '@core/objects';

import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';


@Component({
    selector: 'project-details-breadcrumb-header',
    templateUrl: './breadcrumb-header.component.html',
    styleUrls: ['./breadcrumb-header.component.scss']
})
export class ProjectDetailsBreadcrumbHeaderComponent {
	@Input() project: Objects.Request = null;
	@Input() title: string = '';
	@Input() breadcrumbs: any = [];
	@Input() menu: Array<ThreeDotsMenuItem> = [];
    @Output() action = new EventEmitter<string>();

    public edit: boolean = false;


    onMenuSelect(key: string) {
        this.action.emit(key);
    }

}
