import {AppEffects } from '../app/app.effects';

import { SettingsUsersEffects } from '../settings/users/users.effects';
import { SettingsGroupsEffects } from '../settings/groups/groups.effects';
import { SettingsLicensesEffects } from '../settings/licenses/licenses.effects';
import { SettingsRulesEffects } from '../settings/rules/rules.effects';

import { ProjectsEffects } from '../projects/projects.effects';
import { LabelsEffects } from '../labels/labels.effects';
import { VersionsEffects } from '../versions/versions.effects';
import { LibraryEffects } from '../library/library.effects';
import { DashboardEffects } from '../dashboard/dashboard.effects';

export const rootEffects = [
    AppEffects,
    SettingsUsersEffects,
    SettingsGroupsEffects,
    SettingsLicensesEffects,
    SettingsRulesEffects,
    ProjectsEffects,
    VersionsEffects,
    LabelsEffects,
    LibraryEffects,
    DashboardEffects
]