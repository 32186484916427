import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppRouterService } from '@app/services/app/router.service';

import { VersionListItemMenuClick } from '@core/components/versions/list/version-list-item-menu-click';

import * as Objects from '@core/objects';
import { VersionFeedback } from '@core/models/version-feedback';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';
import { AppService } from '@app/services/app/app.service';
import { AddProjectService } from '@app/services/app/add-project.service';

@Component({
    selector: 'project-details-versions-uploaded',
    templateUrl: './uploaded.component.html',
    styleUrls: ['./uploaded.component.scss']
})
export class ProjectDetailsVersionsUploadedComponent implements OnInit, OnDestroy {

    public readonly LOADING = 0;
    public readonly DONE = 1;
    public readonly ERROR = 2;

    public readonly ACCESS_REQUEST_FEEDBACK = 'share_feedback';
    public readonly ACCESS_ADD_PROJECT = "access_global_new_request";

    @Input() project: Objects.ExtendedRequest;
    @Input() versions: Array<Objects.Version> = [];
    @Input() feedbacks: Array<VersionFeedback> = [];
    public status:number = this.LOADING;
    public feedbackPluginAccess: boolean = false;
    public addProjectAccess: boolean = false;


    protected subscriptor: Subscription;

    public menu: Array<ThreeDotsMenuItem> = [
        {
            title: "See Artwork Details",
            key: "details",
            icon: "see-details"
        },
        // ARTWORK IS UPLOADED ALWAYS
        {
            title: "See Original Project",
            key: "see-project",
            icon: "see-project"
        }
    ];

    // optional menu item
    protected menuAddProject: ThreeDotsMenuItem = {
        title: "Start New Project",
        key: "add-project",
        icon: "add"
    };


    constructor( public app: AppService,
        private AddProjectSrv: AddProjectService,
        private appRouter: AppRouterService ) { }

    ngOnInit() {
        // get plugins access
        this.subscriptor = this.app.getSession().subscribe( (session: any) => {
            this.feedbackPluginAccess = session.options.plugins.some((pa) => pa == this.ACCESS_REQUEST_FEEDBACK);
            this.addProjectAccess = session.options.access.hasOwnProperty(this.ACCESS_ADD_PROJECT)? session.options.access[this.ACCESS_ADD_PROJECT] : false;
            if(this.addProjectAccess){
                this.addToMenu(this.menuAddProject);
            }
        });

    }

    ngOnDestroy() {
        this.subscriptor.unsubscribe();
    }

    init(){

        if(this.project == null || this.versions == null)
            return;
        this.status = this.DONE;
    }

    onMenuClick(menu: VersionListItemMenuClick) {
        // console.debug(" menu version item  click ",menu);
        switch(menu.key) {
            case "details":
                if(this.project != null)
                    // go to version Details
                    this.appRouter.navigate("/versions/"+menu.version.id, { queryParams: { context: 'uploaded' } });
                break;
            case "see-project":
                // navigate
                if(menu.version != null){
                    // this.versionsSrv.getProject$(menu.version).subscribe((originalProject: Objects.Request) => {
                        if(this.project != null)
                            this.appRouter.navigate('/projects/'+this.project.id);
                    // });
                }
                break;
            case "add-project":
                if(menu.version != null){
                    this.AddProjectSrv.clear();
                    this.appRouter.navigate('/projects/add', { queryParams: { version: menu.version.id } });
                }
                break;
        }
    }

    onFileClick(version: Objects.Version,file: Objects.File){
        // console.debug(" versions click file preview ",file);
        if (file != null && this.project != null){
            // TODO set this url to preview version FILE
            this.appRouter.navigate('/projects/'+this.project.id + "/versions/"+version.id + "/files/"+file.id);
        }
    }

    public getFeedbacks(version: Objects.Version){
        return this.feedbacks.filter((f: VersionFeedback) => f.version.id == version.id);
    }

    protected addToMenu(item: ThreeDotsMenuItem) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        this.menu.push(item);
    }

}
