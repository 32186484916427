import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';

import * as Objects from '@core/objects';
import * as Search from '@app/models/search';
import { DashboardService } from 'app/services/app/dashboard.service';
import { AppService } from 'app/services/app/app.service';

import { Session } from '@app/store/models/session.model';

@Component({
    selector: 'dashboard-views-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class DashboardViewsMainComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    // @Input() params: Search.SearchQueryParams = null;

    public status: number = 0;

    private urlSubscription: Subscription;

	constructor(
        private app: AppService,
        private dashSrv: DashboardService,
        private route: ActivatedRoute,
        private router: Router
    ){}

	ngOnInit(){
         this.urlSubscription = this.route.params.subscribe((params: any) => {
            // console.log("pasa por aqui...", params);
            if(params.hasOwnProperty('wid')) {
                this.dashSrv.setCurrentWorkflow(+params.wid);
            } else {
                this.dashSrv.selectCurrentWorkflow().subscribe((workflow: Objects.Workflow) => {
                    if(workflow == null)
                        return;
                        
                    this.router.navigate(["dashboard/"+ workflow.id + "/table"])
                });
            }
        });
    }

    ngOnDestroy() {
        this.urlSubscription.unsubscribe();
    }



}
