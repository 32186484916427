import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelsModule } from '@core/components/labels/labels.module';
import { LayoutTitlesModule } from '@core/components/layout/titles/titles.module';
import { VersionsModule } from '@core/components/versions/versions.module';
import { HelpersModule } from '@core/components/helpers/helpers.module';

import { AppHelperLabelsModule } from '@app/components/helpers/labels/labels.module';

import { VersionBreadcrumbHeaderComponent } from './version-breadcrumb-header/version-breadcrumb-header.component';

@NgModule({
    imports: [
        CommonModule,
        LayoutTitlesModule,
        LabelsModule,
        HelpersModule,
        VersionsModule,
        AppHelperLabelsModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [
        VersionBreadcrumbHeaderComponent
    ],
    exports: [
        VersionBreadcrumbHeaderComponent
    ],

})
export class VersionHelpersModule { }
