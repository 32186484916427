import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import * as  Objects from '@core/objects';

import { VersionFeedback } from '@core/models/version-feedback';

@Component({
    selector: 'core-versions-approval-box',
    templateUrl: './approval-box.component.html',
    styleUrls: ['./approval-box.component.scss']
})
export class VersionsApprovalBoxComponent implements OnInit {
    
    @Input() version: Objects.Version;
    @Input() feedback: VersionFeedback;

    @Output() actionApprove = new EventEmitter<any>();
    @Output() actionReject = new EventEmitter<any>();
    
    constructor( ) { }

    ngOnInit() {
        // this.setFutureStatus();
    }

    onApprove(event: any){
        this.actionApprove.emit(event);
    }

    onReject(event: any){
        this.actionReject.emit(event);
    }

}
