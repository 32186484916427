import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UrlService } from '@core/services/url/url.service';
import { File } from '@core/objects/file';

@Component({
    selector: 'core-file-download-one-action-list',
    templateUrl: './file-download-one-action-list.component.html',
    styleUrls: ['./file-download-one-action-list.component.scss']
})
export class FileDownloadOneActionListComponent implements OnInit {

    @Input() files: Array<File>;
    @Input() actionText: string = "";
    @Input() actionStyle: string = "";
    @Input() bubbles: boolean = false;
    @Input() bubbleTexts: Array<string> = [];

    @Output() actionClick = new EventEmitter<File>();
    @Output() bubbleClick = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {

    }

    onActionClick(file: File) {
        console.debug(" core-file-download-one-action-list onActionClick ",file);
        this.actionClick.emit(file)
    }
    onBubbleClick(file: File) {
        console.debug(" core-file-download-one-action-list onBubbleClick ",file);
        this.bubbleClick.emit(file)
    }

    getBubbleText(index: number) : string {
        if(this.bubbleTexts.length > index)
            return this.bubbleTexts[index];
        
        return "";
    }
}
