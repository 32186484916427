import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { DashboardSearchService, DashboardService } from 'app/services/app/dashboard.service';
import { ProjectsService } from 'app/services/app/projects.service';
import { AppRouterService } from 'app/services/app/router.service';

import * as Objects from '@core/objects';
import * as Search from '@app/models/search';


@Component({
    selector: 'dashboard-views-table-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class DashboardViewsTableMainComponent implements OnInit, OnDestroy {

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;

    public readonly SEARCH_STATUS = Search.SearchHttpStatus;

    protected readonly  availableEvents: any = {
        "request.version.uploaded": "badge_artwork",
        "request.created": "badge_new_request",
        "request.status.updated": "badge_status_update"
    };

    public status: number = 0;
    public sorting: boolean = false;
    public hasSearch: boolean = false;
    public loadingMore: boolean = false;
    public resultsStatus: number = Search.SearchHttpStatus.INITIAL;
    public items: Array<Objects.ExtendedRequest> = [];
    public excludedLabels: Objects.Entity[] = [];
    public openedProject: Objects.ExtendedRequest = null;
    public summaryLoadingId: number = null;
    public order: string = '';
    public orderedField: string = '';


    public events: {name: string, id: number | string}[] = [];


    protected resultsSubscriptor: Subscription;
    protected resultsStatusSubscriptor: Subscription;
    protected appliedFiltersSubscription: Subscription;
    protected projectSubscriptor: Subscription;
    protected appliedSortSubscription: Subscription;
    protected eventsSubscription: Subscription;
    protected termSubscription: Subscription;

	constructor(
        private dashSrv: DashboardService,
        private searchSrv: DashboardSearchService,
        private appRouter: AppRouterService,
        private projectsSrv: ProjectsService
    ){}

	ngOnInit(){
        this.resultsSubscriptor = this.dashSrv.selectResults().subscribe((projects: Objects.ExtendedRequest[]) => {
            this.items = projects;
            this.loadingMore = false; // for stop loading more
            if(projects != null && projects.length > 0){
                this.orderedField = this.order;
                this.sorting = false;
            }
        });

        this.resultsStatusSubscriptor = this.dashSrv.selectResultStatus().subscribe((rStatus: number) => {
            this.resultsStatus = rStatus;
        });

        this.appliedFiltersSubscription = this.searchSrv.selectFilters().subscribe((filters: Search.SearchAppliedFilter[]) => {
            this.hasSearch = (filters.length >0) ? true : false;
            this.excludedLabels = filters.filter((item) => item.key == 'label').map((f)=> f.item);
        });

        this.projectSubscriptor = this.projectsSrv.selectProject().subscribe((project: Objects.ExtendedRequest ) => {
            if(project != null){
                this.openedProject = project;
                this.summaryLoadingId = null;
            }
        });

        this.appliedSortSubscription = this.dashSrv.selectOrder().subscribe((order: string) => {
            // this.sorting = true;
            this.order = order;
            this.orderedField = this.order;
            this.sorting = false;
        });

        this.eventsSubscription = this.dashSrv.selectEvents().subscribe((events: {name: string, id: number | string}[]) => this.filterEvents(events));
        this.termSubscription = this.searchSrv.selectTerm().subscribe((term: string) => this.hasSearch = (term && term !='') );
    }

    ngOnDestroy() {
        this.resultsSubscriptor.unsubscribe();
        this.appliedFiltersSubscription.unsubscribe();
        this.projectSubscriptor.unsubscribe();
        this.appliedSortSubscription.unsubscribe();
        this.eventsSubscription.unsubscribe();
        this.termSubscription.unsubscribe();
    }

    loadMore() {
        this.loadingMore = true;
        this.dashSrv.loadResults();
    }

    onOpen(project: Objects.ExtendedRequest){
        this.projectsSrv.clear();
        this.appRouter.navigate('/projects/'+project.id);
    }

    onSummaryToggle(project: Objects.ExtendedRequest){

        if(this.openedProject != null){
            let previous = this.openedProject.id;
            this.openedProject = null;
            this.projectsSrv.clear();
            if( previous != project.id ){
                this.conditionalLoad(project);
            } else {
                this.summaryLoadingId = null;
            }
            return;
        }
        // in first open
        this.conditionalLoad(project)
    }

    isSummaryOpen(project: Objects.ExtendedRequest){
        if(this.openedProject == null)
            return false;

        return this.openedProject.id == project.id;
    }

    onScroll(){
        // load more only if there is no final.
        if(this.resultsStatus ==  Search.SearchHttpStatus.DONE ){
            this.loadMore();
        }
    }

    private conditionalLoad(project: Objects.ExtendedRequest){
        if(project.target_version == null){
            this.summaryLoadingId = project.id;
            this.projectsSrv.load(project.id);
        } else {
            this.openedProject = project;
            this.summaryLoadingId = null;
        }
    }

    private filterEvents(events: {name: string, id: number | string}[]) {
        this.events = events.filter((event: {name: string, id: number | string}) => Object.keys(this.availableEvents).indexOf(event.name)>-1);
    }

    public eventBadge(id: number | string) : string {
        let events = this.events.filter((event: {name: string, id: number | string}) => event.id == id);

        if(events.length == 0)
            return "";

        // fix problem with status_update and create
        let eventName = events.pop().name;
        if(events.length > 0)
        {
            let leventName = events.pop().name;

            eventName = leventName=="request.created" && eventName == "request.status.updated"? leventName : eventName;
        }

        return this.availableEvents[eventName];
    }

}
