import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { WebsocketService } from '@core/services/websocket/websocket.service';
import { TitleService } from '@core/services/title.service';
import * as Objects from '@core/objects';
import { ThreeDotsMenuItem } from '@core/components/helpers/navigation/three-dots-menu/three-dots-menu-item';

import { AppService } from '@app/services/app/app.service';
import { DashboardService } from 'app/services/app/dashboard.service';

import * as fromState from '@app/store/state/dashboard/dashboard.state';
import * as Search from '@app/models/search';
import { AddProjectService } from 'app/services/app/add-project.service';

@Component({
    selector: 'dashboard-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class DashboardMainComponent implements OnInit, OnDestroy {
    public readonly ACCESS_ADD_PROJECT = "access_global_new_request";

    public readonly STATUS_LOADING = 0;
    public readonly STATUS_DONE = 1;
    public readonly STATUS_NOT_ALLOWED = 2;
    public readonly STATUS_NOT_FOUND = 3;
    public readonly STATUS_ERROR = 4;
    public readonly STATUS_EMPTY = 5;

    public status: number = 0;
    public workflows: Objects.Workflow[] = null;
    public view: string = 'table' // by now 
   
    protected firstLoad: boolean = false;

    protected currentWorkflowId: number = null;

    params: Search.SearchQueryParams = null;

    // TODO review menu icons
    public menu: Array<ThreeDotsMenuItem> = [];
    // optional menu items
    protected menuAddProject: ThreeDotsMenuItem = {
        title: "Start New Project",
        key: "add-project",
        icon: "add",
        order: 1
    };
    protected addProjectAccess: boolean = false;

    protected workflowsSubscription: Subscription;
    protected urlSubscription: Subscription;
    protected querySubscription: Subscription;
    protected wsSubscription: any = null;

	constructor(
        public app: AppService, 
        private dashSrv: DashboardService,
        public websocket: WebsocketService,
        private title: TitleService,
        private route: ActivatedRoute,
        private router: Router,
        private AddProjectSrv: AddProjectService,
        ){}

	ngOnInit(){

        // this.dashSrv.init(null);

        this.title.set("Dashboard");
        this.workflowsSubscription = this.dashSrv.selectWorkflows().subscribe((workflows: fromState.DashboardWorkflows) => {
            this.workflows = workflows.items;

            if(this.workflows.length > 0)
                this.status = this.STATUS_DONE;
            // else if (workflows.empty)
            //     this.status = this.STATUS_EMPTY
            // else
            //     this.dashSrv.loadWorkflows(); // FIX possible bucle if user doesnt have permission in any workflow

            if(workflows.current != null) {
                this.title.set(workflows.current.name + " - Dashboard");
            }
        });

        // rafa: removed workflow from querys, doesnt work well
        // let queryParams = {...this.route.snapshot.queryParams, ...this.route.snapshot.params.hasOwnProperty('wid')? {workflow: this.route.snapshot.params.wid} : {} }
        let queryParams = this.route.snapshot.queryParams;
        console.log("query params--->", queryParams);
        this.dashSrv.start(queryParams, "url");

  

        // this.querySubscription = this.dashSrv.selectQueryParams().subscribe((params: Search.SearchQueryParams) => {
        //     console.log("get store-->", params);
        //     this.setQueryInURL(params);
        // });

        // add project access from session
        this.app.getSession().subscribe( (session: any) => {
            // this.session = session;
            if(session == null)
                return;

            this.addProjectAccess = session.options.access.hasOwnProperty(this.ACCESS_ADD_PROJECT)? session.options.access[this.ACCESS_ADD_PROJECT] : false;
            this.updateMenu(this.menuAddProject,this.addProjectAccess);

            // // connect to websocket
            // this.wsSubscription = this.websocket.addChannel('private-org_'+session.organization.id);
            // this.wsSubscription.bind('request.updated', data => {
            //         // console.log("websocket request update --->", data);
            //         this.dashSrv.updateProject(data.request, data.source_event);
            // });
        });
      
	}

    ngOnDestroy() {
        this.workflowsSubscription.unsubscribe();
        // this.querySubscription.unsubscribe();
        // if(this.wsSubscription != null)
        //     this.wsSubscription.unbind();
    }

    onMenuClick(key: string) {
        switch(key) {
            case "add-project":
                this.AddProjectSrv.clear();
                this.router.navigate(['/projects/add'], { queryParams: { workflow: this.currentWorkflowId } });
                break;
        }
    }

    // this function check either remove or add item. based on subscribed events
    protected updateMenu(item: ThreeDotsMenuItem, addItem:boolean = true) {
        this.menu = this.menu.filter((i: ThreeDotsMenuItem) => item.key != i.key);
        if(addItem){
            this.menu.push(item);
        }
    }

    private setQueryInURL(params: Search.SearchQueryParams) {
        // console.log("queryyyy", query);
        // this.params = params;
        if(params == null)
             return;

        if(!this.firstLoad)
            this.params = params;
            
        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: Search.SearchConvert.removeVoidParams(params),
                queryParamsHandling: '',
                // skipLocationChange: true // doesnt write url

            }
        )
    }
    

    protected setError(error: HttpErrorResponse) {

        if(error == null)
            return;

        switch(error.status) {
            case 500:
                this.status = this.STATUS_ERROR;
                break;
            case 404:
                this.status = this.STATUS_NOT_FOUND;
                break;
            case 403:
                this.status = this.STATUS_NOT_ALLOWED;
                break;
        }
    }

    init() {

    }

}
